import { CreatableSelect } from '@/components/form/common/creatable-select';
import { Select } from '@/components/form/common/select';
import { TextInput } from '@/components/form/common/text-input';
import { useLocations } from '@/hooks/query-hooks/use-locations';
import { useSchools } from '@/hooks/query-hooks/use-schools';

type SiblingItemProps = {
  index: number;
  disabled?: boolean;
  onDelete: () => void;
};

function SiblingItem(props: SiblingItemProps) {
  const { data: locationsData } = useLocations();
  const { data: schoolsData } = useSchools();

  const locations =
    locationsData?.map(location => ({
      label: location.name,
      value: location.id,
    })) || [];

  const schools =
    schoolsData?.map(location => ({
      label: location.name,
      value: location.id,
    })) || [];

  const { index, disabled, onDelete } = props;

  return (
    <div className="light-gray-container group space-y-6">
      <div className="mb-6 flex items-center justify-between">
        <p className="text-md my-1.5 font-medium">Child {index + 1}</p>
        <button
          type="button"
          className="btn btn-error hidden py-2 group-hover:block"
          onClick={onDelete}
          disabled={disabled}
        >
          Remove
        </button>
      </div>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
        <TextInput
          type="text"
          name={`siblings[${index}].firstName`}
          label="First Name*"
          placeholder="John"
          disabled={disabled}
        />
        <TextInput
          type="text"
          name={`siblings[${index}].lastName`}
          label="Last Name*"
          placeholder="Doe"
          disabled={disabled}
        />
        <TextInput
          name={`siblings[${index}].dateOfBirth`}
          type="date"
          label="Date of Birth*"
          disabled={disabled}
        />
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
        <Select
          label="Sex*"
          options={[
            { key: '', value: 'Select..' },
            { key: 'male', value: 'Male' },
            { key: 'female', value: 'Female' },
          ]}
          name={`siblings[${index}].sex`}
          disabled={disabled}
        />
        <Select
          label="Education Stage"
          options={[
            { key: '', value: 'Select..' },
            { key: 'nursery', value: 'Nursery' },
            { key: 'child-minder', value: 'Child Minder' },
            { key: 'playgroup', value: 'Playgroup' },
            { key: 'primary', value: 'Primary' },
            { key: 'secondary', value: 'Secondary' },
          ]}
          name={`siblings[${index}].stage`}
          disabled={disabled}
        />
        <CreatableSelect
          label="Name of Location"
          placeholder="Start typing location name.."
          name={`siblings[${index}].school`}
          options={[
            { label: 'Nursery', options: locations },
            { label: 'Schools', options: schools },
          ]}
        />
      </div>
    </div>
  );
}

export { SiblingItem };
