import { Dialog, Transition } from '@headlessui/react';
import {
  AlertCircle,
  AlertTriangle,
  CheckCircle,
  InfoCircle,
} from '@untitled-ui/icons-react';
import { isFunction } from 'lodash-es';
import { Fragment, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { Alert } from './alert';
import { Spinner } from './spinner';

type MessageType = 'error' | 'warning' | 'success' | 'info';
type ModalProps = {
  open: boolean;
  title: string;
  description?: string;
  onClose: () => void;
  primaryBtnText: string;
  secondaryBtnText?: string;
  primaryAction: () => any;
  secondaryAction?: (() => void) | (() => Promise<void>);
  type?: MessageType;
  disabled?: boolean;
  isLoading?: boolean;
  error?: string;
};

type TypeClasses = {
  [K in MessageType]: string;
};

function Modal(props: ModalProps) {
  const {
    onClose,
    open,
    title,
    description,
    primaryAction,
    secondaryAction,
    primaryBtnText,
    secondaryBtnText,
    type = 'error',
    disabled,
    isLoading,
    error,
  } = props;
  const cancelButtonRef = useRef(null);

  const hasError = Boolean(error);
  const renderIcon = () => {
    switch (type) {
      case 'error':
        return (
          <AlertCircle
            className="h-6 w-6 text-red-600"
            viewBox="0 0 24 24"
            aria-hidden="true"
          />
        );
      case 'info':
        return (
          <InfoCircle
            className="h-6 w-6 text-blue-600"
            viewBox="0 0 24 24"
            aria-hidden="true"
          />
        );
      case 'success':
        return (
          <CheckCircle
            className="h-6 w-6 text-green-600"
            viewBox="0 0 24 24"
            aria-hidden="true"
          />
        );
      case 'warning':
        return (
          <AlertTriangle
            className="h-6 w-6 text-yellow-600"
            viewBox="0 0 24 24"
            aria-hidden="true"
          />
        );
    }
  };

  const classByType = (classes: TypeClasses) => {
    switch (type) {
      case 'error':
        return classes.error;
      case 'info':
        return classes.info;
      case 'warning':
        return classes.warning;
      case 'success':
        return classes.success;
    }
  };

  const handleClose = () => {
    if (!isLoading) {
      onClose();
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-60"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-60 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                {isLoading && (
                  <div className="absolute inset-0 flex h-full w-full items-center justify-center bg-white/80">
                    <Spinner className="h-10 w-10 text-white" />
                  </div>
                )}

                <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-5">
                  <div className="sm:flex sm:items-center">
                    <div
                      className={twMerge(
                        'mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-12 sm:w-12',
                        classByType({
                          error: 'bg-red-100',
                          info: 'bg-blue-100',
                          success: 'bg-green-100',
                          warning: 'bg-yellow-100',
                        })
                      )}
                    >
                      {renderIcon()}
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {title}
                      </Dialog.Title>
                      {description ? (
                        <div className="mt-2">
                          <p className="text-sm">{description}</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {hasError ? (
                    <Alert
                      type="error"
                      text={
                        <>
                          <b>Error during the action</b>
                          <p>{error}</p>
                        </>
                      }
                      className="mt-5"
                    />
                  ) : null}
                </div>
                <div className="border-t border-gray-200 px-4 py-6 sm:flex sm:flex-row-reverse sm:px-6">
                  {primaryBtnText && isFunction(primaryAction) ? (
                    <button
                      type="button"
                      className={twMerge(
                        'btn w-full sm:ml-3 sm:w-auto',
                        classByType({
                          error: 'btn-error',
                          info: 'btn-info',
                          success: 'btn-success',
                          warning: 'btn-warning',
                        })
                      )}
                      onClick={primaryAction}
                      disabled={disabled}
                    >
                      {primaryBtnText}
                    </button>
                  ) : null}
                  {secondaryBtnText && isFunction(secondaryAction) ? (
                    <button
                      type="button"
                      className="btn btn-secondary mt-3 w-full sm:mt-0 sm:w-auto"
                      onClick={secondaryAction}
                      ref={cancelButtonRef}
                      disabled={disabled}
                    >
                      {secondaryBtnText}
                    </button>
                  ) : null}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { Modal };
