import { CapacityDemandTableRow } from '@/types/capacity-simulation';
import {
  AccumulatedDayDemand,
  CapacityReports,
  DemandReports,
  DtoSimulateMatchingLocation,
  FlatResource,
  FlatResourceCapacity,
  FlatResourceDemandReport,
} from '@admissions-support/types';

export function getCapacityOverride({
  locationId,
  day,
  sessionTypeId,
  simulatedCapacity,
}: {
  locationId: string;
  day: keyof FlatResourceCapacity;
  sessionTypeId: string;
  simulatedCapacity: DtoSimulateMatchingLocation[];
}): number | undefined {
  // Is there a simulated change for the locatioln?
  const resource = simulatedCapacity.find(
    resource => resource.locationId === locationId
  );
  if (!resource) {
    return undefined;
  }
  // Is there a simulated change for the session type and for the day?
  const capacityOverride = resource.capacity.find(
    capacity =>
      capacity.sessionTypeId === sessionTypeId && capacity[day] !== undefined
  );

  return capacityOverride?.[day];
}

export function filterBySelectedLocations<
  T extends CapacityReports | DemandReports
>(report: T, selectedLocations: string[]): T {
  if (!report) {
    return [] as unknown as T;
  }

  if (selectedLocations.length === 0) {
    return report;
  }

  return (report as CapacityReports).filter(
    ({ location: { id } }: { location: { id: string } }) =>
      selectedLocations.includes(id)
  ) as T;
}

export function orderByLocationName<T extends CapacityReports | DemandReports>(
  report: T
): T {
  if (!report) {
    return [] as unknown as T;
  }

  return report.sort((a, b) => {
    // First, compare by area name
    if (a.location.areas.length > 0 && b.location.areas.length > 0) {
      const areaComparison = a.location.areas[0].name.localeCompare(
        b.location.areas[0].name
      );

      if (areaComparison !== 0) {
        return areaComparison;
      }
    }
    // If area names are the same, compare by location name
    return a.location.name.localeCompare(b.location.name);
  }) as T;
}

function transformCapacityDemandResource(
  resources: FlatResource[] | FlatResourceDemandReport[]
): CapacityDemandTableRow['capacityAndAvailabilitydata'] {
  return (resources as FlatResource[]).reduce<
    CapacityDemandTableRow['capacityAndAvailabilitydata']
  >(
    (
      acc: CapacityDemandTableRow['capacityAndAvailabilitydata'],
      resource: FlatResourceDemandReport | FlatResource
    ) => {
      acc[resource.sessionTypeGroupName] = {
        group: resource.sessionTypeGroupName,
        capacityRange: resource.capacityRange,
        availabilityRange: resource.availabilityRange,
      };

      return acc;
    },
    {}
  );
}

export function convertReport(
  report: CapacityReports | DemandReports
): CapacityDemandTableRow[] {
  if (!report) {
    return [];
  }

  return report.map(row => ({
    seedCode: row.location.seed,
    location: {
      id: row.location.id,
      name: row.location.name,
      areas: row.location.areas,
    },
    physicalCapacity: row.physicalCapacity,
    capacityAndAvailabilitydata: transformCapacityDemandResource(row.resources),
  }));
}

export function getUniqueGroups(tableData: CapacityDemandTableRow[]): string[] {
  const allGroups = tableData.flatMap(row =>
    Object.values(row.capacityAndAvailabilitydata).map(
      resource => resource.group
    )
  );
  return Array.from(new Set(allGroups));
}

export function transformDemandDayToCapacityDay({
  updatedResource,
  oldResource,
  day,
}: {
  oldResource: FlatResourceDemandReport;
  day: keyof FlatResourceCapacity;
  updatedResource: FlatResource | undefined;
}): AccumulatedDayDemand | null {
  if (!day) {
    return null;
  }

  return {
    capacity: updatedResource?.capacity[day]?.capacity || 0,
    demand: oldResource.capacity[day]?.demand || 0,
  };
}
