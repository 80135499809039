import { locationKey } from '@/config/query-keys';
import { useSchoolYear } from '@/context/school-year.context';
import { useDeleteResourceMutation } from '@/hooks/delete-hooks/use-delete-resource-mutation';
import { useModal } from '@/hooks/use-modal';
import { queryClient } from '@/libs/react-query';
import { getErrorMessage } from '@/utils/get-error-message';
import { Trash01 } from '@untitled-ui/icons-react';
import { useParams } from 'react-router-dom';
import { Modal } from './modal';
import { useDeleteExtraHourResourceMutation } from '@/hooks/delete-hooks/use-delete-extra-hour-resource-mutation';

type DeleteResourceProps = {
  operationId: string;
  resourceId: string;
  onSuccess: () => void;
  isExtraHours?: boolean;
};

function DeleteResource(props: DeleteResourceProps) {
  const { resourceId, operationId, onSuccess, isExtraHours } = props;
  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();
  const params = useParams<{ locationId: string }>();
  const { schoolYear } = useSchoolYear();
  const {
    mutateAsync: deleteResource,
    isPending: isDeletingResource,
    error: deleteResourceError,
  } = useDeleteResourceMutation({
    onSuccess: async () => {
      closeDeleteModal();
      onSuccess();
      await queryClient.invalidateQueries({
        queryKey: locationKey.operation(params.locationId || '', schoolYear.id),
        refetchType: 'active',
      });
    },
  });

  const {
    mutateAsync: deleteExtraHourResource,
    isPending: isDeletingExtraHourResource,
    error: deleteExtraHourResourceError,
  } = useDeleteExtraHourResourceMutation({
    onSuccess: async () => {
      closeDeleteModal();
      onSuccess();
      await queryClient.invalidateQueries({
        queryKey: locationKey.operation(params.locationId || '', schoolYear.id),
        refetchType: 'active',
      });
    },
  });

  const error = deleteExtraHourResourceError || deleteResourceError;

  const isLoading = isDeletingExtraHourResource || isDeletingResource;

  const handleDeleteResource = isExtraHours
    ? async () => await deleteExtraHourResource({ operationId, resourceId })
    : async () => await deleteResource({ resourceId, operationId });

  return (
    <>
      <button
        className="btn btn-error ml-auto flex items-center"
        onClick={openDeleteModal}
        type="button"
      >
        <Trash01
          className="mr-2 h-5 w-5"
          viewBox="0 0 24 24"
          aria-hidden="true"
        />
        Delete Resource
      </button>
      <Modal
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        title="Confirm delete Resource"
        primaryBtnText="Confirm"
        primaryAction={handleDeleteResource}
        secondaryBtnText="Cancel"
        secondaryAction={closeDeleteModal}
        type="error"
        error={getErrorMessage(error)}
        isLoading={isLoading}
      />
    </>
  );
}

export { DeleteResource };
