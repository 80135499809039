import { formatCurrency } from '@/utils/format-currency';
import { Box } from '../box';
import { FallbackComponent } from '../fallback-component';
import { useChildBookingsInfo } from '@/hooks/query-hooks/use-child-bookings-info';
import { ApplicationStatusTag } from '../application/application-status-tag';

type ExtraHoursBalanceProps = {
  childId: string;
};

function Balance(props: ExtraHoursBalanceProps) {
  const { childId } = props;

  const {
    data: bookingsMeta,
    isError: isBookingsMetaError,
    isSuccess: isBookingsMetaSuccess,
    isLoading: isBookingsMetaLoading,
  } = useChildBookingsInfo(childId);

  if (isBookingsMetaLoading) {
    return (
      <Box className="flex w-full flex-col p-5">
        <p className="text-md font-semibold">Sessions Booked</p>
        <p
          className="mb-4 flex h-6 w-8 animate-pulse rounded-md bg-gray-200"
          role="status"
        />

        <p className="text-md mt-auto font-semibold">Outstanding balance</p>
        <p
          className="flex h-6 w-16 animate-pulse rounded-md bg-gray-200"
          role="status"
        />
      </Box>
    );
  }

  if (!isBookingsMetaSuccess || isBookingsMetaError) {
    return (
      <Box className="w-full p-5">
        <FallbackComponent />
      </Box>
    );
  }

  return (
    <Box className="flex w-full  flex-col p-5">
      <p className="text-md font-semibold">Sessions Booked</p>
      <p className="mb-4 font-mono text-gray-500">{bookingsMeta.nbOfBooking}</p>

      <p className="text-md mt-auto font-semibold">Outstanding balance</p>
      <div className="flex space-x-1 font-mono text-gray-500">
        <p>{formatCurrency(Math.abs(Number(bookingsMeta.balance)))}</p>
        {Number(bookingsMeta.balance) < 0 ? (
          <ApplicationStatusTag status="PLACED">Credit</ApplicationStatusTag>
        ) : Number(bookingsMeta.balance) === 0 ? null : (
          <ApplicationStatusTag status="NOT_PLACED">Debit</ApplicationStatusTag>
        )}
      </div>
    </Box>
  );
}

export { Balance };
