interface LoginTitleProps {
  title: string;
  description?: string;
}
function LoginTitle(props: LoginTitleProps) {
  const { title, description } = props;
  return (
    <div className="mt-10">
      <h2 className="mt-8 font-fmly text-2xl font-bold leading-10 tracking-tight text-gray-900 lg:text-4xl">
        {title}
      </h2>
      {description ? (
        <p className="text-md mt-3 font-normal leading-6 text-gray-600">
          {description}
        </p>
      ) : null}
    </div>
  );
}

export { LoginTitle };
