import { useSchoolYear } from '@/context/school-year.context';
import { useLocationOperation } from '@/hooks/query-hooks/use-operation';
import { useSessionTypes } from '@/hooks/query-hooks/use-session-types';
import { useSessions } from '@/hooks/query-hooks/use-sessions';
import { getErrorMessage } from '@/utils/get-error-message';
import {
  CreateProductDto,
  Operation,
  Product,
  ProductListing,
} from '@admissions-support/types';
import { useParams } from 'react-router-dom';
import { Alert } from '../alert';
import { LoadingScreen } from '../loading-screen';
import { ScrollableModal } from '../scroll-modal';
import { ManageProductForm } from './forms/manage-product-form';

type ManageProductModalProps = {
  isOpen: boolean;
  onClose: () => void;
  initialProduct?: ProductListing | Product;
  afterLeave?: () => void;
};

type ModalData = {
  title: string;
  description?: string;
};

type ManageProductFormData = Omit<
  CreateProductDto,
  'locationId' | 'schoolYearId'
>;

function ManageProductModal(props: ManageProductModalProps) {
  const { isOpen, onClose, initialProduct, afterLeave } = props;

  const defaultModalData = {
    title: initialProduct ? 'Edit Product' : 'Add Purchasable Product',
    description: initialProduct
      ? 'Update already existing Product'
      : 'Specify additional products available for purchase',
  };

  const { locationId = '' } = useParams<string>();

  const { schoolYear } = useSchoolYear();

  const {
    isLoading: isSessionTypesLoading,
    data: sessionTypes,
    isError: isSessionTypesError,
    error: sessionTypesError,
  } = useSessionTypes();

  const {
    data: sessions,
    isLoading: isSessionLoading,
    isError: isSessionError,
    error: sessionError,
  } = useSessions(locationId, schoolYear.id);

  const {
    isLoading: isOperationLoading,
    data: operation,
    isError: isOperationError,
  } = useLocationOperation(locationId);

  /**
   * Loading Screen Modal
   */
  if (isSessionLoading || isSessionTypesLoading || isOperationLoading) {
    return (
      <ScrollableModal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        afterLeave={afterLeave}
      >
        <ScrollableModal.Header onClose={onClose}>
          <ScrollableModal.Title
            title={defaultModalData.title}
            description={defaultModalData.description}
          />
        </ScrollableModal.Header>
        <ScrollableModal.Content>
          <div className="my-6">
            <LoadingScreen />
          </div>
        </ScrollableModal.Content>
      </ScrollableModal>
    );
  }

  /**
   * Error Screen Modal
   */
  if (isSessionError || isSessionTypesError || isOperationError) {
    return (
      <ScrollableModal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        afterLeave={afterLeave}
      >
        <ScrollableModal.Header onClose={onClose}>
          <ScrollableModal.Title
            title={defaultModalData.title}
            description={defaultModalData.description}
          />
        </ScrollableModal.Header>
        <ScrollableModal.Content>
          <Alert
            type="error"
            text={getErrorMessage(sessionError || sessionTypesError)}
          />
        </ScrollableModal.Content>
      </ScrollableModal>
    );
  }

  /**
   * Form Screen Modal
   */
  return (
    <ScrollableModal
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
      afterLeave={afterLeave}
    >
      <ManageProductForm
        initialProduct={initialProduct}
        schoolYearId={schoolYear.id}
        sessions={sessions || []}
        sessionTypes={sessionTypes || []}
        onClose={onClose}
        operation={operation as Operation}
      />
    </ScrollableModal>
  );
}

export { ManageProductModal, type ManageProductFormData, type ModalData };
