import { areaService } from '@/services/area.service';
import { HttpError } from '@/types/error';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useDeleteAreaMutation(
  mutationOptions?: UseMutationOptions<void, HttpError, string>
) {
  return useMutation({
    mutationFn: areaService.deleteArea,
    ...mutationOptions,
  });
}

export { useDeleteAreaMutation };
