import { staffRouterPath } from '@/config/route-paths.config';
import { useLeaveModal } from '@/hooks/use-leave-modal';
import { ApplicationFormDto } from '@/types/application';
import { HttpError, isBadRequest } from '@/types/error';
import { transformFormDataToDto } from '@/utils/application-utils';
import { getNestedKeys } from '@/utils/get-nested-keys';
import { Application, UpdateApplicationDto } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { placementPreferencesSchema } from './placement-preferences.schema';
import { PlacementPreferencesFormData } from './placement-preferences.type';

type UsePlacementPreferencesFormProps = {
  onSubmit: (data: UpdateApplicationDto) => Promise<any>;
  initialData: Application;
};

function transformApiDataToFormData(
  initialData: Application
): PlacementPreferencesFormData {
  return {
    currentLocationId: initialData.currentLocation?.id.toString() || '',
    currentLocationDescription: initialData.currentLocationDescription || '',
  };
}

function usePlacementPreferencesForm(props: UsePlacementPreferencesFormProps) {
  const { initialData, onSubmit } = props;
  const isDraft = useRef(true);
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const defaultValues: PlacementPreferencesFormData =
    transformApiDataToFormData(initialData);
  const defaultResolver = yupResolver(placementPreferencesSchema);

  const form = useForm({
    resolver: (data, context, options) => {
      if (isDraft.current) {
        // if its draft no validation required
        return { errors: {}, values: data };
      }
      return defaultResolver(data, context, options);
    },
    defaultValues,
  });

  const { isDirty } = form.formState;
  useLeaveModal({
    show: isDirty,
  });

  useEffect(() => {
    if (!shouldRedirect) {
      return;
    }
    navigate(
      generatePath(staffRouterPath.APPLICATION_ADDITIONAL_DETAILS, {
        id: params.id || '',
      })
    );
  }, [shouldRedirect, navigate, params.id]);

  const { reset } = form;
  useEffect(() => {
    if (!form.formState.isSubmitSuccessful) {
      return;
    }
    reset(undefined, { keepValues: true });

    if (!isDraft.current) {
      setShouldRedirect(true);
    }
  }, [form.formState.isSubmitSuccessful, params.id, navigate, reset]);

  useEffect(() => {
    reset(transformApiDataToFormData(initialData));
  }, [initialData, reset]);

  const handleSubmit = async (data: ApplicationFormDto) => {
    try {
      const transformedApplication = transformFormDataToDto(data, [
        'currentLocationId',
        'currentLocationDescription',
      ]);

      const applicationDto: UpdateApplicationDto = {
        ...transformedApplication,
      };

      await onSubmit(applicationDto);
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        const availableFields = getNestedKeys(form.getValues());

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }

      form.setError('root.server', {
        message: Array.isArray(httpError.message)
          ? httpError.message[0]
          : httpError.message,
      });
    }
  };

  const setDraft = (status: boolean) => {
    isDraft.current = status;
  };

  const saveAsDraft = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setDraft(true);
    await form.handleSubmit(handleSubmit)();
  };

  const handleSave = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setDraft(false);
    await form.handleSubmit(handleSubmit)();
  };

  return { form, handleSubmit: handleSave, saveAsDraft };
}

export { usePlacementPreferencesForm };
