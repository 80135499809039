import { organisationKey } from '@/config/query-keys';
import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import { Organisation, UpdateOrganisationDto } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateOrganisationMutation(
  mutationOptions?: UseMutationOptions<
    Organisation,
    HttpError,
    { data: UpdateOrganisationDto; id: string }
  >
) {
  return useMutation({
    mutationKey: organisationKey.updateOrganisation,
    mutationFn: organisationService.updateOrganisation,
    ...mutationOptions,
  });
}

export { useUpdateOrganisationMutation };
