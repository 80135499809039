import { reportKey } from '@/config/query-keys';
import { useReportingOutputControl } from '@/context/reporting-output-control.context';
import { reportService } from '@/services/report.service';
import { HttpError } from '@/types/error';
import { ToggledColumns } from '@/types/reporting-output-control';
import {
  ExportableLocationsKeys,
  Location,
  PaginateResult,
  ReportsLocationsDto,
} from '@admissions-support/types';
import {
  UseQueryOptions,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';

function useLocationsReportResult(
  data: ReportsLocationsDto,
  queryOptions?: Partial<UseQueryOptions<PaginateResult<Location>, HttpError>>
) {
  return useQuery({
    queryKey: reportKey.getLocationsResults(data),
    queryFn: () =>
      reportService.getLocationsReportResult({
        ...data,
        page: data.page && data.page !== 0 ? data.page + 1 : 1,
      }),
    ...queryOptions,
    placeholderData: keepPreviousData,
  });
}

function useExportLocationsReportResultToCSV(
  data: ReportsLocationsDto,
  queryOptions?: Partial<UseQueryOptions<Blob, HttpError>>
) {
  const { toggledColumns } = useReportingOutputControl();

  const enabledColumns = Object.keys(toggledColumns).filter(
    key => toggledColumns[key as keyof ToggledColumns]
  );

  return useQuery({
    queryKey: reportKey.exportLocationsResult(data),
    queryFn: () =>
      reportService.exportLocationsReportResult({
        ...data,
        isCsvExport: true,
        toExport: enabledColumns as ExportableLocationsKeys[],
        page: data.page && data.page !== 0 ? data.page + 1 : 1,
      }),
    ...queryOptions,
  });
}

export { useExportLocationsReportResultToCSV, useLocationsReportResult };
