import { DateInput } from './form/common/date-input';

type StartEndInputProps = {
  name: string;
  label?: string;
  disabled?: boolean;
};

function StartEndInput(props: StartEndInputProps) {
  const { name, label, disabled } = props;
  return (
    <div className="dark-gray-container">
      {label ? (
        <p className="text-md mb-6 font-medium capitalize">{label}</p>
      ) : null}
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        <DateInput
          name={`${name}.start`}
          type="time"
          label="Start"
          disabled={disabled}
        />
        <DateInput
          name={`${name}.end`}
          type="time"
          label="End"
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export { StartEndInput };
