import { PermissionGuard } from '@/components/auth/permission-guard';
import { PageTitle } from '@/components/page-title';
import { LocationGroupingTable } from '@/components/settings/location-grouping/location-grouping-table';
import { staffRouterPath } from '@/config/route-paths.config';
import { Permission } from '@/types/auth';
import { Link } from 'react-router-dom';

function LocationGrouping() {
  return (
    <div>
      <PageTitle
        variant="gray"
        title="Location Grouping"
        description="Specify groupings of locations"
      >
        <PermissionGuard requiredPermissions={[Permission['location:create']]}>
          <Link
            to={staffRouterPath.NEW_LOCATION_GROUPING}
            className="btn btn-primary"
          >
            Add Area
          </Link>
        </PermissionGuard>
      </PageTitle>
      <div className="my-12">
        <LocationGroupingTable />
      </div>
    </div>
  );
}

export { LocationGrouping };
