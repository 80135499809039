import { staffRouterPath } from '@/config/route-paths.config';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { Permission } from '@/types/auth';
import { NavigationItem } from '@/types/navigation';
import { UserSquare, Users03 } from '@untitled-ui/icons-react';

function usePlatformUsersSubnavigation(): NavigationItem[] {
  const { hasPermission } = usePermissions();

  const platformUsersChildren: NavigationItem[] = [];

  if (hasPermission([Permission['organisation:users:list']])) {
    platformUsersChildren.push({
      name: 'Users',
      href: staffRouterPath.PLATFORM_USERS,
      icon: UserSquare,
    });
  }

  if (hasPermission([Permission['organisation:user-groups:retrieve']])) {
    platformUsersChildren.push({
      name: 'User Groups',
      href: staffRouterPath.USER_GROUPS,
      icon: Users03,
    });
  }

  return platformUsersChildren;
}

export { usePlatformUsersSubnavigation };
