import { Box } from '@/components/box';
import {
  Application,
  ApplicationTemplate,
  UpdateApplicationDto,
} from '@admissions-support/types';
import { ArrowRight } from '@untitled-ui/icons-react';
import { FormProvider } from 'react-hook-form';
import {
  ChildAddress,
  ChildMedicalDetails,
  LocalAuthorityDetails,
  LookedAfterStatus,
} from './additional-child-details.element';
import { useAdditionalChildDetailsForm } from './use-additional-child-details.form';

type AdditionalChildDetailsFormProps = {
  onSubmit: (data: UpdateApplicationDto) => Promise<Application>;
  initialData: Application;
  template: ApplicationTemplate;
};

function ParentAdditionalChildDetailsForm(
  props: AdditionalChildDetailsFormProps
) {
  const { initialData, onSubmit, template } = props;
  const { form, handleSubmit, saveAsDraft } = useAdditionalChildDetailsForm({
    initialData,
    onSubmit,
    template,
  });

  const formDisabled = form.formState.isSubmitting;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={saveAsDraft}
        className="space-y-4"
        id="upsert-application-form"
      >
        <Box className="flex flex-col gap-y-4">
          <ChildAddress disabled={formDisabled} />
        </Box>
        {template.enabledQuestions.includes('local-authority-details') && (
          <Box className="flex flex-col gap-y-4">
            <LocalAuthorityDetails disabled={formDisabled} />
          </Box>
        )}
        <Box className="flex flex-col gap-y-4">
          <ChildMedicalDetails disabled={formDisabled} />
        </Box>
        <Box className="flex flex-col gap-y-4">
          <LookedAfterStatus disabled={formDisabled} />
        </Box>
        <button
          type="button"
          className="btn btn-primary ml-auto mt-6 flex items-center"
          onClick={handleSubmit}
          disabled={formDisabled}
        >
          Continue
          <ArrowRight viewBox="0 0 24 24" className="ml-2 h-5 w-5 text-white" />
        </button>
      </form>
    </FormProvider>
  );
}

export { ParentAdditionalChildDetailsForm };
