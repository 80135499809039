import { userKeys } from '@/config/query-keys';
import { userService } from '@/services/user.service';
import { HttpError } from '@/types/error';
import { UsersAndInvites } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useUsers(
  queryOptions?: UseQueryOptions<UsersAndInvites[], HttpError>
) {
  return useQuery({
    queryKey: userKeys.list,
    queryFn: userService.getUsersAndInvites,
    ...queryOptions,
  });
}

export { useUsers };
