import { parentRouterPath } from '@/config/route-paths.config';
import { ArrowRight, FaceId, Home02 } from '@untitled-ui/icons-react';
import { Link, generatePath, useParams } from 'react-router-dom';

function StartApplication() {
  const { childId = '' } = useParams<{ childId: string }>();

  return (
    <>
      <h1 className="mb-8 mt-6 text-xl font-semibold text-gray-900">
        Childcare Application Process
      </h1>

      <p className="text-md mb-2 mt-6 font-semibold text-gray-900">
        Complete Application Form
      </p>
      <p>
        You will be asked to provide details about your child’s household,
        siblings, parents/carers and your placement preferences.
      </p>
      <p className="text-md mb-2 mt-6 font-semibold text-gray-900">
        Updates and Outcomes
      </p>
      <p>
        You will be able to make changes to your application up until the
        application deadline. Once this passes, any changes will need to be
        approved.
      </p>
      <p className="mt-6">
        You will be notified of any decisions related to your application via
        email, and this dashboard.
      </p>

      <div className="light-gray-container mt-8">
        <p className="text-md mb-2 font-semibold text-gray-900">
          During the application process, the following information will be
          required.
        </p>

        <div className="mt-6 flex gap-x-4">
          <div className="self-start rounded-lg border border-gray-200 p-2">
            <FaceId />
          </div>
          <div>
            <p className="text-md mb-2 font-semibold text-gray-900">
              Child’s Passport or Birth Certificate
            </p>

            <p>
              You will need to upload a copy of either document and provide the
              identification number relevant to that document.
            </p>
          </div>
        </div>

        <div className="mt-6 flex gap-x-4">
          <div className="self-start rounded-lg border border-gray-200 p-2">
            <Home02 />
          </div>
          <div>
            <p className="text-md mb-2 font-semibold text-gray-900">
              Proof of Address
            </p>
            <p>
              You will need to provide evidence that you live that the address
              provided. If you are moving to a new address, you will need to
              provide an accepted mortgage offer or concluded missives.
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-3">
        {childId?.length ? (
          <Link
            to={generatePath(parentRouterPath.CHILD_OVERVIEW, {
              id: childId,
            })}
            className="btn btn-secondary mt-8 flex items-center"
          >
            Back to Child Overview
          </Link>
        ) : null}
        <Link
          to={generatePath(parentRouterPath.APPLICATION_ELIGIBILITY, {
            childId,
          })}
          className="btn btn-primary mt-8 flex items-center"
        >
          Continue
          <ArrowRight viewBox="0 0 24 24" className="ml-2 h-5 w-5 text-white" />
        </Link>
      </div>
    </>
  );
}

export { StartApplication };
