import { transformInitialChoiceValue } from '@/utils/application-utils';
import {
  Choice,
  OperatingDaySessionDto,
  Session,
} from '@admissions-support/types';
import { Dispatch, useReducer } from 'react';

const initialSession: OperatingDaySessionDto = {
  monday: null,
  tuesday: null,
  wednesday: null,
  thursday: null,
  friday: null,
  saturday: null,
  sunday: null,
};

type ChoiceState = {
  /**
   * choice loaded from API -> there are properties we need to
   * calculate in order to have the full ChoiceState
   */
  isExisting: boolean;
  schoolYearId: string;
  establishment: string;
  /**
   * The type of the establishment, either 'CHILD_MINDER', 'PARTNER_PROVIDER' or 'COUNCIL'
   */
  establishmentType: string;
  model: string;
  sessionType: string;
  sessionsPattern: OperatingDaySessionDto;
  sessions: Session[];
  additionalSessionType: string;
  additionalSessionsPattern: OperatingDaySessionDto;
  enableSplitPlacement: boolean;
  splitPlacementEstablishmentId: string;
  splitPlacementSessionsPattern: OperatingDaySessionDto;
  sessionsAtSplitPlacement: Session[];
  enableDifferentEstablishmentForAdditionalSessions: boolean;
  additionalSessionsEstablishmentId: string;
};

const initialChoiceForm: ChoiceState = {
  isExisting: false,
  schoolYearId: '',
  establishment: '',
  establishmentType: '',
  model: '',
  sessionType: '',
  sessionsPattern: initialSession,
  sessions: [],
  sessionsAtSplitPlacement: [],
  additionalSessionType: '',
  additionalSessionsPattern: initialSession,
  enableSplitPlacement: false,
  splitPlacementEstablishmentId: '',
  splitPlacementSessionsPattern: initialSession,
  /**
   * User manually enables additional sessions split placement.
   * This remains false, if core is split
   */
  enableDifferentEstablishmentForAdditionalSessions: false,
  additionalSessionsEstablishmentId: '',
};

enum ChoiceFormActionTypes {
  SET_ESTABLISHMENT = 'SET_ESTABLISHMENT',
  SET_ESTABLISHMENT_TYPE = 'SET_ESTABLISHMENT_TYPE',
  SET_MODEL = 'SET_MODEL',
  AUTO_SET_MODEL = 'AUTO_SET_MODEL',
  SET_SESSION_TYPE = 'SET_SESSION_TYPE',
  TOGGLE_SESSION_BY_DAY = 'TOGGLE_SESSION_BY_DAY',
  TOGGLE_ADDITIONAL_SESSION_BY_DAY = 'TOGGLE_ADDITIONAL_SESSION_BY_DAY',
  SET_SESSIONS = 'SET_SESSIONS',
  SET_ADDITIONAL_SESSION_TYPE = 'SET_ADDITIONAL_SESSION_TYPE',
  TOGGLE_SPLIT_PLACEMENT = 'TOGGLE_SPLIT_PLACEMENT',
  SET_SPLIT_PLACEMENT_ESTABLISHMENT = 'SET_SPLIT_PLACEMENT_ESTABLISHMENT',
  SET_SESSIONS_AT_SPLIT_PLACEMENT = 'SET_SESSIONS_AT_SPLIT_PLACEMENT',
  TOGGLE_SPLIT_PLACEMENT_SESSION_BY_DAY = 'TOGGLE_SPLIT_PLACEMENT_SESSION_BY_DAY',
  TOGGLE_DIFFERENT_ESTABLISHMENT_FOR_ADDITIONAL_SESSIONS = 'TOGGLE_DIFFERENT_ESTABLISHMENT_FOR_ADDITIONAL_SESSIONS',
  SET_ADDITIONAL_SESSIONS_ESTABLISHMENT = 'SET_ADDITIONAL_SESSIONS_ESTABLISHMENT',
}

type Action =
  | { type: ChoiceFormActionTypes.SET_ESTABLISHMENT; payload: string }
  | { type: ChoiceFormActionTypes.SET_ESTABLISHMENT_TYPE; payload: string }
  | { type: ChoiceFormActionTypes.SET_MODEL; payload: string }
  | { type: ChoiceFormActionTypes.AUTO_SET_MODEL; payload: string }
  | { type: ChoiceFormActionTypes.SET_SESSION_TYPE; payload: string }
  | {
      type: ChoiceFormActionTypes.TOGGLE_SESSION_BY_DAY;
      payload: {
        day: keyof OperatingDaySessionDto;
        sectionId: string | null;
      };
    }
  | {
      type: ChoiceFormActionTypes.TOGGLE_ADDITIONAL_SESSION_BY_DAY;
      payload: {
        day: string;
        sectionId: string | null;
      };
    }
  | {
      type: ChoiceFormActionTypes.TOGGLE_SPLIT_PLACEMENT_SESSION_BY_DAY;
      payload: {
        day: string;
        sectionId: string | null;
      };
    }
  | {
      type: ChoiceFormActionTypes.SET_SESSIONS;
      payload: Session[];
    }
  | {
      type: ChoiceFormActionTypes.SET_ADDITIONAL_SESSION_TYPE;
      payload: string;
    }
  | {
      type: ChoiceFormActionTypes.TOGGLE_SPLIT_PLACEMENT;
    }
  | {
      type: ChoiceFormActionTypes.SET_SPLIT_PLACEMENT_ESTABLISHMENT;
      /** locationId */
      payload: string;
    }
  | {
      type: ChoiceFormActionTypes.SET_SESSIONS_AT_SPLIT_PLACEMENT;
      payload: Session[];
    }
  | {
      type: ChoiceFormActionTypes.TOGGLE_DIFFERENT_ESTABLISHMENT_FOR_ADDITIONAL_SESSIONS;
    }
  | {
      type: ChoiceFormActionTypes.SET_ADDITIONAL_SESSIONS_ESTABLISHMENT;
      /** locationId */
      payload: string;
    };

type ChoiceReducerDispatch = Dispatch<Action>;
function reducer(state: ChoiceState, action: Action): ChoiceState {
  switch (action.type) {
    case ChoiceFormActionTypes.SET_ESTABLISHMENT:
      return {
        ...initialChoiceForm,
        schoolYearId: state.schoolYearId,
        establishment: action.payload,
        /**
         * if no split placement is selected, additional sessions location
         * is the same as the core sessions location
         */
        additionalSessionsEstablishmentId: action.payload,
      };
    case ChoiceFormActionTypes.SET_ESTABLISHMENT_TYPE:
      return {
        ...state,
        establishmentType: action.payload,
      };
    case ChoiceFormActionTypes.SET_MODEL:
      return {
        ...initialChoiceForm,
        schoolYearId: state.schoolYearId,
        establishment: state.establishment,
        /**
         * if no split placement is selected, additional sessions location
         * is the same as the core sessions location
         */
        additionalSessionsEstablishmentId: state.establishment,
        sessions: state.sessions,
        model: action.payload,
      };
    case ChoiceFormActionTypes.AUTO_SET_MODEL:
      return {
        ...state,
        model: action.payload,
      };
    case ChoiceFormActionTypes.SET_SESSION_TYPE:
      return {
        ...state,
        sessionsPattern: initialSession,
        sessionType: action.payload,
        additionalSessionsPattern: initialSession,
        additionalSessionType: '',
        splitPlacementEstablishmentId: '',
        enableSplitPlacement: false,
        enableDifferentEstablishmentForAdditionalSessions: false,
        sessionsAtSplitPlacement: [],
        splitPlacementSessionsPattern: initialSession,
      };
    case ChoiceFormActionTypes.TOGGLE_SESSION_BY_DAY:
      if (
        state.sessionsPattern[action.payload.day] === action.payload.sectionId
      ) {
        return {
          ...state,
          sessionsPattern: {
            ...state.sessionsPattern,
            [action.payload.day]: null,
          },
        };
      }

      return {
        ...state,
        sessionsPattern: {
          ...state.sessionsPattern,
          [action.payload.day]: action.payload.sectionId,
        },
      };
    case ChoiceFormActionTypes.TOGGLE_ADDITIONAL_SESSION_BY_DAY:
      if (
        state.additionalSessionsPattern[
          action.payload.day as keyof OperatingDaySessionDto
        ] === action.payload.sectionId
      ) {
        return {
          ...state,
          additionalSessionsPattern: {
            ...state.additionalSessionsPattern,
            [action.payload.day]: null,
          },
        };
      }
      return {
        ...state,
        additionalSessionsPattern: {
          ...state.additionalSessionsPattern,
          [action.payload.day]: action.payload.sectionId,
        },
      };
    case ChoiceFormActionTypes.SET_SESSIONS:
      // it means its an existing choice,
      // so we need to calculate some missing properties from sessions
      if (state.isExisting) {
        // We only need to find one session, because all the sessions in a choice
        // share the same model and session type
        let firstSessionIdFromChoice: string | null = null;
        const sessionDays = Object.keys(state.sessionsPattern);

        sessionDays.forEach(dayName => {
          const sessionId =
            state.sessionsPattern[dayName as keyof OperatingDaySessionDto];
          if (sessionId !== null && firstSessionIdFromChoice === null) {
            firstSessionIdFromChoice = sessionId;
          }
        });

        const session = action.payload.find(
          session => session.id === firstSessionIdFromChoice
        );

        // lets do the same with additionalSession as well
        let firstAdditionalSessionIdFromChoice: string | null = null;

        const additionalSessionDays = Object.keys(
          state.additionalSessionsPattern
        );

        additionalSessionDays.forEach(dayName => {
          const additionalSessionId =
            state.additionalSessionsPattern[
              dayName as keyof OperatingDaySessionDto
            ];
          if (
            additionalSessionId !== null &&
            firstAdditionalSessionIdFromChoice === null
          ) {
            firstAdditionalSessionIdFromChoice = additionalSessionId;
          }
        });

        const additionalSession = action.payload.find(
          session => session.id === firstAdditionalSessionIdFromChoice
        );

        if (session) {
          /**
           * If they are different, we need to look up for the additional session type
           * in the sessionsAtSplitPlacement array
           */
          if (state.establishment === state.additionalSessionsEstablishmentId) {
            return {
              ...state,
              sessionType: session.type.id.toString(),
              model: session.model.id.toString(),
              sessions: action.payload,
              additionalSessionType: additionalSession
                ? additionalSession.type.id.toString()
                : '',
            };
          }

          return {
            ...state,
            sessionType: session.type.id.toString(),
            model: session.model.id.toString(),
            sessions: action.payload,
          };
        }
      }

      return {
        ...state,
        sessions: action.payload,
      };
    case ChoiceFormActionTypes.SET_ADDITIONAL_SESSION_TYPE:
      return {
        ...state,
        additionalSessionsPattern: initialSession,
        additionalSessionType: action.payload,
      };
    case ChoiceFormActionTypes.TOGGLE_SPLIT_PLACEMENT: {
      const newSplitPlacement = !state.enableSplitPlacement;

      if (!newSplitPlacement) {
        const canKeepAdditionalSessionSetting =
          state.enableDifferentEstablishmentForAdditionalSessions &&
          state.additionalSessionsEstablishmentId !== state.establishment;

        return {
          ...state,
          enableSplitPlacement: newSplitPlacement,
          splitPlacementEstablishmentId: '',
          splitPlacementSessionsPattern: initialSession,
          /**
           * We need to empty sessionsAtSplitPlacement array
           */
          sessionsAtSplitPlacement: [],
          /**
           * if user already selected a location for additional sessions
           * lets keep it
           */

          enableDifferentEstablishmentForAdditionalSessions:
            canKeepAdditionalSessionSetting,
          additionalSessionsEstablishmentId: canKeepAdditionalSessionSetting
            ? state.additionalSessionsEstablishmentId
            : state.establishment,
        };
      }

      const commonStatePart: Partial<ChoiceState> = {
        enableSplitPlacement: newSplitPlacement,
        splitPlacementEstablishmentId: '',
        splitPlacementSessionsPattern: initialSession,
      };

      /**
       * If additional split placement enabled, you cannot select the same location id
       * for additional session location and core session location.
       */
      if (state.additionalSessionsEstablishmentId === state.establishment) {
        return {
          ...state,
          ...commonStatePart,
        };
      }

      return {
        ...state,
        ...commonStatePart,
        /**
         * only keep additionalSessionsEstablishmentId if its the same as
         * the core location, because we can be sure its going to be available everywhere.
         * But dont let select select the core location,
         * if enableDifferentEstablishmentForAdditionalSessions is true,
         * you cannot select select additionalSessionsEstablishmentId as enableDifferentEstablishmentForAdditionalSessions
         */
        additionalSessionsEstablishmentId: '',
        /**
         * if we reset additionalSessionsEstablishmentId we need to reset this field as well
         */
        additionalSessionType: '',
        /**
         * if we reset additionalSessionsEstablishmentId we need to reset this field as well
         */
        additionalSessionsPattern: initialSession,
      };
    }
    case ChoiceFormActionTypes.SET_SPLIT_PLACEMENT_ESTABLISHMENT:
      return {
        ...state,
        splitPlacementEstablishmentId: action.payload,
        splitPlacementSessionsPattern: initialSession,
      };
    case ChoiceFormActionTypes.SET_SESSIONS_AT_SPLIT_PLACEMENT: {
      /**
       * If user selected additional session type, enabled core split placement
       * and selected the additional session from not the core location (state.establishment)
       * then we didnt set the additionalSessionType in the SET_SESSIONS action.
       */
      let firstAdditionalSessionIdFromChoice: string | null = null;

      const additionalSessionDays = Object.keys(
        state.additionalSessionsPattern
      );

      additionalSessionDays.forEach(dayName => {
        const additionalSessionId =
          state.additionalSessionsPattern[
            dayName as keyof OperatingDaySessionDto
          ];
        if (
          additionalSessionId !== null &&
          firstAdditionalSessionIdFromChoice === null
        ) {
          firstAdditionalSessionIdFromChoice = additionalSessionId;
        }
      });

      const additionalSession = action.payload.find(
        session => session.id === firstAdditionalSessionIdFromChoice
      );

      if (additionalSession) {
        return {
          ...state,
          sessionsAtSplitPlacement: action.payload,
          additionalSessionType: additionalSession.type.id.toString(),
        };
      }

      return {
        ...state,
        sessionsAtSplitPlacement: action.payload,
      };
    }
    case ChoiceFormActionTypes.TOGGLE_SPLIT_PLACEMENT_SESSION_BY_DAY:
      if (
        state.splitPlacementSessionsPattern[
          action.payload.day as keyof OperatingDaySessionDto
        ] === action.payload.sectionId
      ) {
        return {
          ...state,
          splitPlacementSessionsPattern: {
            ...state.splitPlacementSessionsPattern,
            [action.payload.day]: null,
          },
        };
      }
      return {
        ...state,
        splitPlacementSessionsPattern: {
          ...state.splitPlacementSessionsPattern,
          [action.payload.day]: action.payload.sectionId,
        },
      };
    case ChoiceFormActionTypes.TOGGLE_DIFFERENT_ESTABLISHMENT_FOR_ADDITIONAL_SESSIONS: {
      const newEnableDifferentEstablishment =
        !state.enableDifferentEstablishmentForAdditionalSessions;

      if (!newEnableDifferentEstablishment) {
        /**
         * If user did not select a different location for additional location than
         * the core location, do not reset the additionalSessionType
         */
        if (state.additionalSessionsEstablishmentId === state.establishment) {
          return {
            ...state,
            enableDifferentEstablishmentForAdditionalSessions:
              newEnableDifferentEstablishment,
          };
        }

        return {
          ...state,
          enableDifferentEstablishmentForAdditionalSessions:
            newEnableDifferentEstablishment,
          additionalSessionsEstablishmentId: state.establishment,
          additionalSessionType: '',
        };
      }

      /**
       * if no core split placement is enabled, then if user enables additional split placement,
       * the additional split placement location must be different than the core one. Thats why
       * we need to reset the additional sessions state here.
       */
      if (!state.enableSplitPlacement) {
        return {
          ...state,
          enableDifferentEstablishmentForAdditionalSessions:
            newEnableDifferentEstablishment,
          additionalSessionsEstablishmentId: '',
          additionalSessionType: '',
          additionalSessionsPattern: initialSession,
        };
      }

      return {
        ...state,
        enableDifferentEstablishmentForAdditionalSessions:
          newEnableDifferentEstablishment,
      };
    }
    case ChoiceFormActionTypes.SET_ADDITIONAL_SESSIONS_ESTABLISHMENT:
      return {
        ...state,
        additionalSessionsEstablishmentId: action.payload,
        additionalSessionsPattern: initialSession,
      };
    default:
      return state;
  }
}

function useChoiceFormReducer(
  schoolYearId: string,
  choice?: Choice
): [ChoiceState, ChoiceReducerDispatch] {
  return useReducer(
    reducer,
    { schoolYearId, choice },
    transformInitialChoiceValue
  );
}

export {
  ChoiceFormActionTypes,
  reducer as choiceReducer,
  initialChoiceForm,
  initialSession,
  useChoiceFormReducer,
};
export type { Action as ChoiceFormAction, ChoiceReducerDispatch, ChoiceState };
