import { useState } from 'react';

export interface UseModalResult {
  isOpen: boolean;
  toggle: () => void;
  openModal: () => void;
  closeModal: () => void;
}

function useModal(defaultOpen?: boolean): UseModalResult {
  const [open, setOpen] = useState(defaultOpen || false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return {
    isOpen: open,
    toggle: toggleOpen,
    openModal: handleOpen,
    closeModal: handleClose,
  };
}

export { useModal };
