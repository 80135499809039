import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Alert } from '../alert';
import { TextInput } from './common/text-input';
import { PasswordRules } from '../password-rules';

const schema = yup
  .object({
    password: yup
      .string()
      .min(12, 'Password must be at least 12 characters long')
      .matches(/^(?=.*[0-9])/, 'Password must contain at least 1 number')
      // https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
      .matches(
        /^(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])/,
        'Password must contain at least 1 special character'
      )
      .matches(
        /^(?=.*[A-Z])/,
        'Password must contain at least 1 uppercase letter'
      )
      .matches(
        /^(?=.*[a-z])/,
        'Password must contain at least 1 lowercase letter'
      )
      .required(),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref('password'), ''], 'Passwords must match')
      .required('Confirm Password is required'),
  })
  .required();

interface ResetPasswordDto {
  password: string;
  passwordConfirm: string;
}

interface ResetPasswordFormProps {
  onResetPassword: (data: ResetPasswordDto) => Promise<void>;
}

function ResetPasswordForm(ResetPasswordFormProps: ResetPasswordFormProps) {
  const { onResetPassword } = ResetPasswordFormProps;
  const [hasError, setHasError] = useState(false);
  const form = useForm<ResetPasswordDto>({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      passwordConfirm: '',
    },
  });

  const onSubmit = async (data: ResetPasswordDto) => {
    setHasError(false);
    await onResetPassword(data);
  };

  const password = form.watch('password');

  const isSubmitting = form.formState.isSubmitting;

  return (
    <div className="mt-8">
      {hasError ? (
        <Alert
          type="error"
          text="There was a problem with your password reset."
          className="mb-4"
        />
      ) : null}
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
          <TextInput
            name="password"
            label="New Password*"
            type="password"
            placeholder="Create a password"
            disabled={isSubmitting}
          />
          <TextInput
            name="passwordConfirm"
            label="Confirm Password*"
            type="password"
            placeholder="Create a password"
            disabled={isSubmitting}
          />
          <PasswordRules password={password} schema={schema} />
          <button
            className="btn btn-primary btn-full"
            disabled={isSubmitting || !form.formState.isValid}
          >
            Set New Password
          </button>
        </form>
      </FormProvider>
    </div>
  );
}

export { ResetPasswordForm };
export type { ResetPasswordDto };
