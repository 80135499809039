import { locationKey } from '@/config/query-keys';
import { locationService } from '@/services/location.service';
import { HttpError } from '@/types/error';
import { Location, UpdateLocationDto } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateLocationMutation(
  mutationOptions?: UseMutationOptions<
    Location,
    HttpError,
    { id: string; data: UpdateLocationDto }
  >
) {
  return useMutation({
    mutationKey: locationKey.update,
    mutationFn: locationService.updateLocation,
    ...mutationOptions,
  });
}

export { useUpdateLocationMutation };
