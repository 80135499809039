import {
  AuthenticatorDto,
  AuthenticatorForm,
} from '@/components/form/authenticator.form';
import { LoginTitle } from '@/components/login-title';
import { useAuth } from '@/context/auth.context';
import { VerifyRouterState } from '@/types/auth';
import { ArrowNarrowLeft } from '@untitled-ui/icons-react';
import { Helmet } from 'react-helmet-async';
import { Navigate } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';

function Verify() {
  const navigate = useNavigate();
  const { confirmSignIn, reset } = useAuth();
  const location = useLocation();
  const routeState = location.state as VerifyRouterState | undefined;

  if (!routeState || !routeState.type) {
    return <Navigate to="/auth/sign-in" replace />;
  }

  const isSMS = routeState.type === 'SMS';

  const handleTOTP = async (authenticatorDto: AuthenticatorDto) => {
    await confirmSignIn(authenticatorDto);
  };

  const handleBackToLogin = () => {
    reset();
    navigate('/auth/sign-in');
  };

  return (
    <>
      <Helmet>
        <title>Verify your login</title>
      </Helmet>

      <div>
        {isSMS ? (
          <LoginTitle
            title="Enter the Access code"
            description={`We sent a code to your mobile ending ${routeState.codeDeliveryDetails.destination}`}
          />
        ) : (
          <LoginTitle
            title="Enter the Access code"
            description="This is the code that is shown on your authentication app."
          />
        )}
        {isSMS ? (
          <p className="mt-3">It may take a few minutes to arrive.</p>
        ) : null}
        <AuthenticatorForm onVerify={handleTOTP} />
        <button
          type="button"
          onClick={handleBackToLogin}
          className="link mt-20 flex"
        >
          <ArrowNarrowLeft className="mr-3" /> Back to login
        </button>
      </div>
    </>
  );
}

export { Verify };
