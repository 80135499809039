import { TextInput } from '@/components/form/common/text-input';
import { Dropzone } from '@/components/form/common/dropzone';
import { useFormContext } from 'react-hook-form';
import { paths } from '@/config/upload-paths';

type BrandDetailsSectionProps = {
  isDisabled: boolean;
};

function BrandDetailsSection(props: BrandDetailsSectionProps) {
  const { isDisabled } = props;
  const { watch } = useFormContext();

  const logo = watch('logo');
  const icon = watch('icon');

  return (
    <div className="two-col-form gap-6 py-6">
      <div>
        <p className="font-semibold text-gray-700">Brand Details</p>{' '}
        <p className="text-gray-600">
          Upload your brand logo and define your brand accent colour.
        </p>
      </div>
      <div className="col-span-2 space-y-6">
        <TextInput
          name="brandColour"
          label="Brand Accent Colour"
          placeholder="#F2C05C"
          disabled={isDisabled}
        />
        <Dropzone
          name="logo"
          label="Organisation Logo"
          path={paths.public}
          imageSizeRestriction={{ width: 800, height: 400 }}
          options={{
            accept: {
              'image/jpeg': [],
              'image/png': [],
            },
            multiple: false,
            disabled: isDisabled,
          }}
          disableFileUpload={logo.length > 0}
          fileRestrictionLabel="PNG, JPG (max. 800x400px)"
        />
        <Dropzone
          name="icon"
          label="Organisation Icon"
          path={paths.public}
          imageSizeRestriction={{ width: 600, height: 600 }}
          options={{
            accept: {
              'image/jpeg': [],
              'image/png': [],
            },
            multiple: false,
            disabled: isDisabled,
          }}
          disableFileUpload={icon.length > 0}
          fileRestrictionLabel="PNG, JPG (max. 600x600px)"
          helperText="Should be square, this is used as Favicon and within menu’s"
        />
      </div>
    </div>
  );
}

export { BrandDetailsSection };
