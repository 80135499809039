import { isFunction } from '@tanstack/react-table';
import { getUrl, uploadData, GetUrlWithPathOutput } from 'aws-amplify/storage';

type S3File = {
  filename: string;
  key: string;
  reference: string;
};

async function uploadFile({
  fileName,
  file,
  path,
  handleProgress,
  bucket,
}: {
  fileName: string;
  file: File;
  path: string;
  handleProgress?: (progress: number) => void;
  bucket?: string;
}): Promise<S3File> {
  const uniquePrefix = Math.floor(Date.now() + Math.random());

  const response = await uploadData({
    path: `${path}/${uniquePrefix}_${fileName}`,
    data: file,
    options: {
      contentType: file.type,
      onProgress: progress => {
        if (isFunction(handleProgress)) {
          handleProgress(
            (progress.transferredBytes / (progress.totalBytes || 1)) * 100
          );
        }
      },
      ...(bucket ? { bucket } : {}),
    },
  }).result;

  return {
    filename: fileName,
    key: response.path,
    reference: `${path}/${response.path}`,
  };
}

async function downloadFile({
  path,
  bucket,
}: {
  path: string;
  bucket?: string;
}): Promise<GetUrlWithPathOutput> {
  const signedUrl = await getUrl({
    path,
    ...(bucket
      ? {
          options: {
            bucket: {
              bucketName: bucket || '',
              region: import.meta.env.VITE_AWS_REGION,
            },
          },
        }
      : {}),
  });

  return signedUrl;
}

export const fileService = Object.freeze({
  uploadFile,
  downloadFile,
});

export type { S3File };
