import { Cell, Label, Pie, PieChart } from 'recharts';

const renderCustomLabel = ({
  viewBox,
  value,
}: {
  viewBox?: any;
  value: string;
}) => {
  if (!viewBox) {
    return null;
  }

  const { cx, cy } = viewBox;
  return (
    <g>
      <text
        x={cx}
        y={cy - 4}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={12}
        className="fill-gray-600"
      >
        Total
      </text>
      <text
        x={cx}
        y={cy + 12}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={16}
        className="fill-gray-900 font-mono"
      >
        {value}
      </text>
    </g>
  );
};

export type ChartItem = {
  name: string;
  color: string;
  value: number;
};

type ChartProps = {
  data: ChartItem[];
  title: string;
  description: string;
  total: number;
};

function AllocationPieChart({ data, title, description, total }: ChartProps) {
  return (
    <div className="flex flex-col items-center">
      <h2 className="text- text-xs font-medium text-gray-900">{title}</h2>
      <span className="text-xs font-medium text-gray-600">{description}</span>
      <PieChart width={260} height={260}>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={75}
          outerRadius={100}
          fill="#82ca9d"
        >
          {data.map((e, index) => (
            <Cell key={`cell-${index}`} fill={e.color} />
          ))}
          <Label
            content={({ viewBox }) =>
              renderCustomLabel({ viewBox, value: total.toString() })
            }
            position="center"
          />
        </Pie>
      </PieChart>
    </div>
  );
}

export { AllocationPieChart };
