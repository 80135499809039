import { applicationKey } from '@/config/query-keys';
import { useSchoolYear } from '@/context/school-year.context';
import { applicationService } from '@/services/application.service';
import { ApplicationFilter } from '@/types/application-filter';
import { HttpError } from '@/types/error';
import { PaginateResult } from '@/types/pagination';
import { ApplicationListing } from '@admissions-support/types';
import {
  UseQueryOptions,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import { PaginationState, SortingState } from '@tanstack/react-table';

function useApplications(
  pagination: PaginationState,
  sorting: SortingState,
  filter: ApplicationFilter,
  queryOptions?: Partial<
    UseQueryOptions<PaginateResult<ApplicationListing>, HttpError>
  >
) {
  const { schoolYear } = useSchoolYear();

  return useQuery({
    queryKey: applicationKey.list(schoolYear.id, pagination, sorting, filter),
    queryFn: () =>
      applicationService.listApplications(schoolYear.id, {
        pagination,
        sorting,
        filter,
      }),
    ...queryOptions,
    placeholderData: keepPreviousData,
  });
}

export { useApplications };
