import { Alert } from '@/components/alert';
import { RadioBoxBase } from '@/components/form/common/radio-box';
import { useChoicesContext } from '@/context/choices.context';
import { ChoiceFormActionTypes } from '@/reducers/choice.reducer';
import {
  getSessionDayDetailsForDay,
  selectAvailableSplitSessions,
  selectNbOfSelectedSessions,
} from '@/utils/application-utils';
import {
  getOperatingDaysOfSessionType,
  getStartEndForDay,
} from '@/utils/location-session-utils';
import { SessionType, OperatingDaySessionDto } from '@admissions-support/types';
import { SessionRadioBoxOption } from '../../session-radio-box-option';

type SplitPlacementSessionsProps = {
  selectedSessionType: SessionType;
  disabled?: boolean;
};

function SplitPlacementSessions(props: SplitPlacementSessionsProps) {
  const { selectedSessionType, disabled } = props;
  const { state } = useChoicesContext();
  const daysForSessionType = getOperatingDaysOfSessionType(selectedSessionType);
  const availableSessions = selectAvailableSplitSessions(state);

  const allowance = selectedSessionType?.allowance;

  if (!state.splitPlacementEstablishmentId) {
    return null;
  }

  if (availableSessions.length === 0) {
    return (
      <Alert
        type="warning"
        text="No sessions of the selected type are available at this location."
        className="mt-4"
      />
    );
  }

  return (
    <div className="mt-4 space-y-4">
      {daysForSessionType.map(day => (
        <DayRow key={day} day={day} allowance={allowance} disabled={disabled} />
      ))}
    </div>
  );
}

function DayRow(props: { day: string; allowance: number; disabled?: boolean }) {
  const { day, allowance, disabled } = props;
  const { state, dispatch } = useChoicesContext();
  const availableSessions = selectAvailableSplitSessions(state);
  const nbOfSelectedSessions = selectNbOfSelectedSessions(state);

  const sessionsToDisplay = getSessionDayDetailsForDay(availableSessions, day);

  const dayValue =
    state.splitPlacementSessionsPattern[day as keyof OperatingDaySessionDto];

  /**
   * disable the ablility to select the session if we used all our allowance
   * and the session is unselected
   */
  const isButtonDisabled =
    allowance === nbOfSelectedSessions && dayValue === null;

  const handleSessionDeSelect = (day: string) => () => {
    dispatch({
      type: ChoiceFormActionTypes.TOGGLE_SPLIT_PLACEMENT_SESSION_BY_DAY,
      payload: {
        day,
        sectionId: null,
      },
    });
  };

  const handleSessionSelect = (data: { day: string; sectionId: string }) => {
    dispatch({
      type: ChoiceFormActionTypes.TOGGLE_SPLIT_PLACEMENT_SESSION_BY_DAY,
      payload: data,
    });
  };

  const options = sessionsToDisplay.map(session => {
    const times = getStartEndForDay(session.details);

    return {
      value: session.sessionId,
      Content: () => (
        <SessionRadioBoxOption
          className={isButtonDisabled ? 'show-error' : undefined}
          start={times.start}
          end={times.end}
        />
      ),
      disabled: isButtonDisabled,
    };
  });

  return (
    <div key={day}>
      <p className="label capitalize">{day}</p>
      <RadioBoxBase
        value={dayValue ? dayValue : ''}
        containerClassName="grid gap-4 grid-cols-1 md:grid-cols-2 2xl:grid-cols-3"
        onChange={value => {
          // dont let select more session if we reached the limit
          // but let to change the actual row
          if (isButtonDisabled) {
            return;
          }

          handleSessionSelect({ day, sectionId: value });
        }}
        onActiveClick={handleSessionDeSelect(day)}
        options={options}
        disabled={disabled}
      />
    </div>
  );
}

export { SplitPlacementSessions };
