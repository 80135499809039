import { applicationTemplateKey } from '@/config/query-keys';
import { applicationTemplateService } from '@/services/application-template.service';
import { HttpError } from '@/types/error';
import {
  ApplicationTemplate,
  CreateApplicationTemplateAdditionalQuestionDto,
} from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCreateAdditionalQuestionMutation(
  mutationOptions?: UseMutationOptions<
    ApplicationTemplate,
    HttpError,
    {
      templateId: string;
      data: CreateApplicationTemplateAdditionalQuestionDto;
    }
  >
) {
  return useMutation({
    mutationKey: applicationTemplateKey.createQuestion,
    mutationFn: applicationTemplateService.createQuestion,
    ...mutationOptions,
  });
}

export { useCreateAdditionalQuestionMutation };
