import { authKeys } from '@/config/query-keys';
import { useAuth } from '@/context/auth.context';
import { useQuery } from '@tanstack/react-query';

function useTotpCode() {
  const { isLoading, getTotpCode } = useAuth();

  return useQuery({
    queryKey: authKeys.totp,
    queryFn: getTotpCode,
    retry: false,
    enabled: !isLoading,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}

export { useTotpCode };
