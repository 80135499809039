import { reportKey } from '@/config/query-keys';
import { reportService } from '@/services/report.service';
import { HttpError } from '@/types/error';
import {
  CapacityReportQueryParamsDto,
  CapacityReports,
} from '@admissions-support/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

function useCapacityReport(
  data: CapacityReportQueryParamsDto,
  queryOptions?: Partial<UseQueryOptions<CapacityReports, HttpError>>
) {
  return useQuery({
    queryKey: reportKey.capacity(data),
    queryFn: () => reportService.getCapacityReport(data),
    ...queryOptions,
  });
}

function useCapacityReportCsv(
  data: CapacityReportQueryParamsDto,
  queryOptions?: Partial<UseQueryOptions<Blob, HttpError>>
) {
  return useQuery({
    queryKey: reportKey.capacityCsv(data),
    queryFn: () =>
      reportService.getCapacityReportCsv({
        ...data,
        isCsvExport: true,
      }),
    ...queryOptions,
  });
}

export { useCapacityReport, useCapacityReportCsv };
