function SingleItem(props: { content: React.ReactNode }) {
  const { content } = props;
  return (
    <div className="rounded-lg border border-gray-200 bg-gray-50 px-4 py-3">
      {content}
    </div>
  );
}

export { SingleItem };
