import { applicationTemplateKey } from '@/config/query-keys';
import { applicationTemplateService } from '@/services/application-template.service';
import { HttpError } from '@/types/error';
import {
  ApplicationTemplate,
  UpdateApplicationTemplateDto,
} from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateApplicationTemplateMutation(
  mutationOptions?: UseMutationOptions<
    ApplicationTemplate,
    HttpError,
    { id: string; data: UpdateApplicationTemplateDto }
  >
) {
  return useMutation({
    mutationKey: applicationTemplateKey.update,
    mutationFn: applicationTemplateService.update,
    ...mutationOptions,
  });
}

export { useUpdateApplicationTemplateMutation };
