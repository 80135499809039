import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { GetAllocationReportStat } from '@admissions-support/types';
import { Table } from '@/components/table';

type PercentageFields =
  | 'choice_1_percentage'
  | 'choice_2_percentage'
  | 'choice_3_percentage'
  | 'choice_prescribed_percentage'
  | 'choice_none_percentage';
type TableFields = Omit<GetAllocationReportStat, PercentageFields>;

type AllocationTableProps = {
  tableName: string;
  data: GetAllocationReportStat;
};

type TableDataProps = {
  text: string | number;
};
function TableData({ text }: TableDataProps) {
  return <span className="font-mono text-sm text-gray-600">{text}</span>;
}

export function AllocationTable({ tableName, data }: AllocationTableProps) {
  const columnHelper = createColumnHelper<TableFields>();
  const columns = useMemo(
    () => [
      {
        id: 'titleColumn',
        cell: (info: { row: { index: number } }) => {
          const rowIndex = info.row.index;
          const value = rowIndex === 0 ? 'Applications' : '% of Applications';
          return <span className="text-sm text-gray-600">{value}</span>;
        },
        header: () => (
          <span className="text-xs text-gray-600">{tableName}</span>
        ),
        enableSorting: false,
      },
      columnHelper.accessor('choice_1', {
        id: '1stChoice',
        cell: info => {
          const choiceAmount = info.getValue();
          return <TableData text={choiceAmount} />;
        },
        header: () => <span className="text-xs text-gray-600">1st Choice</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('choice_2', {
        id: '2ndChoice',
        cell: info => {
          const choiceAmount = info.getValue();
          return <TableData text={choiceAmount} />;
        },
        header: () => <span className="text-xs text-gray-600">2nd Choice</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('choice_3', {
        id: '3rdChoice',
        cell: info => {
          const choiceAmount = info.getValue();
          return <TableData text={choiceAmount} />;
        },
        header: () => <span className="text-xs text-gray-600">3rd Choice</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('choice_prescribed', {
        id: 'Prescribed',
        cell: info => {
          const choiceAmount = info.getValue();
          return <TableData text={choiceAmount} />;
        },
        header: () => <span className="text-xs text-gray-600">Prescribed</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('choice_none', {
        id: 'None',
        cell: info => {
          const choiceAmount = info.getValue();
          return <TableData text={choiceAmount} />;
        },
        header: () => <span className="text-xs text-gray-600">None</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('total', {
        id: 'All',
        cell: info => {
          const choiceAmount = info.getValue();
          return <TableData text={choiceAmount} />;
        },
        header: () => <span className="text-xs text-gray-600">All</span>,
        enableSorting: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tableData = [
    {
      choice_1: data.choice_1,
      choice_2: data.choice_2,
      choice_3: data.choice_3,
      choice_prescribed: data.choice_prescribed,
      choice_none: data.choice_none,
      total: data.total,
    },
    {
      choice_1: data.choice_1_percentage,
      choice_2: data.choice_2_percentage,
      choice_3: data.choice_3_percentage,
      choice_prescribed: data.choice_prescribed_percentage,
      choice_none: data.choice_none_percentage,
      total: 100,
    },
  ];

  return <Table data={tableData} columns={columns} />;
}
