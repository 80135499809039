import { useAuth } from '@/context/auth.context';
import { HttpError } from '@/types/error';
import { ConfirmParentAccountDto } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useConfirmParentAccount(
  mutationOptions?: UseMutationOptions<void, HttpError, ConfirmParentAccountDto>
) {
  const { confirmParentAccount } = useAuth();

  return useMutation({
    mutationFn: confirmParentAccount,
    ...mutationOptions,
  });
}

export { useConfirmParentAccount };
