import { Table } from '@/components/table';
import { useProduct } from '@/hooks/query-hooks/use-product';
import { useModal } from '@/hooks/use-modal';
import { ProductWithCapacity } from '@/types/extra-hours';
import { formatCurrency } from '@/utils/format-currency';
import { createColumnHelper } from '@tanstack/react-table';
import { isNumber } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { ManageProductModal } from '../manage-product-modal';

type ProductsTableProps = {
  products: ProductWithCapacity[];
};

function ExtraHourProductsTable(props: ProductsTableProps) {
  const { products } = props;

  const [selectedProductId, setSelectedProductId] = useState<
    string | undefined
  >(undefined);

  const { isOpen, openModal, closeModal } = useModal();

  const { data: product, isFetching: isProductFetching } = useProduct(
    selectedProductId || '',
    {
      enabled: Boolean(selectedProductId),
    }
  );

  const columnHelper = createColumnHelper<ProductWithCapacity>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'productName',
        cell: info => {
          const productName = info.getValue();
          return <span className="text-sm text-gray-600">{productName}</span>;
        },
        header: () => <span className="text-xs text-gray-600">Product</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('capacities', {
        id: 'capacity',
        cell: info => {
          if (info.row.original.capacities.length < 1) {
            return (
              <span className="text-sm text-gray-600">No capacity set</span>
            );
          }

          const capacityItems = info.row.original.capacities.map(
            capacityItem => ({
              name: capacityItem.ratio.name,
              used: capacityItem.allocatedCapacity,
              total: isNumber(capacityItem.capacity)
                ? capacityItem.capacity
                : '∞',
              text: isNumber(capacityItem.capacity)
                ? `${capacityItem.capacity - capacityItem.allocatedCapacity}/${
                    capacityItem.capacity
                  }`
                : `${capacityItem.allocatedCapacity}/∞`,
            })
          );

          return capacityItems.map(c => (
            <p
              key={c.name}
              className="flex justify-between hover:cursor-pointer"
            >
              <span className="text-sm text-gray-700">{c.name}</span>
              <span
                className="text-sm text-gray-600"
                data-tooltip-id="capacity-tooltip"
                data-tooltip-content={`Booked: ${c.used} Total: ${c.total}`}
              >
                {c.text}
              </span>
            </p>
          ));
        },
        header: () => <span className="text-xs text-gray-600">Capacity</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('price', {
        id: 'price',
        cell: info => {
          return (
            <span className="font-mono text-sm text-gray-600">
              {formatCurrency(Number(info.getValue()))}
            </span>
          );
        },
        header: () => <span className="text-xs text-gray-600">Cost</span>,
        enableSorting: false,
      }),
      columnHelper.display({
        id: 'actions',
        cell: info => {
          const productId = info.row.original.id;
          return (
            <button
              className="text-sm font-semibold text-primary-700"
              onClick={() => {
                setSelectedProductId(productId);
                openModal();
              }}
              disabled={isProductFetching}
              type="button"
            >
              Manage Product
            </button>
          );
        },
        header: () => <span className="text-xs text-gray-600">Actions</span>,
        enableSorting: false,
        size: 160,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (products.length < 1) {
    return <p className="mt-3">There aren&apos;t any products for this day</p>;
  }

  return (
    <>
      <Table columns={columns} data={products} />
      <Tooltip id="capacity-tooltip" className="tooltip" place="top-end" />
      <ManageProductModal
        isOpen={isOpen && Boolean(product)}
        onClose={closeModal}
        initialProduct={product}
      />
    </>
  );
}

export { ExtraHourProductsTable };
