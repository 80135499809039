import { Dialog, Transition } from '@headlessui/react';
import { XClose } from '@untitled-ui/icons-react';
import { Fragment } from 'react';
import { Alert } from '../alert';

type SuccessfulBookingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  nbOfBookedExtraHours: number;
};

function SuccessfulBookingModal(props: SuccessfulBookingModalProps) {
  const { isOpen, onClose, nbOfBookedExtraHours } = props;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-60" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative mx-10 transform overflow-auto rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-xl">
                <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-5">
                  <div className="pb-5">
                    <Dialog.Title
                      as="h3"
                      className="flex items-center justify-between text-base font-semibold leading-6 text-gray-900"
                    >
                      Booking Successful
                      <button onClick={onClose} type="button">
                        <XClose className="text-gray-400 hover:text-gray-600" />
                      </button>
                    </Dialog.Title>
                  </div>
                  <Alert
                    type="success"
                    text={`You have successfully booked ${nbOfBookedExtraHours} Session${
                      nbOfBookedExtraHours > 1 ? 's' : ''
                    }`}
                    className="mb-6"
                  />
                  <p className="text-md mb-6 text-gray-600">
                    These sessions will now appear on your dashboard. Please
                    note, payments must be made within 48h of the booked
                    session. Sessions can be cancelled up to 4 weeks in advance
                    of the session
                  </p>

                  <button
                    onClick={onClose}
                    type="button"
                    className="btn btn-primary btn-full"
                  >
                    Done
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { SuccessfulBookingModal };
