import { renderItem } from '@/components/sidebar';
import { NavigationItem } from '@/types/navigation';

type ParentsApplicationNavigationProps = {
  navigation: NavigationItem[];
};

function ParentsApplicationNavigation(
  props: ParentsApplicationNavigationProps
) {
  const { navigation } = props;

  return (
    <nav className="flex flex-1 flex-col" aria-label="Sidebar">
      <ul className="-mx-2 space-y-1">
        {navigation.map(item => renderItem(item))}
      </ul>
    </nav>
  );
}

export { ParentsApplicationNavigation };
