import { useSessions } from '@/hooks/query-hooks/use-sessions';
import { getFirstSessionId } from '@/utils/application-utils';
import { Choice } from '@admissions-support/types';
import { Spinner } from '../spinner';

type Props = {
  choice: Choice;
  schoolYearId: string;
};

function SessionTypeCell(props: Props) {
  const { choice, schoolYearId } = props;

  const {
    isLoading: isSessionDataLoading,
    data: sessions,
    isError,
  } = useSessions(choice.location.id.toString(), schoolYearId);

  if (isSessionDataLoading) {
    return <Spinner className="h-5" />;
  }

  if (!sessions || isError) {
    return <span>Invalid session data</span>;
  }

  // you can have different sessions for each day. however there are common properties,
  // which must be the same for each selected session; session-type, model
  // we find the first non-null session on the attendance pattern to figure out those fields
  const firstSessionId = getFirstSessionId(choice);
  const session = sessions.find(
    session => session.id.toString() === firstSessionId
  );

  if (!session) {
    return <span>Invalid session data</span>;
  }

  return <span>{session?.type.name}</span>;
}

export { SessionTypeCell };
