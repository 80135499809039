import { ApplicationStatusTag } from '@/components/application/application-status-tag';
import { Box } from '@/components/box';
import { parentRouterPath } from '@/config/route-paths.config';
import { formatDateToShortDate } from '@/utils/format-date';
import { Application } from '@admissions-support/types';
import { useState } from 'react';
import { Link, generatePath, useParams } from 'react-router-dom';
import { ChildApplicationStatusTag } from '../child-application-status-tag';
import { PlacementDetailsModal } from '../placement-details-modal';
import { ChildApplicationsTable } from './child-applications-table';
import { isProd } from '@/utils/env-utils';

type ChildApplicationsSectionProps = {
  applications: Application[];
};

function ChildApplicationsSection(props: ChildApplicationsSectionProps) {
  const { id } = useParams<{ id: string }>();
  const { applications } = props;

  const [modalApplicationId, setModalApplicationId] = useState('');

  const handleClickViewPlacementDetails = (applicationId: string) => () => {
    setModalApplicationId(applicationId);
  };

  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex items-center justify-between">
        <div>
          <div className="flex items-center gap-x-4">
            <p className="text-lg font-semibold text-gray-900">Applications</p>
            {applications?.length === 0 ? (
              <ApplicationStatusTag status="PLACEMENT_REJECTED">
                No Applications
              </ApplicationStatusTag>
            ) : null}
          </div>
        </div>
        {!isProd() && (
          <Link
            className="btn btn-primary"
            to={generatePath(parentRouterPath.APPLICATION_START, {
              childId: id || '',
            })}
          >
            New Application
          </Link>
        )}
      </div>

      {applications?.map(application => {
        const isEditApplication =
          application.status === 'SUBMITTED' || application.status === 'DRAFT';
        const isNoChoices = application.choices.length === 0;

        const isViewPlacement = application.choices.some(
          choice => choice.status === 'PLACED' || choice.status === 'GRANTED'
        );

        return (
          <Box key={application.id} className="bg-gray-50">
            <div className="pb-4">
              <div className="flex flex-col items-start justify-between gap-4 sm:flex-row sm:items-center">
                <div className="space-y-4 md:space-y-0">
                  <div className="flex flex-col items-start gap-4 md:flex-row md:items-center">
                    <p className="text-lg font-semibold text-gray-900">
                      {application.schoolYear.name}
                    </p>
                    <ChildApplicationStatusTag status={application.status} />
                  </div>
                  <p>{`Last Updated ${formatDateToShortDate(
                    application.updatedAt
                  )}`}</p>
                </div>
                {isViewPlacement ? (
                  <button
                    className="btn btn-secondary"
                    onClick={handleClickViewPlacementDetails(application.id)}
                  >
                    View Placement Details
                  </button>
                ) : null}

                {isEditApplication && !isProd() && (
                  <Link
                    className="btn btn-secondary"
                    to={generatePath(
                      parentRouterPath.APPLICATION_CHILD_DETAILS,
                      { id: application.id }
                    )}
                  >
                    Edit application
                  </Link>
                )}
              </div>
            </div>
            {/** TODO: add alert if the deadline has past (currently we don't have a concept for deadlines) */}
            {!isNoChoices ? (
              <ChildApplicationsTable application={application} />
            ) : null}
            <PlacementDetailsModal
              application={application}
              isOpen={application.id === modalApplicationId}
              onClose={() => setModalApplicationId('')}
            />
          </Box>
        );
      })}
    </div>
  );
}

export { ChildApplicationsSection };
