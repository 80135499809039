import { useModal } from '@/hooks/use-modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Alert } from './alert';
import { TextInputBase } from './form/common/text-input';
import { ScrollableModal } from './scroll-modal';
import { Spinner } from './spinner';

type DeleteZoneProps = {
  title: string;
  onDelete: () => any;
  actionButtonText: string;
  modalDescription?: string;
  description?: string;
  error?: string;
  isLoading?: boolean;
};

const schema = yup.object({
  confirm: yup
    .string()
    .required()
    .oneOf(['DELETE'], 'Value must be exactly "DELETE".'),
});

function DeleteZoneModal(props: {
  onClose: () => any;
  onSubmit: () => Promise<any>;
  isOpen: boolean;
  description?: string;
  error?: string;
  isLoading?: boolean;
}) {
  const { onClose, onSubmit, isOpen, isLoading, description, error } = props;
  const hasError = Boolean(error);
  const cancelButtonRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      confirm: '',
    },
  });

  const handleClose = () => {
    form.reset({ confirm: '' });
    onClose();
  };

  return (
    <ScrollableModal onClose={onClose} isOpen={isOpen} size="md">
      <ScrollableModal.Header onClose={onClose}>
        <ScrollableModal.Title
          title="Delete Confirmation"
          description={description}
        />
      </ScrollableModal.Header>
      <ScrollableModal.Content>
        {isLoading && (
          <div className="absolute inset-0 z-10 flex h-full w-full items-center justify-center bg-white/80">
            <Spinner className="h-10 w-10 text-white" />
          </div>
        )}
        <TextInputBase
          name="confirm"
          type="text"
          label="Type Delete"
          placeholder="DELETE"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          error={
            inputValue.length > 0 && inputValue !== 'DELETE'
              ? 'Value must be exactly "DELETE".'
              : undefined
          }
        />
        {hasError ? (
          <Alert
            type="error"
            text={
              <>
                <b>Error during the action</b>
                <p>{error}</p>
              </>
            }
            className="mt-5"
          />
        ) : null}
      </ScrollableModal.Content>
      <ScrollableModal.Footer>
        <div className="flex w-full gap-3">
          <button
            type="button"
            className="btn btn-error w-full"
            disabled={isLoading || inputValue !== 'DELETE'}
            onClick={onSubmit}
          >
            Confirm Delete
          </button>
          <button
            type="button"
            className="btn btn-secondary w-full"
            onClick={handleClose}
            ref={cancelButtonRef}
            disabled={isLoading}
          >
            Cancel
          </button>
        </div>
      </ScrollableModal.Footer>
    </ScrollableModal>
  );
}

function DeleteZone(props: DeleteZoneProps) {
  const {
    onDelete,
    title,
    description,
    actionButtonText,
    error,
    isLoading,
    modalDescription,
  } = props;
  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  return (
    <>
      <DeleteZoneModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        isLoading={isLoading}
        onSubmit={onDelete}
        error={error}
        description={modalDescription}
      />
      <div className="rounded-xl border border-red-200 bg-red-50 p-4">
        <p className="text-lg font-medium leading-7 text-gray-900">{title}</p>
        {description ? (
          <p className="text-sm leading-5">{description}</p>
        ) : null}
        <button
          type="button"
          className="btn btn-error mt-6"
          onClick={openDeleteModal}
        >
          {actionButtonText}
        </button>
      </div>
    </>
  );
}

export { DeleteZone };
