import { useLeaveModal } from '@/hooks/use-leave-modal';
import { AdditionalQuestion } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { additionalQuestionSchema } from './additional-question.schema';
import { AdditionalQuestionFormData } from './additional-question.type';

type UseAdditionalQuestionFormProps = {
  initialData?: AdditionalQuestion;
};

function transformApiDataToFormData(
  initialData?: AdditionalQuestion
): AdditionalQuestionFormData {
  if (!initialData) {
    return {
      type: '',
      label: '',
      options: [],
    };
  }

  return {
    type: initialData.type,
    label: initialData.label,
    options:
      initialData.options.map(item => ({ label: item, value: item })) || [],
  };
}

function useAdditionalQuestionForm(props?: UseAdditionalQuestionFormProps) {
  const defaultValues: AdditionalQuestionFormData = transformApiDataToFormData(
    props?.initialData
  );

  const form = useForm({
    resolver: yupResolver(additionalQuestionSchema),
    defaultValues,
  });

  useLeaveModal({
    show: form.formState.isDirty,
  });

  const reset = useCallback(
    (initialdata: AdditionalQuestion) => {
      form.reset(transformApiDataToFormData(initialdata));
    },
    [form]
  );
  return {
    form,
    reset,
  };
}

export { useAdditionalQuestionForm };
