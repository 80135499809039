import { TextInput } from '../common/text-input';

function KeyContactSection() {
  return (
    <div className="two-col-form">
      <div>
        <p className="text-md font-medium leading-7 text-gray-900">
          Key Contact
        </p>
        <p className="text-md leading-6 text-gray-600">
          These details are used to send updates to the location
        </p>
      </div>
      <div className="col-span-2 space-y-6">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <TextInput
            type="text"
            name="keyContact.firstName"
            label="Key Contact First Name*"
            placeholder="Jane"
          />
          <TextInput
            type="text"
            name="keyContact.lastName"
            label="Key Contact Last Name*"
            placeholder="Doe"
          />
        </div>
        <TextInput
          type="email"
          name="keyContact.email"
          label="Key Contact Email*"
          placeholder="jane.doe@email.com"
        />
      </div>
    </div>
  );
}

export { KeyContactSection };
