import { useChild } from '@/hooks/query-hooks/use-child';
import { Box } from '../box';
import { FallbackComponent } from '../fallback-component';
import { CheckboxBase } from '../form/common/checkbox';
import { LoadingScreen } from '../loading-screen';
import { useUpdateChildMutation } from '@/hooks/update-hooks/use-update-child-mutation';

type BookingAvailailityProps = {
  childId: string;
};

function BookingAvailaility(props: BookingAvailailityProps) {
  const { childId } = props;

  const {
    data: child,
    isError: isChildError,
    isSuccess: isChildSuccess,
    isLoading: isChildLoading,
  } = useChild(childId);

  const { mutateAsync: updateChild, isPending: isChildUpdating } =
    useUpdateChildMutation(childId);

  if (isChildLoading) {
    return (
      <Box className="w-full p-5">
        <p className="text-md pb-6 font-semibold">Availability</p>

        <LoadingScreen />
      </Box>
    );
  }

  if (!isChildSuccess || isChildError) {
    return (
      <Box className="w-full p-5">
        <FallbackComponent />
      </Box>
    );
  }

  const handleUpdateChild = async () => {
    await updateChild({
      isExtraHoursBookingEnabled: !child.extraHours.isBookingEnabled,
    });
  };

  return (
    <Box className="w-full p-5">
      <p className="text-md pb-6 font-semibold">Availability</p>
      <CheckboxBase
        onChange={handleUpdateChild}
        checked={child.extraHours.isBookingEnabled}
        disabled={isChildUpdating}
        label="Bookings Enabled"
        helperText="Users are able to purchase extra hours for this child"
        name="booking-availability"
      />
    </Box>
  );
}

export { BookingAvailaility };
