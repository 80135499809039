import { Alert } from '@/components/alert';
import { TextInput } from '@/components/form/common/text-input';
import { parentRouterPath } from '@/config/route-paths.config';
import { useVerifyParentInvitation } from '@/hooks/use-verify-parent-invitation';
import { HttpError, isBadRequest } from '@/types/error';
import { getNestedKeys } from '@/utils/get-nested-keys';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

const schema = yup
  .object({
    postcode: yup.string().required().label('Postcode'),
    dob: yup.string().required().label('Date of Birth'),
  })
  .required();

function VerifyInvitationForm() {
  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get('code') || '';
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      postcode: '',
      dob: '',
    },
    mode: 'onBlur',
  });
  const { mutateAsync: verify, error, isError } = useVerifyParentInvitation();
  const navigate = useNavigate();

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    try {
      await verify({
        code: invitationCode,
        dateOfBirth: data.dob,
        postcode: data.postcode,
      });

      navigate(`${parentRouterPath.CREATE_ACCOUNT}?code=${invitationCode}`);
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        if (!form.formState.defaultValues) {
          return;
        }

        const availableFields = getNestedKeys(form.formState.defaultValues);

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }
    }
  };

  return (
    <div className="mt-8">
      {isError ? (
        <Alert type="error" text={error.message} className="mb-4" />
      ) : null}
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
          <TextInput
            name="postcode"
            label="Postcode*"
            placeholder="Enter postcode"
            helperText="Please enter the postcode used in your application."
            type="text"
          />

          <TextInput
            name="dob"
            label="Date of Birth*"
            type="date"
            helperText="Please enter the DOB of a child you applied for."
          />

          <button
            className="btn btn-primary btn-full"
            disabled={form.formState.isSubmitting || !form.formState.isValid}
          >
            Continue
          </button>
        </form>
      </FormProvider>
    </div>
  );
}

export { VerifyInvitationForm };
