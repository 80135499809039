import SuccessImage from '@/assets/success.svg?react';
import { FallbackComponent } from '@/components/fallback-component';
import { LoadingScreen } from '@/components/loading-screen';
import { parentRouterPath } from '@/config/route-paths.config';
import { useApplication } from '@/hooks/query-hooks/use-application';
import { ArrowRight } from '@untitled-ui/icons-react';
import { Link, Navigate, generatePath, useParams } from 'react-router-dom';

function ApplicationSuccess() {
  const params = useParams<{ id: string }>();
  const {
    data: application,
    isLoading,
    isError,
    isSuccess,
  } = useApplication(params.id || '');

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isSuccess) {
    return <FallbackComponent />;
  }

  if (isError) {
    return <Navigate to="/404" />;
  }

  return (
    <>
      <h1 className="mb-8 mt-6 text-xl font-semibold text-gray-900">
        Application Submitted successfully
      </h1>

      <div className="mb-12 flex justify-center rounded-md border border-gray-200 bg-[#f9f6ef]">
        <SuccessImage />
      </div>
      <p className="text-md text-gray-500">
        We have successfully received your submitted application for{' '}
        <span className="font-semibold text-gray-900">
          {application.child.firstName} {application.child.lastName}
        </span>
        . You will be notified via email and on this dashboard when there is an
        update relating to this application.
      </p>
      <div className="mt-12 flex flex-col items-center gap-y-4 sm:flex-row sm:justify-center sm:gap-x-4 sm:gap-y-0">
        <Link
          className="btn btn-secondary"
          to={generatePath(parentRouterPath.APPLICATION_CHILD_DETAILS, {
            id: params.id || '',
          })}
        >
          Edit application
        </Link>
        <Link
          className="btn btn-primary flex items-center"
          to={parentRouterPath.ROOT}
        >
          Continue to Dashboard
          <ArrowRight viewBox="0 0 24 24" className="ml-2 h-5 w-5 text-white" />
        </Link>
      </div>
    </>
  );
}

export { ApplicationSuccess };
