import { useSchoolYear } from '@/context/school-year.context';
import { HttpError, isBadRequest } from '@/types/error';
import { getNestedKeys } from '@/utils/get-nested-keys';
import {
  Product,
  ProductListing,
  UpdateProductResponseType,
} from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { ManageProductFormData } from './manage-product-modal';
import { manageProductFormSchema } from './manage-product.schema';

type UseManageProductFormProps = {
  onSubmit: (
    data: ManageProductFormData
  ) => Promise<{ created: number } | UpdateProductResponseType>;
  initialData?: ProductListing | Product;
};

const isProductListing = (obj: any): obj is ProductListing => {
  return (
    obj && obj.session && typeof obj.session === 'object' && 'id' in obj.session
  );
};

const defaultFormValues: ManageProductFormData = {
  description: '',
  excludedDays: [],
  sessionId: '',
  name: '',
  price: NaN,
  availableFrom: '',
  availableTo: '',
  linkedSessionTypes: [],
  hasResourceAllocation: true,
};

function transformApiDataToFormData(
  product?: ProductListing | Product
): ManageProductFormData {
  if (!product) {
    return defaultFormValues;
  }

  if (isProductListing(product)) {
    const transformedData: ManageProductFormData = {
      description: product.description,
      excludedDays: product.excludedDays.map(date =>
        format(new Date(date), 'yyyy-MM-dd')
      ),
      sessionId: product.session.id.toString(),
      name: product.name,
      price: 1, // if editing product this won't be used anyways
      availableFrom: format(new Date(product.availableFrom), 'yyyy-MM-dd'),
      availableTo: format(new Date(product.availableTo), 'yyyy-MM-dd'),
      linkedSessionTypes: product.linkedSessionTypes.map(linkedSession =>
        linkedSession.id.toString()
      ),
      hasResourceAllocation: product.hasResourceAllocation,
    };

    return transformedData;
  }

  const transformedData: ManageProductFormData = {
    description: product.description,
    excludedDays: product.excludedDays.map(date =>
      format(new Date(date), 'yyyy-MM-dd')
    ),
    sessionId: product.session?.toString() || '',
    name: product.name,
    price: 1, // if editing product this won't be used anyways
    availableFrom: format(new Date(product.availableFrom), 'yyyy-MM-dd'),
    availableTo: format(new Date(product.availableTo), 'yyyy-MM-dd'),
    linkedSessionTypes: product.linkedSessionTypes.map(linkedSession =>
      linkedSession.toString()
    ),
    hasResourceAllocation: product.hasResourceAllocation,
  };

  return transformedData;
}

function useManageProductForm(props: UseManageProductFormProps) {
  const { onSubmit, initialData } = props;

  const { schoolYear } = useSchoolYear();

  const defaultValues = transformApiDataToFormData(initialData);

  const form = useForm({
    resolver: yupResolver(manageProductFormSchema),
    context: { schoolYear },
    defaultValues,
  });

  const handleSubmit = async (data: ManageProductFormData) => {
    try {
      await onSubmit(data);
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        if (!form.formState.defaultValues) {
          return;
        }

        const availableFields = getNestedKeys(form.formState.defaultValues);
        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, '');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }
    }
  };

  return {
    form,
    handleSubmit,
  };
}

export { useManageProductForm };
