import { DrawerContainer } from '@/components/application/drawer/drawer-container';
import { useReportingOutputControl } from '@/context/reporting-output-control.context';
import { ChildCarer, ChildSiblings, Session } from '@admissions-support/types';
import { SiblingDetails } from './sibling-details';
import { CarerDetails } from './carer-details';
import { ChoiceDetails } from './choice-details';
import { ChoiceSideDrawerContent } from '@/types/reporting-output-control';
import { SessionsDetails } from './sessions-details';

const isCarer = (data: any): data is ChildCarer => {
  return data.relationship || data.relationship === null;
};

const isSibling = (data: any): data is ChildSiblings => {
  return data.school || data.school === null;
};

const isChoice = (data: any): data is ChoiceSideDrawerContent => {
  return data.nbPreference && data.schoolYearId;
};

const isSessions = (data: any): data is Session[] => {
  return Array.isArray(data) && data.every(val => val.times);
};

function ReportingDetailedViewDrawer() {
  const { setSideDrawerContent, sideDrawerContent, isOpen, closeModal } =
    useReportingOutputControl();

  if (!sideDrawerContent) {
    return null;
  }

  const getTitle = () => {
    if (isCarer(sideDrawerContent)) {
      return 'Carer Details';
    }
    if (isSibling(sideDrawerContent)) {
      return 'Sibling Details';
    }
    if (isChoice(sideDrawerContent)) {
      return 'Choice Details';
    }
    if (isSessions(sideDrawerContent)) {
      return 'Sessions Details';
    }
  };

  return (
    <DrawerContainer
      open={isOpen}
      onClose={closeModal}
      onDrawerClosed={() => setSideDrawerContent(undefined)}
      title={getTitle()}
    >
      {isCarer(sideDrawerContent) ? (
        <CarerDetails carer={sideDrawerContent} />
      ) : null}
      {isSibling(sideDrawerContent) ? (
        <SiblingDetails sibling={sideDrawerContent} />
      ) : null}
      {isChoice(sideDrawerContent) ? (
        <ChoiceDetails choice={sideDrawerContent} />
      ) : null}
      {isSessions(sideDrawerContent) ? (
        <SessionsDetails sessions={sideDrawerContent} />
      ) : null}
    </DrawerContainer>
  );
}

export { ReportingDetailedViewDrawer };
