import { ApplicationQuickViewDrawer } from '@/components/application-quick-view-drawer';
import { Box } from '@/components/box';
import { SelectBase } from '@/components/form/common/select';
import RespondToMessageForm from '@/components/form/respond-to-message-form';
import { LoadingScreen } from '@/components/loading-screen';
import Message from '@/components/messaging/message';
import { Modal } from '@/components/modal';
import { PageTitle } from '@/components/page-title';
import { staffRouterPath } from '@/config/route-paths.config';
import { useAuth } from '@/context/auth.context';
import { useTicket } from '@/hooks/query-hooks/use-ticket';
import { useUpdateTicketStatus } from '@/hooks/update-hooks/use-update-ticket-status';
import { useMarkMessageSeen } from '@/hooks/use-mark-message-seen';
import { Ticket } from '@admissions-support/types';
import { Eye } from '@untitled-ui/icons-react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';

function ViewConversation() {
  const { id: ticketId } = useParams();

  const scrollableComponentRef = useRef<HTMLDivElement>(null);
  const [isCloseConfirmationOpen, setIsCloseConfirmationOpen] = useState(false);

  const [
    isApplicationQuickViewDrawerOpen,
    setIsApplicationQuickViewDrawerOpen,
  ] = useState(false);

  const { mutateAsync: updateTicketStatus } = useUpdateTicketStatus(
    ticketId || ''
  );

  const {
    data: ticket,
    isLoading: isTicketLoading,
    refetch,
  } = useTicket(
    {
      id: ticketId,
      isParent: false,
    },
    {
      refetchOnWindowFocus: true,
    }
  );

  const messagesWithoutFirst = ticket?.messages?.slice(1) || [];

  const scrollToNewMessage = () => {
    if (scrollableComponentRef.current) {
      scrollableComponentRef.current.scrollTo({
        top: scrollableComponentRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const { user } = useAuth();

  const { mutateAsync: markMessageSeen, isPending: isMarkMessageSeenLoading } =
    useMarkMessageSeen({
      id: ticketId || '',
      isParent: false,
    });

  const handleMarkMessageSeen = async () => {
    const isAnyMessageUnseen = ticket?.messages.some(
      message => !message.seenBy?.some(({ id }) => id.toString() === user?.id)
    );

    if (isAnyMessageUnseen) {
      await markMessageSeen(ticketId || '');
    }
  };

  const handleStatusChange = async (value: ChangeEvent<HTMLSelectElement>) => {
    const status = value.target.value as Ticket['status'];

    if (status === 'CLOSED') {
      setIsCloseConfirmationOpen(true);
      return;
    }

    setTicketStatus(status);
  };

  const setTicketStatus = async (status: Ticket['status']) => {
    const ticketUpdatePromise = updateTicketStatus({ status });

    await toast.promise(ticketUpdatePromise, {
      success: 'Ticket status updated successfully',
      error: 'Failed to update ticket status',
      loading: 'Updating ticket status...',
    });
    refetch();
  };

  useEffect(() => {
    scrollToNewMessage();
    handleMarkMessageSeen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket?.messages]);

  return (
    <>
      <div>
        <PageTitle title="View Ticket" variant="gray">
          <Link to={staffRouterPath.MESSAGING} className="btn btn-secondary">
            Return to Messaging
          </Link>
        </PageTitle>
        {isTicketLoading ? (
          <div className="mt-12 flex min-h-96 flex-col items-center justify-center">
            <LoadingScreen />
            <div className="mt-4 text-sm font-normal text-gray-500">
              Loading ticket...
            </div>
          </div>
        ) : ticket ? (
          <div className="mt-12 flex flex-col-reverse gap-4 md:grid md:grid-cols-2">
            <div className="flex flex-col gap-6">
              <div className="relative">
                <div className="flex max-h-[calc(100vh_-_406px)] flex-col gap-6 overflow-hidden">
                  {ticket?.messages?.[0] ? (
                    <Message
                      ticketId={ticketId || ''}
                      isFirstMessage
                      title={ticket.title}
                      name={ticket.messages[0].sender.name}
                      createdAt={ticket.messages[0].createdAt}
                      message={ticket.messages[0].message}
                      topic={ticket.topic}
                      status={ticket.status}
                      attachments={ticket.messages[0].attachments || []}
                    />
                  ) : null}
                  {messagesWithoutFirst?.length ? (
                    <div
                      className="flex w-full flex-col gap-6 overflow-y-auto rounded-2xl border border-gray-200 bg-gray-50 p-4 shadow-[inset_1px_4px_10px_0px_#CACACA40]"
                      ref={scrollableComponentRef}
                    >
                      {messagesWithoutFirst.map(message => (
                        <Message
                          ticketId={ticketId || ''}
                          key={message.createdAt}
                          name={message.sender.name}
                          createdAt={message.createdAt}
                          message={message.message}
                          attachments={message.attachments || []}
                        />
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="rounded-2xl border border-gray-200 p-4">
                <RespondToMessageForm
                  ticketId={ticketId || ''}
                  onMessageSent={() => refetch()}
                />
              </div>
            </div>
            <div>
              <Box className="flex w-full flex-col gap-12 p-6 sm:p-6 md:max-w-[412px]">
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col">
                    <div className="text-md font-semibold">Parent/Carer</div>
                    <div className="text-md font-normal">
                      {ticket.user?.name || ticket.createdBy?.name}
                    </div>
                  </div>
                  {ticket.child ? (
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-col">
                        <div className="text-md font-semibold">Child</div>
                        <div className="text-md font-normal">
                          {ticket.child.name}
                        </div>
                      </div>
                      {ticket?.child?.applicationId ? (
                        <button
                          onClick={() =>
                            setIsApplicationQuickViewDrawerOpen(true)
                          }
                          className="btn flex items-center gap-2 whitespace-nowrap shadow-none"
                        >
                          <Eye /> View Details
                        </button>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <div>
                  <SelectBase
                    name="status"
                    label="Status"
                    value={ticket.status}
                    options={[
                      { key: 'OPEN', value: 'Open' },
                      { key: 'IN_PROGRESS', value: 'In Progress' },
                      { key: 'PENDING', value: 'Pending' },
                      { key: 'CLOSED', value: 'Closed' },
                    ]}
                    disabled={isMarkMessageSeenLoading}
                    onChange={handleStatusChange}
                  />
                </div>
              </Box>
            </div>
          </div>
        ) : null}
      </div>
      <Modal
        open={isCloseConfirmationOpen}
        title="Close Ticket"
        description="Are you sure you want to close this ticket?"
        primaryBtnText="Confirm Close Ticket"
        primaryAction={() => {
          setTicketStatus('CLOSED' as Ticket['status']);
          setIsCloseConfirmationOpen(false);
        }}
        secondaryAction={() => setIsCloseConfirmationOpen(false)}
        secondaryBtnText="Cancel"
        onClose={() => setIsCloseConfirmationOpen(false)}
      />

      {ticket?.child?.applicationId ? (
        <ApplicationQuickViewDrawer
          applicationId={ticket.child.applicationId}
          open={isApplicationQuickViewDrawerOpen}
          onClose={() => setIsApplicationQuickViewDrawerOpen(false)}
          disableOnCloseNavigation
        />
      ) : null}
    </>
  );
}

export default ViewConversation;
