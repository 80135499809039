import * as yup from 'yup';

const addTrusteeSchema = yup.object({
  firstName: yup
    .string()
    .label('First Name')
    .required('First Name is required'),
  lastName: yup.string().label('Last Name').required('Last Name is required'),
  email: yup.string().label('Email').required('Email is required'),
  relationship: yup
    .string()
    .label('Relationship')
    .required('Relationship is required'),
  canPurchaseExtraHours: yup.boolean().label('Can Purchase Extra Hours'),
  canManageApplications: yup.boolean().label('Can Manage Applications'),
});

type AddTrusteeFormData = yup.InferType<typeof addTrusteeSchema>;

export { addTrusteeSchema };
export type { AddTrusteeFormData };
