import { LoadingScreen } from '@/components/loading-screen';
import { LoginTitle } from '@/components/login-title';
import { parentRouterPath } from '@/config/route-paths.config';
import { useInvitation } from '@/hooks/query-hooks/use-invitation';
import { usePublicOrganisation } from '@/hooks/query-hooks/use-public-organisation';
import { Helmet } from 'react-helmet-async';
import { Link, useSearchParams } from 'react-router-dom';

function ParentMain() {
  const {
    data: publicOrganisation,
    isLoading,
    isSuccess: isOrganisationSuccess,
    isError: isOrganisationError,
    error: organisationError,
  } = usePublicOrganisation();

  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get('code');

  const {
    isLoading: isInvitationLoading,
    isError: isInvitationError,
    isSuccess: isInvitationSuccess,
  } = useInvitation({
    enabled: !!invitationCode,
  });

  if (isLoading || isInvitationLoading) {
    return <LoadingScreen />;
  }

  if (!isOrganisationSuccess || isOrganisationError) {
    return (
      <>
        <Helmet>
          <title>Sign in</title>
        </Helmet>

        <div>
          <LoginTitle
            title="Something went wrong"
            description={`We're sorry, but we couldn't load this page. ${
              isOrganisationError ? `Reason: ${organisationError.message}` : ''
            }`}
          />
        </div>
      </>
    );
  }

  if (!!invitationCode && (!isInvitationSuccess || isInvitationError)) {
    return (
      <>
        <Helmet>
          <title>Sign in</title>
        </Helmet>

        <div>
          <LoginTitle
            title="Invalid invitation code"
            description="You invitation is expired or not valid"
          />
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Sign in</title>
      </Helmet>

      <div>
        <LoginTitle title="Welcome to Admit" />

        <p className="mb-4 mt-2 font-light">
          You have been directed here by{' '}
          <span className="font-semibold text-primary-600">
            {publicOrganisation.name}
          </span>
          . On this website, you&apos;ll be able to view and purchase extra
          nursery hours where they are available.
        </p>
        <p className="text-sm">
          Please note this product is in its pilot phase.
        </p>
        <Link
          to={
            invitationCode
              ? `${parentRouterPath.VERIFY_INVITATION}?code=${invitationCode}`
              : parentRouterPath.CREATE_ACCOUNT
          }
          className="btn btn-primary btn-full mt-12"
        >
          Get Started
        </Link>
      </div>
    </>
  );
}

export { ParentMain };
