import { useSettings } from '@/context/settings.context';
import { usePlatformUsersSubnavigation } from '@/hooks/use-platform-users-subnavigation';
import { kebabCase } from 'lodash-es';
import { Outlet } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import { renderItem } from '../sidebar';

function PlatformUsersLayout() {
  const platformUsersChildren = usePlatformUsersSubnavigation();
  const { isSidebarCollapsed } = useSettings();

  return (
    <div>
      {platformUsersChildren.map(item => (
        <Tooltip
          key={kebabCase(item.name)}
          anchorSelect={`#${kebabCase(item.name)}`}
          content={item.name}
          place="right"
          className={twMerge(
            'tooltip',
            isSidebarCollapsed ? 'block' : 'hidden'
          )}
        />
      ))}

      <div
        className={twMerge(
          'hidden border-r border-gray-200 lg:flex',
          'bg-gray-100 transition-all lg:fixed lg:inset-y-0',
          isSidebarCollapsed ? 'w-[72px] lg:left-[72px]' : 'w-60 lg:left-72'
        )}
      >
        <nav className="flex flex-1 flex-col pt-8">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul className="space-y-1 px-4">
                {platformUsersChildren.map(item => (
                  <li key={item.name}>{renderItem(item)}</li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
      <div
        className={twMerge(isSidebarCollapsed ? 'lg:ml-[72px]' : 'lg:ml-60')}
      >
        <Outlet />
      </div>
    </div>
  );
}

export { PlatformUsersLayout };
