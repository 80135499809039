import * as yup from 'yup';

interface PasswordRulesProps {
  password: string;
  schema: yup.ObjectSchema<{ password: string }>;
}

function PasswordRules(props: PasswordRulesProps) {
  const { password, schema } = props;
  const validatePasswordRules = (
    password: string
  ): {
    minLength: boolean;
    containsNumber: boolean;
    containsUppercase: boolean;
    containsLowercase: boolean;
    containsSpecialChar: boolean;
  } => {
    if (!password || password.trim() === '') {
      return {
        minLength: false,
        containsNumber: false,
        containsUppercase: false,
        containsLowercase: false,
        containsSpecialChar: false,
      };
    }

    try {
      schema.validateSyncAt(
        'password',
        { password },
        {
          abortEarly: false,
        }
      );

      return {
        minLength: true,
        containsNumber: true,
        containsUppercase: true,
        containsLowercase: true,
        containsSpecialChar: true,
      };
    } catch (e) {
      if (e instanceof yup.ValidationError) {
        const errors = e.errors;

        return {
          minLength: !errors.some(error => /12/g.test(error)),
          containsNumber: !errors.some(error => /number/g.test(error)),
          containsUppercase: !errors.some(error => /uppercase/g.test(error)),
          containsLowercase: !errors.some(error => /lowercase/g.test(error)),
          containsSpecialChar: !errors.some(error =>
            /special character/g.test(error)
          ),
        };
      }

      return {
        minLength: false,
        containsNumber: false,
        containsUppercase: false,
        containsLowercase: false,
        containsSpecialChar: false,
      };
    }
  };

  const passwordValidation = validatePasswordRules(password);

  const renderPasswordRule = (
    ruleName: string,
    passwordPassesTheRule: boolean
  ) => {
    if (passwordPassesTheRule) {
      return (
        <p className="flex text-sm font-medium text-gray-900">
          <svg
            className="mr-2 h-5 w-5 text-green-600"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
              clipRule="evenodd"
            />
          </svg>
          {ruleName}
        </p>
      );
    }

    return (
      <p className="flex text-sm font-medium text-gray-500">
        <svg
          className="mr-2 h-5 w-5 text-gray-500"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
            clipRule="evenodd"
          />
        </svg>
        {ruleName}
      </p>
    );
  };

  return (
    <div className="my-6 space-y-2">
      {renderPasswordRule(
        'Password minimum length 12 character(s)',
        passwordValidation.minLength
      )}

      {renderPasswordRule(
        'Contains at least 1 number',
        passwordValidation.containsNumber
      )}

      {renderPasswordRule(
        'Contains at least 1 special character',
        passwordValidation.containsSpecialChar
      )}

      {renderPasswordRule(
        'Contains at least 1 uppercase letter',
        passwordValidation.containsUppercase
      )}

      {renderPasswordRule(
        'Contains at least 1 lowercase letter',
        passwordValidation.containsLowercase
      )}
    </div>
  );
}

export { PasswordRules };
