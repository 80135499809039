import { bookingService } from '@/services/booking.service';
import { HttpError } from '@/types/error';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCancelBooking(
  mutationOptions?: UseMutationOptions<void, HttpError, { bookingId: string }>
) {
  return useMutation({
    mutationFn: data => bookingService.cancelBooking(data.bookingId),
    ...mutationOptions,
  });
}

export { useCancelBooking };
