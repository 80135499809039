import { useAuth } from '@/context/auth.context';
import { ListTicketsResponseItem } from '@admissions-support/types';
import { twMerge } from 'tailwind-merge';

type MessageTitleCellProps = {
  ticket: ListTicketsResponseItem;
};

function MessageTitleCell(props: MessageTitleCellProps) {
  const { ticket } = props;

  const { isLoading, user } = useAuth();
  if (isLoading) {
    return null;
  }

  const isUnread = !ticket?.seenBy?.includes(user?.id ?? '');

  return (
    <div className="flex items-center gap-1.5">
      <span
        className={twMerge(
          isUnread && 'font-medium text-primary-900',
          'max-w-[220px] overflow-hidden text-ellipsis'
        )}
      >
        {ticket.title}
      </span>

      {isUnread ? (
        <div className="h-3 w-3 animate-pulse rounded-full border-2 border-red-100 bg-red-500" />
      ) : null}
    </div>
  );
}

export { MessageTitleCell };
