import { childKey } from '@/config/query-keys';

import { childService } from '@/services/child.service';
import { HttpError } from '@/types/error';
import { ListChild } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

export function useChildrenByParentId(
  parentId: string,
  queryOptions?: Partial<UseQueryOptions<ListChild[], HttpError>>
) {
  return useQuery({
    queryKey: childKey.listByParentId(parentId),
    queryFn: () => childService.fetchChildrenByParentId(parentId),
    ...queryOptions,
  });
}
