import { twMerge } from 'tailwind-merge';
import { Tag } from './tag';
import { PaymentStatus } from '@admissions-support/types';

type PaymentTagProps = {
  status?: PaymentStatus;
  children: React.ReactNode;
};

function PaymentStatusTag(props: PaymentTagProps) {
  const { status, children } = props;

  return (
    <Tag
      type="outline"
      className="inline-flex w-auto flex-row items-center px-1.5 py-0.5 text-xs font-normal capitalize shadow-xs"
    >
      <span
        className={twMerge(
          'mr-1 inline-block h-1.5 w-1.5 rounded-full',
          status === 'PAID' ? 'bg-green-500' : null,
          status === 'PAYMENT_DUE' ? 'bg-red-500' : null,
          status === 'UNPAID' ? 'bg-yellow-500' : null
        )}
      ></span>
      {children}
    </Tag>
  );
}

export { PaymentStatusTag };
