import { ChildExtraHoursTableFilter } from '@/components/parent/purchase-extra-hours/child-extra-hours-table';
import { axiosClient } from '@/libs/axios';
import { HttpError } from '@/types/error';
import { ExtraHoursBookingsFilter } from '@/types/extra-hours-bookings-control';
import { generateQueryParamsForChildrenBookings } from '@/utils/booking-utils';
import {
  BookingInfo,
  BookingListing,
  ChildMeta,
  ChildWithBookingInfo,
  CreateBookingDto,
  CustomPaginateResult,
  LocationBookingsResponse,
  PaginateResult,
  QueryLocationBookings,
} from '@admissions-support/types';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { AxiosResponse } from 'axios';

async function getBookingsByLocationId(
  locationId: string,
  query: QueryLocationBookings
): Promise<LocationBookingsResponse> {
  const queryParams = [
    `schoolYearId=${query.schoolYearId}`,
    `from=${query.from}`,
    `to=${query.to}`,
  ];

  const response = await axiosClient.get<
    HttpError,
    AxiosResponse<LocationBookingsResponse>
  >(`/bookings/locations/${locationId}?${queryParams.join('&')}`);

  return response.data;
}

async function getBookingsMetaByChildId(childId: string): Promise<BookingInfo> {
  const response = await axiosClient.get<HttpError, AxiosResponse<BookingInfo>>(
    `bookings/children/${childId}/booking-info`
  );

  return response.data;
}

async function getBookingsByChildId(data: {
  childId: string;
  pagination: PaginationState;
  sorting: SortingState;
  filter: ChildExtraHoursTableFilter;
}): Promise<PaginateResult<BookingListing>> {
  const { childId, pagination, sorting, filter } = data;

  const queryParams = [
    `page=${(Number(pagination?.pageIndex) ?? 0) + 1}`,
    `limit=${pagination?.pageSize ? pagination.pageSize : 10}`,
  ];

  if (sorting.length > 0) {
    const sortingBy = sorting[0];

    queryParams.push(`sortBy=${sortingBy.id}`);
    queryParams.push(`orderBy=${sortingBy.desc ? 'desc' : 'asc'}`);
  }

  if (filter.showPastBookings) {
    queryParams.push('showPastBookings=1');
  }

  const response = await axiosClient.get<
    HttpError,
    AxiosResponse<PaginateResult<BookingListing>>
  >(`bookings/children/${childId}?${queryParams.join('&')}`);

  return response.data;
}

async function getBookingsByChildren(
  schoolYearId: string,
  options: {
    pagination: PaginationState;
    sorting: SortingState;
    filter: ExtraHoursBookingsFilter;
  }
): Promise<CustomPaginateResult<ChildWithBookingInfo>> {
  const queryParams = generateQueryParamsForChildrenBookings(
    schoolYearId,
    options
  );

  const response = await axiosClient.get<
    HttpError,
    AxiosResponse<CustomPaginateResult<ChildWithBookingInfo>>
  >(`/bookings/children?${queryParams.join('&')}`);

  return response.data;
}

async function exportBookingsByChildren(
  schoolYearId: string,
  options: {
    pagination: PaginationState;
    sorting: SortingState;
    filter: ExtraHoursBookingsFilter;
  }
): Promise<Blob> {
  const queryParams = generateQueryParamsForChildrenBookings(
    schoolYearId,
    options
  );

  const response = await axiosClient.get(
    `/bookings/children?isCsvExport=true&${queryParams.join('&')}`,
    {
      disableToast: true,
      responseType: 'blob',
    }
  );

  return response.data;
}

const createBooking = async (
  childId: string,
  data: CreateBookingDto
): Promise<void> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<void>,
    CreateBookingDto
  >(`/bookings/children/${childId}`, data, {
    disableToast: true,
  });

  return response.data;
};

async function getBookingMetaByChildId(data: {
  childId: string;
  schoolYearId: string;
}): Promise<ChildMeta> {
  const response = await axiosClient.get<HttpError, AxiosResponse<ChildMeta>>(
    `/bookings/children/${data.childId}/meta?schoolYearId=${data.schoolYearId}`,
    {
      disableToast: true,
      disableErrorToast: true,
    }
  );

  return response.data;
}

const cancelBooking = async (bookingId: string): Promise<void> => {
  const response = await axiosClient.post<HttpError, AxiosResponse<void>>(
    `/bookings/${bookingId}/cancel`
  );

  return response.data;
};

export const bookingService = Object.freeze({
  getBookingsByLocationId,
  getBookingsMetaByChildId,
  getBookingsByChildId,
  getBookingsByChildren,
  createBooking,
  getBookingMetaByChildId,
  cancelBooking,
  exportBookingsByChildren,
});
