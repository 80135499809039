import { staffRouterPath } from '@/config/route-paths.config';
import { useAuth } from '@/context/auth.context';
import { useReportTemplate } from '@/hooks/query-hooks/use-report-template';
import { useMatch, useParams } from 'react-router-dom';

function useCanUserEditTemplate(): boolean {
  const params = useParams<{ id?: string }>();
  const isNewReportTemplate = useMatch(staffRouterPath.NEW_QUERY_BUILDER);
  const { data: reportTemplate } = useReportTemplate(params.id || '', {
    enabled: !isNewReportTemplate,
  });
  const { user } = useAuth();

  return (
    user?.id === reportTemplate?.createdBy.id.toString() ||
    !!isNewReportTemplate
  );
}

export { useCanUserEditTemplate };
