import { useAuth } from '@/context/auth.context';
import { HttpError } from '@/types/error';
import { CreateParentDto, Parent } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useRegisterParentMutation(
  mutationOptions?: UseMutationOptions<Parent, HttpError, CreateParentDto>
) {
  const { registerParent } = useAuth();

  return useMutation({
    mutationFn: registerParent,
    ...mutationOptions,
  });
}

export { useRegisterParentMutation };
