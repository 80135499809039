import { staffRouterPath } from '@/config/route-paths.config';
import { useAuth } from '@/context/auth.context';
import { useReportTemplates } from '@/hooks/query-hooks/use-report-templates';
import { getErrorMessage } from '@/utils/get-error-message';
import { ReportTemplate } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { Box } from '../box';
import { Toggle } from '../form/common/toggle';
import { StatusTag } from '../status-tag';
import { Table } from '../table';

export type ReportTemplatesTableFilter = {
  isOrganisationWide: boolean;
};

function ReportTemplatesTable() {
  const [filter, setFilter] = useState<ReportTemplatesTableFilter>({
    isOrganisationWide: true,
  });

  const {
    data: reportTemplates,
    isLoading: isReportTemplatesLoading,
    isSuccess: isReportTemplatesSuccess,
    isFetching: isReportTemplatesFetching,
    error: reportTemplatesError,
  } = useReportTemplates(
    { filter },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const { user } = useAuth();

  const columnHelper = createColumnHelper<ReportTemplate>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'templateName',
        cell: info => {
          const name = info.getValue();
          return <span className="text-sm text-gray-600">{name}</span>;
        },
        header: () => (
          <span className="text-xs text-gray-600">Template Name</span>
        ),
        enableSorting: true,
      }),
      columnHelper.accessor('createdBy', {
        id: 'createdBy',
        cell: info => {
          const creator = info.getValue();

          const nameToBeDisplayed =
            creator.id.toString() === user?.id ? 'You' : creator.name;

          return (
            <span className="text-sm text-gray-600">{nameToBeDisplayed}</span>
          );
        },
        header: () => (
          <span className="text-xs text-gray-600">Template Creator</span>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor('visibility', {
        id: 'visibility',
        cell: info => {
          const visibility =
            info.getValue() === 'ORGANISATION' ? 'Organisation' : 'Only You';

          const dotColor =
            visibility === 'Organisation'
              ? 'bg-accent-dark-500'
              : 'bg-gray-500';

          return <StatusTag dotColor={dotColor}>{visibility}</StatusTag>;
        },
        header: () => <span className="text-xs text-gray-600">Visibility</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('lastRun', {
        id: 'lastRun',
        cell: info => {
          const lastRun = info.getValue();

          if (!lastRun) {
            return '--';
          }

          return (
            <span className="font-mono text-sm text-gray-600">
              {format(new Date(lastRun), 'dd/MM/yy H:m')}
            </span>
          );
        },
        header: () => (
          <span className="text-xs text-gray-600">Last run by You</span>
        ),
        enableSorting: false,
      }),
      columnHelper.display({
        id: 'actions',
        cell: info => {
          const template = info.row.original;
          return (
            <div className="flex gap-3">
              <Link
                to={generatePath(staffRouterPath.EDIT_QUERY_BUILDER, {
                  id: template.id,
                })}
                className="text-sm font-semibold text-gray-600"
              >
                Edit
              </Link>
              <Link
                to={generatePath(staffRouterPath.RUN_QUERY_BUILDER, {
                  id: template.id,
                })}
                className="text-sm font-semibold text-primary-700"
              >
                Run Report
              </Link>
            </div>
          );
        },
        header: () => <span className="text-xs text-gray-600">Actions</span>,
        enableSorting: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleVisibilityChange = (newVal: boolean) => {
    setFilter(prev => ({
      ...prev,
      isOrganisationWide: newVal,
    }));
  };

  return (
    <Box>
      <div className="flex justify-between">
        <p className="text-lg font-semibold text-primary-900">
          Query Builder Templates
        </p>
        <Toggle
          label="Include Organisation Wide Reports"
          value={filter.isOrganisationWide}
          onChange={handleVisibilityChange}
        />
      </div>
      <Table
        columns={columns}
        data={isReportTemplatesSuccess ? reportTemplates : []}
        isLoading={isReportTemplatesLoading}
        isFetching={isReportTemplatesFetching}
        error={getErrorMessage(reportTemplatesError)}
        paginationType="auto"
        EmptyState={
          <div className="py-12 text-center">
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              No Report Templates found
            </h3>
          </div>
        }
      />
    </Box>
  );
}

export { ReportTemplatesTable };
