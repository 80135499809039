type BooleanCellProps = {
  cellValue?: boolean | null;
};

function BooleanCell(props: BooleanCellProps) {
  const { cellValue } = props;

  return <span>{cellValue ? 'Yes' : 'No'}</span>;
}

export { BooleanCell };
