import { ButtonGroup } from '../common/button-group';
import { TextInput } from '../common/text-input';

function LocationDetailsSection() {
  return (
    <div className="two-col-form">
      <div>
        <p className="text-md font-medium leading-7 text-gray-900">
          Location Details
        </p>
        <p className="text-md leading-6 text-gray-600">
          Specify location details
        </p>
      </div>
      <div className="col-span-2 space-y-6">
        <TextInput
          type="text"
          name="name"
          label="Location Name*"
          placeholder="Little Nippers"
        />

        <ButtonGroup
          name="type"
          label="Type*"
          options={[
            {
              key: 'ELC',
              value: 'ELC',
            },
            {
              key: 'PRIMARY',
              value: 'Primary',
              disabled: true,
            },
            {
              key: 'SECONDARY',
              value: 'Secondary',
              disabled: true,
            },
          ]}
        />

        <ButtonGroup
          name="providerType"
          label="Provider Type*"
          options={[
            {
              key: 'COUNCIL',
              value: 'Council',
            },
            {
              key: 'CHILD_MINDER',
              value: 'Child Minder',
            },
            {
              key: 'PARTNER_PROVIDER',
              value: 'Partner Provider',
            },
          ]}
        />

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <TextInput
            type="text"
            name="serviceProviderNumber"
            label="Service Provider Number*"
            placeholder="111222333444"
          />
          <TextInput
            type="text"
            name="careInspectorNumber"
            label="Care Inspectorate Number*"
            placeholder="111222333444"
          />
          <TextInput
            type="text"
            name="contactNumber"
            label="Contact Number"
            placeholder="0123 345679"
          />
          <TextInput
            type="text"
            name="website"
            label="Website"
            placeholder="https://example.com"
          />
          <TextInput
            type="text"
            name="address.streetAddress"
            label="Address Line 1*"
            placeholder="1 Main Street"
          />
          <TextInput
            type="text"
            name="address.streetAddressTwo"
            label="Address Line 2"
            placeholder="Floor 10"
          />
          <TextInput
            type="text"
            name="address.city"
            label="City"
            placeholder="Edinburgh"
          />
          <TextInput
            type="text"
            name="address.postcode"
            label="Postcode*"
            placeholder="EH1 3LA"
          />
          <TextInput
            type="text"
            name="seedCode"
            label="Seed Code*"
            placeholder="5501620"
          />
        </div>
      </div>
    </div>
  );
}

export { LocationDetailsSection };
