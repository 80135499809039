import { organisationKey } from '@/config/query-keys';
import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useDeleteWeekModelMutation(
  mutationOptions?: UseMutationOptions<void, HttpError, string>
) {
  return useMutation({
    mutationKey: organisationKey.deleteWeekModel,
    mutationFn: organisationService.deleteWeekModel,
    ...mutationOptions,
  });
}

export { useDeleteWeekModelMutation };
