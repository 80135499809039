import { LoadingScreen } from '@/components/loading-screen';
import { useApplicationTemplate } from '@/hooks/query-hooks/use-application-template';
import { Plus } from '@untitled-ui/icons-react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AdditionalQuestion } from './additional-question';
import { AdditionalQuestionForm } from './elements/additional-question-form';

function AdditionalQuestionList() {
  const params = useParams<{ id?: string }>();
  const [isNew, setIsNew] = useState<boolean>(false);

  const {
    isLoading,
    isSuccess,
    data: template,
  } = useApplicationTemplate(params.id || '');

  if (isLoading) {
    return <LoadingScreen />;
  }

  const additionalQuestions = isSuccess ? template.additionalQuestions : [];

  return (
    <div className="space-y-6">
      {additionalQuestions.map(q => (
        <AdditionalQuestion key={q.id} {...q} />
      ))}

      {isNew && (
        <div className="mb-6 rounded-md border p-4">
          <AdditionalQuestionForm onClose={() => setIsNew(false)} />
        </div>
      )}
      <button
        type="button"
        className="btn btn-secondary flex items-center"
        onClick={() => setIsNew(true)}
        disabled={isNew}
      >
        <Plus className="h-5 w-5" viewBox="0 0 24 24" aria-hidden="true" />
        Add Question
      </button>
    </div>
  );
}

export { AdditionalQuestionList };
