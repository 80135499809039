import {
  applicationReportConditions,
  bookingReportConditions,
  locationReportConditions,
} from '@/components/reporting/reporting.const';
import {
  Condition,
  ConditionData,
  ConditionGroup,
} from '@/components/reporting/reporting.type';
import { cloneDeep, set, uniqueId } from 'lodash-es';

function addAndCondition(conditionGroups: ConditionGroup[]): ConditionGroup[] {
  const nbOfConditionGroups = conditionGroups.length;

  // If no groups added, add an AND group
  if (nbOfConditionGroups < 1) {
    return [
      {
        id: uniqueId('condition-group-'),
        variant: 'and',
        data: [{ id: uniqueId('condition-'), type: '', value: '' }],
      },
    ];
  }

  // Check if the last item was an AND or an OR
  const lastConditionGroupIndex = nbOfConditionGroups - 1;
  const lastConditionGroup = conditionGroups[lastConditionGroupIndex];
  // If the last group item was an or, lets add an AND group
  if (lastConditionGroup.variant === 'or') {
    return [
      ...conditionGroups,
      {
        id: uniqueId('condition-group-'),
        variant: 'and',
        data: [{ id: uniqueId('condition-'), type: '', value: '' }],
      },
    ];
  }
  // If it was an AND group, add AND formData to that AND group
  const newConditionGroupData: ConditionData[] = [
    ...lastConditionGroup.data,
    { id: uniqueId('condition-'), type: '', value: '' },
  ];

  const newConditionGroups = cloneDeep(conditionGroups);
  newConditionGroups[lastConditionGroupIndex].data = newConditionGroupData;

  return newConditionGroups;
}

function addOrCondition(conditionGroups: ConditionGroup[]): ConditionGroup[] {
  return [
    ...conditionGroups,
    {
      id: uniqueId('condition-group-'),
      variant: 'or',
      data: [{ id: uniqueId('condition-'), type: '', value: '' }],
    },
  ];
}

function addAndConditionToOrGroup({
  conditionGroups,
  index,
}: {
  conditionGroups: ConditionGroup[];
  index: number;
}): ConditionGroup[] {
  const conditionGroup = conditionGroups[index];

  const newConditionGroupData: ConditionData[] = [
    ...conditionGroup.data,
    { id: uniqueId('condition-'), type: '', value: '' },
  ];
  const newConditionGroups = cloneDeep(conditionGroups);
  newConditionGroups[index].data = newConditionGroupData;

  return newConditionGroups;
}

function deleteCondition({
  conditionGroups,
  groupIndex,
  conditionIndex,
}: {
  conditionGroups: ConditionGroup[];
  groupIndex: number;
  conditionIndex: number;
}): ConditionGroup[] {
  if (conditionGroups.length < 1) {
    return [];
  }
  const newConditionGroups = cloneDeep(conditionGroups);
  const conditionGroup = newConditionGroups[groupIndex];

  // remove an AND condition by its index
  const newConditionGroupData: ConditionData[] = conditionGroup.data.filter(
    (_, index) => index !== conditionIndex
  );

  /**
   * we removed the last condition of the group,
   * so we need to remove the group itself
   */
  if (newConditionGroupData.length < 1) {
    return newConditionGroups.filter((_, index) => index !== groupIndex);
  }

  newConditionGroups[groupIndex].data = newConditionGroupData;

  return newConditionGroups;
}

function updateCondition({
  conditionGroups,
  groupIndex,
  conditionIndex,
  condition,
}: {
  conditionGroups: ConditionGroup[];
  groupIndex: number;
  conditionIndex: number;
  condition: Partial<ConditionData>;
}): ConditionGroup[] {
  const newConditionGroups = cloneDeep(conditionGroups);
  const originalCondition = conditionGroups[groupIndex].data[conditionIndex];

  set(newConditionGroups, `[${groupIndex}].data[${conditionIndex}]`, {
    ...originalCondition,
    ...condition,
  });

  return newConditionGroups;
}

function findGroupForCondition({
  conditionId,
  conditionGroups,
}: {
  conditionId: string;
  conditionGroups: ConditionGroup[];
}): ConditionGroup | undefined {
  return conditionGroups.find(group =>
    group.data.some(condition => condition.id === conditionId)
  );
}

/**
 * Moves a condition from one condition group to another within a list of condition groups.
 *
 * @param {Object} params - The parameters for moving conditions.
 * @param {ConditionGroup[]} params.conditionGroups - The current list of condition groups.
 * @param {string} params.activeId - The ID of the condition to move.
 * @param {string} params.overId - The ID of the target condition within a condition group.
 * @returns {ConditionGroup[]} The updated list of condition groups after moving the condition.
 *
 * @description
 * This function moves a condition identified by `activeId` from its current group to the position
 * before a condition identified by `overId` in potentially another group. It handles the movement
 * by first finding the current groups of both conditions, removing the active condition from its
 * current group, and inserting it into the new group in the position before the condition identified
 * by `overId`. If either condition cannot be found in any group, the original list of condition groups
 * is returned unchanged.
 */
function moveCondition({
  conditionGroups,
  activeId,
  overId,
}: {
  conditionGroups: ConditionGroup[];
  activeId: string;
  overId: string;
}): ConditionGroup[] {
  const newConditionGroups = cloneDeep(conditionGroups);
  const activeConditionGroup = findGroupForCondition({
    conditionId: activeId,
    conditionGroups,
  });

  const overConditionGroup = findGroupForCondition({
    conditionId: overId,
    conditionGroups,
  });

  if (!activeConditionGroup || !overConditionGroup) {
    return conditionGroups;
  }

  const activeConditionGroupIndex = conditionGroups.findIndex(
    group => group.id === activeConditionGroup.id
  );

  const overConditionGroupIndex = conditionGroups.findIndex(
    group => group.id === overConditionGroup.id
  );
  const activeCondition = activeConditionGroup.data.find(
    condition => condition.id === activeId
  );

  const overConditionIndex = overConditionGroup.data.findIndex(
    condition => condition.id === overId
  );

  // remove the condition from the previous group
  newConditionGroups[activeConditionGroupIndex] = {
    ...newConditionGroups[activeConditionGroupIndex],
    data: newConditionGroups[activeConditionGroupIndex].data.filter(
      condition => condition.id !== activeId
    ),
  };

  //add the removed condition to the new group
  if (activeCondition) {
    newConditionGroups[overConditionGroupIndex].data.splice(
      overConditionIndex,
      0,
      activeCondition
    );
  }

  if (newConditionGroups[activeConditionGroupIndex].data.length < 1) {
    return newConditionGroups.filter(
      (_, index) => index !== activeConditionGroupIndex
    );
  }

  return newConditionGroups;
}

function getConditions(
  datasource: string
): { label: string; options: Condition[] }[] {
  switch (datasource) {
    case 'APPLICATIONS':
      return applicationReportConditions;
    case 'LOCATIONS':
      return locationReportConditions;
    case 'BOOKINGS':
      return bookingReportConditions;
    default:
      return [];
  }
}

function downloadCSV(data: Blob): void {
  const {
    URL: { createObjectURL, revokeObjectURL },
    setTimeout,
  } = window;

  const blob = new Blob([data], { type: 'text/csv' });
  const url = createObjectURL(blob);

  const anchor = document.createElement('a');
  anchor.setAttribute('href', url);
  anchor.setAttribute('download', `admit-report-${Date.now()}`);
  anchor.click();

  setTimeout(() => {
    revokeObjectURL(url);
  }, 100);
}

export {
  addAndCondition,
  addAndConditionToOrGroup,
  addOrCondition,
  deleteCondition,
  downloadCSV,
  findGroupForCondition,
  getConditions,
  moveCondition,
  updateCondition,
};
