import { ResourceDay } from '@/types/location-resource';
import { getCapacityAndAllocatedRange } from '@/utils/location-resource-utils';
import { generateClassByCapacity } from '@/utils/location-utils';
import { GenericResourceReference } from '@admissions-support/types';
import { capitalize } from 'lodash-es';

type CapacityGroupProps = {
  resourceDays: ResourceDay[];
  ratio: GenericResourceReference;
};

function CapacityGroup(props: CapacityGroupProps) {
  const { resourceDays, ratio } = props;

  const range = getCapacityAndAllocatedRange(resourceDays, ratio.id.toString());
  const isAllocatedRangeFlat = range.allocated.max === range.allocated.min;
  const isCapacityRangeFlat = range.capacities.min === range.capacities.max;

  const allocatedContent = isAllocatedRangeFlat
    ? range.allocated.max
    : `${range.allocated.min}-${range.allocated.max}`;
  const capacityContent = isCapacityRangeFlat
    ? range.capacities.max
    : `${range.capacities.min}-${range.capacities.max}`;

  return (
    <div className="white-container">
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <p className="text-md font-semibold text-gray-900">{ratio.name}</p>
        </div>
        <div
          className="flex items-center gap-1 hover:cursor-pointer"
          data-tooltip-content={`${allocatedContent} Allocated of ${capacityContent}`}
          data-tooltip-id="capacity-tooltip"
        >
          <span
            className={generateClassByCapacity(
              range.capacities.max,
              range.allocated.max || 0
            )}
          >
            {allocatedContent}
          </span>{' '}
          /{' '}
          <span className="rounded-md border border-gray-200 bg-gray-50 px-2 py-0.5 text-gray-700">
            {capacityContent}
          </span>
        </div>
      </div>
      <div className="mt-4 flex flex-wrap gap-4">
        {resourceDays.map((capacity, index) => {
          const currentCapacityObj = capacity.capacities.find(
            c => c.ratioId === ratio.id.toString()
          );

          if (!currentCapacityObj) {
            return;
          }

          const greenClassWidth = currentCapacityObj.capacity
            ? (currentCapacityObj.allocated / currentCapacityObj.capacity) * 100
            : 0;
          const grayClassWidth = currentCapacityObj.capacity
            ? 100 -
              (currentCapacityObj.allocated / currentCapacityObj.capacity) * 100
            : 100;

          return (
            <div key={index} className="min-w-[70px] flex-1 space-y-2">
              <div className="flex">
                <div
                  className="h-1 rounded-l-md bg-primary-300"
                  style={{ width: `${greenClassWidth}%` }}
                />
                <div
                  className="h-1 rounded-l-md bg-primary-50"
                  style={{ width: `${grayClassWidth}%` }}
                />
              </div>
              <p className="text-xs font-semibold text-gray-700">
                {capitalize(capacity.day)}
              </p>
              <p className="text-sm text-gray-600">{`${
                currentCapacityObj.allocated || 0
              }/${currentCapacityObj.capacity || 0}`}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export { CapacityGroup };
