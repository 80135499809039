import { createColumnHelper } from '@tanstack/react-table';
import { BookingListing } from '@admissions-support/types';
import { useMemo } from 'react';
import { TextCell } from '../cells/text-cell';
import { format } from 'date-fns';
import { capitalize } from 'lodash-es';
import { PaymentStatusTag } from '@/components/payment-status-tag';
import { ApplicationStatusTag } from '@/components/application/application-status-tag';
import { formatCurrency } from '@/utils/format-currency';

function useOutputBookingsTableColumns() {
  const columnHelper = createColumnHelper<BookingListing>();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('child.name', {
        id: 'child.name',
        cell: info => {
          const childName = info.getValue();

          return <TextCell cellValue={childName} />;
        },
        header: () => <span>Child Name</span>,
      }),
      columnHelper.accessor('date', {
        id: 'date',
        cell: info => {
          const date = format(new Date(info.getValue()), 'dd/MM/yyyy') || '--';

          return <TextCell cellValue={date} />;
        },
        header: () => <span>Date</span>,
      }),
      columnHelper.accessor('location.name', {
        id: 'location.name',
        cell: info => {
          const locationName = info.getValue();

          return <TextCell cellValue={locationName} />;
        },
        header: () => <span>Location Name</span>,
      }),
      columnHelper.accessor('paymentStatus', {
        id: 'paymentStatus',
        cell: info => {
          if (info.row.original.status === 'CANCELLED') {
            return (
              <ApplicationStatusTag status="PLACEMENT_REJECTED">
                Cancelled
              </ApplicationStatusTag>
            );
          }
          const paymentStatus = info.getValue();

          return (
            <PaymentStatusTag status={paymentStatus}>
              {capitalize(paymentStatus)}
            </PaymentStatusTag>
          );
        },
        header: () => <span>Payment Status</span>,
      }),
      columnHelper.accessor('price', {
        cell: info => {
          return (
            <span className="font-mono">
              {formatCurrency(Number(info.getValue()))}
            </span>
          );
        },
        header: () => <span>Cost</span>,
        enableSorting: false,
        size: 99,
      }),
      columnHelper.accessor('productName', {
        cell: info => {
          return info.getValue();
        },
        header: () => <span>Product Name</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('time', {
        cell: info => {
          return <span className="font-mono">{info.getValue()}</span>;
        },
        header: () => <span>Time</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('status', {
        id: 'status',
        cell: info => {
          const bookingStatus = info.getValue() || '--';
          return (
            <ApplicationStatusTag
              status={
                bookingStatus === 'ACTIVE' ? 'PLACED' : 'PLACEMENT_REJECTED'
              }
            >
              {capitalize(bookingStatus)}
            </ApplicationStatusTag>
          );
        },
        header: () => (
          <span className="text-xs text-gray-600">Booking Status</span>
        ),
        enableSorting: false,
      }),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    columns,
  };
}

export { useOutputBookingsTableColumns };
