import AdmitLogo from '@/assets/logo.svg?react';

function PoweredBy() {
  return (
    <div className="inline-flex items-center">
      <p className="mr-2 text-xs leading-4 text-gray-600">Powered by</p>
      <a
        href="https://admit.app?utm_source=admit-admin&utm_medium=powered-by"
        target="_blank"
        rel="noreferrer"
      >
        <AdmitLogo className="h-3 w-auto" />
      </a>
    </div>
  );
}

export { PoweredBy };
