import { SchoolYear } from '@admissions-support/types';
import { isFuture, isValid } from 'date-fns';
import * as yup from 'yup';

const recordTransactionFormSchema = yup.object({
  reference: yup
    .string()
    .required()
    .when('type', ([type], schema) => {
      if (type === 'CREDIT') {
        return yup.string().label('Payment Reference');
      }
      return schema;
    })
    .label('Payment Reference'),
  type: yup.string().oneOf(['PAYMENT', 'REFUND', 'CREDIT']).required(),
  amount: yup
    .number()
    .typeError('Amount must be a number')
    .min(0.01)
    .required()
    .label('Amount'),
  date: yup
    .string()
    .required()
    .when('type', ([type], schema) => {
      if (type === 'CREDIT') {
        return yup.string().label('Credit date');
      }
      return schema;
    })
    .test('invalidDate', 'Must be a valid date', value => {
      return isValid(new Date(value));
    })
    .test(
      'cannotBeFutureDate',
      'Transaction date cannot be in the future',
      function test(value) {
        const { schoolYear } = this.options.context as {
          schoolYear?: SchoolYear;
        };

        if (!schoolYear) {
          return true;
        }
        return !isFuture(new Date(value));
      }
    )
    .label('Transaction Date'),
  source: yup
    .string()
    .when('type', ([type], schema) => {
      if (type === 'PAYMENT') {
        return yup.string().required().label('Source');
      }
      return schema.optional();
    })
    .label('Source'),
  notes: yup.string().label('Notes'),
  sourceDescription: yup
    .string()
    .when('source', ([source], schema) => {
      if (source === 'OTHER') {
        return yup.string().required().label('Source Description');
      }
      return schema;
    })
    .label('Source Description'),
});

export { recordTransactionFormSchema };
