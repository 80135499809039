import {
  Condition as ConditionItemType,
  ConditionSelectOption,
  ConditionType,
  QueryBuilderFormData,
} from '@/components/reporting/reporting.type';
import { useAreas } from '@/hooks/query-hooks/use-areas';
import { useLocation } from '@/hooks/query-hooks/use-location';
import { useLocations } from '@/hooks/query-hooks/use-locations';
import { useProduct } from '@/hooks/query-hooks/use-product';
import { useProductsByLocationId } from '@/hooks/query-hooks/use-products-by-location-id';
import { useRatios } from '@/hooks/query-hooks/use-ratios';
import { useSessionTypes } from '@/hooks/query-hooks/use-session-types';
import { useSessions } from '@/hooks/query-hooks/use-sessions';
import { useTerm } from '@/hooks/query-hooks/use-term';
import { useTerms } from '@/hooks/query-hooks/use-terms';
import { getConditions } from '@/utils/reporting-utils';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Grid01, Trash01 } from '@untitled-ui/icons-react';
import { useEffect, useState } from 'react';
import { get, useFormContext } from 'react-hook-form';
import { SingleValue } from 'react-select';
import { twMerge } from 'tailwind-merge';
import {
  GroupSelect,
  GroupSelectBase,
  GroupSelectOption,
} from '../form/common/group-select';
import { TextInput } from '../form/common/text-input';

type ConditionProps =
  | {
      id: string;
      groupIndex: number;
      conditionIndex: number;
      onRemove: () => void;
      isOverlay?: never;
    }
  | {
      id: string;
      groupIndex: number;
      conditionIndex: number;
      isOverlay: true;
      onRemove?: never;
    };

function Condition(props: ConditionProps) {
  const { groupIndex, conditionIndex, onRemove, id, isOverlay } = props;
  const { watch, setValue, formState } = useFormContext<QueryBuilderFormData>();
  const [schoolYear, setSchoolYear] = useState<GroupSelectOption | null>(null);
  const [location, setLocation] = useState<GroupSelectOption | null>(null);

  const conditionTypeInputError = get(
    formState.errors,
    `conditionGroups.${groupIndex}.data.${conditionIndex}.type`
  );

  const condition = watch(
    `conditionGroups.${groupIndex}.data.${conditionIndex}`
  );

  const datasource = watch('dataSource');
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id });

  const style = {
    // Use Translate instead of Transform if overlay is disabled
    // transform: CSS.Translate.toString(transform),
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const isLocationQueryNeeded =
    condition?.type === 'locationId' ||
    condition?.type === 'sessionId' ||
    condition?.type === 'productId';

  const {
    data: ratios,
    isLoading: isRatioLoading,
    isSuccess: isRatioSuccess,
  } = useRatios({
    enabled: condition?.type === 'ratioId',
  });

  const {
    data: areas,
    isLoading: isAreasLoading,
    isSuccess: isAreasSuccess,
  } = useAreas({
    enabled: condition?.type === 'areaName',
  });

  const {
    data: locations,
    isLoading: isLocationsLoading,
    isSuccess: isLocationSuccess,
  } = useLocations({
    enabled: isLocationQueryNeeded,
  });

  const {
    data: schoolYears,
    isLoading: isSchoolYearsLoading,
    isSuccess: isSchoolYearsSuccess,
  } = useTerms({
    enabled: condition?.type === 'schoolYearId',
  });

  const {
    data: sessionTypes,
    isLoading: isSessionTypesLoading,
    isSuccess: isSessionTypesSuccess,
  } = useSessionTypes({
    enabled: condition?.type === 'sessionTypeId',
  });

  // for sessions we also need to ask the user to select
  // school year and location
  const {
    data: sessions,
    isLoading: isSessionsLoading,
    isSuccess: isSessionsSuccess,
  } = useSessions(
    location ? location.value : '',
    schoolYear ? schoolYear.value : '',
    {
      enabled: condition?.type === 'sessionId' && !!location && !!schoolYear,
    }
  );

  // for locations we also need to ask the user to select
  // school year and location
  const {
    data: products,
    isLoading: isProductsLoading,
    isSuccess: isProductsSuccess,
  } = useProductsByLocationId(
    location ? location.value : '',
    { limit: 999, schoolYearId: schoolYear ? schoolYear.value : '' },
    {
      enabled: condition?.type === 'productId' && !!location && !!schoolYear,
    }
  );

  // we need to prefill from a single product the location and the school year
  const { data: product, isSuccess: isProductSuccess } = useProduct(
    condition ? condition.value : '',
    {
      enabled: condition?.type === 'productId' && !!condition?.value,
    }
  );

  //TODO: prefill sessions as well

  const { data: selectedSchoolYear, isSuccess: isSelectedSchoolYearSuccess } =
    useTerm(isProductSuccess ? product.schoolYear.toString() : '', {
      enabled: condition?.type === 'productId' && isProductSuccess,
    });

  const { data: selectedLocation, isSuccess: isSelectedLocationSuccess } =
    useLocation(isProductSuccess ? product.location.toString() : '', {
      enabled: condition?.type === 'productId' && isProductSuccess,
    });

  useEffect(() => {
    if (!isSelectedSchoolYearSuccess) {
      return;
    }

    setSchoolYear({
      label: selectedSchoolYear.name,
      value: selectedSchoolYear.id,
    });
  }, [
    isSelectedSchoolYearSuccess,
    selectedSchoolYear?.id,
    selectedSchoolYear?.name,
  ]);

  useEffect(() => {
    if (!isSelectedLocationSuccess) {
      return;
    }

    setLocation({
      label: selectedLocation.name,
      value: selectedLocation.id,
    });
  }, [isSelectedLocationSuccess, selectedLocation?.id, selectedLocation?.name]);

  if (!condition) {
    return null;
  }

  const isConditionValueLoading =
    (isRatioLoading && condition.type === 'ratioId') ||
    (isSchoolYearsLoading && condition.type === 'schoolYearId') ||
    (isSessionTypesLoading && condition.type === 'sessionTypeId') ||
    (isSessionsLoading && condition.type === 'sessionId') ||
    (isProductsLoading && condition.type === 'productId') ||
    (isAreasLoading && condition.type === 'areaName') ||
    (isLocationsLoading && isLocationQueryNeeded);

  const conditions: { label: string; options: ConditionItemType[] }[] =
    getConditions(datasource);

  const selectedCondition = conditions
    .flatMap(group => group.options)
    .find(item => item.value === condition.type);

  const formattedRatio = isRatioSuccess
    ? ratios.map(ratio => ({ value: ratio.id, label: ratio.name }))
    : [];
  const formattedLocation = isLocationSuccess
    ? locations.map(locaiton => ({ value: locaiton.id, label: locaiton.name }))
    : [];
  const formattedSchoolYear = isSchoolYearsSuccess
    ? schoolYears.map(schoolYear => ({
        value: schoolYear.id,
        label: schoolYear.name,
      }))
    : [];
  const formattedSessiontypes: ConditionSelectOption[] = isSessionTypesSuccess
    ? sessionTypes.map(sessionType => ({
        value: sessionType.id,
        label: sessionType.name,
      }))
    : [];
  const formattedSessions: ConditionSelectOption[] = isSessionsSuccess
    ? sessions.map(session => ({
        value: session.id,
        label: session.name,
      }))
    : [];

  const formattedProducts: ConditionSelectOption[] = isProductsSuccess
    ? products.docs.map(product => ({
        value: product.id,
        label: product.name,
      }))
    : [];
  const formattedAreas: ConditionSelectOption[] = isAreasSuccess
    ? areas.map(product => ({
        value: product.id,
        label: product.name,
      }))
    : [];

  const getConditionSelectOptions = (
    defaultOptions: ConditionSelectOption[]
  ) => {
    switch (condition.type) {
      case 'ratioId':
        return formattedRatio;
      case 'locationId':
        return formattedLocation;
      case 'schoolYearId':
        return formattedSchoolYear;
      case 'sessionTypeId':
        return formattedSessiontypes;
      case 'sessionId':
        return formattedSessions;
      case 'productId':
        return formattedProducts;
      case 'areaName':
        return formattedAreas;
      default:
        return defaultOptions;
    }
  };

  const handleTypeChange = (newValue: SingleValue<GroupSelectOption>) => {
    //we need to reset the value if we change the type

    if (!newValue?.value) {
      return;
    }

    setLocation(null);
    setSchoolYear(null);

    setValue(
      `conditionGroups.${groupIndex}.data.${conditionIndex}.type`,
      newValue.value as ConditionType,
      { shouldValidate: true }
    );

    setValue(`conditionGroups.${groupIndex}.data.${conditionIndex}.value`, '', {
      shouldDirty: false,
      shouldValidate: false,
      shouldTouch: false,
    });
  };

  const handleValueChange = (newValue: SingleValue<GroupSelectOption>) => {
    if (!newValue?.value) {
      return;
    }

    setValue(
      `conditionGroups.${groupIndex}.data.${conditionIndex}`,
      {
        id: condition.id,
        type: condition.type,
        value: newValue.value,
      },
      { shouldValidate: true, shouldDirty: true, shouldTouch: true }
    );
  };

  const handleSchoolYearChange = (value: SingleValue<GroupSelectOption>) => {
    setSchoolYear(value);
    setValue(`conditionGroups.${groupIndex}.data.${conditionIndex}.value`, '');
  };

  const handleLocationChange = (value: SingleValue<GroupSelectOption>) => {
    setLocation(value);
    setValue(`conditionGroups.${groupIndex}.data.${conditionIndex}.value`, '');
  };

  return (
    <div
      className={twMerge(
        'light-gray-container flex p-0',
        isDragging && 'opacity-50'
      )}
      style={style}
      ref={setNodeRef}
    >
      <button className="px-2" {...attributes} {...listeners} type="button">
        <Grid01 className="h-5 w-5" viewBox="0 0 24 24" aria-hidden="true" />
      </button>

      <div
        className={twMerge(
          'grid w-full grid-cols-2 gap-4 border-x px-3 py-2',
          condition.type === 'sessionId' || condition.type === 'productId'
            ? 'lg:grid-cols-4'
            : ''
        )}
      >
        <GroupSelectBase
          name={`conditionGroups.${groupIndex}.data.${conditionIndex}.type`}
          placeholder="Select a Condition.."
          className="w-full"
          value={selectedCondition}
          onChange={handleTypeChange}
          options={conditions}
          error={conditionTypeInputError?.message}
          isDisabled={formState.isSubmitting}
        />

        {!selectedCondition && (
          <GroupSelect
            name={`conditionGroups.${groupIndex}.data.${conditionIndex}.value`}
            placeholder="Select.."
            className="w-full"
            options={[]}
            isDisabled
          />
        )}

        {(condition.type === 'sessionId' || condition.type === 'productId') && (
          <GroupSelectBase
            name={`location`}
            placeholder="Select a Location.."
            className="w-full"
            value={location}
            onChange={handleLocationChange}
            options={formattedLocation}
            isDisabled={formState.isSubmitting}
          />
        )}

        {(condition.type === 'sessionId' || condition.type === 'productId') && (
          <GroupSelectBase
            name={`school-year`}
            placeholder="Select a School Year.."
            className="w-full"
            value={schoolYear}
            onChange={handleSchoolYearChange}
            options={formattedSchoolYear}
            isDisabled={!location || formState.isSubmitting}
          />
        )}
        {selectedCondition && selectedCondition.inputType === 'select' && (
          <GroupSelectBase
            name={`conditionGroups.${groupIndex}.data.${conditionIndex}.value`}
            className="w-full"
            placeholder="Select.."
            value={getConditionSelectOptions(selectedCondition.options).find(
              a => a.value === condition.value
            )}
            onChange={handleValueChange}
            options={getConditionSelectOptions(selectedCondition.options)}
            error={
              get(
                formState.errors,
                `conditionGroups.${groupIndex}.data.${conditionIndex}.value`
              )?.message
            }
            isDisabled={
              isConditionValueLoading ||
              (condition.type === 'sessionId' && !schoolYear) ||
              (condition.type === 'productId' && !schoolYear) ||
              formState.isSubmitting
            }
          />
        )}

        {selectedCondition &&
          (selectedCondition.inputType === 'text' ||
            selectedCondition.inputType === 'date') && (
            <TextInput
              name={`conditionGroups.${groupIndex}.data.${conditionIndex}.value`}
              className="w-full"
              disabled={formState.isSubmitting}
              type={selectedCondition.inputType}
            />
          )}
      </div>

      <button
        className="px-2"
        type="button"
        disabled={isOverlay || formState.isSubmitting}
        onClick={isOverlay ? undefined : onRemove}
      >
        <Trash01 className="h-5 w-5" viewBox="0 0 24 24" aria-hidden="true" />
      </button>
    </div>
  );
}

export { Condition };
