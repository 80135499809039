import { ParentChildDetailsForm } from '@/components/application/child-details/parent-child-details.form';
import { Box } from '@/components/box';
import { parentRouterPath } from '@/config/route-paths.config';
import { useCreateChildMutation } from '@/hooks/create-hooks/use-create-child-mutation';
import { CreateChildDto } from '@admissions-support/types';
import { generatePath, useNavigate } from 'react-router-dom';

function CreateChild() {
  const { mutateAsync: createChild, isPending: isChildCreating } =
    useCreateChildMutation();

  const navigate = useNavigate();

  const handleCreateChild = async (data: CreateChildDto) => {
    const child = await createChild(data);

    navigate(
      generatePath(parentRouterPath.CHILD_OVERVIEW, {
        id: child.id,
      })
    );
  };

  return (
    <Box>
      <div className="mb-6">
        <p className="text-md font-semibold">Add Child</p>
        <p className="text-md">
          Please ensure your child’s details match their birth certificate or
          passport details.
        </p>
      </div>
      <ParentChildDetailsForm
        onSubmit={handleCreateChild}
        isMutating={isChildCreating}
      />
    </Box>
  );
}

export { CreateChild };
