import { productService } from '@/services/product.service';
import { HttpError } from '@/types/error';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useDeleteProductMutation(
  mutationOptions?: UseMutationOptions<void, HttpError, string>
) {
  return useMutation({
    mutationFn: productService.deleteProduct,
    ...mutationOptions,
  });
}

export { useDeleteProductMutation };
