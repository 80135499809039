import { applicationTemplateKey } from '@/config/query-keys';
import { applicationTemplateService } from '@/services/application-template.service';
import { HttpError } from '@/types/error';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useDeleteApplicationTemplateMutation(
  mutationOptions?: UseMutationOptions<void, HttpError, string>
) {
  return useMutation({
    mutationKey: applicationTemplateKey.delete,
    mutationFn: applicationTemplateService.delete,
    ...mutationOptions,
  });
}

export { useDeleteApplicationTemplateMutation };
