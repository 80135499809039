/**
 * We want to be able to run both admin and parent dashboard
 * locally at the same time, so we differentiate it with url.
 * Deployed on AWS we differenciate them with VITE_DASHBOARD env variable
 * Example:
 * Admit Dashboard url: https://dashboard-westlothian-dev.admit.app/
 * Admit Parent url: https://westlothian-dev.admit.app/
 *
 */
function isAdminDashboard(): boolean {
  if (import.meta.env.VITE_ENVIRONMENT !== 'local') {
    return import.meta.env.VITE_DASHBOARD === 'admin';
  }

  const { hostname } = window.location;
  const parts = hostname.split('.');

  if (parts.length > 0 && parts[0].includes('dashboard-')) {
    return true;
  }

  return false;
}

export { isAdminDashboard };
