import { Table } from '@/components/table';
import { staffRouterPath } from '@/config/route-paths.config';
import { useAreas } from '@/hooks/query-hooks/use-areas';
import { getErrorMessage } from '@/utils/get-error-message';
import { Area } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import { Edit01 } from '@untitled-ui/icons-react';
import { useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

function LocationGroupingTable() {
  const { data: areas, isLoading, error } = useAreas();

  const columnHelper = createColumnHelper<Area>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'areaName',
        cell: info => {
          const name = info.getValue();
          return <span className="text-sm text-gray-600">{name}</span>;
        },
        header: () => <span className="text-xs text-gray-600">Name</span>,
        enableSorting: true,
      }),
      columnHelper.accessor('locations', {
        id: 'locations',
        cell: info => {
          const locations =
            info
              .getValue()
              .map(location => location.name)
              .join(', ') || '--';

          const isOverflow = locations.length > 40;

          return (
            <span
              className={twMerge(
                'block max-w-72 truncate text-sm text-gray-600',
                isOverflow ? 'hover:cursor-pointer' : ''
              )}
              data-tooltip-content={locations}
              data-tooltip-id={isOverflow ? 'locations-tooltip' : undefined}
            >
              {locations}
            </span>
          );
        },
        header: () => <span className="text-xs text-gray-600">Locations</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('locations', {
        id: 'locationsCount',
        cell: info => {
          const locationsCount = info.getValue().length;
          return (
            <span className="text-sm text-gray-600">{locationsCount}</span>
          );
        },
        header: () => (
          <span className="text-xs text-gray-600">Location Count</span>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor('id', {
        id: 'actions',
        cell: info => {
          const areaId = info.getValue();
          return (
            <Link
              className="icon-link block"
              to={generatePath(staffRouterPath.EDIT_LOCATION_GROUPING, {
                id: areaId,
              })}
            >
              <Edit01
                className="h-5 w-5"
                viewBox="0 0 24 24"
                aria-hidden="true"
              />
            </Link>
          );
        },
        header: () => null,
        enableSorting: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Tooltip
        id="locations-tooltip"
        className="tooltip max-w-80"
        place="top"
      />
      <Table
        paginationType="auto"
        columns={columns}
        isLoading={isLoading}
        error={getErrorMessage(error)}
        data={areas}
        EmptyState={
          <div className="py-12 text-center">
            <h3 className="mb-5 mt-2 text-sm font-semibold text-gray-900">
              No areas found, try adding some
            </h3>
            <Link
              className="btn btn-primary"
              to={staffRouterPath.NEW_LOCATION_GROUPING}
            >
              Add area
            </Link>
          </div>
        }
      />
    </>
  );
}

export { LocationGroupingTable };
