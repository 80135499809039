import { formatDateToShortDate } from '@/utils/format-date';
import { ChildSiblings } from '@admissions-support/types';
import { Detail } from './detail';

type SiblingDetailsProps = {
  data: ChildSiblings;
};

function SiblingDetails(props: SiblingDetailsProps) {
  const { data } = props;

  return (
    <div className="light-gray-container space-y-3">
      <Detail label="First Name" content={data.firstName} />
      <Detail label="Last Name" content={data.lastName} />
      <Detail
        label="Date of Birth"
        content={
          <span className="font-mono">
            {formatDateToShortDate(data.dateOfBirth)}
          </span>
        }
      />
      <Detail
        label="Sex"
        content={<span className="capitalize">{data.sex}</span>}
      />
      <Detail label="School" content={data.school?.name} />
    </div>
  );
}

export { SiblingDetails };
