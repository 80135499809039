import { FormColEmptyState } from '@/components/form-col-empty-state';
import { ActivityLog } from '@admissions-support/types';
import { ActivitylogItem } from './activity-log-item';
import { Tooltip } from 'react-tooltip';

type ChangeLogSectionProps = {
  activityLogs: ActivityLog[];
};

function ChangeLogSection(props: ChangeLogSectionProps) {
  const { activityLogs } = props;

  return (
    <div className="pt-6">
      <Tooltip id="change-log-tooltip" place="top" className="tooltip" />
      <p className="text-lg text-gray-900">Changelog</p>
      {activityLogs.length > 0 ? (
        <div className="space-y-6 pt-6">
          {activityLogs.map((logItem, logIndex) => (
            <ActivitylogItem
              key={logItem.id}
              data={logItem}
              isLast={activityLogs.length - 1 === logIndex}
            />
          ))}
        </div>
      ) : (
        <FormColEmptyState title="No Changelog to display" />
      )}
    </div>
  );
}

export { ChangeLogSection };
