import { useAuth } from '@/context/auth.context';
import { useTotpCode } from '@/hooks/use-totp-code';
import { Copy03 } from '@untitled-ui/icons-react';
import { QRCodeCanvas } from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Navigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { AuthenticatorDto, AuthenticatorForm } from './form/authenticator.form';
import { Spinner } from './spinner';

function TotpMfaSetup() {
  const { data, isLoading, isError, isFetching } = useTotpCode();
  const { verifyTotp } = useAuth();

  if (isLoading || isFetching) {
    return (
      <div className="flex justify-center py-8">
        <Spinner />
      </div>
    );
  }

  if (isError || (!isLoading && !data)) {
    return <Navigate to="/auth/sign-in" replace />;
  }

  const str =
    'otpauth://totp/Admit.app:' + data?.email + '?secret=' + data?.code;

  const handleTotp = async (data: AuthenticatorDto) => {
    await verifyTotp(data.code);
  };

  return (
    <div>
      <p className="mb-8">
        Scan the QR Code below on your authentication app and enter the code
        generated to continue. Alternatively copy the hash code and paste into
        your authentication app.
      </p>
      <div>
        <label htmlFor="hash" className="label">
          Hash Code
        </label>
        <div className="relative mt-2">
          <input
            name="hash"
            type="text"
            className="input pr-10"
            value={data?.code}
            disabled
          />

          <Tooltip
            anchorSelect="#copy-secret-key"
            content="Copy Secret Key"
            place="top"
            className="tooltip"
          />
          <CopyToClipboard text={data?.code || ''}>
            <button
              id="copy-secret-key"
              className="absolute inset-y-0 right-0 flex items-center pr-3"
              tabIndex={-1}
              type="button"
            >
              <Copy03 className="text-gray-500" aria-hidden="true" />
            </button>
          </CopyToClipboard>
        </div>
      </div>
      <div className="my-8 flex justify-center">
        <QRCodeCanvas value={str} size={198} />
      </div>
      <AuthenticatorForm onVerify={handleTotp} />
    </div>
  );
}

export { TotpMfaSetup };
