import { applicationTemplateKey } from '@/config/query-keys';
import { applicationTemplateService } from '@/services/application-template.service';
import { HttpError } from '@/types/error';
import { ApplicationTemplate } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useApplicationTemplates(
  queryOptions?: Partial<UseQueryOptions<ApplicationTemplate[], HttpError>>
) {
  return useQuery({
    queryKey: applicationTemplateKey.list,
    queryFn: applicationTemplateService.list,
    ...queryOptions,
  });
}

export { useApplicationTemplates };
