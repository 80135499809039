import { isAdminDashboard } from './is-dashboard';

export const getSubDomain = (): string => {
  const { hostname } = window.location;
  const parts = hostname.split('.');

  // for PR previews-s where we cannot set up custom subdomains
  if (import.meta.env.VITE_SUBDOMAIN_OVERRIED) {
    return import.meta.env.VITE_SUBDOMAIN_OVERRIED;
  }

  if (parts.length > 2) {
    const subdomain = parts[0];

    // e.g.: dashboard-westlothian.admit.app
    if (isAdminDashboard()) {
      return subdomain.replace('dashboard-', '');
    }

    // e.g.: westlothian.admit.app
    return subdomain;
  }

  return '';
};
