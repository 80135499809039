import { useQuery } from '@tanstack/react-query';
import { parentService } from '@/services/parent.service';
import { SearchParentDto } from '@admissions-support/types';
import { parentKey } from '@/config/query-keys';

function useSearchParentByNameAndEmail(query: SearchParentDto) {
  return useQuery({
    queryKey: parentKey.searchByNameAndEmail(query),
    queryFn: () => parentService.searchByNameAndEmail(query),
    enabled: query.search?.length >= 3,
  });
}

export { useSearchParentByNameAndEmail };
