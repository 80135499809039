import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { Permission } from '@/types/auth';
import { Helmet } from 'react-helmet-async';
import { Navigate } from 'react-router-dom';
function Home() {
  const { hasPermission } = usePermissions();

  if (hasPermission([Permission['application:fetch']])) {
    return <Navigate to="/applications" />;
  }

  return (
    <div>
      <Helmet>
        <title>Home</title>
      </Helmet>
    </div>
  );
}

export { Home };
