import { PageTitle } from '@/components/page-title';
import { AdditionalContactsSection } from '@/components/settings/organisation-details/additional-contacts-section';
import { AdminContactsSection } from '@/components/settings/organisation-details/admin-contact-section';
import { BrandDetailsSection } from '@/components/settings/organisation-details/brand-details-section';
import { EmailContactsSection } from '@/components/settings/organisation-details/email-contacts-section';
import { UpsertOrganisationDetailsFormData } from '@/components/settings/organisation-details/form/upsert-organisation-details.type';
import { useUpsertOrganisationDetailsForm } from '@/components/settings/organisation-details/form/use-upsert-organisation-details-form';
import { OrganisationDetailsSection } from '@/components/settings/organisation-details/organisation-details.section';
import { SplitPlacementSection } from '@/components/settings/organisation-details/split-placement.section';
import { publicOrganisationKeys } from '@/config/query-keys';
import { useOrganisationData } from '@/context/organisation-control.context';
import { useUpdateOrganisationMutation } from '@/hooks/update-hooks/use-update-organisation';
import { queryClient } from '@/libs/react-query';
import { getSubDomain } from '@/utils/get-subdomain';
import { clone } from 'lodash-es';
import { FormProvider } from 'react-hook-form';

function OrganisationDetails() {
  const { data: organisation } = useOrganisationData();

  const subdomain = getSubDomain();

  const { mutateAsync: updateOrganisation, isPending: isUpdating } =
    useUpdateOrganisationMutation({
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: publicOrganisationKeys.single(subdomain),
        });
      },
    });

  const onSubmit = async (data: UpsertOrganisationDetailsFormData) => {
    const transformedData = clone(data) as Record<string, any>;

    transformedData.logo = transformedData.logo[0];
    transformedData.icon = transformedData.icon[0];
    transformedData.id = undefined;

    const response = await updateOrganisation({
      data: transformedData,
      id: organisation.id,
    });

    return response;
  };

  const { form, handleSubmit } = useUpsertOrganisationDetailsForm({
    onSubmit,
    initialValues: organisation,
  });

  return (
    <div>
      <PageTitle title="Organisation Details" variant="gray">
        <button
          className="btn btn-primary"
          type="submit"
          form="organisation-data-form"
        >
          Save
        </button>
      </PageTitle>
      <div className="mt-6">
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            id="organisation-data-form"
          >
            <OrganisationDetailsSection isDisabled={isUpdating} />
            <BrandDetailsSection isDisabled={isUpdating} />
            <SplitPlacementSection isDisabled={isUpdating} />
            <AdminContactsSection isDisabled={isUpdating} />
            <EmailContactsSection isDisabled={isUpdating} />
            <AdditionalContactsSection isDisabled={isUpdating} />
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

export { OrganisationDetails };
