import { messagingKey } from '@/config/query-keys';
import { messagingService } from '@/services/messaging.service';
import { HttpError } from '@/types/error';
import { Ticket } from '@admissions-support/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

type UseTicketProps = {
  id: string | undefined;
  isParent?: boolean;
};

function useTicket(
  { id, isParent }: UseTicketProps,
  queryOptions?: Partial<UseQueryOptions<Ticket, HttpError>>
) {
  return useQuery({
    queryKey: messagingKey.single(id || ''),
    queryFn: () => messagingService.getTicket(id || '', isParent),
    ...queryOptions,
    enabled: !!id,
  });
}

export { useTicket };
