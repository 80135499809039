import { RadioBoxBase } from '@/components/form/common/radio-box';
import { useChoicesContext } from '@/context/choices.context';
import { ChoiceFormActionTypes } from '@/reducers/choice.reducer';
import {
  getSessionDayDetailsForDay,
  selectAvailableSessions,
  selectNbOfSelectedSessions,
} from '@/utils/application-utils';
import {
  getOperatingDaysOfSessionType,
  getStartEndForDay,
} from '@/utils/location-session-utils';
import { SessionType, OperatingDaySessionDto } from '@admissions-support/types';
import { SessionRadioBoxOption } from '../../session-radio-box-option';

type SessionBoxProps = {
  selectedSessionType: SessionType;
  disabled?: boolean;
};

function SessionsBox(props: SessionBoxProps) {
  const { selectedSessionType, disabled } = props;
  const { state } = useChoicesContext();
  const nbOfSelectedSessions = selectNbOfSelectedSessions(state);
  const daysForSessionType = getOperatingDaysOfSessionType(selectedSessionType);

  const allowance = selectedSessionType.allowance;

  return (
    <div className="dark-gray-container">
      <p className="text-md mb-2 font-medium text-gray-900 sm:flex">
        Select Session
        <span className="ml-auto block font-normal text-gray-600 sm:inline-block">
          {nbOfSelectedSessions}/{allowance} Allowance
        </span>
      </p>
      <p className="text-md mb-4">
        The session type and model you have selected have the following
        sessions.
      </p>

      <div className="space-y-4">
        {daysForSessionType.map(day => (
          <DayRow
            key={day}
            day={day}
            allowance={allowance}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  );
}

function DayRow(props: {
  day: keyof OperatingDaySessionDto;
  allowance: number;
  disabled?: boolean;
}) {
  const { day, allowance, disabled } = props;
  const { state, dispatch } = useChoicesContext();
  const availableSessions = selectAvailableSessions(state);
  const nbOfSelectedSessions = selectNbOfSelectedSessions(state);

  const sessionsToDisplay = getSessionDayDetailsForDay(availableSessions, day);

  const dayValue = state.sessionsPattern[day];

  /**
   * disable the ablility to select the session if we used all our allowance
   * and the session is unselected
   */
  const isButtonDisabled =
    allowance === nbOfSelectedSessions && dayValue === null;

  const handleSessionDeSelect = (day: keyof OperatingDaySessionDto) => () => {
    dispatch({
      type: ChoiceFormActionTypes.TOGGLE_SESSION_BY_DAY,
      payload: {
        day,
        sectionId: null,
      },
    });
  };

  const handleSessionSelect = (data: {
    day: keyof OperatingDaySessionDto;
    sectionId: string;
  }) => {
    dispatch({
      type: ChoiceFormActionTypes.TOGGLE_SESSION_BY_DAY,
      payload: data,
    });
  };

  const options = sessionsToDisplay.map(session => {
    const times = getStartEndForDay(session.details);

    return {
      value: session.sessionId,
      Content: () => (
        <SessionRadioBoxOption
          className={isButtonDisabled ? 'show-error' : undefined}
          start={times.start}
          end={times.end}
        />
      ),
      disabled: isButtonDisabled,
    };
  });

  return (
    <div key={day}>
      <p className="label capitalize">{day}</p>
      <RadioBoxBase
        value={dayValue ? dayValue : undefined}
        containerClassName="grid gap-4 grid-cols-1 md:grid-cols-2 2xl:grid-cols-3"
        onChange={value => {
          // dont allow users to select more sessions if the limit is reached
          // but allow to change the actual row.
          if (isButtonDisabled) {
            return;
          }

          handleSessionSelect({ day, sectionId: value });
        }}
        onActiveClick={handleSessionDeSelect(day)}
        options={options}
        disabled={disabled}
      />
    </div>
  );
}

export { SessionsBox };
