import { useReportingOutputControl } from '@/context/reporting-output-control.context';
import { ParentReference } from '@admissions-support/types';

type OwnersCellProps = {
  owners?: ParentReference[];
};

function OwnersCell(props: OwnersCellProps) {
  const { owners } = props;

  const { toggledColumns } = useReportingOutputControl();

  if (!owners || owners?.length <= 0) {
    return <span>--</span>;
  }

  return (
    <div className="flex flex-col flex-wrap gap-1">
      {owners.map((owner, key) => (
        <span key={key}>{`${
          toggledColumns['child.owners.firstName'] ? owner.firstName : ''
        } ${
          toggledColumns['child.owners.lastName'] ? owner.lastName : ''
        }`}</span>
      ))}
    </div>
  );
}

export { OwnersCell };
