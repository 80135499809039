import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import { Cohort, CreateCohortDto } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCreateCohort(
  mutationOptions?: UseMutationOptions<Cohort, HttpError, CreateCohortDto>
) {
  return useMutation({
    mutationFn: organisationService.createCohort,
    ...mutationOptions,
  });
}

export { useCreateCohort };
