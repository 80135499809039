import { DeleteZone } from '@/components/delete-zone';
import { CohortForm } from '@/components/form/cohort.form';
import { PageTitle } from '@/components/page-title';
import { Spinner } from '@/components/spinner';
import { cohortKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useCreateCohort } from '@/hooks/create-hooks/use-create-cohort';
import { useDeleteCohortMutation } from '@/hooks/delete-hooks/use-delete-cohort-mutation';
import { useCohort } from '@/hooks/query-hooks/use-cohort';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useUpdateCohortMutation } from '@/hooks/update-hooks/use-update-cohort-mutation';
import { queryClient } from '@/libs/react-query';
import { Permission } from '@/types/auth';
import { getErrorMessage } from '@/utils/get-error-message';
import { CreateCohortDto, UpdateCohortDto } from '@admissions-support/types';
import { Navigate, useMatch, useNavigate, useParams } from 'react-router-dom';

async function invalidateCohortsQuery() {
  await queryClient.invalidateQueries({
    queryKey: cohortKey.list,
    refetchType: 'active',
  });
}

function UpsertCohort() {
  const navigate = useNavigate();
  const { mutateAsync: create, isPending: isCreating } = useCreateCohort({
    onSuccess: invalidateCohortsQuery,
  });
  const { mutateAsync: update, isPending: isUpdating } =
    useUpdateCohortMutation({
      onSuccess: invalidateCohortsQuery,
    });
  const params = useParams<{ id?: string }>();
  const isCreateCohortView = useMatch(staffRouterPath.NEW_COHORT);

  const {
    isLoading: isCohortLoading,
    data: cohort,
    isError: isCohortError,
  } = useCohort(params.id);

  const { hasPermission } = usePermissions();

  const {
    mutateAsync: deleteCohort,
    isPending: isDeleting,
    error: deleteError,
  } = useDeleteCohortMutation({
    onSuccess: async () => {
      queryClient.removeQueries({
        queryKey: cohortKey.single(params.id || ''),
      });
      await invalidateCohortsQuery();
      navigate(staffRouterPath.COHORTS);
    },
    retry: 0,
  });

  const isMutating =
    isCreating ||
    isUpdating ||
    !hasPermission([Permission['organisation:update']]);

  const handleCreateCohort = async (data: CreateCohortDto) => {
    await create(data);
    navigate(staffRouterPath.COHORTS);
  };

  const handleUpdateCohort = async (data: UpdateCohortDto) => {
    await update({
      id: params.id || '',
      data,
    });
  };

  const handleCancel = () => {
    navigate(staffRouterPath.COHORTS);
  };

  const handleDeleteCohort = async () => {
    try {
      await deleteCohort(params.id || '');
    } catch {
      // error handled in the modal
    }
  };

  if (isCohortError) {
    return <Navigate to={staffRouterPath.COHORTS} />;
  }

  return (
    <>
      <PageTitle
        title={isCreateCohortView ? 'New Cohort' : 'Edit Cohort'}
        variant="gray"
      >
        <div className="flex gap-3">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCancel}
            disabled={isMutating}
          >
            Cancel
          </button>

          <button
            className="btn btn-primary"
            disabled={isMutating}
            form="upsert-cohort-form"
          >
            {isCreateCohortView ? 'Create' : 'Update'}
          </button>
        </div>
      </PageTitle>

      {isCohortLoading && !isCreateCohortView ? (
        <div className="flex justify-center py-12">
          <Spinner className="h-12 w-12" />
        </div>
      ) : (
        <>
          <CohortForm
            onSubmit={
              isCreateCohortView ? handleCreateCohort : handleUpdateCohort
            }
            initialData={cohort}
            isLoading={isMutating}
          />
          {!isCreateCohortView &&
          hasPermission([Permission['organisation:delete']]) ? (
            <div className="two-col-form">
              <div>
                <p className="text-md font-medium leading-7 text-gray-900">
                  Destructive Actions
                </p>
                <p className="text-md leading-6 text-gray-600">
                  Take care with these actions as they are destructive and may
                  not be reversable.
                </p>
              </div>
              <div className="col-span-2 space-y-6">
                <DeleteZone
                  title="Delete Cohort"
                  actionButtonText="Delete Cohort"
                  description="This action cannot be completed if there are School Years using this cohort."
                  modalDescription="In order to delete this Cohort, please type DELETE in the input below and confirm deletion."
                  onDelete={handleDeleteCohort}
                  isLoading={isDeleting}
                  error={getErrorMessage(deleteError)}
                />
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
}

export { UpsertCohort };
