import { Breadcrumbs } from '@/components/breadcrumbs';
import RespondToMessageForm from '@/components/form/respond-to-message-form';
import { LoadingScreen } from '@/components/loading-screen';
import Message from '@/components/messaging/message';
import { parentRouterPath } from '@/config/route-paths.config';
import { useAuth } from '@/context/auth.context';
import { useTicket } from '@/hooks/query-hooks/use-ticket';
import { useMarkMessageSeen } from '@/hooks/use-mark-message-seen';
import { ArrowLeft } from '@untitled-ui/icons-react';
import { useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

const breadcrumbPaths = [
  {
    content: 'Help Desk',
    link: parentRouterPath.MESSAGING,
  },
  {
    content: 'View Ticket',
    link: parentRouterPath.MESSAGING_CONVERSATION,
  },
];

function ParentViewConversation() {
  const scrollableComponentRef = useRef<HTMLDivElement>(null);

  const { id: ticketId } = useParams();
  const {
    data: ticket,
    isLoading: isTicketLoading,
    refetch,
  } = useTicket(
    {
      id: ticketId,
      isParent: true,
    },
    {
      refetchOnWindowFocus: true,
    }
  );

  const messagesWithoutFirst = ticket?.messages?.slice(1) || [];

  const scrollToNewMessage = () => {
    if (scrollableComponentRef.current) {
      scrollableComponentRef.current.scrollTo({
        top: scrollableComponentRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const { user } = useAuth();

  const { mutateAsync: markMessageSeen } = useMarkMessageSeen({
    id: ticketId || '',
    isParent: true,
  });

  const handleMarkMessageSeen = async () => {
    const isAnyMessageUnseen = ticket?.messages.some(
      message => !message.seenBy.some(({ id }) => id.toString() === user?.id)
    );

    if (isAnyMessageUnseen) {
      await markMessageSeen(ticketId || '');
    }
  };

  useEffect(() => {
    scrollToNewMessage();
    handleMarkMessageSeen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket?.messages]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <Breadcrumbs path={breadcrumbPaths} />
        <Link
          to={parentRouterPath.MESSAGING}
          className="btn btn-secondary flex items-center gap-2"
        >
          <ArrowLeft className="size-4" />
          Back to Help Desk
        </Link>
      </div>
      {isTicketLoading ? (
        <div className="mt-12 flex min-h-96 flex-col items-center justify-center">
          <LoadingScreen />
          <div className="mt-4 text-sm font-normal text-gray-500">
            Loading ticket...
          </div>
        </div>
      ) : ticket ? (
        <div className="flex w-full justify-center">
          <div className="mt-12 w-full max-w-[660px]">
            <div className="flex flex-col gap-6">
              <div className="relative">
                {/* <div className="pointer-events-none absolute top-0 z-10 h-4 w-full bg-gradient-to-b from-white to-transparent" /> */}
                <div
                  className="flex max-h-[calc(100vh_-_430px)] flex-col gap-6 overflow-y-auto"
                  ref={scrollableComponentRef}
                >
                  {ticket?.messages?.[0] ? (
                    <Message
                      ticketId={ticketId || ''}
                      isFirstMessage
                      isParent
                      title={ticket.title}
                      name={ticket.messages[0].sender.name}
                      createdAt={ticket.messages[0].createdAt}
                      message={ticket.messages[0].message}
                      topic={ticket.topic}
                      status={ticket.status}
                      attachments={ticket.messages[0].attachments || []}
                    />
                  ) : null}
                  {messagesWithoutFirst.map(message => (
                    <Message
                      ticketId={ticketId || ''}
                      key={message.createdAt}
                      name={message.sender.name}
                      createdAt={message.createdAt}
                      message={message.message}
                      attachments={message.attachments || []}
                    />
                  ))}
                </div>
                {/* <div className="pointer-events-none absolute bottom-0 z-10 h-8 w-full bg-gradient-to-t from-white to-transparent" /> */}
              </div>
              <div>
                <RespondToMessageForm
                  ticketId={ticketId || ''}
                  onMessageSent={() => refetch()}
                  isParent
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ParentViewConversation;
