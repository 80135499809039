import { Application, ApplicationListing } from '@admissions-support/types';
import { Flag01, NotificationText } from '@untitled-ui/icons-react';
import { Tooltip } from 'react-tooltip';

type ApplicationListFlagsProps = {
  application: ApplicationListing | Application;
};

function ApplicationListFlags(props: ApplicationListFlagsProps) {
  const { application } = props;

  const autoFlags = [];

  if (application.homeStatus === 'MOVING') {
    autoFlags.push('Moving to Area');
  }

  if (application.lookedAfter.isSubjectToCourtDecision) {
    autoFlags.push('LAC');
  }

  return (
    <span className="flex">
      {autoFlags.length > 0 ? (
        <span
          data-tooltip-id={`auto-flagged-application-${application.id}`}
          data-tooltip-content={autoFlags.join(', ')}
          className="mr-2 cursor-help"
        >
          <NotificationText
            viewBox="0 0 24 24"
            className="h-4 w-4 text-yellow-500"
          />
        </span>
      ) : null}
      {application.flagsAndOverrides.isFlagged ? (
        <span
          data-tooltip-id={`flagged-application-${application.id}`}
          data-tooltip-content="Flagged"
          className="cursor-help"
        >
          <Flag01
            viewBox="0 0 24 24"
            className="h-4 w-4 fill-[#D92D20] text-[#D92D20]"
          />
        </span>
      ) : null}
      <Tooltip
        id={`auto-flagged-application-${application.id}`}
        place="top"
        className="tooltip"
      />
      <Tooltip
        id={`flagged-application-${application.id}`}
        place="top"
        className="tooltip"
      />
    </span>
  );
}

export { ApplicationListFlags };
