import { useExtraHoursBookingsControl } from '@/context/extra-hours-bookings-control.context';
import { SearchLg } from '@untitled-ui/icons-react';
import { debounce } from 'lodash-es';
import { ChangeEvent } from 'react';
import { TextInputBase } from '../form/common/text-input';

function ChildNameSearchBar() {
  const { filter, setFilter } = useExtraHoursBookingsControl();

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(prevFilter => ({
      ...prevFilter,
      search: e.target.value,
    }));
  };

  const debouncedSearch = debounce(handleSearchChange, 300);

  return (
    <TextInputBase
      type="search"
      placeholder="Name or Reference Numbers"
      className="w-[320px]"
      defaultValue={filter.search}
      onChange={debouncedSearch}
      LeadingIcon={
        <SearchLg className="h-4 w-4 text-gray-500" viewBox="0 0 24 24" />
      }
    />
  );
}

export { ChildNameSearchBar };
