import { ReportTemplatesTableFilter } from '@/components/reporting/report-templates-table';
import { reportKey } from '@/config/query-keys';
import { reportService } from '@/services/report.service';
import { HttpError } from '@/types/error';
import { ReportTemplate } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useReportTemplates(
  data: {
    filter: ReportTemplatesTableFilter;
  },
  queryOptions?: Partial<UseQueryOptions<ReportTemplate[], HttpError>>
) {
  return useQuery({
    queryKey: reportKey.listTemplates(
      data.filter.isOrganisationWide ? 'ORG' : 'ONLY_YOU'
    ),
    queryFn: () => reportService.getReportTemplates(data),
    ...queryOptions,
  });
}

export { useReportTemplates };
