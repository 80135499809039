import { ReportTemplatesTableFilter } from '@/components/reporting/report-templates-table';
import { axiosClient } from '@/libs/axios';
import { HttpError } from '@/types/error';
import {
  Application,
  BookingListing,
  CapacityReportQueryParamsDto,
  CapacityReports,
  CreateReportTemplateDto,
  CustomPaginateResult,
  DemandReportQueryParamsDto,
  DemandReports,
  GetAllocationReportDto,
  GetAllocationReportResponse,
  Location,
  MatchingScenario,
  PaginateResult,
  ReportTemplate,
  ReportsApplicationsDto,
  ReportsBookingsDto,
  ReportsLocationsDto,
  SimulateMatchingDto,
  UpdateReportTemplateDto,
} from '@admissions-support/types';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

const getReportTemplates = async (data: {
  filter: ReportTemplatesTableFilter;
}): Promise<ReportTemplate[]> => {
  const response = await axiosClient.get<
    HttpError,
    AxiosResponse<ReportTemplate[]>
  >(
    `/report-templates?includeOrganisationWideReports=${data.filter.isOrganisationWide}`
  );

  return response.data;
};

const getApplicationsReportResult = async (
  data: ReportsApplicationsDto
): Promise<PaginateResult<Application>> => {
  const response = await axiosClient.post(
    '/reports/applications/query-builder',
    data,
    {
      disableToast: true,
    }
  );
  return response.data;
};

const getBookingsReportResult = async (
  data: ReportsBookingsDto
): Promise<CustomPaginateResult<BookingListing>> => {
  const response = await axiosClient.post(
    '/reports/bookings/query-builder',
    data,
    {
      disableToast: true,
    }
  );
  return response.data;
};

const getLocationsReportResult = async (
  data: ReportsLocationsDto
): Promise<PaginateResult<Location>> => {
  const response = await axiosClient.post(
    '/reports/locations/query-builder',
    data,
    {
      disableToast: true,
    }
  );
  return response.data;
};

const exportApplicationsReportResult = async (
  data: ReportsApplicationsDto
): Promise<Blob> => {
  const response = await axiosClient.post(
    '/reports/applications/query-builder',
    data,
    {
      disableToast: true,
      responseType: 'blob',
    }
  );
  return response.data;
};

const getReportTemplate = async (id: string): Promise<ReportTemplate> => {
  const response = await axiosClient.get<
    HttpError,
    AxiosResponse<ReportTemplate>
  >(`/report-templates/${id}`);

  return response.data;
};

const createReportTemplate = async (
  data: CreateReportTemplateDto
): Promise<ReportTemplate> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<ReportTemplate>,
    CreateReportTemplateDto
  >('/report-templates', data);

  return response.data;
};

const updateReportTemplate = async ({
  data,
  id,
}: {
  data: UpdateReportTemplateDto;
  id: string;
}): Promise<ReportTemplate> => {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<ReportTemplate>,
    UpdateReportTemplateDto
  >(`/report-templates/${id}`, data);

  return response.data;
};

const deleteReportTemplate = async (id: string) => {
  await axiosClient.delete<HttpError>(`/report-templates/${id}`);
};

const exportLocationsReportResult = async (
  data: ReportsLocationsDto
): Promise<Blob> => {
  const response = await axiosClient.post(
    '/reports/locations/query-builder',
    data,
    {
      disableToast: true,
      responseType: 'blob',
    }
  );
  return response.data;
};

const exportBookinsgReportResult = async (
  data: ReportsBookingsDto
): Promise<Blob> => {
  const response = await axiosClient.post(
    '/reports/bookings/query-builder',
    data,
    {
      disableToast: true,
      responseType: 'blob',
    }
  );
  return response.data;
};

const getAllocationReport = async (
  data: GetAllocationReportDto
): Promise<GetAllocationReportResponse> => {
  const response = await axiosClient.get<
    HttpError,
    AxiosResponse<GetAllocationReportResponse>
  >('/reports/applications/allocation', {
    params: data,
    disableToast: true,
  });

  return response.data;
};

const getAllocationReportCsv = async (
  data: GetAllocationReportDto
): Promise<Blob> => {
  const response = await axiosClient.get(
    '/reports/applications/allocation/csv',
    {
      params: data,
      responseType: 'blob',
    }
  );

  return response.data;
};

const getCapacityReport = async (
  data: CapacityReportQueryParamsDto
): Promise<CapacityReports> => {
  const requestParams: AxiosRequestConfig = {
    params: data,
    disableToast: true,
  };

  const response = await axiosClient.get<
    HttpError,
    AxiosResponse<CapacityReports>
  >('/reports/applications/capacity-report', requestParams);

  return response.data;
};

const getCapacityReportCsv = async (
  data: CapacityReportQueryParamsDto
): Promise<Blob> => {
  const requestParams: AxiosRequestConfig = {
    params: data,
    disableToast: true,
    responseType: 'blob',
  };

  const response = await axiosClient.get<HttpError, AxiosResponse<Blob>>(
    '/reports/applications/capacity-report',
    requestParams
  );

  return response.data;
};

const getDemandReport = async (
  data: DemandReportQueryParamsDto
): Promise<DemandReports> => {
  const requestParams: AxiosRequestConfig = {
    params: data,
    disableToast: true,
  };

  const response = await axiosClient.get<
    HttpError,
    AxiosResponse<DemandReports>
  >('/reports/applications/demand-report', requestParams);

  return response.data;
};

const getDemandReportCsv = async (
  data: DemandReportQueryParamsDto
): Promise<Blob> => {
  const requestParams: AxiosRequestConfig = {
    params: data,
    disableToast: true,
    responseType: 'blob',
  };

  const response = await axiosClient.get<HttpError, AxiosResponse<Blob>>(
    '/reports/applications/demand-report',
    requestParams
  );

  return response.data;
};

const getSimulatedMatching = async (
  data: SimulateMatchingDto
): Promise<MatchingScenario> => {
  const response = await axiosClient.post('/matching/simulate-scenario', data, {
    disableToast: true,
  });
  return response.data;
};

export const reportService = Object.freeze({
  getAllocationReport,
  getAllocationReportCsv,
  getReportTemplates,
  getApplicationsReportResult,
  getBookingsReportResult,
  getLocationsReportResult,
  getReportTemplate,
  createReportTemplate,
  updateReportTemplate,
  exportLocationsReportResult,
  deleteReportTemplate,
  exportApplicationsReportResult,
  exportBookinsgReportResult,
  getCapacityReport,
  getCapacityReportCsv,
  getDemandReport,
  getSimulatedMatching,
  getDemandReportCsv,
});
