import * as yup from 'yup';

const applicationTemplateOverviewSchema = yup.object({
  name: yup.string().required().label('Name'),
  description: yup.string().label('Description').nullable(),
  applicationType: yup.string().label('Application Type').required(),
  cohorts: yup
    .array(yup.string().required())
    .min(1)
    .required()
    .label('Cohorts'),
  enableAdditionalCriteria: yup.boolean().required(),
  criteriaRequirement: yup.string().label('Requirement'),
  criteria: yup
    .array(
      yup.object({
        title: yup.string().required().label('Statement'),
      })
    )
    .required()
    .label('Criteria'),
});

export { applicationTemplateOverviewSchema };
