import { ChangePasswordForm } from '@/components/form/change-password.form';
import { PageTitle } from '@/components/page-title';

function UserProfile() {
  return (
    <>
      <PageTitle title="Your Details" variant="gray" />

      <div className="two-col-form mt-6">
        <div>
          <p className="text-md font-medium leading-7 text-gray-900">
            Change password
          </p>
          <p className="text-md leading-6 text-gray-600">
            Update your password associated with your account.
          </p>
        </div>
        <div className="col-span-2 space-y-6">
          <ChangePasswordForm />
        </div>
      </div>
    </>
  );
}

export { UserProfile };
