import { BreadcrumbNode } from '@/types/breadcrumb';
import { ChevronRight } from '@untitled-ui/icons-react';
import { NavLink } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

type BreadcrumbsProps = {
  path: BreadcrumbNode[];
};

function Breadcrumbs({ path }: BreadcrumbsProps) {
  return (
    <nav className="flex overflow-auto" aria-label="Breadcrumb">
      <ol className="scrollbar-hide flex w-full items-center gap-3 overflow-auto">
        {path.map((node, index) => {
          const isLastLink = index + 1 === path.length;

          const linkProps = {
            ...{
              className: twMerge(
                'text-sm whitespace-nowrap text-breadcrumb-fg',
                isLastLink
                  ? 'font-semibold cursor-default pointer-events-none'
                  : 'font-medium hover:text-brand-fg_hover'
              ),
            },
            ...(isLastLink || !node.link ? { to: '#' } : { to: node.link }),
          };

          return (
            <li key={index} className="flex items-center">
              <div className="flex items-center gap-3">
                {index > 0 && (
                  <ChevronRight
                    className="h-4 w-4 flex-shrink-0 text-gray-300"
                    aria-hidden="true"
                  />
                )}
                <NavLink
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex -- disabling rule because the link is the current page, so it shouldn't be interactive
                  tabIndex={isLastLink ? -1 : 0}
                  aria-current={isLastLink ? 'page' : undefined}
                  {...linkProps}
                  className={twMerge(
                    isLastLink
                      ? 'font-semibold text-primary-700'
                      : 'font-medium text-gray-600',
                    'text-sm',
                    (!node.link || isLastLink) &&
                      'pointer-events-none cursor-default'
                  )}
                >
                  {node.content}
                </NavLink>
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
}

export { Breadcrumbs };
