import { AuthenticatorDto } from '@/components/form/authenticator.form';
import { axiosClient } from '@/libs/axios';
import { queryClient } from '@/libs/react-query';
import {
  ConfirmPasswordResetDto,
  LoginDto,
  Permission,
  RegisterDto,
  User,
} from '@/types/auth';
import { HttpError } from '@/types/error';
import { isAdminDashboard } from '@/utils/is-dashboard';
import {
  ConfirmParentAccountDto,
  ConfirmPhoneNumberDto,
  CreateParentDto,
  ForgottenPasswordDto,
  Invitation,
  Parent,
  SignUpDto,
} from '@admissions-support/types';
import * as Sentry from '@sentry/react';
import {
  ConfirmSignInOutput,
  SignInOutput,
  confirmSignIn as amplifyConfirmSignIn,
  rememberDevice as amplifyRememberDevice,
  confirmResetPassword,
  fetchAuthSession,
  fetchMFAPreference,
  fetchUserAttributes,
  setUpTOTP,
  signIn,
  signOut,
  updateMFAPreference,
  verifyTOTPSetup,
} from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash-es';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

type AuthContextState = {
  user: User | undefined;
  permissions: Permission[];
  rememberDevice: boolean;
  isLoading: boolean;
  isLoggedIn: boolean;
  register: (dto: RegisterDto) => Promise<SignInOutput>;
  registerParent(dto: CreateParentDto): Promise<Parent>;
  login: (loginDto: LoginDto) => Promise<SignInOutput>;
  logOut: () => Promise<void>;
  confirmSignIn: (
    authenticatorDto: AuthenticatorDto
  ) => Promise<ConfirmSignInOutput>;
  handleSession: () => Promise<void>;
  listenAuthChange: () => void;
  getTotpCode: () => Promise<{
    code: string;
    email: string;
  }>;
  verifyTotp: (code: string) => Promise<void>;
  getInvitationByCode: (code: string) => Promise<Invitation>;
  confirmPhoneNumber: (data: ConfirmPhoneNumberDto) => Promise<void>;
  confirmParentAccount: (data: ConfirmParentAccountDto) => Promise<void>;
  reset: () => void;
  sendPasswordReset: (data: ForgottenPasswordDto) => Promise<void>;
  confirmPasswordReset: (data: ConfirmPasswordResetDto) => Promise<void>;
};

const AuthContext = createContext<AuthContextState>({} as AuthContextState);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [rememberDevice, setRememberDevice] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [user, setUser] = useState<User | undefined>(undefined);

  async function login(loginDto: LoginDto): Promise<SignInOutput> {
    /**
     * If parent:
     * - can log in to any organisation with the same user
     * - preferred_username === email address
     * If dashboard:
     * - can NOT log in to multiple organisation with same user
     * - preferred_username === organisationId+emailAddress
     */

    const cleanedEmail = loginDto.email.trim().toLowerCase();
    const username = isAdminDashboard()
      ? `${loginDto.organisationId}:${cleanedEmail}`
      : cleanedEmail;

    const authResponseUser = await signIn({
      username,
      password: loginDto.password,
    });

    setRememberDevice(loginDto.rememberDevice);

    return authResponseUser;
  }

  async function logOut(): Promise<void> {
    await signOut({ global: true });
  }

  async function register(dto: RegisterDto): Promise<SignInOutput> {
    // we call the registration endpoint on the backend,
    // this is not an amplify call!
    const response = await axiosClient.post<
      HttpError,
      AxiosResponse<User>,
      SignUpDto
    >(
      `/auth/sign-up`,
      {
        invitationCode: dto.invitationCode,
        password: dto.password,
      },
      {
        disableToast: true,
      }
    );

    const user = response.data;
    // in order to continue the registration flow (MFA setup)
    // we need to get the cognito cognitoUser, so we log in the cognitoUser
    // we can do it because the cognitoUser is automatically confirmed
    return await login({
      email: user.email,
      organisationId: dto.organisationId,
      password: dto.password,
      rememberDevice: false,
    });
  }

  async function registerParent(dto: CreateParentDto): Promise<Parent> {
    // we call the registration endpoint on the backend,
    // this is not an amplify call!
    const result = await axiosClient.post<
      HttpError,
      AxiosResponse<Parent>,
      CreateParentDto
    >(`/parents`, dto, {
      disableToast: true,
    });

    return result.data;
  }

  async function getTotpCode(): Promise<{
    code: string;
    email: string;
  }> {
    const response = await setUpTOTP();

    return {
      code: response.sharedSecret,
      email: user?.email || '', // at this point we can be sure we have a user
    };
  }

  async function verifyTotp(code: string) {
    // check the provided code
    await verifyTOTPSetup({ code });
    // if the code worked lets enable TOTP MFA for the user
    await updateMFAPreference({ totp: 'PREFERRED' });
    // we need to refetch the session, so we update the user as well
    await handleSession();
  }

  async function confirmSignIn(
    authenticatorDto: AuthenticatorDto
  ): Promise<ConfirmSignInOutput> {
    const response = await amplifyConfirmSignIn({
      challengeResponse: authenticatorDto.code,
    });

    if (response.isSignedIn) {
      await handleSession();
    }

    if (rememberDevice) {
      await amplifyRememberDevice();
    }

    return response;
  }

  async function handleSession() {
    try {
      const session = await fetchAuthSession();

      const { preferred, enabled } = await fetchMFAPreference();

      const userAttributes = await fetchUserAttributes();

      const tokens = session.tokens;

      if (tokens && tokens.accessToken.payload) {
        const permissions = tokens.accessToken.payload[
          'cognito:groups'
        ] as Permission[];
        setPermissions(permissions);

        setAxiosHeader(tokens.accessToken.toString());
        const userId = tokens.accessToken.payload.username as string;
        const userEmail = userAttributes.email || '';

        // if identities array is present, user used OICD login so no MFA needed
        const hasIdentityList = Boolean(tokens.idToken?.payload.identities);

        setUser({
          id: userId,
          email: userEmail,
          familyName: userAttributes.family_name || '',
          givenName: userAttributes.given_name || '',
          preferredMFA: enabled ? preferred : undefined,
          isMFARequired: !hasIdentityList,
        });

        Sentry.setUser({
          email: userEmail,
          id: userId,
        });

        Sentry.setTag(
          'organisation',
          tokens.accessToken.payload.organisationId as string
        );

        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    } catch (e) {
      console.log(e);

      setIsLoggedIn(false);
    }
  }

  async function setAxiosHeader(accessToken?: string) {
    if (accessToken) {
      axiosClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      delete axiosClient.defaults.headers.common.Authorization;
    }
  }

  function listenAuthChange(): () => void {
    const listener = Hub.listen('auth', data => {
      switch (data.payload.event) {
        case 'signedIn':
          handleSession();
          break;
        case 'signedOut':
          localStorage.removeItem('admit_application_control_main');
          localStorage.removeItem('admit_school_year');
          queryClient.clear();
          Sentry.setUser(null);
          reset();
          break;
      }
    });

    return listener;
  }

  async function getInvitationByCode(code: string): Promise<Invitation> {
    const invitation = await axiosClient.get<
      HttpError,
      AxiosResponse<Invitation>
    >(`/invitations/${code}`);

    return invitation.data;
  }

  async function confirmParentAccount(
    data: ConfirmParentAccountDto
  ): Promise<void> {
    await axiosClient.post<
      HttpError,
      AxiosResponse<void>,
      ConfirmParentAccountDto
    >(`/parents/confirm-account`, data, {
      disableToast: true,
    });
  }

  async function confirmPhoneNumber(
    verifyPhoneNumberDto: ConfirmPhoneNumberDto
  ) {
    await axiosClient.post<any, void, ConfirmPhoneNumberDto>(
      '/users/actions/confirm-phone-number',
      verifyPhoneNumberDto,
      {
        disableToast: true,
      }
    );
    await updateMFAPreference({ sms: 'PREFERRED' });
    await handleSession();
  }

  async function sendPasswordReset(data: ForgottenPasswordDto) {
    const url = isAdminDashboard()
      ? '/auth/forgotten-password/staff'
      : '/auth/forgotten-password/parent';

    await axiosClient.post<
      HttpError,
      AxiosResponse<void>,
      ForgottenPasswordDto
    >(url, data, { disableToast: true });
  }

  async function confirmPasswordReset(data: ConfirmPasswordResetDto) {
    await confirmResetPassword(data);
  }

  function reset() {
    setRememberDevice(false);
    setIsLoggedIn(false);
    setIsLoading(false);
  }

  /**
   * Retrieves the user information for the initial authentication.
   */
  useEffect(() => {
    const getUserData = async () => {
      await handleSession();
      setIsLoading(false);
    };

    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Listens to amplify auth changes
   */
  useEffect(() => {
    const listener = listenAuthChange();

    return () => listener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoedValue = useMemo(
    () => ({
      isLoading,
      rememberDevice,
      login,
      logOut,
      confirmSignIn,
      handleSession,
      register,
      registerParent,
      listenAuthChange,
      getTotpCode,
      verifyTotp,
      getInvitationByCode,
      confirmPhoneNumber,
      confirmParentAccount,
      sendPasswordReset,
      confirmPasswordReset,
      reset,
      isLoggedIn,
      permissions,
      user,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, isLoggedIn, user, rememberDevice]
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
}

function useAuth() {
  const authContext = useContext(AuthContext);

  if (isEmpty(authContext)) {
    throw new Error('useAuth has to be used within <AuthContext.Provider>');
  }

  return authContext;
}

export { AuthContext, AuthProvider, useAuth };
