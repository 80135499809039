import { ChildNameSearchBar } from '@/components/extra-hours-bookings/child-name-search-bar';
import { ExtraHoursBookingsTable } from '@/components/extra-hours-bookings/extra-hours-bookings-table';
import { ExtraHoursExportButton } from '@/components/extra-hours-bookings/extra-hours-export-button';
import { ExtraHoursFilterRow } from '@/components/extra-hours-bookings/extra-hours-filter-row';
import { PageTitle } from '@/components/page-title';
import { SchoolYearFilter } from '@/components/school-year-filter';
import { ExtraHoursBookingsControlProvider } from '@/context/extra-hours-bookings-control.context';

function ExtraHoursBookings() {
  return (
    <div>
      <PageTitle title="Extra Hours Bookings" variant="gray" />

      <ExtraHoursBookingsControlProvider>
        <div className="mt-14 flex flex-col items-center justify-between gap-5 md:flex-row">
          <SchoolYearFilter />
          <ChildNameSearchBar />
        </div>
        <div className="mt-3 flex items-center justify-between border-t border-gray-100 pt-3">
          <ExtraHoursFilterRow />
          <ExtraHoursExportButton />
        </div>
        <ExtraHoursBookingsTable />
      </ExtraHoursBookingsControlProvider>
    </div>
  );
}

export { ExtraHoursBookings };
