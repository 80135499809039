import { Box } from '@/components/box';
import { Breadcrumbs } from '@/components/breadcrumbs';
import { FallbackComponent } from '@/components/fallback-component';
import { LoadingScreen } from '@/components/loading-screen';
import { ChildrenListTable } from '@/components/parent/children-list-table';
import { parentRouterPath } from '@/config/route-paths.config';
import { useChildren } from '@/hooks/query-hooks/use-children';
import { BreadcrumbNode } from '@/types/breadcrumb';
import { isProd } from '@/utils/env-utils';
import { Link } from 'react-router-dom';

const breadcrumbPaths: BreadcrumbNode[] = [];

function ParentDashboard() {
  const { data: children, isError, isSuccess, isLoading } = useChildren();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError || !isSuccess) {
    return <FallbackComponent />;
  }

  return (
    <>
      <Breadcrumbs path={breadcrumbPaths} />
      <Box className="mt-8">
        <div className="flex flex-col items-start gap-3 md:flex-row md:items-center md:justify-between md:gap-4">
          <div>
            <p className="text-lg font-semibold text-gray-900">Children</p>
            <p className="text-sm text-gray-600">
              Details of any children that you have added to your account and
              their application status.
            </p>
          </div>
          {!isProd() && (
            <Link
              to={parentRouterPath.APPLICATION_CREATE_CHILD}
              className="btn btn-primary min-w-fit md:self-center"
            >
              Add Child
            </Link>
          )}
        </div>
        {children.length > 0 ? (
          <ChildrenListTable childList={children} />
        ) : null}
      </Box>
    </>
  );
}

export { ParentDashboard };
