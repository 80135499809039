import { useAuth } from '@/context/auth.context';
import { Permission } from '@/types/auth';

type UsePermissionsResult = {
  hasPermission: (permissions: Permission[]) => boolean;
};

function usePermissions(): UsePermissionsResult {
  const { permissions } = useAuth();

  const hasPermission = (requiredPermissions: Permission[]) =>
    requiredPermissions.every(item => permissions.includes(item));

  return {
    hasPermission,
  };
}

export { usePermissions };
