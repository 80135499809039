import { organisationKey } from '@/config/query-keys';
import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import { Ratio } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useRatio(
  id?: string,
  queryOptions?: UseQueryOptions<Ratio, HttpError>
) {
  return useQuery({
    queryKey: organisationKey.ratio(id || ''),
    queryFn: () => organisationService.getRatio(id || ''),
    ...queryOptions,
    enabled: !!id,
  });
}

export { useRatio };
