import { PageTitle } from '@/components/page-title';
import { QuestionList } from '@/components/settings/application-templates/question-list';
import { QuestionNavigation } from '@/components/settings/application-templates/question-navigation';
import { QuestionCategory } from '@/types/application-template';
import { useState } from 'react';
import { ApplicationTemplateNavigation } from './application-template-navigation';

function ApplicationTemplateQuestions() {
  const [selectedCategory, setSelectedCategory] = useState<QuestionCategory>(
    QuestionCategory.ParentCarerDetails
  );

  return (
    <div>
      <PageTitle title="Application Template Questions" variant="gray" />
      <ApplicationTemplateNavigation />

      <div className="mt-4 md:flex md:gap-4">
        <QuestionNavigation
          active={selectedCategory}
          onNavigationChange={setSelectedCategory}
        />
        <QuestionList active={selectedCategory} />
      </div>
    </div>
  );
}

export { ApplicationTemplateQuestions };
