import { axiosClient } from '@/libs/axios';
import { HttpError } from '@/types/error';
import {
  CreateTicketRequest,
  CustomPaginateResult,
  GetStaffTicketRequest,
  HasUnreadMessagesResponse,
  ListTicketsResponseItem,
  RespondToTicketDto,
  Ticket,
  DownloadTicketAttachmentResponse,
  UpdateTicketStatus,
} from '@admissions-support/types';
import { AxiosResponse } from 'axios';

/**
 * Get messages
 * @param isParent - Whether the user is a parent
 * @param params - The parameters to get the messages
 * @returns The messages
 */
const getMessages = async (
  isParent = true,
  params: GetStaffTicketRequest
): Promise<CustomPaginateResult<ListTicketsResponseItem>> => {
  const url = isParent ? '/messaging/tickets' : '/messaging/tickets/staff';
  const response = await axiosClient.get<
    HttpError,
    AxiosResponse<CustomPaginateResult<ListTicketsResponseItem>>
  >(url, {
    params,
  });

  return response.data;
};

/**
 * Create a message
 * @param userId - The user ID
 * @param isParent - Whether the user is a parent
 * @param data - The data to create the message
 * @returns The created message
 */
const createMessage = async (
  userId: string,
  isParent: boolean,
  data: CreateTicketRequest
) => {
  const url = !isParent
    ? `/messaging/users/${userId}/tickets`
    : `/messaging/ticket`;
  const response = await axiosClient.post<HttpError, AxiosResponse<void>>(
    url,
    data,
    {
      disableToast: true,
    }
  );

  return response.data;
};

/**
 * Get a ticket
 * @param id - The ticket ID
 * @returns The ticket
 */
const getTicket = async (id: string, isParent = true) => {
  const url = isParent
    ? `/messaging/tickets/${id}`
    : `/messaging/tickets/staff/${id}`;
  const response = await axiosClient.get<HttpError, AxiosResponse<Ticket>>(url);
  return response.data;
};

/**
 * Respond to a ticket
 * @param id - The ticket ID
 * @param data - The data to respond to the ticket
 * @param isParent - Whether the user is a parent
 * @returns The ticket
 */
const respondToTicket = async (
  id: string,
  data: RespondToTicketDto,
  isParent = true
) => {
  const url = isParent
    ? `/messaging/tickets/${id}/respond`
    : `/messaging/tickets/${id}/respond/staff`;
  const response = await axiosClient.post<HttpError, AxiosResponse<Ticket>>(
    url,
    data,
    {
      disableToast: true,
    }
  );

  return response.data;
};

/**
 * Mark a message as seen
 * @param id - The ticket ID
 * @returns The ticket
 */
const markMessageSeen = async (id: string) => {
  const response = await axiosClient.post<HttpError, AxiosResponse<void>>(
    `/messaging/tickets/${id}/seen`,
    {},
    {
      disableToast: true,
    }
  );

  return response.data;
};

/**
 * Get unread messages
 * @returns The unread messages
 */
const hasUnreadMessages = async (): Promise<HasUnreadMessagesResponse> => {
  const response = await axiosClient.get<
    HttpError,
    AxiosResponse<HasUnreadMessagesResponse>
  >('/messaging/tickets/unread');

  return response.data;
};

/**
 * Update a ticket status
 * @param id - The ticket ID
 * @param status - The status to update the ticket to
 * @returns The ticket
 */
const updateTicketStatus = async (
  id: string,
  status: UpdateTicketStatus['status']
) => {
  const response = await axiosClient.post<HttpError, AxiosResponse<Ticket>>(
    `/messaging/tickets/${id}/status`,
    { status },
    {
      disableToast: true,
    }
  );

  return response.data;
};

/**
 * Get a message attachment
 * @param messageId - The message ID
 * @returns The message attachment
 */
const getMessageAttachment = async (
  ticketId: string,
  fileKey: string
): Promise<DownloadTicketAttachmentResponse> => {
  const response = await axiosClient.get<
    HttpError,
    AxiosResponse<DownloadTicketAttachmentResponse>
  >(`/messaging/tickets/${ticketId}/attachment`, {
    params: {
      fileKey,
    },
  });

  return response.data;
};

export const messagingService = Object.freeze({
  getMessages,
  createMessage,
  getTicket,
  respondToTicket,
  markMessageSeen,
  hasUnreadMessages,
  updateTicketStatus,
  getMessageAttachment,
});
