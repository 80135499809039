import { useApplicationControl } from '@/context/application-control.context';
import { CheckboxBase } from '../form/common/checkbox';

function SiblingFilter() {
  const { filter, setFilter } = useApplicationControl();

  const handleChange = () => {
    setFilter(prev => ({
      ...prev,
      isSiblingAtLocation: !prev.isSiblingAtLocation,
    }));
  };

  return (
    <div>
      <CheckboxBase
        name="isSiblingAtLocation"
        label="Has a Sibling at this Location?"
        onChange={handleChange}
        checked={filter.isSiblingAtLocation}
      />
    </div>
  );
}

export { SiblingFilter };
