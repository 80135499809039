import {
  CodeDeliveryDetails,
  FetchMFAPreferenceOutput,
} from 'aws-amplify/auth';
interface User {
  id: string;
  familyName: string;
  givenName: string;
  email: string;
  preferredMFA: FetchMFAPreferenceOutput['preferred'] | undefined;
  isMFARequired: boolean;
}

interface MfaSetupRouterState {
  fromSmsVerifyScreen?: boolean;
  phoneNumber?: string;
}

type VerifyRouterState =
  | { type?: 'TOTP' }
  | {
      type?: 'SMS';
      codeDeliveryDetails: CodeDeliveryDetails;
    };

export enum Permission {
  'organisation:create' = 'organisation:create',
  'organisation:update' = 'organisation:update',
  'organisation:delete' = 'organisation:delete',
  'organisation:update_catchment_are' = 'organisation:update_catchment_are',
  'organisation:user-groups:create' = 'organisation:user-groups:create',
  'organisation:user-groups:update' = 'organisation:user-groups:update',
  'organisation:user-groups:delete' = 'organisation:user-groups:delete',
  'organisation:user-groups:retrieve' = 'organisation:user-groups:retrieve',
  'organisation:user-groups:fetch' = 'organisation:user-groups:fetch',
  'organisation:users:list' = 'organisation:users:list',
  'invitation:invite' = 'invitation:invite',
  'users:suspend' = 'users:suspend',
  'users:update' = 'users:update',
  'users:fetch' = 'users:fetch',
  'location:create' = 'location:create',
  'location:update' = 'location:update',
  'location:delete' = 'location:delete',
  'location:fetch' = 'location:fetch',
  'application:create' = 'application:create',
  'application:update' = 'application:update',
  'application:delete' = 'application:delete',
  'application:fetch' = 'application:fetch',
  'parent' = 'parent',
  'managers' = 'managers',
  'reporting' = 'reporting',
  'extra-hours' = 'extra-hours',
  'messaging:fetch' = 'messaging:fetch',
  'messaging:create' = 'messaging:create',
}

type LoginDto = {
  organisationId: string;
  email: string;
  password: string;
  rememberDevice: boolean;
};

type RegisterDto = {
  invitationCode: string;
  organisationId: string;
  email: string;
  password: string;
};

type ConfirmPasswordResetDto = {
  username: string;
  confirmationCode: string;
  newPassword: string;
};

export type {
  ConfirmPasswordResetDto,
  LoginDto,
  MfaSetupRouterState,
  RegisterDto,
  User,
  VerifyRouterState,
};
