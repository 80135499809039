import { AmplifyError } from '@/types/error';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import OtpInput from 'react-otp-input';
import * as yup from 'yup';
import { Alert } from '../alert';

const schema = yup
  .object({
    code: yup
      .string()
      .test('len', 'Must be exactly 6 characters', val =>
        val ? val.length === 6 : false
      )
      .required(),
  })
  .required();

interface AuthenticatorDto {
  code: string;
}

interface AuthenticatorFormProps {
  onVerify: (data: AuthenticatorDto) => Promise<void>;
}

function AuthenticatorForm(props: AuthenticatorFormProps) {
  const { onVerify } = props;
  const { watch, setValue, formState, handleSubmit } = useForm<
    Pick<AuthenticatorDto, 'code'>
  >({
    resolver: yupResolver(schema),
    defaultValues: {
      code: '',
    },
    mode: 'onBlur',
  });
  const [error, setError] = useState<string | null>(null);

  const codeValue = watch('code');

  const handleOTPValue = (code: string) => {
    setValue('code', code, { shouldValidate: true });
  };

  const onSubmit = async (data: Pick<AuthenticatorDto, 'code'>) => {
    try {
      await onVerify({ ...data });
    } catch (e) {
      const error = e as AmplifyError;

      switch (error.code) {
        case 'NotAuthorizedException':
        case 'CodeMismatchException':
        case 'ExpiredCodeException':
          setError(error.message);
          break;

        default:
          setError('Invalid verification code provided.');
      }
    }
  };

  return (
    <div className="mt-8">
      {error ? <Alert type="error" text={error} className="mb-4" /> : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <OtpInput
          value={codeValue}
          onChange={handleOTPValue}
          numInputs={6}
          containerStyle="space-x-6 mb-6 mt-8"
          shouldAutoFocus
          renderInput={props => (
            <input
              {...props}
              disabled={formState.isSubmitting}
              className="w-auto flex-1 rounded border-gray-300 p-3 font-fmly text-4xl text-gray-500 focus:border-primary-600 focus:ring-primary-600 disabled:bg-gray-50"
            />
          )}
        />
        <button
          className="btn btn-primary btn-full"
          disabled={!formState.isValid || formState.isSubmitting}
        >
          Verify Code
        </button>
      </form>
    </div>
  );
}

export { AuthenticatorForm };
export type { AuthenticatorDto };
