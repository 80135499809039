import { sessionTypeKey } from '@/config/query-keys';
import { sessionTypeService } from '@/services/session-type.service';
import { HttpError } from '@/types/error';
import { SessionType } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useSessionType(
  id?: string,
  queryOptions?: UseQueryOptions<SessionType, HttpError>
) {
  return useQuery({
    queryKey: sessionTypeKey.single(id || ''),
    queryFn: () => sessionTypeService.getSessionType(id || ''),
    ...queryOptions,
    enabled: !!id,
  });
}

export { useSessionType };
