import { PermissionGuard } from '@/components/auth/permission-guard';
import { PageTitle } from '@/components/page-title';
import { Table } from '@/components/table';
import { staffRouterPath } from '@/config/route-paths.config';
import { useRatios } from '@/hooks/query-hooks/use-ratios';
import { Permission } from '@/types/auth';
import { getErrorMessage } from '@/utils/get-error-message';
import { Ratio, RatioRangeUnit } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import { Edit01, SearchRefraction } from '@untitled-ui/icons-react';
import { useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

function Ratios() {
  const navigate = useNavigate();
  const { isLoading, data: ratios, error } = useRatios();

  const columnHelper = createColumnHelper<Ratio>();

  const handleEdit = (id: string) => () => {
    const redirectUrl = generatePath(staffRouterPath.EDIT_RATIO, {
      id,
    });
    navigate(redirectUrl);
  };

  const getAgeRange = (value: number, unit: RatioRangeUnit) => {
    if (unit === 'MONTHS') {
      return (
        <>
          <span className="font-mono">{value}</span>mos
        </>
      );
    }

    return (
      <>
        <span className="font-mono">{value}</span>yr
      </>
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        cell: info => <span className="font-medium">{info.getValue()}</span>,
        header: () => <span>Ratio Name</span>,
        enableSorting: true,
        sortingFn: 'text',
      }),
      columnHelper.display({
        id: 'nbHoursPerWeek',
        cell: info => (
          <span>
            {getAgeRange(
              info.row.original.from.value,
              info.row.original.from.unit
            )}
            {' - '}
            {getAgeRange(info.row.original.to.value, info.row.original.to.unit)}
          </span>
        ),
        header: () => <span>Age Range</span>,
        size: 260,
      }),
      columnHelper.accessor('nbChildrenPerStaffMember', {
        id: 'nbChildrenPerStaffMember',
        cell: info => <span className="font-mono">{info.getValue()}</span>,
        header: () => <span>Children per staff member</span>,
        enableSorting: true,
        sortingFn: 'text',
        size: 260,
      }),
      columnHelper.display({
        id: 'id',
        cell: info => (
          <button
            type="button"
            className="edit-ratio-tooltip rounded p-2"
            onClick={handleEdit(info.row.original.id)}
          >
            <Edit01
              className="h-5 w-5"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
            <span className="sr-only">Edit Ratio</span>
          </button>
        ),
        size: 72,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleNavigateToNewRatio = () => {
    navigate(staffRouterPath.NEW_RATIO);
  };

  return (
    <>
      <PageTitle
        title="Ratios"
        description="Specify ratio between staff and children"
        variant="white"
      >
        <PermissionGuard
          requiredPermissions={[Permission['organisation:create']]}
        >
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleNavigateToNewRatio}
          >
            Add Ratio
          </button>
        </PermissionGuard>
      </PageTitle>

      <Tooltip
        anchorSelect=".edit-ratio-tooltip"
        content="Edit Ratio"
        place="top"
        className="tooltip"
      />
      <Table
        columns={columns}
        data={ratios}
        paginationType="auto"
        isLoading={isLoading}
        error={getErrorMessage(error)}
        EmptyState={
          <div className="py-12 text-center">
            <SearchRefraction
              className="mx-auto h-8 w-8 text-gray-400"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              No Ratios Found
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              You do not currently have any ratios added. Please add your first
              ratio.
            </p>
          </div>
        }
      />
    </>
  );
}

export { Ratios };
