import { bookingKey } from '@/config/query-keys';
import { bookingService } from '@/services/booking.service';
import { HttpError } from '@/types/error';
import { ExtraHoursBookingsFilter } from '@/types/extra-hours-bookings-control';
import {
  ChildWithBookingInfo,
  CustomPaginateResult,
} from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PaginationState, SortingState } from '@tanstack/react-table';

function useBookingsByChildren(
  schoolYearId: string,
  {
    pagination,
    sorting,
    filter,
  }: {
    pagination: PaginationState;
    sorting: SortingState;
    filter: ExtraHoursBookingsFilter;
  },
  queryOptions?: Partial<
    UseQueryOptions<CustomPaginateResult<ChildWithBookingInfo>, HttpError>
  >
) {
  return useQuery({
    queryKey: bookingKey.byChildren(schoolYearId, pagination, sorting, filter),
    queryFn: () =>
      bookingService.getBookingsByChildren(schoolYearId, {
        pagination,
        sorting,
        filter,
      }),
    ...queryOptions,
  });
}

function useExportBookingsByChildrenToCSV(
  schoolYearId: string,
  {
    pagination,
    sorting,
    filter,
  }: {
    pagination: PaginationState;
    sorting: SortingState;
    filter: ExtraHoursBookingsFilter;
  },
  queryOptions?: Partial<UseQueryOptions<Blob, HttpError>>
) {
  return useQuery({
    queryKey: ['booking', 'csv-export'],
    queryFn: () =>
      bookingService.exportBookingsByChildren(schoolYearId, {
        pagination,
        sorting,
        filter,
      }),
    ...queryOptions,
  });
}
export { useBookingsByChildren, useExportBookingsByChildrenToCSV };
