import { applicationKey } from '@/config/query-keys';
import { applicationService } from '@/services/application.service';
import { HttpError } from '@/types/error';
import { Application, CreateChoiceDto } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCreateChoiceMutation(
  applicationId: string,
  mutationOptions?: UseMutationOptions<Application, HttpError, CreateChoiceDto>
) {
  return useMutation({
    mutationKey: applicationKey.updateApplication,
    mutationFn: (data: CreateChoiceDto) =>
      applicationService.createChoice(applicationId, data),
    ...mutationOptions,
  });
}

export { useCreateChoiceMutation };
