import { twMerge } from 'tailwind-merge';
import { Spinner } from './spinner';

type LoadingScreenProps = {
  className?: string;
};

function LoadingScreen(props: LoadingScreenProps) {
  const { className } = props;
  return (
    <div
      className={twMerge('flex h-full items-center justify-center', className)}
    >
      <Spinner className="h-12 w-12" />
    </div>
  );
}

export { LoadingScreen };
