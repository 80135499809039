import {
  getAllocatedCapacityForAllDay,
  getAllocatedCapacityForDay,
  getCapacityRangeForRatio,
  transformResourceDayCapacityToArray,
} from '@/utils/extra-hours-availability-utils';
import { generateClassByCapacity } from '@/utils/location-utils';
import {
  ExtraHourPublic,
  ExtraHourResource,
  RatioCapacity,
  SessionType,
} from '@admissions-support/types';
import { capitalize, isFunction } from 'lodash-es';

type ExtraHourResourceItemProps = {
  data: ExtraHourResource;
  sessionTypes: SessionType[];
  onClick?: () => void;
  ratios: RatioCapacity[];
  extraHours: ExtraHourPublic[];
};

function ExtraHourResourceItem(props: ExtraHourResourceItemProps) {
  const { onClick, sessionTypes, data, ratios, extraHours } = props;

  const selectedSessionTypes = sessionTypes.filter(sessionType =>
    data.sessionTypes.find(
      selectedSessionTypeId =>
        selectedSessionTypeId.id.toString() === sessionType.id.toString()
    )
  );

  const selectedSessionTypeIds = selectedSessionTypes.map(
    sessionType => sessionType.id
  );

  const selectedSessionTypeNames = selectedSessionTypes
    .map(sessionType => sessionType.name)
    .join(', ');

  const filteredExtraHours = extraHours.filter(extraHour =>
    selectedSessionTypeIds.includes(extraHour.session.type.id.toString())
  );

  const transformedCapacity = transformResourceDayCapacityToArray(
    data.capacities
  );

  return (
    <div className="white-container group relative bg-white">
      <div className="flex flex-row items-center">
        <p className="mb-4 mt-1.5 text-lg font-medium">Resource Group</p>
        {isFunction(onClick) ? (
          <button
            type="button"
            className="btn btn-secondary right-4 top-4 ml-auto"
            onClick={onClick}
          >
            Edit
          </button>
        ) : null}
      </div>

      <p className="text-md mt-6 font-semibold">Session Type(s)</p>
      <p className="text-md text-gray-500">
        {selectedSessionTypes.map(sessionType => sessionType.name).join(', ')}
      </p>

      <div className="light-gray-container mt-5 space-y-2">
        {ratios.map(ratioCapacity => {
          const range = getAllocatedCapacityForAllDay(
            ratioCapacity.ratio.id.toString(),
            filteredExtraHours
          );

          const capacityRange = getCapacityRangeForRatio(
            ratioCapacity.ratio.id.toString(),
            transformedCapacity
          );

          const isAllocatedRangeFlat =
            range.allocated.max === range.allocated.min;
          const isCapacityRangeFlat = capacityRange.min === capacityRange.max;

          const allocatedContent = isAllocatedRangeFlat
            ? range.allocated.max
            : `${range.allocated.min}-${range.allocated.max}`;
          const capacityContent = isCapacityRangeFlat
            ? capacityRange.max
            : `${capacityRange.min}-${capacityRange.max}`;

          return (
            <div
              className="white-container space-y-6"
              key={`${ratioCapacity.ratio.id}-${selectedSessionTypeNames}`}
            >
              <div className="flex justify-between">
                <div className="flex items-center gap-2">
                  <p className="text-md font-semibold text-gray-900">
                    {ratioCapacity.ratio.name}
                  </p>
                </div>
                <div
                  className="flex items-center gap-1 hover:cursor-pointer"
                  data-tooltip-content={`${allocatedContent} Allocated of ${capacityContent}`}
                  data-tooltip-id="capacity-tooltip"
                >
                  <span
                    className={generateClassByCapacity(
                      range.capacities.max,
                      range.allocated.max || 0
                    )}
                  >
                    {allocatedContent}
                  </span>{' '}
                  /{' '}
                  <span className="rounded-md border border-gray-200 bg-gray-50 px-2 py-0.5 text-gray-700">
                    {capacityContent}
                  </span>
                </div>
              </div>
              <div className="mt-4 flex flex-wrap gap-4">
                {transformedCapacity.map((capacity, index) => {
                  const currentCapacityObj = capacity.capacities.find(
                    c => c.ratioId === ratioCapacity.ratio.id.toString()
                  );

                  if (!currentCapacityObj) {
                    return;
                  }

                  const allocatedRange = getAllocatedCapacityForDay(
                    filteredExtraHours,
                    ratioCapacity.ratio.id.toString(),
                    capacity.day.toLowerCase()
                  );

                  const greenClassWidth = currentCapacityObj.capacity
                    ? (allocatedRange.max / currentCapacityObj.capacity) * 100
                    : 0;
                  const grayClassWidth = currentCapacityObj.capacity
                    ? 100 -
                      (allocatedRange.max / currentCapacityObj.capacity) * 100
                    : 100;

                  const allocatedContent =
                    allocatedRange.min === allocatedRange.max
                      ? allocatedRange.max
                      : `${allocatedRange.min}-${allocatedRange.max}`;

                  return (
                    <div key={index} className="min-w-[70px] flex-1 space-y-2">
                      <div className="flex">
                        <div
                          className="h-1 rounded-l-md bg-primary-300"
                          style={{ width: `${greenClassWidth}%` }}
                        />
                        <div
                          className="h-1 rounded-l-md bg-primary-50"
                          style={{ width: `${grayClassWidth}%` }}
                        />
                      </div>
                      <p className="text-xs font-semibold text-gray-700">
                        {capitalize(capacity.day)}
                      </p>
                      <p className="text-sm text-gray-600">{`${
                        allocatedContent || 0
                      }/${currentCapacityObj.capacity || 0}`}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export { ExtraHourResourceItem };
