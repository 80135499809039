/* eslint-disable react/destructuring-assignment */
import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  Row,
  RowSelectionState,
  SortingState,
  TableState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
} from '@untitled-ui/icons-react';
import { isFunction } from 'lodash-es';
import {
  Dispatch,
  Fragment,
  HTMLProps,
  MouseEvent,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { Alert } from './alert';
import { Spinner } from './spinner';

type CommonTableProps<DataType> = {
  data: DataType[] | undefined;
  columns: ColumnDef<DataType, any>[];
  isLoading?: boolean;
  isFetching?: boolean;
  pageSize?: 10 | 20 | 30 | 40 | 50;
  error?: string;
  EmptyState?: React.ReactNode;
  className?: string;
  getRowId?:
    | ((
        originalRow: DataType,
        index: number,
        parent?: Row<DataType> | undefined
      ) => string)
    | undefined;
  columnVisibility?: VisibilityState;
  onRowClick?: (rowIndex: number) => void;
};

type ManualPaginationProps = {
  paginationType: 'manual';
  onPaginationChange: Dispatch<SetStateAction<PaginationState>>;
  pagination: PaginationState;
  /* Number of pages */
  pageCount?: number;
};

type AutoPaginationProps = {
  paginationType: 'auto';
};

type PaginationProps =
  | ManualPaginationProps
  | AutoPaginationProps
  | { paginationType?: never };

type AutoSortingProps = {
  sortingType: 'auto';
  sorting?: SortingState;
};

type ServerSortingProps = {
  sortingType: 'server';
  sorting: SortingState;
  onSortingChange: OnChangeFn<SortingState>;
};

type SortingProps =
  | AutoSortingProps
  | ServerSortingProps
  | { sortingType?: never };

type SelectableProps = {
  rowSelectionType: 'manual';
  rowSelection: RowSelectionState;
  onRowSelectionChange: Dispatch<SetStateAction<RowSelectionState>>;
  SelectionHeader: React.ReactNode;
};

type NonSelectableProps = {
  rowSelectionType?: never;
};

type RowSelectionProps = SelectableProps | NonSelectableProps;

type RowExpansionProps<DataType> = {
  renderExpandedRow?: (row: Row<DataType>) => React.ReactNode;
};

type TableProps<DataType> = CommonTableProps<DataType> &
  PaginationProps &
  SortingProps &
  RowSelectionProps &
  RowExpansionProps<DataType>;

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + 'checkbox cursor-pointer'}
      {...rest}
    />
  );
}

function Table<DataType>(props: TableProps<DataType>) {
  const {
    data,
    columns,
    rowSelectionType,
    isLoading,
    isFetching,
    pageSize = 10,
    paginationType,
    error,
    EmptyState,
    sortingType,
    className,
    getRowId,
    columnVisibility,
    onRowClick,
  } = props;
  const hasError = Boolean(error);
  const isManualPagination = paginationType === 'manual';
  const hasPagination =
    paginationType === 'auto' || paginationType === 'manual';

  const isServerSorting = sortingType === 'server';
  const isSelectable = rowSelectionType === 'manual';

  const shouldDisplayPagination =
    /** auto-pagination: display pagination if the passed data is bigger than the pagesize */
    (hasPagination && pageSize < (data?.length || 0) && !isManualPagination) ||
    (hasPagination &&
      isManualPagination &&
      Array.isArray(data) &&
      data?.length > 0);

  const state: Partial<TableState> = {
    ...(isManualPagination ? { pagination: props.pagination } : null),
    ...(isServerSorting ? { sorting: props.sorting } : null),
    ...(isSelectable ? { rowSelection: props.rowSelection } : null),
    ...(columnVisibility ? { columnVisibility } : null),
  };

  const table = useReactTable({
    data: data || [],
    initialState: {
      pagination: {
        pageSize,
      },
      sorting:
        props.sortingType === 'auto' && props.sorting
          ? props.sorting
          : undefined,
    },
    ...(isFunction(getRowId) ? { getRowId } : null),
    state,
    manualPagination: isManualPagination,
    ...(isManualPagination
      ? {
          pageCount: props.pageCount ?? -1,
          onPaginationChange: props.onPaginationChange,
        }
      : null),
    manualSorting: isServerSorting,
    ...(isServerSorting
      ? {
          onSortingChange: props.onSortingChange,
        }
      : null),
    enableRowSelection: isSelectable,
    ...(isSelectable
      ? {
          onRowSelectionChange: props.onRowSelectionChange,
        }
      : null),
    defaultColumn: {
      size: 999, // it supposed to be auto, but needs to be a number
    },
    columns: isSelectable
      ? [
          {
            id: 'select',
            header: ({ table }) => (
              <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                }}
              />
            ),
            cell: ({ row }) => (
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            ),
            size: 41,
          },
          ...columns,
        ]
      : columns,
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const handleRowClick = (
    event: MouseEvent<HTMLTableCellElement>,
    rowIndex: number
  ) => {
    // Prevent row click if we're clicking on an element inside the row, not the actual row's background
    if (event.target !== event.currentTarget) {
      return;
    }

    if (onRowClick) {
      onRowClick(rowIndex);
    }
  };

  function renderPagination() {
    const currentPage = table.getState().pagination.pageIndex + 1;
    const numberOfPages = table.getPageCount();

    return (
      <div className="flex items-center gap-2 border-t border-gray-200 px-6 py-4">
        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value));
          }}
          className="select w-28"
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
        <div className="ml-auto flex items-center gap-2">
          <p className="mr-2 text-sm">
            Page <span className="font-medium">{currentPage}</span> of
            <span className="font-medium"> {numberOfPages}</span>
          </p>

          <button
            className="btn btn-secondary"
            type="button"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            aria-label="Previous"
          >
            <span className="flex gap-2">
              <ArrowLeft
                className="h-5 w-5"
                viewBox="0 0 24 24"
                aria-hidden="true"
              />{' '}
              Prev
            </span>
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            aria-label="Next"
            type="button"
          >
            <span className="flex gap-2">
              Next{' '}
              <ArrowRight
                className="h-5 w-5"
                viewBox="0 0 24 24"
                aria-hidden="true"
              />
            </span>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={twMerge('mt-8 flow-root', className)}>
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow-sm ring-1 ring-gray-200 sm:rounded-lg">
            <table className="min-w-full table-fixed divide-y divide-gray-200">
              <thead className="relative w-full bg-gray-50">
                {rowSelectionType === 'manual' &&
                  Object.keys(props.rowSelection).length > 0 && (
                    <div className="absolute inset-0 z-10 bg-gray-50">
                      <tr className="flex h-full w-full items-center">
                        <th
                          style={{
                            width: table
                              .getHeaderGroups()[0]
                              .headers[0].getSize(),
                          }}
                          className={twMerge(
                            'relative flex items-center justify-center py-3 pl-6 text-left text-xs font-medium'
                          )}
                        >
                          <div className="flex items-center">
                            {flexRender(
                              table.getHeaderGroups()[0].headers[0].column
                                .columnDef.header,
                              table.getHeaderGroups()[0].headers[0].getContext()
                            )}
                          </div>
                        </th>
                        {props.SelectionHeader}
                      </tr>
                    </div>
                  )}
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index) => (
                      <th
                        key={header.id}
                        style={{
                          width:
                            header.getSize() === 999
                              ? 'auto'
                              : header.getSize(),
                        }}
                        className={twMerge(
                          'relative py-3 text-left text-xs font-medium',
                          isSelectable && index === 0 ? '' : 'pr-6',
                          isSelectable && index === 1 ? 'pl-3' : 'pl-6'
                        )}
                        colSpan={header.colSpan}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            role={
                              header.column.getCanSort() ? 'button' : undefined
                            }
                            tabIndex={
                              header.column.getCanSort() ? 0 : undefined
                            }
                            onKeyDown={
                              header.column.getCanSort()
                                ? header.column.getToggleSortingHandler()
                                : undefined
                            }
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointers select-none relative'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: (
                                <ArrowUp
                                  className="absolute inline h-4 w-4"
                                  viewBox="0 0 24 24"
                                  aria-hidden="true"
                                />
                              ),
                              desc: (
                                <ArrowDown
                                  className="absolute inline h-4 w-4"
                                  viewBox="0 0 24 24"
                                  aria-hidden="true"
                                />
                              ),
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="relative divide-y divide-gray-200 bg-white">
                {isFetching && !isLoading && (
                  <tr className="absolute inset-0 z-10 flex h-full w-full justify-center !border-t-0 bg-gray-25 py-20 opacity-50">
                    <td>
                      <Spinner className="h-12 w-12" />
                    </td>
                  </tr>
                )}
                {isLoading && (
                  <tr className="h-96 sm:h-[500px]">
                    <td
                      colSpan={
                        isSelectable ? columns.length + 1 : columns.length
                      }
                      className="py-4"
                    >
                      <div className="flex justify-center">
                        <Spinner className="h-12 w-12" />
                      </div>
                    </td>
                  </tr>
                )}

                {hasError && (
                  <tr className="h-96 sm:h-[500px]">
                    <td
                      colSpan={
                        isSelectable ? columns.length + 1 : columns.length
                      }
                      className="p-4 text-center"
                    >
                      <Alert
                        text={error}
                        type="error"
                        className="m-auto w-96"
                      />
                    </td>
                  </tr>
                )}
                {table.getRowModel().rows.length < 1 &&
                  EmptyState &&
                  !isLoading &&
                  !hasError && (
                    <tr>
                      <td
                        colSpan={
                          isSelectable ? columns.length + 1 : columns.length
                        }
                      >
                        {EmptyState}
                      </td>
                    </tr>
                  )}

                {!isLoading &&
                  !hasError &&
                  table.getRowModel().rows.map((row, index) => (
                    <Fragment key={row.id}>
                      <tr
                        className={
                          isFetching && index === 0 ? '!border-t-0' : ''
                        }
                      >
                        {row.getVisibleCells().map((cell, index) => (
                          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
                          <td
                            key={cell.id}
                            className={twMerge(
                              'relative cursor-pointer whitespace-nowrap py-4 text-sm',
                              isSelectable && index === 0 ? '' : 'pr-6',
                              isSelectable && index === 1 ? 'pl-3' : 'pl-6'
                            )}
                            onClick={event =>
                              handleRowClick(event, cell.row.index)
                            }
                          >
                            <div className="cursor-auto">
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </div>
                          </td>
                        ))}
                      </tr>
                      {row.getIsExpanded() && props.renderExpandedRow && (
                        <tr>
                          <td colSpan={row.getVisibleCells().length}>
                            {props.renderExpandedRow(row)}
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  ))}
              </tbody>
            </table>
            {shouldDisplayPagination && !isLoading && !hasError
              ? renderPagination()
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export { Table };
