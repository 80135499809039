import { useApplicationControl } from '@/context/application-control.context';
import { useCountApplications } from '@/hooks/query-hooks/use-count-applications';
import { RowSelectionState } from '@tanstack/react-table';
import { Dispatch, SetStateAction } from 'react';

type ApplicationSelectColumnHeaderProps = {
  nbOfTotal: number;
  nbOfSelected: number;
  onSelect: Dispatch<SetStateAction<RowSelectionState>>;
  onActionApply: () => void;
};

function ApplicationSelectColumnHeader(
  props: ApplicationSelectColumnHeaderProps
) {
  const { nbOfSelected, nbOfTotal, onSelect, onActionApply } = props;
  const { filter } = useApplicationControl();

  const { isLoading, data, isSuccess } = useCountApplications(filter, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const handleSelectAll = () => {
    if (!isSuccess) {
      return;
    }

    const allApplicationsIdList = data.reduce((selectedObj, applicationId) => {
      selectedObj[applicationId] = true;

      return selectedObj;
    }, {} as Record<string, boolean>);

    onSelect(allApplicationsIdList);
  };

  const handleDeselectAll = () => {
    onSelect({});
  };

  return (
    <th className="flex w-full items-center px-5 pl-3" colSpan={12}>
      <p className="text-sm font-normal">
        You selected (<b>{nbOfSelected}</b>) rows.{' '}
      </p>
      <div className="ml-12 flex items-center space-x-4">
        <button
          type="button"
          onClick={handleSelectAll}
          className="text-primary ml-4 flex cursor-pointer text-sm font-semibold disabled:text-gray-300"
          disabled={isLoading}
        >
          Select all ({nbOfTotal}) rows
        </button>
        <button
          type="button"
          className="btn btn-sm btn-primary ml-auto block text-sm"
          onClick={onActionApply}
        >
          Apply Actions
        </button>
        <button
          type="button"
          className="btn btn-sm btn-secondary ml-auto block text-sm"
          onClick={handleDeselectAll}
        >
          Cancel
        </button>
      </div>
    </th>
  );
}

export { ApplicationSelectColumnHeader };
