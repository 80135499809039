import { useApplicationControl } from '@/context/application-control.context';
import { enGB } from 'date-fns/locale/en-GB';
import DatePicker, { registerLocale } from 'react-datepicker';
registerLocale('enGB', enGB);

function BirthdayFilter() {
  const { filter, setFilter } = useApplicationControl();

  const handleBaseDateChange = (date: Date | null) => {
    setFilter(prev => ({ ...prev, baseDateOfBirth: date }));
  };

  const handleUpperDateChange = (date: Date | null) => {
    setFilter(prev => ({ ...prev, upperDateOfBirth: date }));
  };

  return (
    <div className="flex">
      <DatePicker
        className="input"
        placeholderText="From"
        wrapperClassName="w-full"
        selected={filter.baseDateOfBirth}
        onChange={handleBaseDateChange}
        selectsStart
        showIcon
        startDate={filter.baseDateOfBirth || undefined}
        endDate={filter.upperDateOfBirth || undefined}
        locale="enGB"
        dateFormat="P"
      />
      <DatePicker
        className="input"
        placeholderText="To"
        wrapperClassName="w-full ml-0 sm:ml-4"
        selected={filter.upperDateOfBirth}
        onChange={handleUpperDateChange}
        selectsEnd
        showIcon
        startDate={filter.baseDateOfBirth || undefined}
        endDate={filter.upperDateOfBirth || undefined}
        minDate={filter.baseDateOfBirth || undefined}
        locale="enGB"
        dateFormat="P"
      />
    </div>
  );
}

export { BirthdayFilter };
