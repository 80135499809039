import { CheckCircle } from '@untitled-ui/icons-react';

type ConfirmationElementProps = {
  nbOfChangedExtraHours: { created: number; removed?: number };
};

function ConfirmationElement(props: ConfirmationElementProps) {
  const { nbOfChangedExtraHours } = props;
  return (
    <div className="space-y-4">
      <div className="flex flex-col gap-4 rounded-lg bg-green-50 p-4 text-green-600">
        <div className="flex gap-4">
          <CheckCircle /> {nbOfChangedExtraHours.created} Session
          {nbOfChangedExtraHours.created > 1 && 's'} Created
        </div>
      </div>
      {nbOfChangedExtraHours.removed ? (
        <div className="flex flex-col gap-4 rounded-lg bg-green-50 p-4 text-green-600">
          <div className="flex gap-4">
            <CheckCircle /> {nbOfChangedExtraHours.removed} Session
            {nbOfChangedExtraHours.removed > 1 && 's'} removed
          </div>
        </div>
      ) : null}

      <p className="font-regular mt-5 text-gray-600">
        These purchasable sessions will be available to purchase as each child
        becomes eligible.
      </p>
    </div>
  );
}

export { ConfirmationElement };
