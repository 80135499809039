import { getTagType } from '@/pages/application/applications';
import { ChoiceStatus } from '@admissions-support/types';
import { twMerge } from 'tailwind-merge';
import { Tag } from '../tag';

type ChoiceTagProps = {
  status?: ChoiceStatus;
  children: React.ReactNode;
};

function ChoiceStatusTag(props: ChoiceTagProps) {
  const { status, children } = props;

  return (
    <Tag
      type={getTagType(status)}
      className="inline-flex w-auto flex-row items-center px-1.5 py-0.5 text-xs font-normal capitalize shadow-xs"
    >
      <span
        className={twMerge(
          'mr-1 inline-block h-1.5 w-1.5 rounded-full',
          status === 'WAITING_LIST' ? 'bg-yellow-500' : null,
          status === 'MATCHED' ? 'bg-blue-500' : null,
          status === 'PLACED' ? 'bg-green-500' : null,
          status === 'RESERVED' ? 'bg-green-500' : null,
          status === 'GRANTED' ? 'bg-green-500' : null,
          status === 'PLACEMENT_REJECTED' ? 'bg-red-500' : null,
          status === 'NOT_MATCHED' ? 'bg-gray-500' : null,
          status === 'SCORED' ? 'bg-gray-500' : null
        )}
      ></span>
      {children}
    </Tag>
  );
}

export { ChoiceStatusTag };
