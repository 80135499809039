import { productKey } from '@/config/query-keys';
import { productService } from '@/services/product.service';
import { HttpError } from '@/types/error';
import { Product } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useProduct(
  id: string,
  queryOptions?: Partial<UseQueryOptions<Product, HttpError>>
) {
  return useQuery({
    queryKey: productKey.single(id),
    queryFn: () => productService.getProductById(id),
    ...queryOptions,
  });
}

export { useProduct };
