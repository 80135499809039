import { trusteeKey } from '@/config/query-keys';
import { trusteeService } from '@/services/trustee.service';
import { HttpError } from '@/types/error';
import { TrusteeWithInvitation } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useTrustees(
  childId: string,
  queryOptions?: Partial<UseQueryOptions<TrusteeWithInvitation[], HttpError>>
) {
  return useQuery({
    queryKey: trusteeKey.list(childId),
    queryFn: () => trusteeService.getAllByChildId(childId),
    ...queryOptions,
  });
}

export { useTrustees };
