import { MultiSelect } from '@/components/form/common/multi-select';
import { TextInput } from '@/components/form/common/text-input';
import { useLocations } from '@/hooks/query-hooks/use-locations';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { Permission } from '@/types/auth';
import { Area, CreateAreaDto, UpdateAreaDto } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

const schema = yup.object({
  name: yup.string().required().label('Name'),
  locations: yup.array().of(yup.string().required()).label('Used for Intakes'),
});

type LocationGroupingFormProps = {
  onSubmit: (
    data: CreateAreaDto
  ) => Promise<void> | ((data: UpdateAreaDto) => Promise<void>);
  initialData?: Area;
  isLoading?: boolean;
};

function UpsertLocationGroupingForm(props: LocationGroupingFormProps) {
  const { initialData, isLoading, onSubmit } = props;
  const { hasPermission } = usePermissions();

  const {
    data: locations,
    isLoading: isLocationsLoading,
    isSuccess: isLocationsSuccess,
  } = useLocations();

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: initialData?.name || '',
      locations:
        initialData?.locations?.map(locationRef => locationRef.id.toString()) ||
        [],
    },
  });

  const submitHandler = async (data: CreateAreaDto | UpdateAreaDto) => {
    await onSubmit(data);
  };

  const locationsOptions = useMemo(
    () =>
      isLocationsSuccess
        ? locations.map(location => ({
            label: location.name,
            value: location.id,
          }))
        : [],
    [isLocationsSuccess, locations]
  );

  const hasUpsertPermission =
    hasPermission([Permission['location:create']]) ||
    hasPermission([Permission['location:update']]);

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(submitHandler)}
        id="upsert-location-grouping-form"
      >
        <div className="mt-6 bg-white pt-6">
          <div className="two-col-form pt-0">
            <h2 className="text-md font-medium leading-7 text-gray-900">
              Group Details
            </h2>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-12">
                <div className="space-y-6">
                  <TextInput
                    name="name"
                    type="text"
                    label="Name*"
                    placeholder="Linlithgow"
                    disabled={isLoading || !hasUpsertPermission}
                  />
                  <MultiSelect
                    name="locations"
                    label="Locations"
                    placeholder="Select..."
                    options={locationsOptions}
                    isDisabled={
                      isLoading || isLocationsLoading || !hasUpsertPermission
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export { UpsertLocationGroupingForm };
