import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { RouterProvider } from 'react-router-dom';

import { AuthProvider } from './context/auth.context';
import { SchoolYearProvider } from './context/school-year.context';
import { SettingsProvider } from './context/settings.context';
import { queryClient } from './libs/react-query';
import { router } from './router';
import { isAdminDashboard } from './utils/is-dashboard';
import { OrganisationDataProvider } from './context/organisation-control.context';

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <OrganisationDataProvider>
          <HelmetProvider>
            <Helmet
              titleTemplate={`%s | Admissions Support`}
              defaultTitle="Admissions Support"
            />
            <SettingsProvider>
              {isAdminDashboard() ? (
                <SchoolYearProvider>
                  <RouterProvider router={router} />
                </SchoolYearProvider>
              ) : (
                <RouterProvider router={router} />
              )}
            </SettingsProvider>
          </HelmetProvider>

          <Toaster />
          <ReactQueryDevtools
            initialIsOpen={false}
            position={'bottom'}
            buttonPosition={'bottom-right'}
          />
        </OrganisationDataProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export { App };
