import { useApplicationControl } from '@/context/application-control.context';
import { CheckboxBase } from '../form/common/checkbox';

function LookedAfterFilter() {
  const { filter, setFilter } = useApplicationControl();

  const handleChange = () => {
    setFilter(prev => ({
      ...prev,
      isLookedAfter: !prev.isLookedAfter,
    }));
  };

  return (
    <div>
      <CheckboxBase
        name="isLookedAfter"
        label="Is the child looked after?"
        onChange={handleChange}
        checked={filter.isLookedAfter}
      />
    </div>
  );
}

export { LookedAfterFilter };
