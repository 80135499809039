export const staffRouterPath = Object.freeze({
  ROOT: '/',
  AUTH: '/auth',
  SIGN_IN: '/auth/sign-in',
  FORGOTTEN_PASSWORD: '/auth/forgotten-password',
  RESET_PASSWORD: '/auth/reset-password',
  VERIFY: '/auth/verify',
  CREATE_ACCOUNT: '/auth/create-account',
  MFA_SETUP: '/auth/mfa-setup',
  SMS_VERIFICATION_MFA_SETUP: '/auth/mfa-setup/sms-verification',
  PLATFORM_USERS_MAIN: '/platform-users',
  PLATFORM_USERS: '/platform-users/users',
  NEW_PLATFORM_USER: '/platform-users/new',
  PLATFORM_USER: '/platform-users/:id',
  USER_GROUPS: '/platform-users/user-groups',
  NEW_USER_GROUP: '/platform-users/user-groups/new',
  EDIT_USER_GROUP: '/platform-users/user-groups/:id',
  LOCATIONS: '/locations',
  NEW_LOCATION: '/locations/new',
  EDIT_LOCATION: '/locations/:locationId/edit',
  OVERVIEW_LOCATION: '/locations/:locationId/overview',
  LOCATION_PURCHASE_EXTRA_HOURS_BASE:
    '/locations/:locationId/purchase-extra-hours',
  LOCATION_PURCHASE_EXTRA_HOURS_CALENDAR:
    '/locations/:locationId/purchase-extra-hours/calendar',
  LOCATION_PURCHASE_EXTRA_HOURS_PRODUCTS:
    '/locations/:locationId/purchase-extra-hours/products',
  LOCATION_PURCHASE_EXTRA_HOURS_AVAILABILITY:
    '/locations/:locationId/purchase-extra-hours/availability',
  LOCATION_APPLICATIONS: '/locations/:locationId/applications',
  LOCATION_APPLICATION_OVERVIEW:
    '/locations/:locationId/applications/:id/overview',
  EXTRA_HOURS_BOOKINGS: '/extra-hours-bookings',
  EXTRA_HOURS_BOOKINGS_CHILD_DETAILS: '/extra-hours-bookings/child/:id',
  SESSIONS: '/locations/:locationId/sessions',
  CAPACITY: '/locations/:locationId/capacity',
  RESOURCES: '/locations/:locationId/resources',
  SETTINGS: '/settings',
  ORGANISATION_DETAILS: '/settings/organisation-details',
  WEEK_MODELS: '/settings/week-models',
  NEW_WEEK_MODEL: '/settings/week-models/new',
  EDIT_WEEK_MODEL: '/settings/week-models/:id',
  TERMS: '/settings/terms',
  NEW_TERM: '/settings/terms/new',
  EDIT_TERM: '/settings/terms/:id',
  SESSION_TYPES: '/settings/session-types',
  NEW_SESSION_TYPE: '/settings/session-types/new',
  EDIT_SESSION_TYPE: '/settings/session-types/:id',
  RATIOS: '/settings/ratios',
  NEW_RATIO: '/settings/ratio/new',
  EDIT_RATIO: '/settings/ratio/:id',
  LOCATION_GROUPING: '/settings/location-grouping',
  NEW_LOCATION_GROUPING: '/settings/location-grouping/new',
  EDIT_LOCATION_GROUPING: '/settings/location-grouping/:id',
  COHORTS: '/settings/cohorts',
  NEW_COHORT: '/settings/cohorts/new',
  EDIT_COHORT: '/settings/cohorts/:id',
  APPLICATIONS: '/applications',
  BULK_UPLOAD: '/applications/bulk-upload',
  OVERVIEW_APPLICATION: '/applications/:id/overview',
  EDIT_APPLICATION: '/applications/:id',
  APPLICATION_CHILD_DETAILS: '/applications/:id/child-details',
  APPLICATION_CARER: '/applications/:id/carer',
  APPLICATION_HOUSEHOLD_DETAILS: '/applications/:id/household-details',
  APPLICATION_PLACEMENT_PREFERENCES: '/applications/:id/placement-preferences',
  APPLICATION_ADDITIONAL_DETAILS: '/applications/:id/additional-details',
  NEW_APPLICATION_ELIGIBILITY: '/applications/eligibility/:childId',
  NEW_APPLICATION: '/applications/new',
  USER_PROFILE: '/user-profile',
  REPORTING: '/reporting',
  NEW_QUERY_BUILDER: '/reporting/query-builder/new',
  RUN_QUERY_BUILDER: '/reporting/query-builder/:id/run',
  EDIT_QUERY_BUILDER: '/reporting/query-builder/:id',
  REPORTING_ALLOCATION: '/reporting/allocation',
  REPORTING_CAPACITY: '/reporting/capacity-planning',
  REPORTING_DEMAND: '/reporting/demand-summary',
  APPLICATION_TEMPLATES: '/settings/application-templates',
  NEW_APPLICATION_TEMPLATE: '/settings/application-templates/new',
  EDIT_APPLICATION_TEMPLATE: '/settings/application-templates/:id/overview',
  APPLICATION_TEMPLATE_QUESTIONS:
    '/settings/application-templates/:id/questions',
  APPLICATION_TEMPLATE_ADDITIONAL_QUESTIONS:
    '/settings/application-templates/:id/additional-questions',
  MESSAGING: '/messaging',
  MESSAGING_CONVERSATION: '/messaging/:id',
  MESSAGING_NEW_CONVERSATION: '/messaging/new',
  TRUSTEES: '/trustees/:childId',
});

export const parentRouterPath = Object.freeze({
  ROOT: '/',
  WELCOME_AUTH: '/auth/auth-welcome',
  START_AUTH: '/auth/start',
  VERIFY_INVITATION: '/auth/verify-invitation',
  CREATE_ACCOUNT: '/auth/create-account',
  FORGOTTEN_PASSWORD: '/auth/forgotten-password',
  RESET_PASSWORD: '/auth/reset-password',
  VERIFY_EMAIL: '/auth/verify-email',
  CONFIRM_ACCOUNT: '/auth/confirm-account',
  SIGN_IN: '/auth/sign-in',
  INVITATION_SIGN_IN: '/auth/invitation-sign-in',
  VERIFY: '/auth/verify',
  MFA_SETUP: '/auth/mfa-setup',
  SMS_VERIFICATION_MFA_SETUP: '/auth/mfa-setup/sms-verification',
  APPLICATION_GETTING_STARTED: '/applications/getting-started',
  APPLICATION_SELECT_INTAKE: '/applications/select-intake',
  APPLICATION_CREATE_CHILD: '/applications/create-child',
  EDIT_APPLICATION: '/applications/:id',
  APPLICATION_CHILD_DETAILS: '/applications/:id/child-details',
  CHILD_OVERVIEW: '/children/:id/overview',
  CHILD_PURCHASE_EXTRA_HOURS: '/children/:id/purchase-extra-hours',
  CHILD_AVAILABLE_EXTRA_HOURS: '/children/:id/new-booking/extra-hours',
  CHILD_NEW_BOOKING: '/children/:id/new-booking',
  CHILD_UPDATE: '/children/:id/update',
  APPLICATION_CARER: '/applications/:id/carer',
  APPLICATION_HOUSEHOLD_DETAILS: '/applications/:id/household-details',
  APPLICATION_PLACEMENT_PREFERENCES: '/applications/:id/placement-preferences',
  APPLICATION_ADDITIONAL_DETAILS: '/applications/:id/additional-details',
  WELCOME: '/welcome',
  APPLICATION_ELIGIBILITY: '/applications/eligibility/:childId',
  APPLICATION_CONFIRM_ELIGIBILITY: '/applications/eligibility/:childId/confirm',
  APPLICATION_START: '/applications/start/:childId',
  APPLICATION_SUCCESS: '/applications/:id/success',
  HELPDESK: '/helpdesk',
  HELPDESK_TICKET: '/helpdesk/ticket/:id',
  HELPDESK_NEW_TICKET: '/helpdesk/new-ticket',
  TRUSTEE_DASHBOARD: '/children/:id/trustee',
  MESSAGING: '/messaging',
  MESSAGING_CONVERSATION: '/messaging/:id',
  MESSAGING_NEW_CONVERSATION: '/messaging/new',
});
