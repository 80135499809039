import { Ticket } from '@admissions-support/types';
import { CheckboxBase } from '../form/common/checkbox';
import { messageCategories } from './message-categories';
import { ChangeEvent } from 'react';

type MessageCategoryFilterProps = {
  selectedCategories: Ticket['topic'][];
  setSelectedCategories: (categories: Ticket['topic'][]) => void;
};

function MessageCategoryFilter(props: MessageCategoryFilterProps) {
  const { selectedCategories, setSelectedCategories } = props;

  const handleCategoryChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedCategory = event.target.name as Ticket['topic'];
    const index = selectedCategories.indexOf(selectedCategory);
    if (index === -1) {
      setSelectedCategories([...selectedCategories, selectedCategory]);
    } else {
      setSelectedCategories(
        selectedCategories.filter(c => c !== selectedCategory)
      );
    }
  };

  const handleSelectAllCategories = () => {
    if (selectedCategories.length === Object.keys(messageCategories).length) {
      setSelectedCategories([]);
    } else {
      setSelectedCategories(
        Object.keys(messageCategories) as Ticket['topic'][]
      );
    }
  };

  const isSomeSelected = Object.keys(messageCategories).some(category =>
    selectedCategories.includes(category as Ticket['topic'])
  );

  return (
    <>
      <div className="max-h-96 space-y-2 overflow-y-auto">
        {Object.entries(messageCategories).map(([key, value]) => {
          return (
            <CheckboxBase
              key={key}
              name={key}
              label={value}
              onChange={handleCategoryChange}
              checked={selectedCategories.findIndex(c => c === key) >= 0}
              className="ml-1"
            />
          );
        })}
      </div>
      <hr className="!my-4" />
      <CheckboxBase
        name="selectAllCategories"
        label="Select All"
        onChange={handleSelectAllCategories}
        checked={
          selectedCategories.length === Object.keys(messageCategories).length
        }
        indeterminate={isSomeSelected}
        className="ml-1"
      />
    </>
  );
}

export { MessageCategoryFilter };
