import { Textarea } from '@/components/form/common/textarea';

type AdditionalInfoElementProps = {
  disabled?: boolean;
};

function AdditionalInfoElement(props: AdditionalInfoElementProps) {
  const { disabled } = props;

  return (
    <>
      <div>
        <p className="text-md font-medium leading-7 text-gray-900">
          Other information
        </p>
        <p className="text-md leading-6 text-gray-600">
          Please give below any further information you want us to consider. You
          may want to describe any particular needs your child has. This is
          particularly important if your child&apos;s fifth birthday is between
          the start of the school session and 31st December and therefore not
          automatically entitled to an additional year of early learning &
          childcare.
        </p>
      </div>
      <div>
        <Textarea
          name="additionalDetails"
          label="Supporting Information"
          disabled={disabled}
          placeholder="Leave blank if not applicable"
          rows={5}
        />
      </div>
    </>
  );
}

export { AdditionalInfoElement };
