import { RegisterDto, RegisterForm } from '@/components/form/register.form';
import { LoadingScreen } from '@/components/loading-screen';
import { LoginTitle } from '@/components/login-title';
import { useAuth } from '@/context/auth.context';
import { useInvitation } from '@/hooks/query-hooks/use-invitation';
import { Helmet } from 'react-helmet-async';

function CreateAccount() {
  const { isLoading, data: invitation, isError } = useInvitation();
  const { register } = useAuth();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!invitation || isError) {
    return (
      <>
        <Helmet>
          <title>Create account</title>
        </Helmet>

        <div>
          <LoginTitle
            title="Invalid invitation code"
            description="You invitation is expired or not valid"
          />
        </div>
      </>
    );
  }

  const handleRegister = async (dto: RegisterDto) => {
    await register({
      invitationCode: invitation.id,
      organisationId: invitation.organisationId.toString(),
      email: dto.email,
      password: dto.password,
    });
  };

  return (
    <>
      <Helmet>
        <title>Create account</title>
      </Helmet>

      <div>
        <LoginTitle
          title={`Hi ${invitation.firstName},`}
          description="You have been invited to create an account."
        />
        <RegisterForm onRegister={handleRegister} initialData={invitation} />
      </div>
    </>
  );
}

export { CreateAccount };
