import { userKeys } from '@/config/query-keys';
import { childService } from '@/services/child.service';
import { HttpError } from '@/types/error';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useAssignChildMutation(
  mutationOptions?: UseMutationOptions<
    void,
    HttpError,
    {
      email: string | null;
      childId: string;
    }
  >
) {
  return useMutation({
    mutationKey: userKeys.suspend,
    mutationFn: childService.assignEmailToChild,
    ...mutationOptions,
  });
}

export { useAssignChildMutation };
