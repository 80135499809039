import { twMerge } from 'tailwind-merge';

type TextCellProps = {
  cellValue?: string | null;
  tooltipId?: string | null;
};

function TextCell(props: TextCellProps) {
  const { cellValue, tooltipId } = props;

  const isOverflow = cellValue && 25 < cellValue.length;

  return (
    <span
      className={twMerge(
        'block max-w-52 overflow-hidden truncate',
        isOverflow ? 'hover:cursor-pointer' : ''
      )}
      data-tooltip-id={isOverflow && tooltipId ? tooltipId : ''}
      data-tooltip-content={cellValue}
    >
      {cellValue ? cellValue : '--'}
    </span>
  );
}

export { TextCell };
