import { organisationKey } from '@/config/query-keys';
import { useSchoolYear } from '@/context/school-year.context';
import { useModal } from '@/hooks/use-modal';
import { useResetMatching } from '@/hooks/use-reset-matching';
import { useRunMatching } from '@/hooks/use-run-matching';
import { queryClient } from '@/libs/react-query';
import { MatchingSummary } from '@admissions-support/types';
import { Dialog, Transition } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatDistance } from 'date-fns';
import { Fragment, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Tooltip } from 'react-tooltip';
import * as yup from 'yup';
import { Select } from '../form/common/select';
import { Spinner } from '../spinner';

type MatchingModalProps = {
  disabled?: boolean;
};

const schema = yup.object({
  choice: yup.string().required(),
});

function MatchingModal(props: MatchingModalProps) {
  const { disabled } = props;
  const cancelButtonRef = useRef(null);
  const { isOpen, openModal, closeModal } = useModal();
  const { schoolYear } = useSchoolYear();
  const { isPending: isResetting, mutateAsync: resetMatching } =
    useResetMatching();

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      choice: '0',
    },
  });

  const {
    isPending: isMatching,
    mutateAsync,
    data,
    reset,
  } = useRunMatching({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['application'],
      });
      queryClient.invalidateQueries({
        queryKey: ['application-list'],
      });
      queryClient.invalidateQueries({
        queryKey: organisationKey.terms,
      });
    },
  });

  const handleSubmit = (data: { choice: string }) => {
    mutateAsync({ choice: parseInt(data.choice) });
  };

  const handleReset = async () => {
    await resetMatching();
  };

  const disableForm = isMatching || disabled;
  const choice = form.watch('choice');

  const getLastRunByTerm = () => {
    switch (choice) {
      case '0':
        if (!schoolYear.matchingChoicesDetails.firstChoiceLastRunAt) {
          return 'Never run before';
        }

        return (
          'Last run ' +
          formatDistance(
            new Date(schoolYear.matchingChoicesDetails.firstChoiceLastRunAt),
            new Date(),
            { addSuffix: true }
          )
        );
      case '1':
        if (!schoolYear.matchingChoicesDetails.secondChoiceLastRunAt) {
          return 'Never run before';
        }

        return (
          'Last run ' +
          formatDistance(
            new Date(schoolYear.matchingChoicesDetails.secondChoiceLastRunAt),
            new Date(),
            { addSuffix: true }
          )
        );
      case '2':
        if (!schoolYear.matchingChoicesDetails.thirdChoiceLastRunAt) {
          return 'Never run before';
        }

        return (
          'Last run ' +
          formatDistance(
            new Date(schoolYear.matchingChoicesDetails.thirdChoiceLastRunAt),
            new Date(),
            { addSuffix: true }
          )
        );
    }
  };

  function sumAllocations(): number {
    let sum = 0;

    if (!data) {
      return 0;
    }

    for (const key in data?.allocationsMade) {
      const value =
        data.allocationsMade[key as keyof MatchingSummary['allocationsMade']];
      if (value !== null && value !== undefined) {
        sum += value;
      }
    }

    return sum;
  }

  function getAllocationPercantage(allocation?: number) {
    if (!allocation) {
      return 0;
    }

    return (allocation / nbOfAllocations) * 100;
  }

  function resetModalView() {
    form.reset();
    closeModal();
    reset();
  }

  const nbOfAllocations = sumAllocations();
  const percentageOfNotMatched = getAllocationPercantage(
    data?.allocationsMade.NOT_MATCHED
  );
  const percentageOfMatched = getAllocationPercantage(
    data?.allocationsMade.MATCHED
  );
  const percentageOfWaitingList = getAllocationPercantage(
    data?.allocationsMade.WAITING_LIST
  );

  return (
    <>
      <button
        className="btn btn-primary"
        onClick={openModal}
        disabled={disableForm}
      >
        Run Matching
      </button>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-60"
          initialFocus={cancelButtonRef}
          onClose={resetModalView}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900 bg-opacity-75 backdrop-blur-sm transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-60 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-5">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Run Matching
                        </Dialog.Title>
                      </div>
                    </div>
                  </div>

                  {isMatching && (
                    <div className="absolute inset-0 z-10 flex h-full items-center justify-center bg-white">
                      <div className="flex flex-col items-center">
                        <Spinner className="h-12 w-12" />
                        <p className="mt-1">Processing Applications..</p>
                      </div>
                    </div>
                  )}
                  {data && (
                    <div className="px-4 pb-8 sm:px-6">
                      <p className="text-md font-semibold">
                        {nbOfAllocations} Applications Processed
                      </p>
                      <p>Matching took {data.duration} seconds</p>
                      <Tooltip
                        anchorSelect=".waiting-list"
                        content={`${data.allocationsMade.WAITING_LIST} Waiting List`}
                        place="top"
                        className="tooltip"
                      />

                      <Tooltip
                        anchorSelect=".matched"
                        content={`${data.allocationsMade.MATCHED} Matched`}
                        place="top"
                        className="tooltip"
                      />
                      <Tooltip
                        anchorSelect=".not-matched"
                        content={`${data.allocationsMade.NOT_MATCHED} Not Matched`}
                        place="top"
                        className="tooltip"
                      />
                      <div className="relative mt-4 flex h-[14px] w-full overflow-hidden rounded-md bg-gray-100">
                        <div
                          style={{
                            width: percentageOfWaitingList + '%',
                          }}
                          className="waiting-list cursor-pointer bg-yellow-500"
                        />
                        <div
                          style={{
                            width: percentageOfMatched + '%',
                          }}
                          className="matched cursor-pointer bg-primary-400"
                        />
                        <div
                          style={{
                            width: percentageOfNotMatched + '%',
                          }}
                          className="not-matched cursor-pointer bg-gray-400"
                        />
                      </div>

                      <div className="mt-6 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="btn btn-secondary mt-4 w-full sm:mr-2 sm:mt-0"
                          onClick={resetModalView}
                        >
                          View Applications
                        </button>
                      </div>
                    </div>
                  )}
                  {!data && (
                    <FormProvider {...form}>
                      <form onSubmit={form.handleSubmit(handleSubmit)}>
                        <div className="px-4 py-8 sm:px-6">
                          <Select
                            name="choice"
                            label="Choice"
                            disabled={disableForm}
                            options={[
                              { key: '0', value: '1st Choice' },
                              { key: '1', value: '2nd Choice' },
                              { key: '2', value: '3rd Choice' },
                            ]}
                            helperText={getLastRunByTerm()}
                          />
                          <button
                            className="link text-sm"
                            onClick={handleReset}
                            type="button"
                          >
                            {isResetting ? 'Resetting..' : 'Reset matching?'}
                          </button>
                        </div>

                        <div className="px-4 py-6 sm:flex sm:flex-row-reverse sm:px-6">
                          <button
                            type="submit"
                            className="btn btn-primary w-full sm:ml-2"
                            disabled={disableForm}
                          >
                            Run Matching
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary mt-4 w-full sm:mr-2 sm:mt-0"
                            onClick={closeModal}
                            ref={cancelButtonRef}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </FormProvider>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export { MatchingModal };
