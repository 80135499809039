import { Box } from '@/components/box';
import { Toggle } from '@/components/form/common/toggle';
import { parentRouterPath } from '@/config/route-paths.config';
import { useMessages } from '@/hooks/query-hooks/use-messages';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { MessagingTable } from './messaging-table';

function ParentMessaging() {
  const [showUnreadFirst, setShowUnreadFirst] = useState(false);
  const [showClosedTasks, setShowClosedTasks] = useState(false);
  const { data: messages, isLoading } = useMessages(
    { isParent: true },
    {
      ...(showClosedTasks
        ? { status: ['OPEN', 'IN_PROGRESS', 'PENDING', 'CLOSED'] }
        : {}),
      ...(showUnreadFirst && { showUnreadFirst: true }),
    }
  );

  return (
    <div>
      <Box className="mt-8">
        <div className="flex flex-col items-start gap-3 md:flex-row md:items-center md:justify-between md:gap-4">
          <div>
            <p className="text-lg font-semibold text-gray-900">Your Tickets</p>
            <p className="text-sm text-gray-600">
              Details of any of your tickets.
            </p>
          </div>
          <div className="flex gap-4">
            <Toggle
              value={showUnreadFirst}
              onChange={() => setShowUnreadFirst(!showUnreadFirst)}
              label="Show Unread First"
            />
            <Toggle
              value={showClosedTasks}
              onChange={() => setShowClosedTasks(!showClosedTasks)}
              label="Show Closed Tasks"
            />
            <Link
              to={parentRouterPath.MESSAGING_NEW_CONVERSATION}
              className="btn btn-primary"
            >
              New Support Ticket
            </Link>
          </div>
        </div>
        <div className="mt-5">
          <MessagingTable
            messages={messages?.docs || []}
            isLoading={isLoading}
            setShowClosedTasks={setShowClosedTasks}
            isParent
          />
        </div>
      </Box>
    </div>
  );
}

export { ParentMessaging };
