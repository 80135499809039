import { isAdminDashboard } from '@/utils/is-dashboard';
import { Amplify } from 'aws-amplify';

let userPoolClientId;

/**
 * during local development we need this, so we can have the different webClientId for each dashboard,
 * on production we can just change the VITE_USER_POOL_WEB_CLIENT_ID based on the deployed
 */
if (import.meta.env.VITE_ENVIRONMENT !== 'local') {
  userPoolClientId = import.meta.env.VITE_USER_POOL_WEB_CLIENT_ID;
} else {
  userPoolClientId = isAdminDashboard()
    ? import.meta.env.VITE_USER_POOL_WEB_CLIENT_ID
    : import.meta.env.VITE_USER_POOL_WEB_CLIENT_ID_PARENT;
}

Amplify.configure({
  Auth: {
    Cognito: {
      identityPoolId: import.meta.env.VITE_IDENTITY_POOL_ID,
      userPoolId: import.meta.env.VITE_USER_POOL_ID,
      userPoolClientId: userPoolClientId,
      signUpVerificationMethod: 'code',
      loginWith: {
        oauth: {
          domain: import.meta.env.VITE_USER_POOL_DOMAIN,
          scopes: ['aws.cognito.signin.user.admin', 'openid'],
          redirectSignIn: [import.meta.env.VITE_REDIRECT_SIGN_IN_URL],
          redirectSignOut: [import.meta.env.VITE_REDIRECT_SIGN_OUT_URL],
          responseType: 'code',
          providers: [{ custom: 'mygovscot' }],
        },
      },
    },
  },

  Storage: {
    S3: {
      bucket: import.meta.env.VITE_BUCKET,
      region: import.meta.env.VITE_AWS_REGION,

      buckets: {
        'messaging-files': {
          region: import.meta.env.VITE_AWS_REGION,
          bucketName: import.meta.env.VITE_MESSAGING_BUCKET,
        },
      },
    },
  },
});
