import { LoadingScreen } from '@/components/loading-screen';
import { LoginTitle } from '@/components/login-title';
import { parentRouterPath } from '@/config/route-paths.config';
import { useOrganisationData } from '@/context/organisation-control.context';
import { useConfirmParentAccount } from '@/hooks/use-confirm-parent-account';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Navigate, useSearchParams } from 'react-router-dom';

function ConfirmParentAccount() {
  const { mutate, isPending, isError, error } = useConfirmParentAccount();
  const [searchParams] = useSearchParams();
  const confirmationCode = searchParams.get('code') || '';
  const { data: organisation } = useOrganisationData();

  useEffect(() => {
    if (confirmationCode) {
      mutate({ confirmationCode, organisationId: organisation.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmationCode]);

  if (!confirmationCode) {
    return <Navigate to={parentRouterPath.SIGN_IN} replace />;
  }

  if (isPending) {
    return <LoadingScreen />;
  }

  if (isError) {
    return (
      <>
        <Helmet>
          <title>Unsuccessful Account Validation</title>
        </Helmet>

        <div>
          <LoginTitle
            title="Unsuccessful Account Validation!"
            description={`We're sorry, but we couldn't validate your code. Reason: ${error.message}`}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Account Confirmed</title>
      </Helmet>

      <div>
        <LoginTitle
          title="Successfully Verified"
          description="You can now login and continue setting up your account."
        />

        <Link
          to={parentRouterPath.SIGN_IN}
          className="btn btn-primary btn-full mt-5"
        >
          Sign In
        </Link>
      </div>
    </>
  );
}

export { ConfirmParentAccount };
