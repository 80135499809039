import { organisationKey } from '@/config/query-keys';
import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import { CreateUserGroupDto, Organisation } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCreateUserGroupMutation(
  mutationOptions?: UseMutationOptions<
    Organisation,
    HttpError,
    CreateUserGroupDto
  >
) {
  return useMutation({
    mutationKey: organisationKey.userGroupsList,
    mutationFn: organisationService.createUserGroup,
    ...mutationOptions,
  });
}

export { useCreateUserGroupMutation };
