function formatCurrency(amount: number): string {
  const options: Intl.NumberFormatOptions = {
    style: 'currency' as const,
    currency: 'GBP',
    minimumFractionDigits: amount % 1 === 0 ? 0 : 2,
    maximumFractionDigits: amount % 1 === 0 ? 0 : 2,
  };
  return amount.toLocaleString('en-GB', options);
}

export { formatCurrency };
