import { LoginTitle } from '@/components/login-title';
import { parentRouterPath } from '@/config/route-paths.config';
import { Helmet } from 'react-helmet-async';
import { Navigate, useLocation } from 'react-router-dom';

function VerifyParentEmail() {
  const { state } = useLocation();

  if (!state || !state.email) {
    return <Navigate to={parentRouterPath.SIGN_IN} />;
  }

  return (
    <>
      <Helmet>
        <title>Verify Your Email</title>
      </Helmet>

      <div>
        <LoginTitle title="Verify Your Email" />
        <p className="mt-2 font-light">
          We have sent a verification link to <b>{state.email}</b>.
        </p>
        <p className="mt-2 font-light">
          Please click on it to activate your account. If you do not see the
          email, check your spam folder.
        </p>
      </div>
    </>
  );
}

export { VerifyParentEmail };
