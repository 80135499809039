import { AuthenticatorForm } from '@/components/form/authenticator.form';
import { LoginTitle } from '@/components/login-title';
import { useConfirmPhoneNumberMutation } from '@/hooks/use-confirm-phone-number-mutation';
import { MfaSetupRouterState } from '@/types/auth';
import { ConfirmPhoneNumberDto } from '@admissions-support/types';
import { ArrowNarrowLeft } from '@untitled-ui/icons-react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';

function SmsVerificationMfaSetup() {
  const navigate = useNavigate();
  const location = useLocation();
  const routeState = location.state as MfaSetupRouterState;
  const { mutateAsync } = useConfirmPhoneNumberMutation();

  const handlePhoneNumberVerify = async (codeDto: ConfirmPhoneNumberDto) => {
    await mutateAsync(codeDto);
    navigate('/');
  };

  const routerState: MfaSetupRouterState = {
    fromSmsVerifyScreen: true,
    phoneNumber: routeState.phoneNumber,
  };

  const handleBackToLogin = () => {
    navigate('/auth/mfa-setup', { state: routerState });
  };

  return (
    <>
      <Helmet>
        <title>Verify your login</title>
      </Helmet>

      <div>
        <LoginTitle
          title="Verify your code"
          description="You should receive a code. Enter the code to verify. Alternatively you can return to the previous screen to edit the number to you’d like to use."
        />

        <AuthenticatorForm onVerify={handlePhoneNumberVerify} />
        <div>
          <button
            type="button"
            onClick={handleBackToLogin}
            className="link mt-20 flex"
          >
            <ArrowNarrowLeft className="mr-3" /> Edit Phone Number
          </button>
        </div>
      </div>
    </>
  );
}

export { SmsVerificationMfaSetup };
