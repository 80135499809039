import { Link } from 'react-router-dom';

type FormColEmptyStateProps = {
  title: string;
  description?: string;
  btnText?: string;
  slug?: string;
};

function FormColEmptyState(props: FormColEmptyStateProps) {
  const { title, description, btnText, slug } = props;
  return (
    <div className="mx-auto max-w-[350px] py-6">
      <p className="text-md text-center font-medium text-gray-900">{title}</p>
      {description ? (
        <p className="text-center text-sm">{description}</p>
      ) : null}
      {btnText && slug && (
        <div className="mt-6 text-center">
          <Link to={slug} className="btn btn-secondary">
            {btnText}
          </Link>
        </div>
      )}
    </div>
  );
}

export { FormColEmptyState };
