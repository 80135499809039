import { ChoiceState } from '@/reducers/choice.reducer';
import {
  Choice,
  ChoiceDto,
  OperatingDaySession,
} from '@admissions-support/types';
import { pickBy, uniqBy } from 'lodash-es';
import {
  selectNbOfAdditionalSelectedSessions,
  selectNbOfSelectedSplitPlacementSessions,
} from './application-utils';

function transformChoiceStateToChoiceDto(
  state: ChoiceState,
  nbPreference: number
): Omit<ChoiceDto, 'type'> {
  const commonState: Omit<ChoiceDto, 'type'> = {
    sessions: state.sessionsPattern,
    locationId: state.establishment,
    nbPreference: nbPreference,
    additionalSessions: state.additionalSessionsPattern,
  };

  const nbOfAdditionalSelectedSessions =
    selectNbOfAdditionalSelectedSessions(state);
  const nbOfSplitPlacementSelectedSessions =
    selectNbOfSelectedSplitPlacementSessions(state);

  /**
   * We have split placement enabled only on the core sessions.
   */
  if (
    state.enableSplitPlacement &&
    state.splitPlacementEstablishmentId &&
    nbOfSplitPlacementSelectedSessions > 0 &&
    nbOfAdditionalSelectedSessions === 0
  ) {
    return {
      ...commonState,
      splitPlacement: {
        sessions: state.splitPlacementSessionsPattern,
        locationId: state.splitPlacementEstablishmentId,
        /**
         * we know its null, because if you have split placement enabled on the core,
         * the additional sessions is going to be split as well.
         * And because nbOfAdditionalSelectedSessions is 0, we know we did not select any
         * additional sessions.
         */
        additionalSessionLocationId: null,
      },
    };
  }

  /**
   * We only split the additional sessions
   */
  if (
    state.enableDifferentEstablishmentForAdditionalSessions &&
    !state.enableSplitPlacement &&
    nbOfAdditionalSelectedSessions > 0
  ) {
    return {
      ...commonState,
      splitPlacement: {
        sessions: null,
        locationId: null,
        additionalSessionLocationId:
          state.additionalSessionsEstablishmentId || null,
      },
    };
  }

  /**
   * We have both core and additional sessions placement enabled
   */
  if (
    state.enableSplitPlacement &&
    nbOfAdditionalSelectedSessions > 0 &&
    nbOfSplitPlacementSelectedSessions > 0
  ) {
    return {
      ...commonState,
      splitPlacement: {
        sessions: state.splitPlacementSessionsPattern,
        locationId: state.splitPlacementEstablishmentId,
        additionalSessionLocationId:
          state.additionalSessionsEstablishmentId || null,
      },
    };
  }

  return {
    ...commonState,
    splitPlacement: null,
  };
}

function getLocationsByChoice(choice: Choice): { id: string; name: string }[] {
  const locations: { id: string; name: string }[] = [
    {
      id: choice.location.id.toString(),
      name: choice.location.name,
    },
  ];

  if (choice.splitPlacement?.additionalSessionLocation) {
    locations.push({
      id: choice.splitPlacement.additionalSessionLocation.id.toString(),
      name: choice.splitPlacement.additionalSessionLocation.name,
    });
  }

  if (choice.splitPlacement?.location) {
    locations.push({
      id: choice.splitPlacement.location.id.toString(),
      name: choice.splitPlacement.location.name,
    });
  }

  return uniqBy(locations, 'id');
}

function getChoiceLocationsName(choice: Choice): string {
  const locations = getLocationsByChoice(choice);

  return locations.map(location => location.name).join(', ');
}

function getOperatingDaysForSessions(
  sessions?: OperatingDaySession | null
): (keyof OperatingDaySession)[] {
  if (!sessions) {
    return [];
  }

  const nonNullDays = pickBy(sessions, value => value !== null);

  const days = Object.keys(nonNullDays);

  return days as (keyof OperatingDaySession)[];
}

function isCouncilChoice(state: ChoiceState): boolean {
  return state.establishmentType === 'COUNCIL';
}

export {
  getChoiceLocationsName,
  getLocationsByChoice,
  getOperatingDaysForSessions,
  isCouncilChoice,
  transformChoiceStateToChoiceDto,
};
