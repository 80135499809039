import { ChildExtraHoursTableFilter } from '@/components/parent/purchase-extra-hours/child-extra-hours-table';
import { bookingKey } from '@/config/query-keys';
import { bookingService } from '@/services/booking.service';
import { HttpError } from '@/types/error';
import { BookingListing, PaginateResult } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PaginationState, SortingState } from '@tanstack/react-table';

function useChildBookings(
  data: {
    childId: string;
    pagination: PaginationState;
    sorting: SortingState;
    filter: ChildExtraHoursTableFilter;
  },
  queryOptions?: UseQueryOptions<PaginateResult<BookingListing>, HttpError>
) {
  return useQuery({
    queryKey: bookingKey.byChild(
      data.childId,
      data.pagination,
      data.sorting,
      data.filter
    ),
    queryFn: () =>
      bookingService.getBookingsByChildId({
        childId: data.childId,
        pagination: data.pagination,
        sorting: data.sorting,
        filter: data.filter,
      }),
    ...queryOptions,
  });
}

export { useChildBookings };
