import * as yup from 'yup';

const additionalQuestionSchema = yup.object({
  type: yup.string().required().label('Question Type'),
  label: yup.string().required().label('Question Label'),
  options: yup
    .array(
      yup.object({
        label: yup.string().required().label('Option'),
        value: yup.string().required().label('Option'),
      })
    )
    .required()
    .label('Question Options'),
});

export { additionalQuestionSchema };
