import { DeleteZone } from '@/components/delete-zone';
import { WeekModelForm } from '@/components/form/week-model.form';
import { PageTitle } from '@/components/page-title';
import { Spinner } from '@/components/spinner';
import { organisationKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useCreateWeekModelMutation } from '@/hooks/create-hooks/use-create-week-model-mutation';
import { useDeleteWeekModelMutation } from '@/hooks/delete-hooks/use-delete-week-model-mutation';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useUpdateWeekModelMutation } from '@/hooks/update-hooks/use-update-week-model';
import { useWeekModel } from '@/hooks/query-hooks/use-week-model';
import { queryClient } from '@/libs/react-query';
import { Permission } from '@/types/auth';
import { getErrorMessage } from '@/utils/get-error-message';
import { CreateModelsDto, UpdateModelsDto } from '@admissions-support/types';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

async function invalidateWeekModelsQuery() {
  await queryClient.invalidateQueries({
    queryKey: organisationKey.weekModels,
    refetchType: 'active',
  });
}

function UpsertWeekModel() {
  const navigate = useNavigate();
  const { mutateAsync: create, isPending: isCreating } =
    useCreateWeekModelMutation({
      onSuccess: invalidateWeekModelsQuery,
    });
  const { mutateAsync: update, isPending: isUpdating } =
    useUpdateWeekModelMutation({
      onSuccess: invalidateWeekModelsQuery,
    });
  const params = useParams<{ id?: string }>();
  const isCreateWeekModelView = useMatch(staffRouterPath.NEW_WEEK_MODEL);

  const {
    isLoading: isWeekModelLoading,
    data: weekModel,
    error,
  } = useWeekModel(params.id);

  useEffect(() => {
    if (error) {
      navigate(staffRouterPath.WEEK_MODELS);
    }
  }, [error, navigate]);

  const {
    mutateAsync: deleteWeekModel,
    isPaused: isDeleting,
    error: deleteError,
  } = useDeleteWeekModelMutation({
    onSuccess: async () => {
      queryClient.removeQueries({
        queryKey: organisationKey.weekModel(params.id || ''),
      });
      await invalidateWeekModelsQuery();
      navigate(staffRouterPath.WEEK_MODELS);
    },
    retry: 0,
  });
  const { hasPermission } = usePermissions();

  const isLoading =
    isCreating ||
    isUpdating ||
    !hasPermission([Permission['organisation:update']]);

  const handleCreateWeekModel = async (data: CreateModelsDto) => {
    if (!data.additionalReadingURL) {
      delete data.additionalReadingURL;
    }

    await create(data);
    navigate(staffRouterPath.WEEK_MODELS);
  };

  const handleUpdateWeekModel = async (data: UpdateModelsDto) => {
    if (!data.additionalReadingURL) {
      data.additionalReadingURL = null;
    }

    await update({
      id: params.id || '',
      data,
    });
  };

  const handleCancel = () => {
    navigate(staffRouterPath.WEEK_MODELS);
  };

  const handleDeleteWeekModel = async () => {
    await deleteWeekModel(params.id || '');
  };

  return (
    <div>
      <PageTitle
        title={isCreateWeekModelView ? 'New Week Model' : 'Edit Week Model'}
        variant="gray"
      >
        <div className="flex gap-3">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCancel}
            disabled={isLoading}
          >
            Cancel
          </button>

          <button
            className="btn btn-primary"
            disabled={isLoading}
            form="upsert-user-group-form"
          >
            {isCreateWeekModelView ? 'Create' : 'Update'}
          </button>
        </div>
      </PageTitle>

      {isWeekModelLoading && !isCreateWeekModelView ? (
        <div className="flex justify-center py-12">
          <Spinner className="h-12 w-12" />
        </div>
      ) : (
        <>
          <WeekModelForm
            onSubmit={
              isCreateWeekModelView
                ? handleCreateWeekModel
                : handleUpdateWeekModel
            }
            initialData={weekModel}
            isLoading={isLoading}
          />
          {!isCreateWeekModelView &&
          hasPermission([Permission['organisation:delete']]) ? (
            <div className="two-col-form">
              <div>
                <p className="text-md font-medium leading-7 text-gray-900">
                  Destructive Actions
                </p>
                <p className="text-md leading-6 text-gray-600">
                  Take care with these actions as they are destructive and may
                  not be reversable.
                </p>
              </div>
              <div className="col-span-2 space-y-6">
                <DeleteZone
                  title="Delete Week Model"
                  actionButtonText="Delete Week Model"
                  description="This action cannot be completed if there are locations using this model."
                  modalDescription="In order to delete this Week Model, please type DELETE in the input below and confirm deletion."
                  onDelete={handleDeleteWeekModel}
                  isLoading={isDeleting}
                  error={getErrorMessage(deleteError)}
                />
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}

export { UpsertWeekModel };
