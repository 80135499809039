import { Select } from '@/components/form/common/select';
import { TextInput } from '@/components/form/common/text-input';
import { PageTitle } from '@/components/page-title';
import { SchoolYearFilter } from '@/components/school-year-filter';
import { useCapacitySimulation } from '@/context/capacity-simulation.context';
import { useSchoolYear } from '@/context/school-year.context';
import {
  useDemandReport,
  useDemandReportCsv,
} from '@/hooks/query-hooks/use-demand-report';
import { useRatios } from '@/hooks/query-hooks/use-ratios';
import {
  filterBySelectedLocations,
  orderByLocationName,
} from '@/utils/capacity-simulation-utils';
import { formatDateToYYYYMMDD } from '@/utils/format-date';
import {
  CapacityReportQueryParamsDto,
  DemandReportQueryParamsDto,
  SchoolYear,
} from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileDownload03, Loading01, Play } from '@untitled-ui/icons-react';
import { format, isWithinInterval } from 'date-fns';
import { isEmpty } from 'lodash-es';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { DemandTable } from './demand-table';
import { CapacityDemandLocationFilter } from './locations-filter';

const schema = yup.object({
  ratioId: yup.string().required().label('Ration'),
  matchingDate: yup
    .string()
    .test(
      'notInSchoolYear',
      'Date must be within the selected school year',
      function test(value) {
        const { schoolYear } = this.options.context as {
          schoolYear?: SchoolYear;
        };
        if (!schoolYear || !value) {
          return true;
        }

        const isSelectedDateIsValid = isWithinInterval(new Date(value), {
          start: new Date(schoolYear.startDate),
          end: new Date(schoolYear.endDate),
        });

        return isSelectedDateIsValid
          ? true
          : this.createError({
              message: `Date must be within the selected school year: ${format(
                new Date(schoolYear.startDate),
                'dd/MM/yyyy'
              )} - ${format(new Date(schoolYear.endDate), 'dd/MM/yyyy')}`,
              path: 'matchingDate',
            });
      }
    ),
});

export type CapacityFormData = yup.InferType<typeof schema>;

function DemandSummaryReport() {
  const {
    data: ratios,
    isSuccess: isRatioSuccess,
    isLoading: isRatiosLoading,
  } = useRatios();
  const ratiosForSelect = isRatioSuccess
    ? ratios.map(ratio => ({
        key: ratio.id,
        value: ratio.name,
      }))
    : [];
  const { schoolYear } = useSchoolYear();
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [reportFilter, setReportFilter] =
    useState<CapacityReportQueryParamsDto>({} as CapacityReportQueryParamsDto);

  const { setDataUpdatedAt } = useCapacitySimulation();

  const form = useForm({
    context: {
      schoolYear,
    },
    resolver: yupResolver(schema),
    defaultValues: {
      ratioId: '',
      matchingDate: formatDateToYYYYMMDD(new Date()),
    },
  });

  const {
    data: demandReport,
    isFetching: isDemandReportLoading,
    dataUpdatedAt: demandReportUpdatedAt,
    refetch: refetchDemandReport,
    isSuccess: isDemandReportSuccess,
  } = useDemandReport(
    {
      ...reportFilter,
      choiceIndex: 0,
    },
    {
      enabled: !isEmpty(reportFilter),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 0,
    }
  );

  const { refetch: fetchDemandCsv } = useDemandReportCsv(
    {
      ...reportFilter,
      choiceIndex: 0,
    },
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (isDemandReportSuccess) {
      setDataUpdatedAt(demandReportUpdatedAt);
    }
  }, [isDemandReportSuccess, demandReportUpdatedAt, setDataUpdatedAt]);

  useEffect(() => {
    if (isEmpty(reportFilter)) {
      return;
    }

    async function init() {
      await refetchDemandReport();
    }

    init();
  }, [refetchDemandReport, reportFilter]);

  const submitHandler = async (data: CapacityFormData) => {
    const demandReportFilter: DemandReportQueryParamsDto = {
      ratioId: data.ratioId,
      schoolYearId: schoolYear.id,
      date: data.matchingDate,
      // we decided to include only the first choice for demand summary
      choiceIndex: 0,
    };

    setReportFilter(demandReportFilter);
  };

  const exportCSV = async () => {
    // Request CSV
    const csvBlob = await toast.promise(fetchDemandCsv(), {
      loading: 'Exporting CSV...',
      success: 'CSV exported successfully',
      error: 'Error exporting CSV',
    });

    if (!csvBlob.isSuccess) {
      return;
    }

    const blob = csvBlob.data;

    // Download the file
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'demand-report.csv';

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const demandReportWithSelectedLocations = orderByLocationName(
    filterBySelectedLocations(demandReport || [], selectedLocations)
  );

  return (
    <div>
      <PageTitle
        title="Demand Summary"
        description="Custom Report"
        variant="gray"
      />
      <div className="two-col-form mt-6 gap-6 py-6">
        <div className="flex flex-col">
          <h2 className="font-semibold text-gray-700">Demand Summary</h2>
          <span className="text-gray-600">
            View the raw application demand.
          </span>
        </div>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(submitHandler)}
            className="col-span-2"
          >
            <div className="grid gap-6 rounded-lg border border-solid border-gray-200 px-4 py-6">
              <div className="grid gap-6 sm:grid-cols-2">
                <div className="space-y-6">
                  <SchoolYearFilter
                    label="Select School Year"
                    className="shadow-sm"
                    disabled={form.formState.isSubmitting}
                  />
                  <Select
                    label="Ratio"
                    options={[
                      { key: '', value: 'Select a Ratio...' },
                      ...ratiosForSelect,
                    ]}
                    name="ratioId"
                    disabled={isRatiosLoading}
                  />
                </div>
                <div className="space-y-6">
                  <TextInput
                    name="matchingDate"
                    type="date"
                    label="Select Matching Date"
                  />
                </div>
              </div>
              <div className="flex justify-end">
                <button className="btn btn-primary">
                  <div className="flex items-center gap-2">
                    {isDemandReportLoading ? (
                      <Loading01
                        className="leading-icon animate-spin"
                        aria-hidden="true"
                      />
                    ) : (
                      <Play className="leading-icon" aria-hidden="true" />
                    )}
                    {isDemandReportLoading ? 'Query Running' : 'Run Query'}
                  </div>
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
      {demandReport && (
        <div className="flex flex-col gap-6 bg-white pb-6">
          <div className="flex items-center justify-between pt-6">
            <div className="flex items-center gap-4">
              <h4 className="text-lg font-semibold text-gray-900">Output</h4>
              <CapacityDemandLocationFilter
                onSelectedLocationsChange={setSelectedLocations}
                selectedLocations={selectedLocations}
              />
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={exportCSV}
              >
                <div className="flex items-center gap-2">
                  <FileDownload03 className="h-5 w-5" />
                  Export to CSV
                </div>
              </button>
            </div>
          </div>
          <FormProvider {...form}>
            <DemandTable
              report={demandReportWithSelectedLocations}
              isLoading={isDemandReportLoading}
            />
          </FormProvider>
        </div>
      )}
    </div>
  );
}

export { DemandSummaryReport };
