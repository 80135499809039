import { UseModalResult, useModal } from '@/hooks/use-modal';
import {
  ToggledColumns,
  ReportingOutputControl,
  ReportingOutputFilter,
  SideDrawerContent,
} from '@/types/reporting-output-control';
import { DeepPartial } from '@/types/util';
import { PaginationState, SortingState } from '@tanstack/react-table';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

type ReportingOutputControlContextState = ReportingOutputControl & {
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  setSorting: Dispatch<SetStateAction<SortingState>>;
  setFilter: Dispatch<SetStateAction<ReportingOutputFilter>>;
  setSideDrawerContent: Dispatch<SetStateAction<SideDrawerContent>>;
  setToggledColumns: Dispatch<SetStateAction<ToggledColumns>>;
  setHasQueryRun: Dispatch<SetStateAction<boolean>>;
} & UseModalResult;

const defaultReportingOutputControl: Omit<
  ReportingOutputControl,
  'toggledColumns'
> = {
  filter: {},
  pagination: {
    pageIndex: 0,
    pageSize: 10,
  },
  sorting: [
    {
      id: 'childName',
      desc: false,
    },
  ],
  sideDrawerContent: undefined,
  hasQueryRun: false,
};

const ReportingOutputControlContext = createContext<
  ReportingOutputControlContextState | undefined
>(undefined);

function ReportingOutputControlProvider({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
  defaultControl?: DeepPartial<ReportingOutputControl>;
}) {
  const [sideDrawerContent, setSideDrawerContent] = useState<SideDrawerContent>(
    defaultReportingOutputControl.sideDrawerContent
  );

  const [pagination, setPagination] = useState<PaginationState>(
    defaultReportingOutputControl.pagination
  );

  const [sorting, setSorting] = useState<SortingState>(
    defaultReportingOutputControl.sorting
  );

  const [filter, setFilter] = useState<ReportingOutputFilter>(
    defaultReportingOutputControl.filter
  );

  const [hasQueryRun, setHasQueryRun] = useState<boolean>(false);

  const [toggledColumns, setToggledColumns] = useState<ToggledColumns>({});

  const { isOpen, openModal, closeModal, toggle } = useModal();

  const handleSetFilter = (state: SetStateAction<ReportingOutputFilter>) => {
    // navigate to the first page if filter changes
    setPagination(prev => ({ ...prev, pageIndex: 0 }));
    setFilter(state);
  };

  const updatedReportingOutputControl: ReportingOutputControl = useMemo(
    () => ({
      filter,
      pagination,
      sorting,
      sideDrawerContent,
      toggledColumns,
      hasQueryRun,
    }),
    [
      filter,
      pagination,
      sorting,
      sideDrawerContent,
      toggledColumns,
      hasQueryRun,
    ]
  );

  return (
    <ReportingOutputControlContext.Provider
      value={{
        ...updatedReportingOutputControl,
        setFilter: handleSetFilter,
        setPagination,
        setSorting,
        setSideDrawerContent,
        setToggledColumns,
        setHasQueryRun,
        isOpen,
        openModal,
        closeModal,
        toggle,
      }}
    >
      {children}
    </ReportingOutputControlContext.Provider>
  );
}

const useReportingOutputControl = () => {
  const context = useContext(ReportingOutputControlContext);

  if (!context) {
    throw new Error(
      'useReportingOutputControl must be used within a ReportingOutputControlProvider'
    );
  }
  return context;
};

export {
  ReportingOutputControlProvider,
  defaultReportingOutputControl,
  useReportingOutputControl,
};
