import { extraHourService } from '@/services/extra-hour.service';
import { HttpError } from '@/types/error';
import { UpdateExtraHoursDto } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateProductPrice(
  mutationOptions?: UseMutationOptions<
    { updated: number },
    HttpError,
    { data: UpdateExtraHoursDto }
  >
) {
  return useMutation({
    mutationFn: extraHourService.updateExtraHoursPrice,
    ...mutationOptions,
  });
}

export { useUpdateProductPrice };
