type BadRequest = {
  error: string;
  statusCode: 400;
  message: string[] | string;
};

type HttpError =
  | {
      error: string;
      statusCode: number;
      message: string;
    }
  | BadRequest;

function isBadRequest(error: HttpError): error is BadRequest {
  return error.statusCode === 400;
}

type AmplifyError = { code: string; message: string };

export { isBadRequest };
export type { AmplifyError, HttpError };
