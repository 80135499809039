import { userKeys } from '@/config/query-keys';
import { userService } from '@/services/user.service';
import { HttpError } from '@/types/error';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useSuspendUserMutation(
  mutationOptions?: UseMutationOptions<
    void,
    HttpError,
    { isSuspended: boolean; userId: string }
  >
) {
  return useMutation({
    mutationKey: userKeys.suspend,
    mutationFn: userService.suspendUser,
    ...mutationOptions,
  });
}

export { useSuspendUserMutation };
