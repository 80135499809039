import { applicationTemplateKey } from '@/config/query-keys';
import { applicationTemplateService } from '@/services/application-template.service';
import { HttpError } from '@/types/error';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useDeleteAdditionalQuestionMutation(
  mutationOptions?: UseMutationOptions<
    void,
    HttpError,
    { templateId: string; questionId: string }
  >
) {
  return useMutation({
    mutationKey: applicationTemplateKey.deleteQuestion,
    mutationFn: applicationTemplateService.deleteQuestion,
    ...mutationOptions,
  });
}

export { useDeleteAdditionalQuestionMutation };
