import { ExtraHourResourceState } from '@/types/extra-hour-resource';
import { getOperatingDaysOfSessionType } from '@/utils/location-session-utils';
import { RatioCapacity, SessionType } from '@admissions-support/types';
import { merge, uniq } from 'lodash-es';
import { useReducer } from 'react';

type ExtraHourResourceForm = {
  sessionTypes: SessionType[];
  sessionTypeIds: string[];
  resources: ExtraHourResourceState[];
  ratioCapacities: RatioCapacity[];
};

const initialExtraHourResourceForm: ExtraHourResourceForm = {
  sessionTypes: [], // list of sessions to look up session types by id
  sessionTypeIds: [],
  resources: [],
  ratioCapacities: [],
};

enum ExtraHourResourceFormActionTypes {
  SET_SESSSION_TYPES = 'SET_SESSSION_TYPES',
  TOGGLE_SAME_CAPACITY = 'TOGGLE_SAME_CAPACITY',
  SET_GENERAL_CAPACITY = 'SET_GENERAL_CAPACITY',
  SET_CAPACITY = 'SET_CAPACITY',
  SET_RATIO_CAPACITY = 'SET_RATIO_CAPACITY',
}

type Action =
  | {
      type: ExtraHourResourceFormActionTypes.SET_SESSSION_TYPES;
      payload: string[];
    }
  | {
      type: ExtraHourResourceFormActionTypes.TOGGLE_SAME_CAPACITY;
      payload: {
        ratioId: string;
      };
    }
  | {
      type: ExtraHourResourceFormActionTypes.SET_GENERAL_CAPACITY;
      payload: {
        ratioId: string;
        capacity: number | null;
      };
    }
  | {
      type: ExtraHourResourceFormActionTypes.SET_CAPACITY;
      payload: {
        day: string;
        ratioId: string;
        capacity: number | null;
      };
    }
  | {
      type: ExtraHourResourceFormActionTypes.SET_RATIO_CAPACITY;
      payload: {
        ratioId: string;
        capacity: number | null;
      }[];
    };

function reducer(
  state: ExtraHourResourceForm,
  action: Action
): ExtraHourResourceForm {
  switch (action.type) {
    case ExtraHourResourceFormActionTypes.SET_SESSSION_TYPES: {
      if (action.payload.length < 1) {
        return {
          ...state,
          sessionTypeIds: [],
          resources: [],
        };
      }

      // no session type was selected earlier,
      // so lets build the basic resource array
      if (state.sessionTypeIds.length < 1) {
        const selectedSessionTypeId = action.payload[0];

        const selectedSessionType = state.sessionTypes.find(
          sessionType => sessionType.id === selectedSessionTypeId
        );

        // not possible, just leave it here to not worry about undefined session
        if (!selectedSessionType) {
          return {
            ...state,
            sessionTypeIds: [],
            resources: [],
          };
        }

        const operatingDays =
          getOperatingDaysOfSessionType(selectedSessionType);

        const emptyResources: ExtraHourResourceState[] =
          state.ratioCapacities.map(rc => {
            return {
              ratioId: rc.ratio.id.toString(),
              hasSameCapacityForEachDay: true,
              capacityDays: operatingDays.map(day => {
                return {
                  day,
                  capacity: 0,
                };
              }),
            };
          });

        return {
          ...state,
          sessionTypeIds: action.payload,
          resources: emptyResources,
        };
      }

      return {
        ...state,
        sessionTypeIds: action.payload,
      };
    }

    case ExtraHourResourceFormActionTypes.TOGGLE_SAME_CAPACITY: {
      const ratioId = action.payload.ratioId;

      const newStateResources = state.resources.map(resource => {
        if (resource.ratioId === ratioId) {
          const isAllCapacityTheSame =
            uniq(resource.capacityDays.map(cd => cd.capacity)).length === 1;
          // If toggle turned to true and not all capacities are the same, make them 0
          if (!isAllCapacityTheSame && !resource.hasSameCapacityForEachDay) {
            return {
              ...resource,
              hasSameCapacityForEachDay: !resource.hasSameCapacityForEachDay,
              capacityDays: resource.capacityDays.map(cd => ({
                ...cd,
                capacity: 0,
              })),
            };
          }
          return {
            ...resource,
            hasSameCapacityForEachDay: !resource.hasSameCapacityForEachDay,
          };
        }
        return resource;
      });

      return {
        ...state,
        resources: newStateResources,
      };
    }

    case ExtraHourResourceFormActionTypes.SET_GENERAL_CAPACITY: {
      const ratioId = action.payload.ratioId;
      const newCapacity = action.payload.capacity;

      const newStateResources = state.resources.map(resource => {
        if (resource.ratioId === ratioId) {
          return {
            ...resource,
            capacityDays: resource.capacityDays.map(cd => ({
              ...cd,
              capacity: newCapacity,
            })),
          };
        }
        return resource;
      });

      return {
        ...state,
        resources: newStateResources,
      };
    }

    case ExtraHourResourceFormActionTypes.SET_CAPACITY: {
      const ratioId = action.payload.ratioId;
      const day = action.payload.day;
      const newCapacity = action.payload.capacity;

      const newStateResources = state.resources.map(resource => {
        if (resource.ratioId === ratioId) {
          return {
            ...resource,
            capacityDays: resource.capacityDays.map(cd => {
              if (cd.day === day) {
                return {
                  ...cd,
                  capacity: newCapacity,
                };
              }
              return cd;
            }),
          };
        }
        return resource;
      });

      return {
        ...state,
        resources: newStateResources,
      };
    }

    case ExtraHourResourceFormActionTypes.SET_RATIO_CAPACITY: {
      return {
        ...state,
        ratioCapacities: state.ratioCapacities.map(rc => {
          const matchingRatio = action.payload.find(
            ratio => ratio.ratioId === rc.ratio.id.toString()
          );
          if (matchingRatio) {
            return {
              ...rc,
              capacity: matchingRatio.capacity || 0,
            };
          }

          return { ...rc, capacity: 0 };
        }),
      };
    }
  }
}

function useExtraHourResourceFormReducer(
  ratioCapacities: RatioCapacity[],
  sessionTypes: SessionType[],
  initialState?: Partial<ExtraHourResourceForm>
) {
  const defaultState = {
    ...initialExtraHourResourceForm,
    ratioCapacities,
    sessionTypes,
  };

  // any overlapping properties in initialState will overwrite those in defaultState
  return useReducer(reducer, merge({}, defaultState, initialState));
}

export { ExtraHourResourceFormActionTypes, useExtraHourResourceFormReducer };
export type { ExtraHourResourceForm };
