import { staffRouterPath } from '@/config/route-paths.config';
import { useProductControl } from '@/context/products-control.context';
import { useSchoolYear } from '@/context/school-year.context';
import { useLocationOperation } from '@/hooks/query-hooks/use-operation';
import { useProductsByLocationId } from '@/hooks/query-hooks/use-products-by-location-id';
import { getErrorMessage } from '@/utils/get-error-message';
import { ProductListing } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import {
  CurrencyPoundCircle,
  Edit01,
  SearchRefraction,
  Trash01,
} from '@untitled-ui/icons-react';
import { useEffect, useMemo } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { FormColEmptyState } from '../form-col-empty-state';
import { Table } from '../table';

type ProductsTableSectionProps = {
  onEditProduct: (product: ProductListing) => void;
  onChangePrice: (product: ProductListing) => void;
  onDelete: (product: ProductListing) => void;
};

function ProductsTableSection(props: ProductsTableSectionProps) {
  const { onEditProduct, onChangePrice, onDelete } = props;

  const { locationId = '' } = useParams<{ locationId: string }>();

  const { schoolYear } = useSchoolYear();
  const {
    data: operation,
    isLoading: isOperationLoading,
    error: operationError,
    isSuccess: isOperationSuccess,
  } = useLocationOperation(locationId);

  const { pagination, setPagination } = useProductControl();

  const {
    data: products,
    isLoading: isProductsLoading,
    error: productsError,
    isFetching: isProductsFetching,
  } = useProductsByLocationId(locationId, {
    schoolYearId: schoolYear.id,
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
  });

  /**
   * user have pagination saved in local storage, but data changed.
   * There is no longer data available at the saved pageindex, so reset it to 0
   */
  useEffect(() => {
    if (pagination.pageIndex > 0 && products && products.docs.length === 0) {
      setPagination({
        pageIndex: 0,
        pageSize: pagination.pageSize,
      });
    }
  }, [products, pagination, setPagination]);

  const columnHelper = createColumnHelper<ProductListing>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        cell: info => {
          const productName = info.getValue();
          return <span className="text-sm text-gray-600">{productName}</span>;
        },
        header: () => (
          <span className="text-xs text-gray-600">Product Name</span>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor('sessionType', {
        id: 'sessionType',
        cell: info => {
          const sessionType = info.getValue();
          if (!sessionType) {
            return <span className="text-sm text-gray-600">--</span>;
          }
          return (
            <span className="text-sm text-gray-600">{sessionType.name}</span>
          );
        },
        header: () => (
          <span className="text-xs text-gray-600">Resource Group</span>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor('session', {
        id: 'session',
        cell: info => {
          const session = info.getValue();
          if (!session) {
            return <span className="text-sm text-gray-600">--</span>;
          }
          return <span className="text-sm text-gray-600">{session.name}</span>;
        },
        header: () => <span className="text-xs text-gray-600">Based On</span>,
        enableSorting: false,
      }),
      columnHelper.display({
        id: 'actions',
        cell: info => {
          const product = info.row.original;
          return (
            <div className="flex gap-1">
              <button
                className="icon-link"
                type="button"
                onClick={() => {
                  onDelete(product);
                }}
              >
                <Trash01 aria-hidden="true" />
              </button>
              <button
                className="icon-link"
                type="button"
                onClick={() => {
                  onChangePrice(product);
                }}
              >
                <CurrencyPoundCircle aria-hidden="true" />
              </button>
              <button
                className="icon-link"
                type="button"
                onClick={() => {
                  onEditProduct(product);
                }}
              >
                <Edit01
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                />
              </button>
            </div>
          );
        },
        header: () => <span className="text-xs text-gray-600">Actions</span>,
        enableSorting: false,
        size: 160,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isLoading = isOperationLoading || isProductsLoading;

  const hasOperationDefined =
    isOperationSuccess && operation.extraHoursResources.length > 0;

  return (
    <Table
      columns={columns}
      data={products?.docs || []}
      isLoading={isLoading}
      isFetching={isProductsFetching}
      error={getErrorMessage(productsError || operationError)}
      onPaginationChange={setPagination}
      pagination={pagination}
      pageCount={products?.totalPages}
      paginationType="manual"
      EmptyState={
        hasOperationDefined ? (
          <div className="py-12 text-center">
            <SearchRefraction
              className="mx-auto h-8 w-8 text-gray-400"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              No Products found
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Get started by creating a product.
            </p>
          </div>
        ) : (
          <FormColEmptyState
            title="No Extra Hour Resource Found"
            btnText="Add Extra Hour Resource"
            slug={generatePath(
              staffRouterPath.LOCATION_PURCHASE_EXTRA_HOURS_AVAILABILITY,
              {
                locationId,
              }
            )}
            description="Before you're able to create a product for this location, please define at least one resource."
          />
        )
      }
    />
  );
}

export { ProductsTableSection };
