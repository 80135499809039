import { twMerge } from 'tailwind-merge';

type BoxProps = {
  children: React.ReactNode;
  className?: string;
};

function Box(props: BoxProps) {
  const { children, className } = props;

  return (
    <div
      className={twMerge(
        'rounded-xl border border-gray-200 bg-white p-4 sm:p-8',
        className
      )}
    >
      {children}
    </div>
  );
}

export { Box };
