import { twMerge } from 'tailwind-merge';
import { RadioBoxOptionProps } from '../form/common/radio-box';

type CheckboxItemProps = {
  onChange: () => void;
  checked: boolean;
  Content: React.ComponentType<RadioBoxOptionProps>;
  disabled?: boolean;
};

function CheckboxItem(props: CheckboxItemProps) {
  const { onChange, checked, Content, disabled } = props;

  return (
    <div
      className={twMerge(
        'relative flex select-none rounded-xl border p-4 focus:outline-none',
        checked
          ? 'z-10 border-primary-600 bg-primary-50 ring-1 ring-primary-600'
          : 'border-gray-200 bg-white',
        disabled ? 'cursor-not-allowed bg-gray-50' : 'cursor-pointer'
      )}
      onClick={onChange}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') {
          onChange();
          e.preventDefault();
        }
      }}
      role="button"
      tabIndex={-1}
    >
      <input
        type="checkbox"
        className={twMerge(
          'h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600',
          disabled ? 'cursor-not-allowed bg-gray-50' : 'cursor-pointer'
        )}
        checked={checked}
        onChange={() => {
          //silent to ignore console error. this onChange event will propagate to the parent div's onClick handler
        }}
      />
      <div className="ml-3 w-full">
        <Content checked={checked} />
      </div>
    </div>
  );
}
export { CheckboxItem };
