import { Controller, get, useFormContext } from 'react-hook-form';
import Select, { GroupBase, OptionsOrGroups, Props } from 'react-select';
import { twMerge } from 'tailwind-merge';

export type GroupSelectOption = {
  label: string;
  value: string;
  isDisabled?: boolean;
};

interface GroupSelectProps
  extends Props<GroupSelectOption, false, GroupBase<GroupSelectOption>> {
  name: string;
  label?: string;
  helperText?: string;
}

function isGroupedOption(
  options: OptionsOrGroups<GroupSelectOption, GroupBase<GroupSelectOption>>
): options is GroupBase<GroupSelectOption>[] {
  const groups = options as GroupBase<GroupSelectOption>[];

  if (groups.length < 1) {
    return false;
  }

  return Array.isArray(groups[0].options);
}

function GroupSelect({
  label,
  name,
  options,
  helperText,
  className,
  ...inputProps
}: GroupSelectProps) {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const error = get(errors, name);
  const hasError = Boolean(error);

  const getValue = (option: GroupSelectOption) => {
    if (!options) {
      return;
    }

    if (isGroupedOption(options)) {
      return options
        .flatMap(group => group.options)
        .find(o => o.value === option.value);
    }
  };

  return (
    <div className={className}>
      {label ? (
        <label htmlFor={name} className="label">
          {label}
        </label>
      ) : null}
      <div className={twMerge('relative', label ? 'mt-2' : null)}>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value, ref, disabled } }) => {
            return (
              <Select
                ref={ref}
                options={options}
                isDisabled={disabled}
                styles={{
                  input: baseStyle => ({
                    ...baseStyle,
                    padding: 0,
                    margin: 0,
                    'input:focus': {
                      boxShadow: 'none',
                    },
                  }),
                  valueContainer: baseStyle => ({
                    ...baseStyle,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingLeft: 12,
                  }),

                  placeholder: baseStyle => ({
                    ...baseStyle,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }),

                  clearIndicator: baseStyle => ({
                    ...baseStyle,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }),
                  dropdownIndicator: baseStyle => ({
                    ...baseStyle,
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingRight: 12,
                  }),

                  /*
                   * with empty return objects no default style will be added by the lib,
                   * and can be styled with tailwind classes in classNames property
                   */
                  control: () => ({}),
                  multiValueRemove: () => ({}),
                  multiValue: () => ({}),
                  multiValueLabel: () => ({}),
                  option: () => ({}),
                }}
                tabSelectsValue
                classNames={{
                  control: state =>
                    twMerge(
                      'input flex pt-[7px] pb-[7px] bg-white',
                      state.isDisabled ? 'bg-gray-50 text-gray-500' : null,
                      state.isFocused
                        ? 'ring-2 ring-inset ring-primary-600'
                        : null,
                      hasError ? 'ring-red-500 focus:ring-red-600' : null
                    ),
                  placeholder: () => '',
                  multiValue: () =>
                    'flex bg-white border border-gray-300 rounded-md pl-2 pr-1 m-0.5',
                  multiValueLabel: () => 'flex text-sm text-gray-700',
                  multiValueRemove: () =>
                    'flex text-gray-400 hover:text-red-500 items-center ml-1',
                  option: state =>
                    twMerge(
                      'px-3 py-2 select-none cursor-pointer hover:bg-secondary-light-200 hover:text-primary-800',
                      state.isFocused
                        ? 'bg-secondary-light-200 text-primary-800'
                        : null,
                      state.isDisabled
                        ? 'text-gray-300 hover:cursor-not-allowed hover:text-gray-300 hover:bg-white'
                        : ''
                    ),
                }}
                value={getValue(value)}
                onChange={option => onChange(option?.value)}
                {...inputProps}
              />
            );
          }}
        />
      </div>
      {helperText ? (
        <p className="mt-1.5 text-sm text-gray-600">{helperText}</p>
      ) : null}
      {hasError ? (
        <p className="mt-1.5 text-sm text-red-600">
          {error?.message?.toString()}
        </p>
      ) : null}
    </div>
  );
}

function GroupSelectBase({
  label,
  name,
  options,
  helperText,
  error,
  value,
  isSubmitting,
  onChange,
  className,
  ...inputProps
}: GroupSelectProps & {
  error?: string;
  isSubmitting?: boolean;
}) {
  const hasError = Boolean(error);

  return (
    <div className={className}>
      {label ? (
        <label htmlFor={name} className="label">
          {label}
        </label>
      ) : null}
      <div className={twMerge('relative', label ? 'mt-2' : null)}>
        <Select
          key={`${name}:${value}`}
          options={options}
          isDisabled={isSubmitting}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            input: baseStyle => ({
              ...baseStyle,
              padding: 0,
              margin: 0,
              'input:focus': {
                boxShadow: 'none',
              },
            }),
            valueContainer: baseStyle => ({
              ...baseStyle,
              paddingTop: 1,
              paddingBottom: 1,
              paddingLeft: 12,
            }),

            placeholder: baseStyle => ({
              ...baseStyle,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }),

            clearIndicator: baseStyle => ({
              ...baseStyle,
              paddingTop: 0,
              paddingBottom: 0,
            }),
            dropdownIndicator: baseStyle => ({
              ...baseStyle,
              paddingTop: 0,
              paddingBottom: 0,
              paddingRight: 12,
            }),

            /*
             * with empty return objects no default style will be added by the lib,
             * and can be styled with tailwind classes in classNames property
             */
            control: () => ({}),
            multiValueRemove: () => ({}),
            multiValue: () => ({}),
            multiValueLabel: () => ({}),
            option: () => ({}),
          }}
          tabSelectsValue
          classNames={{
            control: state =>
              twMerge(
                'input flex pt-[7px] pb-[7px] bg-white',
                state.isDisabled ? 'bg-gray-50 text-gray-500' : null,
                state.isFocused ? 'ring-2 ring-inset ring-primary-600' : null,
                hasError ? 'ring-red-500 focus:ring-red-600' : null
              ),
            placeholder: () => '',
            multiValue: () =>
              'flex bg-white border border-gray-300 rounded-md pl-2 pr-1 m-0.5',
            multiValueLabel: () => 'flex text-sm text-gray-700',
            multiValueRemove: () =>
              'flex text-gray-400 hover:text-red-500 items-center ml-1',
            option: state =>
              twMerge(
                'px-3 py-2 select-none cursor-pointer hover:bg-secondary-light-200 hover:text-primary-800',
                state.isFocused
                  ? 'bg-secondary-light-200 text-primary-800'
                  : null
              ),
          }}
          value={value}
          onChange={onChange}
          {...inputProps}
        />
      </div>
      {helperText ? (
        <p className="mt-1.5 text-sm text-gray-600">{helperText}</p>
      ) : null}
      {hasError ? (
        <p className="mt-1.5 text-sm text-red-600">{error?.toString()}</p>
      ) : null}
    </div>
  );
}

export { GroupSelect, GroupSelectBase };
