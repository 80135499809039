import { logKey } from '@/config/query-keys';
import { logService } from '@/services/log.service';
import { HttpError } from '@/types/error';
import { Log } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useChangelog(
  applicationId: string,
  queryOptions?: UseQueryOptions<Log[], HttpError>
) {
  return useQuery({
    queryKey: logKey.single(applicationId),
    queryFn: () => logService.getByApplicationId(applicationId),
    ...queryOptions,
    enabled: Boolean(applicationId),
  });
}

export { useChangelog };
