import * as yup from 'yup';
import {
  applicationConditionTypes,
  bookingConditionTypes,
  locationConditionTypes,
} from './reporting.const';
import { ConditionType } from './reporting.type';

const conditionSchema = yup.object().shape({
  id: yup.string().required(),
  type: yup
    .mixed<ConditionType | ''>()
    .oneOf(
      [
        ...applicationConditionTypes,
        ...locationConditionTypes,
        ...bookingConditionTypes,
      ],
      'Invalid condition type'
    )
    .required()
    .label('Condition type'),
  value: yup.string().required().label('Condition value'),
});

const conditionGroupSchema = yup.object().shape({
  id: yup.string().required(),
  variant: yup.mixed<'and' | 'or'>().oneOf(['and', 'or']).required(),
  data: yup.array().of(conditionSchema).required(),
});

const queryBuilderSchema = yup.object().shape({
  name: yup.string().required().label('Name'),
  visibility: yup.boolean().required().label('Visibility'),
  dataSource: yup.string().required().label('Data Source'),
  conditionGroups: yup.array().of(conditionGroupSchema).required(),
});

export { conditionGroupSchema, conditionSchema, queryBuilderSchema };
