import { userService } from '@/services/user.service';
import { HttpError } from '@/types/error';
import { VerifyInvitationDto } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useVerifyParentInvitation(
  mutationOptions?: UseMutationOptions<void, HttpError, VerifyInvitationDto>
) {
  return useMutation({
    mutationFn: userService.verifyParentInvitation,
    ...mutationOptions,
  });
}

export { useVerifyParentInvitation };
