import { locationKey } from '@/config/query-keys';
import { locationService } from '@/services/location.service';
import { HttpError } from '@/types/error';
import {
  ExtraHourPublic,
  FetchExtraHoursByLocationDto,
} from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useExtraHoursByLocation(
  locationId: string,
  data: FetchExtraHoursByLocationDto,
  queryOptions?: Partial<UseQueryOptions<ExtraHourPublic[], HttpError>>
) {
  return useQuery({
    queryKey: locationKey.extraHoursByLocationId(
      locationId || '',
      data.from,
      data.to
    ),
    queryFn: () => locationService.getExtraHoursByLocation(locationId, data),
    ...queryOptions,
  });
}

export { useExtraHoursByLocation };
