import { Table } from '@/components/table';
import { CapacityReport, FlatResource } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { Tooltip } from 'react-tooltip';
import { CapacityResourcingTableCell } from './capacity-table-cells';

type CapacityResourcingTableProps = {
  report: CapacityReport;
};

function CapacityResourcingTable({ report }: CapacityResourcingTableProps) {
  const columnHelper = createColumnHelper<FlatResource>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('sessionTypeGroupName', {
        header: () => <div>Resource Group</div>,
        cell: ({ row }) => <div>{row.original.sessionTypeGroupName}</div>,
      }),
      columnHelper.accessor('capacity.monday', {
        header: () => <div>Monday</div>,
        cell: ({ row }) => (
          <CapacityResourcingTableCell
            resource={row.original}
            day="monday"
            locationId={report.location.id}
          />
        ),
      }),
      columnHelper.accessor('capacity.tuesday', {
        header: () => <div>Tuesday</div>,
        cell: ({ row }) => (
          <CapacityResourcingTableCell
            resource={row.original}
            day="tuesday"
            locationId={report.location.id}
          />
        ),
      }),
      columnHelper.accessor('capacity.wednesday', {
        header: () => <div>Wednesday</div>,
        cell: ({ row }) => (
          <CapacityResourcingTableCell
            resource={row.original}
            day="wednesday"
            locationId={report.location.id}
          />
        ),
      }),
      columnHelper.accessor('capacity.thursday', {
        header: () => <div>Thursday</div>,
        cell: ({ row }) => (
          <CapacityResourcingTableCell
            resource={row.original}
            day="thursday"
            locationId={report.location.id}
          />
        ),
      }),
      columnHelper.accessor('capacity.friday', {
        header: () => <div>Friday</div>,
        cell: ({ row }) => (
          <CapacityResourcingTableCell
            resource={row.original}
            day="friday"
            locationId={report.location.id}
          />
        ),
      }),
    ],
    [columnHelper, report.location.id]
  );

  return (
    <div className="flex flex-col gap-2">
      <span className="text-sm font-medium text-gray-900">Resourcing</span>
      {report.resources.length === 0 ? (
        <div className="text-sm text-gray-500">No resources found</div>
      ) : (
        <>
          <Table columns={columns} data={report.resources} className="mt-0" />
          <Tooltip
            place="top-end"
            id="capacity-resourcing-tooltip"
            className="tooltip !z-50"
          />
        </>
      )}
    </div>
  );
}

export { CapacityResourcingTable };
