import { useLeaveModal } from '@/hooks/use-leave-modal';
import { HttpError, isBadRequest } from '@/types/error';
import { getNestedKeys } from '@/utils/get-nested-keys';
import { Location } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { LocationDetailsSection } from './upsert-location/location-details.section';
import { KeyContactSection } from './upsert-location/key-contact.section';
import { AreasSection } from './upsert-location/areas.section';

const formArea = yup.object({
  label: yup.string().label('Area Name').required(),
  value: yup.string().label('Area Id').required(),
});

const schema = yup.object({
  name: yup.string().required().label('Name'),
  type: yup.string().required().label('Type'),
  providerType: yup.string().required().label('Provider Type'),
  serviceProviderNumber: yup
    .string()
    .required()
    .label('Service Provider Number'),
  careInspectorNumber: yup.string().required().label('Care Inspector Number'),
  contactNumber: yup.string().nullable().label('Contact Number'),
  website: yup.string().nullable().label('Website'),
  seedCode: yup.string().nullable().label('Seed Code'),
  address: yup
    .object({
      streetAddress: yup.string().required().label('Address Line 1'),
      streetAddressTwo: yup.string().nullable().label('Address Line 2'),
      city: yup.string().nullable().label('City'),
      postcode: yup.string().required().label('Postcode'),
    })
    .required(),
  keyContact: yup.object({
    firstName: yup.string().required().label('First Name'),
    lastName: yup.string().required().label('Last Name'),
    email: yup.string().email().required().label('Email'),
  }),
  areas: yup.array(formArea).label('Areas'),
});

export type LocationFormData = yup.InferType<typeof schema>;

type LocationFormProps = {
  onSubmit: (data: LocationFormData) => any;
  initialData?: Location;
  isLoading?: boolean;
};

function LocationForm(props: LocationFormProps) {
  const { initialData, onSubmit } = props;

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: initialData?.name || '',
      type: initialData?.type,
      providerType: initialData?.providerType,
      serviceProviderNumber: initialData?.serviceProviderNumber || '',
      careInspectorNumber: initialData?.careInspectorNumber || '',
      contactNumber: initialData?.contactNumber || '',
      website: initialData?.website || '',
      seedCode: initialData?.seedCode || '',
      address: {
        streetAddress: initialData?.address.streetAddress || '',
        streetAddressTwo: initialData?.address.streetAddressTwo || '',
        city: initialData?.address.city || '',
        postcode: initialData?.address.postcode || '',
      },
      keyContact: {
        firstName: initialData?.keyContact.firstName || '',
        lastName: initialData?.keyContact.lastName || '',
        email: initialData?.keyContact.email || '',
      },
      areas:
        initialData?.areas?.map(area => ({
          value: area.id.toString(),
          label: area.name,
        })) || [],
    },
  });

  useLeaveModal({
    show: form.formState.isDirty && !!initialData,
  });

  const submitHandler = async (data: any) => {
    //TODO: needs to update the package in order to avoid this
    const convertedData = data as LocationFormData;

    if (!convertedData.address.streetAddressTwo) {
      convertedData.address.streetAddressTwo = null;
    }

    if (!convertedData.address.city) {
      convertedData.address.city = null;
    }

    if (!convertedData.website) {
      convertedData.website = null;
    }

    if (!convertedData.contactNumber) {
      convertedData.contactNumber = null;
    }

    try {
      await onSubmit(convertedData);
      form.reset(form.getValues(), { keepValues: true });
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        const availableFields = getNestedKeys(form.getValues());

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }
    }
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(submitHandler)}
        className="flex h-full flex-1 flex-col"
        id="upsert-location-form"
      >
        <LocationDetailsSection />
        <KeyContactSection />
        <AreasSection />
      </form>
    </FormProvider>
  );
}

export { LocationForm };
