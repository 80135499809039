import {
  GroupSelect,
  GroupSelectOption,
} from '@/components/form/common/group-select';
import { PageTitle } from '@/components/page-title';
import { SchoolYearFilter } from '@/components/school-year-filter';
import { useSchoolYear } from '@/context/school-year.context';
import { useAllocationReport } from '@/hooks/query-hooks/use-allocation-report';
import { useLocations } from '@/hooks/query-hooks/use-locations';
import { AllocationPieChart } from '@/pages/reporting/allocation/allocation-chart';
import {
  ColorCode,
  colorsToColumns,
} from '@/pages/reporting/allocation/allocation-color-code';
import { AllocationTable } from '@/pages/reporting/allocation/allocation-table';
import { reportService } from '@/services/report.service';
import { GetAllocationReportResponse } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileDownload03, Loading01, Play } from '@untitled-ui/icons-react';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

const convertToChartItem = (report?: GetAllocationReportResponse) => {
  if (!report) {
    return null;
  }

  return report.cohorts.map(cohort => ({
    id: cohort.id,
    name: cohort.name,
    total: cohort.stats.total,
    stats: Object.entries(cohort.stats)
      .filter(([key]) => !key.includes('percentage') && !key.includes('total'))
      .map(([key, value]) => ({
        name: key,
        value: value,
        color: colorsToColumns[key as keyof typeof colorsToColumns], // Assign color based on the key
      })),
  }));
};

const schema = yup.object({
  locationId: yup.string().required(),
  schoolYearId: yup.string().required(),
});

function AllocationReport() {
  const {
    data: locations,
    isSuccess: isLocationSuccess,
    isLoading: isLocationLoading,
  } = useLocations();
  const schoolYear = useSchoolYear();

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      locationId: '',
      schoolYearId: '',
    },
  });

  useEffect(() => {
    form.setValue('schoolYearId', schoolYear.schoolYear.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolYear]);

  const locationId = form.watch('locationId');
  const schoolYearId = form.watch('schoolYearId');

  const {
    data: report,
    refetch: fetch,
    isFetching: isQueryLoading,
  } = useAllocationReport(locationId, schoolYearId, {
    enabled: false, // Disabled so the query only runs when the user presses the button
    refetchOnMount: false,
  });

  const locationsOptions: GroupSelectOption[] = isLocationSuccess
    ? locations.map(location => ({
        value: location.id,
        label: location.name,
      }))
    : [];

  const submit = (event: React.BaseSyntheticEvent) => {
    event.preventDefault();
    form.handleSubmit(() => fetch())();
  };

  const chartData = convertToChartItem(report);

  // TODO: Add this
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const exportCSV = async () => {
    // Request CSV
    const { locationId, schoolYearId } = form.getValues();
    const blob = await reportService.getAllocationReportCsv({
      locationId,
      schoolYearId,
    });

    // Download the file
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'allocation_report.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <PageTitle
        title="Allocation Report"
        description={'Custom Report'}
        variant="gray"
      />
      <div className="two-col-form mt-6 gap-6 py-6">
        <div className="flex flex-col">
          <h2 className="font-semibold text-gray-700">Report Details</h2>
          <span className="text-gray-600">Configure the Allocation Report</span>
        </div>
        <FormProvider {...form}>
          <div className="col-span-2 space-y-6">
            <div className="grid gap-6 rounded-lg border border-solid border-gray-200 px-4 py-6 md:grid-cols-2">
              <GroupSelect
                label="Select Location"
                name="locationId"
                placeholder="Type to search.."
                className="w-full"
                options={locationsOptions}
                isDisabled={isLocationLoading}
              />
              <SchoolYearFilter
                label={'Select School Year'}
                className="shadow-sm"
              />
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                className="btn btn-primary"
                disabled={!form.formState.isValid}
                onClick={submit}
              >
                <div className="flex items-center gap-2">
                  {isQueryLoading ? (
                    <Loading01
                      className="leading-icon animate-spin"
                      aria-hidden="true"
                    />
                  ) : (
                    <Play className="leading-icon" aria-hidden="true" />
                  )}
                  {isQueryLoading ? 'Query Running' : 'Run Query'}
                </div>
              </button>
            </div>
          </div>
        </FormProvider>
      </div>
      <div className="flex flex-col gap-6 bg-white pb-6">
        <div className="flex items-center justify-between border-t-[1px] border-solid border-t-gray-200 pt-6">
          <h4 className="text-lg font-semibold text-gray-900">Output</h4>
          <button
            type="button"
            className="btn btn-secondary"
            disabled={!report}
            onClick={exportCSV}
          >
            <div className="flex items-center gap-2">
              <FileDownload03 className="h-5 w-5" />
              Export to CSV
            </div>
          </button>
        </div>
        {report && chartData ? (
          <div className="flex flex-col gap-6">
            {chartData?.length ? (
              <div className="flex flex-col gap-6 md:flex-row">
                {chartData?.map(chart => (
                  <AllocationPieChart
                    key={chart.id}
                    data={chart.stats}
                    title={chart.name}
                    description={'Spring Intake'}
                    total={chart.total}
                  />
                ))}
                <div className="flex flex-col items-center gap-1 md:items-start">
                  <ColorCode
                    color={colorsToColumns['choice_1']}
                    text={'1st Choice'}
                  />
                  <ColorCode
                    color={colorsToColumns['choice_2']}
                    text={'2st Choice'}
                  />
                  <ColorCode
                    color={colorsToColumns['choice_3']}
                    text={'3rd Choice'}
                  />
                  <ColorCode
                    color={colorsToColumns['choice_prescribed']}
                    text={'Other'}
                  />
                  <ColorCode
                    color={colorsToColumns['choice_none']}
                    text={'None'}
                  />
                </div>
              </div>
            ) : null}
            <div className="flex flex-col gap-6">
              {report.cohorts.map(cohort => (
                <AllocationTable
                  key={cohort.id}
                  tableName={cohort.name}
                  data={cohort.stats}
                />
              ))}
              <AllocationTable
                tableName={'All eligible'}
                data={report.allEligible}
              />
            </div>
          </div>
        ) : (
          <div>
            <p>There are no results that match your criteria.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export { AllocationReport };
