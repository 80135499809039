import { messagingService } from '@/services/messaging.service';
import { HttpError } from '@/types/error';
import { CreateTicketRequest } from '@admissions-support/types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

type UseCreateMessageProps = {
  userId: string;
  isParent: boolean;
};

function useCreateMessage(
  { userId, isParent }: UseCreateMessageProps,
  mutationOptions?: UseMutationOptions<void, HttpError, CreateTicketRequest>
) {
  return useMutation({
    mutationFn: (data: CreateTicketRequest) =>
      messagingService.createMessage(userId, isParent, data),
    ...mutationOptions,
  });
}

export { useCreateMessage };
