import { messagingKey } from '@/config/query-keys';
import { useAuth } from '@/context/auth.context';
import { messagingService } from '@/services/messaging.service';
import { Permission } from '@/types/auth';
import { HttpError } from '@/types/error';
import { HasUnreadMessagesResponse } from '@admissions-support/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

function useHasUnreadMessages(
  queryOptions?: Partial<UseQueryOptions<HasUnreadMessagesResponse, HttpError>>
) {
  const { permissions } = useAuth();

  return useQuery({
    queryKey: messagingKey.hasUnreadMessages,
    queryFn: messagingService.hasUnreadMessages,
    ...queryOptions,
    enabled: permissions.includes('messaging:read' as Permission),
  });
}

export { useHasUnreadMessages };
