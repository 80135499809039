import { areaKey } from '@/config/query-keys';
import { areaService } from '@/services/area.service';
import { HttpError } from '@/types/error';
import { Area } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useArea(
  id: string,
  queryOptions?: Partial<UseQueryOptions<Area, HttpError>>
) {
  return useQuery({
    queryKey: areaKey.single(id),
    queryFn: () => areaService.getArea(id),
    ...queryOptions,
  });
}

export { useArea };
