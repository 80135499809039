import { trusteeKey } from '@/config/query-keys';
import { useCreateTrusteeMutation } from '@/hooks/create-hooks/use-create-trustee';
import { queryClient } from '@/libs/react-query';
import {
  AddTrusteeFormData,
  addTrusteeSchema,
} from '@/types/add-trustee.schema';
import { HttpError } from '@/types/error';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BaseModalPropsExport } from '../base-modal';
import { ScrollableModal } from '../scroll-modal';
import { AddTrusteeForm } from './add-trustee-form';

type AddTrusteeModalProps = {
  childId: string;
} & BaseModalPropsExport;

function AddTrusteeModal(props: AddTrusteeModalProps) {
  const { isOpen, onClose, childId } = props;

  const { mutateAsync: createTrustee } = useCreateTrusteeMutation();
  const form = useForm({
    resolver: yupResolver(addTrusteeSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      relationship: '',
      canPurchaseExtraHours: false,
      canManageApplications: false,
    },
  });

  useEffect(() => {
    form.reset();
  }, [isOpen, form]);

  const onSubmit = async (data: AddTrusteeFormData) => {
    try {
      const permissions: string[] = [];
      if (data.canManageApplications) {
        permissions.push('MANAGE_APPLICATIONS');
      }
      if (data.canPurchaseExtraHours) {
        permissions.push('PURCHASE_EXTRA_HOURS');
      }

      await createTrustee({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        relationship: data.relationship,
        childId,
        permissions,
      });
      await queryClient.invalidateQueries({
        queryKey: trusteeKey.list(childId),
      });

      onClose();
    } catch (error) {
      const httpError = error as HttpError;

      if (httpError.message === 'Invitation with this email already exists!') {
        form.setError('email', { message: httpError.message });
      }
    }
  };

  return (
    <ScrollableModal isOpen={isOpen} onClose={onClose} size="xl">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ScrollableModal.Header onClose={onClose}>
            <div>
              <ScrollableModal.Title title="Add Trustee" />
              <p className="text-base text-gray-500">
                Please provide the details for this trustee, and specify their
                level of access.
              </p>
            </div>
          </ScrollableModal.Header>
          <ScrollableModal.Content>
            <AddTrusteeForm />
          </ScrollableModal.Content>

          <ScrollableModal.Footer>
            <div className="flex w-full gap-3">
              <button
                className="btn btn-primary w-full"
                disabled={!isEmpty(form.formState.errors)}
                type="submit"
              >
                Invite
              </button>
            </div>
          </ScrollableModal.Footer>
        </form>
      </FormProvider>
    </ScrollableModal>
  );
}

export { AddTrusteeModal };
