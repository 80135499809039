import { Alert } from '@/components/alert';
import { FallbackComponent } from '@/components/fallback-component';
import { LoadingScreen } from '@/components/loading-screen';
import { useSchoolYear } from '@/context/school-year.context';
import { useSessions } from '@/hooks/query-hooks/use-sessions';
import {
  DayObject,
  generateDaysInMonth,
  generateMonthObjects,
  sessionDaysToWeekDays,
} from '@/utils/location-utils';
import { ChevronLeft, ChevronRight, Repeat04 } from '@untitled-ui/icons-react';
import { format, isWithinInterval } from 'date-fns';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { ManageProductFormData } from '../../manage-product.type';

function CalendarElement() {
  const { schoolYear } = useSchoolYear();

  const { locationId = '' } = useParams<string>();

  const { getValues, setValue, watch, formState } =
    useFormContext<ManageProductFormData>();

  const monthObjects = generateMonthObjects(schoolYear);

  const {
    data: sessions,
    isLoading: isSessionsLoading,
    isSuccess: isSessionSuccess,
  } = useSessions(locationId, schoolYear.id);

  const currentSession = sessions?.find(
    session => session.id === getValues().sessionId
  );

  const initialMonthObjIndex = monthObjects.findIndex(
    monthObj =>
      monthObj.month ===
        (new Date(getValues().availableFrom).getMonth() + 1).toString() &&
      monthObj.year ===
        new Date(getValues().availableFrom).getFullYear().toString()
  );

  const [selectedMonthObjectIndex, setSelectedMonthObjectIndex] = useState(
    () => {
      if (initialMonthObjIndex === -1) {
        return 0;
      }
      if (initialMonthObjIndex === monthObjects.length - 1) {
        return initialMonthObjIndex - 1;
      }
      return initialMonthObjIndex;
    }
  );

  const isPreviousButtonDisabled = selectedMonthObjectIndex - 1 < 0;

  const isNextButtonDisabled =
    selectedMonthObjectIndex + 1 >= monthObjects.length - 1;

  const onPreviousMonth = () => {
    if (isPreviousButtonDisabled) {
      return;
    }

    setSelectedMonthObjectIndex(prev => prev - 1);
  };

  const onNextMonth = () => {
    if (isNextButtonDisabled) {
      return;
    }

    setSelectedMonthObjectIndex(prev => prev + 1);
  };

  const selectedMonthObjects = [
    monthObjects[selectedMonthObjectIndex],
    monthObjects[selectedMonthObjectIndex + 1],
  ];

  const sessionWeekdayNumbers = sessionDaysToWeekDays(currentSession?.times);

  const currentMonthDays = [
    generateDaysInMonth(
      selectedMonthObjects[0].year,
      selectedMonthObjects[0].month
    ),
    generateDaysInMonth(
      selectedMonthObjects[1].year,
      selectedMonthObjects[1].month
    ),
  ].map(month =>
    month.map(day => {
      const isWithinFormInterval = isWithinInterval(new Date(day.date), {
        start: new Date(getValues().availableFrom),
        end: new Date(getValues().availableTo),
      });
      if (
        sessionWeekdayNumbers.includes(day.dayOfWeek) &&
        day.isCurrentMonth &&
        isWithinFormInterval
      ) {
        return {
          ...day,
          isInSession: true,
        };
      }
      return day;
    })
  );

  const onDayClick = (day: DayObject) => {
    const newValue = getValues().excludedDays;

    if (newValue.includes(day.date)) {
      // toggle: if a date was already select, unselect it
      onRestoreSession(day.date);
      return;
    }

    if (!day.isInSession) {
      return;
    }

    newValue.push(day.date);
    setValue('excludedDays', newValue);
  };

  const onRestoreSession = (day: string) => {
    const newValue = getValues().excludedDays.filter(
      (date: string) => date !== day
    );
    setValue('excludedDays', newValue, { shouldValidate: true });
  };

  if (isSessionsLoading) {
    return <LoadingScreen />;
  }

  if (!isSessionsLoading && !isSessionSuccess) {
    return <FallbackComponent />;
  }

  const excludedDays: string[] = watch('excludedDays');

  return (
    <>
      <div className="relative grid grid-cols-1 gap-x-14 pt-6 md:grid-cols-2">
        <button
          type="button"
          onClick={onPreviousMonth}
          disabled={isPreviousButtonDisabled}
          className="absolute -left-1.5 top-[18px] flex items-center justify-center p-1.5 hover:text-gray-500"
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeft
            className={twMerge(
              'h-5 w-5',
              isPreviousButtonDisabled ? 'text-gray-400' : 'text-gray-700'
            )}
            aria-hidden="true"
          />
        </button>
        <button
          type="button"
          onClick={onNextMonth}
          disabled={isNextButtonDisabled}
          className="absolute -right-1.5 top-[18px] flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Next month</span>
          <ChevronRight
            className={twMerge(
              'h-5 w-5',
              isNextButtonDisabled ? 'text-gray-400' : 'text-gray-700'
            )}
            aria-hidden="true"
          />
        </button>
        {selectedMonthObjects.map((month, monthIdx) => (
          <section
            key={monthIdx}
            className={twMerge(
              monthIdx === selectedMonthObjects.length - 1 && 'hidden md:block',
              'text-center'
            )}
          >
            <h2 className="text-sm font-semibold text-gray-900">
              {month.label}
            </h2>
            <div className="mt-6 rounded-lg border border-gray-200 px-6 py-5">
              <div className="mb-1.5 mt-6 grid grid-cols-7 text-xs leading-6 text-gray-700">
                <div>Mo</div>
                <div>Tu</div>
                <div>We</div>
                <div>Th</div>
                <div>Fr</div>
                <div>Sa</div>
                <div>Su</div>
              </div>
              <div className="mt-2 grid grid-cols-7 grid-rows-6 gap-x-1 gap-y-1.5 rounded-lg bg-white text-sm">
                {currentMonthDays[monthIdx].map((day, dayIdx) => (
                  <div
                    key={day.date}
                    className={twMerge(
                      !day.isCurrentMonth || !day.isInSession
                        ? 'bg-gray-50 text-gray-300'
                        : 'bg-white text-gray-900',
                      'flex items-center justify-center rounded-lg'
                    )}
                  >
                    <button
                      disabled={!day.isCurrentMonth || !day.isInSession}
                      className={twMerge(
                        dayIdx === 0 && 'rounded-tl-lg',
                        dayIdx === 6 && 'rounded-tr-lg',
                        day.isInSession
                          ? 'border-green-100 bg-green-50 text-green-600'
                          : '',
                        excludedDays.includes(day.date) && day.isInSession
                          ? 'border-red-100 bg-red-50 text-red-600'
                          : '',
                        'relative flex h-10 w-full items-center justify-center rounded-lg border focus:z-10'
                      )}
                      type="button"
                      onClick={() => onDayClick(day)}
                    >
                      <time
                        dateTime={day.date}
                        className={twMerge(
                          'flex h-full w-full items-center justify-center rounded-lg'
                        )}
                      >
                        {new Date(day.date).getDate()}
                      </time>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </section>
        ))}
      </div>
      <div className="my-5 h-[1px] w-full border-t border-gray-200" />
      {excludedDays.length > 0 ? (
        <div>
          <p className="pb-6 text-lg font-semibold text-gray-900">
            Removed Sessions
          </p>
          {formState.errors.excludedDays &&
            formState.errors.excludedDays.message && (
              <Alert
                type="error"
                text={formState.errors.excludedDays?.message}
                className="mb-4"
              />
            )}
          <div className="flex flex-col gap-2">
            {excludedDays.map(day => (
              <div key={`removed-${day}`} className="flex justify-between">
                <div className="flex">
                  <p className="mr-6 w-14 text-sm text-gray-700">
                    {format(new Date(day), 'dd/MM/yy')}
                  </p>
                  <p className="text-sm text-black">{getValues().name}</p>
                </div>
                <button
                  type="button"
                  onClick={() => onRestoreSession(day)}
                  className="flex items-center gap-2 text-sm font-semibold text-gray-600"
                >
                  Restore Session <Repeat04 aria-hidden="true" />
                </button>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
}

export { CalendarElement };
