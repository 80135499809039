import { locationKey } from '@/config/query-keys';
import { locationService } from '@/services/location.service';
import { HttpError } from '@/types/error';
import { Location, UpdateSessionDto } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

function useUpdateSessionMutation(
  mutationOptions?: UseMutationOptions<
    Location,
    HttpError,
    { sessionId: string; data: UpdateSessionDto }
  >
) {
  const params = useParams<{ locationId: string }>();
  const locationId = params.locationId || '';

  return useMutation({
    mutationKey: locationKey.updateSession(locationId),
    mutationFn: ({
      data,
      sessionId,
    }: {
      data: UpdateSessionDto;
      sessionId: string;
    }) =>
      locationService.updateSession({
        locationId: locationId,
        sessionId,
        data,
      }),
    ...mutationOptions,
  });
}

export { useUpdateSessionMutation };
