import { Resource, ResourceCapacity } from '@admissions-support/types';
import { toHumanReadableNumber } from './general-utils';
import { flattenDeep, omit } from 'lodash-es';
import { getIsActive } from './location-resource-utils';

function getAllocatedCapacityRangeByRatio(
  resources: Resource[],
  selectedRatioId: string
) {
  const allocatedCapacitiesArray = flattenDeep(
    resources.map(resource => {
      const currentCapacity = resource.capacities.find((capacity, index) => {
        const isActive = getIsActive(resource.capacities, index, capacity);
        return isActive;
      });

      if (!currentCapacity) {
        return;
      }

      const filteredCurrentCapacity = omit(currentCapacity, [
        'id',
        'from',
      ]) as ResourceCapacity;

      return Object.values(filteredCurrentCapacity);
    })
  )
    .filter(capacity => capacity.ratio.id === selectedRatioId)
    .map(capacity => capacity.allocated);

  return {
    min: toHumanReadableNumber(Math.min(...allocatedCapacitiesArray)),
    max: toHumanReadableNumber(Math.max(...allocatedCapacitiesArray)),
  };
}

export { getAllocatedCapacityRangeByRatio };
