import { Alert } from '@/components/alert';
import { Detail } from '@/components/application/drawer/detail';
import { Spinner } from '@/components/spinner';
import { useReportingOutputControl } from '@/context/reporting-output-control.context';
import { useSessions } from '@/hooks/query-hooks/use-sessions';
import {
  ToggledColumns,
  ChoiceSideDrawerContent,
} from '@/types/reporting-output-control';
import { exportableKeysApplicationsLookup } from '@/utils/query-builder-utils';
import { forEach, isArray, isObject, map } from 'lodash-es';

type ChoiceDetailsProps = {
  choice: ChoiceSideDrawerContent;
};

function extractChoicePaths(obj: object): {
  path: string;
  value: number | string;
}[] {
  const flatObject: Record<string, number | string> = {};

  function flatten(obj: any, prefix = '') {
    forEach(obj, (value, key) => {
      if (key === 'id') {
        return;
      }
      const path = prefix ? `${prefix}.${key}` : key;
      if (isObject(value) && !isArray(value)) {
        flatten(value, path);
      } else {
        flatObject[path] = value;
      }
    });
  }

  flatten(obj);

  return map(flatObject, (value, path) => ({ path, value }));
}

function ChoiceDetails(props: ChoiceDetailsProps) {
  const { choice } = props;

  const { toggledColumns } = useReportingOutputControl();

  const {
    isLoading: isSessionDataLoading,
    data: sessions,
    isError,
  } = useSessions(choice.location.id.toString(), choice.schoolYearId);

  if (isSessionDataLoading) {
    return <Spinner className="m-auto" />;
  }

  if (!sessions || isError) {
    return (
      <Alert type="error" text={<p>No sessions found!</p>} className="mt-5" />
    );
  }

  return (
    <div className="space-y-4">
      {extractChoicePaths(choice).map(({ path, value }) => {
        if (!toggledColumns[('choices.' + path) as keyof ToggledColumns]) {
          return;
        }
        const realValue =
          sessions.find(session => session.id === value)?.name || value;
        return (
          <Detail
            label={exportableKeysApplicationsLookup['choices.' + path]}
            key={path}
            content={realValue ? realValue : '--'}
          />
        );
      })}
    </div>
  );
}

export { ChoiceDetails };
