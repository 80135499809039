import { PageTitle } from '@/components/page-title';
import { ReportTemplatesTable } from '@/components/reporting/report-templates-table';
import { staffRouterPath } from '@/config/route-paths.config';
import { Link } from 'react-router-dom';
import { ReportsTable } from '@/components/reporting/reports-table';

function Reporting() {
  return (
    <div>
      <PageTitle title="Reporting" variant="gray">
        <Link
          to={staffRouterPath.NEW_QUERY_BUILDER}
          className="btn btn-primary"
        >
          Launch Query Builder
        </Link>
      </PageTitle>
      <div className="flex flex-col gap-6 pt-11">
        <ReportsTable />
        <ReportTemplatesTable />
      </div>
    </div>
  );
}

export { Reporting };
