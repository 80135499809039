import { productKey } from '@/config/query-keys';
import { productService } from '@/services/product.service';
import { HttpError } from '@/types/error';
import { CreateProductDto } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCreateProductMutation(
  mutationOptions?: UseMutationOptions<
    { created: number },
    HttpError,
    CreateProductDto
  >
) {
  return useMutation({
    mutationKey: productKey.create,
    mutationFn: productService.createProduct,
    ...mutationOptions,
  });
}

export { useCreateProductMutation };
