import {
  ChoiceReducerDispatch,
  ChoiceState,
  useChoiceFormReducer,
} from '@/reducers/choice.reducer';
import { Choice } from '@admissions-support/types';
import { createContext, useContext } from 'react';

type ChoicesContextState = {
  state: ChoiceState;
  dispatch: ChoiceReducerDispatch;
};

const ChoicesContext = createContext<ChoicesContextState | undefined>(
  undefined
);

function ChoicesProvider({
  children,
  schoolYearId,
  choice,
}: {
  children: React.ReactNode;
  schoolYearId: string;
  choice?: Choice;
}) {
  const [state, dispatch] = useChoiceFormReducer(schoolYearId, choice);

  return (
    <ChoicesContext.Provider value={{ state, dispatch }}>
      {children}
    </ChoicesContext.Provider>
  );
}

const useChoicesContext = () => {
  const context = useContext(ChoicesContext);
  if (!context) {
    throw new Error('useChoicesContext must be used within a ChoicesProvider');
  }
  return context;
};

export { ChoicesProvider, useChoicesContext };
