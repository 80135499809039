import { Balance } from '@/components/extra-hours-bookings/balance';
import { CopyBookingId } from '@/components/extra-hours-bookings/copy-booking-id';
import { ParentPayment } from '@/components/extra-hours-bookings/parent-payment';
import { SuccessfulBookingModal } from '@/components/extra-hours-bookings/successful-booking-modal';
import { ChildExtraHoursTable } from '@/components/parent/purchase-extra-hours/child-extra-hours-table';
import { useParams, useSearchParams } from 'react-router-dom';

function ChildPurchaseExtraHours() {
  const [searchParams, setSearchParams] = useSearchParams();
  const booked = searchParams.get('booked');
  const isBookingSuccessModalOpen = Boolean(booked);
  const { id = '' } = useParams();

  const onSuccessModalClose = () => {
    setSearchParams({}, { replace: true });
  };

  return (
    <div className="space-y-4">
      <div className="grid gap-4 sm:grid-cols-3">
        <ParentPayment childId={id} />
        <Balance childId={id} />
        <CopyBookingId childId={id} />
      </div>
      <ChildExtraHoursTable />
      <SuccessfulBookingModal
        isOpen={isBookingSuccessModalOpen}
        onClose={onSuccessModalClose}
        nbOfBookedExtraHours={Number(booked)}
      />
    </div>
  );
}

export { ChildPurchaseExtraHours };
