import { Box } from '@/components/box';
import { LoadingScreen } from '@/components/loading-screen';
import { AddTrusteeModal } from '@/components/parent/add-trustee-modal';
import { TrusteeListTable } from '@/components/parent/trustee-list-table';
import { parentRouterPath } from '@/config/route-paths.config';
import { useParentPermission } from '@/hooks/query-hooks/use-parent-permission';
import { useTrustees } from '@/hooks/query-hooks/use-trustee';
import { useModal } from '@/hooks/use-modal';
import { hasManageTrusteesPermission } from '@/utils/parent-utils';
import { Navigate, useParams } from 'react-router-dom';

export function TrusteeDashboard() {
  const params = useParams<{ id: string }>();
  const {
    data: parentPermission,
    error: parentPermissionError,
    isSuccess: isParentPermissionSuccess,
    isLoading: isParentPermissionLoading,
  } = useParentPermission(params.id || '', {
    enabled: Boolean(params.id),
  });

  const { data, isLoading } = useTrustees(params.id || '');
  const { openModal, isOpen, closeModal } = useModal();

  if (isParentPermissionLoading) {
    return <LoadingScreen />;
  }

  if (
    !isParentPermissionSuccess ||
    parentPermissionError ||
    !hasManageTrusteesPermission(parentPermission)
  ) {
    return <Navigate to={parentRouterPath.ROOT} />;
  }

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-4">
        <Box>
          <h5 className="mb-4 text-lg font-semibold">What is a Trustee?</h5>
          <p className="text-md">
            Adding a trustee will allow another person to access your child’s
            account. Where they will be able to support with application
            management, purchase extra hours. Trustees can be removed at any
            time, and can only access the children you have added them to.
          </p>
        </Box>
      </div>
      <div className="col-span-12 md:col-span-8">
        <Box>
          <div className="flex items-center justify-between">
            <h4 className="text-lg font-semibold">Trustees</h4>
            <button className="btn btn-secondary btn-sm" onClick={openModal}>
              Add Trustee
            </button>
          </div>
          <AddTrusteeModal
            isOpen={isOpen}
            onClose={closeModal}
            childId={params.id || ''}
          />
          <TrusteeListTable
            data={data}
            isLoading={isLoading}
            childId={params.id || ''}
          />
        </Box>
      </div>
    </div>
  );
}
