import { Radio } from '@/components/form/common/radio';
import { TextInput } from '@/components/form/common/text-input';
import { Toggle } from '@/components/form/common/toggle';
import { Plus } from '@untitled-ui/icons-react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ApplicationOverviewFormData } from '../application-template-overview.type';

function CriteriaItem({
  index,
  onDelete,
}: {
  index: number;
  onDelete: () => void;
}) {
  const form = useFormContext<ApplicationOverviewFormData>();

  return (
    <div className="light-gray-container p-3">
      <div className="mb-4 flex items-center justify-between">
        <p className="text-md font-semibold">Criteria</p>

        <button
          type="button"
          className="btn btn-error py-2"
          onClick={onDelete}
          disabled={form.formState.disabled}
        >
          Remove
        </button>
      </div>
      <TextInput
        name={`criteria[${index}].title`}
        label="Statement"
        placeholder="Do you receive child benefit?"
      />
    </div>
  );
}

function CriteriaForm() {
  const form = useFormContext<ApplicationOverviewFormData>();
  const {
    fields: criteria,
    append: addCriteria,
    remove: removeCriteria,
    replace: overrideCriteria,
  } = useFieldArray({
    control: form.control,
    name: 'criteria',
  });

  const handleToggle = (value: boolean) => {
    if (value) {
      overrideCriteria({ title: '' });
    }

    form.setValue('enableAdditionalCriteria', value);
  };

  const isCriteriaEnabled = form.watch('enableAdditionalCriteria');
  return (
    <div className="flex h-full flex-1 flex-col">
      <div className="mt-6 bg-white pt-6">
        <div className="two-col-form pt-0">
          <div>
            <h2 className="text-md font-medium leading-7 text-gray-900">
              Additional Criteria
            </h2>
            <p className="text-md leading-6 text-gray-600">
              Define if there are additional criteria to become eligible beyond
              child’s DOB (Ratio). All Questions are Yes/No only.
            </p>
          </div>
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-12">
              <div className="mb-6">
                <Toggle
                  label="Enable Additional Criteria"
                  onChange={handleToggle}
                  value={isCriteriaEnabled}
                />
              </div>
              {isCriteriaEnabled && (
                <div className="space-y-6">
                  <Radio
                    name="criteriaRequirement"
                    label="Requirement"
                    options={[
                      { label: 'Any Statement True', value: 'any' },
                      { label: 'All Statements are True', value: 'all' },
                    ]}
                  />
                  {criteria.map((_crtr, index) => (
                    <CriteriaItem
                      key={index}
                      index={index}
                      onDelete={() => removeCriteria(index)}
                    />
                  ))}

                  <button
                    type="button"
                    disabled={form.formState.disabled}
                    className="btn btn-secondary flex items-center"
                    onClick={() => addCriteria({ title: '' })}
                  >
                    <Plus
                      className="h-5 w-5"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    />
                    Add Criteria
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { CriteriaForm };
