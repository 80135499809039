import { SessionType } from '@admissions-support/types';
import { isFunction } from 'lodash-es';

type ResourceGroupWrapperProps = {
  onClick?: () => void;
  sessionTypes: SessionType[];
  children: React.ReactNode;
};

function ResourceGroupWrapper(props: ResourceGroupWrapperProps) {
  const { onClick, sessionTypes, children } = props;
  return (
    <div className="white-container group relative bg-white">
      <div className="flex flex-row items-center">
        <p className="mb-4 mt-1.5 text-lg font-medium">Resource Group</p>
        {isFunction(onClick) ? (
          <button
            type="button"
            className="btn btn-secondary right-4 top-4 ml-auto"
            onClick={onClick}
          >
            Edit
          </button>
        ) : null}
      </div>

      <div className="mb-5">
        <p className="text-md mt-6 font-semibold">Session Type(s)</p>
        <p className="text-md text-gray-500">
          {sessionTypes.map(sessionType => sessionType.name).join(', ')}
        </p>
      </div>
      <div className="space-y-5">{children}</div>
    </div>
  );
}

export { ResourceGroupWrapper };
