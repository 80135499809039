import { bookingKey } from '@/config/query-keys';
import { bookingService } from '@/services/booking.service';
import { HttpError } from '@/types/error';
import {
  LocationBookingsResponse,
  QueryLocationBookings,
} from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useBookingsByLocationId(
  locationId: string,
  query: QueryLocationBookings,
  queryOptions?: UseQueryOptions<LocationBookingsResponse, HttpError>
) {
  return useQuery({
    queryKey: bookingKey.byLocation(
      locationId,
      query.schoolYearId,
      query.from,
      query.to
    ),
    queryFn: () => bookingService.getBookingsByLocationId(locationId, query),
    ...queryOptions,
  });
}

export { useBookingsByLocationId };
