import {
  ExtraHoursBookingsControl,
  ExtraHoursBookingsFilter,
} from '@/types/extra-hours-bookings-control';
import { DeepPartial } from '@/types/util';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { merge } from 'lodash-es';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type ExtraHoursBookingsControlContextState = ExtraHoursBookingsControl & {
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  setSorting: Dispatch<SetStateAction<SortingState>>;
  setFilter: Dispatch<SetStateAction<ExtraHoursBookingsFilter>>;
};

function transformLocalStorageFilter(
  storedFilter: string
): ExtraHoursBookingsControl {
  const parsedControlObject = JSON.parse(
    storedFilter
  ) as ExtraHoursBookingsControl;

  return {
    ...parsedControlObject,
  };
}

const defaultExtraHoursBookingsControl: ExtraHoursBookingsControl = {
  filter: {
    search: '',
    status: '',
  },
  pagination: {
    pageIndex: 0,
    pageSize: 10,
  },
  sorting: [
    {
      id: 'childName',
      desc: false,
    },
  ],
};

const ExtraHoursBookingsControlContext = createContext<
  ExtraHoursBookingsControlContextState | undefined
>(undefined);

function ExtraHoursBookingsControlProvider({
  children,
  defaultControl,
}: {
  children: React.ReactNode | React.ReactNode[];
  defaultControl?: DeepPartial<ExtraHoursBookingsControl>;
}) {
  const localStorageKey = 'admit_extra_hours_bookings_control';
  const localStorageItem = localStorage.getItem(localStorageKey);

  let persistedExtraHoursBookingsControl: ExtraHoursBookingsControl;
  try {
    const programaticDefaultValue = localStorageItem
      ? transformLocalStorageFilter(localStorageItem)
      : defaultExtraHoursBookingsControl;

    persistedExtraHoursBookingsControl = merge(
      {},
      defaultExtraHoursBookingsControl,
      programaticDefaultValue,
      defaultControl
    );
  } catch (error) {
    console.error('Failed to parse localStorageItem:', error);
    persistedExtraHoursBookingsControl = merge(
      {},
      defaultExtraHoursBookingsControl,
      defaultControl
    );
  }

  const [pagination, setPagination] = useState<PaginationState>(
    persistedExtraHoursBookingsControl.pagination
  );

  const [sorting, setSorting] = useState<SortingState>(
    persistedExtraHoursBookingsControl.sorting
  );

  const [filter, setFilter] = useState<ExtraHoursBookingsFilter>(
    persistedExtraHoursBookingsControl.filter
  );

  const handleSetFilter = (state: SetStateAction<ExtraHoursBookingsFilter>) => {
    // navigate to the first page if filter changes
    setPagination(prev => ({ ...prev, pageIndex: 0 }));
    setFilter(state);
  };

  const updatedExtraHoursBookingsControl: ExtraHoursBookingsControl = useMemo(
    () => ({
      filter,
      pagination,
      sorting,
    }),
    [filter, pagination, sorting]
  );

  useEffect(() => {
    try {
      const serializedData = JSON.stringify(updatedExtraHoursBookingsControl);
      localStorage.setItem(localStorageKey, serializedData);
    } catch (error) {
      console.error(
        'Failed to serialize extra hours bookings control state:',
        error
      );
    }
  }, [updatedExtraHoursBookingsControl, localStorageKey]);

  return (
    <ExtraHoursBookingsControlContext.Provider
      value={{
        ...updatedExtraHoursBookingsControl,
        setFilter: handleSetFilter,
        setPagination,
        setSorting,
      }}
    >
      {children}
    </ExtraHoursBookingsControlContext.Provider>
  );
}

const useExtraHoursBookingsControl = () => {
  const context = useContext(ExtraHoursBookingsControlContext);

  if (!context) {
    throw new Error(
      'useExtraHoursBookingsControl must be used within a ExtraHoursBookingsControlProvider'
    );
  }
  return context;
};

export {
  ExtraHoursBookingsControlProvider,
  defaultExtraHoursBookingsControl,
  useExtraHoursBookingsControl,
};
