import { Detail } from '@/components/application/drawer/detail';
import { useReportingOutputControl } from '@/context/reporting-output-control.context';
import { formatApplicationAddress } from '@/utils/application-utils';
import { ChildCarer, SeemisAddress } from '@admissions-support/types';
import { clone } from 'lodash-es';

type CarerDetailsProps = {
  carer: ChildCarer;
};

function CarerDetails(props: CarerDetailsProps) {
  const { carer } = props;

  const { toggledColumns } = useReportingOutputControl();

  const address = clone(carer.address) || ({} as SeemisAddress);

  if (!toggledColumns['carers.address.lineOne']) {
    address.lineOne = null;
  }
  if (!toggledColumns['carers.address.lineTwo']) {
    address.lineTwo = null;
  }
  if (!toggledColumns['carers.address.lineThree']) {
    address.lineThree = null;
  }
  if (!toggledColumns['carers.address.lineFour']) {
    address.lineFour = null;
  }
  if (!toggledColumns['carers.address.postcode']) {
    address.postcode = null;
  }

  const addressString = formatApplicationAddress(address);

  return (
    <div className="space-y-4">
      {toggledColumns['carers.firstName'] ? (
        <Detail label="First Name" content={carer.firstName || '--'} />
      ) : null}
      {toggledColumns['carers.lastName'] ? (
        <Detail label="Last Name" content={carer.lastName || '--'} />
      ) : null}
      {toggledColumns['carers.address.lineOne'] &&
      toggledColumns['carers.address.lineTwo'] &&
      toggledColumns['carers.address.lineThree'] &&
      toggledColumns['carers.address.lineFour'] &&
      toggledColumns['address.postcode'] ? (
        <Detail
          label="Address"
          content={addressString ? addressString : '--'}
        />
      ) : null}
      {toggledColumns['carers.relationship'] ? (
        <Detail
          label="Relationship to Child"
          content={carer.relationship || '--'}
        />
      ) : null}
      {toggledColumns['carers.mobile'] ? (
        <Detail
          label="Phone Number"
          content={
            <a href={`tel:${carer.mobile}`} target="_blank" rel="noreferrer">
              {carer.mobile}
            </a>
          }
        />
      ) : null}
      {toggledColumns['carers.email'] ? (
        <Detail
          label="Email Address"
          content={
            <a href={`mailto:${carer.email}`} target="_blank" rel="noreferrer">
              {carer.email}
            </a>
          }
        />
      ) : null}
    </div>
  );
}

export { CarerDetails };
