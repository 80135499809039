import { get } from 'lodash-es';
import { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

interface UnitValueInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  unitOptions: { label: string; value: string }[];
  label?: string;
}

/**
 *
 * UnitValueInput should be used with a nested input value with unit and value property names:
 * inputName: {
 *  unit: string,
 *  value: any
 * }
 */
function UnitValueInput({
  label,
  name,
  className,
  unitOptions,
  ...inputProps
}: UnitValueInputProps) {
  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext();

  const inputName = `${name}.value`;
  const selectName = `${name}.unit`;

  const error = get(errors, inputName);
  const hasError = Boolean(error);

  return (
    <div className={className}>
      {label ? (
        <label htmlFor={inputName} className="label mb-2">
          {label}
        </label>
      ) : null}
      <div className="relative">
        <input
          id={inputName}
          className={twMerge(
            'input pr-20',
            hasError ? 'ring-red-500 focus:ring-red-300' : null
          )}
          aria-invalid={hasError ? 'true' : 'false'}
          disabled={isSubmitting}
          {...inputProps}
          {...register(inputName)}
        />
        <div className="absolute inset-y-0 right-0 flex items-center">
          <label htmlFor={selectName} className="sr-only">
            Unit
          </label>
          <select
            id={selectName}
            className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-right text-gray-500 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm"
            {...register(selectName)}
          >
            {unitOptions.map(unit => (
              <option value={unit.value} key={unit.value}>
                {unit.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      {hasError ? (
        <p className="mt-1.5 text-sm text-red-600">
          {error?.message?.toString()}
        </p>
      ) : null}
    </div>
  );
}

export { UnitValueInput };
