import * as React from "react";
const SvgLogo = (props) => /* @__PURE__ */ React.createElement("svg", { width: 126, height: 26, viewBox: "0 0 126 26", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("mask", { id: "mask0_1019_33137", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 8, y: 1, width: 19, height: 25 }, /* @__PURE__ */ React.createElement("path", { d: "M26.9983 25.8317H18.2372L8.53612 6.56289L11.4843 1.49988H14.6157L26.9983 25.8317Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask0_1019_33137)" }, /* @__PURE__ */ React.createElement("mask", { id: "mask1_1019_33137", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: -94, y: -77, width: 321, height: 180 }, /* @__PURE__ */ React.createElement("path", { d: "M226.319 -76.9951H-93.6607V102.993H226.319V-76.9951Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask1_1019_33137)" }, /* @__PURE__ */ React.createElement("path", { d: "M27.1649 1.33325H8.36946V25.9983H27.1649V1.33325Z", fill: "#023C2C" }))), /* @__PURE__ */ React.createElement("mask", { id: "mask2_1019_33137", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 0, y: 12, width: 17, height: 14 }, /* @__PURE__ */ React.createElement("path", { d: "M16.2256 12.6108V12.6142L9.16442 24.7267L8.54779 25.8317H0L1.97821 22.5185L7.10789 13.7158L7.58786 12.6108L16.224 12.6125L16.2256 12.6108Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask2_1019_33137)" }, /* @__PURE__ */ React.createElement("mask", { id: "mask3_1019_33137", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: -94, y: -77, width: 321, height: 180 }, /* @__PURE__ */ React.createElement("path", { d: "M226.319 -76.9951H-93.6607V102.993H226.319V-76.9951Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask3_1019_33137)" }, /* @__PURE__ */ React.createElement("path", { d: "M16.3923 12.4442H-0.166656V25.9984H16.3923V12.4442Z", fill: "#106951" }))), /* @__PURE__ */ React.createElement("mask", { id: "mask4_1019_33137", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 34, y: 1, width: 25, height: 25 }, /* @__PURE__ */ React.createElement("path", { d: "M41.7957 15.5507L45.3555 6.08961L49.0235 15.5507H41.7973H41.7957ZM52.3667 24.2535H49.4552V25.6984H58.9463V24.2535H56.5381L47.657 1.50323H43.5939L44.5638 4.06641L37.1926 23.0619C36.8327 24.0001 36.401 24.2535 34.4961 24.2535V25.6984H41.939V24.2535H40.2858C38.8842 24.2535 38.7392 23.6402 39.0275 22.8819L41.2574 16.9956H49.5619L52.3667 24.2535Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask4_1019_33137)" }, /* @__PURE__ */ React.createElement("mask", { id: "mask5_1019_33137", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: -94, y: -77, width: 321, height: 180 }, /* @__PURE__ */ React.createElement("path", { d: "M226.319 -76.9951H-93.6607V102.993H226.319V-76.9951Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask5_1019_33137)" }, /* @__PURE__ */ React.createElement("path", { d: "M59.1129 1.33655H34.3312V25.865H59.1129V1.33655Z", fill: "#023C2C" }))), /* @__PURE__ */ React.createElement("mask", { id: "mask6_1019_33137", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 58, y: 0, width: 18, height: 26 }, /* @__PURE__ */ React.createElement("path", { d: "M69.9089 21.6653C69.1523 22.7486 67.9274 23.4352 66.5941 23.4352C64.0009 23.4352 62.3427 20.7987 62.3427 16.9723C62.3427 12.0976 64.5042 9.89437 66.6658 9.89437C68.539 9.89437 69.9072 11.2309 69.9072 13.5775V21.6653H69.9089ZM73.7636 0H73.547L67.784 1.98654V2.81649C69.5139 3.17813 69.9089 3.82809 69.9089 5.12801V9.74938C69.1523 9.09942 67.9273 8.52112 66.4874 8.52112C62.236 8.52112 58.3463 12.3126 58.3463 18.2705C58.3463 22.4586 60.8678 25.9983 64.5426 25.9983C67.2441 25.9983 69.0089 24.0485 69.9106 22.5686C69.9106 23.6152 70.2355 25.1317 70.7038 25.9983H70.8838L75.7469 24.6268V23.7968C74.1253 23.5085 73.7653 22.8219 73.7653 21.8103V0H73.7636Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask6_1019_33137)" }, /* @__PURE__ */ React.createElement("mask", { id: "mask7_1019_33137", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: -94, y: -77, width: 321, height: 180 }, /* @__PURE__ */ React.createElement("path", { d: "M226.319 -76.9951H-93.6607V102.993H226.319V-76.9951Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask7_1019_33137)" }, /* @__PURE__ */ React.createElement("path", { d: "M75.9118 -0.166626H58.1796V26.165H75.9118V-0.166626Z", fill: "#023C2C" }))), /* @__PURE__ */ React.createElement("mask", { id: "mask8_1019_33137", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 76, y: 8, width: 29, height: 18 }, /* @__PURE__ */ React.createElement("path", { d: "M92.6558 12.1442C92.1158 9.8677 90.4976 8.56612 88.1594 8.56612C85.6062 8.56612 83.6997 10.481 82.7998 12.0359C82.7998 10.951 82.5481 9.4694 82.0798 8.56612H81.8998L76.9368 10.2643V11.096C78.5917 11.4576 78.915 12.1076 78.915 13.1192V24.3235H76.7935V25.6967H84.743V24.3235H82.8014V12.9025C83.593 11.8176 84.6713 11.1676 86.1462 11.1676C88.0878 11.1676 88.951 12.5775 88.951 14.3841V24.3235H86.9728V25.6967H94.814V24.3235H92.8358V12.9025C93.6624 11.8176 94.7057 11.1676 96.1806 11.1676C98.1221 11.1676 98.9854 12.5775 98.9854 14.3841V24.3235H97.0439V25.6967H104.992V24.3235H102.905V14.1674C102.905 10.626 101.215 8.56445 98.1938 8.56445C95.6756 8.56445 93.5907 10.5877 92.6541 12.1426L92.6558 12.1442Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask8_1019_33137)" }, /* @__PURE__ */ React.createElement("mask", { id: "mask9_1019_33137", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: -94, y: -77, width: 321, height: 180 }, /* @__PURE__ */ React.createElement("path", { d: "M226.319 -76.9951H-93.6607V102.993H226.319V-76.9951Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask9_1019_33137)" }, /* @__PURE__ */ React.createElement("path", { d: "M105.16 8.39948H76.6285V25.865H105.16V8.39948Z", fill: "#023C2C" }))), /* @__PURE__ */ React.createElement("mask", { id: "mask10_1019_33137", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 105, y: 0, width: 9, height: 26 }, /* @__PURE__ */ React.createElement("path", { d: "M111.755 24.3234V8.56442H111.538L105.887 10.2626V11.0943C107.543 11.4192 107.866 12.1059 107.866 13.3358V24.3234H105.743V25.6967H113.843V24.3234H111.755ZM107.363 3.35808C107.363 4.69466 108.48 5.81626 109.811 5.81626C111.143 5.81626 112.223 4.69633 112.223 3.35808C112.223 2.01983 111.143 0.899902 109.811 0.899902C108.48 0.899902 107.363 2.01983 107.363 3.35808Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask10_1019_33137)" }, /* @__PURE__ */ React.createElement("mask", { id: "mask11_1019_33137", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: -94, y: -77, width: 321, height: 180 }, /* @__PURE__ */ React.createElement("path", { d: "M226.319 -76.9951H-93.6607V102.993H226.319V-76.9951Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask11_1019_33137)" }, /* @__PURE__ */ React.createElement("path", { d: "M114.009 0.734924H105.577V25.865H114.009V0.734924Z", fill: "#023C2C" }))), /* @__PURE__ */ React.createElement("mask", { id: "mask12_1019_33137", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: 114, y: 3, width: 12, height: 23 }, /* @__PURE__ */ React.createElement("path", { d: "M116.636 4.79468V8.93442H114.143V10.3027H116.636V22.0753C116.636 24.6668 118.622 26 120.826 26C123.174 26 124.872 24.8484 125.992 23.2635L125.27 22.4352C124.475 23.2985 123.644 23.8035 122.634 23.8035C121.514 23.8035 120.539 23.1552 120.539 21.8236V10.3043H124.765V8.93609H120.539V3.60809H120.286L116.638 4.79635L116.636 4.79468Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask12_1019_33137)" }, /* @__PURE__ */ React.createElement("mask", { id: "mask13_1019_33137", style: {
  maskType: "luminance"
}, maskUnits: "userSpaceOnUse", x: -94, y: -77, width: 321, height: 180 }, /* @__PURE__ */ React.createElement("path", { d: "M226.319 -76.9951H-93.6607V102.993H226.319V-76.9951Z", fill: "white" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask13_1019_33137)" }, /* @__PURE__ */ React.createElement("path", { d: "M126.159 3.43976H113.976V26.165H126.159V3.43976Z", fill: "#023C2C" }))));
export default SvgLogo;
