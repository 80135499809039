import { TextInput } from '@/components/form/common/text-input';
import { Plus } from '@untitled-ui/icons-react';
import { useFieldArray, useFormContext } from 'react-hook-form';

type AdminContactsSectionProps = {
  isDisabled: boolean;
};

function AdminContactsSection(props: AdminContactsSectionProps) {
  const { isDisabled } = props;

  const form = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'adminContacts',
  });

  const handleAddAdminContact = () => {
    append([
      {
        email: '',
        firstName: '',
        lastName: '',
        position: '',
        phone: '',
      },
    ]);
  };

  return (
    <div className="two-col-form gap-6 py-6">
      <div>
        <p className="font-semibold text-gray-700">Admin Contact</p>{' '}
        <p className="text-gray-600">
          Specify organisation name and local authority
        </p>
      </div>
      <div className="col-span-2 space-y-6">
        {fields.map((adminContact, idx) => {
          return (
            <div
              className="light-gray-container space-y-6"
              key={adminContact.id}
            >
              <div className="flex justify-between">
                <p className="font-semibold">Admin Contact</p>
                <button
                  className="btn btn-secondary"
                  type="button"
                  disabled={isDisabled}
                  onClick={() => remove(idx)}
                >
                  Remove
                </button>
              </div>
              <div className="flex flex-col gap-6 sm:flex-row">
                <TextInput
                  name={`adminContacts.${idx}.firstName`}
                  label="First Name*"
                  placeholder="First name"
                  className="w-full"
                  disabled={isDisabled}
                />
                <TextInput
                  name={`adminContacts.${idx}.lastName`}
                  label="Last Name*"
                  placeholder="Last name"
                  className="w-full"
                  disabled={isDisabled}
                />
              </div>
              <TextInput
                name={`adminContacts.${idx}.email`}
                label="Email*"
                placeholder="admin@"
                disabled={isDisabled}
              />
              <TextInput
                name={`adminContacts.${idx}.position`}
                label="Position*"
                placeholder="Manager"
                disabled={isDisabled}
              />
              <TextInput
                name={`adminContacts.${idx}.phone`}
                label="Phone (Optional)"
                placeholder="+44"
                disabled={isDisabled}
              />
            </div>
          );
        })}
        <button
          type="button"
          className="btn btn-secondary flex items-center"
          onClick={handleAddAdminContact}
          disabled={isDisabled}
        >
          <Plus
            className="mr-1 inline h-5 w-5"
            viewBox="0 0 24 24"
            aria-hidden="true"
          />
          Add Admin Contact
        </button>
      </div>
    </div>
  );
}

export { AdminContactsSection };
