import { AlertCircle, Eye, EyeOff } from '@untitled-ui/icons-react';
import { get } from 'lodash-es';
import { InputHTMLAttributes, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  LeadingIcon?: React.ReactNode;
  helperText?: string;
  prefix?: string;
}

function TextInput({
  label,
  name,
  type,
  LeadingIcon,
  className,
  helperText,
  prefix,
  ...inputProps
}: TextInputProps) {
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext();
  const error = get(errors, name);
  const hasError = Boolean(error);

  const isPasswordField = type === 'password';
  const hasPrefix = Boolean(prefix);
  const hasLeadingIcon = !!LeadingIcon && !hasPrefix;

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={className}>
      {label ? (
        <label htmlFor={name} className="label mb-2">
          {label}
        </label>
      ) : null}
      <div className="relative flex">
        {hasLeadingIcon ? (
          <div
            className={twMerge(
              'pointer-events-none absolute inset-y-0 left-0 flex items-center border-r pl-3 pr-2',
              hasError ? 'border-red-500' : 'border-gray-300'
            )}
          >
            {LeadingIcon}
          </div>
        ) : null}
        {hasPrefix ? (
          <span
            className={twMerge(
              'text-md inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-600 shadow-sm',
              hasError ? 'border-red-500' : null
            )}
          >
            {prefix}
          </span>
        ) : null}
        <input
          id={name}
          type={showPassword ? 'text' : type}
          className={twMerge(
            'input',
            isPasswordField || hasError ? 'pr-10' : null,
            hasError ? 'ring-red-500 focus:ring-red-300' : null,
            hasLeadingIcon ? 'pl-12' : null,
            hasPrefix ? 'rounded-l-none' : null
          )}
          aria-invalid={hasError ? 'true' : 'false'}
          disabled={isSubmitting}
          {...inputProps}
          {...register(name)}
        />

        {isPasswordField ? (
          <button
            className="absolute inset-y-0 right-0 flex items-center pr-3"
            onClick={handleShowPassword}
            tabIndex={-1}
            type="button"
          >
            {showPassword ? (
              <EyeOff
                width={16}
                height={16}
                viewBox="0 0 24 24"
                className={twMerge(hasError ? 'text-red-500' : 'text-gray-500')}
                aria-hidden="true"
              />
            ) : (
              <Eye
                width={16}
                height={16}
                viewBox="0 0 24 24"
                className={twMerge(hasError ? 'text-red-500' : 'text-gray-500')}
                aria-hidden="true"
              />
            )}
          </button>
        ) : null}

        {!isPasswordField && hasError ? (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <AlertCircle className="text-red-500" aria-hidden="true" />
          </div>
        ) : null}
      </div>

      {helperText ? <p className="mt-1.5 text-sm">{helperText}</p> : null}

      {hasError ? (
        <p className="mt-1.5 text-sm text-red-600">
          {error?.message?.toString()}
        </p>
      ) : null}
    </div>
  );
}

function TextInputBase({
  label,
  name,
  type,
  LeadingIcon,
  className,
  helperText,
  prefix,
  error,
  ...inputProps
}: Omit<TextInputProps, 'name'> & { error?: string; name?: string }) {
  const [showPassword, setShowPassword] = useState(false);

  const hasError = Boolean(error);

  const isPasswordField = type === 'password';
  const hasPrefix = Boolean(prefix);
  const hasLeadingIcon = !!LeadingIcon && !hasPrefix;

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={className}>
      {label ? (
        <label htmlFor={name} className="label mb-2">
          {label}
        </label>
      ) : null}
      <div className="relative flex">
        {hasLeadingIcon ? (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            {LeadingIcon}
          </div>
        ) : null}
        {hasPrefix ? (
          <span
            className={twMerge(
              'text-md inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-600 shadow-sm',
              hasError ? 'border-red-500' : null
            )}
          >
            {prefix}
          </span>
        ) : null}
        <input
          id={name}
          type={showPassword ? 'text' : type}
          className={twMerge(
            'input',
            isPasswordField || hasError ? 'pr-10' : null,
            hasError ? 'ring-red-500 focus:ring-red-300' : null,
            hasLeadingIcon ? 'pl-10' : null,
            hasPrefix ? 'rounded-l-none' : null
          )}
          aria-invalid={hasError ? 'true' : 'false'}
          {...inputProps}
        />

        {isPasswordField ? (
          <button
            className="absolute inset-y-0 right-0 flex items-center pr-3"
            onClick={handleShowPassword}
            tabIndex={-1}
            type="button"
          >
            {showPassword ? (
              <EyeOff
                width={16}
                height={16}
                viewBox="0 0 24 24"
                className={twMerge(hasError ? 'text-red-500' : 'text-gray-500')}
                aria-hidden="true"
              />
            ) : (
              <Eye
                width={16}
                height={16}
                viewBox="0 0 24 24"
                className={twMerge(hasError ? 'text-red-500' : 'text-gray-500')}
                aria-hidden="true"
              />
            )}
          </button>
        ) : null}

        {!isPasswordField && hasError ? (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <AlertCircle className="text-red-500" aria-hidden="true" />
          </div>
        ) : null}
      </div>

      {helperText ? <p className="mt-1.5 text-sm">{helperText}</p> : null}

      {hasError ? (
        <p className="mt-1.5 text-sm text-red-600">{error?.toString()}</p>
      ) : null}
    </div>
  );
}
export { TextInput, TextInputBase };
