import { S3File, fileService } from '@/services/file.service';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useFileUploadMutation(
  mutationOptions?: UseMutationOptions<
    S3File,
    any,
    {
      fileName: string;
      file: File;
      path: string;
      handleProgress?: (progress: number) => void;
      bucket?: string;
    }
  >
) {
  return useMutation({
    mutationFn: fileService.uploadFile,
    ...mutationOptions,
  });
}

export { useFileUploadMutation };
