import { trusteeKey } from '@/config/query-keys';
import { trusteeService } from '@/services/trustee.service';
import { HttpError } from '@/types/error';
import { ChildPermission } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useParentPermission(
  id: string,
  queryOptions?: Partial<UseQueryOptions<ChildPermission, HttpError>>
) {
  return useQuery({
    queryKey: trusteeKey.permission(id),
    queryFn: () => trusteeService.getParentPermission(id),
    ...queryOptions,
  });
}

export { useParentPermission };
