import { Permission } from '@/types/auth';
import { IUserGroup } from '@/types/user-group';
import { ChangeEvent } from 'react';
import { CheckboxBase } from './form/common/checkbox';

type PermissionGroupItemProps = {
  data: IUserGroup;
  selectedPermissions: string[];
  onSelectAll: (selectAll: boolean, value: string[]) => any;
  onPermissionSelect: (event: ChangeEvent<HTMLInputElement>) => any;
  disabled?: boolean;
};

function PermissionGroupItem(props: PermissionGroupItemProps) {
  const {
    data,
    selectedPermissions,
    onSelectAll,
    onPermissionSelect,
    disabled,
  } = props;

  const permissionListOfPermissionGroup = data.permissions.map(
    permission => permission.value
  );
  const isSomeSelected = permissionListOfPermissionGroup.some(permission =>
    selectedPermissions.includes(permission)
  );
  const isAllSelected = permissionListOfPermissionGroup.every(permission =>
    selectedPermissions.includes(permission)
  );

  return (
    <fieldset disabled={disabled}>
      <div className="two-col-form">
        <div>
          <p className="text-md font-medium leading-7 text-gray-900">
            {data.name}
          </p>
          <CheckboxBase
            name={`select-all-${data.name}`}
            label="Select all"
            checked={isAllSelected}
            indeterminate={isSomeSelected}
            onChange={onSelectAll(
              !isAllSelected,
              permissionListOfPermissionGroup
            )}
          />
        </div>

        <div className="col-span-2 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5">
          {data.permissions.map(permission => {
            const activeFieldsToDisable = selectedPermissions.filter(element =>
              permission.disableIfSelected?.includes(element as Permission)
            );

            const hasActiveDisableField = activeFieldsToDisable.length > 0;

            return (
              <CheckboxBase
                key={permission.value}
                name={permission.value}
                label={permission.name}
                helperText={permission.description}
                onChange={onPermissionSelect}
                checked={selectedPermissions.includes(permission.value)}
                disabled={hasActiveDisableField}
              />
            );
          })}
        </div>
      </div>
    </fieldset>
  );
}

export { PermissionGroupItem };
