import { getFirstSessionId, getOperatingDays } from '@/utils/application-utils';
import { formatOperatingDays } from '@/utils/format-operating-days';
import {
  TableChoice,
  Choice,
  OperatingDaySession,
} from '@admissions-support/types';
import { Spinner } from '../spinner';
import { useSessions } from '@/hooks/query-hooks/use-sessions';

type ExtraSessionCellProps = {
  choice: Choice | TableChoice;
  schoolYearId: string;
};

function ExtraSessionCell(props: ExtraSessionCellProps) {
  const { choice, schoolYearId } = props;

  const daysAvailable = Object.keys(choice.additionalSessions);

  const {
    isLoading: isSessionDataLoading,
    data: sessions,
    isError,
  } = useSessions(choice.location.id.toString(), schoolYearId);

  if (isSessionDataLoading) {
    return <Spinner className="h-5" />;
  }

  if (!sessions || isError) {
    return <span>Invalid session data</span>;
  }

  const operatingDays = daysAvailable.filter(
    day => !!choice.additionalSessions[day as keyof OperatingDaySession]
  );

  const operatingDaysToDisplay = formatOperatingDays(
    getOperatingDays(operatingDays)
  );

  // you can have different sessions for each day. however there are common properties,
  // which must be the same for each selected session; session-type, model
  // we find the first non-null session on the attendance pattern to figure out those fields
  const firstSessionId = getFirstSessionId(choice as Choice, true);
  const session = sessions.find(
    session => session.id.toString() === firstSessionId
  );

  if (!operatingDaysToDisplay) {
    return <span title="No extra sessions">--</span>;
  }

  if (choice.splitPlacement) {
    const tooltipContent =
      choice.splitPlacement.additionalSessionLocation?.name ||
      'No additional session location';

    return (
      <div
        data-tooltip-id="split-placement-extra-session-tooltip"
        data-tooltip-content={tooltipContent}
        className="inline-block flex flex-col hover:cursor-pointer"
      >
        <p>{operatingDaysToDisplay}</p>
        <p>{session?.type.name}</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <p>{operatingDaysToDisplay}</p>
      <p>{session?.type.name}</p>
    </div>
  );
}

export { ExtraSessionCell };
