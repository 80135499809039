import { staffRouterPath } from '@/config/route-paths.config';
import { useLeaveModal } from '@/hooks/use-leave-modal';
import { HttpError, isBadRequest } from '@/types/error';
import { transformFormDataToDto } from '@/utils/application-utils';
import { getNestedKeys } from '@/utils/get-nested-keys';
import { Application, UpdateApplicationDto } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { carersFormSchema } from './carer.schema';
import { Carer, CarersFormData } from './carer.type';

type UseCarerFormProps = {
  onSubmit: (data: UpdateApplicationDto) => Promise<any>;
  initialData: Application;
};

const defaultCarer: Carer = {
  title: null,
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  address: {
    lineOne: '',
    lineTwo: null,
    lineThree: null,
    lineFour: null,
    postcode: '',
    uprn: null,
    eastings: null,
    northings: null,
  },
  isPreferredContact: null,
  relationship: null,
  canCollectChild: null,
  isEmergencyContact: null,
  occupation: null,
  occupationSchedule: null,
};

function transformApiDataToFormData(initialData: Application): CarersFormData {
  if (!Array.isArray(initialData.carers) || initialData.carers.length < 1) {
    return {
      carers: [{ ...defaultCarer }],
    };
  }

  return {
    carers: initialData.carers.map(carer => ({
      title: carer.title,
      firstName: carer.firstName || '',
      lastName: carer.lastName || '',
      email: carer.email || '',
      mobile: carer.mobile || '',
      relationship: carer.relationship,
      isPreferredContact: carer.isPreferredContact,

      address: carer.address
        ? {
            lineOne: carer.address.lineOne || '',
            lineTwo: carer.address.lineTwo || null,
            lineThree: carer.address.lineThree || null,
            lineFour: carer.address.lineFour || null,
            postcode: carer.address.postcode || '',
            uprn: carer.address.uprn || null,
            eastings: carer.address.eastings || null,
            northings: carer.address.northings || null,
          }
        : null,
      occupation: carer.occupation,
      occupationSchedule: carer.occupationSchedule,
      isEmergencyContact: carer.isEmergencyContact,
      canCollectChild: carer.canCollectChild,
    })),
  };
}

function useCarerForm(props: UseCarerFormProps) {
  const { initialData, onSubmit } = props;
  const isDraft = useRef(true);
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const defaultValues: CarersFormData = transformApiDataToFormData(initialData);
  const defaultResolver = yupResolver(carersFormSchema);

  const form = useForm({
    resolver: (data, context, options) => {
      if (isDraft.current) {
        // if its draft no validation required
        return { errors: {}, values: data };
      }
      return defaultResolver(data, context, options);
    },
    defaultValues,
  });

  useLeaveModal({
    show: form.formState.isDirty,
  });

  useEffect(() => {
    if (!shouldRedirect) {
      return;
    }
    navigate(
      generatePath(staffRouterPath.APPLICATION_HOUSEHOLD_DETAILS, {
        id: params.id || '',
      })
    );
  }, [shouldRedirect, navigate, params.id]);

  const { reset } = form;
  useEffect(() => {
    if (!form.formState.isSubmitSuccessful) {
      return;
    }
    reset(undefined, { keepValues: true });

    if (!isDraft.current) {
      setShouldRedirect(true);
    }
  }, [form.formState.isSubmitSuccessful, params.id, navigate, reset]);

  useEffect(() => {
    reset(transformApiDataToFormData(initialData));
  }, [initialData, reset]);

  const handleSubmit = async (data: UpdateApplicationDto) => {
    try {
      const transformedApplication = transformFormDataToDto(data, ['carers']);

      const applicationDto: UpdateApplicationDto = {
        carers: transformedApplication.carers,
      };

      await onSubmit(applicationDto);
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        const availableFields = getNestedKeys(form.getValues());

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }

      form.setError('root.server', {
        message: Array.isArray(httpError.message)
          ? httpError.message[0]
          : httpError.message,
      });
    }
  };

  const setDraft = (status: boolean) => {
    isDraft.current = status;
  };

  const saveAsDraft = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setDraft(true);
    await form.handleSubmit(handleSubmit)();
  };

  const handleSave = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setDraft(false);
    await form.handleSubmit(handleSubmit)();
  };

  return {
    form,
    handleSubmit: handleSave,
    saveAsDraft,
  };
}

export { defaultCarer, useCarerForm };
