import { NavigationItem } from '@/types/navigation';
import { Application } from '@admissions-support/types';
import { additionalChildDetailsSchema } from './application/additional-child-details/additional-child-details.schema';
import { carersFormSchema } from './application/carer/carer.schema';
import { householdApiSchema } from './application/houshold-details/household.schema';
import { renderItem } from './sidebar';

type ApplicationNavigationProps = {
  navigation: NavigationItem[];
};
function ApplicationNavigation(props: ApplicationNavigationProps) {
  const { navigation } = props;

  return (
    <nav className="flex flex-1 flex-col" aria-label="Sidebar">
      <ul className="-mx-2 space-y-1">
        {navigation.map(item => renderItem(item))}
      </ul>
    </nav>
  );
}

function isParentCarerDisabled(application?: Application): boolean {
  return !additionalChildDetailsSchema.isValidSync(application);
}

function isHouseholdDetailsDisabled(application?: Application): boolean {
  return (
    !carersFormSchema.isValidSync(application) ||
    isParentCarerDisabled(application)
  );
}

function isPlacementPreferencesDisabled(application?: Application): boolean {
  return (
    !householdApiSchema.isValidSync(application) ||
    isHouseholdDetailsDisabled(application)
  );
}

function isAdditionalDetailsDisabled(application?: Application): boolean {
  return (
    isPlacementPreferencesDisabled(application) ||
    (application?.choices.length || 0) < 1
  );
}

export {
  ApplicationNavigation,
  isAdditionalDetailsDisabled,
  isHouseholdDetailsDisabled,
  isParentCarerDisabled,
  isPlacementPreferencesDisabled,
};
