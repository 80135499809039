import { Tag } from '@/components/tag';
import { useCapacitySimulation } from '@/context/capacity-simulation.context';
import { useSchoolYear } from '@/context/school-year.context';
import { useSimulateScenarioMutation } from '@/hooks/use-simulate-scenario';
import { queryClient } from '@/libs/react-query';
import { formatDateToYYYYMMDD } from '@/utils/format-date';
import {
  CheckCircle,
  RefreshCcw01,
  RefreshCcw05,
} from '@untitled-ui/icons-react';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';
import { CapacityFormData } from './capacity-report';

type CapacityUpdateBarProps = {
  locationId: string;
};

function CapacityUpdateBar({ locationId }: CapacityUpdateBarProps) {
  const {
    simulatedCapacity,
    resetSimulatedCapacityForLocation,
    dataUpdatedAt,
  } = useCapacitySimulation();
  const [tableWidth, setTableWidth] = useState(0);
  const elementRef = useRef<HTMLDivElement | null>(null);

  const { schoolYear } = useSchoolYear();
  const { getValues } = useFormContext<CapacityFormData>();
  const hasSimulatedCapacity = simulatedCapacity.some(
    resource => resource.locationId === locationId
  );
  const {
    mutateAsync: simulateMatching,
    isPending: isPublishing,
    isError,
    error,
    reset: resetSimulateMutation,
  } = useSimulateScenarioMutation({
    retry: false,
  });

  useEffect(() => {
    const element = document.querySelector('main');
    const scrollThingy = document.querySelector('.overflow-x-auto');

    const handleScroll = () => {
      if (elementRef.current) {
        elementRef.current.style.left = `${scrollThingy?.scrollLeft}px`;
      }
    };

    if (scrollThingy) {
      handleScroll();
      scrollThingy.addEventListener('scroll', handleScroll);
    }

    if (!element) {
      return;
    }

    // eslint-disable-next-line compat/compat
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.contentRect) {
          setTableWidth(entry.contentRect.width);
        }
      }
    });

    resizeObserver.observe(element);

    return () => {
      if (scrollThingy) {
        scrollThingy.removeEventListener('scroll', handleScroll);
      }

      if (resizeObserver && element) {
        resizeObserver.unobserve(element);
      }
    };
  }, [hasSimulatedCapacity]);

  useEffect(() => {
    // Apply the same width to the target element
    if (elementRef.current) {
      elementRef.current.style.width = `${tableWidth - 48}px`;
    }
  }, [tableWidth, hasSimulatedCapacity]);

  useEffect(() => {
    if (!isError) {
      return;
    }

    toast.error(
      Array.isArray(error.message) ? error.message[0] : error.message
    );
  }, [error, isError]);

  const handlePublish = async () => {
    const { choiceIndex, ratioId, matchingDate } = getValues();

    await simulateMatching({
      schoolYearId: schoolYear.id,
      //@ts-ignore backend want date type for some reason
      date: matchingDate || formatDateToYYYYMMDD(new Date()),
      ratioId,
      choiceIndexes: [choiceIndex === 'all' ? 0 : parseInt(choiceIndex)],
      isPublishingChanges: true,
      locationData: simulatedCapacity,
      //@ts-ignore backend want date type for some reason
      simulatedAt: new Date(dataUpdatedAt).toISOString(),
    });

    toast.success('Capacity successfully updated!');

    // reset because we have already updated
    resetSimulatedCapacityForLocation(locationId);

    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['query-result', 'capacity'] }),
      queryClient.invalidateQueries({ queryKey: ['query-result', 'demand'] }),
    ]);
  };

  const isNewChangeAvailable = error?.message.includes(
    'your changes were blocked'
  );

  const handleRefreshData = async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: ['query-result', 'capacity'] }),
      queryClient.invalidateQueries({ queryKey: ['query-result', 'demand'] }),
    ]);
    resetSimulatedCapacityForLocation(locationId);
    resetSimulateMutation();
  };

  if (!hasSimulatedCapacity) {
    return null;
  }

  return (
    <div className="relative h-16 w-full pl-6">
      <div
        ref={elementRef}
        className={twMerge(
          'absolute right-0 flex items-center',
          isNewChangeAvailable ? 'justify-between' : 'justify-end'
        )}
      >
        {isNewChangeAvailable && (
          <div>
            <div className="flex rounded-md border border-gray-300 bg-white p-1">
              <Tag
                type="outline"
                className="mr-2 inline-flex w-auto flex-row items-center px-1.5 py-0.5 text-xs font-normal capitalize shadow-xs"
              >
                <span className="mr-1 inline-block h-1.5 w-1.5 rounded-full bg-yellow-500"></span>
                New data available
              </Tag>
              <button
                className="group flex items-center text-xs"
                type="button"
                onClick={handleRefreshData}
              >
                Reload data to continue
                <RefreshCcw05
                  className="ml-1 h-4 w-4 transform transition-transform duration-300 group-hover:rotate-180 group-hover:scale-100"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        )}

        <div className="flex gap-2">
          <button
            type="button"
            className="btn btn-icon shadow-none"
            onClick={() => resetSimulatedCapacityForLocation(locationId)}
            disabled={isPublishing || isNewChangeAvailable}
          >
            <RefreshCcw01 className="leading-icon h-5 w-5" aria-hidden="true" />
            Reset Changes
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-icon"
            onClick={handlePublish}
            disabled={isPublishing || isNewChangeAvailable}
          >
            <CheckCircle className="leading-icon h-5 w-5" aria-hidden="true" />
            Publish Changes
          </button>
        </div>
      </div>
    </div>
  );
}
export { CapacityUpdateBar };
