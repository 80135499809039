import { DeleteZone } from '@/components/delete-zone';
import { SessionTypeForm } from '@/components/form/session-type.form';
import { PageTitle } from '@/components/page-title';
import { Spinner } from '@/components/spinner';
import { sessionTypeKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useCreateSessionTypeMutation } from '@/hooks/create-hooks/use-create-session-type-mutation';
import { useDeleteSessionTypeMutation } from '@/hooks/delete-hooks/use-delete-session-type-mutation';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useSessionType } from '@/hooks/query-hooks/use-session-type';
import { useSessionTypes } from '@/hooks/query-hooks/use-session-types';
import { useUpdateSessionTypeMutation } from '@/hooks/update-hooks/use-update-session-type-mutation';
import { queryClient } from '@/libs/react-query';
import { Permission } from '@/types/auth';
import { getErrorMessage } from '@/utils/get-error-message';
import {
  CreateSessionTypeDto,
  UpdateSessionTypeDto,
} from '@admissions-support/types';
import { useEffect } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

async function invalidateSessionTypesQuery() {
  await queryClient.invalidateQueries({
    queryKey: sessionTypeKey.list,
    refetchType: 'active',
  });
}

function UpsertSessionType() {
  const navigate = useNavigate();
  const {
    mutateAsync: create,
    isPending: isCreating,
    isSuccess: isSessionTypeCreated,
  } = useCreateSessionTypeMutation({
    onSuccess: invalidateSessionTypesQuery,
  });
  const { mutateAsync: update, isPending: isUpdating } =
    useUpdateSessionTypeMutation({
      onSuccess: invalidateSessionTypesQuery,
    });
  const params = useParams<{ id?: string }>();
  const isCreateView = Boolean(useMatch(staffRouterPath.NEW_SESSION_TYPE));

  const {
    isLoading: isWeekModelLoading,
    data: sessionType,
    error,
  } = useSessionType(params.id);

  useEffect(() => {
    if (error) {
      navigate(staffRouterPath.SESSION_TYPES);
    }
  }, [error, navigate]);

  const { data: sessionTypes, isLoading: isSessionTypesLoading } =
    useSessionTypes({
      select: data =>
        data
          .filter(sessionType => sessionType.additionalSessionTypes.length < 1)
          .filter(sessionType => {
            // dont let select the current session type as additional session type
            if (params.id) {
              return sessionType.id !== params.id;
            }

            return true;
          }),
    });

  const {
    mutateAsync: deleteSessionType,
    isPending: isDeleting,
    error: deleteError,
  } = useDeleteSessionTypeMutation({
    onSuccess: async () => {
      queryClient.removeQueries({
        queryKey: sessionTypeKey.single(params.id || ''),
      });
      await invalidateSessionTypesQuery();
      navigate(staffRouterPath.SESSION_TYPES);
    },
  });
  const { hasPermission } = usePermissions();

  useEffect(() => {
    if (!isSessionTypeCreated) {
      return;
    }

    navigate(staffRouterPath.SESSION_TYPES);
  }, [isSessionTypeCreated, navigate]);

  const isMutating =
    isCreating ||
    isUpdating ||
    !hasPermission([Permission['organisation:update']]);

  const handleCreate = async (data: CreateSessionTypeDto) => {
    if (
      Array.isArray(data.additionalSessionTypes) &&
      data.additionalSessionTypes[0]?.length === 0
    ) {
      data.additionalSessionTypes = [];
    }

    return await create(data);
  };

  const handleUpdate = async (data: UpdateSessionTypeDto) => {
    // remove empty array: [[]] => []
    if (
      Array.isArray(data.additionalSessionTypes) &&
      data.additionalSessionTypes[0]?.length === 0
    ) {
      data.additionalSessionTypes = [];
    }

    return await update({
      id: params.id || '',
      data,
    });
  };

  const handleCancel = () => {
    navigate(staffRouterPath.SESSION_TYPES);
  };

  const handleDeleteSessionType = async () => {
    await deleteSessionType(params.id || '');
  };

  return (
    <>
      <PageTitle
        title={isCreateView ? 'New Session Type' : 'Edit Session Type'}
        variant="gray"
      >
        <div className="flex gap-3">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCancel}
            disabled={isMutating}
          >
            Cancel
          </button>

          <button
            className="btn btn-primary"
            disabled={isMutating}
            form="upsert-session-type-form"
          >
            {isCreateView ? 'Create' : 'Update'}
          </button>
        </div>
      </PageTitle>

      {(isWeekModelLoading && !isCreateView) || isSessionTypesLoading ? (
        <div className="flex justify-center py-12">
          <Spinner className="h-12 w-12" />
        </div>
      ) : (
        <>
          <SessionTypeForm
            onSubmit={isCreateView ? handleCreate : handleUpdate}
            initialData={sessionType}
            isLoading={isMutating}
            sessionTypes={sessionTypes || []}
          />
          {!isCreateView &&
          hasPermission([Permission['organisation:delete']]) ? (
            <div className="two-col-form">
              <div>
                <p className="text-md font-medium leading-7 text-gray-900">
                  Destructive Actions
                </p>
                <p className="text-md leading-6 text-gray-600">
                  Take care with these actions as they are destructive and may
                  not be reversable.
                </p>
              </div>
              <div className="col-span-2 space-y-6">
                <DeleteZone
                  title="Delete Session Type"
                  actionButtonText="Delete Session Type"
                  description="This action cannot be completed if there are locations using this session type."
                  modalDescription="In order to delete this Session Type, please type DELETE in the input below and confirm deletion."
                  onDelete={handleDeleteSessionType}
                  isLoading={isDeleting}
                  error={getErrorMessage(deleteError)}
                />
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
}

export { UpsertSessionType };
