import { reportKey } from '@/config/query-keys';
import { useReportingOutputControl } from '@/context/reporting-output-control.context';
import { reportService } from '@/services/report.service';
import { HttpError } from '@/types/error';
import { ToggledColumns } from '@/types/reporting-output-control';
import {
  Application,
  PaginateResult,
  ReportsApplicationsDto,
} from '@admissions-support/types';
import {
  UseQueryOptions,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';

function useApplicationsReportResult(
  data: ReportsApplicationsDto,
  queryOptions?: Partial<
    UseQueryOptions<PaginateResult<Application>, HttpError>
  >
) {
  return useQuery({
    queryKey: reportKey.getApplicationsResults(data),
    queryFn: () => {
      return reportService.getApplicationsReportResult({
        ...data,
        page: data.page && data.page !== 0 ? data.page + 1 : 1,
      });
    },
    ...queryOptions,
    placeholderData: keepPreviousData,
  });
}

function useExportApplicationsReportResultToCSV(
  data: ReportsApplicationsDto,
  queryOptions?: Partial<UseQueryOptions<Blob, HttpError>>
) {
  const { toggledColumns } = useReportingOutputControl();

  const enabledColumns = Object.keys(toggledColumns).filter(
    key => toggledColumns[key as keyof ToggledColumns]
  );

  return useQuery({
    queryKey: reportKey.exportApplicationsResult(data),
    queryFn: () => {
      return reportService.exportApplicationsReportResult({
        ...data,
        isCsvExport: true,
        toExport: enabledColumns as ReportsApplicationsDto['toExport'],
        page: data.page && data.page !== 0 ? data.page + 1 : 1,
      });
    },
    ...queryOptions,
  });
}

export { useApplicationsReportResult, useExportApplicationsReportResultToCSV };
