import { Table } from '@/components/table';
import { Tag } from '@/components/tag';
import { DetailedAllocation } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';

function NumberContainer({ value }: { value: number | null }) {
  if (value === null) {
    return <>-</>;
  }

  return (
    <Tag type="default" className="h-[24px] min-w-10 p-0 font-mono text-sm ">
      {value}
    </Tag>
  );
}

type TableRow = {
  sessionTypeId: string;
  sessionTypeName: string;
  monday: number | null;
  tuesday: number | null;
  wednesday: number | null;
  thursday: number | null;
  friday: number | null;
};

function convertDetailedAllocation(
  detailedAllocation: DetailedAllocation
): TableRow[] {
  return detailedAllocation.allocations.map(data => ({
    sessionTypeId: data.sessionType.id.toString(),
    sessionTypeName: data.sessionType.name,
    monday: data.allocation.monday,
    tuesday: data.allocation.tuesday,
    wednesday: data.allocation.wednesday,
    thursday: data.allocation.thursday,
    friday: data.allocation.friday,
  }));
}

type CapacityResourceTableProps = {
  detailedAllocation: DetailedAllocation;
};

function CapacityResourceTable({
  detailedAllocation,
}: CapacityResourceTableProps) {
  const columnHelper = createColumnHelper<TableRow>();
  const convertedDetailedAllocation: TableRow[] =
    convertDetailedAllocation(detailedAllocation);

  const columns = [
    columnHelper.accessor('sessionTypeName', {
      header: 'Session Type',
      cell: ({ row }) => {
        return <span>{row.original.sessionTypeName}</span>;
      },
      enableSorting: false,
    }),
    columnHelper.accessor('monday', {
      header: 'Monday',
      cell: ({ row }) => {
        return <NumberContainer value={row.original.monday} />;
      },
      enableSorting: false,
    }),
    columnHelper.accessor('tuesday', {
      header: 'Tuesday',
      cell: ({ row }) => {
        return <NumberContainer value={row.original.tuesday} />;
      },
      enableSorting: false,
    }),
    columnHelper.accessor('wednesday', {
      header: 'Wednesday',
      cell: ({ row }) => {
        return <NumberContainer value={row.original.wednesday} />;
      },
      enableSorting: false,
    }),
    columnHelper.accessor('thursday', {
      header: 'Thursday',
      cell: ({ row }) => {
        return <NumberContainer value={row.original.thursday} />;
      },
      enableSorting: false,
    }),
    columnHelper.accessor('friday', {
      header: 'Friday',
      cell: ({ row }) => {
        return <NumberContainer value={row.original.friday} />;
      },
      enableSorting: false,
    }),
  ];

  return (
    <div className="flex flex-col gap-2">
      <span className="text-sm font-medium text-gray-900">
        {detailedAllocation.cohort.name}
      </span>
      {convertedDetailedAllocation.length === 0 ? (
        <div className="text-sm text-gray-500">No resource data found</div>
      ) : (
        <Table
          data={convertedDetailedAllocation}
          columns={columns}
          className="mt-0"
        />
      )}
    </div>
  );
}

export default CapacityResourceTable;
