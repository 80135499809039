import { FallbackComponent } from '@/components/fallback-component';
import { LoadingScreen } from '@/components/loading-screen';
import { PageTitle } from '@/components/page-title';
import { transformQueryBuilderApiDataToFormData } from '@/components/reporting/query-builder.form';
import { OutputTable } from '@/components/reporting/query-builder/output-table';
import { staffRouterPath } from '@/config/route-paths.config';
import { useReportingOutputControl } from '@/context/reporting-output-control.context';
import { useReportTemplate } from '@/hooks/query-hooks/use-report-template';
import { ReportingOutputFilter } from '@/types/reporting-output-control';
import {
  exportableKeysApplications,
  getAndConditions,
  getOrConditions,
} from '@/utils/query-builder-utils';
import { useEffect } from 'react';
import { Link, generatePath, useParams } from 'react-router-dom';

function RunQueryBuilder() {
  const params = useParams<{ id?: string }>();
  const {
    data: reportTemplate,
    isLoading: isReportLoading,
    isSuccess: isReportSuccess,
    isError: isReportError,
  } = useReportTemplate(params.id || '');
  const { setToggledColumns, setFilter } = useReportingOutputControl();

  useEffect(() => {
    if (!isReportSuccess) {
      return;
    }

    const newToggledColumns = exportableKeysApplications.reduce((acc, key) => {
      return {
        ...acc,
        [key]: reportTemplate.toggledColumns.includes(key) || false,
      };
    }, {});

    const formData = transformQueryBuilderApiDataToFormData(reportTemplate);
    const conditions: ReportingOutputFilter = {
      ...getAndConditions(formData.conditionGroups),
      orConditions: getOrConditions(formData.conditionGroups),
    };

    setFilter(conditions);
    setToggledColumns(newToggledColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReportSuccess]);

  if (isReportLoading) {
    return <LoadingScreen />;
  }

  if (!isReportSuccess || isReportError) {
    return <FallbackComponent />;
  }

  return (
    <>
      <PageTitle title={reportTemplate.name} variant="gray">
        <div className="flex gap-3">
          <Link
            to={generatePath(staffRouterPath.EDIT_QUERY_BUILDER, {
              id: reportTemplate.id,
            })}
            className="btn btn-secondary"
          >
            Edit
          </Link>
        </div>
      </PageTitle>

      <OutputTable
        className="mt-12"
        runOnMount
        dataSource={reportTemplate.dataSource}
      />
    </>
  );
}

export { RunQueryBuilder };
