import { productKey } from '@/config/query-keys';
import { productService } from '@/services/product.service';
import { HttpError } from '@/types/error';
import {
  CustomPaginateResult,
  FetchAllProductsByLocationAndSchoolYearDto,
  ProductListing,
} from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useProductsByLocationId(
  locationId: string,
  query: FetchAllProductsByLocationAndSchoolYearDto,
  queryOptions?: Partial<
    UseQueryOptions<CustomPaginateResult<ProductListing>, HttpError>
  >
) {
  return useQuery({
    queryKey: productKey.byLocation(
      locationId,
      query.schoolYearId,
      query.page,
      query.limit
    ),
    queryFn: () => productService.getProductsByLocationId(locationId, query),
    ...queryOptions,
  });
}

export { useProductsByLocationId };
