import { usePlatformUsersSubnavigation } from '@/hooks/use-platform-users-subnavigation';
import { Navigate } from 'react-router-dom';

/**
 * Navigate the user for the first navigation which is available
 * in their role. If there is none, redirect to home
 */
function PlatformUsersMain() {
  const platformUsersChildren = usePlatformUsersSubnavigation();

  if (platformUsersChildren.length === 0) {
    return <Navigate to="/" />;
  }

  return <Navigate to={platformUsersChildren[0].href} />;
}

export { PlatformUsersMain };
