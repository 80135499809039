import { sessionTypeKey } from '@/config/query-keys';
import { sessionTypeService } from '@/services/session-type.service';
import { HttpError } from '@/types/error';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useDeleteSessionTypeMutation(
  mutationOptions?: UseMutationOptions<void, HttpError, string>
) {
  return useMutation({
    mutationKey: sessionTypeKey.delete,
    mutationFn: sessionTypeService.removeSessionType,
    ...mutationOptions,
  });
}

export { useDeleteSessionTypeMutation };
