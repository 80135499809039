import { HttpError, isBadRequest } from '@/types/error';
import { getNestedKeys } from '@/utils/get-nested-keys';
import { Invitation } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { Alert } from '../alert';
import { PasswordRules } from '../password-rules';
import { TextInput } from './common/text-input';

const schema = yup
  .object({
    email: yup.string().email().required().label('Email'),
    firstName: yup.string().required().label('First name'),
    lastName: yup.string().required().label('Last name'),
    password: yup
      .string()
      .min(12, 'Password must be at least 12 characters long')
      .matches(/^(?=.*[0-9])/, 'Password must contain at least 1 number')
      // https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
      .matches(
        /^(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])/,
        'Password must contain at least 1 special character'
      )
      .matches(
        /^(?=.*[A-Z])/,
        'Password must contain at least 1 uppercase letter'
      )
      .matches(
        /^(?=.*[a-z])/,
        'Password must contain at least 1 lowercase letter'
      )
      .required(),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref('password'), ''], 'Passwords must match')
      .required('Confirm Password is required'),
  })
  .required();

interface RegisterDto {
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirm: string;
  email: string;
}

interface RegisterFormProps {
  onRegister: (data: RegisterDto) => Promise<void>;
  initialData?: Invitation;
}

function RegisterForm(props: RegisterFormProps) {
  const { onRegister, initialData } = props;
  const [hasError, setHasError] = useState(false);
  const form = useForm<RegisterDto>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: initialData?.firstName || '',
      lastName: initialData?.lastName || '',
      email: initialData?.email || '',
      password: '',
      passwordConfirm: '',
    },
    mode: 'all',
  });

  const hasInitialData = initialData !== undefined;

  const onSubmit = async (data: RegisterDto) => {
    try {
      await onRegister(data);
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        if (!form.formState.defaultValues) {
          return;
        }

        const availableFields = getNestedKeys(form.formState.defaultValues);

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            //need it because of different error structure
            if (field === 'email') {
              const formatedMsg = httpError.message.replace('email - ', '');
              form.setError(field, { message: formatedMsg });
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }

      setHasError(true);
    }
  };

  const password = form.watch('password');

  return (
    <div className="mt-8">
      {hasError ? (
        <Alert
          type="error"
          text="There was a problem with your registration."
          className="mb-4"
        />
      ) : null}
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
          <div className="grid grid-cols-1 gap-x-5 gap-y-5 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <TextInput
                name="firstName"
                label="First Name*"
                placeholder="Jane"
                type="text"
              />
            </div>
            <div className="sm:col-span-3">
              <TextInput
                name="lastName"
                placeholder="Doe"
                label="Last Name*"
                type="text"
              />
            </div>
          </div>
          <TextInput
            name="email"
            placeholder="jane.d@emailaddress.com"
            label="Email *"
            type="email"
            disabled={hasInitialData}
          />
          <TextInput
            name="password"
            label="Password*"
            type="password"
            placeholder="Enter your password"
            autoComplete="new-password"
          />
          <TextInput
            name="passwordConfirm"
            label="Confirm Password*"
            type="password"
            placeholder="Enter your password"
          />
          <PasswordRules password={password} schema={schema} />
          <button
            className="btn btn-primary btn-full"
            disabled={form.formState.isSubmitting || !form.formState.isValid}
          >
            Continue
          </button>
          <p className="text-center text-sm">
            Already have an account? <Link to="/auth/sign-in">Log in</Link>
          </p>
        </form>
      </FormProvider>
    </div>
  );
}

export { RegisterForm };
export type { RegisterDto };
