import { LinkExternal01 } from '@untitled-ui/icons-react';
import { twMerge } from 'tailwind-merge';
import { RadioBoxOptionProps } from '../form/common/radio-box';

function ModelRadioBoxOption(
  props: RadioBoxOptionProps & {
    name: string;
    nbHoursPerWeek: number;
    url?: string | null;
  }
) {
  const { checked, name, nbHoursPerWeek, url } = props;

  return (
    <div className="flex">
      <div>
        <span
          className={twMerge(
            checked ? 'text-primary-800' : 'text-gray-700',
            'block text-sm font-medium'
          )}
        >
          {name}
        </span>
        <span className="block text-sm text-gray-600">
          {nbHoursPerWeek} Hours per Week
        </span>
      </div>
      {url ? (
        <a
          onClick={e => e.stopPropagation()}
          href={url}
          target="_blank"
          rel="noreferrer"
          className="ml-auto block items-center text-sm"
        >
          <LinkExternal01
            viewBox="0 0 24 24"
            className="mr-2 inline-block h-5 w-5"
          />
          View details
        </a>
      ) : null}
    </div>
  );
}

export { ModelRadioBoxOption };
