import { messagingKey } from '@/config/query-keys';
import { useRespondToTicketMutation } from '@/hooks/create-hooks/use-respond-to-ticket';
import { queryClient } from '@/libs/react-query';
import { RespondToTicketRequest } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Attachment01 } from '@untitled-ui/icons-react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Textarea } from './common/textarea';
import { useFileUploadMutation } from '@/hooks/use-file-upload-mutation';
import { S3File } from '@/services/file.service';
import { paths } from '@/config/upload-paths';
import { useState, useRef } from 'react';
import toast from 'react-hot-toast';
import { MessageAttachment } from '../messaging/message-attachment';

const messageSchema = yup.object({
  message: yup.string().min(1).label('Message').required(),
});

type RespondToMessageFormProps = {
  ticketId: string;
  onMessageSent: () => void;
  isParent?: boolean;
};

function RespondToMessageForm({
  ticketId,
  onMessageSent,
  isParent = false,
}: RespondToMessageFormProps) {
  const [attachments, setAttachments] = useState<S3File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { mutateAsync: uploadFile, isPending: isUploading } =
    useFileUploadMutation();

  const form = useForm({
    defaultValues: {
      message: '',
    },
    resolver: yupResolver(messageSchema),
  });

  const { mutate: respondToTicket } = useRespondToTicketMutation(
    {
      id: ticketId || '',
      isParent,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: messagingKey.single(ticketId || ''),
        });
        form.reset();
        setAttachments([]);
        onMessageSent();
      },
    }
  );

  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (!files?.length) {
      return;
    }

    try {
      const uploadPromises = Array.from(files).map(file =>
        uploadFile({
          fileName: file.name,
          file,
          path: paths.messaging,
          bucket: 'messaging-files',
        })
      );

      const uploadedFiles = await Promise.all(uploadPromises);
      setAttachments(prev => [...prev, ...uploadedFiles]);
    } catch (error) {
      toast.error('Failed to upload file. Please try again.');
    }
  };

  const handleRemoveAttachment = (key: string) => {
    setAttachments(prev => prev.filter(file => file.key !== key));
  };

  const submitHandler = async (data: RespondToTicketRequest) => {
    await respondToTicket({
      message: data.message,
      attachments: attachments,
    });
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(submitHandler)}
        className="flex flex-col gap-2"
      >
        <Textarea
          name="message"
          placeholder="Type Response"
          label="Reply"
          rows={4}
        />
        <div className="flex flex-col gap-4">
          {attachments.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {attachments.map(file => (
                <MessageAttachment
                  key={file.key}
                  fileKey={file.key}
                  filename={file.filename}
                  reference={file.reference}
                  handleDownload={handleRemoveAttachment}
                />
              ))}
            </div>
          )}
          <div className="flex justify-end gap-2">
            <input
              type="file"
              className="hidden"
              ref={fileInputRef}
              onChange={handleFileSelect}
              multiple
              accept="image/*,.pdf,.doc,.docx"
            />
            <button
              className="btn btn-secondary flex items-center gap-1"
              type="button"
              onClick={() => fileInputRef.current?.click()}
              disabled={isUploading}
            >
              <Attachment01 height={20} width={20} /> Add Attachment
            </button>
            <button
              className="btn btn-primary"
              disabled={!form.formState.isValid || isUploading}
            >
              Send
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export default RespondToMessageForm;
