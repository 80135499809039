import { organisationKey } from '@/config/query-keys';
import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import { CreateRatioDto, Organisation } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCreateRatioMutation(
  mutationOptions?: UseMutationOptions<Organisation, HttpError, CreateRatioDto>
) {
  return useMutation({
    mutationKey: organisationKey.createRatio,
    mutationFn: organisationService.createRatio,
    ...mutationOptions,
  });
}

export { useCreateRatioMutation };
