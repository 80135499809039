import { useExtraHoursBookingsControl } from '@/context/extra-hours-bookings-control.context';
import { ChangeEvent } from 'react';
import { ApplicationStatusTag } from '../application/application-status-tag';
import { CheckboxBase } from '../form/common/checkbox';

function PaymentStatusFilter() {
  const { filter, setFilter } = useExtraHoursBookingsControl();

  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedStatus = event.target.name;

    const isPreviouslySelected = filter.status === selectedStatus;
    if (isPreviouslySelected) {
      setFilter(prevFilter => ({
        ...prevFilter,
        status: '',
      }));
    } else {
      setFilter(prevFilter => ({
        ...prevFilter,
        status: selectedStatus,
      }));
    }
  };

  return (
    <div className="space-y-2">
      {paymentStatuses.map(status => {
        return (
          <CheckboxBase
            key={status.value}
            name={status.value}
            label={
              <ApplicationStatusTag
                status={
                  status.value === 'DUE_PAYMENT'
                    ? 'PLACEMENT_REJECTED'
                    : 'PLACED'
                }
              >
                {status.label}
              </ApplicationStatusTag>
            }
            onChange={handleStatusChange}
            checked={filter.status === status.value}
          />
        );
      })}
    </div>
  );
}

const paymentStatuses: { label: string; value: string }[] = [
  { label: 'Healthy', value: 'HEALTHY' },
  { label: 'Payment Due', value: 'DUE_PAYMENT' },
];

export { PaymentStatusFilter, paymentStatuses };
