import { Box } from '@/components/box';
import { CarerItem } from '@/components/carer-item';
import { staffRouterPath } from '@/config/route-paths.config';
import {
  Application,
  ApplicationTemplate,
  UpdateApplicationDto,
} from '@admissions-support/types';
import { ArrowLeft, ArrowRight, Minus, Plus } from '@untitled-ui/icons-react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { defaultCarer, useCarerForm } from './use-carer.form';

type ParentCarerFormProps = {
  onSubmit: (data: UpdateApplicationDto) => any;
  initialData: Application;
  template: ApplicationTemplate;
  isMutating?: boolean;
};

function ParentCarerForm(props: ParentCarerFormProps) {
  const { initialData, onSubmit, isMutating, template } = props;
  const { form, handleSubmit, saveAsDraft } = useCarerForm({
    initialData,
    onSubmit,
  });
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const {
    append: addNewCarer,
    fields: carers,
    remove: removeCarer,
  } = useFieldArray({
    control: form.control,
    name: 'carers',
  });

  const formDisabled = isMutating;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={saveAsDraft}
        className="space-y-4"
        id="upsert-application-form"
      >
        {carers.map((carer, index) => {
          return (
            <Box className="flex flex-col space-y-4" key={`carer-${carer.id}`}>
              <CarerItem
                index={index}
                disabled={formDisabled}
                initialData={initialData}
                template={template}
              />
            </Box>
          );
        })}

        {carers.length < 2 ? (
          <div className="flex justify-center">
            <button
              type="button"
              className="btn btn-secondary flex items-center"
              onClick={() => addNewCarer(defaultCarer)}
              disabled={isMutating}
            >
              <Plus
                className="h-5 w-5"
                viewBox="0 0 24 24"
                aria-hidden="true"
              />
              Add Carer
            </button>
          </div>
        ) : (
          <div className="flex justify-center">
            <button
              type="button"
              className="btn btn-error flex items-center"
              onClick={() => removeCarer(carers.length - 1)}
              disabled={isMutating}
            >
              <Minus
                className="h-5 w-5"
                viewBox="0 0 24 24"
                aria-hidden="true"
              />
              Remove Carer
            </button>
          </div>
        )}
      </form>

      <div className="mt-6 flex flex-col space-y-4 sm:flex-row sm:justify-between sm:space-y-0">
        <button
          className="btn btn-secondary flex items-center"
          type="button"
          onClick={() =>
            navigate(
              generatePath(staffRouterPath.APPLICATION_CHILD_DETAILS, {
                id: params.id || '',
              })
            )
          }
          disabled={formDisabled}
        >
          <ArrowLeft
            viewBox="0 0 24 24"
            className="mr-2 h-5 w-5 text-gray-500"
          />
          Previous
        </button>
        <button
          className="btn btn-primary flex items-center"
          type="button"
          onClick={handleSubmit}
          disabled={formDisabled}
        >
          Continue
          <ArrowRight viewBox="0 0 24 24" className="ml-2 h-5 w-5 text-white" />
        </button>
      </div>
    </FormProvider>
  );
}

export { ParentCarerForm };
