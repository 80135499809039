import {
  BulkUpdateApplicationsDto,
  BulkUpdateApplicationsResponse,
} from '@admissions-support/types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { HttpError } from '@/types/error';
import { applicationKey } from '@/config/query-keys';
import { applicationService } from '@/services/application.service';
import { queryClient } from '@/libs/react-query';

function useBulkUpdateApplicationsMutation(
  mutationOptions?: UseMutationOptions<
    BulkUpdateApplicationsResponse,
    HttpError,
    BulkUpdateApplicationsDto
  >
) {
  return useMutation({
    mutationKey: applicationKey.bulkUpdateApplications,
    mutationFn: applicationService.bulkUpdateApplications,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['application'],
      });
      queryClient.invalidateQueries({
        queryKey: ['application-list'],
      });
    },
    ...mutationOptions,
  });
}

export { useBulkUpdateApplicationsMutation };
