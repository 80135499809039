import MygovscotLogo from '@/assets/mygovscot.svg?react';
import { LoginDto, LoginForm } from '@/components/form/login.form';
import { LoadingScreen } from '@/components/loading-screen';
import { LoginTitle } from '@/components/login-title';
import { useAuth } from '@/context/auth.context';
import { usePublicOrganisation } from '@/hooks/query-hooks/use-public-organisation';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { Spinner } from '@/components/spinner';
import { signInWithRedirect } from 'aws-amplify/auth';
import { isAdminDashboard } from '@/utils/is-dashboard';
import { isProd } from '@/utils/env-utils';
import { twMerge } from 'tailwind-merge';
import { parentRouterPath } from '@/config/route-paths.config';
import { Label, Radio, RadioGroup } from '@headlessui/react';
import { LockKeyholeCircle, UserPlus02 } from '@untitled-ui/icons-react';

type SignInOptionValue = 'create-account' | 'login';

const options = [
  {
    name: 'Create Account',
    value: 'create-account',
    Icon: UserPlus02,
  },
  {
    name: 'Login',
    value: 'login',
    Icon: LockKeyholeCircle,
  },
];

function InvitationSignIn() {
  const navigate = useNavigate();
  const [screenState, setScreenState] =
    useState<SignInOptionValue>('create-account');
  const { isLoading, data: organisation, isError } = usePublicOrganisation();
  const { login } = useAuth();
  const [searchParams] = useSearchParams();
  const mygovscotRedirectCode = searchParams.get('code');
  const isUserRedirected = !!mygovscotRedirectCode;
  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!organisation || isError) {
    return <Navigate to="/404" />;
  }

  const handleScreenChange = (value: string) => {
    if (value === 'create-account' || value === 'login') {
      setScreenState(value);
    }
  };

  const handleLogin = async (data: LoginDto) => {
    const result = await login({
      organisationId: organisation.id,
      password: data.password,
      email: data.email.trim(),
      rememberDevice: data.rememberDevice,
    });

    switch (result.nextStep.signInStep) {
      /*
       * User has 2FA setup, so needs to complete SMS or TOTP challenge
       */
      case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
        navigate('/auth/verify', {
          state: {
            type: 'TOTP',
          },
        });
        break;
      case 'CONFIRM_SIGN_IN_WITH_SMS_CODE':
        navigate('/auth/verify', {
          state: {
            type: 'SMS',
            codeDeliveryDetails: result.nextStep.codeDeliveryDetails,
          },
        });
        break;
      /*
       * User needs to set-up 2FA
       */
      case 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION':
        navigate('/auth/mfa-setup');
        break;
    }
  };

  const handleMyGovSignIn = async () => {
    await signInWithRedirect({
      provider: {
        custom: 'mygovscot',
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>Sign in</title>
      </Helmet>

      <div>
        <RadioGroup value={screenState} onChange={handleScreenChange}>
          <div className="mb-3 mt-8 flex space-x-2 rounded-md bg-white">
            {options.map(({ name, Icon, value }) => (
              <Radio
                key={name}
                value={value}
                className={({ checked }) =>
                  twMerge(
                    checked
                      ? 'z-10 border-2 border-green-200 bg-green-50'
                      : 'border-gray-200',
                    'relative flex flex-1 cursor-pointer items-center rounded-xl border p-4 focus:outline-none'
                  )
                }
              >
                {({ checked }) => (
                  <span className="mr-3 flex flex-col">
                    <Label
                      as="span"
                      className={twMerge(
                        checked ? 'text-primary-800' : 'text-gray-700',
                        'flex items-center text-sm font-medium'
                      )}
                    >
                      <div
                        className={twMerge(
                          checked ? 'bg-success-100' : 'bg-primary-50',
                          'mr-4 flex h-8 w-8 items-center justify-center rounded-full'
                        )}
                      >
                        <Icon
                          className="h-4 w-4 text-primary-600"
                          viewBox="0 0 24 24"
                        />
                      </div>
                      {name}
                    </Label>
                  </span>
                )}
              </Radio>
            ))}
          </div>
        </RadioGroup>

        {screenState === 'login' ? (
          <>
            <LoginTitle
              title="Login"
              description="Welcome back! Please enter your details."
            />
            <LoginForm onLogin={handleLogin} />
          </>
        ) : (
          <>
            <LoginTitle
              title="Create Your Account"
              description="You can create an account using your mygov.scot credentials"
            />
            {!isAdminDashboard() && !isProd() && (
              <button
                onClick={handleMyGovSignIn}
                className="btn btn-secondary text-md relative mb-6 mt-6 flex w-full flex-col items-center justify-center font-semibold sm:flex-row"
                disabled={isUserRedirected}
              >
                {isUserRedirected && (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <Spinner className="m-0 h-5" />
                  </div>
                )}
                <MygovscotLogo
                  className={twMerge(
                    'mt-1 h-5 sm:mr-3',
                    isUserRedirected && 'opacity-25'
                  )}
                />
                Sign in with mygov.scot
              </button>
            )}
            <div className="mx-auto mt-12 w-full rounded-lg border border-gray-200 bg-gray-50 p-6">
              <h2 className="text-lg font-semibold text-gray-900">
                Don&apos;t have a mygov.scot account?
              </h2>
              <p className="mt-2 text-gray-600">
                You can create an account using an email address and password.
              </p>
              <div className="mt-4">
                <button
                  onClick={() => navigate(parentRouterPath.CREATE_ACCOUNT)}
                  className="rounded-lg border border-gray-300 bg-white px-4 py-2 text-gray-800 hover:bg-gray-100"
                >
                  Create Account
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export { InvitationSignIn };
