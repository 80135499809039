import { HttpError, isBadRequest } from '@/types/error';
import { LocationSessionFormData } from '@/types/location-session';
import { getNestedKeys } from '@/utils/get-nested-keys';
import {
  areAllDaysAreEqual,
  days,
  findFirstNonNullDay,
  generateDateFromTime,
  getOperatingDaysOfSessionType,
  getStartEndForDay,
} from '@/utils/location-session-utils';
import {
  CreateSessionDto,
  Location,
  Model,
  OperatingDays,
  Session,
  SessionTiming,
  SessionType,
  UpdateSessionDto,
} from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { DeleteSession } from '../delete-session';
import { StartEndInput } from '../start-end-input';
import { Select } from './common/select';
import { TextInput } from './common/text-input';
import { Toggle } from './common/toggle';
import { Copy01 } from '@untitled-ui/icons-react';
import toast from 'react-hot-toast';

const schema = yup.object({
  name: yup.string().required().label('Session Name'),
  type: yup.string().required().label('Session Type'),
  model: yup.string().required().label('Week Model'),
  sameTime: yup.bool().required(),
  generalTime: yup.object().when('sameTime', {
    is: true,
    then: schema =>
      schema.shape({
        start: yup.string().required().label('Start Time'),
        end: yup.string().required().label('End Time'),
      }),
    otherwise: schema =>
      schema.shape({
        start: yup.string().label('Start Time'),
        end: yup.string().label('End Time'),
      }),
  }),
  ...days.reduce((timesObject, day) => {
    return {
      ...timesObject,
      [day]: yup
        .object()
        .when(
          ['type', 'sameTime'],
          ([sessionTypeId, sameTime], schema, { context }) => {
            // if true all the operation times will be the same, so
            // no need to check for the day objects
            if (sameTime) {
              return schema.shape({
                start: yup.string().label('Start Time'),
                end: yup.string().label('End Time'),
              });
            }
            const sessionTypes = context.sessionTypes as SessionType[];

            // get the operatingDays for the selected session id
            const selectedSessionType = sessionTypes.find(
              sessionType => sessionType.id === sessionTypeId
            );

            const daysNotOptional =
              getOperatingDaysOfSessionType(selectedSessionType);

            if (daysNotOptional.includes(day)) {
              return schema.shape({
                start: yup.string().required().label('Start Time'),
                end: yup.string().required().label('End Time'),
              });
            }

            return schema.shape({
              start: yup.string().label('Start Time'),
              end: yup.string().label('End Time'),
            });
          }
        ),
    };
  }, {}),
});

type LocationSessionFormProps =
  | {
      sessionTypes: SessionType[];
      weekModels: Model[];
      onSubmit: (
        data: Omit<CreateSessionDto, 'schoolYearId'>
      ) => Promise<Location>;
      onClose?: () => void;
      isMutating?: boolean;
      initialData?: undefined;
      id?: string;
      disabled?: boolean;
    }
  | {
      id: string;
      sessionTypes: SessionType[];
      weekModels: Model[];
      onSubmit: (data: UpdateSessionDto) => Promise<Location>;
      initialData: Session;
      onClose?: () => void;
      isMutating?: boolean;
      disabled?: boolean;
    };

function LocationSessionForm(props: LocationSessionFormProps) {
  const {
    sessionTypes,
    weekModels,
    initialData,
    onClose,
    isMutating,
    onSubmit,
    id,
    disabled,
  } = props;

  const defaultSameTime = initialData?.times
    ? areAllDaysAreEqual(initialData?.times)
    : true;

  const formDefaultValues = useMemo(
    () => ({
      name: initialData?.name ? String(initialData?.name) : '',
      type: initialData?.type.id ? String(initialData?.type.id) : '',
      model: initialData?.model.id ? String(initialData?.model.id) : '',
      sameTime: defaultSameTime,
      generalTime: defaultSameTime
        ? {
            start: findFirstNonNullDay(initialData?.times).start,
            end: findFirstNonNullDay(initialData?.times).end,
          }
        : {
            start: '',
            end: '',
          },
      ...days.reduce(
        (openingHours, day) => ({
          ...openingHours,
          [day]: {
            start: getStartEndForDay(
              initialData?.times[day as keyof SessionTiming]
            ).start,
            end: getStartEndForDay(
              initialData?.times[day as keyof SessionTiming]
            ).end,
          },
        }),
        {}
      ),
    }),
    [defaultSameTime, initialData]
  );

  const form = useForm({
    resolver: yupResolver(schema),
    context: {
      sessionTypes,
    },
    defaultValues: formDefaultValues,
  });

  const [isUserEditing, setIsUserEditing] = useState(false);

  // if no initial data passed we are creating a new session
  const isCreateNewSessionComponent = initialData === undefined;
  const sameTimeValue = form.watch('sameTime');
  const selectedTypeId = form.watch('type');

  const shouldFormBeDisabled = !isUserEditing && !isCreateNewSessionComponent;

  const selectedSessionType = sessionTypes.find(
    sessionType => sessionType.id === selectedTypeId
  );

  const daysToDisplay = getOperatingDaysOfSessionType(selectedSessionType);

  // no need to mess with filled but not needed data
  useEffect(() => {
    if (sameTimeValue || !form.formState.isDirty) {
      return;
    }

    days.forEach(day =>
      // @ts-ignore react-hook-forms cannot see dynamically created fields
      form.setValue(day, {
        start: '',
        end: '',
      })
    );
    form.reset(form.getValues(), { keepValues: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTypeId]);

  // Update form value after submission (submission -> query revalidation -> refetch query -> form reset  )
  const reset = form.reset;
  useEffect(() => {
    if (isUserEditing) {
      return;
    }

    reset(formDefaultValues);
  }, [isUserEditing, reset, formDefaultValues]);

  const handleSubmit = async (formData: LocationSessionFormData) => {
    try {
      const times = daysToDisplay.reduce((times, day) => {
        // handle when openingTime is the same for every day
        if (formData.sameTime) {
          return {
            ...times,
            [day]: {
              start: generateDateFromTime(formData.generalTime.start),
              end: generateDateFromTime(formData.generalTime.end),
            },
          };
        }

        const actualDayOpening = formData[day as keyof OperatingDays];

        return {
          ...times,
          [day]: {
            start: generateDateFromTime(actualDayOpening.start),
            end: generateDateFromTime(actualDayOpening.end),
          },
        };
      }, {});

      const session = {
        name: formData.name,
        typeId: formData.type,
        modelId: formData.model,
        times: Object.keys(times).length > 0 ? times : undefined,
      };

      await onSubmit(session);
      if (isUserEditing) {
        setIsUserEditing(false);
      }
      form.reset(formDefaultValues);
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        if (!form.formState.defaultValues) {
          return;
        }

        const availableFields = getNestedKeys(form.getValues());

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message
              .replace('times.', '') //remove because of different field structure on the backend
              .replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            const formatedMsg = msg.replaceAll(`"`, ''); //TODO: remove once @isBefore decorator fixed
            //if there is an error with the date, but we use the same date for every day, display error only for generalTime field
            if (formData.sameTime) {
              //is there any day related error in the array?
              const isErrorWithDate = days.some(day => field.includes(day));

              // if yes, does that message relates to the actual field?
              if (isErrorWithDate && msg.includes(field)) {
                const formatedMsg = msg.replace(`times.${field}`, 'Field'); //remove because of different field structure on the backend

                // find the day for the error, which we use to replace the field name to generalTime in the next steps
                const whichDayTheFieldBelongsTo = days.find(day =>
                  field.includes(day)
                );

                const fieldName = field
                  .replace(whichDayTheFieldBelongsTo || '', 'generalTime')
                  .replaceAll(`"`, ''); //TODO: remove once @isBefore decorator fixed

                // @ts-ignore react-hooks-form does not see manually constructed field name as valid field name
                form.setError(fieldName, {
                  message: formatedMsg,
                });

                return;
              }
            }

            if (formatedMsg.includes(field)) {
              const msgToDisplay = formatedMsg
                .replace('times.', '') //remove because of different field structure on the backend
                .replace(field, 'Field');

              form.setError(field, { message: msgToDisplay });
            }
          });
        });
      }
    }
  };

  const handleCloseEdit = () => {
    setIsUserEditing(false);
    form.reset(formDefaultValues);
  };

  const sessionTypeOptions = [
    { key: '', value: 'Select Session Type...' },
    ...sessionTypes.map(sessionType => ({
      key: sessionType.id,
      value: sessionType.name,
    })),
  ];
  const weekModelOptions = [
    { key: '', value: 'Select Model...' },
    ...weekModels.map(model => ({
      key: model.id,
      value: model.name,
    })),
  ];

  const handleCopyValue = async () => {
    try {
      if (!initialData) {
        return;
      }
      await navigator.clipboard.writeText(initialData.id);

      toast.success('ID copied to clipboard!');
    } catch (error) {
      toast.error('Error copying to clipboard!');
    }
  };

  return (
    <FormProvider {...form}>
      <form
        className="light-gray-container group"
        //@ts-ignore yup does not like automatically generated fields
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <div className="mb-6 flex items-center justify-between">
          <p className="text-md my-1.5 flex items-center font-medium">
            {isCreateNewSessionComponent
              ? 'Add Session'
              : isUserEditing
              ? 'Edit Session'
              : 'Session'}
            {!isCreateNewSessionComponent ? (
              <button
                type="button"
                className="copy-session-id-tooltip ml-2"
                onClick={handleCopyValue}
              >
                <Copy01 className="h-4 w-4" />
              </button>
            ) : null}
          </p>
          {/* Show it if you clicked on Add session button */}
          {isCreateNewSessionComponent && (
            <div className="space-x-3">
              <button
                type="button"
                className="btn btn-secondary py-2"
                onClick={onClose}
                disabled={isMutating}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary py-2"
                disabled={!form.formState.isDirty || isMutating}
              >
                Save
              </button>
            </div>
          )}

          {/* Show it if you hover over an existing session */}
          {!isCreateNewSessionComponent && !isUserEditing && !disabled && (
            <button
              type="button"
              className="btn btn-secondary hidden py-2 group-hover:block"
              onClick={() => setIsUserEditing(true)}
            >
              Edit
            </button>
          )}

          {/* Show it if you clicked on Edit button on an existing session */}
          {!isCreateNewSessionComponent && isUserEditing && (
            <div className="space-x-3">
              <button
                type="button"
                className="btn btn-secondary py-2"
                onClick={handleCloseEdit}
                disabled={isMutating}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary py-2"
                disabled={!form.formState.isDirty || isMutating}
              >
                Save
              </button>
            </div>
          )}
        </div>

        <div className="mb-4 space-y-6">
          <TextInput
            name="name"
            label="Session Name"
            helperText="Internal use only. Descriptive name for this session, used for identifying sessions in a list."
            placeholder="Short Session AM"
            disabled={isMutating || shouldFormBeDisabled}
          />

          <Select
            name="type"
            label="Session Type"
            options={sessionTypeOptions}
            helperText="Defines the pattern that users can select"
            disabled={isMutating || shouldFormBeDisabled}
          />

          <Select
            name="model"
            label="Model"
            options={weekModelOptions}
            helperText="Defines the number of hours per week."
            disabled={isMutating || shouldFormBeDisabled}
          />
          {(isUserEditing || isCreateNewSessionComponent) && (
            <Toggle
              value={sameTimeValue}
              onChange={e => form.setValue('sameTime', e)}
              label="Use Same Start and End Time"
              disabled={shouldFormBeDisabled}
              description="Session times defined below are the same for all days this session operates"
            />
          )}
          {sameTimeValue ? (
            <StartEndInput
              name="generalTime"
              disabled={isMutating || shouldFormBeDisabled}
            />
          ) : (
            <div className="space-y-6">
              {daysToDisplay.map(day => (
                <StartEndInput
                  key={day}
                  name={day}
                  label={day}
                  disabled={isMutating || shouldFormBeDisabled}
                />
              ))}
            </div>
          )}
        </div>
        {!isCreateNewSessionComponent && isUserEditing && id && (
          <DeleteSession sessionId={id} />
        )}
      </form>
    </FormProvider>
  );
}

export { LocationSessionForm };
