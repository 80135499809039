import { Alert } from '@/components/alert';
import {
  ExtraHourResourceFormActionTypes,
  useExtraHourResourceFormReducer,
} from '@/reducers/extra-hour-resources.reducer';
import { HttpError, isBadRequest } from '@/types/error';
import {
  getAllocatedCapacityForAllDay,
  getAllocatedCapacityForDay,
  transformInitialExtraHourResourceValue,
  transformLocationResourceFormDataToDto,
} from '@/utils/extra-hours-availability-utils';
import { generateClassByCapacity } from '@/utils/location-utils';
import {
  CreateExtraHourResourceDto,
  ExtraHourPublic,
  ExtraHourResource,
  Operation,
  RatioCapacity,
  SessionType,
  UpdateExtraHourResourceDto,
} from '@admissions-support/types';
import * as Sentry from '@sentry/react';
import { capitalize, isEqual, isNumber } from 'lodash-es';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { DeleteResource } from '../../delete-resource';
import { MultiSelectBase } from '../../form/common/multi-select';
import { TextInputBase } from '../../form/common/text-input';
import { Toggle } from '../../form/common/toggle';

type ExtraHourResourceFormProps =
  // Create
  | {
      sessionTypes: SessionType[];
      ratioCapacities: RatioCapacity[];
      onSubmit: (data: CreateExtraHourResourceDto) => Promise<Operation>;
      onClose: () => void;
      isMutating?: boolean;
      initialData?: undefined;
      id?: string;
      operationId?: string;
      extraHours: ExtraHourPublic[];
    }
  // Update
  | {
      id: string;
      operationId: string;
      sessionTypes: SessionType[];
      ratioCapacities: RatioCapacity[];
      onSubmit: (data: UpdateExtraHourResourceDto) => Promise<Operation>;
      initialData: ExtraHourResource;
      onClose: () => void;
      isMutating?: boolean;
      extraHours: ExtraHourPublic[];
    };

function ExtraHourResourceForm(props: ExtraHourResourceFormProps) {
  const {
    sessionTypes,
    ratioCapacities,
    initialData,
    onClose,
    isMutating,
    onSubmit,
    operationId,
    id,
    extraHours,
  } = props;

  const transformedInitialState = transformInitialExtraHourResourceValue(
    ratioCapacities.map(rc => rc.ratio.id.toString()),
    initialData
  );

  const [state, dispatch] = useExtraHourResourceFormReducer(
    ratioCapacities,
    sessionTypes,
    transformedInitialState
  );

  const rootRef = useRef<HTMLFormElement>(null);
  const [capacityError, setCapacityError] = useState<string[]>([]);

  // if no initial data passed we are creating a new resource
  const isCreateNewResourceComponent = initialData === undefined;

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const payload = transformLocationResourceFormDataToDto(state);

      await onSubmit(payload);
      onClose();
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        if (Array.isArray(httpError.message)) {
          const capacityErrors = httpError.message
            //if errors are prefixed with extraHours.capacities, we know the error is about the capacity numbers
            .filter(msg => msg.includes('extrahours.capacities'))
            .map(msg => msg.replace('extrahours.capacities ', ''));
          setCapacityError(capacityErrors);
        }
      }

      if (!isBadRequest(httpError)) {
        Sentry.captureException(error);
      }
    }
  };

  const selectedSessionType = sessionTypes.find(
    sessionType => sessionType.id === state.sessionTypeIds[0]
  );

  // you only can select session type if they work on the same day as the others
  const selectableSessionTypes = sessionTypes.filter(sessionType => {
    // no session type is selected, so you may choose any of it
    if (!selectedSessionType) {
      return true;
    }

    if (isEqual(sessionType.operatingDays, selectedSessionType.operatingDays)) {
      return true;
    }

    return false;
  });

  const filteredExtraHours = extraHours.filter(extraHour =>
    state.sessionTypeIds.includes(extraHour.session.type.id.toString())
  );

  useEffect(() => {
    // When the component mounts, scroll the page to this component
    if (rootRef.current) {
      rootRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Display blur on edit */}
      <div
        className="fixed inset-0 z-10 !mt-0 backdrop-blur-sm transition-opacity"
        role="presentation"
        onClick={onClose}
      />

      <form
        className="white-container group relative z-10 pt-0"
        ref={rootRef}
        onSubmit={handleSubmit}
      >
        <div className="sticky left-0 top-0 z-50 flex items-center justify-between bg-white py-4">
          <p className="text-md my-1.5 font-medium">
            {isCreateNewResourceComponent ? 'Add Resource' : 'Edit Resource'}
          </p>

          <div className="space-x-3">
            <button
              type="button"
              className="btn btn-secondary py-2"
              onClick={onClose}
              disabled={isMutating}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary py-2"
              type="submit"
              disabled={isMutating || state.sessionTypeIds.length < 1}
            >
              Save
            </button>
          </div>
        </div>

        <MultiSelectBase
          name="sessionTypeIds"
          label="Session Type(s)"
          helperText="Select session types that will use these spaces."
          placeholder="Select Session..."
          options={selectableSessionTypes.map(sessionType => ({
            label: sessionType.name,
            value: sessionType.id,
          }))}
          isDisabled={isMutating}
          value={state.sessionTypeIds}
          onChange={value =>
            dispatch({
              type: ExtraHourResourceFormActionTypes.SET_SESSSION_TYPES,
              payload: value,
            })
          }
        />
        {capacityError.length > 0 && (
          <Alert
            text={
              <div className="space-y-2">
                {capacityError.map(error => (
                  <p key={error} className="leading-tight">
                    {error}
                  </p>
                ))}
              </div>
            }
            type="error"
          />
        )}
        {state.sessionTypeIds.length > 0 && (
          <div className="light-gray-container mt-6 space-y-2">
            {state.ratioCapacities.map(rc => {
              const currentResource = state.resources.find(
                resource => resource.ratioId === rc.ratio.id.toString()
              );

              if (!currentResource) {
                return;
              }

              const range = getAllocatedCapacityForAllDay(
                rc.ratio.id.toString(),
                filteredExtraHours
              );

              const isAllocatedRangeFlat =
                range.allocated.max === range.allocated.min;
              const isCapacityRangeFlat =
                range.capacities.min === range.capacities.max;

              const allocatedContent = isAllocatedRangeFlat
                ? range.allocated.max
                : `${range.allocated.min}-${range.allocated.max}`;

              const capacityContent = isCapacityRangeFlat
                ? range.capacities.max
                : `${range.capacities.min}-${range.capacities.max}`;

              return (
                <div className="white-container" key={rc.ratio.id.toString()}>
                  <p className="text-md mb-2 font-semibold text-gray-700">
                    {rc.ratio.name}
                  </p>
                  <Toggle
                    value={currentResource?.hasSameCapacityForEachDay}
                    onChange={() =>
                      dispatch({
                        type: ExtraHourResourceFormActionTypes.TOGGLE_SAME_CAPACITY,
                        payload: { ratioId: rc.ratio.id.toString() },
                      })
                    }
                    label="Same Resource for each day"
                    disabled={!selectedSessionType}
                    description="Every day will have the same level of resource available"
                  />
                  <div className="dark-gray-container mt-4">
                    {currentResource.hasSameCapacityForEachDay ? (
                      <div>
                        <div className="mb-1 flex items-center justify-between">
                          <p className="text-sm font-medium text-gray-700">
                            All Days
                          </p>
                          <span
                            className={twMerge(
                              generateClassByCapacity(
                                range.capacities.max,
                                range.allocated.max || 0
                              ),
                              'hover:cursor-pointer'
                            )}
                            data-tooltip-content={`${allocatedContent} Allocated of ${capacityContent}`}
                            data-tooltip-id="capacity-tooltip"
                            data-tooltip-class-name="mb-5"
                          >
                            {allocatedContent}
                          </span>
                        </div>
                        <TextInputBase
                          value={
                            isNumber(currentResource.capacityDays[0].capacity)
                              ? currentResource.capacityDays[0].capacity
                              : ''
                          }
                          onChange={e => {
                            dispatch({
                              type: ExtraHourResourceFormActionTypes.SET_GENERAL_CAPACITY,
                              payload: {
                                ratioId: rc.ratio.id.toString(),
                                capacity: parseInt(e.target.value),
                              },
                            });
                          }}
                          min={0}
                          type="number"
                        />
                      </div>
                    ) : (
                      <div className="grid grid-cols-2 gap-6">
                        {currentResource.capacityDays.map(cd => {
                          const allocatedRange = getAllocatedCapacityForDay(
                            filteredExtraHours,
                            rc.ratio.id.toString(),
                            cd.day.toLowerCase()
                          );

                          const dayCapacity = initialData?.capacities[
                            cd.day
                          ].find(
                            c => c.ratioId.toString() === rc.ratio.id.toString()
                          )?.capacity;

                          const allocatedContentDay =
                            allocatedRange.min === allocatedRange.max
                              ? allocatedRange.max
                              : `${allocatedRange.min}-${allocatedRange.max}`;

                          return (
                            <div key={`${currentResource.ratioId}-${cd.day}`}>
                              <div className="mb-1 flex items-center justify-between">
                                <p className="text-sm font-medium text-gray-700">
                                  {capitalize(cd.day)}
                                </p>
                                <span
                                  className={twMerge(
                                    generateClassByCapacity(
                                      dayCapacity || 0,
                                      allocatedRange.max || 0
                                    ),
                                    'hover:cursor-pointer'
                                  )}
                                  data-tooltip-content={`${allocatedContentDay} Allocated of ${
                                    dayCapacity || 0
                                  }`}
                                  data-tooltip-id="capacity-tooltip"
                                  data-tooltip-class-name="mb-5"
                                >
                                  {allocatedContentDay}
                                </span>
                              </div>
                              <TextInputBase
                                onChange={e =>
                                  dispatch({
                                    type: ExtraHourResourceFormActionTypes.SET_CAPACITY,
                                    payload: {
                                      ratioId: currentResource.ratioId,
                                      capacity: parseInt(e.target.value),
                                      day: cd.day,
                                    },
                                  })
                                }
                                value={isNumber(cd.capacity) ? cd.capacity : ''}
                                min={0}
                                type="number"
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {!isCreateNewResourceComponent && id && operationId && (
          <DeleteResource
            operationId={operationId}
            resourceId={id}
            onSuccess={onClose}
            isExtraHours={Boolean(extraHours)}
          />
        )}
      </form>
    </>
  );
}

export { ExtraHourResourceForm };
