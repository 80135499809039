import { areaKey } from '@/config/query-keys';
import { areaService } from '@/services/area.service';
import { HttpError } from '@/types/error';
import { Area } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useAreas(queryOptions?: Partial<UseQueryOptions<Area[], HttpError>>) {
  return useQuery({
    queryKey: areaKey.list,
    queryFn: areaService.getAreas,
    ...queryOptions,
  });
}

export { useAreas };
