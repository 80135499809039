import { ParentPlacementPreferencesForm } from '@/components/application/placement-preferences/parent-placement-preferences.form';
import { Spinner } from '@/components/spinner';
import { staffRouterPath } from '@/config/route-paths.config';
import { useApplication } from '@/hooks/query-hooks/use-application';
import { useLocations } from '@/hooks/query-hooks/use-locations';
import { useUpdateApplicationMutation } from '@/hooks/update-hooks/use-update-application-mutation';
import { Navigate, useParams } from 'react-router-dom';

function ParentPlacementPreferences() {
  const params = useParams<{ id: string }>();
  const applicationId = params.id || '';

  const {
    data: application,
    error: applicationError,
    isLoading: isApplicationLoading,
  } = useApplication(applicationId, {
    enabled: Boolean(applicationId),
  });

  const {
    data: locationsData,
    error: locationError,
    isLoading: isLocationDataLoding,
  } = useLocations({
    select: locations =>
      //hide archived/inactive locations
      locations.filter(location => location.status === 'ACTIVE'),
  });

  const { mutateAsync: updateApplication } =
    useUpdateApplicationMutation(applicationId);

  const isFormLoading = isApplicationLoading || isLocationDataLoding;

  const locations = Array.isArray(locationsData)
    ? locationsData.map(location => ({
        key: location.id,
        value: location.name,
      }))
    : [];

  if (applicationError || locationError) {
    return <Navigate to={staffRouterPath.APPLICATIONS} />;
  }

  if (isApplicationLoading) {
    return (
      <div className="flex justify-center py-12">
        <Spinner className="h-12 w-12" />
      </div>
    );
  }

  return (
    <div>
      {!isFormLoading && application && locations ? (
        <ParentPlacementPreferencesForm
          initialData={application}
          locations={locations}
          onSubmit={updateApplication}
        />
      ) : (
        <div className="flex items-center justify-center px-4 py-20">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export { ParentPlacementPreferences };
