import { fileKey } from '@/config/query-keys';
import { fileService } from '@/services/file.service';
import { HttpError } from '@/types/error';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { GetUrlWithPathOutput } from 'aws-amplify/storage';

function useFile(
  path: string,
  bucket?: string,
  queryOptions?: Partial<UseQueryOptions<GetUrlWithPathOutput, HttpError>>
) {
  return useQuery({
    queryKey: fileKey.single(path),
    queryFn: () => fileService.downloadFile({ path, bucket }),
    ...queryOptions,
    enabled: Boolean(path),
  });
}

export { useFile };
