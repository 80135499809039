import { useModal } from '@/hooks/use-modal';
import { TrusteeWithInvitation } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import { Edit05, SearchRefraction } from '@untitled-ui/icons-react';
import { capitalize } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Table } from '../table';
import { Tag } from '../tag';
import { UpdateTrusteeModal } from './update-trustee-modal';

type TrusteeListTableProps = {
  data?: TrusteeWithInvitation[];
  isLoading?: boolean;
  childId: string;
};

function TrusteeListTable(props: TrusteeListTableProps) {
  const { data, isLoading, childId } = props;
  const { openModal, isOpen, closeModal } = useModal();
  const [selectedTrustee, setSelectedTrustee] = useState<
    TrusteeWithInvitation | undefined
  >(undefined);

  const columnHelper = createColumnHelper<TrusteeWithInvitation>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        cell: info => {
          const email = info.row.original.email;
          return (
            <span className="text-primary-900">
              {info.getValue()}
              <br />
              <span className="text-gray-600">{email}</span>
            </span>
          );
        },
        header: () => <span>Trustee Name</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('relationship', {
        id: 'relationship',
        cell: info => {
          return info.getValue();
        },
        header: () => <span>Relationship to Child</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('permissions', {
        cell: info => {
          return (
            <div className="space-x-2">
              {info.getValue().map(permission => (
                <Tag type="outline" key={permission}>
                  {permission
                    .toLowerCase()
                    .split('_')
                    .map(word => capitalize(word))
                    .join(' ')}
                </Tag>
              ))}
            </div>
          );
        },
        header: () => <span>Access</span>,
        enableSorting: false,
      }),

      columnHelper.display({
        id: 'id',
        cell: info => (
          <button
            type="button"
            className="view-child-tooltip icon-link block"
            onClick={() => {
              setSelectedTrustee(info.row.original);
              openModal();
            }}
          >
            <Edit05
              className="h-5 w-5"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
            <span className="sr-only">Update Trustee</span>
          </button>
        ),
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <UpdateTrusteeModal
        isOpen={isOpen}
        onClose={closeModal}
        childId={childId}
        trustee={selectedTrustee}
      />
      <Table
        columns={columns}
        data={data}
        className="mt-5"
        isLoading={isLoading}
        EmptyState={
          <div className="py-12 text-center">
            <SearchRefraction
              className="mx-auto h-8 w-8 text-gray-400"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              No Trustees Added
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Please add a trustee to continue
            </p>
          </div>
        }
      />
    </>
  );
}

export { TrusteeListTable };
