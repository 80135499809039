import { TabNavigation } from '@/components/tab-navigation';
import { staffRouterPath } from '@/config/route-paths.config';
import { generatePath, useParams } from 'react-router-dom';

function ApplicationTemplateNavigation() {
  const params = useParams<{ id: string }>();
  return (
    <div className="mt-5">
      <TabNavigation
        tabContainerClassName="border-b border-gray-200 bg-gray-25 -mx-4 px-4 pb-6 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        options={[
          {
            name: 'Overview',
            url: generatePath(staffRouterPath.EDIT_APPLICATION_TEMPLATE, {
              id: params.id || '',
            }),
          },
          {
            name: 'Questions',
            url: generatePath(staffRouterPath.APPLICATION_TEMPLATE_QUESTIONS, {
              id: params.id || '',
            }),
          },
          {
            name: 'Additional Questions',
            url: generatePath(
              staffRouterPath.APPLICATION_TEMPLATE_ADDITIONAL_QUESTIONS,
              {
                id: params.id || '',
              }
            ),
          },
        ]}
      />
    </div>
  );
}

export { ApplicationTemplateNavigation };
