import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

type UseLeaveModalProps = {
  show: boolean;
};

function useLeaveModal(props: UseLeaveModalProps) {
  const { show } = props;
  const blocker = useBlocker(show);

  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    if (show) {
      window.addEventListener('beforeunload', handler);
      return () => {
        window.removeEventListener('beforeunload', handler);
      };
    }
    return;
  }, [show]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      const canUserProceed = window.confirm(
        'Do you want to leave this site? Changes you made may not be saved.'
      );

      if (canUserProceed) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker]);
}

export { useLeaveModal };
