import {
  defaultApplicationControl,
  useApplicationControl,
} from '@/context/application-control.context';
import { getChoice } from '@/pages/application/applications';
import { ChoiceFilter } from '@admissions-support/types';
import { ChangeEvent } from 'react';
import { CheckboxBase } from '../form/common/checkbox';

function ChoicesFilter() {
  const { filter, setFilter } = useApplicationControl();

  const handleChoiceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedChoice = parseInt(event.target.id) as ChoiceFilter;

    const index = filter.choices.indexOf(selectedChoice);
    if (index === -1) {
      setFilter(prevFilter => ({
        ...prevFilter,
        choices: [...prevFilter.choices, selectedChoice],
      }));
    } else {
      setFilter(prevFilter => ({
        ...prevFilter,
        choices: prevFilter.choices.filter(choice => choice !== selectedChoice),
      }));
    }
  };

  const handleSelectAllChoice = () => {
    if (filter.choices.length === choices.length) {
      setFilter(prevFilter => ({
        ...prevFilter,
        choices: defaultApplicationControl.filter.choices,
      }));
    } else {
      setFilter(prevFilter => ({
        ...prevFilter,
        choices: choices.map(s => parseInt(s.value) as ChoiceFilter),
      }));
    }
  };

  const isSomeSelected = choices.some(choice =>
    filter.choices.includes(parseInt(choice.value) as ChoiceFilter)
  );

  return (
    <>
      <div className="max-h-96 space-y-2 overflow-y-auto">
        {choices.map(choice => {
          return (
            <CheckboxBase
              key={choice.value}
              name={choice.value}
              label={getChoice(parseInt(choice.value), 'choice')}
              onChange={handleChoiceChange}
              checked={
                filter.choices.findIndex(c => c === parseInt(choice.value)) >= 0
              }
            />
          );
        })}
      </div>
      <hr className="!my-4" />
      <CheckboxBase
        name="selectAllChoices"
        label="Select All"
        onChange={handleSelectAllChoice}
        checked={filter.choices.length === choices.length}
        indeterminate={isSomeSelected}
      />
    </>
  );
}

const choices: { label: string; value: string }[] = [
  { label: 'First Choice', value: '1' },
  { label: 'Second Choice', value: '2' },
  { label: 'Third Choice', value: '3' },
];

export { ChoicesFilter, choices };
