import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

type Tab = {
  title: string;
  fieldValue: any;
};

type FormTabButtonsProps = {
  tabs: Tab[];
  fieldName: string;
};

function FormTabButtons(props: FormTabButtonsProps) {
  const { fieldName, tabs } = props;
  const form = useFormContext();

  const isActiveValue = form.watch(fieldName);

  return (
    <div className="flex gap-1">
      {tabs.map(tab => (
        <button
          type="button"
          key={tab.title}
          onClick={() => form.setValue(fieldName, tab.fieldValue)}
          className={twMerge(
            'btn rounded-md px-3 py-2 shadow-none ',
            tab.fieldValue === isActiveValue
              ? 'bg-primary-50 text-primary-700'
              : 'text-gray-500'
          )}
        >
          {tab.title}
        </button>
      ))}
    </div>
  );
}

export { FormTabButtons, type Tab };
