import { areaService } from '@/services/area.service';
import { HttpError } from '@/types/error';
import { CreateAreaDto, Area } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCreateAreaMutation(
  mutationOptions?: UseMutationOptions<Area, HttpError, CreateAreaDto>
) {
  return useMutation({
    mutationFn: areaService.createArea,
    ...mutationOptions,
  });
}

export { useCreateAreaMutation };
