import { HttpError, isBadRequest } from '@/types/error';
import { getNestedKeys } from '@/utils/get-nested-keys';
import { Child, UpdateChildDto } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseSyntheticEvent } from 'react';
import { useForm } from 'react-hook-form';
import { makePaymentSchema } from './make-payment.schema';
import { MakePaymentFormData } from './make-payment.type';

type UseChildDetailsFormProps = {
  initialData?: Child;
  onSubmit: (data: UpdateChildDto) => Promise<Child>;
};

function transformApiDataToFormData(initialData?: Child): MakePaymentFormData {
  if (!initialData) {
    return {
      paymentOption: '',
      childCareReference: '',
      voucherProvider: '',
    };
  }

  return {
    paymentOption: '',
    childCareReference: initialData.extraHours.childCareReference,
    voucherProvider: initialData.extraHours.voucherProvider,
  };
}

function useMakePaymentForm(props: UseChildDetailsFormProps) {
  const { initialData, onSubmit } = props;

  const defaultValues: MakePaymentFormData =
    transformApiDataToFormData(initialData);

  const form = useForm({
    resolver: yupResolver(makePaymentSchema),
    defaultValues,
  });

  const submitHandler = async (data: MakePaymentFormData) => {
    if (data.paymentOption === 'EPAY_CAPITA') {
      window.open(
        'https://ip.e-paycapita.com/AIP/itemSelectionPage.do?link=showItemSelectionPage&siteId=171&languageCode=EN&source=AIP '
      );
      return;
    }

    try {
      const transformedDto: UpdateChildDto = {
        childCareReference: data.childCareReference || null,
        voucherProvider: data.voucherProvider || null,
      };
      await onSubmit(transformedDto);
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        const availableFields = getNestedKeys(form.getValues());

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      } else {
        form.setError('root.server', {
          message: Array.isArray(httpError.message)
            ? httpError.message[0]
            : httpError.message,
        });
      }
    }
  };

  const handleSubmit = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    await form.handleSubmit(submitHandler)();
  };

  return {
    form,
    handleSubmit,
    defaultValues,
  };
}

export { useMakePaymentForm };
