import { TextInput } from '@/components/form/common/text-input';

type EmailContactsSectionProps = {
  isDisabled: boolean;
};

function EmailContactsSection(props: EmailContactsSectionProps) {
  const { isDisabled } = props;
  return (
    <div className="two-col-form gap-6 py-6">
      <div>
        <p className="font-semibold text-gray-700">Email Contacts</p>{' '}
        <p className="text-gray-600">
          Specify email addresses to be used within email templates
        </p>
      </div>
      <div className="col-span-2 space-y-6">
        <div className="light-gray-container space-y-6">
          <div className="flex justify-between">
            <p className="font-semibold">Support Contact</p>
          </div>
          <TextInput
            name="contactEmail"
            label="Contact Email"
            placeholder="admin@"
            disabled={isDisabled}
          />
        </div>
      </div>
    </div>
  );
}

export { EmailContactsSection };
