import LoginShape from '@/assets/login-shape.svg?react';
import { usePublicOrganisation } from '@/hooks/query-hooks/use-public-organisation';
import { Navigate } from 'react-router-dom';
import { Logo } from '../logo';
import { PoweredBy } from '../powered-by';
import { Spinner } from '../spinner';

interface LoginLayoutProps {
  children?: React.ReactNode;
}

function LoginLayout({ children }: LoginLayoutProps) {
  const { isLoading, isError, isSuccess } = usePublicOrganisation();

  if (isError || (!isSuccess && !isLoading)) {
    return <Navigate to="/404" />;
  }

  return (
    <div className="flex min-h-full">
      <div className="relative flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-[420px] lg:w-[420px]">
          {!isLoading && (
            <div>
              <Logo className="h-10" />
            </div>
          )}

          <div className="mb-7">
            {isLoading ? (
              <div className="flex justify-center">
                <Spinner />
              </div>
            ) : (
              children
            )}
          </div>
          <div className="absolute bottom-0 left-0 right-0 flex justify-center pb-7">
            <PoweredBy />
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 items-end justify-end bg-[#F9F6EF] lg:flex">
        <LoginShape aria-hidden="true" className="pl-24 pt-24" />
      </div>
    </div>
  );
}

export { LoginLayout };
