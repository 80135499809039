import { ExtraHourResourceForm } from '@/reducers/extra-hour-resources.reducer';
import {
  ExtraHourResourceDay,
  ExtraHourResourceState,
} from '@/types/extra-hour-resource';
import {
  CreateExtraHourResourceDto,
  ExtraHourPublic,
  ExtraHourResource,
  ExtraHourResourceCapacity,
  ExtraHourResourceCapacityDto,
  OperatingDaySessionDto,
} from '@admissions-support/types';
import { format } from 'date-fns';
import { every, forEach, uniqBy } from 'lodash-es';
import { toHumanReadableNumber } from './general-utils';

/**
 * Checks if the resource capacities across different days are the same for each ratio ID.
 *
 * @example
 * const resourceCapacities = [
 *   {
 *     capacities: [
 *       { capacity: 10, ratioId: 'uuid' },
 *       { capacity: 15, ratioId: 'uuid' },
 *     ],
 *   },
 *   {
 *     capacities: [
 *       { capacity: 10, ratioId: 'uuid' },
 *       { capacity: 15, ratioId: 'uuid' },
 *     ],
 *   },
 * ];
 * const result = areResourceCapacitiesTheSame(resourceCapacities);
 * console.log(result); // Output: true (resource capacities are the same for each ratio ID)
 */
function areResourceCapacitiesTheSame(
  resourceCapacities?: ExtraHourResourceDay[]
) {
  if (!resourceCapacities || resourceCapacities.length === 0) {
    return true;
  }

  const capacityNumberReference = resourceCapacities[0].capacities;

  const flattenedCapacities = resourceCapacities.flatMap(resourceDay =>
    resourceDay.capacities.map(capacityObj => ({
      capacity: capacityObj.capacity,
      ratioId: capacityObj.ratioId,
    }))
  );

  function findReferenceCapacityForRatioId(ratioId: string): number | null {
    const resourceItem = capacityNumberReference.find(
      item => item.ratioId === ratioId
    );

    if (!resourceItem) {
      return null;
    }

    return resourceItem.capacity;
  }
  return flattenedCapacities.every(
    rc => rc.capacity === findReferenceCapacityForRatioId(rc.ratioId)
  );
}

function transformInitialExtraHourResourceValue(
  ratioIds: string[],
  extraHourResource?: ExtraHourResource
): Partial<ExtraHourResourceForm> | undefined {
  if (!extraHourResource) {
    return undefined;
  }

  const stateResources: ExtraHourResourceState[] = ratioIds.map(ratioId => {
    const resources = transformResourceDayCapacityToArray(
      extraHourResource.capacities
    ).map(resource => {
      return {
        day: resource.day as keyof OperatingDaySessionDto,
        capacity:
          resource.capacities.find(cap => cap.ratioId === ratioId)?.capacity ||
          0,
      };
    });

    const hasSameCapacityForEachDay =
      uniqBy(resources, 'capacity').length === 0;

    return {
      ratioId,
      hasSameCapacityForEachDay: hasSameCapacityForEachDay,
      capacityDays: resources,
    };
  });

  return {
    sessionTypeIds: extraHourResource.sessionTypes.map(sessionType =>
      sessionType.id.toString()
    ),
    resources: stateResources,
  };
}

/**
 * Transforms a resource capacity object into an array of ResourceDay objects.
 *
 * @example
 * const resourceCapacity = {
 *   Monday: [
 *     { capacity: 10, ratio: { id: 'uuid' }, allocated: 5 },
 *     { capacity: 15, ratio: { id: 'uuid' }, allocated: 3 },
 *   ],
 *   Tuesday: [
 *     { capacity: 20, ratio: { id: 'uuid' }, allocated: 2 },
 *   ],
 * };
 * const result = transformResourceDayCapacityToArray(resourceCapacity);
 * console.log(result);
 * // Output: [
 * //   { day: 'Monday', capacities: [{ capacity: 10, ratioId: 'uuid', allocated: 5 }, { capacity: 15, ratioId: 'uuid', allocated: 3 }] },
 * //   { day: 'Tuesday', capacities: [{ capacity: 20, ratioId: 'uuid', allocated: 2 }] }
 * // ]
 */
function transformResourceDayCapacityToArray(
  resourceCapacity?: ExtraHourResourceCapacity
): ExtraHourResourceDay[] {
  if (!resourceCapacity) {
    return [];
  }

  const operatingDays = Object.keys(resourceCapacity).filter(
    day => resourceCapacity[day as keyof ExtraHourResourceCapacity].length > 0
  );

  return operatingDays
    .map(day => {
      // need unique, because on the backend we store one ratio multiple times (for each ratio-sessiontype combination)
      const currentResourceCapacity = uniqBy(
        (resourceCapacity as ExtraHourResourceCapacity)[
          day as keyof ExtraHourResourceCapacity
        ],
        'ratioId'
      );

      return {
        day,
        capacities: currentResourceCapacity.map(item => {
          return {
            capacity: item.capacity,
            ratioId: item.ratioId.toString(),
          };
        }),
      };
    })
    .flat();
}

function getCapacityRangeForRatio(
  ratioId: string,
  extraHourResourceDays: ExtraHourResourceDay[]
): { min: number; max: number } {
  const capacityList: number[] = [];

  extraHourResourceDays.forEach(day =>
    day.capacities.map(capacity => {
      if (capacity.ratioId === ratioId) {
        capacityList.push(capacity.capacity || 0);
      }
    })
  );

  return {
    min: Math.min(...capacityList),
    max: Math.max(...capacityList),
  };
}

/**
 * Transforms ExtraHourResourceForm data into a CreateResourceDto for backend API consumption.
 */
function transformLocationResourceFormDataToDto(
  state: ExtraHourResourceForm
): CreateExtraHourResourceDto {
  const resourceCapacityDto = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  } as ExtraHourResourceCapacityDto;

  state.resources.forEach(resource => {
    resource.capacityDays.forEach(capacityDay => {
      resourceCapacityDto[capacityDay.day].push({
        capacity: capacityDay.capacity || 0,
        ratioId: resource.ratioId,
      });
    });
  });

  return {
    sessionTypeIds: state.sessionTypeIds,
    capacities: resourceCapacityDto,
  };
}

// Written by chatGPT
function allRatiosHaveConsistentCapacities(
  capacities: ExtraHourResourceCapacity
): boolean {
  // Initialize an object to store each ratio and its capacities across different days
  const ratiosCapacities: Record<string, (number | null)[]> = {};

  // Collect all capacities for each ratio across all days
  forEach(capacities, day => {
    day.forEach(entry => {
      if (!ratiosCapacities[entry.ratioId.toString()]) {
        ratiosCapacities[entry.ratioId.toString()] = [];
      }
      ratiosCapacities[entry.ratioId.toString()].push(entry.capacity);
    });
  });

  // Check if all capacities for each ratio are the same across all days
  return every(ratiosCapacities, capacities => {
    return every(capacities, capacity => capacity === capacities[0]);
  });
}

function getAllocatedCapacityForAllDay(
  ratioId: string,
  filteredExtraHours: ExtraHourPublic[]
) {
  const filteredArray = filteredExtraHours.filter(
    extraHour => extraHour.ratio.id.toString() === ratioId
  );

  const allocatedRange = filteredArray.map(
    extraHour => extraHour.allocatedCapacity
  );

  const capacityRange = filteredArray.map(extraHour => extraHour.capacity || 0);

  const maxAllocated = toHumanReadableNumber(Math.max(...allocatedRange));

  const minAllocated = toHumanReadableNumber(Math.min(...allocatedRange));

  const maxCapacity = toHumanReadableNumber(Math.max(...capacityRange));

  const minCapacity = toHumanReadableNumber(Math.min(...capacityRange));

  return {
    allocated: { min: minAllocated, max: maxAllocated },
    capacities: { min: minCapacity, max: maxCapacity },
  };
}

function getAllocatedCapacityForDay(
  filteredExtraHours: ExtraHourPublic[],
  ratioId: string,
  day: string
) {
  const allocatedAndCapacityArray = filteredExtraHours
    .filter(
      extraHour =>
        extraHour.ratio.id.toString() === ratioId &&
        format(extraHour.date, 'EEEE').toLowerCase() === day
    )
    .map(extraHour => ({
      allocated: extraHour.allocatedCapacity,
      capacity: extraHour.capacity,
    }));

  const maxAllocated = toHumanReadableNumber(
    Math.max(...allocatedAndCapacityArray.map(item => item.allocated))
  );

  const minAllocated = toHumanReadableNumber(
    Math.min(...allocatedAndCapacityArray.map(item => item.allocated))
  );

  return { min: minAllocated, max: maxAllocated };
}

export {
  allRatiosHaveConsistentCapacities,
  areResourceCapacitiesTheSame,
  getAllocatedCapacityForAllDay,
  getAllocatedCapacityForDay,
  getCapacityRangeForRatio,
  toHumanReadableNumber,
  transformInitialExtraHourResourceValue,
  transformLocationResourceFormDataToDto,
  transformResourceDayCapacityToArray,
};
