import { EligibilityForm } from '@/components/application/eligibility/eligibility-form';
import { PageTitle } from '@/components/page-title';
import { staffRouterPath } from '@/config/route-paths.config';
import { useNavigate, useParams } from 'react-router-dom';

function ApplicationEligibility() {
  const { childId } = useParams<{ childId: string }>();
  const navigate = useNavigate();

  return (
    <>
      <PageTitle
        title="Select Starting Date"
        variant="gray"
        className="top-0 z-10 mt-[52px] py-3 lg:sticky lg:mt-0 lg:py-5"
      >
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => navigate(staffRouterPath.APPLICATIONS)}
        >
          Cancel
        </button>
      </PageTitle>
      <div className="two-col-form">
        <div></div>
        <div>
          <EligibilityForm childId={childId || ''} />
        </div>
      </div>
    </>
  );
}

export { ApplicationEligibility };
