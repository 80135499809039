import { OperatingDays } from '@admissions-support/types';

export function formatOperatingDays(operatingDays: OperatingDays) {
  const daysOfWeek: (keyof OperatingDays)[] = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  const readableDaysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const result: string[] = [];
  let startRange: number | null = null;

  daysOfWeek.forEach((day, i) => {
    if (operatingDays[day]) {
      if (startRange === null) {
        startRange = i;
      }
      // Handle consecutive days
      if (i < daysOfWeek.length - 1 && operatingDays[daysOfWeek[i + 1]]) {
        return;
      }
      // Handle single day or range
      if (startRange === i) {
        result.push(readableDaysOfWeek[startRange]);
      } else {
        result.push(
          `${readableDaysOfWeek[startRange]}-${readableDaysOfWeek[i]}`
        );
      }
      startRange = null;
    }
  });

  return result.join(', ');
}
