import { useSchoolYear } from '@/context/school-year.context';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { updateProductPriceSchema } from './update-product-price.schema';
import { UpdateProductPriceFormData } from './update-product-price.type';

const defaultValues: UpdateProductPriceFormData = {
  price: NaN,
  availableFrom: '',
  availableTo: '',
};

function useUpdateProductPriceForm() {
  const { schoolYear } = useSchoolYear();

  const form = useForm({
    resolver: yupResolver(updateProductPriceSchema),
    context: { schoolYear },
    defaultValues,
  });

  useEffect(() => {
    form.reset();
  }, [form]);

  return {
    form,
  };
}

export { useUpdateProductPriceForm };
