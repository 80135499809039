import { ChildPermission } from '@admissions-support/types';

export function hasManageApplicationsPermission(
  childPermission: ChildPermission
): boolean {
  if (childPermission.role === 'parent') {
    return true;
  }
  return childPermission.permissions.includes('MANAGE_APPLICATIONS');
}

export function hasManageTrusteesPermission(
  childPermission: ChildPermission
): boolean {
  if (childPermission.role === 'parent') {
    return true;
  }
  return false;
}

export function hasManageExtraHoursPermission(
  childPermission: ChildPermission
): boolean {
  if (childPermission.role === 'parent') {
    return true;
  }
  return childPermission.permissions.includes('PURCHASE_EXTRA_HOURS');
}
