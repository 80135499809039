import { useAuth } from '@/context/auth.context';
import { Navigate, useSearchParams } from 'react-router-dom';
import { LoadingScreen } from '../loading-screen';
import { isAdminDashboard } from '@/utils/is-dashboard';
import { parentRouterPath, staffRouterPath } from '@/config/route-paths.config';

type AuthGuardProps = {
  children?: React.ReactNode;
};

function AuthGuard({ children }: AuthGuardProps) {
  const { isLoggedIn, isLoading, user } = useAuth();
  const [searchParams] = useSearchParams();
  //if we have this code defined in the search params, user was redirected from mygovscot to admit
  const mygovscotRedirectCode = searchParams.get('code');

  const getRedirectToParam = () => {
    if (isAdminDashboard()) {
      return staffRouterPath.SIGN_IN;
    }
    if (!mygovscotRedirectCode) {
      return parentRouterPath.SIGN_IN;
    }
    return parentRouterPath.WELCOME_AUTH;
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isLoggedIn || !user) {
    return (
      <Navigate
        // navigate parent flow to welcome screen as entry point
        to={getRedirectToParam()}
        replace
      />
    );
  }

  // user with no MFA set up technically logged in, but cannot do anything until 2FA set up
  if (!user.preferredMFA && user.isMFARequired) {
    return <Navigate to="/auth/mfa-setup" replace />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export { AuthGuard };
