import { childService } from '@/services/child.service';
import { HttpError } from '@/types/error';
import { Child, CreateChildDto } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCreateChildMutation(
  mutationOptions?: UseMutationOptions<Child, HttpError, CreateChildDto>
) {
  return useMutation({
    mutationFn: childService.createChild,
    ...mutationOptions,
  });
}

export { useCreateChildMutation };
