import { ExtraHoursBookingsFilter } from '@/types/extra-hours-bookings-control';
import { PaginationState, SortingState } from '@tanstack/react-table';

function generateQueryParamsForChildrenBookings(
  schoolYearId: string,
  {
    pagination,
    sorting,
    filter,
  }: {
    pagination: PaginationState;
    sorting: SortingState;
    filter: ExtraHoursBookingsFilter;
  }
): string[] {
  const queryParams = [
    `schoolYearId=${schoolYearId}`,
    `page=${(Number(pagination?.pageIndex) ?? 0) + 1}`,
    `limit=${pagination?.pageSize ? pagination.pageSize : 10}`,
  ];

  if (sorting.length > 0) {
    const sortingBy = sorting[0];

    queryParams.push(`sortBy=${sortingBy.id}`);
    queryParams.push(`orderBy=${sortingBy.desc ? 'desc' : 'asc'}`);
  }

  if (filter.status) {
    queryParams.push(`billingStatus=${filter.status}`);
  }

  if (filter.search.trim() !== '') {
    queryParams.push(`q=${filter.search}`);
  }

  return queryParams;
}

export { generateQueryParamsForChildrenBookings };
