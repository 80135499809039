import { SessionTypeExtendedReference } from '@admissions-support/types';
import { flatten } from 'lodash-es';

function getAdditionalSessionTypeAllowanceLabel(
  additionalSessionTypes: SessionTypeExtendedReference[][]
): string {
  //We can do this because we currently dont allow AND between additional session types.
  const flattenSessionTypes = flatten(additionalSessionTypes);

  const distinctAllowances = new Set();

  flattenSessionTypes.forEach(additionalSessionType =>
    distinctAllowances.add(additionalSessionType.allowance)
  );

  const allowances = Array.from(distinctAllowances);

  if (allowances.length < 1) {
    return '';
  }

  if (allowances.length === 1) {
    return ` + 1 additional session`;
  }

  return ` + ${allowances.join(' or ')} additional sesssions`;
}

export { getAdditionalSessionTypeAllowanceLabel };
