import { FallbackComponent } from '@/components/fallback-component';
import { CheckboxBase } from '@/components/form/common/checkbox';
import { LoadingScreen } from '@/components/loading-screen';
import { parentRouterPath } from '@/config/route-paths.config';
import { useCreateEmptyApplication } from '@/hooks/create-hooks/use-create-empty-application';
import { useApplicationTemplates } from '@/hooks/query-hooks/use-application-templates';
import { ArrowRight } from '@untitled-ui/icons-react';
import { useState } from 'react';
import { generatePath, Navigate, useSearchParams } from 'react-router-dom';

function EligibilityConfirmForm() {
  const [searchParams] = useSearchParams({
    schoolYearId: '',
    childIdentificationNumber: '',
    templateId: '',
  });
  const [selectedFields, setSelectedFields] = useState<string[]>([]);

  const {
    mutateAsync: createApplication,
    isPending: isApplicationMutating,
    isSuccess: isApplicationCreated,
    data: createdApplication,
  } = useCreateEmptyApplication();

  const handleNewApplication = () => {
    createApplication({
      schoolYearId: searchParams.get('schoolYearId') || '',
      identificationNumber: searchParams.get('childIdentificationNumber') || '',
      templateId: searchParams.get('templateId') || '',
    });
  };

  const {
    data: applicationTemplates,
    isSuccess,
    isLoading,
  } = useApplicationTemplates({
    select: data =>
      data.filter(template => template.id === searchParams.get('templateId')),
  });

  if (isApplicationCreated) {
    return (
      <Navigate
        to={generatePath(parentRouterPath.APPLICATION_CHILD_DETAILS, {
          id: createdApplication.id,
        })}
      />
    );
  }

  const toggleSelectedField = (field: string) => {
    const isInCurrently = selectedFields.includes(field);

    if (isInCurrently) {
      setSelectedFields(prev => prev.filter(f => f !== field));
    } else {
      setSelectedFields(prev => [...prev, field]);
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isSuccess || applicationTemplates.length < 1) {
    return <FallbackComponent />;
  }

  const template = applicationTemplates[0];

  return (
    <form className="rounded-md border px-4 py-3">
      <p className="text-md mb-4 font-semibold">Additional Eligibility</p>
      <div className="space-y-3">
        {template.criteriaRequirement === 'all' ? (
          <>
            {template.criteria.map((c, index) => (
              <p key={`criteria-${index}`} className="text-sm">
                {c.title}
              </p>
            ))}
            <CheckboxBase
              name="confirm-all"
              label="I confirm that ALL of these statements apply"
              onChange={() => toggleSelectedField('confirm-all')}
              checked={selectedFields.includes('confirm-all')}
            />
          </>
        ) : (
          <>
            {template.criteria.map((c, index) => (
              <CheckboxBase
                key={`criteria-${index}`}
                name={`criteria-${index}`}
                label={c.title}
                onChange={() => toggleSelectedField(`criteria-${index}`)}
                checked={selectedFields.includes(`criteria-${index}`)}
              />
            ))}
          </>
        )}
      </div>

      <button
        className="btn btn-primary mt-8 flex items-center"
        type="button"
        disabled={isApplicationMutating || selectedFields.length < 1}
        onClick={handleNewApplication}
      >
        Continue with Application
        <ArrowRight viewBox="0 0 24 24" className="ml-2 h-5 w-5" />
      </button>
    </form>
  );
}

export { EligibilityConfirmForm };
