import { locationKey } from '@/config/query-keys';
import { locationService } from '@/services/location.service';
import { HttpError } from '@/types/error';
import { School } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useSchools(queryOptions?: UseQueryOptions<School[], HttpError>) {
  return useQuery({
    queryKey: locationKey.schools,
    queryFn: locationService.getSchools,
    ...queryOptions,
  });
}

export { useSchools };
