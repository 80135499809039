import { locationKey } from '@/config/query-keys';
import { useSchoolYear } from '@/context/school-year.context';
import { locationService } from '@/services/location.service';
import { HttpError } from '@/types/error';
import { Operation } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useLocationOperation(
  locationId?: string,
  queryOptions?: UseQueryOptions<Operation, HttpError>
) {
  const { schoolYear } = useSchoolYear();

  return useQuery({
    queryKey: locationKey.operation(locationId || '', schoolYear.id),
    queryFn: () =>
      locationService.getOperation({
        locationId: locationId || '',
        termId: schoolYear.id,
      }),
    ...queryOptions,
    enabled: !!locationId,
  });
}

export { useLocationOperation };
