import { Tag } from '@/components/tag';
import { useCapacitySimulation } from '@/context/capacity-simulation.context';

type UnpublishedChangeTagProps = {
  locationId: string;
};

function UnpublishedChangeTag({ locationId }: UnpublishedChangeTagProps) {
  const { simulatedCapacity } = useCapacitySimulation();

  const hasLocationSimulatedCapacity = simulatedCapacity.some(
    sc => sc.locationId === locationId
  );

  if (!hasLocationSimulatedCapacity) {
    return null;
  }

  return <Tag type="warning">Unpublished Changes</Tag>;
}

export { UnpublishedChangeTag };
