import { TextInput } from '@/components/form/common/text-input';
import { Toggle } from '@/components/form/common/toggle';
import { Plus } from '@untitled-ui/icons-react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { SiblingItem } from './sibling-item';
import { defaultSibling } from './use-household.form';

type HomeLanguageProps = {
  disabled?: boolean;
};

function HomeLanguage(props: HomeLanguageProps) {
  const { disabled } = props;

  return (
    <>
      <div>
        <p className="text-md font-medium leading-7 text-gray-900">
          Home Language
        </p>
      </div>
      <div className="col-span-2">
        <TextInput
          name="homeLanguage"
          type="text"
          label="What language do you normally speak at home?*"
          disabled={disabled}
          placeholder="Home Language"
        />
      </div>
    </>
  );
}

type SiblingsProps = { disabled?: boolean };

function Siblings(props: SiblingsProps) {
  const { disabled } = props;
  const { control, setValue } = useFormContext();
  const {
    append: addSibling,
    fields: siblings,
    remove: removeSibling,
  } = useFieldArray({
    control: control,
    name: 'siblings',
  });

  const onToggle = () => {
    if (siblings.length > 0) {
      setValue('siblings', []);
      return;
    }
    addSibling(defaultSibling);
  };

  return (
    <>
      <div>
        <p className="text-md font-medium leading-7 text-gray-900">
          Other Children in the Household
        </p>
        <p className="text-md leading-6 text-gray-600">
          Children are aged 16 and under.
        </p>
      </div>

      <div className="col-span-2 space-y-6">
        <Toggle
          value={siblings.length > 0}
          label="Are the other children who live with the child?"
          description="If yes, Please fill in the details of each other child living at the address."
          onChange={onToggle}
          disabled={disabled}
        />

        {siblings.map((_sibling, index) => {
          return (
            <SiblingItem
              key={index}
              index={index}
              disabled={disabled}
              onDelete={() => removeSibling(index)}
            />
          );
        })}
        {siblings.length > 0 && (
          <div>
            <button
              type="button"
              className="btn btn-secondary flex items-center"
              onClick={() => addSibling(defaultSibling)}
              disabled={disabled}
            >
              <Plus
                className="h-5 w-5"
                viewBox="0 0 24 24"
                aria-hidden="true"
              />
              Add another Child
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export { HomeLanguage, Siblings };
