import { twMerge } from 'tailwind-merge';
import { Tag } from './tag';

type PaymentTagProps = {
  children: React.ReactNode;
  dotColor: string;
};

function StatusTag(props: PaymentTagProps) {
  const { children, dotColor } = props;

  return (
    <Tag
      type="outline"
      className="inline-flex w-auto flex-row items-center px-1.5 py-0.5 text-xs font-normal capitalize shadow-xs"
    >
      {dotColor ? (
        <span
          className={twMerge(
            'mr-1 inline-block h-1.5 w-1.5 rounded-full',
            dotColor
          )}
        />
      ) : null}
      {children}
    </Tag>
  );
}

export { StatusTag };
