import { staffRouterPath } from '@/config/route-paths.config';
import { useAuth } from '@/context/auth.context';
import { Navigate } from 'react-router-dom';
import { LoadingScreen } from '../loading-screen';

type GuestGuardProps = {
  children?: React.ReactNode;
};

function GuestGuard({ children }: GuestGuardProps) {
  const { isLoggedIn, isLoading } = useAuth();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isLoggedIn) {
    return <Navigate to={staffRouterPath.ROOT} replace />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export { GuestGuard };
