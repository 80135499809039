import { Select } from '@/components/form/common/select';
import { Textarea } from '@/components/form/common/textarea';

type PreSchoolProps = {
  locations: { key: string; value: string }[];
  disabled?: boolean;
};

function PreSchool(props: PreSchoolProps) {
  const { disabled, locations } = props;

  return (
    <>
      <div>
        <p className="text-md leading-6 text-gray-600">
          Please give details of any other type of pre-school your child goes to
          or has gone to.
        </p>
      </div>
      <div className="space-y-4">
        <Select
          label="Current Nursery attended, if applicable"
          options={[
            { key: '', value: 'Select current nursery attended...' },
            ...locations,
          ]}
          name="currentLocationId"
          disabled={disabled}
        />
        <Textarea
          name="currentLocationDescription"
          label="Details"
          disabled={disabled}
          placeholder="Enter details of other pre-school your child has attended"
          rows={3}
        />
      </div>
    </>
  );
}

export { PreSchool };
