import { PermissionGuard } from '@/components/auth/permission-guard';
import { PageTitle } from '@/components/page-title';
import { Table } from '@/components/table';
import { staffRouterPath } from '@/config/route-paths.config';
import { useUserGroups } from '@/hooks/query-hooks/use-user-groups';
import { Permission } from '@/types/auth';
import { getErrorMessage } from '@/utils/get-error-message';
import { UserGroup } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import { Edit01 } from '@untitled-ui/icons-react';
import { useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

function UserGroups() {
  const navigate = useNavigate();
  const { isLoading, data: userGroups, error } = useUserGroups();

  const columnHelper = createColumnHelper<UserGroup>();

  const handleEdit = (id: string) => () => {
    const redirectUrl = generatePath(staffRouterPath.EDIT_USER_GROUP, {
      id,
    });
    navigate(redirectUrl);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        cell: info => <span className="font-medium">{info.getValue()}</span>,
        header: () => <span>Name</span>,
        enableSorting: true,
        size: 200,
      }),
      columnHelper.accessor('description', {
        id: 'description',
        cell: info => info.getValue(),
        header: () => <span>Description</span>,
        enableSorting: true,
      }),
      columnHelper.display({
        id: 'id',
        cell: info => (
          <PermissionGuard
            requiredPermissions={[Permission['organisation:user-groups:fetch']]}
          >
            <button
              type="button"
              className="icon-link"
              onClick={handleEdit(info.row.original.id)}
            >
              <Edit01
                className="h-5 w-5"
                viewBox="0 0 24 24"
                aria-hidden="true"
              />
              <span className="sr-only">Edit user group</span>
            </button>
          </PermissionGuard>
        ),
        size: 72,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleNavigateToNewUserGroup = () => {
    navigate(staffRouterPath.NEW_USER_GROUP);
  };

  return (
    <div>
      <PageTitle
        title="User Groups"
        description="Manage user groups and their permissions"
        variant="white"
      >
        <PermissionGuard
          requiredPermissions={[Permission['organisation:user-groups:create']]}
        >
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleNavigateToNewUserGroup}
          >
            Add User Group
          </button>
        </PermissionGuard>
      </PageTitle>

      <Table
        columns={columns}
        data={userGroups}
        paginationType="auto"
        isLoading={isLoading}
        error={getErrorMessage(error)}
      />
    </div>
  );
}

export { UserGroups };
