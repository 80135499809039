import { locationKey } from '@/config/query-keys';
import { locationService } from '@/services/location.service';
import { HttpError } from '@/types/error';
import { Resource, UpdateResourceDto } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateLocationResource(
  mutationOptions?: UseMutationOptions<
    Resource,
    HttpError,
    { data: UpdateResourceDto; operationId: string; resourceId: string }
  >
) {
  return useMutation({
    mutationKey: locationKey.updateLocationResource,
    mutationFn: ({ data, operationId, resourceId }) =>
      locationService.updateLocationResource({
        operationId,
        resourceId,
        data,
      }),
    ...mutationOptions,
  });
}

export { useUpdateLocationResource };
