import { DayObject } from '@/utils/location-utils';
import { BookingSummary, ExtraHourPublic } from '@admissions-support/types';
import { isSameDay } from 'date-fns';
import { uniqBy } from 'lodash-es';
import { twMerge } from 'tailwind-merge';

type CalendarDayButtonProps = {
  day: DayObject;
  onSelectDay: (newVal: string) => any;
  extraHours?: ExtraHourPublic[];
  bookings?: BookingSummary[];
  selectedDay?: string;
};

function CalendarDayButton(props: CalendarDayButtonProps) {
  const { day, extraHours, selectedDay, onSelectDay, bookings } = props;

  const extraHoursForCurrentDay = uniqBy(
    extraHours?.filter(extraHour =>
      isSameDay(new Date(day.date), new Date(extraHour.date))
    ) || [],
    'product.id'
  );

  const bookingsForCurrentDay =
    bookings?.filter(booking =>
      isSameDay(new Date(day.date), new Date(booking.date))
    ) || [];

  const greenDateFrameClassesForHover =
    'group-hover:rounded-xl group-hover:border group-hover:border-green-100 group-hover:bg-green-50 group-hover:text-primary-600';

  return (
    <button
      key={day.date}
      className={twMerge(
        day.isCurrentMonth
          ? 'group bg-white hover:bg-gray-25 hover:text-gray-500'
          : 'cursor-not-allowed bg-gray-50 text-gray-500',
        'relative flex h-[150px] flex-col items-start px-3 py-2'
      )}
      disabled={!day.isCurrentMonth}
      onClick={() => onSelectDay(day.date)}
    >
      <time
        dateTime={day.date}
        className={twMerge(
          'mb-2 flex h-10 w-10 flex-col items-center justify-center text-sm text-gray-700',
          greenDateFrameClassesForHover,
          selectedDay === day.date
            ? 'rounded-xl border border-green-100 bg-green-50 text-primary-600'
            : ''
        )}
      >
        {new Date(day.date).getDate()}
        {bookingsForCurrentDay.length > 0 ? (
          <div className="h-[5px] w-[5px] rounded-full bg-green-600" />
        ) : null}
      </time>
      {extraHoursForCurrentDay.length > 0 && (
        <>
          <span className="sr-only">
            {extraHoursForCurrentDay.length} products
          </span>
          <span className="flex w-full flex-col gap-2">
            {extraHoursForCurrentDay.map((extraHour, idx) => {
              const product = (
                <div
                  key={idx}
                  className="flex w-full text-sm font-medium text-gray-900"
                >
                  <p className="w-2/3 truncate text-left">
                    {extraHour.product.name}
                  </p>
                  {/* <p className="w-1/3 font-normal text-gray-600">
                    {extraHour.capacity}
                  </p> */}
                </div>
              );
              if (extraHoursForCurrentDay.length >= 3) {
                if (idx === 0) {
                  return product;
                }
                if (idx === 1) {
                  return (
                    <div
                      key={idx}
                      className="flex gap-2 text-sm font-medium text-gray-900"
                    >
                      +{extraHoursForCurrentDay.length} Products
                    </div>
                  );
                }
                return;
              }
              return product;
            })}
          </span>
        </>
      )}
    </button>
  );
}

export { CalendarDayButton };
