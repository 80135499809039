import { childKey } from '@/config/query-keys';
import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import { Cohort } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

/**
 *
 * Fetches all Cohort for an organisation
 */
function useCohorts(queryOptions?: UseQueryOptions<Cohort[], HttpError>) {
  return useQuery({
    queryKey: childKey.list,
    queryFn: organisationService.listCohorts,
    ...queryOptions,
  });
}

export { useCohorts };
