import { applicationTemplateKey } from '@/config/query-keys';
import { applicationTemplateService } from '@/services/application-template.service';
import { HttpError } from '@/types/error';
import {
  ApplicationTemplate,
  CreateApplicationTemplateDto,
} from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCreateApplicationTemplateMutation(
  mutationOptions?: UseMutationOptions<
    ApplicationTemplate,
    HttpError,
    CreateApplicationTemplateDto
  >
) {
  return useMutation({
    mutationKey: applicationTemplateKey.create,
    mutationFn: applicationTemplateService.create,
    ...mutationOptions,
  });
}

export { useCreateApplicationTemplateMutation };
