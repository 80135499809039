import { format, isAfter, isBefore, isSameDay, startOfDay } from 'date-fns';
import * as yup from 'yup';

const newBookingSchema = yup.object({
  locationId: yup.string().required().label('Location'),
  from: yup
    .string()
    .required()
    .test(
      'availableFromDate',
      'Available from date must be before available to date',
      (value, context) => {
        if (context.parent.range === 'one-day') {
          return true;
        }

        return value
          ? isBefore(new Date(value), new Date(context.parent.to))
          : false;
      }
    )
    .test(
      'greaterThanEligibility',
      'You are not yet elibible from this date!',
      function test(value) {
        const { startDate } = this.options.context as {
          startDate: string;
          endDate: string;
        };

        return isAfter(startOfDay(value), startOfDay(startDate)) ||
          isSameDay(startOfDay(value), startOfDay(startDate))
          ? true
          : this.createError({
              message: `You are eligible only from ${format(
                startDate,
                'dd/MM/yyyy'
              )}`,
              path: 'from',
            });
      }
    )
    .label('Date From'),
  to: yup
    .string()
    .test(
      'lessThanEndDate',
      'Your school year has ended!',
      function test(value) {
        const { endDate } = this.options.context as {
          endDate: string;
        };

        if (this.parent.range === 'longer' && !endDate) {
          return this.createError({
            message: 'Day to is required field',
            path: 'to',
          });
        }

        if (!value) {
          return true;
        }

        return isBefore(startOfDay(value), startOfDay(endDate)) ||
          isSameDay(startOfDay(value), startOfDay(endDate))
          ? true
          : this.createError({
              message: `Your school year ends at ${format(
                endDate,
                'dd/MM/yyyy'
              )}`,
              path: 'to',
            });
      }
    )
    .label('Day To'),
  days: yup
    .array()
    .of(yup.string())
    .label('Selected Days')
    .when('range', ([range], schema) => {
      if (range === 'longer') {
        return schema.min(1).required();
      }
      return schema;
    }),
  range: yup.string().required().label('Range'),
});

export { newBookingSchema };
