import { TextInput } from '@/components/form/common/text-input';
import { HttpError, isBadRequest } from '@/types/error';
import { getNestedKeys } from '@/utils/get-nested-keys';
import { Cohort, CreateCohortDto } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Checkbox } from './common/checkbox';

const schema = yup.object({
  name: yup.string().required().label('Name'),
  isUsedForIntake: yup.boolean().required().label('Used for Intakes'),
});

type CohortFormProps = {
  onSubmit: (data: CreateCohortDto) => Promise<void>;
  initialData?: Cohort;
  isLoading?: boolean;
};

function CohortForm(props: CohortFormProps) {
  const { initialData, isLoading, onSubmit } = props;

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: initialData?.name || '',
      isUsedForIntake: initialData?.isUsedForIntake || false,
    },
  });

  const submitHandler = async (data: CreateCohortDto) => {
    try {
      await onSubmit(data);
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        if (!form.formState.defaultValues) {
          return;
        }

        const availableFields = getNestedKeys(form.formState.defaultValues);

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }
    }
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(submitHandler)}
        className="flex h-full flex-1 flex-col"
        id="upsert-cohort-form"
      >
        <div className="mt-6 bg-white pt-6">
          <div className="two-col-form pt-0">
            <h2 className="text-md font-medium leading-7 text-gray-900">
              Cohort Details
            </h2>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-12">
                <div className="space-y-6">
                  <TextInput
                    name="name"
                    type="text"
                    label="Cohort Name*"
                    placeholder="Ante Pre School"
                    disabled={isLoading}
                  />
                  <Checkbox
                    label="Uses Intakes"
                    helperText="Does this cohort use intakes to determine eligibility?"
                    name="isUsedForIntake"
                    disabled={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export { CohortForm };
