import * as yup from 'yup';

const updateTrusteeSchema = yup.object({
  relationship: yup
    .string()
    .label('Relationship')
    .required('Relationship is required'),
  canPurchaseExtraHours: yup.boolean().label('Can Purchase Extra Hours'),
  canManageApplications: yup.boolean().label('Can Manage Applications'),
});

type UpdateTrusteeFormData = yup.InferType<typeof updateTrusteeSchema>;

export { updateTrusteeSchema };
export type { UpdateTrusteeFormData };
