import { getChoiceName } from '@/components/application-quick-view-drawer';
import { useSchoolYear } from '@/context/school-year.context';
import { transformScore } from '@/utils/application-utils';
import {
  findLeafPaths,
  formatChangeLogValue,
  transformPathToReadableText,
} from '@/utils/changelog-utils';
import { Log } from '@admissions-support/types';
import { Disclosure as HeadlessUIDisclosure } from '@headlessui/react';
import { ChevronDown } from '@untitled-ui/icons-react';
import { format, formatDistance } from 'date-fns';
import { get, startCase } from 'lodash-es';
import { twMerge } from 'tailwind-merge';
import { ChoiceStatusTag } from '../choice-status-tag';
import { LocationChoice } from '../location-choice';

type ChangelogItemProps = {
  data: Log;
  isLast?: boolean;
};

function ChangelogItem(props: ChangelogItemProps) {
  const { data, isLast } = props;
  const { schoolYear } = useSchoolYear();
  const changedValues = findLeafPaths(data.newValue);
  const isNote = data.title === 'Note Updated';
  const isApplicationUpdated = data.title === 'Application updated';
  const isChoiceUpdated = data.title === 'Choice updated';

  // because its a lot of content, we want to display it as Disclosure
  if (isNote || isApplicationUpdated || isChoiceUpdated) {
    return (
      <HeadlessUIDisclosure as="div" className="relative flex gap-x-4">
        {({ open }) => (
          <>
            <div
              className={twMerge(
                'absolute left-0 top-0 flex w-6 justify-center',
                isLast ? 'h-6' : '-bottom-6'
              )}
            >
              <div className="w-0.5 bg-gray-500" />
            </div>

            <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
              <div className="h-2 w-2 rounded-full bg-gray-500" />
            </div>

            <div className="flex-auto">
              <HeadlessUIDisclosure.Button
                className="flex-auto cursor-pointer"
                as="div"
              >
                <div className="flex justify-between">
                  <p className="py-0.5 text-sm font-bold leading-5 text-gray-700">
                    <span className="font-normal text-gray-900">
                      {data.user?.name}
                    </span>{' '}
                    {data.title}
                  </p>

                  <div className="flex items-center">
                    <time
                      dateTime={format(
                        new Date(data.timestamp),
                        'yyyy-MM-dd HH:mm'
                      )}
                      className="flex-none py-0.5 text-sm leading-5 text-gray-500 hover:cursor-pointer"
                      data-tooltip-id="change-log-tooltip"
                      data-tooltip-content={format(
                        new Date(data.timestamp),
                        'yyyy-MM-dd HH:mm'
                      )}
                    >
                      {formatDistance(new Date(data.timestamp), new Date(), {
                        addSuffix: true,
                      })}
                    </time>
                    <ChevronDown
                      className={twMerge(
                        'ml-4 h-5 w-5 shrink-0 text-gray-400',
                        open && 'rotate-180'
                      )}
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </HeadlessUIDisclosure.Button>

              <HeadlessUIDisclosure.Panel className="mt-4 flex-auto ">
                {/** Note */}
                {isNote && (
                  <div className="rounded-md border border-gray-100 bg-gray-25 p-4 text-sm text-gray-700">
                    {data.newValue.notes}
                  </div>
                )}

                {/** Application updated */}
                {isApplicationUpdated && (
                  <div className="space-y-2">
                    {changedValues.map(changedItem => {
                      const fieldLabel = transformPathToReadableText(
                        changedItem.path
                      );

                      const formattedOldValue = formatChangeLogValue(
                        get(data.oldValue, changedItem.path)
                      );

                      const formattedNewValue = formatChangeLogValue(
                        get(data.newValue, changedItem.path)
                      );

                      if (formattedOldValue === formattedNewValue) {
                        return null;
                      }

                      return (
                        <div
                          key={changedItem.path}
                          className="rounded-md border border-gray-100 bg-gray-25 p-4 text-sm text-gray-700"
                        >
                          <p className="text-sm font-bold text-red-600">
                            {fieldLabel} Updated From
                          </p>
                          <p className="mb-4">{formattedOldValue}</p>
                          <p className="text-sm font-bold text-green-600">
                            {fieldLabel} Updated To
                          </p>
                          <p>{formattedNewValue}</p>
                        </div>
                      );
                    })}
                  </div>
                )}

                {/** Single Choice updated */}
                {isChoiceUpdated && (
                  <div className="space-y-6">
                    <div>
                      <span className="text-sm font-bold text-red-600">
                        {getChoiceName(data.oldValue)} Choice Updated From
                      </span>

                      <div className="light-gray-container relative mt-2">
                        <div className="mb-4 flex justify-between">
                          <p className="flex font-medium">
                            <span className="mr-2">
                              {getChoiceName(data.oldValue)} Choice
                            </span>
                            {data.oldValue.status ? (
                              <ChoiceStatusTag status={data.oldValue.status}>
                                {startCase(data.oldValue.status.toLowerCase())}
                              </ChoiceStatusTag>
                            ) : null}
                          </p>

                          <span className="font-mono">
                            Score: {transformScore(data.oldValue.score) ?? '-'}
                          </span>
                        </div>
                        <LocationChoice
                          choice={data.oldValue}
                          schoolYearId={schoolYear.id}
                        />
                      </div>
                    </div>
                    <div>
                      <span className="text-sm font-bold text-green-600">
                        {getChoiceName(data.newValue)} Choice Updated To
                      </span>

                      <div className="light-gray-container relative mt-2">
                        <div className="mb-4 flex justify-between">
                          <p className="flex font-medium">
                            <span className="mr-2">
                              {getChoiceName(data.newValue)} Choice
                            </span>
                            {data.newValue.status ? (
                              <ChoiceStatusTag status={data.newValue.status}>
                                {startCase(data.newValue.status.toLowerCase())}
                              </ChoiceStatusTag>
                            ) : null}
                          </p>

                          <span className="font-mono">
                            Score: {transformScore(data.newValue.score) ?? '-'}
                          </span>
                        </div>
                        <LocationChoice
                          choice={data.newValue}
                          schoolYearId={schoolYear.id}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </HeadlessUIDisclosure.Panel>
            </div>
          </>
        )}
      </HeadlessUIDisclosure>
    );
  }

  return (
    <div className="relative flex gap-x-4">
      <div
        className={twMerge(
          'absolute left-0 top-0 flex w-6 justify-center',
          isLast ? 'h-6' : '-bottom-6'
        )}
      >
        <div className="w-0.5 bg-gray-500" />
      </div>

      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
        <div className="h-2 w-2 rounded-full bg-gray-500" />
      </div>
      <div className="flex-auto">
        <div className="mb-4 flex justify-between">
          <p className="py-0.5 text-sm font-bold leading-5 text-gray-700">
            <span className="font-normal text-gray-900">{data.user?.name}</span>{' '}
            {data.title}
          </p>

          <time
            dateTime={format(new Date(data.timestamp), 'yyyy-MM-dd HH:mm')}
            className="flex-none py-0.5 text-sm leading-5 text-gray-500 hover:cursor-pointer"
            data-tooltip-id="change-log-tooltip"
            data-tooltip-content={format(
              new Date(data.timestamp),
              'yyyy-MM-dd HH:mm'
            )}
          >
            {formatDistance(new Date(data.timestamp), new Date(), {
              addSuffix: true,
            })}
          </time>
        </div>
      </div>
    </div>
  );
}

export { ChangelogItem };
