import { useSchoolYear } from '@/context/school-year.context';
import { useLeaveModal } from '@/hooks/use-leave-modal';
import { HttpError, isBadRequest } from '@/types/error';
import { getNestedKeys } from '@/utils/get-nested-keys';
import {
  Operation,
  Ratio,
  UpdateOperationDto,
} from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { map, sortBy } from 'lodash-es';
import { useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CheckboxBase } from './common/checkbox';
import { TextInput } from './common/text-input';
import { Tooltip } from 'react-tooltip';
import { getAllocatedCapacityRangeByRatio } from '@/utils/location-capacity-utils';
import { generateClassByCapacity } from '@/utils/location-utils';
import { twMerge } from 'tailwind-merge';

type OperationFormData = {
  maxPhysicalCapacity: number | null;
  ratios: { ratio: { id: string; name: string }; maxCapacity: number | null }[];
};

type LocationCapacityFormProps = {
  initialData: Operation;
  ratios: Ratio[];
  onSubmit: ({ data }: { data: UpdateOperationDto }) => Promise<Operation>;
  disabled?: boolean;
};

const schema = yup.object({
  maxPhysicalCapacity: yup
    .number()
    .min(1)
    .nullable()
    .required()
    .label('Max Physical Capacity')
    .typeError('Max Physical Capacity must be a number'),
  ratios: yup
    .array(
      yup.object({
        ratio: yup.object({
          id: yup.string().required(),
          name: yup.string().required(),
        }),
        maxCapacity: yup
          .number()
          .min(1)
          .typeError('Max Capacity must be a number')
          .required()
          .nullable()
          .label('Max Capacity'),
      })
    )
    .required(),
});

function LocationCapacityForm(props: LocationCapacityFormProps) {
  const { initialData, ratios, onSubmit, disabled } = props;
  const {
    schoolYear: { id: schoolYearId },
  } = useSchoolYear();
  const defaultValues = {
    maxPhysicalCapacity: initialData.maxPhysicalCapacity || undefined,
    //TODO: its an ObjectId, it should be string instead in the types lib
    ratios: initialData.ratios.map(r => ({
      maxCapacity: r.capacity,
      ratio: {
        ...r.ratio,
        id: String(r.ratio.id),
      },
    })),
  };

  const form = useForm<OperationFormData>({
    resolver: yupResolver<OperationFormData>(schema),
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolYearId, initialData]);

  const {
    fields: selectedRatios,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name: 'ratios',
  });

  useLeaveModal({
    show: form.formState.isDirty,
  });

  const submitHandler = async (data: OperationFormData) => {
    try {
      const ratios = data.ratios.map(r => ({
        ratioId: r.ratio.id,
        maxCapacity: r.maxCapacity || 0,
      }));

      const convertedData: UpdateOperationDto = {
        ...data,
        ratios,
      };

      await onSubmit({ data: convertedData });
      form.reset(form.getValues(), { keepValues: true });
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        const availableFields = getNestedKeys(form.getValues());

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }
    }
  };

  const handleRatioCheckboxChange = (ratio: Ratio) => () => {
    const actualRatio = selectedRatios.findIndex(r => r.ratio.id === ratio.id);

    if (actualRatio >= 0) {
      remove(actualRatio);
      return;
    }
    append({
      ratio: {
        id: ratio.id,
        name: ratio.name,
      },
      maxCapacity: null,
    });
  };

  const ratioIds = map(ratios, 'id');

  const sortedSelectedRatios = sortBy(selectedRatios, obj => {
    return ratioIds.indexOf(obj.ratio.id);
  }).map(ratio => ({
    ...ratio,
    realIndex: selectedRatios.findIndex(r => r.ratio.id === ratio.ratio.id),
  }));

  return (
    <FormProvider {...form}>
      <Tooltip
        place="top-end"
        id="capacity-tooltip"
        className="tooltip !z-50"
      />
      <form
        onSubmit={form.handleSubmit(submitHandler)}
        className="flex h-full flex-1 flex-col space-y-6"
        id="update-capacity-form"
      >
        <div>
          <p className="label">Placements Available for</p>
          <div className="grid grid-cols-1 gap-x-6 gap-y-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5">
            {ratios.map(ratio => {
              return (
                <CheckboxBase
                  name={`ratio.${ratio.id}`}
                  key={ratio.id}
                  label={ratio.name}
                  disabled={disabled}
                  onChange={handleRatioCheckboxChange(ratio)}
                  checked={map(selectedRatios, 'ratio.id').includes(ratio.id)}
                />
              );
            })}
          </div>
        </div>
        <TextInput
          name="maxPhysicalCapacity"
          type="number"
          min={1}
          label="Max Physical Capacity"
          helperText="This is the maximum capacity for children of any age as specified by the care inspectorate."
          placeholder="40"
          disabled={disabled}
        />
        {sortedSelectedRatios.map(selectedRatio => {
          const maxCapacity = selectedRatio.maxCapacity;

          const allocatedCapacityRange = getAllocatedCapacityRangeByRatio(
            initialData.resources,
            selectedRatio.ratio.id
          );

          const isRangeFlat =
            allocatedCapacityRange.min === allocatedCapacityRange.max;

          const content = isRangeFlat
            ? allocatedCapacityRange.max
            : `${allocatedCapacityRange.min} - ${allocatedCapacityRange.max}`;

          return (
            <div
              key={selectedRatio.ratio.id}
              className="light-gray-container group"
            >
              <div className="text-md mb-6 flex items-center justify-between gap-3">
                <p className="font-medium">{selectedRatio.ratio.name}</p>
                <span
                  className={twMerge(
                    generateClassByCapacity(
                      maxCapacity || 0,
                      allocatedCapacityRange.max || 0
                    ),
                    'mb-1 hover:cursor-pointer'
                  )}
                  data-tooltip-content={`${content} Allocated of ${
                    maxCapacity || 0
                  }`}
                  data-tooltip-id="capacity-tooltip"
                  data-tooltip-offset={35}
                >
                  {content}
                </span>
              </div>
              <div>
                <TextInput
                  name={`ratios.${selectedRatio.realIndex}.maxCapacity`}
                  type="number"
                  min={1}
                  label="Max Registered Capacity"
                  helperText="This is the maximum capacity for children of this age"
                  placeholder="30"
                  disabled={disabled}
                />
              </div>
            </div>
          );
        })}
      </form>
    </FormProvider>
  );
}

export { LocationCapacityForm };
export type { OperationFormData };
