import { bookingService } from '@/services/booking.service';
import { HttpError } from '@/types/error';
import { CreateBookingDto } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCreateBookingMutation(
  childId: string,
  mutationOptions?: UseMutationOptions<void, HttpError, CreateBookingDto>
) {
  return useMutation({
    mutationFn: data => bookingService.createBooking(childId, data),
    ...mutationOptions,
  });
}

export { useCreateBookingMutation };
