import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HttpError } from '@/types/error';
import { GetAllocationReportResponse } from '@admissions-support/types';
import { reportService } from '@/services/report.service';
import { reportKey } from '@/config/query-keys';

function useAllocationReport(
  locationId: string,
  schoolYearId: string,
  queryOptions?: Partial<
    UseQueryOptions<GetAllocationReportResponse, HttpError>
  >
) {
  return useQuery({
    queryKey: reportKey.allocation(locationId, schoolYearId),
    queryFn: () => {
      return reportService.getAllocationReport({ locationId, schoolYearId });
    },
    ...queryOptions,
  });
}

export { useAllocationReport };
