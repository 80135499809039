import { ButtonGroup } from '@/components/form/common/button-group';
import { MultiSelect } from '@/components/form/common/multi-select';
import { TextInput } from '@/components/form/common/text-input';
import { useCohorts } from '@/hooks/query-hooks/use-cohorts';
import { useMemo } from 'react';

function OverviewForm() {
  const {
    isLoading: isCohortsLoading,
    isSuccess: isCohortsSuccess,
    data: cohortsData,
  } = useCohorts();

  const formattedCohorts = useMemo(
    () =>
      isCohortsSuccess
        ? cohortsData.map(cohort => ({
            label: cohort.name,
            value: cohort.id,
            isUsedForIntake: cohort.isUsedForIntake,
          }))
        : [],
    [isCohortsSuccess, cohortsData]
  );

  return (
    <div className="flex h-full flex-1 flex-col">
      <div className="mt-6 bg-white pt-6">
        <div className="two-col-form pt-0">
          <h2 className="text-md font-medium leading-7 text-gray-900">
            Form Details
          </h2>

          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-12">
              <div className="space-y-6">
                <ButtonGroup
                  name="applicationType"
                  label="Application Type*"
                  options={[
                    {
                      key: 'ELC',
                      value: 'ELC',
                    },
                    {
                      key: 'PRIMARY',
                      value: 'Primary',
                    },
                    {
                      key: 'SECONDARY',
                      value: 'Secondary',
                    },
                  ]}
                />

                <TextInput
                  type="text"
                  name="name"
                  label="Application Form Name*"
                  placeholder="3-5 Year Olds ELC Placement"
                />

                <TextInput
                  type="text"
                  name="description"
                  label="Application Description"
                  placeholder="3-5 Year Olds ELC Placement"
                />

                <MultiSelect
                  name="cohorts"
                  label="Cohort(s)*"
                  placeholder="Select cohorts..."
                  options={formattedCohorts}
                  isDisabled={isCohortsLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { OverviewForm };
