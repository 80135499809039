import { userKeys } from '@/config/query-keys';
import { useAuth } from '@/context/auth.context';
import { useMutation } from '@tanstack/react-query';

function useConfirmPhoneNumberMutation() {
  const { confirmPhoneNumber } = useAuth();

  return useMutation({
    mutationKey: userKeys.confirmPhoneNumberMutation,
    mutationFn: confirmPhoneNumber,
  });
}

export { useConfirmPhoneNumberMutation };
