import { organisationKey } from '@/config/query-keys';
import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import { Ratio } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useRatios(
  queryOptions?: Partial<UseQueryOptions<Ratio[], HttpError>>
) {
  return useQuery({
    queryKey: organisationKey.ratios,
    queryFn: organisationService.listRatios,
    ...queryOptions,
  });
}

export { useRatios };
