import { Box } from '@/components/box';
import { ChildApplicationsSection } from '@/components/child/overview/child-applications-section';
import { ChildDetailsSection } from '@/components/child/overview/child-details-section';
import { FallbackComponent } from '@/components/fallback-component';
import { LoadingScreen } from '@/components/loading-screen';
import { parentRouterPath } from '@/config/route-paths.config';
import { useApplicationsByChildId } from '@/hooks/query-hooks/use-applications-by-child-id';
import { useChild } from '@/hooks/query-hooks/use-child';
import { useParentPermission } from '@/hooks/query-hooks/use-parent-permission';
import { hasManageApplicationsPermission } from '@/utils/parent-utils';
import { Navigate, useParams } from 'react-router-dom';

function ChildOverview() {
  const params = useParams<{ id: string }>();

  const {
    data: child,
    error: childError,
    isLoading: isChildLoading,
    isSuccess: isChildSuccess,
  } = useChild(params.id || '', {
    enabled: Boolean(params.id),
  });

  const {
    data: parentPermission,
    error: parentPermissionError,
    isSuccess: isParentPermissionSuccess,
    isLoading: isParentPermissionLoading,
  } = useParentPermission(params.id || '', {
    enabled: Boolean(params.id),
  });

  const {
    data: applications,
    error: applicationsError,
    isSuccess: isApplicationSuccess,
    isLoading: isApplicationLoading,
  } = useApplicationsByChildId(params.id, {
    enabled: Boolean(params.id),
  });

  if (isChildLoading || isApplicationLoading || isParentPermissionLoading) {
    return <LoadingScreen />;
  }

  if (childError || !isChildSuccess || parentPermissionError) {
    return <Navigate to={parentRouterPath.ROOT} />;
  }

  if (
    !isApplicationSuccess ||
    applicationsError ||
    !isParentPermissionSuccess
  ) {
    return <FallbackComponent />;
  }

  return (
    <div className="flex flex-col gap-y-6">
      <Box>
        <ChildDetailsSection
          child={child}
          canManageApplications={hasManageApplicationsPermission(
            parentPermission
          )}
        />
      </Box>
      {hasManageApplicationsPermission(parentPermission) && (
        <Box>
          <ChildApplicationsSection applications={applications} />
        </Box>
      )}
    </div>
  );
}

export { ChildOverview };
