import { locationKey } from '@/config/query-keys';
import { locationService } from '@/services/location.service';
import { HttpError } from '@/types/error';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

function useDeleteSessionMutation(
  mutationOptions?: UseMutationOptions<void, HttpError, { sessionId: string }>
) {
  const params = useParams<{ locationId: string }>();
  const locationId = params.locationId || '';

  return useMutation({
    mutationKey: locationKey.deleteSession(locationId),
    mutationFn: ({ sessionId }) =>
      locationService.deleteSession({ locationId: locationId, sessionId }),
    ...mutationOptions,
  });
}

export { useDeleteSessionMutation };
