import * as yup from 'yup';
import { addressSchema } from '../additional-child-details/additional-child-details.schema';

const carerSchema = yup.object({
  title: yup
    .string()
    .label('Title')
    .oneOf(['MR', 'MRS', 'MX', 'MISS', 'DR', 'MS', 'PROF', 'REV'])
    .required()
    .nullable(),
  firstName: yup.string().label('First Name').required(),
  lastName: yup.string().label('Last Name').required(),
  mobile: yup.string().label('Mobile').required(),
  occupation: yup.string().label('Occupation').nullable(),
  occupationSchedule: yup.string().label('Occupation Schedule').nullable(),
  email: yup.string().email().label('Email').required(),
  address: addressSchema.nullable(),
  isPreferredContact: yup.boolean().required().nullable(),
  isEmergencyContact: yup.boolean().nullable(),
  canCollectChild: yup.boolean().nullable(),
  relationship: yup
    .string()
    .oneOf(['PARENT', 'CARER', 'FOSTER_CARER', 'KINSHIP_CARER', 'OTHER'])
    .required()
    .nullable(),
});

const carersFormSchema = yup.object({
  carers: yup.array(carerSchema).min(1),
});

export { carerSchema, carersFormSchema };
