import { useSessions } from '@/hooks/query-hooks/use-sessions';
import { useParams } from 'react-router-dom';
import { Spinner } from './spinner';
import { capitalize } from 'lodash-es';
import { SessionTiming } from '@admissions-support/types';
import { format } from 'date-fns';

type SessionDetailsProps = {
  sessionId?: string;
  schoolYearId: string;
};

function SessionDetails(props: SessionDetailsProps) {
  const { sessionId, schoolYearId } = props;

  const { locationId = '' } = useParams<string>();

  const {
    isLoading: isSessionsInitialLoading,
    data: sessions,
    isSuccess: isSessionsSuccess,
  } = useSessions(locationId, schoolYearId, { enabled: Boolean(sessionId) });

  const currentSession = sessions?.find(session => session.id === sessionId);

  if (isSessionsInitialLoading) {
    return <Spinner className="m-auto" />;
  }

  if (!isSessionsSuccess && !isSessionsInitialLoading) {
    return <p className="text-red-500">Session not available</p>;
  }

  if (!currentSession) {
    return null;
  }

  return (
    <div className="rounded-lg border border-gray-200 bg-gray-100 p-4">
      <p className="font-semibold text-gray-900">Details</p>
      <div className="my-6 flex gap-6">
        <div className="min-w-48">
          <p className="font-semibold text-gray-700">Session Type</p>
          <p className="font-mono text-gray-600">{currentSession.type.name}</p>
        </div>
        <div className="min-w-48">
          <p className="font-semibold text-gray-700">Model</p>
          <p className="font-mono text-gray-600">{currentSession.model.name}</p>
        </div>
      </div>
      <div className="flex flex-wrap gap-x-4 gap-y-6">
        {Object.keys(currentSession.times).map(day => {
          const value = currentSession.times[day as keyof SessionTiming];
          if (!value || !value.start || !value.end) {
            return null;
          }

          return (
            <div className="rounded-lg bg-white p-4" key={day}>
              <p className="pb-1.5 text-sm text-gray-700">{capitalize(day)}</p>
              <div className="flex text-sm">
                <p className="text-gray-700">
                  {format(new Date(value.start), 'h:mm')}&nbsp;
                </p>
                <p className="text-gray-600">
                  {format(new Date(value.start), 'a')}&nbsp;
                </p>
                -&nbsp;
                <p className="text-gray-700">
                  {format(new Date(value.end), 'h:mm')}&nbsp;
                </p>
                <p className="text-gray-600">
                  {format(new Date(value.end), 'a')}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export { SessionDetails };
