import { Permission } from '@/types/auth';
import { IUserGroup } from '@/types/user-group';

const permissionGroups: IUserGroup[] = [
  {
    name: 'General',
    permissions: [
      { name: 'Create Setting', value: Permission['organisation:create'] },
      { name: 'Update Setting', value: Permission['organisation:update'] },
      { name: 'Delete Setting', value: Permission['organisation:delete'] },
      {
        name: 'Reporting',
        value: Permission['reporting'],
      },
    ],
  },
  {
    name: 'User Management',
    permissions: [
      { name: 'List Users', value: Permission['organisation:users:list'] },
      { name: 'View User', value: Permission['users:fetch'] },
      { name: 'Create User', value: Permission['invitation:invite'] },
      {
        name: 'Update Users',
        value: Permission['users:update'],
        description: 'Includes their user group',
      },
      {
        name: 'Suspend Users',
        value: Permission['users:suspend'],
        description: 'Includes user invitations',
      },
    ],
  },

  {
    name: 'Permission Management',
    permissions: [
      {
        name: 'View User Group',
        value: Permission['organisation:user-groups:fetch'],
      },
      {
        name: 'List User Groups',
        value: Permission['organisation:user-groups:retrieve'],
      },
      {
        name: 'Create User Group',
        value: Permission['organisation:user-groups:create'],
      },
      {
        name: 'Update User Group',
        value: Permission['organisation:user-groups:update'],
      },
      {
        name: 'Delete User Group',
        value: Permission['organisation:user-groups:delete'],
      },
    ],
  },
  {
    name: 'Location Management',
    permissions: [
      {
        name: 'Create Location',
        value: Permission['location:create'],
      },
      {
        name: 'Update Location',
        value: Permission['location:update'],
      },
      {
        name: 'Delete Location',
        value: Permission['location:delete'],
      },
      {
        name: 'View Location',
        value: Permission['location:fetch'],
      },
    ],
  },
  {
    name: 'Application Management',
    permissions: [
      {
        name: 'Create Application',
        value: Permission['application:create'],
      },
      {
        name: 'Update Application',
        value: Permission['application:update'],
      },
      {
        name: 'Delete Application',
        value: Permission['application:delete'],
      },
      {
        name: 'View Application',
        value: Permission['application:fetch'],
      },
    ],
  },
  {
    name: 'Extra Hours',
    permissions: [
      {
        name: 'Manage',
        value: Permission['extra-hours'],
      },
    ],
  },
  {
    name: 'Messaging',
    permissions: [
      {
        name: 'View Tickets',
        value: Permission['messaging:fetch'],
      },
      {
        name: 'Create Ticket',
        value: Permission['messaging:create'],
      },
    ],
  },
];

export { permissionGroups };
