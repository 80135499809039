import { userKeys } from '@/config/query-keys';
import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import { UpdateUserGroupDto, UserGroup } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateUserGroupMutation(
  mutationOptions?: UseMutationOptions<
    UserGroup,
    HttpError,
    { id: string; data: UpdateUserGroupDto }
  >
) {
  return useMutation({
    mutationKey: userKeys.suspend,
    mutationFn: organisationService.updateUserGroup,
    ...mutationOptions,
  });
}

export { useUpdateUserGroupMutation };
