import { ProductControl } from '@/types/product-pagination';
import { DeepPartial } from '@/types/util';
import { PaginationState } from '@tanstack/react-table';
import { merge } from 'lodash-es';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type ProductControlContextState = ProductControl & {
  setPagination: Dispatch<SetStateAction<PaginationState>>;
};

function transformLocalStorageFilter(storedFilter: string): ProductControl {
  const parsedControlObject = JSON.parse(storedFilter) as ProductControl;

  // we need to parse date from string to Date type
  return {
    ...parsedControlObject,
  };
}

const defaultProductControl: ProductControl = {
  pagination: {
    pageIndex: 0,
    pageSize: 10,
  },
};

const ProductControlContext = createContext<
  ProductControlContextState | undefined
>(undefined);

function ProductControlProvider({
  children,
  defaultControl,
}: {
  children: React.ReactNode | React.ReactNode[];
  defaultControl?: DeepPartial<ProductControl>;
}) {
  const localStorageKey = 'admit_products_control';
  const localStorageItem = localStorage.getItem(localStorageKey);

  let persistedProductControl: ProductControl;
  try {
    const programaticDefaultValue = localStorageItem
      ? transformLocalStorageFilter(localStorageItem)
      : defaultProductControl;

    persistedProductControl = merge(
      {},
      defaultProductControl,
      programaticDefaultValue,
      defaultControl
    );
  } catch (error) {
    console.error('Failed to parse localStorageItem:', error);
    persistedProductControl = merge({}, defaultProductControl, defaultControl);
  }

  const [pagination, setPagination] = useState<PaginationState>(
    persistedProductControl.pagination
  );

  const updatedProductControl: ProductControl = useMemo(
    () => ({
      pagination,
    }),
    [pagination]
  );

  useEffect(() => {
    try {
      const serializedData = JSON.stringify(updatedProductControl);
      localStorage.setItem(localStorageKey, serializedData);
    } catch (error) {
      console.error('Failed to serialize application control state:', error);
    }
  }, [updatedProductControl, localStorageKey]);

  return (
    <ProductControlContext.Provider
      value={{
        ...updatedProductControl,
        setPagination,
      }}
    >
      {children}
    </ProductControlContext.Provider>
  );
}

const useProductControl = () => {
  const context = useContext(ProductControlContext);

  if (!context) {
    throw new Error(
      'useApplicationControl must be used within a ApplicationControlProvider'
    );
  }
  return context;
};

export { ProductControlProvider, defaultProductControl, useProductControl };
