import { extraHourKey } from '@/config/query-keys';
import { extraHourService } from '@/services/extra-hour.service';
import { HttpError } from '@/types/error';
import { ChildBookingFilter } from '@/types/extra-hours-bookings-control';
import { ExtraHoursAvailableToBook } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useAvailableExtraHours(
  childId: string,
  filter: ChildBookingFilter,
  queryOptions?: Partial<
    UseQueryOptions<ExtraHoursAvailableToBook[], HttpError>
  >
) {
  return useQuery({
    queryKey: extraHourKey.list(childId, ''),
    queryFn: () => extraHourService.fetchExtraHoursByChildId(childId, filter),
    ...queryOptions,
  });
}

export { useAvailableExtraHours };
