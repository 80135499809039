import { format, isValid, parse } from 'date-fns';
import { capitalize, isBoolean, split } from 'lodash-es';

type LeafPaths = { path: string; value: any }[];

function findLeafPaths(obj: any, path = ''): LeafPaths {
  let paths: LeafPaths = [];
  if (obj !== null && typeof obj === 'object') {
    Object.keys(obj).forEach(key => {
      paths = paths.concat(
        findLeafPaths(obj[key], path + (path ? '.' : '') + key)
      );
    });
  } else {
    paths.push({ path, value: obj });
  }
  return paths;
}

function transformPathToReadableText(path: string) {
  // Split the path by '.' to get individual parts
  const parts = split(path, '.');

  // Map over the parts to transform each one
  const transformedParts = parts.map(part => {
    // Split by camelCase and add spaces, then capitalize each word
    const words = part.match(/[A-Z]?[a-z]+|\d+/g) || [];
    const capitalizedWords = words.map(word => capitalize(word));
    return capitalizedWords.join(' ');
  });

  // Join the transformed parts with spaces
  return transformedParts.join(' ');
}

function formatChangeLogValue(value: any): string {
  if (!value) {
    return '-';
  }

  const isValueBoolean = isBoolean(value);

  if (isValueBoolean) {
    return value ? 'Yes' : 'No';
  }

  const date = parse(value, 'P', new Date());

  const isValueDate = isValid(date);

  if (isValueDate) {
    return format(value, 'yyyy-MM-dd');
  }

  return value || '-';
}

export { findLeafPaths, formatChangeLogValue, transformPathToReadableText };
