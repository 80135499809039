import { Helmet } from 'react-helmet-async';
import { Navigate, useNavigate } from 'react-router-dom';

import { LoadingScreen } from '@/components/loading-screen';
import { LoginTitle } from '@/components/login-title';
import { parentRouterPath } from '@/config/route-paths.config';
import { usePublicOrganisation } from '@/hooks/query-hooks/use-public-organisation';

function WelcomeAuth() {
  const navigate = useNavigate();
  const { isLoading, data: organisation, isError } = usePublicOrganisation();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!organisation || isError) {
    return <Navigate to="/404" />;
  }

  return (
    <>
      <Helmet>
        <title>Sign in</title>
      </Helmet>

      <div>
        <LoginTitle title="Welcome to Admit" />
        <p className="text-md mt-3 font-normal leading-6 text-gray-600">
          You have been directed here by{' '}
          <span className="font-semibold text-primary-600">
            {organisation.name}
          </span>
          . On this website, you&apos;ll be able to apply for your early years
          childcare, primary and secondary school admissions.
        </p>
        <button
          onClick={() => navigate(parentRouterPath.INVITATION_SIGN_IN)}
          className="btn btn-primary btn-full mt-6"
        >
          Get started
        </button>
      </div>
    </>
  );
}

export { WelcomeAuth };
