import * as yup from 'yup';

const createMessageFormSchema = yup.object({
  recipient: yup.string().min(1).label('Recipient').required(),
  title: yup.string().min(10).label('Title').required(),
  topic: yup.string().min(1).label('Topic').required(),
  childId: yup.string().min(1).label('Child').nullable(),
  message: yup.string().min(10).label('Message').required(),
  attachments: yup
    .array(
      yup.object({
        filename: yup.string().required(),
        reference: yup.string().required(),
        key: yup.string().required(),
      })
    )
    .optional(),
});

const parentCreateMessageFormSchema = yup.object({
  title: yup.string().min(10).label('Title').required(),
  topic: yup.string().min(1).label('Topic').required(),
  childId: yup.string().min(1).label('Child').nullable(),
  message: yup.string().min(10).label('Message').required(),
  attachments: yup
    .array(
      yup.object({
        filename: yup.string().required(),
        reference: yup.string().required(),
        key: yup.string().required(),
      })
    )
    .optional(),
});

export { createMessageFormSchema, parentCreateMessageFormSchema };
