import { staffRouterPath } from '@/config/route-paths.config';
import { useAuth } from '@/context/auth.context';
import { useModal } from '@/hooks/use-modal';
import { LogOut01 } from '@untitled-ui/icons-react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { Modal } from './modal';

function LogoutNavigationItem() {
  const { user, isLoading, logOut } = useAuth();
  const { isOpen, openModal, closeModal } = useModal();

  const handleLogout = async () => {
    await logOut();
  };

  if (isLoading || !user) {
    return null;
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={closeModal}
        title="Confirm Log Out"
        description="Are you sure you want to log out? Logging out will end your current session and you will need to sign in again to access your account."
        primaryBtnText="Log Out"
        primaryAction={handleLogout}
        secondaryBtnText="Cancel"
        secondaryAction={closeModal}
        type="warning"
      />
      <Tooltip
        anchorSelect="#logout-button"
        content="Log Out"
        place="right"
        className="tooltip hidden group-data-[is-sidebar-collapsed]/sidebar:block"
      />
      <div className="mx-6 flex items-center border-t border-gray-200 pb-4 pt-6">
        <div className="hide-account-info-if-collapsed mr-3 min-w-0 flex-1">
          <Link
            to={staffRouterPath.USER_PROFILE}
            className="navigation-item flex-col"
          >
            <span className="block truncate text-left text-sm font-semibold text-gray-700">
              {user.givenName} {user.familyName}
            </span>
            <span className="block truncate text-left text-xs font-normal">
              {user.email}
            </span>
          </Link>
        </div>
        <div className="ml-auto">
          <button onClick={openModal} type="button" id="logout-button">
            <LogOut01 aria-hidden="true" />
            <span className="sr-only">Log out</span>
          </button>
        </div>
      </div>
    </>
  );
}

export { LogoutNavigationItem };
