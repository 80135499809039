import * as yup from 'yup';

const intakeCohortSchema = yup.object({
  cohortId: yup.string().required(),
  startDate: yup
    .string()
    .required()
    .label('Start Date')
    .when('isFromChildsBirthday', ([isFromChildsBirthday], schema) => {
      if (isFromChildsBirthday) {
        return yup.string().optional();
      }
      return schema;
    }),
  endDate: yup
    .string()
    .required()
    .label('End Date')
    .when('isFromChildsBirthday', ([isFromChildsBirthday], schema) => {
      if (isFromChildsBirthday) {
        return yup.string().optional();
      }
      return schema;
    })
    .test(
      'is-greater',
      "End date can't be earlier than start date",
      function (value) {
        const startDate = new Date(this.parent.startDate);
        const endDate = new Date(value);
        return endDate > startDate;
      }
    ),
  isFromChildsBirthday: yup.boolean().required(),
});

const intakeSchema = yup.object({
  name: yup.string().required().label('Intake Name'),
  startDate: yup.string().required().label('Start Date'),
  endDate: yup
    .string()
    .required()
    .label('End Date')
    .test(
      'is-greater',
      "End date can't be earlier than start date",
      function (value) {
        const startDate = new Date(this.parent.startDate);
        const endDate = new Date(value);
        return endDate > startDate;
      }
    ),
  deadline: yup
    .string()
    .required()
    .label('Deadline')
    .test(
      'is-greater',
      "Deadline can't be later than start date",
      function (value) {
        const startDate = new Date(this.parent.startDate);
        const deadline = new Date(value);
        return startDate > deadline;
      }
    ),
  cohortEligibility: yup.array(intakeCohortSchema).required(),
});

const schoolYearCohortSchema = yup.object({
  cohortId: yup.string().required(),
  cohortName: yup.string().required(),
  startDate: yup.string().required().label('Start Date').nullable(),
  endDate: yup
    .string()
    .required()
    .label('To Date')
    .test(
      'is-greater',
      "To date can't be earlier than start date",
      function (value) {
        const startDate = new Date(this.parent.startDate);
        const endDate = new Date(value);
        return endDate > startDate;
      }
    )
    .nullable(),
});

const schoolYearSchema = yup.object({
  name: yup.string().required().label('Name'),
  startDate: yup.string().required().label('Start Date'),
  endDate: yup
    .string()
    .required()
    .label('End Date')
    .test(
      'is-greater',
      "End date can't be earlier than start date",
      function (value) {
        const startDate = new Date(this.parent.startDate);
        const endDate = new Date(value);
        return endDate > startDate;
      }
    ),
  intakes: yup
    .array(intakeSchema)
    .required()
    .min(1, 'You must have at least one intake')
    .label('Intakes'),
  cohorts: yup.array(schoolYearCohortSchema).required().min(1),
  selectedCohortIds: yup.array(yup.string()).required().min(1).label('Cohorts'),
});

export { schoolYearSchema };
