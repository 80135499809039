import { publicOrganisationKeys } from '@/config/query-keys';
import { organisationService } from '@/services/organisation.service';
import { getSubDomain } from '@/utils/get-subdomain';
import { useQuery } from '@tanstack/react-query';

function usePublicOrganisation() {
  const subdomain = getSubDomain();

  return useQuery({
    queryKey: publicOrganisationKeys.single(subdomain),
    queryFn: () => organisationService.getPublicOrganisationByUrl(subdomain),
  });
}

export { usePublicOrganisation };
