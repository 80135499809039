import { capitalize } from 'lodash-es';
import { twMerge } from 'tailwind-merge';
import { RadioBoxOptionProps } from '../../form/common/radio-box';

function DataSourceOption(
  props: RadioBoxOptionProps & {
    name: string;
    description: string;
    subtitle: string;
  }
) {
  const { checked, name, description, subtitle } = props;

  return (
    <div>
      <span
        className={twMerge(
          checked ? 'text-primary-800' : 'text-gray-700',
          'block text-sm font-medium'
        )}
      >
        {capitalize(name)}
      </span>
      <span className="mb-1 block text-sm text-gray-600">{description}</span>
      <span className="block text-xs font-medium text-gray-900">
        {subtitle}
      </span>
    </div>
  );
}

export { DataSourceOption };
