import { RadioBoxOptionProps } from '../form/common/radio-box';

function SessionRadioBoxOption(
  props: RadioBoxOptionProps & {
    title?: string;
    start: string;
    end: string;
    className?: string;
  }
) {
  const { title, start, end, className } = props;

  return (
    <div className={className}>
      {title ? <p className="mb-1.5 text-sm font-semibold">{title}</p> : null}
      <p className="text-sm">{`${start}  -  ${end}`}</p>
    </div>
  );
}

export { SessionRadioBoxOption };
