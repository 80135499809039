import { PermissionGuard } from '@/components/auth/permission-guard';
import { PageTitle } from '@/components/page-title';
import { Table } from '@/components/table';
import { staffRouterPath } from '@/config/route-paths.config';
import { useSessionTypes } from '@/hooks/query-hooks/use-session-types';
import { Permission } from '@/types/auth';
import { formatOperatingDays } from '@/utils/format-operating-days';
import { getErrorMessage } from '@/utils/get-error-message';
import { getAdditionalSessionTypeAllowanceLabel } from '@/utils/session-type-utils';
import { SessionType } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import { Copy01, Edit01, SearchRefraction } from '@untitled-ui/icons-react';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { generatePath, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

function SessionTypes() {
  const navigate = useNavigate();
  const { isLoading, data: sessionTypes, error } = useSessionTypes();

  const columnHelper = createColumnHelper<SessionType>();

  const handleEdit = (id: string) => () => {
    const redirectUrl = generatePath(staffRouterPath.EDIT_SESSION_TYPE, {
      id,
    });
    navigate(redirectUrl);
  };

  const handleCopyValue = async (sessionTypeId: string) => {
    try {
      if (!sessionTypeId) {
        return;
      }

      await navigator.clipboard.writeText(sessionTypeId);

      toast.success('ID copied to clipboard!');
    } catch (error) {
      toast.error('Error copying to clipboard!');
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        cell: info => {
          const sessionTypeId = info.row.original.id;

          return (
            <div className="flex items-center font-medium">
              {info.getValue()}
              <button
                type="button"
                className="copy-session-type-tooltip ml-2"
                onClick={() => handleCopyValue(sessionTypeId)}
              >
                <Copy01 className="h-4 w-4" />
              </button>
            </div>
          );
        },
        header: () => <span>Name</span>,
        enableSorting: true,
        sortingFn: 'text',
      }),
      columnHelper.accessor('operatingDays', {
        id: 'operatingDays',
        cell: info => (
          <span>{formatOperatingDays(info.getValue()) || '-'}</span>
        ),
        header: () => <span>Operating Days</span>,
        enableSorting: true,
        size: 388,
      }),
      columnHelper.accessor('allowance', {
        id: 'allowance',
        cell: info => (
          <span>
            {info.getValue()}
            {getAdditionalSessionTypeAllowanceLabel(
              info.row.original.additionalSessionTypes
            )}
          </span>
        ),
        header: () => <span>Allowance (per week)</span>,
        enableSorting: true,
        size: 260,
      }),
      columnHelper.display({
        id: 'id',
        cell: info => (
          <button
            type="button"
            className="edit-session-type-tooltip rounded p-2"
            onClick={handleEdit(info.row.original.id)}
          >
            <Edit01
              className="h-5 w-5"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
            <span className="sr-only">Edit Session Type</span>
          </button>
        ),
        size: 72,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleNavigateToNewSessionType = () => {
    navigate(staffRouterPath.NEW_SESSION_TYPE);
  };

  return (
    <>
      <PageTitle
        title="Session Types"
        description="Specify the parameters around the different types of sessions that parents can select"
        variant="white"
      >
        <PermissionGuard
          requiredPermissions={[Permission['organisation:create']]}
        >
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleNavigateToNewSessionType}
          >
            Add Session Type
          </button>
        </PermissionGuard>
      </PageTitle>

      <Tooltip
        className="tooltip"
        place="top"
        anchorSelect=".copy-session-type-tooltip"
        content="Copy Session Type ID"
      />
      <Tooltip
        anchorSelect=".edit-session-type-tooltip"
        content="Edit Session Type"
        place="top"
        className="tooltip"
      />
      <Table
        columns={columns}
        data={sessionTypes}
        paginationType="auto"
        isLoading={isLoading}
        error={getErrorMessage(error)}
        EmptyState={
          <div className="py-12 text-center">
            <SearchRefraction
              className="mx-auto h-8 w-8 text-gray-400"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              No Session Types Found
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              You do not currently have any Session Types added. Please add your
              first Session Type.
            </p>
          </div>
        }
      />
    </>
  );
}

export { SessionTypes };
