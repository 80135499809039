import { DeleteZone } from '@/components/delete-zone';
import {
  LocationForm,
  LocationFormData,
} from '@/components/form/location.form';
import { PageTitle } from '@/components/page-title';
import { Spinner } from '@/components/spinner';
import { locationKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useCreateLocationMutation } from '@/hooks/create-hooks/use-create-location-mutation';
import { useDeleteLocationMutation } from '@/hooks/delete-hooks/use-delete-location-mutation';
import { useLocation } from '@/hooks/query-hooks/use-location';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useUpdateLocationMutation } from '@/hooks/update-hooks/use-update-location-mutation';
import { queryClient } from '@/libs/react-query';
import { Permission } from '@/types/auth';
import { getErrorMessage } from '@/utils/get-error-message';
import { CreateLocationDto } from '@admissions-support/types';
import { useEffect } from 'react';
import {
  generatePath,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';

async function invalidateLocationQueries() {
  await queryClient.invalidateQueries({
    queryKey: locationKey.list,
    refetchType: 'active',
  });
}

function UpsertLocation() {
  const navigate = useNavigate();
  const params = useParams<{ locationId?: string }>();
  const locationId = params.locationId || '';
  const { mutateAsync: create, isPending: isCreating } =
    useCreateLocationMutation({
      onSuccess: async location => {
        await invalidateLocationQueries();

        const redirectUrl = generatePath(staffRouterPath.EDIT_LOCATION, {
          locationId: location.id,
        });
        navigate(redirectUrl);
      },
    });
  const { mutateAsync: update, isPending: isUpdating } =
    useUpdateLocationMutation({
      onSuccess: invalidateLocationQueries,
    });
  const {
    mutateAsync: deleteLocation,
    isPending: isDeleting,
    error: deleteError,
  } = useDeleteLocationMutation({
    onSuccess: async () => {
      queryClient.removeQueries({
        queryKey: locationKey.single(locationId),
      });
      await invalidateLocationQueries();
      navigate(staffRouterPath.LOCATIONS);
    },
    retry: 0,
  });
  const isCreateLocationView = useMatch(staffRouterPath.NEW_LOCATION);
  const isMutating = isCreating || isUpdating;
  const {
    isLoading: isLocationLoading,
    data: location,
    error,
  } = useLocation(params.locationId);

  useEffect(() => {
    if (error) {
      navigate(staffRouterPath.LOCATIONS);
    }
  }, [error, navigate]);

  const { hasPermission } = usePermissions();

  const handleCancel = () => {
    if (isCreateLocationView) {
      navigate(staffRouterPath.LOCATIONS);
    } else {
      const redirectUrl = generatePath(staffRouterPath.OVERVIEW_LOCATION, {
        locationId: locationId,
      });
      navigate(redirectUrl);
    }
  };

  const handleSubmitLocation = async (data: LocationFormData) => {
    const convertedData = {
      ...data,
      areas: data.areas ? data.areas.map(area => area.value) : [],
    } as CreateLocationDto;

    if (isCreateLocationView) {
      await create(convertedData);
      return;
    }
    await update({
      id: locationId,
      data: convertedData,
    });
  };
  const handleDeleteLocation = async () => {
    await deleteLocation(locationId);
  };

  return (
    <div>
      <PageTitle
        title={isCreateLocationView ? 'New Location' : 'Edit Location'}
        variant="gray"
      >
        <div className="flex gap-3">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCancel}
            disabled={isMutating}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            form="upsert-location-form"
            disabled={isCreating}
          >
            {isCreateLocationView ? 'Create' : 'Update'}
          </button>
        </div>
      </PageTitle>
      {isLocationLoading && !isCreateLocationView ? (
        <div className="mt-6 flex justify-center py-12">
          <Spinner className="h-12 w-12" />
        </div>
      ) : (
        <div className="mt-6">
          <LocationForm
            onSubmit={handleSubmitLocation}
            initialData={location}
          />
          {!isCreateLocationView &&
          hasPermission([Permission['location:delete']]) ? (
            <div className="two-col-form">
              <div>
                <p className="text-md font-medium leading-7 text-gray-900">
                  Destructive Actions
                </p>
                <p className="text-md leading-6 text-gray-600">
                  Take care with these actions as they are destructive and may
                  not be reversable.
                </p>
              </div>
              <div className="col-span-2 space-y-6">
                <DeleteZone
                  title="Delete Location"
                  actionButtonText="Delete Location"
                  description="Deleting a location will remove all the location details. This action cannot be completed if there are applications associated with it."
                  modalDescription="In order to delete this location, please type DELETE in the input below and confirm deletion."
                  onDelete={handleDeleteLocation}
                  isLoading={isDeleting}
                  error={getErrorMessage(deleteError)}
                />
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export { UpsertLocation };
