import { kebabCase } from 'lodash-es';
import { ChangeEvent } from 'react';
import { NavLink, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

type TabItem = {
  name: string;
  url: string;
  activePath?: string | string[];
};

type TabNavigationProps = {
  options: TabItem[];
  tabContainerClassName?: string;
};

function TabNavigation(props: TabNavigationProps) {
  const { options, tabContainerClassName } = props;
  const location = useLocation();
  const navigate = useNavigate();

  if (options.length < 1) {
    return null;
  }

  const mobileSelectId = options.map(tab => kebabCase(tab.name)).join('-');

  const currentSelectedTab =
    options.find(tab => {
      const cleanedTabUrl = tab.url.endsWith('/')
        ? tab.url.slice(0, -1)
        : tab.url;
      const cleanedLocationPath = location.pathname.endsWith('/')
        ? location.pathname.slice(0, -1)
        : location.pathname;

      const isCurrentBySlugMatch = cleanedTabUrl === cleanedLocationPath;
      if (!tab.activePath) {
        return isCurrentBySlugMatch;
      }

      let isCurrentByActivePath: boolean;

      if (Array.isArray(tab.activePath)) {
        isCurrentByActivePath = tab.activePath.some(path =>
          path.includes(tab.url)
        );
        return isCurrentByActivePath || isCurrentBySlugMatch;
      }

      isCurrentByActivePath = Boolean(
        matchPath(tab?.activePath || '', location.pathname)
      );

      return isCurrentByActivePath || isCurrentBySlugMatch;
    }) || options[0];

  const handleMobileSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedTab = options.find(tab => tab.url === event.target.value);

    if (selectedTab) {
      navigate(selectedTab.url);
    }
  };

  return (
    <>
      <div className={twMerge('sm:hidden', tabContainerClassName)}>
        <label htmlFor={`tab-${mobileSelectId}`} className="sr-only">
          Select a tab
        </label>
        <select
          id={`tab-${mobileSelectId}`}
          name={`tab-${mobileSelectId}`}
          className="input"
          defaultValue={currentSelectedTab.url}
          onChange={handleMobileSelect}
        >
          {options.map(tab => (
            <option key={tab.url} value={tab.url}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className={twMerge('hidden sm:block', tabContainerClassName)}>
        <nav
          className="border-bg-gray-200 flex space-x-2 overflow-x-auto rounded-md border bg-gray-50 p-1"
          aria-label="Tabs"
        >
          {options.map(tab => (
            <NavLink
              key={tab.name}
              to={tab.url}
              className={({ isActive }) =>
                twMerge(
                  'flex-shrink-0 rounded-md px-3 py-2 text-sm font-medium text-gray-500 hover:text-gray-700',
                  isActive || tab.name === currentSelectedTab.name
                    ? 'bg-white text-gray-700 shadow-sm'
                    : null
                )
              }
              aria-current={currentSelectedTab ? 'page' : undefined}
            >
              {tab.name}
            </NavLink>
          ))}
        </nav>
      </div>
    </>
  );
}

export { TabNavigation };
export type { TabItem };
