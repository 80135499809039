import { TextInput } from '@/components/form/common/text-input';
import { HttpError, isBadRequest } from '@/types/error';
import { getNestedKeys } from '@/utils/get-nested-keys';
import { CreateRatioDto, Ratio } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Alert } from '../alert';
import { UnitValueInput } from './common/unit-value-input';

const schema = yup.object({
  name: yup.string().required().label('Name'),
  from: yup.object({
    unit: yup.string().required(),
    value: yup
      .number()
      .min(1)
      .typeError('Field must be a valid number.')
      .required()
      .label('From'),
  }),
  to: yup.object({
    unit: yup.string().required(),
    value: yup
      .number()
      .min(1)
      .typeError('Field must be a valid number.')
      .required()
      .label('To'),
  }),
  nbChildrenPerStaffMember: yup
    .number()
    .required()
    .min(0)
    .typeError('Field must be a valid number.')
    .label('Children per Staff'),
});

type RatioFormProps = {
  onSubmit: (data: CreateRatioDto) => Promise<void>;
  initialData?: Ratio;
  isLoading?: boolean;
};

function RatioForm(props: RatioFormProps) {
  const { initialData, isLoading, onSubmit } = props;
  const isEditRatioView = Boolean(initialData);

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: initialData?.name || '',
      from: initialData?.from || {
        unit: 'YEARS',
        value: undefined,
      },
      to: initialData?.to || {
        unit: 'YEARS',
        value: undefined,
      },
      nbChildrenPerStaffMember:
        //because 0 is a valid value as well
        typeof initialData?.nbChildrenPerStaffMember === 'number'
          ? initialData?.nbChildrenPerStaffMember
          : undefined,
    },
  });

  const submitHandler = async (data: any) => {
    //TODO: needs to update the package in order to avoid this
    const convertedData = data as CreateRatioDto;
    try {
      await onSubmit(convertedData);
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        if (!form.formState.defaultValues) {
          return;
        }

        const availableFields = getNestedKeys(form.formState.defaultValues);

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }
    }
  };

  const unitOptions = [
    { label: 'Years', value: 'YEARS' },
    { label: 'Months', value: 'MONTHS' },
  ];

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(submitHandler)}
        className="flex h-full flex-1 flex-col"
        id="upsert-ratio-form"
      >
        <div className="mt-6 bg-white pt-6">
          <div className="two-col-form pt-0">
            <h2 className="text-md font-medium leading-7 text-gray-900">
              Ratio Details
            </h2>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-12">
                <div className="space-y-6">
                  {isEditRatioView && (
                    <Alert
                      type="warning"
                      text={
                        <>
                          <p className="mb-1 font-medium">Attention!</p>
                          <p>
                            Updating or deleting a ratio will affect all{' '}
                            <b>Locations, Resources</b> and <b>Sessions</b> that
                            use this ratio.
                          </p>
                        </>
                      }
                    />
                  )}
                  <TextInput
                    name="name"
                    type="text"
                    label="Ratio Name*"
                    disabled={isLoading}
                  />

                  <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                    <UnitValueInput
                      name="from"
                      type="number"
                      label="From*"
                      unitOptions={unitOptions}
                      disabled={isLoading}
                      min={1}
                    />
                    <UnitValueInput
                      name="to"
                      type="number"
                      label="To*"
                      unitOptions={unitOptions}
                      disabled={isLoading}
                      min={1}
                    />
                  </div>
                  <TextInput
                    name="nbChildrenPerStaffMember"
                    type="number"
                    label="Children per Staff*"
                    disabled={isLoading}
                    helperText="How many children can be allocated per staff member"
                    min={0}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export { RatioForm };
