import { EligibilityConfirmForm } from '@/components/application/eligibility/eligibility-confirm-form';
import { ArrowLeft } from '@untitled-ui/icons-react';
import { useNavigate } from 'react-router-dom';

function EligibilityConfirm() {
  const navigate = useNavigate();

  return (
    <div>
      <h1 className="mb-8 mt-6 text-xl font-semibold text-gray-900">
        Confirm Eligibility
      </h1>
      <button
        type="button"
        className="mb-8 flex items-center text-sm font-semibold"
        onClick={() => navigate(-1)}
      >
        <ArrowLeft viewBox="0 0 24 24" className="mr-2 h-5 w-5 " />
        Return to Applications
      </button>

      <EligibilityConfirmForm />
    </div>
  );
}

export { EligibilityConfirm };
