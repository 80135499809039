import {
  ResetPasswordDto,
  ResetPasswordForm,
} from '@/components/form/reset-password.form';
import { parentRouterPath } from '@/config/route-paths.config';
import { useAuth } from '@/context/auth.context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { AmplifyError } from '@/types/error';

let toastId: string;

function ResetPassword() {
  const { confirmPasswordReset } = useAuth();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const userId = searchParams.get('userId') || '';
  const confirmationCode = searchParams.get('confirmationCode') || '';

  const handleResetPassword = async (data: ResetPasswordDto) => {
    try {
      toastId = toast.loading('Loading..');

      await confirmPasswordReset({
        username: userId,
        confirmationCode: confirmationCode,
        newPassword: data.password,
      });

      toast.success('Password changed!', {
        id: toastId,
      });
    } catch (e) {
      const error = e as AmplifyError;

      toast.error(error.message, {
        id: toastId,
      });
    }

    navigate(parentRouterPath.SIGN_IN);
  };

  return (
    <div className="mt-8">
      <p className="font-fmly text-4xl text-gray-900">New Password</p>
      <ResetPasswordForm onResetPassword={handleResetPassword} />
    </div>
  );
}

export { ResetPassword };
