import { messagingKey } from '@/config/query-keys';
import { messagingService } from '@/services/messaging.service';
import { HttpError } from '@/types/error';
import { DownloadTicketAttachmentResponse } from '@admissions-support/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

type UseGetMessageAttachmentProps = {
  ticketId: string;
  fileKey: string;
};

function useGetMessageAttachment(
  { ticketId, fileKey }: UseGetMessageAttachmentProps,
  queryOptions?: Partial<
    UseQueryOptions<unknown, HttpError, DownloadTicketAttachmentResponse>
  >
) {
  return useQuery({
    queryKey: messagingKey.messageAttachment(ticketId, fileKey),
    queryFn: () => messagingService.getMessageAttachment(ticketId, fileKey),
    ...queryOptions,
  });
}

export { useGetMessageAttachment };
