import { AdditionalDetailsForm } from '@/components/form/additional-details.form';
import { PageTitle } from '@/components/page-title';
import { Spinner } from '@/components/spinner';
import { applicationKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useApplication } from '@/hooks/query-hooks/use-application';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useUpdateApplicationMutation } from '@/hooks/update-hooks/use-update-application-mutation';
import { Permission } from '@/types/auth';
import { useIsMutating } from '@tanstack/react-query';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

function AdditionalDetails() {
  const navigate = useNavigate();
  const nbOfMutation = useIsMutating({
    mutationKey: applicationKey.updateApplication,
  });

  const params = useParams<{ id: string }>();

  const {
    data: application,
    error: applicationError,
    isLoading: isApplicationLoading,
  } = useApplication(params.id || '', {
    enabled: Boolean(params.id),
  });
  const { hasPermission } = usePermissions();

  const isFormLoading = isApplicationLoading;
  const { mutateAsync: updateApplication, isPending: isApplicationUpdating } =
    useUpdateApplicationMutation(application?.id || '');
  const isFormDisabled =
    isApplicationUpdating || !hasPermission([Permission['application:update']]);

  if (applicationError) {
    return <Navigate to={staffRouterPath.APPLICATIONS} />;
  }

  return (
    <div>
      <PageTitle
        title="Edit Application"
        variant="gray"
        className="top-0 z-10 mt-[52px] py-3 lg:sticky lg:mt-0 lg:py-5"
      >
        <div className="flex gap-3">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate(staffRouterPath.APPLICATIONS)}
          >
            Cancel
          </button>

          <button
            className="btn btn-primary"
            form="upsert-application-form"
            disabled={Boolean(nbOfMutation) || isFormDisabled}
          >
            Save Changes
          </button>
        </div>
      </PageTitle>
      {!isFormLoading && application ? (
        <AdditionalDetailsForm
          initialData={application}
          onSubmit={updateApplication}
          isMutating={isFormDisabled}
        />
      ) : (
        <div className="flex items-center justify-center px-4 py-20">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export { AdditionalDetails };
