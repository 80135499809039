import { parentRouterPath } from '@/config/route-paths.config';
import { isProd } from '@/utils/env-utils';
import { formatDateToShortDate } from '@/utils/format-date';
import { Child } from '@admissions-support/types';
import { Link, generatePath } from 'react-router-dom';

type ChildDetailsSectionProps = {
  child: Child;
  canManageApplications: boolean;
};

function ChildDetailsSection(props: ChildDetailsSectionProps) {
  const { child, canManageApplications } = props;

  const knownAsFirstName = child.knownAsFirstName ? child.knownAsFirstName : '';

  const knownAsLastName = child.knownAsLastName ? child.knownAsLastName : '';

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between">
        <p className="text-lg font-semibold text-gray-900">Child Details</p>
        {!isProd() && canManageApplications && (
          <Link
            to={generatePath(parentRouterPath.CHILD_UPDATE, {
              id: child.id || '',
            })}
            className="btn btn-secondary"
          >
            Update Details
          </Link>
        )}
      </div>
      <div className="flex flex-wrap gap-8">
        <div className="flex flex-col">
          <p className="text-md font-semibold text-gray-700">Full Name</p>
          <p className="text-gray-600">{`${child.firstName} ${child.lastName}`}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-md font-semibold text-gray-700">Known as</p>
          <p className="text-gray-600">
            {!knownAsFirstName && !knownAsLastName
              ? '--'
              : `${knownAsFirstName} ${knownAsLastName}`}
          </p>
        </div>
        <div className="flex flex-col">
          <p className="text-md font-semibold text-gray-700">DOB</p>
          <p className="text-gray-600">
            {formatDateToShortDate(child.dateOfBirth)}
          </p>
        </div>
        <div className="flex flex-col">
          <p className="text-md font-semibold text-gray-700">Sex</p>
          <p>{child.sex}</p>
        </div>
        {/* <div className="flex flex-col">
          <p className="text-md font-semibold text-gray-900">Multiple Birth</p>
          <p className="text-gray-600">--</p>
        </div> */}
      </div>
    </div>
  );
}

export { ChildDetailsSection };
