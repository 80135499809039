import { staffRouterPath } from '@/config/route-paths.config';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { SearchExistingChildForm } from './form/search-existing-child.form';
import { ScrollableModal } from './scroll-modal';

type NewApplicationModalProps = {
  open: boolean;
  onClose: () => void;
};

function NewApplicationModal(props: NewApplicationModalProps) {
  const { open, onClose } = props;
  const [isExistingChild, setIsExistingChild] = useState(false);

  return (
    <ScrollableModal
      isOpen={open}
      onClose={onClose}
      size="xl"
      afterLeave={() => setIsExistingChild(false)}
    >
      <ScrollableModal.Header onClose={onClose}>
        <ScrollableModal.Title
          title="Start New Application"
          description="Create an application for a new child, or start new application for an existing child."
        />
      </ScrollableModal.Header>

      <ScrollableModal.Content className="pb-6">
        {!isExistingChild ? (
          <div className="block justify-between space-x-0 space-y-4 sm:flex sm:space-x-4 sm:space-y-0">
            <Link
              type="button"
              className="btn btn-secondary btn-full"
              to={staffRouterPath.NEW_APPLICATION}
            >
              Add Child
            </Link>
            <button
              type="button"
              className="btn btn-primary btn-full"
              onClick={() => setIsExistingChild(true)}
            >
              Existing Child
            </button>
          </div>
        ) : (
          <SearchExistingChildForm />
        )}
      </ScrollableModal.Content>
    </ScrollableModal>
  );
}

export { NewApplicationModal };
