import { organisationKey } from '@/config/query-keys';
import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import { SchoolYear } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useTerms(
  queryOptions?: Partial<UseQueryOptions<SchoolYear[], HttpError>>
) {
  return useQuery({
    queryKey: organisationKey.terms,
    queryFn: organisationService.listTerms,
    ...queryOptions,
  });
}

export { useTerms };
