import * as yup from 'yup';

const baseSibling = yup.object({
  firstName: yup.string().label('First Name').required(),
  lastName: yup.string().label('Last Name').required(),
  sex: yup.string().label('Sex').required(),
  stage: yup.string().label('Stage').required().nullable(),
  dateOfBirth: yup.string().label('Date of Birth').required(),
});

const formSibling = baseSibling.shape({
  school: yup
    .object({
      label: yup.string().label('School Name').required(),
      value: yup.string().label('School Id').required(),
      __isNew__: yup.boolean(),
    })
    .required()
    .nullable()
    .label('Location'),
});

const apiSibling = baseSibling.shape({
  school: yup
    .object({
      name: yup.string().label('School Name').required(),
      id: yup.string().label('School Id').nullable(),
    })
    .required()
    .label('Location'),
});

const householdApiSchema = yup.object({
  siblings: yup.array(apiSibling),
  homeLanguage: yup.string().label('Home Language').required(),
});

const householdFormSchema = yup.object({
  siblings: yup.array(formSibling),
  homeLanguage: yup.string().label('Home Language').required(),
});

export { householdApiSchema, householdFormSchema };
