import {
  activityLogKey,
  bookingKey,
  transactionKey,
} from '@/config/query-keys';
import { useCreateTransactionMutation } from '@/hooks/use-create-transaction-mutation';
import { queryClient } from '@/libs/react-query';
import { Child, CreateTransactionDto } from '@admissions-support/types';
import { isEmpty } from 'lodash-es';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { BaseModalPropsExport } from '../base-modal';
import { ScrollableModal } from '../scroll-modal';
import { RecordTransactionForm } from './record-transaction.form';
import { RecordTransactionFormData } from './record-transaction.type';
import { useRecordTransactionForm } from './use-record-transaction-form';

type TransactionModalProps = { child: Child } & BaseModalPropsExport;

function TransactionModal(props: TransactionModalProps) {
  const { isOpen, onClose, child } = props;

  const params = useParams<{ id: string }>();
  const childId = params.id || '';

  const {
    mutateAsync: createTransactionMutation,
    isPending: isCreatingTransaction,
  } = useCreateTransactionMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: transactionKey.list });

      await queryClient.invalidateQueries({
        queryKey: bookingKey.metaByChild(childId),
      });

      await queryClient.invalidateQueries({
        queryKey: activityLogKey.byChildId(childId),
      });

      await queryClient.invalidateQueries({
        queryKey: ['booking', 'child'],
      });

      onClose();
    },
  });

  const onSubmit = async (data: RecordTransactionFormData) => {
    const requestData = {
      ...data,
      childId: childId,
    } as CreateTransactionDto;

    if (!requestData.date) {
      delete requestData.date;
    }

    const response = await createTransactionMutation(requestData);

    return response;
  };

  const { form, handleSubmit } = useRecordTransactionForm({
    onSubmit,
  });

  return (
    <ScrollableModal isOpen={isOpen} onClose={onClose} size="xl">
      <ScrollableModal.Header onClose={onClose}>
        <ScrollableModal.Title title="Record Transaction" />
      </ScrollableModal.Header>
      <ScrollableModal.Content>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            id="record-transaction-form"
          >
            <RecordTransactionForm child={child} />
          </form>
        </FormProvider>
      </ScrollableModal.Content>

      <ScrollableModal.Footer>
        <div className="flex w-full gap-3">
          <button
            className="btn btn-secondary w-full"
            type="button"
            onClick={onClose}
            disabled={isCreatingTransaction}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary w-full"
            form="record-transaction-form"
            disabled={!isEmpty(form.formState.errors) || isCreatingTransaction}
          >
            Submit
          </button>
        </div>
      </ScrollableModal.Footer>
    </ScrollableModal>
  );
}

export { TransactionModal };
