import { axiosClient } from '@/libs/axios';
import { Log } from '@admissions-support/types';

const getByApplicationId = async (applicationId: string): Promise<Log[]> => {
  const response = await axiosClient.get<Log[]>(`/logs/${applicationId}`);

  return response.data;
};

export const logService = Object.freeze({
  getByApplicationId,
});
