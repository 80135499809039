import { ChildExtraHoursTableFilter } from '@/components/parent/purchase-extra-hours/child-extra-hours-table';
import { ApplicationFilter } from '@/types/application-filter';
import { ExtraHoursBookingsFilter } from '@/types/extra-hours-bookings-control';
import {
  CapacityReportQueryParamsDto,
  DemandReportQueryParamsDto,
  GetStaffTicketRequest,
  ReportsApplicationsDto,
  ReportsBookingsDto,
  ReportsLocationsDto,
  SearchParentDto,
  WhichIntakeDto,
} from '@admissions-support/types';
import { PaginationState, SortingState } from '@tanstack/react-table';

export const publicOrganisationKeys = {
  single: (url: string) => ['public-organisation', url],
};

export const invitationKeys = {
  single: (code: string) => ['invitation', code] as const,
  create: ['create-invitation'],
  resend: (userId: string) => ['invitation', 'resend', userId],
};

export const authKeys = {
  totp: ['auth', 'totp-token'],
};

export const userKeys = {
  phoneNumberMutation: ['phone-number-mutation'],
  confirmPhoneNumberMutation: ['confirm-phone-number-mutation'],
  list: ['platform-users'],
  user: (id: string) => ['platform-users', id],
  suspend: ['suspend-user-mutation'],
};

export const organisationKey = {
  userGroupsList: ['user-groups'],
  userGroup: (id: string) => ['user-groups', id],
  weekModels: ['models'],
  weekModel: (id: string) => ['models', id],
  createWeekModel: ['create-model'],
  updateWeekModel: ['update-model'],
  deleteWeekModel: ['delete-model'],
  terms: ['terms'],
  term: (id: string) => ['terms', id],
  createTerm: ['create-term'],
  updateTerm: ['update-term'],
  deleteTerm: ['delete-term'],
  ratios: ['ratios'],
  ratio: (id: string) => ['ratios', id],
  createRatio: ['create-ratio'],
  updateRatio: ['update-ratio'],
  deleteRatio: ['delete-ratio'],
  updateOrganisation: ['update-organisation'],
  eligibility: (data: WhichIntakeDto) => [
    'eligibility',
    data.birthday,
    data.schoolYearId,
  ],
};

export const locationKey = {
  create: ['create-location'],
  update: ['update-location'],
  delete: ['delete-location'],
  list: ['locations'],
  single: (id: string) => ['locations', id],
  createSession: (id: string) => ['session-locations', id, 'create'],
  listSessions: (id: string, termId: string) => [
    'session-locations',
    id,
    termId,
  ],
  updateSession: (id: string) => ['session-locations', id, 'update'],
  deleteSession: (id: string) => ['session-locations', id, 'delete'],
  operation: (locationId: string, termId: string) => [
    'locations',
    locationId,
    'operation',
    termId,
  ],
  updateOperation: (id: string, termId: string) => [
    'locations',
    id,
    'operation',
    termId,
  ],
  schools: ['locations', 'schools'],
  extraHoursByLocationId: (locationId: string, from: Date, to?: Date) => [
    'extra-hours',
    locationId,
    to,
    from,
  ],
  createExtraHourResource: ['create-extra-hour-resource'],
  updateExtraHourResource: ['update-extra-hour-resource'],
  updateLocationResource: ['update-location-resource'],
};

export const sessionTypeKey = {
  create: ['create-session-type'],
  update: ['update-session-type'],
  delete: ['delete-session-type'],
  list: ['session-types'],
  single: (id: string) => ['session-types', id],
};

export const applicationKey = {
  list: (
    schoolYear: string,
    pagination: PaginationState,
    sorting: SortingState,
    filter: ApplicationFilter
  ) => ['application-list', { schoolYear, pagination }, sorting, filter],
  count: (schoolYear: string, filter: ApplicationFilter) =>
    ['application-list', 'count', schoolYear, filter] as const,
  single: (id: string) => ['application', id],
  byChildId: (childId: string) => ['application', 'child', childId],
  updateApplication: ['application', 'update-mutation'],
  bulkUpdateApplications: ['application', 'bulk-update-mutation'],
  bulkEmailApplicants: ['application', 'bulk-email-mutation'],
  runMatching: ['run-matching'],
  resetMatching: ['reset-matching'],
  updateChoice: ['application', 'update-choice-mutation'],
};

export const childKey = {
  list: ['child'],
  single: (id: string) => ['child', id],
  assignationMeta: (id: string) => ['child-assignation-meta', id],
  updateChild: ['child', 'update-mutation'],
  listByParentId: (parentId: string) => ['child', 'parent', parentId],
};

export const parentKey = {
  searchByNameAndEmail: (query: SearchParentDto) => [
    'parent',
    'search-by-name-and-email',
    query,
  ],
};

export const logKey = {
  single: (applicationId: string) => ['log', applicationId],
};

export const fileKey = {
  single: (path: string) => ['file', path],
};

export const bookingKey = {
  byLocation: (locationId: string, termId: string, from: Date, to: Date) => [
    'booking',
    locationId,
    termId,
    from,
    to,
  ],
  metaByChild: (childId: string) => ['booking', 'child', 'meta', childId],
  byChild: (
    childId: string,
    pagination: PaginationState,
    sorting: SortingState,
    filter: ChildExtraHoursTableFilter
  ) => ['booking', 'child', childId, pagination, sorting, filter],
  byChildren: (
    schoolYearId: string,
    pagination: PaginationState,
    sorting: SortingState,
    filter: ExtraHoursBookingsFilter
  ) => ['booking', { schoolYearId, pagination }, sorting, filter],
  childMeta: (childId: string, schoolYearId: string) => [
    'booking',
    'child-meta',
    childId,
    schoolYearId,
  ],
};

export const productKey = {
  list: ['product'],
  create: ['create-product'],
  update: ['update-product'],
  single: (id: string) => ['product', id],
  byLocation: (
    locationId: string,
    termId: string,
    page?: number,
    limit?: number
  ) => ['product', locationId, termId, page, limit],
};

export const transactionKey = {
  list: ['transaction'],
  create: ['create-transaction'],
};

export const extraHourKey = {
  list: (childId: string, termId: string) => ['extra-hour', childId, termId],
  deleteExtraHour: ['delete-extra-hour'],
};

export const activityLogKey = {
  create: ['create-activity-log'],
  byChildId: (childId: string) => ['activity-log', childId],
};

export const cohortKey = {
  list: ['cohort'],
  single: (id: string) => ['cohort', id],
  updateCohort: ['cohort', 'update-mutation'],
};

export const areaKey = {
  list: ['area'],
  single: (id: string) => ['area', id],
  updateArea: ['update-area'],
};

export const reportKey = {
  listTemplates: (filter?: 'ONLY_YOU' | 'ORG') => ['list-templates', filter],
  updateTemplate: ['update-template'],
  getApplicationsResults: (data: ReportsApplicationsDto) => [
    'query-result',
    'applications',
    data,
  ],
  getBookingsResults: (data: ReportsBookingsDto) => [
    'query-result',
    'bookings',
    data,
  ],
  getLocationsResults: (data: ReportsLocationsDto) => [
    'query-result',
    'locations',
    data,
  ],
  exportApplicationsResult: (data: ReportsApplicationsDto) => [
    'query-result',
    'applications',
    'export',
    data,
  ],
  exportLocationsResult: (data: ReportsLocationsDto) => [
    'query-result',
    'locations',
    'export',
    data,
  ],
  exportBookingsResult: (data: ReportsBookingsDto) => [
    'query-result',
    'bookings',
    'export',
    data,
  ],
  singleTemplate: (id: string) => ['list-template', id],
  allocation: (locationId: string, schoolYearId: string) => [
    'query-result',
    'allocation',
    locationId,
    schoolYearId,
  ],
  capacity: (data: CapacityReportQueryParamsDto) => [
    'query-result',
    'capacity',
    data,
  ],
  capacityCsv: (data: CapacityReportQueryParamsDto) => [
    'query-result',
    'capacity',
    'csv',
    data,
  ],
  demand: (data: DemandReportQueryParamsDto) => [
    'query-result',
    'demand',
    data,
  ],
  demandCsv: (data: DemandReportQueryParamsDto) => [
    'query-result',
    'demand',
    'csv',
    data,
  ],

  simulate: ['simulate-senario'],
};

export const applicationTemplateKey = {
  list: ['application-template'],
  single: (id: string) => ['application-template', id] as const,
  create: ['application-template', 'create'],
  update: ['application-template', 'update'],
  delete: ['application-template', 'delete'],
  createQuestion: ['application-template', 'question', 'create'],
  deleteQuestion: ['application-template', 'question', 'delete'],
};

export const messagingKey = {
  all: ['messaging'],
  list: (params: GetStaffTicketRequest, isParent: boolean) => [
    'messaging',
    isParent,
    params,
  ],
  hasUnreadMessages: ['messaging', 'has-unread-messages'],
  respondToTicket: ['messaging', 'respond-to-ticket'],
  single: (id: string) => ['messaging', id],
  messageAttachment: (ticketId: string, fileKey: string) => [
    'messaging',
    ticketId,
    fileKey,
  ],
  updateTicketStatus: ['messaging', 'update-ticket-status'],
};

export const trusteeKey = {
  list: (childId: string) => ['trustee', childId] as const,
  permission: (childId: string) => ['trustee', 'permission', childId],
};
