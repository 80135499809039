import { locationKey } from '@/config/query-keys';
import { locationService } from '@/services/location.service';
import { HttpError } from '@/types/error';
import {
  Operation,
  UpdateExtraHourResourceDto,
} from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateExtraHourResource(
  mutationOptions?: UseMutationOptions<
    Operation,
    HttpError,
    {
      operationId: string;
      resourceId: string;
      data: UpdateExtraHourResourceDto;
    }
  >
) {
  return useMutation({
    mutationKey: locationKey.updateExtraHourResource,
    mutationFn: ({ operationId, resourceId, data }) =>
      locationService.updateExtraHourResource({
        operationId,
        resourceId,
        data,
      }),
    ...mutationOptions,
  });
}

export { useUpdateExtraHourResource };
