import { locationKey } from '@/config/query-keys';
import { locationService } from '@/services/location.service';
import { HttpError } from '@/types/error';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useDeleteLocationMutation(
  mutationOptions?: UseMutationOptions<void, HttpError, string>
) {
  return useMutation({
    mutationKey: locationKey.delete,
    mutationFn: locationService.deleteLocation,
    ...mutationOptions,
  });
}

export { useDeleteLocationMutation };
