import { AlertCircle } from '@untitled-ui/icons-react';
import { useState } from 'react';
import { Alert } from './alert';
import { Spinner } from './spinner';

type ConfirmDescructionProps = {
  buttonText: string;
  actionButtonText: string;
  declineButtonText: string;
  title: string;
  text: string;
  action: () => any;
  isLoading?: boolean;
  error?: string;
};

function ConfirmDescruction(props: ConfirmDescructionProps) {
  const {
    buttonText,
    isLoading,
    title,
    text,
    error,
    actionButtonText,
    declineButtonText,
    action,
  } = props;
  const [activated, setActivated] = useState(false);

  const hasError = Boolean(error);

  if (activated) {
    return (
      <div className="relative overflow-hidden rounded-lg border border-gray-300 bg-white text-left sm:my-8">
        {isLoading && (
          <div className="absolute inset-0 flex h-full w-full items-center justify-center bg-white/80">
            <Spinner className="h-10 w-10 text-white" />
          </div>
        )}

        <div className="px-4 pt-5 sm:p-6 sm:pb-2">
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-12 sm:w-12">
              <AlertCircle
                className="h-6 w-6 text-red-600"
                viewBox="0 0 24 24"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                {title}
              </h3>
              <div className="mt-2">
                <p className="text-sm">{text}</p>
              </div>
            </div>
          </div>
          {hasError ? (
            <Alert
              type="error"
              text={
                <>
                  <b>Error during the action</b>
                  <p>{error}</p>
                </>
              }
              className="mt-5"
            />
          ) : null}
        </div>
        <div className="px-4 py-6 sm:flex sm:flex-row-reverse sm:px-6">
          <button
            type="button"
            className="btn btn-error w-full sm:ml-3 sm:w-auto"
            onClick={action}
            disabled={isLoading}
          >
            {actionButtonText}
          </button>
          <button
            type="button"
            className="btn btn-secondary mt-3 w-full sm:mt-0 sm:w-auto"
            onClick={() => setActivated(false)}
            disabled={isLoading}
          >
            {declineButtonText}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <button
        type="button"
        tabIndex={-1}
        className="btn btn-error-alt w-full"
        onClick={() => setActivated(true)}
      >
        {buttonText}
      </button>
    </div>
  );
}

export { ConfirmDescruction };
