import { useLeaveModal } from '@/hooks/use-leave-modal';
import { ApplicationTemplate } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { applicationTemplateOverviewSchema } from './application-template-overview.schema';
import { ApplicationOverviewFormData } from './application-template-overview.type';

type UseApplicationTemplateOverviewProps = {
  initialData?: ApplicationTemplate;
};

function transformApiDataToFormData(
  initialData?: ApplicationTemplate
): ApplicationOverviewFormData {
  if (!initialData) {
    return {
      name: '',
      description: '',
      applicationType: '',
      cohorts: [],
      enableAdditionalCriteria: false,
      criteria: [],
      criteriaRequirement: 'any',
    };
  }

  return {
    name: initialData.name,
    description: initialData.description,
    applicationType: initialData.applicationType,
    cohorts: initialData.cohorts.map(item => item.id.toString()),
    enableAdditionalCriteria: initialData.criteria.length > 0,
    criteria: initialData.criteria,
    criteriaRequirement: initialData.criteriaRequirement || 'any',
  };
}

function useApplicationTemplateOverview(
  props?: UseApplicationTemplateOverviewProps
) {
  const defaultValues: ApplicationOverviewFormData = transformApiDataToFormData(
    props?.initialData
  );

  const form = useForm({
    resolver: yupResolver(applicationTemplateOverviewSchema),
    defaultValues,
  });

  useLeaveModal({
    show: form.formState.isDirty,
  });

  const reset = useCallback(
    (initialdata: ApplicationTemplate) => {
      form.reset(transformApiDataToFormData(initialdata));
    },
    [form]
  );
  return {
    form,
    reset,
  };
}

export { useApplicationTemplateOverview };
