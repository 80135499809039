import { areaKey } from '@/config/query-keys';
import { areaService } from '@/services/area.service';
import { HttpError } from '@/types/error';
import { Area, UpdateAreaDto } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateAreaMutation(
  mutationOptions?: UseMutationOptions<
    Area,
    HttpError,
    { id: string; data: UpdateAreaDto }
  >
) {
  return useMutation({
    mutationKey: areaKey.updateArea,
    mutationFn: areaService.updateArea,
    ...mutationOptions,
  });
}

export { useUpdateAreaMutation };
