import { escapeRegExp } from 'lodash-es';

function highlightQuery(text: string, searchQuery: string) {
  if (searchQuery.trim() === '') {
    return text;
  }

  // Split the search query into words and escape each word
  const words = searchQuery.split(/\s+/).map(escapeRegExp);

  // Create a regular expression that matches any of the words
  const queryRegex = new RegExp(`(${words.join('|')})`, 'gi');
  const parts = text.split(queryRegex);

  return (
    <>
      {parts.map((part, index) =>
        queryRegex.test(part) ? (
          <span key={index} style={{ backgroundColor: 'yellow' }}>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </>
  );
}

export { highlightQuery };
