import { Box } from '@/components/box';
import { Breadcrumbs } from '@/components/breadcrumbs';
import { ParentCreateMessageForm } from '@/components/form/parent-create-message.form';
import { parentRouterPath } from '@/config/route-paths.config';
import { ArrowLeft } from '@untitled-ui/icons-react';
import { Link } from 'react-router-dom';

function ParentCreateMessage() {
  const breadcrumbPaths = [
    {
      content: 'Help Desk',
      link: parentRouterPath.MESSAGING,
    },
    {
      content: 'New Support Ticket',
      link: parentRouterPath.MESSAGING_NEW_CONVERSATION,
    },
  ];

  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex items-center justify-between">
        <Breadcrumbs path={breadcrumbPaths} />
        <Link
          to={parentRouterPath.MESSAGING}
          className="btn btn-secondary flex items-center gap-2"
        >
          <ArrowLeft className="size-4" />
          Back to Help Desk
        </Link>
      </div>
      <div className="mt-6 flex w-full justify-center">
        <Box>
          <h1 className="text-lg font-semibold text-primary-900">
            New Support Ticket
          </h1>
          <p className="mt-4">
            Please fill in the required fields below, indicated by an *.
            Additional fields are not required but may aid us with your support
            query.
          </p>
          <div className="mt-8">
            <ParentCreateMessageForm />
          </div>
        </Box>
      </div>
    </div>
  );
}

export { ParentCreateMessage };
