import { parentRouterPath } from '@/config/route-paths.config';
import { formatDateToShortDate } from '@/utils/format-date';
import { ListChild } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import { Eye } from '@untitled-ui/icons-react';
import { startCase } from 'lodash-es';
import { useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { ChildApplicationStatusCell } from '../application/child-application-status-cell';
import { Table } from '../table';

type ChildrenListTableProps = {
  childList?: ListChild[];
};

function ChildrenListTable(props: ChildrenListTableProps) {
  const { childList } = props;

  const columnHelper = createColumnHelper<ListChild>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        cell: info => {
          return <span className="text-primary-900">{info.getValue()}</span>;
        },
        header: () => <span>Name</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('relationshipType', {
        id: 'relationshipType',
        cell: info => {
          return <span className="text-gray-600">{info.getValue()}</span>;
        },
        header: () => <span>Relationship</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('id', {
        cell: info => {
          const childId = info.row.original.id;

          return <ChildApplicationStatusCell childId={childId} />;
        },
        header: () => <span>Application Status</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('dob', {
        cell: info => {
          const dateOfBirth = info.getValue();
          return (
            <span className="font-mono text-gray-600">
              {formatDateToShortDate(dateOfBirth)}
            </span>
          );
        },
        header: () => <span>DOB</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('identificationType', {
        cell: info => {
          const idType = info.getValue();

          const idNumber = info.row.original.identificationNumber;
          return (
            <span className="text-primary-900">
              <span>{startCase(idType)}</span>
              <br />
              <span className="font-mono text-gray-600">{idNumber}</span>
            </span>
          );
        },
        header: () => <span>ID Type & Number</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('updatedAt', {
        cell: info => {
          const updatedAtString = info.getValue();

          if (!updatedAtString) {
            return <span className="font-mono text-gray-600">--</span>;
          }

          return (
            <span className="font-mono text-gray-600">
              {formatDateToShortDate(updatedAtString)}
            </span>
          );
        },
        header: () => <span>Last Update</span>,
        enableSorting: false,
      }),
      columnHelper.display({
        id: 'actions',
        cell: info => (
          <Link
            type="button"
            className="view-child-tooltip icon-link block"
            to={generatePath(parentRouterPath.CHILD_OVERVIEW, {
              id: info.row.original.id,
            })}
          >
            <Eye className="h-5 w-5" viewBox="0 0 24 24" aria-hidden="true" />
            <span className="sr-only">View application</span>
          </Link>
        ),
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Tooltip
        anchorSelect=".view-child-tooltip"
        content="View Child"
        place="top"
        className="tooltip"
      />
      <Table columns={columns} data={childList} className="mt-5" />
    </>
  );
}

export { ChildrenListTable };
