import { AlertCircle } from '@untitled-ui/icons-react';
import { twMerge } from 'tailwind-merge';

interface AlertProps {
  type: 'error' | 'warning' | 'success' | 'info';
  title?: string;
  text: React.ReactNode;
  className?: string;
}
function Alert(props: AlertProps) {
  const { title, text, type, className } = props;

  return (
    <div
      className={twMerge(
        'rounded-lg border p-4 shadow-sm',
        type === 'error' ? 'border-red-300 bg-red-50' : null,
        type === 'warning' ? 'border-yellow-300 bg-yellow-25' : null,
        type === 'success' ? 'border-green-100 bg-green-50 shadow-none' : null,
        type === 'info' ? 'bg-blue-50' : null,
        className
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          {type === 'error' ? (
            <svg
              className="h-5 w-5 text-red-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                clipRule="evenodd"
              />
            </svg>
          ) : null}
          {type === 'warning' ? (
            <AlertCircle
              className="h-5 w-5 text-yellow-600"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
          ) : null}
          {type === 'success' ? (
            <svg
              className="h-5 w-5 text-green-600"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clipRule="evenodd"
              />
            </svg>
          ) : null}
          {type === 'info' ? (
            <svg
              className="h-5 w-5 text-blue-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                clipRule="evenodd"
              />
            </svg>
          ) : null}
        </div>
        <div
          className={twMerge(
            'ml-3 text-sm',
            type === 'error' ? 'text-red-700' : null,
            type === 'warning' ? 'text-yellow-700' : null,
            type === 'success' ? 'text-green-600' : null,
            type === 'info' ? 'text-blue-700' : null
          )}
        >
          {title ? <div className="mb-1 font-medium">{title}</div> : null}
          <div>{text}</div>
        </div>
      </div>
    </div>
  );
}

export { Alert };
