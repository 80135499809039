import { childKey } from '@/config/query-keys';
import { childService } from '@/services/child.service';
import { HttpError } from '@/types/error';
import { Child } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useChildByPassport(
  id: string,
  queryOptions?: Partial<UseQueryOptions<Child, HttpError>>
) {
  return useQuery({
    queryKey: childKey.single(id),
    queryFn: () => childService.getChildByPassport(id),
    ...queryOptions,
  });
}

export { useChildByPassport };
