import { CopyBookingIdBase } from '@/components/extra-hours-bookings/copy-booking-id';
import { RadioBox, RadioBoxOption } from '@/components/form/common/radio-box';
import { TextInput } from '@/components/form/common/text-input';
import { ScrollableModal } from '@/components/scroll-modal';
import { BaseSyntheticEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { MakePaymentFormData } from './make-payment.type';

type MakePaymentFormProps = {
  paymentOptions: RadioBoxOption[];
  childId: string;
  onSubmit: (e: BaseSyntheticEvent) => void;
  disabled?: boolean;
};

function MakePaymentForm(props: MakePaymentFormProps) {
  const { paymentOptions, childId, onSubmit, disabled } = props;
  const { watch, formState } = useFormContext<MakePaymentFormData>();
  const paymentOption = watch('paymentOption');

  return (
    <>
      <ScrollableModal.Content>
        <form onSubmit={onSubmit}>
          <p className="text-md mb-4 text-gray-600">
            Select your preferred payment method below.
          </p>
          <RadioBox
            name="paymentOption"
            containerClassName="space-y-2 p-1"
            label="Payment Option"
            options={paymentOptions}
            disabled={disabled}
          />
          <div className="mt-4 border-t border-gray-200 pt-4">
            {paymentOption === 'EPAY_CAPITA' && (
              <>
                <p className="mb-6">
                  Please note that payments can take up to 24 hours to be
                  reflected on your account, however you will receive a payment
                  receipt immediately via email.
                </p>

                <p className="label mb-1">Child Booking ID</p>
                <CopyBookingIdBase childId={childId} />
                <p className="mt-2 text-sm text-gray-600">
                  Used as booking reference
                </p>
              </>
            )}

            {paymentOption === 'TAX_FREE_CHILD_CARE' && (
              <>
                <p className="mb-6">
                  Please provide your reference number. We’ll use this to
                  compare payment received to your account, and the balance will
                  be updated accordingly.
                </p>
                <TextInput
                  name="childCareReference"
                  label="Tax Free Childcare Reference"
                  placeholder="12345-6789"
                  disabled={disabled}
                />
              </>
            )}

            {paymentOption === 'CHILDCARE_VOUCHERS' && (
              <>
                <p className="mb-6">
                  Please provide the name of the voucher scheme(s) you are
                  using.
                </p>
                <TextInput
                  name="voucherProvider"
                  label="Voucher Provider(s)"
                  placeholder="ELC Care co, ELC Care co"
                  disabled={disabled}
                />
              </>
            )}
          </div>
        </form>
      </ScrollableModal.Content>

      <ScrollableModal.Footer>
        <button
          className="btn btn-primary btn-full"
          disabled={(!formState.isDirty && !formState.isValid) || disabled}
          onClick={onSubmit}
        >
          Continue
        </button>
      </ScrollableModal.Footer>
    </>
  );
}

export { MakePaymentForm };
