import { organisationKey } from '@/config/query-keys';
import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import { Model } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useWeekModels(queryOptions?: UseQueryOptions<Model[], HttpError>) {
  return useQuery({
    queryKey: organisationKey.weekModels,
    queryFn: organisationService.listWeekModels,
    ...queryOptions,
  });
}

export { useWeekModels };
