import { FallbackComponent } from '@/components/fallback-component';
import { Logo } from '@/components/logo';
import { LogOut } from '@/components/parent/log-out';
import { PoweredBy } from '@/components/powered-by';
import * as Sentry from '@sentry/react';
import { Outlet, useLocation } from 'react-router-dom';

function BoxedLayout() {
  const location = useLocation();

  return (
    <div className="mx-auto max-w-[800px] sm:mt-5 sm:px-4">
      <div className="border-gray-200 bg-white px-4 py-3 sm:rounded-xl sm:border md:px-20 md:py-16">
        <header>
          <Logo className="h-12" />
        </header>
        <main>
          <Sentry.ErrorBoundary
            fallback={props => <FallbackComponent {...props} />}
            key={location.pathname}
            showDialog
          >
            <Outlet />
          </Sentry.ErrorBoundary>
        </main>
      </div>
      <div className="mt-4">
        <LogOut />
      </div>
      <footer className="py-12 text-center">
        <PoweredBy />
      </footer>
    </div>
  );
}

export { BoxedLayout };
