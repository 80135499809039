import { locationService } from '@/services/location.service';
import { HttpError } from '@/types/error';
import { CreateResourceDto, Resource } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCreateLocationResource(
  mutationOptions?: UseMutationOptions<
    Resource,
    HttpError,
    { data: CreateResourceDto; operationId: string }
  >
) {
  return useMutation({
    mutationFn: ({
      data,
      operationId,
    }: {
      data: CreateResourceDto;
      operationId: string;
    }) =>
      locationService.createLocationResource({
        operationId,
        data,
      }),
    ...mutationOptions,
  });
}

export { useCreateLocationResource };
