import { axiosClient } from '@/libs/axios';
import { HttpError } from '@/types/error';
import { Area, CreateAreaDto, UpdateAreaDto } from '@admissions-support/types';
import { AxiosResponse } from 'axios';

const createArea = async (data: CreateAreaDto): Promise<Area> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<Area>,
    CreateAreaDto
  >('/areas', data);

  return response.data;
};

const getArea = async (id: string): Promise<Area> => {
  const response = await axiosClient.get<HttpError, AxiosResponse<Area>>(
    `/areas/${id}`
  );

  return response.data;
};

const getAreas = async (): Promise<Area[]> => {
  const response = await axiosClient.get<HttpError, AxiosResponse<Area[]>>(
    '/areas'
  );

  return response.data;
};

const updateArea = async ({
  id,
  data,
}: {
  id: string;
  data: UpdateAreaDto;
}): Promise<Area> => {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<Area>,
    UpdateAreaDto
  >(`/areas/${id}`, data);

  return response.data;
};

const deleteArea = async (id: string) => {
  await axiosClient.delete<HttpError>(`/areas/${id}`);
};

export const areaService = Object.freeze({
  createArea,
  getArea,
  getAreas,
  updateArea,
  deleteArea,
});
