import {
  defaultApplicationControl,
  useApplicationControl,
} from '@/context/application-control.context';
import { ApplicationStatus } from '@admissions-support/types';
import { ChangeEvent } from 'react';
import { CheckboxBase } from '../form/common/checkbox';
import { ApplicationStatusTag } from './application-status-tag';

function StatusFilter() {
  const { filter, setFilter } = useApplicationControl();

  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedStatus = event.target.name as ApplicationStatus;

    const index = filter.statuses.indexOf(selectedStatus);
    if (index === -1) {
      setFilter(prevFilter => ({
        ...prevFilter,
        statuses: [...prevFilter.statuses, selectedStatus],
      }));
    } else {
      setFilter(prevFilter => ({
        ...prevFilter,
        statuses: prevFilter.statuses.filter(
          status => status !== selectedStatus
        ),
      }));
    }
  };

  const handleSelectAllStatus = () => {
    if (filter.statuses.length === statuses.length) {
      setFilter(prevFilter => ({
        ...prevFilter,
        statuses: defaultApplicationControl.filter.statuses,
      }));
    } else {
      setFilter(prevFilter => ({
        ...prevFilter,
        statuses: statuses.map(s => s.value),
      }));
    }
  };

  const isSomeSelected = statuses.some(status =>
    filter.statuses.includes(status.value)
  );

  return (
    <>
      <div className="max-h-96 space-y-2 overflow-y-auto">
        {statuses.map(status => {
          return (
            <CheckboxBase
              key={status.value}
              name={status.value}
              label={
                <ApplicationStatusTag status={status.value}>
                  {status.label}
                </ApplicationStatusTag>
              }
              onChange={handleStatusChange}
              checked={filter.statuses.findIndex(s => s === status.value) >= 0}
            />
          );
        })}
      </div>
      <hr className="!my-4" />
      <CheckboxBase
        name="selectAllStatus"
        label="Select All"
        onChange={handleSelectAllStatus}
        checked={filter.statuses.length === statuses.length}
        indeterminate={isSomeSelected}
      />
    </>
  );
}

const statuses: { label: string; value: ApplicationStatus }[] = [
  { label: 'Draft', value: 'DRAFT' },
  { label: 'Submitted', value: 'SUBMITTED' },
  { label: 'Placed', value: 'PLACED' },
  { label: 'Granted', value: 'GRANTED' },
  { label: 'Not Placed', value: 'NOT_PLACED' },
  { label: 'Placement Rejected', value: 'PLACEMENT_REJECTED' },
];

export { StatusFilter, statuses };
