import { Tag } from '@/components/tag';
import { AdditionalQuestion as AdditionalQuestionType } from '@admissions-support/types';
import { useState } from 'react';
import { AdditionalQuestionForm } from './elements/additional-question-form';

type AdditionalQuestionProps = AdditionalQuestionType;

function AdditionalQuestion({
  id,
  label,
  type,
  options,
}: AdditionalQuestionProps) {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const isOptionsAllowed =
    type === 'radio' || type === 'checkbox' || type === 'select';
  if (isEdit) {
    return (
      <div className="rounded-md border p-4">
        <AdditionalQuestionForm
          initialData={{
            id,
            label,
            type,
            options,
          }}
          onClose={() => setIsEdit(false)}
        />
      </div>
    );
  }

  return (
    <div className="group rounded-md border p-4">
      <div className="mb-2 flex justify-between">
        <Tag type="outline" className="my-1 capitalize">
          {type}
        </Tag>

        <button
          className="btn btn-secondary btn-sm hidden group-hover:block"
          type="button"
          onClick={() => setIsEdit(true)}
        >
          Edit
        </button>
      </div>
      <p className="text-md font-semibold">Question Label</p>
      <p className="text-gray-500">{label}</p>

      {isOptionsAllowed && (
        <>
          <p className="text-md mt-4 font-semibold">Question Options</p>
          {options.length > 0 && (
            <div className="space-x-1">
              {options.map(o => (
                <Tag type="outline" key={o} className="px-1 py-0.5">
                  {o}
                </Tag>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export { AdditionalQuestion };
