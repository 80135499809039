import { organisationKey } from '@/config/query-keys';
import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import { Organisation, UpdateRatioDto } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateRatioMutation(
  mutationOptions?: UseMutationOptions<
    Organisation,
    HttpError,
    { id: string; data: UpdateRatioDto }
  >
) {
  return useMutation({
    mutationKey: organisationKey.updateRatio,
    mutationFn: organisationService.updateRatio,
    ...mutationOptions,
  });
}

export { useUpdateRatioMutation };
