import { childKey } from '@/config/query-keys';
import { childService } from '@/services/child.service';
import { HttpError } from '@/types/error';
import { AssignationMeta } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useChildAssignationMeta(
  id: string,
  queryOptions?: UseQueryOptions<AssignationMeta, HttpError>
) {
  return useQuery({
    queryKey: childKey.assignationMeta(id || ''),
    queryFn: () => childService.getChildAssignationMeta(id || ''),
    ...queryOptions,
  });
}

export { useChildAssignationMeta };
