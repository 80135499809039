import { userKeys } from '@/config/query-keys';
import { userService } from '@/services/user.service';
import { useMutation } from '@tanstack/react-query';

function usePhoneNumberMutation() {
  return useMutation({
    mutationKey: userKeys.phoneNumberMutation,
    mutationFn: userService.changePhoneNumber,
  });
}

export { usePhoneNumberMutation };
