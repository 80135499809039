import { DeleteZone } from '@/components/delete-zone';
import { SchoolYearForm } from '@/components/form/school-year/school-year.form';
import { PageTitle } from '@/components/page-title';
import { Spinner } from '@/components/spinner';
import { organisationKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useCreateTermMutation } from '@/hooks/create-hooks/use-create-term-mutation';
import { useDeleteTermMutation } from '@/hooks/delete-hooks/use-delete-term';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useTerm } from '@/hooks/query-hooks/use-term';
import { useUpdateTermMutation } from '@/hooks/update-hooks/use-update-term';
import { queryClient } from '@/libs/react-query';
import { Permission } from '@/types/auth';
import { getErrorMessage } from '@/utils/get-error-message';
import {
  CreateSchoolYearDto,
  UpdateSchoolYearDto,
} from '@admissions-support/types';
import { useEffect } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

async function invalidateTermsQuery() {
  await queryClient.invalidateQueries({
    queryKey: organisationKey.terms,
    refetchType: 'active',
  });
}

function UpsertTerm() {
  const navigate = useNavigate();
  const { mutateAsync: create, isPending: isCreating } = useCreateTermMutation({
    onSuccess: invalidateTermsQuery,
  });
  const { mutateAsync: update, isPending: isUpdating } = useUpdateTermMutation({
    onSuccess: invalidateTermsQuery,
  });
  const params = useParams<{ id?: string }>();
  const isCreateTermView = useMatch(staffRouterPath.NEW_TERM);

  const {
    isLoading: isSchoolYearLoading,
    data: term,
    error,
  } = useTerm(params.id);

  useEffect(() => {
    if (error) {
      navigate(staffRouterPath.TERMS);
    }
  }, [error, navigate]);

  const {
    mutateAsync: deleteTerm,
    isPending: isDeleting,
    error: deleteError,
  } = useDeleteTermMutation({
    onSuccess: async () => {
      queryClient.removeQueries({
        queryKey: organisationKey.term(params.id || ''),
      });
      await invalidateTermsQuery();
      navigate(staffRouterPath.TERMS);
    },
    retry: 0,
  });

  const { hasPermission } = usePermissions();

  const isLoading =
    isCreating ||
    isUpdating ||
    !hasPermission([Permission['organisation:update']]);

  const handleCreateTerm = async (data: CreateSchoolYearDto) => {
    await create(data);
    navigate(staffRouterPath.TERMS);
  };

  const handleUpdateTerm = async (data: UpdateSchoolYearDto) => {
    await update({
      id: params.id || '',
      data,
    });
  };

  const handleCancel = () => {
    navigate(staffRouterPath.TERMS);
  };

  const handleDeleteTerm = async () => {
    await deleteTerm(params.id || '');
  };

  return (
    <div>
      <PageTitle
        title={isCreateTermView ? 'New School Year' : 'Edit School Year'}
        variant="gray"
      >
        <div className="flex gap-3">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCancel}
            disabled={isLoading}
          >
            Cancel
          </button>

          <button
            className="btn btn-primary"
            disabled={isLoading}
            form="upsert-term-form"
          >
            {isCreateTermView ? 'Create' : 'Update'}
          </button>
        </div>
      </PageTitle>

      {isSchoolYearLoading && !isCreateTermView ? (
        <div className="flex justify-center py-12">
          <Spinner className="h-12 w-12" />
        </div>
      ) : (
        <>
          {isCreateTermView ? (
            <SchoolYearForm onSubmit={handleCreateTerm} isLoading={isLoading} />
          ) : (
            <SchoolYearForm
              onSubmit={handleUpdateTerm}
              initialData={term}
              isLoading={isLoading}
            />
          )}
          {!isCreateTermView &&
          hasPermission([Permission['organisation:delete']]) ? (
            <div className="two-col-form">
              <div>
                <p className="text-md font-medium leading-7 text-gray-900">
                  Destructive Actions
                </p>
                <p className="text-md leading-6 text-gray-600">
                  Take care with these actions as they are destructive and may
                  not be reversable.
                </p>
              </div>
              <div className="col-span-2 space-y-6">
                <DeleteZone
                  title="Delete School Year"
                  actionButtonText="Delete School Year"
                  description="This action cannot be completed if there are locations using this School Year."
                  modalDescription="In order to delete this School Year, please type DELETE in the input below and confirm deletion."
                  onDelete={handleDeleteTerm}
                  isLoading={isDeleting}
                  error={getErrorMessage(deleteError)}
                />
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}

export { UpsertTerm };
