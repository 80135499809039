import { Detail } from '@/components/application/drawer/detail';
import { useReportingOutputControl } from '@/context/reporting-output-control.context';
import { ChildSiblings } from '@admissions-support/types';
import { format } from 'date-fns';

type SiblingDetailsProps = {
  sibling: ChildSiblings;
};

function SiblingDetails(props: SiblingDetailsProps) {
  const { sibling } = props;

  const { toggledColumns } = useReportingOutputControl();

  return (
    <div className="space-y-4">
      {toggledColumns['siblings.firstName'] ? (
        <Detail label="First Name" content={sibling.firstName || '--'} />
      ) : null}
      {toggledColumns['siblings.lastName'] ? (
        <Detail label="Last Name" content={sibling.lastName || '--'} />
      ) : null}
      {toggledColumns['siblings.dateOfBirth'] ? (
        <Detail
          label="Date of Birth"
          content={
            sibling.dateOfBirth
              ? format(new Date(sibling.dateOfBirth), 'dd/MM/yyyy')
              : '--'
          }
        />
      ) : null}
      {toggledColumns['siblings.sex'] ? (
        <Detail label="Sex" content={sibling.sex || '--'} />
      ) : null}
      {toggledColumns['siblings.school.name'] ? (
        <Detail
          label="School"
          content={sibling.school ? sibling.school?.name : '--'}
        />
      ) : null}
      {toggledColumns['siblings.stage'] ? (
        <Detail label="Stage" content={sibling.stage ? sibling.stage : '--'} />
      ) : null}
    </div>
  );
}

export { SiblingDetails };
