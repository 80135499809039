import { activityLogKey } from '@/config/query-keys';
import { activityLogService } from '@/services/activity-log.service';
import { HttpError } from '@/types/error';
import { ActivityLog, CreateActivityLogDto } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCreateActivityLogMutation(
  mutationOptions?: UseMutationOptions<
    ActivityLog,
    HttpError,
    CreateActivityLogDto
  >
) {
  return useMutation({
    mutationKey: activityLogKey.create,
    mutationFn: activityLogService.createActivityLog,
    ...mutationOptions,
  });
}

export { useCreateActivityLogMutation };
