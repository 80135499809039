import {
  isAdditionalDetailsDisabled,
  isHouseholdDetailsDisabled,
  isParentCarerDisabled,
  isPlacementPreferencesDisabled,
} from '@/components/application-navigation';
import { FallbackComponent } from '@/components/fallback-component';
import { LoadingScreen } from '@/components/loading-screen';
import { Logo } from '@/components/logo';
import { MobileNavigation } from '@/components/mobile-navigation';
import { LogOut } from '@/components/parent/log-out';
import { PoweredBy } from '@/components/powered-by';
import { applicationKey } from '@/config/query-keys';
import { parentRouterPath, staffRouterPath } from '@/config/route-paths.config';
import { useApplication } from '@/hooks/query-hooks/use-application';
import { NavigationItem } from '@/types/navigation';
import * as Sentry from '@sentry/react';
import { useIsMutating } from '@tanstack/react-query';
import {
  AnnotationInfo,
  ArrowLeft,
  FaceContent,
  GraduationHat02,
  Home02,
  User01,
  UserPlus02,
} from '@untitled-ui/icons-react';
import { useMemo } from 'react';
import {
  Navigate,
  Outlet,
  generatePath,
  matchPath,
  useParams,
  Link,
} from 'react-router-dom';
import { ParentsApplicationNavigation } from '../../parent/parents-application-navigation';
import { BreadcrumbNode } from '@/types/breadcrumb';
import { Breadcrumbs } from '@/components/breadcrumbs';

function ParentsApplicationLayout() {
  const params = useParams<{ id: string }>();
  const isCreateChildScreen = Boolean(
    matchPath(parentRouterPath.APPLICATION_CREATE_CHILD, location.pathname)
  );
  const {
    data: application,
    isLoading,
    isError,
  } = useApplication(params.id || '', {
    enabled: Boolean(params.id),
  });

  const nbOfMutation = useIsMutating({
    mutationKey: applicationKey.updateApplication,
  });

  const navigationItems: NavigationItem[] = useMemo(
    () => [
      {
        name: 'Child Details',
        href: params.id
          ? generatePath(staffRouterPath.APPLICATION_CHILD_DETAILS, {
              id: params.id,
            })
          : staffRouterPath.NEW_APPLICATION,
        icon: FaceContent,
        disabled: isCreateChildScreen,
      },
      {
        name: 'Parent/Carer Details',
        href: generatePath(staffRouterPath.APPLICATION_CARER, {
          id: params.id || '',
        }),
        icon: User01,
        disabled: isParentCarerDisabled(application) || isCreateChildScreen,
      },
      {
        name: 'Household Details',
        href: generatePath(staffRouterPath.APPLICATION_HOUSEHOLD_DETAILS, {
          id: params.id || '',
        }),
        icon: Home02,
        disabled:
          isHouseholdDetailsDisabled(application) || isCreateChildScreen,
      },
      {
        name: 'Placement Preferences',
        href: generatePath(staffRouterPath.APPLICATION_PLACEMENT_PREFERENCES, {
          id: params.id || '',
        }),
        icon: GraduationHat02,
        disabled:
          isPlacementPreferencesDisabled(application) || isCreateChildScreen,
      },
      {
        name: 'Additional Details',
        href: generatePath(staffRouterPath.APPLICATION_ADDITIONAL_DETAILS, {
          id: params.id || '',
        }),
        icon: AnnotationInfo,
        disabled:
          isAdditionalDetailsDisabled(application) || isCreateChildScreen,
      },
    ],
    [application, isCreateChildScreen, params.id]
  );

  if (isCreateChildScreen) {
    navigationItems.unshift({
      name: 'Add Child',
      href: parentRouterPath.APPLICATION_CREATE_CHILD,
      icon: UserPlus02,
    });
  }

  if (isLoading && Boolean(params.id)) {
    return (
      <div className="flex h-full flex-col items-center overflow-y-scroll bg-gray-50">
        <div className="container mx-auto">
          <header className="hidden pt-3 lg:block">
            <Logo className="h-12" />
          </header>
        </div>
        <LoadingScreen className="flex-1" />
      </div>
    );
  }

  if (isError) {
    return <Navigate to="/404" />;
  }

  const breadcrumbPaths: BreadcrumbNode[] = [
    {
      content: 'Children',
      link: parentRouterPath.ROOT,
    },
    {
      content: 'Add Child',
    },
  ];

  return (
    <div className="h-full overflow-y-scroll bg-gray-50">
      <MobileNavigation navigation={navigationItems} bottomNavigation={[]} />
      <div className="container mx-auto">
        <header className="hidden items-center justify-between pt-3 lg:flex">
          <Logo className="h-12" />
          {!isCreateChildScreen && (
            <button
              className="btn btn-secondary"
              form="upsert-application-form"
              disabled={Boolean(nbOfMutation)}
            >
              Save changes
            </button>
          )}
        </header>
        <div className="mt-20 flex  flex-col items-start justify-between gap-6 sm:flex-row sm:items-center lg:mt-6">
          <Breadcrumbs path={breadcrumbPaths} />
          <Link
            to={parentRouterPath.ROOT}
            className="btn btn-secondary flex w-fit items-center"
          >
            <ArrowLeft
              viewBox="0 0 24 24"
              className="mr-2 h-5 w-5 text-gray-500"
            />
            Back to Children
          </Link>
        </div>
        <div className="mt-6 flex gap-8">
          <aside className="sticky top-6 hidden h-full flex-shrink-0 flex-grow-0 basis-72 lg:block">
            <ParentsApplicationNavigation navigation={navigationItems} />
          </aside>
          <main className="w-full">
            <Sentry.ErrorBoundary
              fallback={props => <FallbackComponent {...props} />}
              key={location.pathname}
              showDialog
            >
              <Outlet />
            </Sentry.ErrorBoundary>
          </main>
        </div>
        <div className="mt-4">
          <LogOut />
        </div>
        <footer className="py-12 text-center">
          <PoweredBy />
        </footer>
      </div>
    </div>
  );
}

export { ParentsApplicationLayout };
