import { HttpError } from '@/types/error';

export const getErrorMessage = (
  error: HttpError | null
): string | undefined => {
  if (!error) {
    return undefined;
  }

  if (Array.isArray(error.message)) {
    return error.message.join(', ');
  }

  return error.message;
};
