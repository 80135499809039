import { Tag } from '@/components/tag';
import { generateClassByCapacity } from '@/utils/location-utils';
import {
  FlatResourceCapacity,
  FlatResourceDemandReport,
} from '@admissions-support/types';
import { twMerge } from 'tailwind-merge';

function DemandResourcingTableCell({
  resource,
  day,
}: {
  resource: FlatResourceDemandReport;
  day: keyof FlatResourceCapacity;
}) {
  // These are most likely be numbers, but we want to display "-" if they are not numbers
  const capacity = resource.capacity[day]?.capacity;
  const demand = resource.capacity[day]?.demand;
  const hasDataForDay = capacity !== undefined && demand !== undefined;
  const demandValue = Number.isNaN(demand) ? '-' : demand;
  const capacityValue = Number.isNaN(capacity) ? '-' : capacity;

  const tooltipValue = !hasDataForDay
    ? ''
    : `${demandValue} demand for ${capacityValue} capacity`;

  if (!hasDataForDay) {
    return <>-</>;
  }

  return (
    <div>
      <div
        className="inline-flex items-center gap-2"
        data-tooltip-content={tooltipValue}
        data-tooltip-id="capacity-resourcing-tooltip"
      >
        <Tag
          type="default"
          className={twMerge(
            generateClassByCapacity(capacity || 0, demand || 0),
            'FOOO h-[24px] min-w-10 p-0 text-center font-mono text-sm'
          )}
        >
          {demandValue}
        </Tag>
        <span className="text-sm font-medium text-gray-900">/</span>
        <Tag
          type="default"
          className="h-[24px] min-w-10 p-0 text-center font-mono text-sm"
        >
          {capacityValue}
        </Tag>
      </div>
    </div>
  );
}

export { DemandResourcingTableCell };
