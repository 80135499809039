import { uniqueId } from 'lodash-es';
import {
  InputHTMLAttributes,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { useFormContext } from 'react-hook-form';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string | React.ReactNode;
  helperText?: string | React.ReactNode;
  indeterminate?: boolean;
  labelClassName?: string;
}

const CheckboxBase = forwardRef<HTMLInputElement, CheckboxProps>(
  function CheckboxBase(
    { label, className, name, helperText, indeterminate, id, ...rest },
    forwardedRef
  ) {
    const ref = useRef<HTMLInputElement | null>(null);

    useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement);

    useEffect(() => {
      if (!ref.current) {
        return;
      }

      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate;
      }
    }, [ref, indeterminate, rest.checked]);

    const randomId = id || uniqueId(`${name}_`);

    return (
      <fieldset className={className}>
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id={randomId}
              type="checkbox"
              className="checkbox"
              name={name}
              ref={ref}
              {...rest}
            />
          </div>
          <div className="ml-3">
            <label htmlFor={randomId} className="text-gray-700">
              {label}
            </label>
            {helperText ? (
              <p className="text-sm text-gray-600">{helperText}</p>
            ) : null}
          </div>
        </div>
      </fieldset>
    );
  }
);

function Checkbox({ className, name, ...rest }: CheckboxProps) {
  const { register } = useFormContext();

  return (
    <CheckboxBase
      id={uniqueId(`${name}_`)}
      type="checkbox"
      className={className}
      {...register(name)}
      {...rest}
    />
  );
}

export { Checkbox, CheckboxBase };
