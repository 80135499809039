import { Detail } from '@/components/application/drawer/detail';
import { useReportingOutputControl } from '@/context/reporting-output-control.context';
import { Session } from '@admissions-support/types';
import { format } from 'date-fns';

type SessionsDetailsProps = {
  sessions: Session[];
};

function SessionsDetails(props: SessionsDetailsProps) {
  const { sessions } = props;

  const { toggledColumns } = useReportingOutputControl();

  return (
    <div className="space-y-5">
      {sessions.map((session, idx) => {
        return (
          <div key={idx} className="space-y-5">
            <p className="text-lg font-medium">{session.name}</p>
            <div className="space-y-2 rounded-md bg-gray-50 p-5">
              {toggledColumns['sessions.name'] ? (
                <Detail label="Session Name" content={session.name} />
              ) : null}
              {toggledColumns['sessions.model.name'] ? (
                <Detail
                  label="Session Model Name"
                  content={session.model.name}
                />
              ) : null}
              {toggledColumns['sessions.type.name'] ? (
                <Detail label="Session Type Name" content={session.type.name} />
              ) : null}
              {toggledColumns['sessions.times.monday.start'] ? (
                <Detail
                  label="Monday start"
                  content={
                    session.times.monday?.start
                      ? format(new Date(session.times.monday.start), 'p')
                      : '--'
                  }
                />
              ) : null}
              {toggledColumns['sessions.times.monday.end'] ? (
                <Detail
                  label="Monday end"
                  content={
                    session.times.monday?.end
                      ? format(new Date(session.times.monday.end), 'p')
                      : '--'
                  }
                />
              ) : null}
              {toggledColumns['sessions.times.tuesday.start'] ? (
                <Detail
                  label="Tuesday start"
                  content={
                    session.times.tuesday?.start
                      ? format(new Date(session.times.tuesday.start), 'p')
                      : '--'
                  }
                />
              ) : null}
              {toggledColumns['sessions.times.tuesday.end'] ? (
                <Detail
                  label="Tuesday end"
                  content={
                    session.times.tuesday?.end
                      ? format(new Date(session.times.tuesday.end), 'p')
                      : '--'
                  }
                />
              ) : null}
              {toggledColumns['sessions.times.wednesday.start'] ? (
                <Detail
                  label="Wednesday start"
                  content={
                    session.times.wednesday?.start
                      ? format(new Date(session.times.wednesday.start), 'p')
                      : '--'
                  }
                />
              ) : null}
              {toggledColumns['sessions.times.wednesday.end'] ? (
                <Detail
                  label="Wednesday end"
                  content={
                    session.times.wednesday?.end
                      ? format(new Date(session.times.wednesday.end), 'p')
                      : '--'
                  }
                />
              ) : null}
              {toggledColumns['sessions.times.thursday.start'] ? (
                <Detail
                  label="Thursday start"
                  content={
                    session.times.thursday?.start
                      ? format(new Date(session.times.thursday?.start), 'p')
                      : '--'
                  }
                />
              ) : null}
              {toggledColumns['sessions.times.thursday.end'] ? (
                <Detail
                  label="Thursday end"
                  content={
                    session.times.thursday?.end
                      ? format(new Date(session.times.thursday.end), 'p')
                      : '--'
                  }
                />
              ) : null}
              {toggledColumns['sessions.times.friday.start'] ? (
                <Detail
                  label="Friday start"
                  content={
                    session.times.friday?.start
                      ? format(new Date(session.times.friday.start), 'p')
                      : '--'
                  }
                />
              ) : null}
              {toggledColumns['sessions.times.friday.end'] ? (
                <Detail
                  label="Friday end"
                  content={
                    session.times.friday?.end
                      ? format(new Date(session.times.friday?.end), 'p')
                      : '--'
                  }
                />
              ) : null}
              {toggledColumns['sessions.times.saturday.start'] ? (
                <Detail
                  label="Saturday start"
                  content={
                    session.times.saturday?.start
                      ? format(new Date(session.times.saturday.start), 'p')
                      : '--'
                  }
                />
              ) : null}
              {toggledColumns['sessions.times.saturday.end'] ? (
                <Detail
                  label="Saturday end"
                  content={
                    session.times.saturday?.end
                      ? format(new Date(session.times.saturday.end), 'p')
                      : '--'
                  }
                />
              ) : null}
              {toggledColumns['sessions.times.sunday.start'] ? (
                <Detail
                  label="Sunday start"
                  content={
                    session.times.sunday?.start
                      ? format(new Date(session.times.sunday.start), 'p')
                      : '--'
                  }
                />
              ) : null}
              {toggledColumns['sessions.times.sunday.end'] ? (
                <Detail
                  label="Sunday end"
                  content={
                    session.times.sunday?.end
                      ? format(new Date(session.times.sunday.end), 'p')
                      : '--'
                  }
                />
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export { SessionsDetails };
