import { organisationKey } from '@/config/query-keys';
import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import {
  IntakeWithSchoolYear,
  WhichIntakeDto,
} from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useEligibility(
  data: WhichIntakeDto,
  queryOptions?: Partial<UseQueryOptions<IntakeWithSchoolYear, HttpError>>
) {
  return useQuery({
    queryKey: organisationKey.eligibility(data),
    queryFn: () => organisationService.getEligibility(data),
    ...queryOptions,
  });
}

export { useEligibility };
