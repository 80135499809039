import {
  Disclosure as HeadlessUIDisclosure,
  Transition,
} from '@headlessui/react';
import { MinusCircle, PlusCircle } from '@untitled-ui/icons-react';
import { isFunction } from 'lodash-es';

type DisclosureProps = {
  title: string;
  content: React.ReactNode;
  defaultOpen?: boolean;
  onChange?: (status: boolean) => void;
};

function Disclosure(props: DisclosureProps) {
  const { title, content, defaultOpen, onChange } = props;

  return (
    <HeadlessUIDisclosure as="div" defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <HeadlessUIDisclosure.Button
            className="flex w-full justify-between py-6 text-lg font-medium text-gray-900"
            onClick={() => {
              if (isFunction(onChange)) {
                onChange(!open);
              }
            }}
          >
            {title}

            {!open && (
              <PlusCircle aria-hidden="true" className="block text-gray-400" />
            )}
            {open && (
              <MinusCircle aria-hidden="true" className="block text-gray-400" />
            )}
          </HeadlessUIDisclosure.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <HeadlessUIDisclosure.Panel className="pb-6">
              {content}
            </HeadlessUIDisclosure.Panel>
          </Transition>
        </>
      )}
    </HeadlessUIDisclosure>
  );
}

export { Disclosure };
