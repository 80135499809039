import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { Permission } from '@/types/auth';
import {
  Application,
  ApplicationTemplate,
  UpdateApplicationDto,
} from '@admissions-support/types';
import { ArrowRight } from '@untitled-ui/icons-react';
import { FormProvider } from 'react-hook-form';
import {
  ChildAddress,
  ChildMedicalDetails,
  LocalAuthorityDetails,
  LookedAfterStatus,
} from '../application/additional-child-details/additional-child-details.element';
import { useAdditionalChildDetailsForm } from '../application/additional-child-details/use-additional-child-details.form';

type AdditionalChildDetailsFormProps = {
  onSubmit: (data: UpdateApplicationDto) => any;
  initialData: Application;
  template: ApplicationTemplate;
};

function AdditionalChildDetailsForm(props: AdditionalChildDetailsFormProps) {
  const { initialData, onSubmit, template } = props;
  const { form, handleSubmit, saveAsDraft } = useAdditionalChildDetailsForm({
    initialData,
    onSubmit,
    template,
  });

  const { hasPermission } = usePermissions();

  const formDisabled =
    form.formState.isSubmitting ||
    !hasPermission([Permission['application:update']]);

  return (
    <FormProvider {...form}>
      <form onSubmit={saveAsDraft} id="upsert-application-form">
        <div className="two-col-form">
          <ChildAddress disabled={formDisabled} />
        </div>

        {template.enabledQuestions.includes('local-authority-details') && (
          <div className="two-col-form">
            <LocalAuthorityDetails disabled={formDisabled} />
          </div>
        )}
        <div className="two-col-form">
          <ChildMedicalDetails disabled={formDisabled} />
        </div>

        <div className="two-col-form">
          <LookedAfterStatus disabled={formDisabled} />
        </div>
        <button
          className="btn btn-primary ml-auto mt-6 flex items-center"
          type="button"
          onClick={handleSubmit}
          disabled={formDisabled}
        >
          Continue to Next Session
          <ArrowRight viewBox="0 0 24 24" className="ml-2 h-5 w-5 text-white" />
        </button>
      </form>
    </FormProvider>
  );
}

export { AdditionalChildDetailsForm };
