import { Table } from '@/components/table';
import { CapacityDemandTableRow } from '@/types/capacity-simulation';
import {
  convertReport,
  getUniqueGroups,
} from '@/utils/capacity-simulation-utils';
import { DemandReports } from '@admissions-support/types';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { useMemo } from 'react';
import {
  AvailabilityRangeCell,
  CapacityRangeCell,
  CapacityTableActionsCell,
  CapacityTableHeaderCell,
  CapacityTableLocationCell,
  CapacityTableValueCell,
} from './capacity-table-cells';
import DemandResourceTable from './demand-resource-table';
import { DemandResourcingTable } from './demand-resourcing-table';

type DemandTableProps = {
  report: DemandReports;
  isLoading?: boolean;
};

function renderExpandedRow(
  demandReport: DemandReports,
  row: Row<CapacityDemandTableRow>
) {
  const currentDemandReport = demandReport?.[row.index];
  return (
    <tr className="flex w-full bg-gray-50">
      <td
        colSpan={row.getVisibleCells().length}
        className="w-full p-0"
        style={{ padding: 0 }}
      >
        <div className="flex w-full flex-col gap-7 p-6">
          <div className="flex flex-col gap-6">
            <DemandResourcingTable report={currentDemandReport} />

            {currentDemandReport?.detailedAllocation?.map(
              detailedAllocation => (
                <DemandResourceTable
                  key={detailedAllocation.cohort.id.toString()}
                  detailedAllocation={detailedAllocation}
                />
              )
            )}
          </div>
        </div>
      </td>
    </tr>
  );
}

function DemandTable({ report, isLoading }: DemandTableProps) {
  const columnHelper = createColumnHelper<CapacityDemandTableRow>();
  const tableData = convertReport(report);
  const uniqueGroups = getUniqueGroups(tableData);

  const columns = useMemo(
    () => [
      // SEED Code
      columnHelper.accessor('seedCode', {
        cell: info => {
          const name = info.getValue();
          return <CapacityTableValueCell text={name} />;
        },
        header: () => <CapacityTableHeaderCell text="SEED Code" />,
        enableSorting: false,
      }),

      // Location
      columnHelper.accessor('location', {
        cell: info => {
          const location = info.getValue();
          return (
            <CapacityTableLocationCell
              locationId={location?.id}
              locationName={location?.name}
              areaNames={location?.areas.map(area => area.name)}
            />
          );
        },
        header: () => <CapacityTableHeaderCell text="Location" />,
        enableSorting: false,
      }),

      // Physical Capacity
      columnHelper.accessor('physicalCapacity', {
        cell: info => {
          const capacity = info.getValue();
          return <CapacityTableHeaderCell text={capacity.toString()} />;
        },
        header: () => <CapacityTableHeaderCell text="Physical Capacity" />,
        enableSorting: false,
      }),

      // Resource Range
      columnHelper.group({
        id: 'resourceRange',
        header: () => (
          <CapacityTableHeaderCell text="Resource Range" isGroupHeader={true} />
        ),
        columns: uniqueGroups.map(group =>
          columnHelper.accessor(
            row => row.capacityAndAvailabilitydata[group]?.capacityRange,
            {
              id: `resourceRange_${group}`,
              header: () => (
                <CapacityTableHeaderCell text={group} isGroupHeader={true} />
              ),
              cell: info => {
                const value = info.getValue();
                return <CapacityRangeCell min={value?.min} max={value?.max} />;
              },
              enableSorting: false,
            }
          )
        ),
      }),

      // Availability Range
      columnHelper.group({
        id: 'availability',
        header: () => (
          <CapacityTableHeaderCell
            text="Availability Summary"
            isGroupHeader={true}
          />
        ),
        columns: uniqueGroups.map(group =>
          columnHelper.accessor(
            row => row.capacityAndAvailabilitydata[group]?.availabilityRange,
            {
              id: `availabilityRange_${group}`,
              header: () => (
                <CapacityTableHeaderCell text={group} isGroupHeader={true} />
              ),
              cell: info => {
                const value = info.getValue();
                return (
                  <AvailabilityRangeCell min={value?.min} max={value?.max} />
                );
              },
              enableSorting: false,
            }
          )
        ),
      }),

      // Actions
      columnHelper.display({
        id: 'actions',
        header: () => <CapacityTableHeaderCell text="Actions" />,
        cell: ({ row: { toggleExpanded, getIsExpanded } }) => {
          return (
            <CapacityTableActionsCell
              isExpanded={getIsExpanded()}
              expandRow={toggleExpanded}
            />
          );
        },
        enableSorting: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnHelper]
  );

  return (
    <Table
      columns={columns}
      data={tableData}
      paginationType="auto"
      renderExpandedRow={row => renderExpandedRow(report, row)}
      className="mt-0"
      isFetching={isLoading}
    />
  );
}

export { DemandTable };
