import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { Permission } from '@/types/auth';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/auth.context';
import { LoadingScreen } from '../loading-screen';

type PermissionGuardProps = {
  children: React.ReactNode;
  requiredPermissions: Permission[];
  /**
   * If it is true we dont redirect users with unsufficient permission,
   * just render nothing (hide UI elements)
   */
  redirectNotAllowed?: boolean;
};

function PermissionGuard(props: PermissionGuardProps) {
  const { children, redirectNotAllowed, requiredPermissions } = props;
  const { isLoading } = useAuth();
  const { hasPermission } = usePermissions();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!hasPermission(requiredPermissions) && !redirectNotAllowed) {
    return null;
  }

  if (
    import.meta.env.VITE_ENVIRONMENT === 'development' ||
    import.meta.env.VITE_ENVIRONMENT === 'local'
  ) {
    const missingPermissions = requiredPermissions.filter(
      permission => !hasPermission([permission])
    );

    if (missingPermissions.length > 0) {
      console.log('Missing permissions:', missingPermissions);
    }
  }

  if (!hasPermission(requiredPermissions)) {
    return <Navigate to="/404" />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export { PermissionGuard };
