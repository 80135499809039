import { Controller, FormProvider, useForm } from 'react-hook-form';
import { TextInput } from './common/text-input';
import { Textarea } from './common/textarea';
import { Dropzone } from './common/dropzone';
import { Link, useNavigate } from 'react-router-dom';
import { staffRouterPath } from '@/config/route-paths.config';
import { twMerge } from 'tailwind-merge';
import { useSearchParentByNameAndEmail } from '@/hooks/query-hooks/use-search-parent-by-name-and-email';
import { CustomSelect } from '../custom-select';
import { useMemo, useState } from 'react';
import { useChildrenByParentId } from '@/hooks/query-hooks/use-children-by-parent-id';
import { messageCategories } from '../messaging/message-categories';
import { yupResolver } from '@hookform/resolvers/yup';
import { createMessageFormSchema } from '../messaging/create-message.schema';
import { useCreateMessage } from '@/hooks/create-hooks/use-create-message';
import { InferType } from 'yup';
import { CreateTicketRequest } from '@admissions-support/types';

function StaffCreateMessageForm() {
  const form = useForm({
    resolver: yupResolver(createMessageFormSchema),
    mode: 'onBlur',
    defaultValues: {
      recipient: '',
      title: '',
      topic: '',
      childId: null,
      message: '',
    },
  });

  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const { data: parents, isLoading: isParentListLoading } =
    useSearchParentByNameAndEmail({
      search,
    });

  const recipientId = form.watch('recipient');
  const { data: children } = useChildrenByParentId(recipientId, {
    enabled: !!recipientId,
  });

  const { mutateAsync: createMessage, isPending: isCreatingMessage } =
    useCreateMessage({ userId: recipientId, isParent: false });

  const recipientOptions = useMemo(
    () =>
      parents?.map(parent => ({
        value: `${parent.firstName} ${parent.lastName} (${parent.email})`,
        key: parent.id,
      })) || [],
    [parents]
  );

  const childrenOptions = useMemo(
    () =>
      children?.map(child => ({
        value: child.name,
        key: child.id,
      })) || [],
    [children]
  );

  const relatesToOptions = useMemo(
    () =>
      Object.entries(messageCategories).map(([key, value]) => ({
        value: value,
        key: key,
      })),
    []
  );

  const isValid = form.formState.isValid;

  const submitHandler = async (
    data: InferType<typeof createMessageFormSchema>
  ) => {
    await createMessage({
      childId: data.childId,
      message: data.message,
      title: data.title,
      topic: data.topic as CreateTicketRequest['topic'],
      attachments: data.attachments || [],
    });

    navigate(staffRouterPath.MESSAGING);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)}>
        <div className="flex flex-col gap-8">
          <Controller
            control={form.control}
            name="recipient"
            render={({ field }) => (
              <CustomSelect
                label="Recipient*"
                options={recipientOptions}
                isSearchable
                onInputChange={setSearch}
                isLoading={isParentListLoading}
                isClearable
                error={form.formState.errors.recipient?.message?.toString()}
                {...field}
              />
            )}
          />
          <TextInput
            name="title"
            label="Title*"
            placeholder="Descriptive title"
            helperText="Short descriptive title, max 25 words"
          />
          <div className="flex w-full gap-8">
            <div className="w-full">
              <Controller
                control={form.control}
                name="topic"
                render={({ field }) => (
                  <CustomSelect
                    label="Relates To*"
                    options={relatesToOptions}
                    helperText="Select the category that best represents your query"
                    error={form.formState.errors.topic?.message?.toString()}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="w-full">
              <Controller
                control={form.control}
                name="childId"
                render={({ field }) => (
                  <CustomSelect
                    label="Child"
                    options={childrenOptions}
                    helperText="If your question is specific to an individual child"
                    isDisabled={!recipientId || childrenOptions.length === 0}
                    isClearable
                    error={form.formState.errors.childId?.message?.toString()}
                    {...field}
                    value={field.value || ''}
                  />
                )}
              />
            </div>
          </div>
          <Textarea
            name="message"
            label="Description*"
            helperText="Provide as much detail as possible to help us support your quicker"
            rows={4}
            placeholder="Enter a description..."
          />
          <Dropzone
            name="attachments"
            label="Attachments"
            path="attachments"
            helperText="If you would like to attach any documents or images to support your query you can do so"
            options={{
              accept: {
                'image/jpeg': [],
                'image/png': [],
                'application/pdf': [],
              },
            }}
            bucket="messaging-files"
            fileRestrictionLabel="Only jpg, png, pdf files are accepted."
          />
        </div>
        <div className="mt-8 flex justify-end">
          <div className="flex gap-4">
            <Link to={staffRouterPath.MESSAGING} className="btn btn-secondary">
              Cancel
            </Link>
            <button
              className={twMerge(
                'btn btn-primary',
                (form.formState.isSubmitting || isCreatingMessage) && 'loading'
              )}
              type="submit"
              disabled={!isValid}
            >
              Submit Ticket
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export { StaffCreateMessageForm };
