import { GetAllocationReportStat } from '@admissions-support/types';

type PercentageFields =
  | 'choice_1_percentage'
  | 'choice_2_percentage'
  | 'choice_3_percentage'
  | 'choice_prescribed_percentage'
  | 'choice_none_percentage';

export const colorsToColumns: Record<
  keyof Omit<GetAllocationReportStat, PercentageFields>,
  string
> = {
  choice_1: ' #ebe9fe',
  choice_2: ' #bdb4fe',
  choice_3: ' #7a5af8',
  choice_prescribed: ' #5925dc',
  choice_none: '#4a1fb8',
  total: '#3e1c96',
};

type ColorCodeProps = {
  color: string;
  text: string;
};

function ColorCode({ color, text }: ColorCodeProps) {
  return (
    <div className="flex items-center gap-2">
      <div
        className={`h-[10px] w-[10px] rounded-[3px]`}
        style={{ backgroundColor: color }}
      />
      <span className="text-sm text-gray-600">{text}</span>
    </div>
  );
}

export { ColorCode };
