import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Spinner } from './spinner';

type DrawerProps = {
  open: boolean;
  onClose: () => any;
  title?: string;
  description?: string;
  children: React.ReactNode;
  onDrawerClosed?: () => void;
  isLoading?: boolean;
  size?: 'md' | '3xl';
};

function Drawer(props: DrawerProps) {
  const {
    title,
    description,
    onClose,
    open,
    children,
    isLoading,
    onDrawerClosed,
    size = 'md',
  } = props;

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={onDrawerClosed}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  className={`pointer-events-auto w-screen ${
                    size === 'md' ? 'max-w-md' : ''
                  } ${size === '3xl' ? 'max-w-3xl' : ''}`}
                >
                  <div className="relative flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    {isLoading && (
                      <div className="absolute inset-0 z-10 flex h-full w-full items-center justify-center bg-white/80">
                        <Spinner className="h-10 w-10 text-white" />
                      </div>
                    )}
                    {title || description ? (
                      <div className="px-4 sm:px-6">
                        <div>
                          {title ? (
                            <Dialog.Title className="text-xl font-medium text-gray-900">
                              {title}
                            </Dialog.Title>
                          ) : null}

                          {description ? (
                            <Dialog.Description className="text-sm">
                              {description}
                            </Dialog.Description>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { Drawer };
