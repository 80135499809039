import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactNode, useState } from 'react';
import { XClose } from '@untitled-ui/icons-react';
import { CustomSelect } from '@/components/custom-select';
import { BulkActionType } from '@/components/application/bulk-action/bulk-action.type';
import { BulkPlacementDecisionForm } from '@/components/application/bulk-action/bulk-placement-decision.form';
import { BulkMessageApplicantsForm } from '@/components/application/bulk-action/bulk-message-applicants.form';

type MessageApplicantsProps = {
  applicantIds: string[];
  onClose: () => void;
};

function MessageApplicants({ applicantIds, onClose }: MessageApplicantsProps) {
  return (
    <BulkMessageApplicantsForm applicantIds={applicantIds} onClose={onClose} />
  );
}

type PlacementDecisionProps = {
  applicantIds: string[];
  onClose: () => void;
};

function PlacementDecision({ applicantIds, onClose }: PlacementDecisionProps) {
  return (
    <BulkPlacementDecisionForm applicantIds={applicantIds} onClose={onClose} />
  );
}

type BulkActionModalProps = {
  open: boolean;
  onClose: () => void;
  selectedApplicantIds: string[];
};

const bulkActionOptions: { key: BulkActionType; value: string }[] = [
  {
    key: 'placementDecision',
    value: 'Placement Decision',
  },
  {
    key: 'messageApplicants',
    value: 'Message Applicants',
  },
];

function BulkActionModal(props: BulkActionModalProps) {
  const { open, onClose, selectedApplicantIds } = props;
  const [selectedBulkAction, setSelectedBulkAction] =
    useState<BulkActionType | null>(null);

  const handleClose = () => {
    setSelectedBulkAction(null);
    onClose();
  };

  const bulkActionToComponentMap: Record<BulkActionType, ReactNode> = {
    placementDecision: (
      <PlacementDecision
        applicantIds={selectedApplicantIds}
        onClose={handleClose}
      />
    ),
    messageApplicants: (
      <MessageApplicants
        applicantIds={selectedApplicantIds}
        onClose={handleClose}
      />
    ),
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-60" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="flex w-full justify-between gap-4">
                  <span className="text-lg font-semibold text-primary-900">
                    Apply Actions
                  </span>
                  <button
                    type="button"
                    className="focus:ring-indigo-500 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Close</span>
                    <XClose className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-9">
                  <CustomSelect
                    name={'bulkAction'}
                    label={'Bulk Action'}
                    options={bulkActionOptions}
                    value={''}
                    onChange={data =>
                      setSelectedBulkAction(data as BulkActionType)
                    }
                  />

                  {selectedBulkAction ? (
                    <div className="mt-5">
                      <div className="flex flex-col gap-2">
                        {bulkActionToComponentMap[selectedBulkAction] || null}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { BulkActionModal };
