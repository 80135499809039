import { Helmet } from 'react-helmet-async';
import { twMerge } from 'tailwind-merge';

type PageTitleProps = {
  title: string;
  variant: 'white' | 'gray';
  description?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
};

function PageTitle(props: PageTitleProps) {
  const { title, description, children, variant, className } = props;
  const grayClasses = 'border-b border-gray-200 bg-gray-25';
  const whiteClasses = 'bg-white';

  return (
    <div
      className={twMerge(
        '-mx-4 -my-6 flex flex-col gap-4 px-4 py-6 sm:-mx-6 sm:flex-row sm:items-center sm:px-6 lg:-mx-8 lg:px-8',
        variant === 'white' ? whiteClasses : grayClasses,
        className
      )}
    >
      <div>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <h1 className="my-1.5 text-lg font-bold text-gray-900">{title}</h1>
        {description ? description : null}
      </div>
      <div className="sm:ml-auto">{children}</div>
    </div>
  );
}

export { PageTitle };
