import { useSettingsSubnavigation } from '@/hooks/use-settings-subnavigation';
import { Navigate } from 'react-router-dom';

/**
 * Navigate the user for the first navigation which is available
 * in their role. If there is none, redirect to home
 */
function SettingsMain() {
  const navigation = useSettingsSubnavigation();

  if (navigation.length === 0) {
    return <Navigate to="/" />;
  }

  return <Navigate to={navigation[0].href} />;
}

export { SettingsMain };
