import { axiosClient } from '@/libs/axios';
import { HttpError } from '@/types/error';
import {
  CreateSessionTypeDto,
  SessionType,
  UpdateSessionTypeDto,
} from '@admissions-support/types';
import { AxiosResponse } from 'axios';

async function listSessionType(): Promise<SessionType[]> {
  const response = await axiosClient.get<SessionType[]>(`/session-types`);

  return response.data;
}

async function getSessionType(id: string): Promise<SessionType> {
  const response = await axiosClient.get<SessionType>(`/session-types/${id}`);

  return response.data;
}

async function createSessionType(data: CreateSessionTypeDto) {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<SessionType>,
    CreateSessionTypeDto
  >(`/session-types`, data);

  return response.data;
}

async function updateSessionType({
  id,
  data,
}: {
  id: string;
  data: UpdateSessionTypeDto;
}) {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<SessionType>,
    UpdateSessionTypeDto
  >(`/session-types/${id}`, data);

  return response.data;
}

async function removeSessionType(id: string) {
  await axiosClient.delete(`/session-types/${id}`);
}

export const sessionTypeService = Object.freeze({
  listSessionType,
  getSessionType,
  createSessionType,
  updateSessionType,
  removeSessionType,
});
