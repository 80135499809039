import { applicationKey } from '@/config/query-keys';
import { applicationService } from '@/services/application.service';
import { HttpError } from '@/types/error';
import { Choice, UpdateChoiceDto } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateChoiceMutation(
  mutationOptions?: UseMutationOptions<
    Choice,
    HttpError,
    {
      applicationId: string;
      choiceId: string;
      data: UpdateChoiceDto;
    }
  >
) {
  return useMutation({
    mutationKey: applicationKey.updateChoice,
    mutationFn: applicationService.updateChoice,
    ...mutationOptions,
  });
}

export { useUpdateChoiceMutation };
