import { ChildCarer } from '@admissions-support/types';
import { Detail } from './detail';
import { formatApplicationAddress } from '@/utils/application-utils';

type ParentDetailsProps = {
  data: ChildCarer;
};

function ParentDetails(props: ParentDetailsProps) {
  const { data } = props;

  return (
    <div className="space-y-4">
      <Detail label="First Name" content={data.firstName || '--'} />
      <Detail label="Last Name" content={data.lastName || '--'} />
      <Detail
        label="Address"
        content={data.address ? formatApplicationAddress(data.address) : '--'}
      />
      <Detail
        label="Relationship to Child"
        content={data.relationship || '--'}
      />
      <Detail
        label="Phone Number"
        content={
          <a href={`tel:${data.mobile}`} target="_blank" rel="noreferrer">
            {data.mobile}
          </a>
        }
      />
      <Detail
        label="Email Address"
        content={
          <a href={`mailto:${data.email}`} target="_blank" rel="noreferrer">
            {data.email}
          </a>
        }
      />
    </div>
  );
}

export { ParentDetails };
