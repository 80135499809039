import { locationKey } from '@/config/query-keys';
import { useSchoolYear } from '@/context/school-year.context';
import { locationService } from '@/services/location.service';
import { HttpError } from '@/types/error';
import { CreateSessionDto, Location } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

function useCreateSessionMutation(
  mutationOptions?: UseMutationOptions<
    Location,
    HttpError,
    Omit<CreateSessionDto, 'schoolYearId'>
  >
) {
  const params = useParams<{ locationId: string }>();
  const locationId = params.locationId || '';
  const { schoolYear } = useSchoolYear();

  return useMutation({
    mutationKey: locationKey.createSession(locationId),
    mutationFn: (data: Omit<CreateSessionDto, 'schoolYearId'>) =>
      locationService.createSession({
        id: locationId,
        data: {
          ...data,
          schoolYearId: schoolYear.id,
        },
      }),
    ...mutationOptions,
  });
}

export { useCreateSessionMutation };
