import { useFile } from '@/hooks/use-file';
import { useFileUploadMutation } from '@/hooks/use-file-upload-mutation';
import { S3File } from '@/services/file.service';
import { Trash01, UploadCloud02, XCircle } from '@untitled-ui/icons-react';
import { omit } from 'lodash-es';
import { useEffect, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import {
  ArrayPath,
  FieldArray,
  get,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import toast from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';

type DropzoneBaseProps = {
  onFilesChange: (files: S3File[]) => any;
  path: string;
  files: S3File[];
  setError: (...args: any) => void;
  clearErrors: () => void;
  name?: string; // name prop should only be used if component is in a react-hook-form
  fileRestrictionLabel?: string;
  options?: Omit<DropzoneOptions, 'onDrop'>;
  className?: string;
  classes?: {
    root?: string;
  };
  disableRemoveButton?: boolean;
  error?: string;
  helperText?: string;
  disableFileUpload?: boolean;
  imageSizeRestriction?: {
    height?: number;
    width?: number;
  };
  bucket?: string;
};

const fileUploadMetaData: Record<string, any> = {
  maxSize: 10485760, // 10 mb
  errorMessages: {
    'file-too-large': 'File is larger than 10 Megabytes',
  },
};

function DropzoneBase(props: DropzoneBaseProps) {
  const {
    options,
    className,
    classes,
    fileRestrictionLabel,
    onFilesChange,
    files,
    disableRemoveButton,
    path,
    error,
    helperText,
    setError,
    name,
    clearErrors,
    disableFileUpload,
    imageSizeRestriction,
    bucket,
  } = props;

  const isInForm = Boolean(name);

  const {
    mutateAsync: uploadAsync,
    isPending: isFileUploading,
    isError: isUploadError,
  } = useFileUploadMutation();
  const [progress, setProgress] = useState<
    Record<string, { progress: number; size: number }>
  >({});

  const [queryKey, setQueryKey] = useState('');

  const {
    data: file,
    isSuccess: isFileSuccess,
    isError: isFileError,
  } = useFile(queryKey, bucket, {
    enabled: Boolean(queryKey),
  });

  const fileUrl = isFileSuccess ? file.url.toString() : undefined;
  useEffect(() => {
    if (isFileError) {
      toast.error('File preview failed. Please try again.');
    }

    if (!fileUrl) {
      return;
    }

    window.open(fileUrl, '_blank');
    setQueryKey('');
  }, [isFileError, fileUrl]);

  const handleDownload = async (key: string) => {
    setQueryKey(key);
  };

  useEffect(() => {
    if (isUploadError) {
      toast.error('File upload failed. Please try again.');
    }
  }, [isUploadError]);

  const cleanedFiles = files.map(file => omit(file, 'id'));

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      maxSize: fileUploadMetaData.maxSize,
      onDropRejected: err => {
        const customError = err[0].errors[0];

        if (!err[0]?.errors[0]) {
          return;
        }

        const errorMessage = Object.keys(fileUploadMetaData.errorMessages).find(
          errCode => errCode === customError.code
        );

        if (errorMessage) {
          customError.message = fileUploadMetaData.errorMessages[errorMessage];
        }

        if (isInForm) {
          setError(name, customError);
          return;
        }

        setError(customError.message);
      },
      onDrop: async (acceptedFiles: File[]) => {
        if (acceptedFiles.length === 0) {
          return;
        }

        clearErrors();

        //init progress object with 0
        const initialProgress = acceptedFiles.reduce(
          (init, file) => ({
            ...init,
            [file.name]: { progress: 0, size: file.size },
          }),
          {}
        );
        setProgress(initialProgress);

        const errors: string[] = [];

        const uploadPromises: Promise<S3File>[] = [];

        if (imageSizeRestriction) {
          let counter = 0;
          acceptedFiles.forEach(file => {
            const image = new Image();
            image.onload = async () => {
              if (
                imageSizeRestriction.width &&
                imageSizeRestriction.width < image.width
              ) {
                errors.push(
                  `Image width cannot exceed ${imageSizeRestriction.width} px`
                );
              }

              if (
                imageSizeRestriction.height &&
                imageSizeRestriction.height < image.height
              ) {
                errors.push(
                  `Image height cannot exceed ${imageSizeRestriction.height} px`
                );
              }
              if (errors.length > 0) {
                setError(name, { message: errors.join(', ') });
                setProgress({});
                return;
              }

              uploadPromises.push(
                uploadAsync({
                  fileName: file.name,
                  file: file,
                  path,
                  ...(bucket ? { bucket } : {}),
                  handleProgress: progressPercentage =>
                    setProgress(prev => ({
                      ...prev,
                      [file.name]: {
                        ...prev[file.name],
                        progress: progressPercentage,
                      },
                    })),
                })
              );

              counter++;

              if (counter === acceptedFiles.length) {
                //upload all files and handle progress bar
                const s3FileKeys = await Promise.all(uploadPromises);

                //we uploaded all the files so remove from progress object
                const updatedProgress = { ...progress };
                s3FileKeys.forEach(s3File => {
                  delete updatedProgress[s3File.filename];
                });

                onFilesChange([...s3FileKeys, ...cleanedFiles]);
                setProgress(updatedProgress);
              }
            };
            image.src = URL.createObjectURL(file);
          });
          return;
        }

        acceptedFiles.forEach(file => {
          uploadPromises.push(
            uploadAsync({
              fileName: file.name,
              file: file,
              path,
              ...(bucket ? { bucket } : {}),
              handleProgress: progressPercentage =>
                setProgress(prev => ({
                  ...prev,
                  [file.name]: {
                    ...prev[file.name],
                    progress: progressPercentage,
                  },
                })),
            })
          );
        });

        //upload all files and handle progress bar
        const s3FileKeys = await Promise.all(uploadPromises);

        //we uploaded all the files so remove from progress object
        const updatedProgress = { ...progress };
        s3FileKeys.forEach(s3File => {
          delete updatedProgress[s3File.filename];
        });

        onFilesChange([...s3FileKeys, ...cleanedFiles]);
        setProgress(updatedProgress);
      },
      ...options,
      disabled: disableFileUpload || options?.disabled || isFileUploading,
    });

  const hasError = Boolean(error);

  const handleDelete = (keyToDelete: string) => () => {
    const updatedFilesList = cleanedFiles.filter(
      file => file.key !== keyToDelete
    );

    onFilesChange(updatedFilesList);
  };

  const filesInProgress = Object.keys(progress).map((fileName: string) => {
    const actualProgress = (progress && progress[fileName]) || 0;

    return (
      <li key={fileName} className="flex rounded-xl border border-gray-200 p-4">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mr-3"
        >
          <path
            d="M4.5 4C4.5 2.067 6.067 0.5 8 0.5H23.7929L35.5 12.2071V36C35.5 37.933 33.933 39.5 32 39.5H8C6.067 39.5 4.5 37.933 4.5 36V4Z"
            fill="white"
            stroke="#D0D5DD"
          />
          <path
            d="M24.5 8V1.20711L34.7929 11.5H28C26.067 11.5 24.5 9.933 24.5 8Z"
            fill="white"
            stroke="#D0D5DD"
          />
          <path
            d="M12.8 24.8H27.2M12.8 24.8V21.6C12.8 20.7163 13.5163 20 14.4 20H17.6M12.8 24.8V28C12.8 28.8837 13.5163 29.6 14.4 29.6H17.6M27.2 24.8V28C27.2 28.8837 26.4836 29.6 25.6 29.6H17.6M27.2 24.8V21.6C27.2 20.7163 26.4836 20 25.6 20H17.6M17.6 20V29.6"
            stroke="#344054"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <div className="w-full">
          <p className="text-gray-70 flex justify-between text-sm font-medium">
            {fileName}
            {actualProgress.progress === 100 && (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="15"
                  height="15"
                  rx="7.5"
                  fill="#0D5743"
                />
                <path
                  d="M11.3334 5.5L6.75008 10.0833L4.66675 8"
                  stroke="white"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width="15"
                  height="15"
                  rx="7.5"
                  stroke="#0D5743"
                />
              </svg>
            )}
          </p>
          <p className="text-sm text-gray-600">{actualProgress.size} KB</p>
          <div className="flex">
            <div className="mt-2 h-2 w-full rounded-full bg-gray-200">
              <div
                className="h-full rounded-full bg-primary-600 text-center text-xs text-white"
                style={{
                  width: `${actualProgress.progress}%`,
                }}
              ></div>
            </div>
            <p className="w-10 text-right text-sm">
              {actualProgress.progress.toFixed(0)}%
            </p>
          </div>
        </div>
      </li>
    );
  });

  const uploadedFileList = files.map((file: S3File) => {
    return (
      <li key={file.key} className="flex rounded-xl border border-gray-200 p-4">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mr-3"
        >
          <path
            d="M4.5 4C4.5 2.067 6.067 0.5 8 0.5H23.7929L35.5 12.2071V36C35.5 37.933 33.933 39.5 32 39.5H8C6.067 39.5 4.5 37.933 4.5 36V4Z"
            fill="white"
            stroke="#D0D5DD"
          />
          <path
            d="M24.5 8V1.20711L34.7929 11.5H28C26.067 11.5 24.5 9.933 24.5 8Z"
            fill="white"
            stroke="#D0D5DD"
          />
          <path
            d="M12.8 24.8H27.2M12.8 24.8V21.6C12.8 20.7163 13.5163 20 14.4 20H17.6M12.8 24.8V28C12.8 28.8837 13.5163 29.6 14.4 29.6H17.6M27.2 24.8V28C27.2 28.8837 26.4836 29.6 25.6 29.6H17.6M27.2 24.8V21.6C27.2 20.7163 26.4836 20 25.6 20H17.6M17.6 20V29.6"
            stroke="#344054"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <div className="w-full">
          <p className="text-gray-70 flex justify-between text-sm font-medium">
            <button
              type="button"
              disabled={options?.disabled}
              onClick={() => handleDownload(file.key)}
            >
              {file.filename}
            </button>

            {disableRemoveButton ? (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="15"
                  height="15"
                  rx="7.5"
                  fill="#0D5743"
                />
                <path
                  d="M11.3334 5.5L6.75008 10.0833L4.66675 8"
                  stroke="white"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width="15"
                  height="15"
                  rx="7.5"
                  stroke="#0D5743"
                />
              </svg>
            ) : (
              <button
                onClick={handleDelete(file.key)}
                type="button"
                disabled={options?.disabled}
              >
                <span className="sr-only">Delete file</span>
                <Trash01
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-500"
                  viewBox="0 0 24 24"
                />
              </button>
            )}
          </p>
          <p className="h-5" />
          <div className="flex">
            <div className="mt-2 h-2 w-full rounded-full bg-gray-200">
              <div
                className="h-full rounded-full bg-primary-600 text-center text-xs text-white"
                style={{
                  width: '100%',
                }}
              ></div>
            </div>
            <p className="w-10 text-right text-sm">100%</p>
          </div>
        </div>
      </li>
    );
  });

  return (
    <div className="space-y-4">
      <div>
        <div
          {...getRootProps()}
          className={twMerge(
            'flex items-center justify-center rounded-xl border-2 border-dashed bg-white px-6 pb-6 pt-5',
            'hover:cursor-pointer',
            isDragActive ? 'border-primary-300' : 'border-gray-200',
            isDragReject ? 'border-red-500' : null,
            options?.disabled || isFileUploading || disableFileUpload
              ? 'cursor-not-allowed opacity-50 hover:cursor-not-allowed'
              : null,
            classes?.root,
            className
          )}
        >
          <div className="space-y-1 text-center">
            <div
              className={twMerge(
                'mx-auto flex h-10 w-10 items-center justify-center rounded-lg border shadow-sm',
                isDragReject ? 'border-red-500' : 'border-gray-200'
              )}
            >
              {isDragReject ? (
                <XCircle
                  className="text-red-500"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                />
              ) : (
                <UploadCloud02
                  className="-mb-1 text-gray-600"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                />
              )}
            </div>

            <div className="flex text-sm text-gray-600">
              <input {...getInputProps()} className="sr-only" />

              <p>
                {!isDragActive ? (
                  <span className="mr-1 font-bold text-primary-600">
                    Click to upload
                  </span>
                ) : null}
                {isDragActive ? 'Drop the files here ...' : 'or drag and drop'}
              </p>
            </div>

            <p className="text-xs text-gray-500">
              {fileRestrictionLabel
                ? fileRestrictionLabel
                : 'Any file up to 10MB'}
            </p>
          </div>
        </div>
        {helperText ? (
          <p className="mt-1.5 text-sm text-gray-600">{helperText}</p>
        ) : null}
        {hasError ? (
          <p className="mt-1.5 text-sm text-red-600">{error}</p>
        ) : null}
      </div>
      {Object.keys(filesInProgress).length > 0 && (
        <ul className="space-y-4">{filesInProgress}</ul>
      )}

      {uploadedFileList.length > 0 && (
        <ul className="space-y-4">{uploadedFileList}</ul>
      )}
    </div>
  );
}

function Dropzone<T extends Record<string, S3File[]>>(
  props: Omit<
    DropzoneBaseProps,
    'files' | 'onFilesChange' | 'error' | 'setError' | 'clearErrors'
  > & {
    name: ArrayPath<T>;
    label?: string;
  }
) {
  const { label, disableFileUpload, name, bucket, ...restProps } = props;
  const {
    control,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext<T>();
  const error = get(errors, name);

  const { fields, replace } = useFieldArray({
    name: name,
    control: control,
  });

  const handleChange = (files: S3File[]) => {
    replace(files as FieldArray<T, ArrayPath<T>>);
  };

  return (
    <div>
      {label ? (
        <label htmlFor={name} className="label mb-1.5">
          {label}
        </label>
      ) : null}
      <DropzoneBase
        //@ts-expect-error TODO: fix type
        files={fields || []}
        onFilesChange={handleChange}
        error={error ? error.message : undefined}
        setError={setError}
        clearErrors={clearErrors}
        name={name}
        disableFileUpload={disableFileUpload}
        bucket={bucket}
        {...restProps}
      />
    </div>
  );
}

export { Dropzone, DropzoneBase };
