import { organisationKey } from '@/config/query-keys';
import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import { CreateModelsDto, Organisation } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCreateWeekModelMutation(
  mutationOptions?: UseMutationOptions<Organisation, HttpError, CreateModelsDto>
) {
  return useMutation<Organisation, HttpError, CreateModelsDto>({
    mutationKey: organisationKey.createWeekModel,
    mutationFn: organisationService.createWeekModel,
    ...mutationOptions,
  });
}

export { useCreateWeekModelMutation };
