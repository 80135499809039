import { Copy03 } from '@untitled-ui/icons-react';
import { uniqueId } from 'lodash-es';
import toast from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';
import { Box } from '../box';

type CopyBookingIdProps = {
  childId: string;
};

function CopyBookingIdBase(props: CopyBookingIdProps) {
  const { childId } = props;

  const handleCopyValue = async () => {
    try {
      await navigator.clipboard.writeText(childId);
      toast.success('Copied!');
    } catch (error) {
      toast.error('Error copying to clipboard!');
    }
  };

  const tooltipId = uniqueId('copy-child-id-');

  return (
    <>
      <Tooltip
        anchorSelect={`#${tooltipId}`}
        content="Copy Ref Number"
        className="tooltip"
      />
      <button
        className="relative mt-auto flex"
        id={tooltipId}
        type="button"
        onClick={handleCopyValue}
      >
        <span className="input truncate bg-gray-50 pl-3 pr-10 text-start text-gray-500">
          {childId}
        </span>

        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
          <Copy03
            width={16}
            height={16}
            viewBox="0 0 24 24"
            aria-hidden="true"
          />
        </span>
      </button>
    </>
  );
}

function CopyBookingId(props: CopyBookingIdProps) {
  return (
    <Box className="flex w-full flex-col p-5">
      <p className="text-md font-semibold">Child Booking ID</p>
      <p className="mb-4 text-sm text-gray-600">Used as booking reference</p>

      <CopyBookingIdBase {...props} />
    </Box>
  );
}

export { CopyBookingId, CopyBookingIdBase };
