import { Switch } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

type ToggleProps = {
  value: boolean;
  onChange: (value: boolean) => void;
  label: string;
  description?: string;
  disabled?: boolean;
};

function Toggle(props: ToggleProps) {
  const { value, onChange, label, description, disabled } = props;
  return (
    <Switch.Group
      as="div"
      className="flex items-center justify-between space-x-2"
    >
      <Switch
        disabled={disabled}
        checked={value}
        onChange={onChange}
        className={twMerge(
          value
            ? 'border-primary-600 bg-primary-600'
            : 'border-gray-200 bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2 disabled:cursor-default disabled:border-gray-300 disabled:bg-gray-300'
        )}
      >
        <span
          aria-hidden="true"
          className={twMerge(
            value ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
      <span className="flex flex-grow flex-col">
        <Switch.Label
          as="span"
          className="text-sm font-medium leading-6 text-gray-700"
          passive
        >
          {label}
        </Switch.Label>
        {description ? (
          <Switch.Description as="span" className="text-sm text-gray-600">
            {description}
          </Switch.Description>
        ) : null}
      </span>
    </Switch.Group>
  );
}

export { Toggle };
