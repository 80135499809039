import { PageTitle } from '@/components/page-title';
import { ApplicationOverviewFormData } from '@/components/settings/application-templates/application-template-overview.type';
import { OverviewForm } from '@/components/settings/application-templates/elements/overview-form';
import { useApplicationTemplateOverview } from '@/components/settings/application-templates/use-application-template-overview.form';
import { applicationTemplateKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useCreateApplicationTemplateMutation } from '@/hooks/create-hooks/use-create-application-template-mutation';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { queryClient } from '@/libs/react-query';
import { Permission } from '@/types/auth';
import { LocationType } from '@admissions-support/types';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { generatePath, useNavigate } from 'react-router-dom';

async function invalidateApplicationTemplatesQuery() {
  await queryClient.invalidateQueries({
    queryKey: applicationTemplateKey.list,
    refetchType: 'active',
  });
}

function CreateApplicationTemplate() {
  const navigate = useNavigate();
  const {
    mutateAsync: create,
    isPending: isCreating,
    isSuccess: isCreated,
    data: createdTemplate,
  } = useCreateApplicationTemplateMutation();

  const { hasPermission } = usePermissions();

  const { form, reset } = useApplicationTemplateOverview();
  const isLoading = isCreating || !hasPermission([Permission['managers']]);

  const shouldRedirect = !form.formState.isDirty && isCreated;

  useEffect(() => {
    if (!isCreated) {
      return;
    }

    reset(createdTemplate);
    invalidateApplicationTemplatesQuery();
  }, [isCreated, createdTemplate, reset]);

  useEffect(() => {
    if (!shouldRedirect) {
      return;
    }

    navigate(
      generatePath(staffRouterPath.EDIT_APPLICATION_TEMPLATE, {
        id: createdTemplate.id,
      })
    );
  }, [shouldRedirect, navigate, createdTemplate]);

  const handleCancel = () => {
    navigate(staffRouterPath.APPLICATION_TEMPLATES);
  };

  const handleSubmit = async (data: ApplicationOverviewFormData) => {
    const formattedCohorts = Array.isArray(data.cohorts)
      ? data.cohorts.map(co => ({ id: co }))
      : [];

    await create({
      name: data.name,
      description: data.description || null,
      cohorts: formattedCohorts,
      applicationType: data.applicationType as LocationType,
    });

    await invalidateApplicationTemplatesQuery();
  };

  return (
    <div>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <PageTitle title="New Application Template" variant="gray">
            <div className="flex gap-3">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
                disabled={isLoading}
              >
                Cancel
              </button>

              <button className="btn btn-primary" disabled={isLoading}>
                Create
              </button>
            </div>
          </PageTitle>

          <OverviewForm />
        </form>
      </FormProvider>
    </div>
  );
}

export { CreateApplicationTemplate };
