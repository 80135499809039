import { Dialog, Transition } from '@headlessui/react';
import { XClose } from '@untitled-ui/icons-react';
import { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';

type ScrollableModalProps = {
  onClose: () => void;
  children: React.ReactNode;
  isOpen: boolean;
  /**
   * tailwind max-width used here (https://tailwindcss.com/docs/max-width)
   */
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
  afterLeave?: () => void;
};

function ScrollableModal(props: ScrollableModalProps) {
  const { children, onClose, isOpen, size, afterLeave } = props;

  const sizeClasses = {
    xs: 'sm:max-w-xs',
    sm: 'sm:max-w-sm',
    md: 'sm:max-w-md',
    lg: 'sm:max-w-lg',
    xl: 'sm:max-w-xl',
    '2xl': 'sm:max-w-2xl',
    '3xl': 'sm:max-w-3xl',
  };

  const sizeClass = sizeClasses[size] || 'sm:max-w-md';

  return (
    <Transition.Root show={isOpen} as={Fragment} afterLeave={afterLeave} appear>
      <Dialog as="div" className="relative z-60" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 backdrop-blur-sm transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-60 overflow-y-auto">
          <div className="flex h-full items-end justify-center text-center sm:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 translate-y-12 sm:translate-y-0"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-32 sm:translate-y-0"
            >
              <Dialog.Panel
                className={twMerge(
                  'pointer-events-auto relative flex max-h-[90vh]',
                  'w-full flex-col overflow-hidden rounded-t-3xl border-none',
                  'bg-white bg-clip-padding text-left shadow-xl outline-none sm:rounded-b-lg sm:rounded-t-lg',
                  sizeClass
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

type ModalHeaderProps = {
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
};

function ModalHeader(props: ModalHeaderProps) {
  const { onClose, children, className } = props;
  return (
    <div
      className={twMerge(
        'flex flex-shrink-0 items-start justify-between p-5 sm:p-6',
        className
      )}
    >
      {children}
      <button
        type="button"
        className="text-neutral-500 hover:text-neutral-800 focus:text-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300 box-content rounded-none border-none hover:no-underline focus:opacity-100 focus:shadow-none focus:outline-none"
        onClick={onClose}
        aria-label="Close"
      >
        <XClose className="text-gray-400 hover:text-gray-600" />
      </button>
    </div>
  );
}

type ModalFooterProps = {
  children: React.ReactNode;
  className?: string;
};

function ModalFooter(props: ModalFooterProps) {
  const { children, className } = props;
  return (
    <div
      className={twMerge('flex flex-shrink-0 flex-wrap p-5 sm:p-6', className)}
    >
      {children}
    </div>
  );
}

type ModalContentProps = {
  children: React.ReactNode;
  className?: string;
};

function ModalContent(props: ModalContentProps) {
  const { children, className } = props;

  return (
    <div
      className={twMerge(
        'relative overflow-y-auto overflow-x-hidden px-5 sm:px-6',
        className
      )}
    >
      {children}
    </div>
  );
}

type ModalTitleProps = {
  title: string;
  description?: string;
  className?: string;
};

function ModalTitle(props: ModalTitleProps) {
  const { title, description, className } = props;

  return (
    <div className={className}>
      <h5 className="text-lg font-semibold text-gray-900">{title}</h5>
      {description && <p className="text-sm text-gray-600">{description}</p>}
    </div>
  );
}

ScrollableModal.Header = ModalHeader;
ScrollableModal.Footer = ModalFooter;
ScrollableModal.Content = ModalContent;
ScrollableModal.Title = ModalTitle;

export { ScrollableModal };
