import { EligibilityForm } from '@/components/application/eligibility/eligibility-form';
import { useParams } from 'react-router-dom';

function Eligibility() {
  const { childId } = useParams<{ childId: string }>();

  return (
    <div>
      <h1 className="mb-8 mt-6 text-xl font-semibold text-gray-900">
        Select Starting Date
      </h1>

      <EligibilityForm childId={childId || ''} />
    </div>
  );
}

export { Eligibility };
