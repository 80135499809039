import { axiosClient } from '@/libs/axios';
import { HttpError } from '@/types/error';
import { CreateTransactionDto, Transaction } from '@admissions-support/types';
import { AxiosResponse } from 'axios';

async function createTransaction(
  data: CreateTransactionDto
): Promise<Transaction> {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<Transaction>,
    CreateTransactionDto
  >('/transactions', data);

  return response.data;
}

export const transactionService = Object.freeze({ createTransaction });
