import { applicationService } from '@/services/application.service';
import { HttpError } from '@/types/error';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useDeleteChoiceMutation(
  mutationOptions?: UseMutationOptions<
    void,
    HttpError,
    {
      applicationId: string;
      choiceId: string;
    }
  >
) {
  return useMutation({
    mutationFn: applicationService.deleteChoice,
    ...mutationOptions,
  });
}

export { useDeleteChoiceMutation };
