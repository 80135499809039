import { bookingKey } from '@/config/query-keys';
import { bookingService } from '@/services/booking.service';
import { HttpError } from '@/types/error';
import { ChildMeta } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useChildBookingMeta(
  childId: string,
  schoolYearId: string,
  queryOptions?: Partial<UseQueryOptions<ChildMeta, HttpError>>
) {
  return useQuery<ChildMeta, HttpError>({
    queryKey: bookingKey.childMeta(childId, schoolYearId),
    queryFn: () =>
      bookingService.getBookingMetaByChildId({ childId, schoolYearId }),
    ...queryOptions,
  });
}

export { useChildBookingMeta };
