import { axiosClient } from '@/libs/axios';
import { HttpError } from '@/types/error';
import {
  ApplicationTemplate,
  CreateApplicationTemplateAdditionalQuestionDto,
  CreateApplicationTemplateDto,
  UpdateApplicationTemplateAdditionalQuestionDto,
  UpdateApplicationTemplateDto,
} from '@admissions-support/types';
import { AxiosResponse } from 'axios';

async function list(): Promise<ApplicationTemplate[]> {
  const response = await axiosClient.get<ApplicationTemplate[]>(
    `/application-templates`
  );

  return response.data;
}

const create = async (
  data: CreateApplicationTemplateDto
): Promise<ApplicationTemplate> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<ApplicationTemplate>,
    CreateApplicationTemplateDto
  >(`/application-templates`, data);

  return response.data;
};

async function get(id: string): Promise<ApplicationTemplate> {
  const response = await axiosClient.get<ApplicationTemplate>(
    `/application-templates/${id}`
  );

  return response.data;
}

const update = async ({
  id,
  data,
}: {
  id: string;
  data: UpdateApplicationTemplateDto;
}): Promise<ApplicationTemplate> => {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<ApplicationTemplate>,
    UpdateApplicationTemplateDto
  >(`/application-templates/${id}`, data);

  return response.data;
};

const deleteTemplate = async (id: string) => {
  await axiosClient.delete(`/application-templates/${id}`);
};

const updateAdditionalQuestion = async ({
  templateId,
  questionId,
  data,
}: {
  templateId: string;
  questionId: string;
  data: UpdateApplicationTemplateAdditionalQuestionDto;
}): Promise<ApplicationTemplate> => {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<ApplicationTemplate>,
    UpdateApplicationTemplateAdditionalQuestionDto
  >(
    `/application-templates/${templateId}/additional-questions/${questionId}`,
    data
  );

  return response.data;
};

const deleteQuestion = async ({
  templateId,
  questionId,
}: {
  templateId: string;
  questionId: string;
}) => {
  await axiosClient.delete(
    `/application-templates/${templateId}/additional-questions/${questionId}`
  );
};

const createQuestion = async ({
  data,
  templateId,
}: {
  templateId: string;
  data: CreateApplicationTemplateAdditionalQuestionDto;
}): Promise<ApplicationTemplate> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<ApplicationTemplate>,
    CreateApplicationTemplateAdditionalQuestionDto
  >(`/application-templates/${templateId}/additional-questions`, data);

  return response.data;
};

export const applicationTemplateService = Object.freeze({
  list,
  create,
  get,
  update,
  delete: deleteTemplate,
  updateAdditionalQuestion,
  deleteQuestion,
  createQuestion,
});
