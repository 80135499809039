import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { HttpError } from '@/types/error';
import { RespondToTicketDto, Ticket } from '@admissions-support/types';
import { messagingService } from '@/services/messaging.service';
import { messagingKey } from '@/config/query-keys';

type RespondToTicketMutationOptions = {
  id: string;
  isParent?: boolean;
};

function useRespondToTicketMutation(
  options: RespondToTicketMutationOptions,
  mutationOptions?: UseMutationOptions<Ticket, HttpError, RespondToTicketDto>
) {
  return useMutation({
    mutationKey: messagingKey.respondToTicket,
    mutationFn: (data: RespondToTicketDto) =>
      messagingService.respondToTicket(options.id, data, options.isParent),
    ...mutationOptions,
  });
}

export { useRespondToTicketMutation };
