import {
  ApplicationControl,
  ApplicationFilter,
} from '@/types/application-filter';
import { DeepPartial } from '@/types/util';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { merge } from 'lodash-es';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSchoolYear } from './school-year.context';

type ApplicationControlContextState = ApplicationControl & {
  type: 'main' | 'nursery';
  setFilter: Dispatch<SetStateAction<ApplicationFilter>>;
  setSorting: Dispatch<SetStateAction<SortingState>>;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
};

function transformLocalStorageFilter(storedFilter: string): ApplicationControl {
  const parsedControlObject = JSON.parse(storedFilter) as ApplicationControl;

  // we need to parse date from string to Date type
  return {
    ...parsedControlObject,
    filter: {
      ...parsedControlObject.filter,
      upperDateOfBirth: parsedControlObject.filter?.upperDateOfBirth
        ? new Date(parsedControlObject.filter.upperDateOfBirth)
        : null,
      baseDateOfBirth: parsedControlObject.filter?.baseDateOfBirth
        ? new Date(parsedControlObject.filter.baseDateOfBirth)
        : null,
    },
  };
}

const defaultApplicationControl: ApplicationControl = {
  filter: {
    statuses: [],
    choices: [],
    locations: [],
    intakes: [],
    search: '',
    isSiblingAtLocation: false,
    isLookedAfter: false,
    choiceStatuses: [],
    baseDateOfBirth: null,
    upperDateOfBirth: null,
  },
  sorting: [
    {
      id: 'name',
      desc: false,
    },
  ],
  pagination: {
    pageIndex: 0,
    pageSize: 10,
  },
};

const ApplicationControlContext = createContext<
  ApplicationControlContextState | undefined
>(undefined);

function ApplicationControlProvider({
  children,
  type,
  defaultControl,
}: {
  children: React.ReactNode | React.ReactNode[];
  type: ApplicationControlContextState['type'];
  defaultControl?: DeepPartial<ApplicationControl>;
}) {
  const { schoolYear } = useSchoolYear();
  const localStorageKey = `admit_application_control_${type}`;
  const localStorageItem = localStorage.getItem(localStorageKey);

  let persistedApplicationControl: ApplicationControl;
  try {
    const programaticDefaultValue = localStorageItem
      ? transformLocalStorageFilter(localStorageItem)
      : defaultApplicationControl;

    persistedApplicationControl = merge(
      {},
      defaultApplicationControl,
      programaticDefaultValue,
      defaultControl
    );
  } catch (error) {
    console.error('Failed to parse localStorageItem:', error);
    persistedApplicationControl = merge(
      {},
      defaultApplicationControl,
      defaultControl
    );
  }

  const [pagination, setPagination] = useState<PaginationState>(
    persistedApplicationControl.pagination
  );

  const [sorting, setSorting] = useState<SortingState>(
    persistedApplicationControl.sorting
  );

  const [filter, setFilter] = useState<ApplicationFilter>(
    persistedApplicationControl.filter
  );

  const handleSetFilter = (state: SetStateAction<ApplicationFilter>) => {
    // navigate to the first page if filter changes
    setPagination(prev => ({ ...prev, pageIndex: 0 }));
    setFilter(state);
  };

  const updatedApplicationControl: ApplicationControl = useMemo(
    () => ({
      filter,
      sorting,
      pagination,
    }),
    [filter, sorting, pagination]
  );

  useEffect(() => {
    try {
      const serializedData = JSON.stringify(updatedApplicationControl);
      localStorage.setItem(localStorageKey, serializedData);
    } catch (error) {
      console.error('Failed to serialize application control state:', error);
    }
  }, [updatedApplicationControl, localStorageKey]);

  /**
   * We need to reset the filters if you change the school year,
   * because there are filters unique to school years. (eg.: intake)
   */
  const previousSchoolYearId = useRef(schoolYear.id);

  useEffect(() => {
    if (schoolYear.id === previousSchoolYearId.current) {
      return;
    }
    previousSchoolYearId.current = schoolYear.id;

    const resetedFilter = {
      ...defaultApplicationControl.filter,
      ...(type === 'nursery' ? { locations: filter.locations } : {}),
    };
    handleSetFilter(resetedFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolYear]);

  return (
    <ApplicationControlContext.Provider
      value={{
        ...updatedApplicationControl,
        setFilter: handleSetFilter,
        setPagination,
        setSorting,
        type,
      }}
    >
      {children}
    </ApplicationControlContext.Provider>
  );
}

const useApplicationControl = () => {
  const context = useContext(ApplicationControlContext);

  if (!context) {
    throw new Error(
      'useApplicationControl must be used within a ApplicationControlProvider'
    );
  }
  return context;
};

export {
  ApplicationControlProvider,
  defaultApplicationControl,
  useApplicationControl,
};
