import * as yup from 'yup';
import { PlacementActionType } from '@/components/application/bulk-action/bulk-action.type';

const bulkPlacementActionTypes: PlacementActionType[] = ['PLACED', 'GRANTED'];

export const bulkPlacementSchema = yup.object({
  applicantIds: yup
    .array(yup.string())
    .label('Selected applicants')
    .min(1)
    .required(),
  actionType: yup
    .string()
    .label('Action type')
    .oneOf(bulkPlacementActionTypes)
    .required(),
});

export const bulkMessageSchema = yup.object({
  applicantIds: yup
    .array(yup.string())
    .label('Selected applicants')
    .min(1)
    .required(),
  emailTemplateId: yup.string().label('Email template').required(),
});
