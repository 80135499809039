import { ParentHouseholdForm } from '@/components/application/houshold-details/parent-household.form';
import { Spinner } from '@/components/spinner';
import { staffRouterPath } from '@/config/route-paths.config';
import { useApplication } from '@/hooks/query-hooks/use-application';
import { useUpdateApplicationMutation } from '@/hooks/update-hooks/use-update-application-mutation';
import { Navigate, useParams } from 'react-router-dom';

function ParentHousehold() {
  const params = useParams<{ id: string }>();

  const {
    data: application,
    error: applicationError,
    isLoading: isApplicationLoading,
  } = useApplication(params.id || '', {
    enabled: Boolean(params.id),
  });

  const isFormLoading = isApplicationLoading;

  const { mutateAsync: updateApplication } = useUpdateApplicationMutation(
    application?.id || ''
  );

  if (applicationError) {
    return <Navigate to={staffRouterPath.APPLICATIONS} />;
  }

  return (
    <div>
      {!isFormLoading && application ? (
        <ParentHouseholdForm
          initialData={application}
          onSubmit={updateApplication}
        />
      ) : (
        <div className="flex items-center justify-center  px-4 py-20">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export { ParentHousehold };
