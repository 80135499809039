import { trusteeService } from '@/services/trustee.service';
import { HttpError } from '@/types/error';
import {
  TrusteeWithInvitation,
  UpdateTrusteeInvitationDto,
} from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateTrusteeMutation(
  mutationOptions?: UseMutationOptions<
    TrusteeWithInvitation,
    HttpError,
    { trusteeId: string; childId: string; data: UpdateTrusteeInvitationDto }
  >
) {
  return useMutation({
    mutationFn: trusteeService.updateTrustee,
    ...mutationOptions,
  });
}

export { useUpdateTrusteeMutation };
