import {
  CustomPaginateResult,
  GetStaffTicketRequest,
  ListTicketsResponseItem,
} from '@admissions-support/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HttpError } from '@/types/error';
import { messagingKey } from '@/config/query-keys';
import { messagingService } from '@/services/messaging.service';

function useMessages(
  { isParent = true }: { isParent?: boolean },
  params: GetStaffTicketRequest,
  queryOptions?: Partial<
    UseQueryOptions<CustomPaginateResult<ListTicketsResponseItem>, HttpError>
  >
) {
  return useQuery({
    queryKey: messagingKey.list(params, isParent),
    queryFn: () => messagingService.getMessages(isParent, params),
    ...queryOptions,
  });
}

export { useMessages };
