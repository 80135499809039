import { Alert } from '@/components/alert';
import { FormTabButtons, Tab } from '@/components/form-tab-buttons';
import { ScrollableModal } from '@/components/scroll-modal';
import { productKey } from '@/config/query-keys';
import { useCreateProductMutation } from '@/hooks/create-hooks/use-create-product-mutation';
import { useUpdateProductMutation } from '@/hooks/update-hooks/use-update-product-mutation';
import { queryClient } from '@/libs/react-query';
import { hasLocationExtraHourResourceForSession } from '@/utils/product-utils';
import {
  Operation,
  Product,
  ProductListing,
  Session,
  SessionType,
  UpdateProductDto,
  UpdateProductResponseType,
} from '@admissions-support/types';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ManageProductFormData } from '../manage-product-modal';
import { useManageProductForm } from '../use-manage-product.form';
import { CalendarElement } from './elements/calendar.element';
import { ConfirmationElement } from './elements/confirmation.element';
import { ProductDetailsElement } from './elements/product-details.element';

type ManageProductFormProps = {
  schoolYearId: string;
  sessions: Session[];
  sessionTypes: SessionType[];
  operation: Operation;
  onClose: () => void;
  initialProduct?: ProductListing | Product;
};

function ManageProductForm(props: ManageProductFormProps) {
  const {
    sessions,
    sessionTypes,
    schoolYearId,
    onClose,
    initialProduct,
    operation,
  } = props;

  const { locationId = '' } = useParams<string>();

  const [currentFormStep, setCurrentFormStep] = useState(0);

  const {
    mutateAsync: createProductMutation,
    isPending: isCreatingProduct,
    data: createdProduct,
    isSuccess: isCreateSuccess,
  } = useCreateProductMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: productKey.list,
      });
    },
  });

  const {
    mutateAsync: updateProductMutation,
    isPending: isUpdatingProduct,
    data: updatedProduct,
    isSuccess: isUpdateSuccess,
  } = useUpdateProductMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: productKey.list,
      });
    },
  });

  const handleManageProduct = async (
    data: ManageProductFormData
  ): Promise<{ created: number } | UpdateProductResponseType> => {
    let response: { created: number } | UpdateProductResponseType;

    if (initialProduct) {
      const cleanData: UpdateProductDto = {
        name: data.name,
        availableFrom: data.availableFrom,
        availableTo: data.availableTo,
        linkedSessionTypes: data.linkedSessionTypes,
        excludedDays: data.excludedDays,
        description: data.description,
      };
      response = await updateProductMutation({
        productId: initialProduct.id,
        data: cleanData,
      });
    } else {
      response = await createProductMutation({
        ...data,
        schoolYearId,
        locationId,
      });
    }

    setCurrentFormStep(prev => prev + 1);

    return response;
  };

  const { form, handleSubmit } = useManageProductForm({
    onSubmit: handleManageProduct,
    initialData: initialProduct,
  });

  const sessionTypesValues = sessionTypes.map(sessionType => ({
    label: sessionType.name,
    value: sessionType.id,
  }));

  const onClickPrevious = () => {
    if (currentFormStep === 0) {
      onClose();
      return;
    }
    if (currentFormStep === 1) {
      form.setValue('excludedDays', []);
    }

    setCurrentFormStep(prev => prev - 1);
  };

  const onClickNext = async () => {
    if (currentFormStep === 2) {
      onClose();
      return;
    }

    await form.trigger();

    const isError = Object.keys(form.formState.errors).length > 0;

    if (isError) {
      return;
    }

    setCurrentFormStep(prev => prev + 1);
  };

  const hasResourceAllocation = form.watch('hasResourceAllocation');

  const tabs: Tab[] = [
    {
      title: 'Create from Session',
      fieldValue: true,
    },
    {
      fieldValue: false,
      title: 'Top Up Session',
    },
  ];

  if (currentFormStep === 0) {
    const sessionId = form.watch('sessionId');
    const selectedSession = sessions.find(session => session.id === sessionId);

    const disableNextButton =
      !hasLocationExtraHourResourceForSession({
        session: selectedSession,
        extraHoursResource: operation.extraHoursResources,
      }) && hasResourceAllocation;

    return (
      <>
        <ScrollableModal.Header onClose={onClose}>
          <ScrollableModal.Title
            title={
              initialProduct
                ? 'Update Purchasable Product'
                : 'Add Purchasable Product'
            }
            description={'Specify additional sessions available for purchase'}
          />
        </ScrollableModal.Header>
        <ScrollableModal.Content>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
              {!initialProduct ? (
                <FormTabButtons tabs={tabs} fieldName="hasResourceAllocation" />
              ) : null}
              {hasResourceAllocation && (
                <p className="font-regular mt-2 pb-5 text-sm text-gray-600">
                  Create from Session will use an existing session to form the
                  basis for the product.
                </p>
              )}
              {!hasResourceAllocation && (
                <>
                  <p className="font-regular mt-2 pb-5 text-sm text-gray-600">
                    Top Up Session will not adhere to a session. Instead it will
                    be used to extend a session that a child is already
                    attending. Pay close attention to who you make it available
                    to.
                  </p>
                  <Alert
                    type="warning"
                    className="mb-4"
                    text={
                      <>
                        <p className="font-semibold">Attention!</p>
                        <p>
                          This product does not use resource, it is intended to
                          be used to extend sessions where the child is already
                          accounted for.
                        </p>
                      </>
                    }
                  />
                </>
              )}
              <ProductDetailsElement
                initialData={initialProduct}
                sessionTypesValues={sessionTypesValues}
                sessions={sessions}
                extraHoursResource={operation.extraHoursResources}
              />
            </form>
          </FormProvider>
        </ScrollableModal.Content>
        <ScrollableModal.Footer>
          <div className="flex w-full gap-3">
            <button
              className="btn btn-secondary w-full"
              type="button"
              onClick={onClickPrevious}
              disabled={isCreatingProduct || isUpdatingProduct}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary w-full"
              onClick={onClickNext}
              type="button"
              disabled={
                isCreatingProduct || isUpdatingProduct || disableNextButton
              }
            >
              Next
            </button>
          </div>
        </ScrollableModal.Footer>
      </>
    );
  }

  if (currentFormStep === 1) {
    return (
      <>
        <ScrollableModal.Header onClose={onClose}>
          <ScrollableModal.Title
            title="Confirm Sessions"
            description="Remove individual sessions if required."
          />
        </ScrollableModal.Header>
        <ScrollableModal.Content>
          <FormProvider {...form}>
            <form
              onSubmit={form.handleSubmit(handleSubmit)}
              id="manage-product-modal-form"
            >
              <CalendarElement />
            </form>
          </FormProvider>
        </ScrollableModal.Content>
        <ScrollableModal.Footer>
          <div className="flex w-full gap-3">
            <button
              className="btn btn-secondary w-full"
              type="button"
              onClick={onClickPrevious}
              disabled={isCreatingProduct || isUpdatingProduct}
            >
              Back
            </button>
            <button
              className="btn btn-primary w-full"
              type="submit"
              form="manage-product-modal-form"
              key="manage-product-modal-form-submit-button"
              disabled={isCreatingProduct || isUpdatingProduct}
            >
              Next
            </button>
          </div>
        </ScrollableModal.Footer>
      </>
    );
  }

  return (
    <>
      <ScrollableModal.Header onClose={onClose}>
        <ScrollableModal.Title title="Confirm Sessions" />
      </ScrollableModal.Header>
      <ScrollableModal.Content>
        <ConfirmationElement
          nbOfChangedExtraHours={
            isCreateSuccess
              ? createdProduct
              : isUpdateSuccess
              ? {
                  created: updatedProduct.created,
                  removed: updatedProduct.removed,
                }
              : { created: 0 }
          }
        />
      </ScrollableModal.Content>
      <ScrollableModal.Footer>
        <button
          className="btn btn-primary w-full"
          type="button"
          onClick={onClose}
        >
          Done
        </button>
      </ScrollableModal.Footer>
    </>
  );
}

export { ManageProductForm };
