import { LoginTitle } from '@/components/login-title';
import { SmsMfaSetup } from '@/components/sms-mfa-setup';
import { TotpMfaSetup } from '@/components/totp-mfa-setup';
import { MfaSetupRouterState } from '@/types/auth';
import { Label, Radio, RadioGroup } from '@headlessui/react';
import { Phone01, QrCode02 } from '@untitled-ui/icons-react';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

const settings = [
  {
    name: 'Authenticator',
    value: 'SOFTWARE_TOKEN_MFA',
    Icon: QrCode02,
  },
  {
    name: 'SMS',
    value: 'SMS_MFA',
    Icon: Phone01,
  },
];

function MFASetup() {
  const location = useLocation();
  const state = location.state as MfaSetupRouterState;

  const [selectedMFAType, setSelectedMFAType] = useState(
    state?.fromSmsVerifyScreen ? 'SMS_MFA' : 'SOFTWARE_TOKEN_MFA'
  );

  return (
    <>
      <Helmet>
        <title>MFA Setup</title>
      </Helmet>
      <LoginTitle
        title="Enable Two-Factor Authentication"
        description="In order to access this system, we require you to enable two-factor authentication. Select your preferred authentication method."
      />
      <RadioGroup value={selectedMFAType} onChange={setSelectedMFAType}>
        <Label className="sr-only">Select MFA type</Label>
        <div className="mb-3 mt-3 flex space-x-2 rounded-md bg-white">
          {settings.map(({ name, Icon, value }) => (
            <Radio
              key={name}
              value={value}
              className={({ checked }) =>
                twMerge(
                  checked
                    ? 'z-10 border-2 border-green-200 bg-green-50'
                    : 'border-gray-200',
                  'relative flex flex-1 cursor-pointer items-center rounded-xl border p-4 focus:outline-none'
                )
              }
            >
              {({ checked }) => (
                <span className="mr-3 flex flex-col">
                  <Label
                    as="span"
                    className={twMerge(
                      checked ? 'text-primary-800' : 'text-gray-700',
                      'flex items-center text-sm font-medium'
                    )}
                  >
                    <div
                      className={twMerge(
                        checked ? 'bg-success-100' : 'bg-primary-50',
                        'mr-4 flex h-8 w-8 items-center justify-center rounded-full'
                      )}
                    >
                      <Icon
                        className="h-4 w-4 text-primary-600"
                        viewBox="0 0 24 24"
                      />
                    </div>
                    {name}
                  </Label>
                </span>
              )}
            </Radio>
          ))}
        </div>
      </RadioGroup>

      {selectedMFAType === 'SOFTWARE_TOKEN_MFA' ? (
        <TotpMfaSetup />
      ) : (
        <SmsMfaSetup />
      )}
    </>
  );
}

export { MFASetup };
