import { TextInput } from '@/components/form/common/text-input';
import { HttpError, isBadRequest } from '@/types/error';
import { getNestedKeys } from '@/utils/get-nested-keys';
import { CreateModelsDto, Model } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

const schema = yup.object({
  name: yup.string().required().label('Name'),
  nbHoursPerWeek: yup
    .number()
    .required()
    .min(0)
    .label('Hours per Week')
    .typeError('Field should be a number'),
  additionalReadingURL: yup.string().nullable().label('Url'),
});

type WeekModelFormProps = {
  onSubmit: (data: CreateModelsDto) => Promise<void>;
  initialData?: Model;
  isLoading?: boolean;
};

function WeekModelForm(props: WeekModelFormProps) {
  const { initialData, isLoading, onSubmit } = props;

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: initialData?.name || '',
      nbHoursPerWeek: initialData?.nbHoursPerWeek,
      additionalReadingURL: initialData?.additionalReadingURL || '',
    },
  });

  const submitHandler = async (data: CreateModelsDto) => {
    try {
      await onSubmit(data);
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        if (!form.formState.defaultValues) {
          return;
        }

        const availableFields = getNestedKeys(form.formState.defaultValues);

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }
    }
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(submitHandler)}
        className="flex h-full flex-1 flex-col"
        id="upsert-user-group-form"
      >
        <div className="mt-6 bg-white pt-6">
          <div className="two-col-form pt-0">
            <div>
              <h2 className="text-md font-medium leading-7 text-gray-900">
                Details
              </h2>
              <p className="text-md leading-6 text-gray-600">
                Specify model details
              </p>
            </div>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="space-y-6 sm:col-span-4">
                <TextInput
                  name="name"
                  type="text"
                  label="Week Model Name*"
                  disabled={isLoading}
                />
                <TextInput
                  name="nbHoursPerWeek"
                  type="number"
                  label="Hours per week*"
                  disabled={isLoading}
                  min={0}
                />
              </div>
            </div>
          </div>
          <div className="two-col-form">
            <div>
              <h2 className="text-md font-medium leading-7 text-gray-900">
                Additional Reading
              </h2>
              <p className="text-md leading-6 text-gray-600">
                Optionally specify a url to direct the parent to more
                information. This makes the model name clickable for the parent.
              </p>
            </div>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="space-y-6 sm:col-span-4">
                <TextInput
                  name="additionalReadingURL"
                  type="text"
                  label="Url"
                  placeholder="https://myurl.com"
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export { WeekModelForm };
