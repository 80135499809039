import { invitationKeys } from '@/config/query-keys';
import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import { Invitation } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useResendInvitationMutation(
  id: string,
  mutationOptions?: UseMutationOptions<Invitation, HttpError>
) {
  return useMutation({
    mutationKey: invitationKeys.resend(id),
    mutationFn: () => organisationService.resendInvitation(id),
    ...mutationOptions,
  });
}

export { useResendInvitationMutation };
