import { applicationTemplateKey } from '@/config/query-keys';
import { applicationTemplateService } from '@/services/application-template.service';
import { HttpError } from '@/types/error';
import {
  ApplicationTemplate,
  UpdateApplicationTemplateAdditionalQuestionDto,
} from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateAdditionalQuestion(
  mutationOptions?: UseMutationOptions<
    ApplicationTemplate,
    HttpError,
    {
      templateId: string;
      questionId: string;
      data: UpdateApplicationTemplateAdditionalQuestionDto;
    }
  >
) {
  return useMutation({
    mutationKey: applicationTemplateKey.update,
    mutationFn: applicationTemplateService.updateAdditionalQuestion,
    ...mutationOptions,
  });
}

export { useUpdateAdditionalQuestion };
