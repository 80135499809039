import { bookingKey } from '@/config/query-keys';
import { bookingService } from '@/services/booking.service';
import { HttpError } from '@/types/error';
import { BookingInfo } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useChildBookingsInfo(
  childId: string,
  queryOptions?: Partial<UseQueryOptions<BookingInfo, HttpError>>
) {
  return useQuery({
    queryKey: bookingKey.metaByChild(childId),
    queryFn: () => bookingService.getBookingsMetaByChildId(childId),
    ...queryOptions,
  });
}

export { useChildBookingsInfo };
