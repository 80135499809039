import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { HttpError, isBadRequest } from '@/types/error';
import { getNestedKeys } from '@/utils/get-nested-keys';
import { UpsertOrganisationDetailsFormData } from './upsert-organisation-details.type';
import { upsertOrganisationDetailsFormSchema } from './upsert-organisation-details.schema';
import { OrganisationPublic } from '@admissions-support/types';
import { clone } from 'lodash-es';

type UseUpsertOrganisationDetailsFormProps = {
  onSubmit: (
    data: UpsertOrganisationDetailsFormData
  ) => Promise<OrganisationPublic>;
  initialValues: OrganisationPublic;
};

const defaultValues: UpsertOrganisationDetailsFormData = {
  name: '',
  isCouncilSplitPermitted: false,
  contactEmail: '',
  website: '',
  localAuthority: '',
  brandColour: '',
  logo: [],
  icon: [],
  additionalContacts: [],
  adminContacts: [],
};

const transformApiDataToFormValues = (
  data: OrganisationPublic
): UpsertOrganisationDetailsFormData => {
  const transformedData = clone(
    data
  ) as unknown as UpsertOrganisationDetailsFormData;

  if (transformedData.logo && !Array.isArray(transformedData.logo)) {
    transformedData.logo = [transformedData.logo];
  }
  if (transformedData.icon && !Array.isArray(transformedData.icon)) {
    transformedData.icon = [transformedData.icon];
  }
  if (!transformedData.logo) {
    transformedData.logo = defaultValues.logo;
  }
  if (!transformedData.icon) {
    transformedData.icon = defaultValues.icon;
  }

  return transformedData;
};

function useUpsertOrganisationDetailsForm(
  props: UseUpsertOrganisationDetailsFormProps
) {
  const { onSubmit, initialValues } = props;

  const form = useForm({
    resolver: yupResolver(upsertOrganisationDetailsFormSchema),
    defaultValues: transformApiDataToFormValues(initialValues) || defaultValues,
  });

  const handleSubmit = async (data: UpsertOrganisationDetailsFormData) => {
    try {
      const response = await onSubmit(data);

      return response;
    } catch (error) {
      const httpError = error as HttpError;
      if (isBadRequest(httpError)) {
        const availableFields = getNestedKeys(form.getValues());
        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }
            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }
          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }
      form.setError('root.server', {
        message: Array.isArray(httpError.message)
          ? httpError.message[0]
          : httpError.message,
      });
    }
  };

  return {
    form,
    handleSubmit,
  };
}

export { useUpsertOrganisationDetailsForm };
