import { ProductCapacity, ProductWithCapacity } from '@/types/extra-hours';
import { ExtraHourPublic } from '@admissions-support/types';
import { isSameDay } from 'date-fns';
import { uniqBy } from 'lodash-es';

function getProductsWithCapacity({
  extraHours,
  selectedDay,
}: {
  extraHours?: ExtraHourPublic[];
  selectedDay?: string;
}): ProductWithCapacity[] {
  if (!extraHours) {
    return [];
  }

  const formattedSelectedDay = selectedDay ? new Date(selectedDay) : new Date();

  // its a long list, because we have extra hours for each ratio and product.
  const extraHoursOnSameDay = extraHours.filter(extraHour =>
    isSameDay(new Date(extraHour.date), formattedSelectedDay)
  );

  const availableProducts: { id: string; name: string }[] = uniqBy(
    extraHoursOnSameDay,
    'product.id'
  ).map(extraHour => ({
    id: extraHour.product.id,
    name: extraHour.product.name,
  }));

  const productsWithRatioCapacity: ProductWithCapacity[] =
    availableProducts.map(product => {
      const extraHoursForProduct = extraHoursOnSameDay.filter(
        extraHour => extraHour.product.id === product.id
      );

      if (extraHoursForProduct.length < 1) {
        throw new Error('No extra hours found for this product!');
      }

      const capacitiesForProduct: ProductCapacity[] = extraHoursForProduct.map(
        extraHour => ({
          ratio: {
            id: extraHour.ratio.id.toString(),
            name: extraHour.ratio.name,
          },
          capacity: extraHour.capacity,
          allocatedCapacity: extraHour.allocatedCapacity,
        })
      );

      return {
        ...product,
        price: extraHoursForProduct[0].price,
        capacities: capacitiesForProduct,
      };
    });

  return productsWithRatioCapacity;
}

export { getProductsWithCapacity };
