import { useAuth } from '@/context/auth.context';
import { Navigate } from 'react-router-dom';
import { LoadingScreen } from '../loading-screen';

type NoMfaAuthGuardProps = {
  children?: React.ReactNode;
};

function NoMfaAuthGuard({ children }: NoMfaAuthGuardProps) {
  const { isLoggedIn, isLoading, user } = useAuth();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isLoggedIn) {
    return <Navigate to="/auth/sign-in" />;
  }

  if (user?.preferredMFA || !user?.isMFARequired) {
    return <Navigate to="/" />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export { NoMfaAuthGuard };
