import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

function Page404() {
  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <Helmet>
        <title>Not Found</title>
      </Helmet>

      <div className="text-center">
        <p className="text-indigo-600 text-base font-semibold">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Page not found
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link to="/" className="btn btn-primary">
            Go back home
          </Link>
          <a
            href="mailto:help@admit.app"
            className="text-sm font-semibold text-gray-900"
          >
            Contact support <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  );
}

export { Page404 };
