import { Controller, FormProvider, useForm } from 'react-hook-form';
import { TextInput } from './common/text-input';
import { Textarea } from './common/textarea';
import { Dropzone } from './common/dropzone';
import { Link, useNavigate } from 'react-router-dom';
import { staffRouterPath } from '@/config/route-paths.config';
import { twMerge } from 'tailwind-merge';
import { CustomSelect } from '../custom-select';
import { useMemo } from 'react';
import { messageCategories } from '../messaging/message-categories';
import { yupResolver } from '@hookform/resolvers/yup';
import { parentCreateMessageFormSchema } from '../messaging/create-message.schema';
import { useCreateMessage } from '@/hooks/create-hooks/use-create-message';
import { useAuth } from '@/context/auth.context';
import { useChildren } from '@/hooks/query-hooks/use-children';
import { InferType } from 'yup';
import { CreateTicketRequest } from '@admissions-support/types';

function ParentCreateMessageForm() {
  const form = useForm({
    resolver: yupResolver(parentCreateMessageFormSchema),
    mode: 'onBlur',
    defaultValues: {
      title: '',
      topic: '',
      childId: null,
      message: '',
    },
  });

  const navigate = useNavigate();

  const { user } = useAuth();

  const { data: children, isLoading: isChildrenLoading } = useChildren();

  const { mutateAsync: createMessage, isPending: isCreatingMessage } =
    useCreateMessage({ userId: user?.id || '', isParent: true });

  const childrenOptions = useMemo(
    () =>
      children?.map(child => ({
        value: child.name,
        key: child.id,
      })) || [],
    [children]
  );

  const relatesToOptions = useMemo(
    () =>
      Object.entries(messageCategories).map(([key, value]) => ({
        value: value,
        key: key,
      })),
    []
  );

  const isValid = form.formState.isValid;

  const submitHandler = async (
    data: InferType<typeof parentCreateMessageFormSchema>
  ) => {
    await createMessage({
      childId: data.childId,
      message: data.message,
      title: data.title,
      topic: data.topic as CreateTicketRequest['topic'],
      attachments: data.attachments || [],
    });

    navigate(staffRouterPath.MESSAGING);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)}>
        <div className="flex flex-col gap-8">
          <TextInput
            name="title"
            label="Title*"
            placeholder="Descriptive title"
            helperText="Short descriptive title, max 25 words"
          />
          <div className="flex w-full gap-8">
            <div className="w-full">
              <Controller
                control={form.control}
                name="topic"
                render={({ field }) => (
                  <CustomSelect
                    label="Relates To*"
                    options={relatesToOptions}
                    helperText="Select the category that best represents your query"
                    error={form.formState.errors.topic?.message?.toString()}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="w-full">
              <Controller
                control={form.control}
                name="childId"
                render={({ field }) => (
                  <CustomSelect
                    label="Child"
                    options={childrenOptions}
                    helperText="If your question is specific to an individual child"
                    isDisabled={childrenOptions.length === 0}
                    isClearable
                    error={form.formState.errors.childId?.message?.toString()}
                    {...field}
                    value={field.value || ''}
                    isLoading={isChildrenLoading}
                  />
                )}
              />
            </div>
          </div>
          <Textarea
            name="message"
            label="Description*"
            helperText="Provide as much detail as possible to help us support your quicker"
            rows={4}
            placeholder="Enter a description..."
          />
          <Dropzone
            name="attachments"
            label="Attachments"
            path="attachments"
            helperText="If you would like to attach any documents or images to support your query you can do so"
            options={{
              accept: {
                'image/jpeg': [],
                'image/png': [],
                'application/pdf': [],
              },
            }}
            bucket="messaging-files"
            fileRestrictionLabel="Only jpg, png, pdf and docx files are accepted."
          />
        </div>
        <div className="mt-8 flex justify-end">
          <div className="flex gap-4">
            <Link to={staffRouterPath.MESSAGING} className="btn btn-secondary">
              Cancel
            </Link>
            <button
              className={twMerge(
                'btn btn-primary',
                (form.formState.isSubmitting || isCreatingMessage) && 'loading'
              )}
              type="submit"
              disabled={!isValid}
            >
              Submit Ticket
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export { ParentCreateMessageForm };
