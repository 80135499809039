import { LoadingScreen } from '@/components/loading-screen';
import { useApplicationTemplates } from '@/hooks/query-hooks/use-application-templates';
import { ApplicationTemplateChoice } from './application-template-choice';

type ApplicationTemplateChoicesProps = {
  cohortId: string;
  schoolYearId: string;
  childIdentificationNumber: string;
};

function ApplicationTemplateChoices({
  cohortId,
  schoolYearId,
  childIdentificationNumber,
}: ApplicationTemplateChoicesProps) {
  const {
    data: applicationTemplates,
    isSuccess,
    isLoading,
  } = useApplicationTemplates({
    select: data =>
      data.filter(
        template =>
          template.cohorts.findIndex(
            cohort => cohort.id.toString() === cohortId
          ) >= 0
      ),
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isSuccess) {
    return null;
  }

  return (
    <div className="space-y-4">
      {applicationTemplates.map(template => (
        <ApplicationTemplateChoice
          applicationTemplate={template}
          childIdentificationNumber={childIdentificationNumber}
          schoolYearId={schoolYearId}
          key={template.id}
        />
      ))}
    </div>
  );
}

export { ApplicationTemplateChoices };
