import { ApplicantNameSearchBar } from '@/components/application/applicant-name-searchbar';
import { ApplicationsFilterDrawer } from '@/components/application/applications-filter-drawer';
import { ApplicationsFilterRow } from '@/components/application/applications-filter-row';
import { ApplicationsList } from '@/components/application/applications-list';
import { BulkActionModal } from '@/components/application/bulk-action/bulk-action-modal';
import { MatchingModal } from '@/components/application/matching-modal';
import { PermissionGuard } from '@/components/auth/permission-guard';
import { BulkUploadModal } from '@/components/bulk-upload.modal';
import { NewApplicationModal } from '@/components/new-application.modal';
import { PageTitle } from '@/components/page-title';
import { SchoolYearFilter } from '@/components/school-year-filter';
import { Tag } from '@/components/tag';
import { staffRouterPath } from '@/config/route-paths.config';
import { ApplicationControlProvider } from '@/context/application-control.context';
import { Permission } from '@/types/auth';
import { ApplicationStatus, ChoiceStatus } from '@admissions-support/types';
import { useState } from 'react';
import { matchPath, useNavigate } from 'react-router-dom';

function Applications() {
  const isBulkUploadModal = Boolean(
    matchPath(staffRouterPath.BULK_UPLOAD, location.pathname)
  );

  const [openBulkUpload, setOpenBulkUpload] = useState(isBulkUploadModal);
  const [openNewApplicationModal, setOpenNewApplicationModal] = useState(false);
  const [openBulkActionModal, setOpenBulkActionModal] = useState<
    string[] | null
  >(null);

  const navigate = useNavigate();
  const handleOpenActionModal = (applicationIds: string[]) => {
    setOpenBulkActionModal(applicationIds);
  };

  const handleCloseActionModal = () => {
    setOpenBulkActionModal(null);
  };

  return (
    <>
      <PageTitle title="Applications" variant="gray">
        <PermissionGuard
          requiredPermissions={[Permission['application:create']]}
        >
          <div className="space-x-2 sm:ml-auto">
            <button
              type="button"
              className="btn shadow-none"
              onClick={() => {
                setOpenNewApplicationModal(true);
              }}
            >
              New Application
            </button>

            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setOpenBulkUpload(true);
                navigate(staffRouterPath.BULK_UPLOAD);
              }}
            >
              Bulk upload
            </button>

            <MatchingModal />
          </div>
        </PermissionGuard>
      </PageTitle>
      <ApplicationControlProvider type="main">
        <div className="!mt-12 flex flex-col space-y-2 sm:flex-row sm:items-center sm:space-y-0">
          <div className="flex space-x-2 sm:mr-2">
            <SchoolYearFilter />

            <ApplicationsFilterDrawer />
          </div>
          <div className="sm:ml-auto">
            <ApplicantNameSearchBar />
          </div>
        </div>
        <ApplicationsFilterRow />

        <ApplicationsList onActionApply={handleOpenActionModal} />
      </ApplicationControlProvider>

      <BulkUploadModal
        open={openBulkUpload}
        onClose={() => {
          navigate(staffRouterPath.APPLICATIONS);
          setOpenBulkUpload(false);
        }}
      />

      <BulkActionModal
        open={!!openBulkActionModal}
        onClose={handleCloseActionModal}
        selectedApplicantIds={openBulkActionModal || []}
      />

      <NewApplicationModal
        open={openNewApplicationModal}
        onClose={() => {
          setOpenNewApplicationModal(false);
        }}
      />
    </>
  );
}

function getChoice(nbChoice: number | null, suffix?: string) {
  const formattedSuffix = suffix ? ` ${suffix}` : '';
  if (nbChoice === 1) {
    return <Tag type="success">1st{formattedSuffix}</Tag>;
  }

  if (nbChoice === 2) {
    return <Tag type="warning">2nd{formattedSuffix}</Tag>;
  }

  if (nbChoice === 3) {
    return <Tag type="error">3rd{formattedSuffix}</Tag>;
  }

  return <Tag type="default">None</Tag>;
}

function getTagType(value?: ApplicationStatus | ChoiceStatus) {
  switch (value) {
    case 'PLACED':
    case 'GRANTED':
    case 'RESERVED':
      return 'success';
    case 'DRAFT':
    case 'SCORED':
    case 'NOT_MATCHED':
      return 'default';
    case 'PLACEMENT_REJECTED':
      return 'error';
    case 'WAITING_LIST':
      return 'warning';
    case 'SUBMITTED':
    case 'MATCHED':
      return 'info';
    default:
      return 'default';
  }
}

export { Applications, getChoice, getTagType };
