import { Alert } from '@/components/alert';
import { CheckboxBase } from '@/components/form/common/checkbox';
import { Spinner } from '@/components/spinner';
import { useChoicesContext } from '@/context/choices.context';
import { useSessions } from '@/hooks/query-hooks/use-sessions';
import { ChoiceFormActionTypes } from '@/reducers/choice.reducer';
import {
  selectNbOfAdditionalSelectedSessions,
  selectSessionListWithDayTitle,
} from '@/utils/application-utils';
import { getStartEndForDay } from '@/utils/location-session-utils';
import { SessionType, OperatingDaySessionDto } from '@admissions-support/types';
import { capitalize } from 'lodash-es';
import { useEffect } from 'react';
import { CheckboxItem } from '../../checkbox-item';
import { SessionRadioBoxOption } from '../../session-radio-box-option';
import { CustomSelect } from '@/components/custom-select';

type AdditionalSessionsBoxProps = {
  selectedSessionType: SessionType | undefined;
  locations: { key: string; value: string }[];
  disabled?: boolean;
};

function AdditionalSessionsBox(props: AdditionalSessionsBoxProps) {
  const { selectedSessionType, disabled, locations } = props;
  const { state, dispatch } = useChoicesContext();

  /**
   * This query should only run if user chooses a different additional session type location
   * than the core session location. If user enabled split session, we dont run this query,
   * because we must have the needed sessions. (in the state.sessions or state.sessionsAtSplitPlacement)
   */
  const {
    data: sessionsData,
    isSuccess: sessionQuerySuccess,
    isLoading: isSessionLoading,
  } = useSessions(state.additionalSessionsEstablishmentId, state.schoolYearId, {
    enabled:
      !!state.additionalSessionsEstablishmentId && !state.enableSplitPlacement,
  });

  useEffect(() => {
    /**
     * We need to check for state.enableSplitPlacement because
     * if the query has cached data, then the query will be initialized with isSuccess=true
     */
    if (sessionQuerySuccess && !state.enableSplitPlacement) {
      dispatch({
        type: ChoiceFormActionTypes.SET_SESSIONS_AT_SPLIT_PLACEMENT,
        payload: sessionsData,
      });
    }
  }, [dispatch, sessionsData, sessionQuerySuccess, state.enableSplitPlacement]);

  /**
   * we decided to have only OR relationship between session types,
   * however the underlying OR-AND relationship is still in the DB
   * So lets use only the first array of every array. 2d->1d
   * Currently you cannot add more than 1 array to the AND array on the FE.
   */
  const flatAdditionalSessionTypes =
    selectedSessionType?.additionalSessionTypes.map(arr => arr[0]) || [];
  const additionalSessionTypesRef = flatAdditionalSessionTypes.map(
    sessionType => ({
      key: sessionType.id,
      value: sessionType.name,
    })
  );
  const selectedAdditionalSessionType = flatAdditionalSessionTypes.find(
    sessionType => sessionType.id === state.additionalSessionType
  );

  const nbOfSelectedAdditionalSessions =
    selectNbOfAdditionalSelectedSessions(state);

  const availableAdditionalSessions = selectSessionListWithDayTitle(state);

  const filteredLocations = locations.filter(location => {
    /**
     * if core split placement is not enabled, you can choose any location but the core one.
     */
    if (!state.enableSplitPlacement) {
      return location.key !== state.establishment;
    }

    /**
     * if core split placement enabled, you can choose either the core or split placement
     * locations
     */
    if (
      location.key === state.splitPlacementEstablishmentId ||
      location.key === state.establishment
    ) {
      return true;
    }
  });

  const handleSessionTypeChange = (value: string) => {
    dispatch({
      type: ChoiceFormActionTypes.SET_ADDITIONAL_SESSION_TYPE,
      payload: value,
    });
  };

  const handleSplitPlacementChange = () => {
    dispatch({
      type: ChoiceFormActionTypes.TOGGLE_DIFFERENT_ESTABLISHMENT_FOR_ADDITIONAL_SESSIONS,
    });
  };

  const handleEstablishmentChange = (value: string) => {
    dispatch({
      type: ChoiceFormActionTypes.SET_ADDITIONAL_SESSIONS_ESTABLISHMENT,
      payload: value,
    });
  };

  const handleToggleRadio = (day: string, value: string) => () => {
    const dayValue =
      state.additionalSessionsPattern[day as keyof OperatingDaySessionDto];

    const isButtonDisabled =
      selectedAdditionalSessionType?.allowance ===
        nbOfSelectedAdditionalSessions && dayValue === null;

    // dont let select more session if we reached the limit
    // but let to change the actual row
    if (isButtonDisabled) {
      return;
    }

    dispatch({
      type: ChoiceFormActionTypes.TOGGLE_ADDITIONAL_SESSION_BY_DAY,
      payload: {
        day: day,
        sectionId: value,
      },
    });
  };

  /**
   * If user opted-in for core split placement, they need to choose
   * which location they would like to attend for additional sessions
   */
  const enableDifferentLocation =
    state.enableDifferentEstablishmentForAdditionalSessions ||
    state.enableSplitPlacement;

  const hasSessionsToDisplay = availableAdditionalSessions.length > 0;

  return (
    <div className="dark-gray-container space-y-4">
      <p className="text-md font-medium text-gray-900 sm:flex">
        Additional Sessions
        {state.additionalSessionType && (
          <span className="ml-auto inline-block font-normal text-gray-600">
            {nbOfSelectedAdditionalSessions}/
            {selectedAdditionalSessionType?.allowance} Allowance
          </span>
        )}
      </p>
      <p className="text-md text-gray-600">
        The session type you have selected has an additional allowance, you can
        select additional sessions below.
      </p>

      {/**
       * Display the checkbox only if setting isEnabledForAdditional
       * is enabled on the sessionType
       */}
      {selectedSessionType?.splitPlacement.isEnabledForAdditional &&
        !state.enableSplitPlacement && (
          <CheckboxBase
            name="enableAdditionalSessionsSplitPlacement"
            label="Split Placement"
            helperText="Select a different location for this session."
            onChange={handleSplitPlacementChange}
            /**
             * If user enabled core split placement, they NEED to choose the location for
             * additional sessions. So they cannot uncheck this checkbox
             */
            checked={state.enableDifferentEstablishmentForAdditionalSessions}
            disabled={
              disabled ||
              (state.enableSplitPlacement &&
                selectedSessionType?.splitPlacement.isEnabledForAdditional &&
                state.enableDifferentEstablishmentForAdditionalSessions)
            }
          />
        )}

      {enableDifferentLocation && (
        <CustomSelect
          name="additionalSessionsEstablishmentId"
          label="Establishment"
          value={state.additionalSessionsEstablishmentId}
          onChange={handleEstablishmentChange}
          options={[...filteredLocations]}
          isDisabled={
            disabled ||
            (state.enableSplitPlacement &&
              !selectedSessionType?.splitPlacement.isEnabledForAdditional)
          }
          menuListClasses="!max-h-56"
        />
      )}

      {state.additionalSessionsEstablishmentId && (
        <CustomSelect
          name="additionalSessionType"
          label="Session Type"
          value={state.additionalSessionType}
          onChange={handleSessionTypeChange}
          options={[
            { key: '', value: 'Select..' },
            ...additionalSessionTypesRef,
          ]}
          isDisabled={disabled}
          menuListClasses="!max-h-56"
        />
      )}

      {state.additionalSessionType &&
        hasSessionsToDisplay &&
        !isSessionLoading && (
          <>
            <p className="label mb-1 mt-4">Select Session</p>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 2xl:grid-cols-3">
              {availableAdditionalSessions.map(session => {
                const dayValue =
                  state.additionalSessionsPattern[
                    session.day as keyof OperatingDaySessionDto
                  ];

                const isButtonDisabled =
                  selectedAdditionalSessionType?.allowance ===
                    nbOfSelectedAdditionalSessions && dayValue === null;
                const times = getStartEndForDay(session.details);

                return (
                  <CheckboxItem
                    Content={() => (
                      <SessionRadioBoxOption
                        title={capitalize(session.day)}
                        className={isButtonDisabled ? 'show-error' : undefined}
                        start={times.start}
                        end={times.end}
                      />
                    )}
                    key={session.day}
                    checked={!!dayValue}
                    onChange={handleToggleRadio(session.day, session.sessionId)}
                    disabled={isButtonDisabled}
                  />
                );
              })}
            </div>
          </>
        )}

      {/**
       * Loading while fetching sessions for the split placement location
       */}
      {isSessionLoading && (
        <div className="mt-4 flex justify-center space-y-8">
          <Spinner />
        </div>
      )}

      {state.additionalSessionType &&
        !hasSessionsToDisplay &&
        !isSessionLoading && (
          <Alert
            type="warning"
            text="No sessions of the selected type are available at this location."
            className="mt-4"
          />
        )}
    </div>
  );
}

export { AdditionalSessionsBox };
