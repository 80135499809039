import { LocationResourceForm } from '@/reducers/resources.reducer';
import {
  ResourceDay,
  ResourceGroup,
  StateResource,
} from '@/types/location-resource';
import {
  CreateResourceDto,
  OperatingDaySessionDto,
  RatioCapacity,
  Resource,
  ResourceCapacity,
  ResourceDayCapacity,
  ResourceDayCapacityDto,
} from '@admissions-support/types';
import { format, isAfter, isBefore, isSameDay } from 'date-fns';
import {
  compact,
  find,
  flatMap,
  forEach,
  fromPairs,
  groupBy,
  map,
  mapValues,
  omit,
  sortBy,
  uniqBy,
} from 'lodash-es';
import { toHumanReadableNumber } from './general-utils';

function transformCapacitiesToArray(
  resourceCapacities: ResourceCapacity[],
  ratioIdToFilterBy: string
) {
  // Using flatMap for mapping and flattening in one go
  const capacities = flatMap(resourceCapacities, capacity => {
    // Assuming there's a clear distinction of structure here and `from` is always set
    const from = capacity.from;

    const capacityWithDayArray = compact(
      map(capacity, (value, key) => {
        // Check if the value is an array, proceed if true
        if (Array.isArray(value)) {
          const foundCapacity = find(
            value,
            cap => cap.ratio.id.toString() === ratioIdToFilterBy
          );
          if (!foundCapacity) {
            return;
          }
          return {
            day: key,
            // Find the capacity with the matching ratioId
            capacity: foundCapacity,
          };
        }
        // This ensures that 'from' or other non-array properties are handled correctly
      })
    );

    return {
      capacities: capacityWithDayArray,
      from,
    };
  });

  return capacities;
}

function getIsActive(
  filteredCapacities: ResourceCapacity[],
  index: number,
  currentCapacityGroup: ResourceCapacity
) {
  // If array contains only one element it's always the active
  if (filteredCapacities.length === 1) {
    return true;
  }
  // Checking last element
  if (!filteredCapacities[index + 1]) {
    return isAfter(new Date(), new Date(currentCapacityGroup.from));
  }
  // Checking if current date is before first element's from date
  if (isBefore(new Date(), filteredCapacities[0].from) && index === 0) {
    return true;
  }
  return (
    isAfter(new Date(), new Date(currentCapacityGroup.from)) &&
    isBefore(new Date(), new Date(filteredCapacities[index + 1].from))
  );
}

function getCapacityAndAllocatedRange(
  dayCapacities: ResourceDay[],
  ratioId: string
) {
  const capacitiesAndAllocated = dayCapacities.map(dayCap => {
    const correctCapacityObj = dayCap.capacities.find(
      c => c.ratioId === ratioId
    );
    return {
      capacity: correctCapacityObj?.capacity || 0,
      allocated: correctCapacityObj?.allocated || 0,
    };
  });

  const capacitiesAndAllocatedRange = {
    capacityRange: capacitiesAndAllocated.map(ca => ca.capacity),
    allocatedRange: capacitiesAndAllocated.map(ca => ca.allocated),
  };

  const rangeObj = {
    capacities: {
      min: toHumanReadableNumber(
        Math.min(...capacitiesAndAllocatedRange.capacityRange)
      ),
      max: toHumanReadableNumber(
        Math.max(...capacitiesAndAllocatedRange.capacityRange)
      ),
    },
    allocated: {
      min: toHumanReadableNumber(
        Math.min(...capacitiesAndAllocatedRange.allocatedRange)
      ),
      max: toHumanReadableNumber(
        Math.max(...capacitiesAndAllocatedRange.allocatedRange)
      ),
    },
  };

  return rangeObj;
}

function transformInitialLocationResourceValue(
  ratioCapacities: RatioCapacity[],
  resource?: Resource
) {
  if (!resource) {
    return undefined;
  }

  const selectedSessionTypeIds = resource.sessionTypes.map(sessionType =>
    sessionType.id.toString()
  );

  const newResourceValue: StateResource[] = resource.capacities.map(
    resourceCapacity => {
      const resourceGroups =
        transformResourceDayCapacityToArray(resourceCapacity);
      return {
        from: format(new Date(resourceCapacity.from), 'yyyy-MM-dd'),
        uid: resourceCapacity.id.toString(),
        resourceGroups: ratioCapacities.map(rc => {
          const filteredResourceGroups = resourceGroups.map(resourceDay => {
            return {
              day: resourceDay.day,
              capacity: resourceDay.capacities.find(
                c => c.ratioId === rc.ratio.id.toString()
              )?.capacity,
            };
          });

          const hasSameCapacityForEachDay =
            uniqBy(filteredResourceGroups, 'capacity').length === 1;

          return {
            ratioId: rc.ratio.id.toString(),
            hasSameCapacityForEachDay,
            capacityGroup: filteredResourceGroups.map(rg => {
              return {
                day: rg.day as keyof OperatingDaySessionDto,
                capacity: rg.capacity || 0,
              };
            }),
          };
        }),
      };
    }
  );

  return {
    resources: newResourceValue,
    sessionTypeIds: selectedSessionTypeIds,
  };
}

function createEmptyResourceGroup(
  operatingDays: (keyof OperatingDaySessionDto)[],
  ratioIds: string[]
): ResourceGroup[] {
  const resourceGroup = ratioIds.map(ratioId => ({
    hasSameCapacityForEachDay: true,
    capacityGroup: operatingDays.map(day => {
      return { day, capacity: 0 };
    }),
    ratioId,
  }));
  return resourceGroup;
}

function getAllocatedCapacityRangeForAllDay(
  ratioId: string,
  from: string,
  dbResource?: Resource
) {
  if (!dbResource) {
    return;
  }

  const correctCapacityDaysObj = dbResource.capacities.find(cap =>
    isSameDay(new Date(cap.from), new Date(from))
  );

  const filteredCapacities = transformResourceDayCapacityToArray(
    correctCapacityDaysObj
  ).map(resourceDay => {
    return {
      day: resourceDay.day,
      capacity: resourceDay.capacities.find(c => c.ratioId === ratioId),
    };
  });

  const maxAllocated = toHumanReadableNumber(
    Math.max(...filteredCapacities.map(item => item.capacity?.allocated || 0))
  );

  const minAllocated = toHumanReadableNumber(
    Math.min(...filteredCapacities.map(item => item.capacity?.allocated || 0))
  );

  const maxCapacity = toHumanReadableNumber(
    Math.max(...filteredCapacities.map(item => item.capacity?.capacity || 0))
  );

  const minCapacity = toHumanReadableNumber(
    Math.min(...filteredCapacities.map(item => item.capacity?.capacity || 0))
  );

  return {
    capacity: {
      max: maxCapacity,
      min: minCapacity,
    },
    allocated: {
      max: maxAllocated,
      min: minAllocated,
    },
  };
}

function generateVariantsForEachSessionType(
  capacity: number,
  sessionTypeIds: string[],
  ratioId: string
) {
  return sessionTypeIds.map(sessionTypeId => ({
    capacity,
    sessionTypeId,
    ratioId,
  }));
}

function transformLocationResourceFormDataToDto(
  state: LocationResourceForm,
  isCreateNewResource?: boolean
): CreateResourceDto {
  // Flatten to intermediate structure with days mapped directly to capacities
  const intermediate = state.resources.flatMap(resource =>
    resource.resourceGroups.flatMap(group => {
      const entry = {
        from: resource.from,
        ratioId: group.ratioId,
        id: resource.uid,
        capacities: fromPairs(
          group.capacityGroup.map(item => [
            item.day,
            [
              ...generateVariantsForEachSessionType(
                item.capacity || 0,
                state.sessionTypeIds,
                group.ratioId
              ),
            ],
          ])
        ),
      };
      return entry;
    })
  );

  // Group by 'from' and merge the capacities arrays for the same days
  const grouped = groupBy(intermediate, entry => entry.from);
  const merged = map(grouped, (entries, date) => {
    // Since the entries are grouped here, every 'entries' will contain the same id
    const id = entries[0].id;
    const mergedEntries = entries.reduce(
      (acc, entry) => {
        forEach(entry.capacities, (value, day) => {
          const convertedDay = day as keyof OperatingDaySessionDto;
          if (!acc[convertedDay]) {
            acc[convertedDay] = [];
          }
          acc[convertedDay] = acc[convertedDay].concat(
            value as ResourceDayCapacityDto[]
          );
        });
        return acc;
      },
      {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
      } as { [key in keyof OperatingDaySessionDto]: ResourceDayCapacityDto[] }
    );

    // resource-<id> generated in the resource.reducer.tsx
    // If the id starts with 'resource-', we need to add it to the merged object
    // means that we are updating an existing resource
    // If does not start with 'resource-', we are creating a new resource therefore no id is needed
    // If we passed the generatedId we temporarily use on the frontend, it would not pass validation on the backend
    const isExistingResource = id.includes('resource-');
    return isCreateNewResource
      ? {
          from: new Date(date),
          ...mapValues(mergedEntries, arr => arr || []),
        }
      : {
          from: new Date(date),
          ...(!isExistingResource ? { id } : {}),
          ...mapValues(mergedEntries, arr => arr || []),
        };
  });

  return {
    capacities: sortBy(merged, item => new Date(item.from)),
    sessionTypeIds: state.sessionTypeIds,
  };
}

function transformResourceDayCapacityToArray(
  resourceCapacity?: ResourceCapacity
): ResourceDay[] {
  if (!resourceCapacity) {
    return [];
  }

  const operatingDays = Object.keys(resourceCapacity).filter(day => {
    const dayResourceCapacity = resourceCapacity[day as keyof ResourceCapacity];
    if (!Array.isArray(dayResourceCapacity)) {
      return false;
    }

    return dayResourceCapacity.length > 0;
  });

  const resourceDays = operatingDays
    .map(day => {
      // need unique, because on the backend we store one ratio multiple times (for each ratio-sessiontype combination)

      const filteredCapacities = omit(resourceCapacity, ['from', 'id']);

      const currentResourceCapacity: ResourceDayCapacity[] = uniqBy(
        filteredCapacities[day as keyof Omit<ResourceCapacity, 'from' | 'id'>],
        'ratio.id'
      );

      const newCapacities = currentResourceCapacity.map(item => {
        return {
          capacity: item.capacity,
          ratioId: item.ratio.id.toString(),
          allocated: item.allocated,
          sessionTypeId: item.sessionType.id.toString(),
        };
      });

      return {
        day,
        capacities: newCapacities,
      };
    })
    .flat();

  return resourceDays;
}

export {
  createEmptyResourceGroup,
  getAllocatedCapacityRangeForAllDay,
  getCapacityAndAllocatedRange,
  getIsActive,
  transformCapacitiesToArray,
  transformInitialLocationResourceValue,
  transformLocationResourceFormDataToDto,
  transformResourceDayCapacityToArray,
};
