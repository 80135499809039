import { SchoolYear } from '@admissions-support/types';
import { format, isBefore, isValid, isWithinInterval } from 'date-fns';
import * as yup from 'yup';

const updateProductPriceSchema = yup.object({
  price: yup
    .number()
    .typeError('Product Price must be a number')
    .min(0)
    .required()
    .label('Product Price'),
  availableFrom: yup
    .string()
    .required()
    .test(
      'availableFromDate',
      'Available from date must be before available to date',
      (value, context) => {
        return value
          ? isBefore(new Date(value), new Date(context.parent.availableTo))
          : false;
      }
    )
    .test('invalidDate', 'Must be a valid date', value => {
      return isValid(new Date(value));
    })
    .test(
      'notInSchoolYear',
      'Date must be within the selected school year',
      function test(value) {
        const { schoolYear } = this.options.context as {
          schoolYear?: SchoolYear;
        };
        if (!schoolYear) {
          return true;
        }

        const isSelectedDateIsValid = isWithinInterval(new Date(value), {
          start: new Date(schoolYear.startDate),
          end: new Date(schoolYear.endDate),
        });

        return isSelectedDateIsValid
          ? true
          : this.createError({
              message: `Date must be within the selected school year: ${format(
                new Date(schoolYear.startDate),
                'P'
              )} - ${format(new Date(schoolYear.endDate), 'P')}`,
              path: 'availableFrom',
            });
      }
    )
    .label('Available From'),
  availableTo: yup
    .string()
    .required()
    .test('invalidDate', 'Must be a valid date', value => {
      return isValid(new Date(value));
    })
    .test(
      'notInSchoolYear',
      'Date must be within the selected school year',
      function test(value) {
        const { schoolYear } = this.options.context as {
          schoolYear?: SchoolYear;
        };

        if (!schoolYear) {
          return true;
        }

        const isSelectedDateIsValid = isWithinInterval(new Date(value), {
          start: new Date(schoolYear.startDate),
          end: new Date(schoolYear.endDate),
        });

        return isSelectedDateIsValid
          ? true
          : this.createError({
              message: `Date must be within the selected school year: ${format(
                new Date(schoolYear.startDate),
                'P'
              )} - ${format(new Date(schoolYear.endDate), 'P')}`,
              path: 'availableTo',
            });
      }
    )
    .label('Available To'),
});

export { updateProductPriceSchema };
