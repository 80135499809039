import { useUpdateProductPrice } from '@/hooks/update-hooks/use-update-product-price-mutation';
import { ProductListing } from '@admissions-support/types';
import { CheckCircle } from '@untitled-ui/icons-react';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { ScrollableModal } from '../scroll-modal';
import { UpdateProductPriceForm } from './forms/update-product-price-form';
import { UpdateProductPriceFormData } from './update-product-price.type';
import { useUpdateProductPriceForm } from './use-update-product-price.form';

type ManageProductPriceModalProps = {
  isOpen: boolean;
  onClose: () => void;
  initialProduct?: ProductListing;
  afterLeave?: () => void;
};

function ManageProductPriceModal(props: ManageProductPriceModalProps) {
  const { isOpen, onClose, initialProduct, afterLeave } = props;

  const { form } = useUpdateProductPriceForm();
  const {
    mutateAsync: updateExtraHours,
    data: updateResult,
    isSuccess: isUpdated,
  } = useUpdateProductPrice();

  const handleSubmit = async (data: UpdateProductPriceFormData) => {
    await updateExtraHours({
      data: {
        productId: initialProduct?.id || '',
        from: new Date(data.availableFrom),
        to: new Date(data.availableTo),
        price: data.price,
      },
    });
  };

  useEffect(() => {
    form.reset();
  }, [form, isOpen]);

  if (isUpdated) {
    return (
      <ScrollableModal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        afterLeave={afterLeave}
      >
        <ScrollableModal.Header onClose={onClose}>
          <ScrollableModal.Title title="Price Updated" />
        </ScrollableModal.Header>
        <ScrollableModal.Content>
          <div className="flex flex-col gap-4 rounded-lg bg-green-50 p-4 text-green-600">
            <div className="flex gap-4">
              <CheckCircle /> You updated {updateResult.updated} extra hours in
              the given range.
            </div>
          </div>
        </ScrollableModal.Content>
        <ScrollableModal.Footer>
          <div className="flex w-full gap-3">
            <button
              className="btn btn-primary w-full"
              type="button"
              onClick={onClose}
            >
              Done
            </button>
          </div>
        </ScrollableModal.Footer>
      </ScrollableModal>
    );
  }

  return (
    <ScrollableModal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      afterLeave={afterLeave}
    >
      <ScrollableModal.Header onClose={onClose}>
        <ScrollableModal.Title title="Update Price" />
      </ScrollableModal.Header>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <ScrollableModal.Content>
            <UpdateProductPriceForm initialProduct={initialProduct} />
          </ScrollableModal.Content>
          <ScrollableModal.Footer>
            <div className="flex w-full gap-3">
              <button
                className="btn btn-secondary w-full"
                type="button"
                onClick={onClose}
              >
                Cancel
              </button>
              <button className="btn btn-primary w-full">Submit</button>
            </div>
          </ScrollableModal.Footer>
        </form>
      </FormProvider>
    </ScrollableModal>
  );
}

export { ManageProductPriceModal };
