import { parentRouterPath, staffRouterPath } from '@/config/route-paths.config';
import {
  LoaderFunctionArgs,
  createBrowserRouter,
  matchPath,
  redirect,
} from 'react-router-dom';

import { AuthGuard } from '@/components/auth/auth-guard';
import { GuestGuard } from '@/components/auth/guest-guard';
import { PermissionGuard } from '@/components/auth/permission-guard';
import { Layout } from '@/components/layouts/layout';
import { RouteWrapper } from '@/components/utils/route-wrapper';

import { SignIn } from '@/pages/auth/sign-in';
import { Home } from '@/pages/index';
import { AllocationReport } from '@/pages/reporting/allocation/allocation-report';
import * as Sentry from '@sentry/react';
import { NoMfaAuthGuard } from './components/auth/nomfa-auth-guard';
import { ApplicationLayout } from './components/layouts/application.layout';
import { LoginLayout } from './components/layouts/login.layout';
import { BoxedLayout } from './components/layouts/parents/boxed.layout';
import { ParentsApplicationLayout } from './components/layouts/parents/parents-application.layout';
import { ParentsChildrenListLayout } from './components/layouts/parents/parents-children-list.layout';
import { ParentsViewChildNavigation } from './components/layouts/parents/parents-view-child-navigation.layout';
import { PlatformUsersLayout } from './components/layouts/platform-users.layout';
import { SettingsLayout } from './components/layouts/settings.layout';
import { CapacitySimulationProvider } from './context/capacity-simulation.context';
import { ReportingOutputControlProvider } from './context/reporting-output-control.context';
import { Page404 } from './pages/404';
import { AdditionalDetails } from './pages/application/additional-details';
import { ApplicationEligibility } from './pages/application/application-eligibility';
import { Applications } from './pages/application/applications';
import { Carer } from './pages/application/carer';
import { ChildDetails } from './pages/application/child-details';
import { EditApplication } from './pages/application/edit-application';
import { Household } from './pages/application/household';
import { PlacementPreferences } from './pages/application/placement-preferences';
import { CreateAccount } from './pages/auth/create-account';
import { ForgottenPassword } from './pages/auth/forgotten-password';
import { MFASetup } from './pages/auth/mfa-setup';
import { ResetPassword } from './pages/auth/reset-password';
import { SmsVerificationMfaSetup } from './pages/auth/sms-verification-mfa-setup';
import { Verify } from './pages/auth/verify';
import { WelcomeAuth } from './pages/auth/welcome';
import { ExtraHoursBookings } from './pages/extra-hours-bookings/extra-hours-bookings';
import { ExtraHoursBookingsChildDetails } from './pages/extra-hours-bookings/extra-hours-bookings-child-details';
import { LocationApplications } from './pages/location/location-applications';
import { LocationCapacity } from './pages/location/location-capacity';
import { LocationOverview } from './pages/location/location-overview';
import { LocationResources } from './pages/location/location-resources';
import { LocationSessions } from './pages/location/location-sessions';
import { Locations } from './pages/location/locations';
import { AvailabilityPage } from './pages/location/purchase-extra-hours/availability-page';
import { CalendarPage } from './pages/location/purchase-extra-hours/calendar-page';
import { ProductsPage } from './pages/location/purchase-extra-hours/products-page';
import { UpsertLocation } from './pages/location/upsert-location';
import { ApplicationSuccess } from './pages/parent/application-success';
import { AdditionalChildDetails } from './pages/parent/application/additional-child-details';
import { ParentAdditionalInfo } from './pages/parent/application/additional-info';
import { ParentCarer } from './pages/parent/application/carer';
import { CreateChild } from './pages/parent/application/create-child';
import { ParentHousehold } from './pages/parent/application/household';
import { ParentPlacementPreferences } from './pages/parent/application/placement-preferences';
import { ConfirmParentAccount } from './pages/parent/auth/confirm-account';
import { ParentCreateAccount } from './pages/parent/auth/create-account';
import { ParentMain } from './pages/parent/auth/parent-main';
import { VerifyParentEmail } from './pages/parent/auth/verify-email';
import { VerifyInvitation } from './pages/parent/auth/verify-invitation';
import { AvailableExtraHours } from './pages/parent/children/available-extra-hours';
import { ChildUpdate } from './pages/parent/children/child-update';
import { NewBooking } from './pages/parent/children/new-booking';
import { ChildOverview } from './pages/parent/children/view/child-overview';
import { ChildPurchaseExtraHours } from './pages/parent/children/view/child-purchase-extra-hours';
import { TrusteeDashboard } from './pages/parent/children/view/trustee-dashboard';
import { ParentDashboard } from './pages/parent/dashboard';
import { Eligibility } from './pages/parent/eligibility';
import { EligibilityConfirm } from './pages/parent/eligibility-confirm';
import { StartApplication } from './pages/parent/start-application';
import { Welcome } from './pages/parent/welcome';
import { PlatformUsers } from './pages/platform-users/platform-users';
import { PlatformUsersMain } from './pages/platform-users/platform-users-main';
import { UpsertUserGroup } from './pages/platform-users/upsert-user-group';
import { UserGroups } from './pages/platform-users/user-groups';
import { CapacityReport } from './pages/reporting/capacity-planning-demand/capacity-report';
import { DemandSummaryReport } from './pages/reporting/capacity-planning-demand/demand-summary';
import { Reporting } from './pages/reporting/reporting';
import { RunQueryBuilder } from './pages/reporting/run-query-builder';
import { UpsertQueryBuilder } from './pages/reporting/upsert-query-builder';
import { AdditionalQuestionsApplicationTemplate } from './pages/settings/application-templates/additional-questions';
import { ApplicationTemplates } from './pages/settings/application-templates/application-templates';
import { CreateApplicationTemplate } from './pages/settings/application-templates/create-application-template';
import { ApplicationTemplateQuestions } from './pages/settings/application-templates/questions';
import { UpdateApplicationTemplate } from './pages/settings/application-templates/update-application-template';
import { Cohorts } from './pages/settings/cohorts/cohorts';
import { UpsertCohort } from './pages/settings/cohorts/upsert-cohort';
import { LocationGrouping } from './pages/settings/location-grouping/location-grouping';
import { UpsertLocationGrouping } from './pages/settings/location-grouping/upsert-location-grouping';
import { OrganisationDetails } from './pages/settings/organisation-details';
import { Ratios } from './pages/settings/ratios/ratios';
import { UpsertRatio } from './pages/settings/ratios/upsert-ratio';
import { SchoolYears } from './pages/settings/school-years/school-years';
import { UpsertTerm } from './pages/settings/school-years/upsert-term';
import { SessionTypes } from './pages/settings/session-types/session-types';
import { UpsertSessionType } from './pages/settings/session-types/upsert-session-type';
import { SettingsMain } from './pages/settings/settings-main';
import { UpsertWeekModel } from './pages/settings/week-models/upsert-week-model';
import { WeekModels } from './pages/settings/week-models/week-models';
import { Trustees } from './pages/trustees';
import { UserProfile } from './pages/user-profile';
import { Permission } from './types/auth';
import { isProd } from './utils/env-utils';
import { isAdminDashboard } from './utils/is-dashboard';
import { InvitationSignIn } from './pages/auth/invitation-sign-in';
import { Messaging } from './pages/messaging/messaging';
import { ParentMessaging } from './pages/messaging/parent-messaging';
import CreateMessage from './pages/messaging/create-message';
import { ParentCreateMessage } from './pages/messaging/parent-create-message';
import ViewConversation from './pages/messaging/view-conversation';
import ParentViewConversation from './pages/messaging/parent-view-conversation';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const adminRouter = sentryCreateBrowserRouter([
  {
    element: <RouteWrapper guard={AuthGuard} layout={Layout} />,
    children: [
      {
        path: staffRouterPath.ROOT,
        element: <Home />,
      },
      {
        path: staffRouterPath.USER_PROFILE,
        element: <UserProfile />,
      },
      {
        element: <PlatformUsersLayout />,
        children: [
          {
            path: staffRouterPath.PLATFORM_USERS_MAIN,
            element: <PlatformUsersMain />,
          },
          {
            path: staffRouterPath.PLATFORM_USERS,
            element: (
              <PermissionGuard
                requiredPermissions={[Permission['organisation:users:list']]}
                redirectNotAllowed
              >
                <PlatformUsers />
              </PermissionGuard>
            ),
          },
          {
            path: staffRouterPath.NEW_PLATFORM_USER,
            element: (
              <PermissionGuard
                requiredPermissions={[Permission['invitation:invite']]}
                redirectNotAllowed
              >
                <PlatformUsers />
              </PermissionGuard>
            ),
          },
          {
            path: staffRouterPath.PLATFORM_USER,
            element: (
              <PermissionGuard
                requiredPermissions={[Permission['users:fetch']]}
                redirectNotAllowed
              >
                <PlatformUsers />
              </PermissionGuard>
            ),
          },
          {
            path: staffRouterPath.USER_GROUPS,
            element: (
              <PermissionGuard
                requiredPermissions={[
                  Permission['organisation:user-groups:retrieve'],
                ]}
                redirectNotAllowed
              >
                <UserGroups />
              </PermissionGuard>
            ),
          },
          {
            path: staffRouterPath.NEW_USER_GROUP,
            element: (
              <PermissionGuard
                requiredPermissions={[
                  Permission['organisation:user-groups:create'],
                ]}
                redirectNotAllowed
              >
                <UpsertUserGroup />
              </PermissionGuard>
            ),
          },
          {
            path: staffRouterPath.EDIT_USER_GROUP,
            element: (
              <PermissionGuard
                requiredPermissions={[
                  Permission['organisation:user-groups:fetch'],
                ]}
                redirectNotAllowed
              >
                <UpsertUserGroup />
              </PermissionGuard>
            ),
          },
        ],
      },
      {
        element: <SettingsLayout />,
        children: [
          {
            path: staffRouterPath.SETTINGS,
            element: <SettingsMain />,
          },
          {
            path: staffRouterPath.ORGANISATION_DETAILS,
            element: <OrganisationDetails />,
          },
          {
            path: staffRouterPath.WEEK_MODELS,
            element: <WeekModels />,
          },
          {
            path: staffRouterPath.NEW_WEEK_MODEL,
            element: <UpsertWeekModel />,
          },
          {
            path: staffRouterPath.EDIT_WEEK_MODEL,
            element: <UpsertWeekModel />,
          },
          {
            path: staffRouterPath.SESSION_TYPES,
            element: <SessionTypes />,
          },
          {
            path: staffRouterPath.NEW_SESSION_TYPE,
            element: <UpsertSessionType />,
          },
          {
            path: staffRouterPath.EDIT_SESSION_TYPE,
            element: <UpsertSessionType />,
          },
          {
            path: staffRouterPath.RATIOS,
            element: <Ratios />,
          },
          {
            path: staffRouterPath.NEW_RATIO,
            element: <UpsertRatio />,
          },
          {
            path: staffRouterPath.EDIT_RATIO,
            element: <UpsertRatio />,
          },
          {
            path: staffRouterPath.LOCATION_GROUPING,
            element: <LocationGrouping />,
          },
          {
            path: staffRouterPath.NEW_LOCATION_GROUPING,
            element: <UpsertLocationGrouping />,
          },
          {
            path: staffRouterPath.EDIT_LOCATION_GROUPING,
            element: <UpsertLocationGrouping />,
          },
          {
            path: staffRouterPath.COHORTS,
            element: <Cohorts />,
          },
          {
            path: staffRouterPath.NEW_COHORT,
            element: <UpsertCohort />,
          },
          {
            path: staffRouterPath.EDIT_COHORT,
            element: <UpsertCohort />,
          },
          {
            path: staffRouterPath.TERMS,
            element: <SchoolYears />,
          },
          {
            path: staffRouterPath.NEW_TERM,
            element: <UpsertTerm />,
          },
          {
            path: staffRouterPath.EDIT_TERM,
            element: <UpsertTerm />,
          },
          {
            path: staffRouterPath.NEW_APPLICATION_TEMPLATE,
            element: <CreateApplicationTemplate />,
          },
          {
            path: staffRouterPath.APPLICATION_TEMPLATES,
            element: <ApplicationTemplates />,
          },
          {
            path: staffRouterPath.EDIT_APPLICATION_TEMPLATE,
            element: <UpdateApplicationTemplate />,
          },
          {
            path: staffRouterPath.APPLICATION_TEMPLATE_QUESTIONS,
            element: <ApplicationTemplateQuestions />,
          },
          {
            path: staffRouterPath.APPLICATION_TEMPLATE_ADDITIONAL_QUESTIONS,
            element: <AdditionalQuestionsApplicationTemplate />,
          },
        ],
      },
      {
        path: staffRouterPath.LOCATIONS,
        element: (
          <PermissionGuard
            requiredPermissions={[Permission['location:fetch']]}
            redirectNotAllowed
          >
            <Locations />
          </PermissionGuard>
        ),
      },
      {
        path: staffRouterPath.NEW_LOCATION,
        element: (
          <PermissionGuard
            requiredPermissions={[Permission['location:create']]}
            redirectNotAllowed
          >
            <UpsertLocation />
          </PermissionGuard>
        ),
      },
      {
        path: staffRouterPath.EDIT_LOCATION,
        element: (
          <PermissionGuard
            requiredPermissions={[
              Permission['location:update'],
              Permission['location:fetch'],
            ]}
            redirectNotAllowed
          >
            <UpsertLocation />
          </PermissionGuard>
        ),
      },
      {
        path: staffRouterPath.OVERVIEW_LOCATION,
        element: (
          <PermissionGuard
            requiredPermissions={[Permission['location:fetch']]}
            redirectNotAllowed
          >
            <LocationOverview />
          </PermissionGuard>
        ),
      },
      {
        path: staffRouterPath.LOCATION_PURCHASE_EXTRA_HOURS_BASE,
        index: true,
        loader: ({ request }: LoaderFunctionArgs) => {
          const pathname = new URL(request.url).pathname;

          if (
            matchPath(
              staffRouterPath.LOCATION_PURCHASE_EXTRA_HOURS_BASE,
              pathname
            )
          ) {
            return redirect(request.url + '/calendar');
          }

          return null;
        },
      },
      {
        path: staffRouterPath.LOCATION_PURCHASE_EXTRA_HOURS_CALENDAR,
        element: (
          <PermissionGuard
            requiredPermissions={[
              Permission['location:update'],
              Permission['location:fetch'],
            ]}
            redirectNotAllowed
          >
            <CalendarPage />
          </PermissionGuard>
        ),
      },
      {
        path: staffRouterPath.LOCATION_PURCHASE_EXTRA_HOURS_PRODUCTS,
        element: (
          <PermissionGuard
            requiredPermissions={[
              Permission['location:update'],
              Permission['location:fetch'],
            ]}
            redirectNotAllowed
          >
            <ProductsPage />
          </PermissionGuard>
        ),
      },
      {
        path: staffRouterPath.LOCATION_PURCHASE_EXTRA_HOURS_AVAILABILITY,
        element: (
          <PermissionGuard
            requiredPermissions={[
              Permission['location:update'],
              Permission['location:fetch'],
            ]}
            redirectNotAllowed
          >
            <AvailabilityPage />
          </PermissionGuard>
        ),
      },
      {
        path: staffRouterPath.LOCATION_APPLICATIONS,
        element: (
          <PermissionGuard
            requiredPermissions={[
              Permission['location:fetch'],
              Permission['application:fetch'],
            ]}
            redirectNotAllowed
          >
            <LocationApplications />
          </PermissionGuard>
        ),
      },
      {
        path: staffRouterPath.LOCATION_APPLICATION_OVERVIEW,
        element: (
          <PermissionGuard
            requiredPermissions={[
              Permission['location:fetch'],
              Permission['application:fetch'],
            ]}
            redirectNotAllowed
          >
            <LocationApplications />
          </PermissionGuard>
        ),
      },
      {
        path: staffRouterPath.EXTRA_HOURS_BOOKINGS,
        element: (
          <PermissionGuard
            requiredPermissions={[
              Permission['location:update'],
              Permission['location:fetch'],
            ]}
          >
            <ExtraHoursBookings />
          </PermissionGuard>
        ),
      },
      {
        path: staffRouterPath.EXTRA_HOURS_BOOKINGS_CHILD_DETAILS,
        element: (
          <PermissionGuard
            requiredPermissions={[
              Permission['location:update'],
              Permission['location:fetch'],
            ]}
          >
            <ExtraHoursBookingsChildDetails />
          </PermissionGuard>
        ),
      },
      {
        path: staffRouterPath.SESSIONS,
        element: (
          <PermissionGuard
            requiredPermissions={[Permission['location:fetch']]}
            redirectNotAllowed
          >
            <LocationSessions />
          </PermissionGuard>
        ),
      },
      {
        path: staffRouterPath.CAPACITY,
        element: (
          <PermissionGuard
            requiredPermissions={[Permission['location:fetch']]}
            redirectNotAllowed
          >
            <LocationCapacity />
          </PermissionGuard>
        ),
      },
      {
        path: staffRouterPath.RESOURCES,
        element: (
          <PermissionGuard
            requiredPermissions={[Permission['location:fetch']]}
            redirectNotAllowed
          >
            <LocationResources />
          </PermissionGuard>
        ),
      },
      {
        path: staffRouterPath.APPLICATIONS,
        element: <Applications />,
        children: [],
      },
      {
        path: staffRouterPath.BULK_UPLOAD,
        element: <Applications />,
      },
      {
        path: staffRouterPath.OVERVIEW_APPLICATION,
        element: <Applications />,
      },
      {
        path: staffRouterPath.NEW_QUERY_BUILDER,
        element: (
          <ReportingOutputControlProvider>
            <UpsertQueryBuilder />
          </ReportingOutputControlProvider>
        ),
      },
      {
        path: staffRouterPath.EDIT_QUERY_BUILDER,
        element: (
          <ReportingOutputControlProvider>
            <UpsertQueryBuilder />
          </ReportingOutputControlProvider>
        ),
      },
      {
        path: staffRouterPath.RUN_QUERY_BUILDER,
        element: (
          <ReportingOutputControlProvider>
            <RunQueryBuilder />
          </ReportingOutputControlProvider>
        ),
      },
      {
        path: staffRouterPath.REPORTING,
        element: <Reporting />,
      },
      {
        path: staffRouterPath.REPORTING_ALLOCATION,
        element: <AllocationReport />,
      },
      {
        path: staffRouterPath.REPORTING_CAPACITY,
        element: (
          <CapacitySimulationProvider>
            <CapacityReport />
          </CapacitySimulationProvider>
        ),
      },
      {
        path: staffRouterPath.REPORTING_DEMAND,
        element: (
          <CapacitySimulationProvider>
            <DemandSummaryReport />
          </CapacitySimulationProvider>
        ),
      },
      {
        path: staffRouterPath.TRUSTEES,
        element: <Trustees />,
        children: [],
      },
    ],
  },
  {
    element: <RouteWrapper guard={AuthGuard} layout={ApplicationLayout} />,
    children: [
      {
        path: staffRouterPath.NEW_APPLICATION,
        element: <ChildDetails />,
      },
      {
        path: staffRouterPath.NEW_APPLICATION_ELIGIBILITY,
        element: <ApplicationEligibility />,
      },
      {
        path: staffRouterPath.EDIT_APPLICATION,
        element: <EditApplication />,
      },
      {
        path: staffRouterPath.APPLICATION_CHILD_DETAILS,
        element: <ChildDetails />,
      },
      {
        path: staffRouterPath.APPLICATION_CARER,
        element: <Carer />,
      },
      {
        path: staffRouterPath.APPLICATION_HOUSEHOLD_DETAILS,
        element: <Household />,
      },
      {
        path: staffRouterPath.APPLICATION_ADDITIONAL_DETAILS,
        element: <AdditionalDetails />,
      },
      {
        path: staffRouterPath.APPLICATION_PLACEMENT_PREFERENCES,
        element: <PlacementPreferences />,
      },
    ],
  },
  {
    path: staffRouterPath.AUTH,
    element: <RouteWrapper guard={GuestGuard} layout={LoginLayout} />,
    children: [
      {
        path: staffRouterPath.SIGN_IN,
        element: <SignIn />,
      },
      {
        path: staffRouterPath.FORGOTTEN_PASSWORD,
        element: <ForgottenPassword />,
      },
      {
        path: staffRouterPath.RESET_PASSWORD,
        element: <ResetPassword />,
      },
      {
        path: staffRouterPath.VERIFY,
        element: <Verify />,
      },
      {
        path: staffRouterPath.CREATE_ACCOUNT,
        element: <CreateAccount />,
      },
    ],
  },
  {
    path: staffRouterPath.AUTH,
    element: <RouteWrapper guard={NoMfaAuthGuard} layout={LoginLayout} />,
    children: [
      {
        path: staffRouterPath.MFA_SETUP,
        element: <MFASetup />,
      },
      {
        path: staffRouterPath.SMS_VERIFICATION_MFA_SETUP,
        element: <SmsVerificationMfaSetup />,
      },
    ],
  },
  {
    element: <RouteWrapper guard={AuthGuard} layout={Layout} />,
    children: [
      {
        path: staffRouterPath.MESSAGING,
        element: (
          <PermissionGuard
            requiredPermissions={[Permission['messaging:fetch']]}
          >
            <Messaging />
          </PermissionGuard>
        ),
      },
      {
        path: staffRouterPath.MESSAGING_CONVERSATION,
        element: (
          <PermissionGuard
            requiredPermissions={[Permission['messaging:fetch']]}
          >
            <ViewConversation />
          </PermissionGuard>
        ),
      },
      {
        path: staffRouterPath.MESSAGING_NEW_CONVERSATION,
        element: (
          <PermissionGuard
            requiredPermissions={[Permission['messaging:create']]}
          >
            <CreateMessage />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <Page404 />,
  },
]);

const parentRouter = sentryCreateBrowserRouter([
  {
    element: <RouteWrapper guard={GuestGuard} layout={LoginLayout} />,
    children: [
      { path: parentRouterPath.WELCOME_AUTH, element: <WelcomeAuth /> },
      {
        path: parentRouterPath.SIGN_IN,
        element: <SignIn />,
      },
      {
        path: parentRouterPath.INVITATION_SIGN_IN,
        element: <InvitationSignIn />,
      },
      {
        path: parentRouterPath.CREATE_ACCOUNT,
        element: <ParentCreateAccount />,
      },
      {
        path: parentRouterPath.VERIFY,
        element: <Verify />,
      },
    ],
  },
  {
    element: <RouteWrapper layout={LoginLayout} />,
    children: [
      {
        path: parentRouterPath.VERIFY_INVITATION,
        element: <VerifyInvitation />,
      },
      {
        path: parentRouterPath.START_AUTH,
        element: <ParentMain />,
      },
      {
        path: parentRouterPath.CONFIRM_ACCOUNT,
        element: <ConfirmParentAccount />,
      },
      {
        path: parentRouterPath.VERIFY_EMAIL,
        element: <VerifyParentEmail />,
      },
      {
        path: parentRouterPath.FORGOTTEN_PASSWORD,
        element: <ForgottenPassword />,
      },
      {
        path: parentRouterPath.RESET_PASSWORD,
        element: <ResetPassword />,
      },
    ],
  },
  {
    element: <RouteWrapper guard={NoMfaAuthGuard} layout={LoginLayout} />,
    children: [
      {
        path: parentRouterPath.MFA_SETUP,
        element: <MFASetup />,
      },
      {
        path: parentRouterPath.SMS_VERIFICATION_MFA_SETUP,
        element: <SmsVerificationMfaSetup />,
      },
    ],
  },
  !isProd()
    ? {
        element: (
          <RouteWrapper guard={AuthGuard} layout={ParentsApplicationLayout} />
        ),
        children: [
          {
            path: parentRouterPath.APPLICATION_CREATE_CHILD,
            element: <CreateChild />,
          },
          {
            path: parentRouterPath.APPLICATION_CHILD_DETAILS,
            element: <AdditionalChildDetails />,
          },
          {
            path: parentRouterPath.APPLICATION_CARER,
            element: <ParentCarer />,
          },
          {
            path: parentRouterPath.APPLICATION_HOUSEHOLD_DETAILS,
            element: <ParentHousehold />,
          },
          {
            path: parentRouterPath.APPLICATION_PLACEMENT_PREFERENCES,
            element: <ParentPlacementPreferences />,
          },
          {
            path: parentRouterPath.APPLICATION_ADDITIONAL_DETAILS,
            element: <ParentAdditionalInfo />,
          },
        ],
      }
    : {},
  {
    element: (
      <RouteWrapper guard={AuthGuard} layout={ParentsChildrenListLayout} />
    ),
    children: [
      {
        path: parentRouterPath.ROOT,
        element: <ParentDashboard />,
      },
      !isProd()
        ? {
            path: parentRouterPath.CHILD_UPDATE,
            element: <ChildUpdate />,
          }
        : {},
      {
        element: <ParentsViewChildNavigation />,
        children: [
          {
            path: parentRouterPath.CHILD_OVERVIEW,
            element: <ChildOverview />,
          },
          {
            path: parentRouterPath.CHILD_PURCHASE_EXTRA_HOURS,
            element: <ChildPurchaseExtraHours />,
          },
          {
            path: parentRouterPath.TRUSTEE_DASHBOARD,
            element: <TrusteeDashboard />,
          },
        ],
      },
    ],
  },
  {
    element: (
      <RouteWrapper guard={AuthGuard} layout={ParentsChildrenListLayout} />
    ),
    children: [
      {
        path: parentRouterPath.CHILD_NEW_BOOKING,
        element: <NewBooking />,
      },
      {
        path: parentRouterPath.CHILD_AVAILABLE_EXTRA_HOURS,
        element: <AvailableExtraHours />,
      },
    ],
  },
  !isProd()
    ? {
        element: <RouteWrapper guard={AuthGuard} layout={BoxedLayout} />,
        children: [
          {
            path: parentRouterPath.WELCOME,
            element: <Welcome />,
          },
          {
            path: parentRouterPath.APPLICATION_CONFIRM_ELIGIBILITY,
            element: <EligibilityConfirm />,
          },
          {
            path: parentRouterPath.APPLICATION_ELIGIBILITY,
            element: <Eligibility />,
          },
          {
            path: parentRouterPath.APPLICATION_START,
            element: <StartApplication />,
          },
          {
            path: parentRouterPath.APPLICATION_SUCCESS,
            element: <ApplicationSuccess />,
          },
        ],
      }
    : {},
  {
    element: (
      <RouteWrapper guard={AuthGuard} layout={ParentsChildrenListLayout} />
    ),
    children: [
      {
        path: parentRouterPath.MESSAGING,
        element: <ParentMessaging />,
      },
      {
        path: parentRouterPath.MESSAGING_CONVERSATION,
        element: <ParentViewConversation />,
      },
      {
        path: parentRouterPath.MESSAGING_NEW_CONVERSATION,
        element: <ParentCreateMessage />,
      },
    ],
  },
  {
    path: '*',
    element: <Page404 />,
  },
]);

const router = isAdminDashboard() ? adminRouter : parentRouter;
export { router };
