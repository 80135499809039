export enum QuestionCategory {
  ChildDetails = 'child-details',
  ParentCarerDetails = 'parent-carer',
  HouseholdDetails = 'household-details',
  PlacementPreferences = 'placement-preferences',
  AdditionalDetails = 'additional-details',
}

export type QuestionTab = {
  title: string;
  category: QuestionCategory;
};

export enum QuestionType {
  System = 'system',
  Optional = 'optional',
}

export type Question = {
  id: string;
  type: QuestionType;
  category: QuestionCategory;
  title: string;
  description?: string;
};
