import { sessionTypeKey } from '@/config/query-keys';
import { sessionTypeService } from '@/services/session-type.service';
import { HttpError } from '@/types/error';
import { CreateSessionTypeDto, SessionType } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCreateSessionTypeMutation(
  mutationOptions?: UseMutationOptions<
    SessionType,
    HttpError,
    CreateSessionTypeDto
  >
) {
  return useMutation({
    mutationKey: sessionTypeKey.create,
    mutationFn: sessionTypeService.createSessionType,
    ...mutationOptions,
  });
}

export { useCreateSessionTypeMutation };
