import { axiosClient } from '@/libs/axios';
import { ApplicationFilter } from '@/types/application-filter';
import { HttpError } from '@/types/error';
import { PaginateResult } from '@/types/pagination';
import { getApplicationsQueryParams } from '@/utils/application-utils';
import {
  Application,
  ApplicationListing,
  BulkEmailApplicantsDto,
  BulkUpdateApplicationsDto,
  BulkUpdateApplicationsResponse,
  Choice,
  CreateApplicationDto,
  CreateChoiceDto,
  CreateEmptyApplicationDto,
  MatchingSummary,
  ResetMatchingDto,
  RunMatchingDto,
  UpdateApplicationDto,
  UpdateChoiceDto,
  ValidateUploadApplication,
  ValidateUploadApplicationDto,
} from '@admissions-support/types';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { AxiosResponse } from 'axios';

const listApplications = async (
  schoolYear: string,
  {
    pagination,
    sorting,
    filter,
  }: {
    pagination: PaginationState;
    sorting: SortingState;
    filter: ApplicationFilter;
  }
): Promise<PaginateResult<ApplicationListing>> => {
  const queryParams = getApplicationsQueryParams({
    schoolYear,
    pagination,
    sorting,
    filter,
  });

  const response = await axiosClient.get<PaginateResult<ApplicationListing>>(
    `/applications?${queryParams.join('&')}`
  );

  return response.data;
};

const countApplications = async ({
  filter,
  schoolYear,
}: {
  schoolYear: string;
  filter: ApplicationFilter;
}): Promise<string[]> => {
  const queryParams = getApplicationsQueryParams({
    schoolYear,
    filter,
  });

  const response = await axiosClient.get<string[]>(
    `/applications/count?${queryParams.join(
      '&'
    )}&page=1&limit=1&sortBy=name&orderBy=asc`
  );

  return response.data;
};

const getApplication = async (id: string): Promise<Application> => {
  const response = await axiosClient.get<Application>(`/applications/${id}`);

  return response.data;
};

const validateApplicationFile = async (data: ValidateUploadApplicationDto) => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<ValidateUploadApplication>,
    ValidateUploadApplicationDto
  >(`/applications/uploads/validate`, data, {
    disableToast: true,
  });

  return response.data;
};

const processApplicationFile = async (data: ValidateUploadApplicationDto) => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<ValidateUploadApplication>,
    ValidateUploadApplicationDto
  >(`/applications/uploads`, data);

  return response.data;
};

const createApplication = async (
  data: CreateApplicationDto
): Promise<Application> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<Application>,
    CreateApplicationDto
  >(`/applications`, data);

  return response.data;
};

const updateChoice = async ({
  applicationId,
  choiceId,
  data,
}: {
  applicationId: string;
  choiceId: string;
  data: UpdateChoiceDto;
}) => {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<Choice>,
    UpdateChoiceDto
  >(`/applications/${applicationId}/choices/${choiceId}`, data);

  return response.data;
};

const updateApplication = async ({
  applicationId,
  data,
}: {
  applicationId: string;
  data: UpdateApplicationDto;
}) => {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<Application>,
    UpdateApplicationDto
  >(`/applications/${applicationId}`, data);

  return response.data;
};

/**
 * Bulk update applications
 * @param data
 */
const bulkUpdateApplications = async (data: BulkUpdateApplicationsDto) => {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<BulkUpdateApplicationsResponse>,
    BulkUpdateApplicationsDto
  >(`/applications/bulk-update`, data, {
    disableToast: true,
  });

  return response.data;
};

/**
 * Bulk message applicants
 * @param data
 */
const bulkEmailApplicants = async (data: BulkEmailApplicantsDto) => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<void>,
    BulkEmailApplicantsDto
  >(`/applications/bulk-email`, data, {
    disableToast: true,
  });

  return response.data;
};

/**
 *
 * Pass a child identification number and a term id,
 * and this will create an empty draft application
 */
const createApplicationWithChildIdentification = async (
  applicationDto: CreateEmptyApplicationDto
): Promise<Application> => {
  const response = await axiosClient.post<Application>(
    `/applications/initialise`,
    applicationDto
  );

  return response.data;
};

const createChoice = async (
  applicationId: string,
  data: CreateChoiceDto
): Promise<Application> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<Application>,
    CreateChoiceDto
  >(`/applications/${applicationId}/choices`, data);

  return response.data;
};

const runMatching = async (data: RunMatchingDto): Promise<MatchingSummary> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<MatchingSummary>,
    RunMatchingDto
  >(`/matching`, data, {
    disableToast: true,
  });

  return response.data;
};

const resetMatching = async (data: ResetMatchingDto): Promise<void> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<void>,
    ResetMatchingDto
  >(`/matching/reset`, data, {
    disableToast: true,
  });

  return response.data;
};

const getApplicationsByChildId = async (
  childId: string
): Promise<Application[]> => {
  const response = await axiosClient.get<Application[]>(
    `/applications/children/${childId}`
  );

  return response.data;
};

const deleteChoice = async ({
  applicationId,
  choiceId,
}: {
  applicationId: string;
  choiceId: string;
}) => {
  await axiosClient.delete<HttpError>(
    `/applications/${applicationId}/choices/${choiceId}`
  );
};

export const applicationService = Object.freeze({
  listApplications,
  getApplication,
  bulkUpdateApplications,
  bulkEmailApplicants,
  validateApplicationFile,
  processApplicationFile,
  createApplication,
  updateChoice,
  updateApplication,
  createApplicationWithChildIdentification,
  createChoice,
  runMatching,
  resetMatching,
  getApplicationsByChildId,
  countApplications,
  deleteChoice,
});
