type ConditionTagProps = {
  children: React.ReactNode;
};

function ConditionTag(props: ConditionTagProps) {
  const { children } = props;

  return (
    <span className="mt-4 inline-block rounded-md border border-primary-200 bg-primary-50 px-1.5 py-0.5 text-sm text-primary-700">
      {children}
    </span>
  );
}

export { ConditionTag };
