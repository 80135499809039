import { PermissionGuard } from '@/components/auth/permission-guard';
import { PageTitle } from '@/components/page-title';
import { Table } from '@/components/table';
import { staffRouterPath } from '@/config/route-paths.config';
import { useWeekModels } from '@/hooks/query-hooks/use-week-models';
import { Permission } from '@/types/auth';
import { getErrorMessage } from '@/utils/get-error-message';
import { Model } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import { Edit01, SearchRefraction } from '@untitled-ui/icons-react';
import { useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

function WeekModels() {
  const navigate = useNavigate();
  const { isLoading, data: models, error } = useWeekModels();

  const columnHelper = createColumnHelper<Model>();

  const handleEdit = (id: string) => () => {
    const redirectUrl = generatePath(staffRouterPath.EDIT_WEEK_MODEL, {
      id,
    });
    navigate(redirectUrl);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        cell: info => <span className="font-medium">{info.getValue()}</span>,
        header: () => <span>Name</span>,
        enableSorting: true,
        sortingFn: 'text',
      }),
      columnHelper.accessor('nbHoursPerWeek', {
        id: 'nbHoursPerWeek',
        cell: info => <span className="font-mono">{info.getValue()}</span>,
        header: () => <span>Hours per Week</span>,
        enableSorting: true,
        size: 388,
      }),
      columnHelper.display({
        id: 'id',
        cell: info => (
          <button
            type="button"
            className="edit-model-tooltip rounded p-2"
            onClick={handleEdit(info.row.original.id)}
          >
            <Edit01
              className="h-5 w-5"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
            <span className="sr-only">Edit Week Model</span>
          </button>
        ),
        size: 72,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleNavigateToNewModel = () => {
    navigate(staffRouterPath.NEW_WEEK_MODEL);
  };

  return (
    <div>
      <PageTitle
        title="Week Models"
        description="Specify the different calendar approaches to attendance"
        variant="white"
      >
        <PermissionGuard
          requiredPermissions={[Permission['organisation:create']]}
        >
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleNavigateToNewModel}
          >
            Add Model
          </button>
        </PermissionGuard>
      </PageTitle>

      <Tooltip
        anchorSelect=".edit-model-tooltip"
        content="Edit Week Model"
        place="top"
        className="tooltip"
      />
      <Table
        columns={columns}
        data={models}
        paginationType="auto"
        isLoading={isLoading}
        error={getErrorMessage(error)}
        EmptyState={
          <div className="py-12 text-center">
            <SearchRefraction
              className="mx-auto h-8 w-8 text-gray-400"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              No Week Models Found
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              You do not currently have any Week Models added. Please add your
              first Week Model.
            </p>
          </div>
        }
      />
    </div>
  );
}

export { WeekModels };
