import { useSchoolYear } from '@/context/school-year.context';
import { useTerms } from '@/hooks/query-hooks/use-terms';
import { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

type SchoolYearFilterProps = {
  className?: string;
  label?: string;
  disabled?: boolean;
};

function SchoolYearFilter(props: SchoolYearFilterProps) {
  const { className, label, disabled } = props;
  const { isLoading, data: terms } = useTerms();
  const { schoolYear, setSchoolYear } = useSchoolYear();
  const schoolYearsToDisplay = terms ? terms : [schoolYear];

  const actualSchoolYearId = schoolYear.id;
  useEffect(() => {
    const hasTerms = terms && Array.isArray(terms) && terms.length > 0;

    if (actualSchoolYearId || !hasTerms) {
      return;
    }

    setSchoolYear(terms[0]);
  }, [actualSchoolYearId, terms, setSchoolYear]);

  const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTermId = event.target.value;
    const selectedTerm = terms?.find(term => term.id === selectedTermId);

    if (selectedTerm) {
      setSchoolYear(selectedTerm);
    }
  };

  return (
    <fieldset>
      <label
        className={twMerge(label ? null : 'sr-only', 'label mb-2')}
        htmlFor="school-year-filter"
      >
        {label || 'School Year Filter'}
      </label>
      <select
        id="school-year-filter"
        className={twMerge('input shadow-none', className)}
        disabled={isLoading || disabled}
        onChange={onSelectChange}
        value={schoolYear.id}
      >
        {!schoolYear.id ? <option>Choose School Year...</option> : null}
        {schoolYearsToDisplay.map(term => (
          <option key={term.id} value={term.id}>
            {term.name}
          </option>
        ))}
      </select>
    </fieldset>
  );
}

export { SchoolYearFilter };
