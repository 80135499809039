import { LoadingScreen } from '@/components/loading-screen';
import { LoginTitle } from '@/components/login-title';
import { VerifyInvitationForm } from '@/components/parent/invitation/verify-invitation.form';
import { useInvitation } from '@/hooks/query-hooks/use-invitation';
import { Helmet } from 'react-helmet-async';

function VerifyInvitation() {
  const {
    isLoading: isInvitationLoading,
    isError: isInvitationError,
    isSuccess: isInvitationSuccess,
  } = useInvitation();

  if (isInvitationLoading) {
    return <LoadingScreen />;
  }

  if (!isInvitationSuccess || isInvitationError) {
    return (
      <>
        <Helmet>
          <title>Sign in</title>
        </Helmet>

        <div>
          <LoginTitle
            title="Invalid invitation code"
            description="Your invitation is expired or not valid"
          />
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Sign in</title>
      </Helmet>

      <div>
        <LoginTitle
          title="Security Question"
          description="Before you’re able to create your account, please confirm the the postcode and Date of Birth of a child you have in a West Lothian ran Nursery. If you have applied for multiple children, you can use the details of any of the children."
        />
        <VerifyInvitationForm />
      </div>
    </>
  );
}

export { VerifyInvitation };
