import { useApplicationsByChildId } from '@/hooks/query-hooks/use-applications-by-child-id';
import { ChildApplicationStatusTag } from '../child/child-application-status-tag';
import { Spinner } from '../spinner';

type ChildApplicationStatusCellProps = {
  childId: string;
};

function ChildApplicationStatusCell(props: ChildApplicationStatusCellProps) {
  const { childId } = props;

  const {
    data: applications,
    isLoading,
    isError,
    isSuccess,
  } = useApplicationsByChildId(childId);

  if (isLoading) {
    return <Spinner className="m-auto h-5" />;
  }

  if (isError || !isSuccess) {
    return <span>Invalid application data</span>;
  }

  if (applications.length <= 0) {
    return <span>No application</span>;
  }

  /**
   * We don't have a concept of a "current" term in the database
   * yet so the first application's status gets rendered until we do
   */
  return <ChildApplicationStatusTag status={applications[0].status} />;
}

export { ChildApplicationStatusCell };
