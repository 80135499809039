import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useDeleteCohortMutation(
  mutationOptions?: UseMutationOptions<void, HttpError, string>
) {
  return useMutation({
    mutationFn: organisationService.deleteCohort,
    ...mutationOptions,
  });
}

export { useDeleteCohortMutation };
