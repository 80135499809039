import { CheckboxItem } from '@/components/application/checkbox-item';
import { FallbackComponent } from '@/components/fallback-component';
import { LoadingScreen } from '@/components/loading-screen';
import { useCohorts } from '@/hooks/query-hooks/use-cohorts';
import { formatDateToYYYYMMDD } from '@/utils/format-date';
import { addDays } from 'date-fns';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { TextInput, TextInputBase } from '../common/text-input';
import { SchoolYearFormData } from './school-year.type';

type IntakesFormProps = {
  disabled?: boolean;
};

function IntakesForm(props: IntakesFormProps) {
  const { disabled } = props;
  const {
    isLoading: isCohortsLoading,
    isSuccess: isCohortsSuccess,
    data: cohortsData,
  } = useCohorts();
  const { watch, formState, setValue } = useFormContext<SchoolYearFormData>();
  const { append, remove } = useFieldArray<SchoolYearFormData>({
    name: 'intakes',
  });

  const intakes = watch('intakes');
  const endDate = watch('endDate');
  const startDate = watch('startDate');
  const selectedCohortIds = watch('selectedCohortIds');

  if (isCohortsLoading) {
    return <LoadingScreen />;
  }

  if (!isCohortsSuccess) {
    return <FallbackComponent />;
  }

  const findCohortNameById = (cohortId: string) => {
    const cohort = cohortsData.find(cohort => cohort.id === cohortId);

    return cohort ? cohort.name : '';
  };

  const addEmptyIntake = () => {
    const selectedCohorts = cohortsData.filter(
      cohort => selectedCohortIds.includes(cohort.id) && cohort.isUsedForIntake
    );

    append({
      name: '',
      startDate: '',
      endDate: '',
      deadline: '',
      cohortEligibility: selectedCohorts.map(cohort => ({
        cohortId: cohort.id,
        startDate: '',
        endDate: '',
        isFromChildsBirthday: false,
      })),
    });
  };

  const onChangeIsFromChildsBirthday =
    (
      name: `intakes.${number}.cohortEligibility.${number}`,
      previousValue: boolean
    ) =>
    () => {
      /**
       * if previously it was selected, now we deselect it
       * we need to reset start and end dates
       */
      // if (!previousValue) {
      //   setValue(`${name}.startDate`, '');
      //   setValue(`${name}.endDate`, '');
      // }

      setValue(`${name}.isFromChildsBirthday`, !previousValue);
    };

  return (
    <section>
      <div className="two-col-form pt-0">
        <div>
          <h2 className="text-md font-medium leading-7 text-gray-900">
            Eligibility
          </h2>
        </div>
        <div className="col-span-2 space-y-4">
          <div className="light-gray-container w-full space-y-4 sm:col-span-4">
            <p className="text-md font-medium">School Year</p>
            <p>
              Cohorts that do not rely on intakes will use the school year to
              determine eligibility.
            </p>

            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
              <TextInputBase
                type="date"
                label="Start Date*"
                value={startDate}
                disabled
              />
              <TextInputBase
                type="date"
                label="End Date*"
                value={endDate}
                disabled
              />
            </div>
            <TextInputBase
              type="date"
              label="Application Deadline*"
              value={formatDateToYYYYMMDD(addDays(new Date(endDate), -1))}
              disabled
            />
          </div>
          {intakes.map((intake, index) => {
            return (
              <div
                className="light-gray-container w-full space-y-4 sm:col-span-4"
                key={`intake-${index}`}
              >
                <div className="items-center justify-between space-y-4 sm:flex sm:space-y-0">
                  <p className="text-md font-medium">Intake</p>
                  <button
                    type="button"
                    className="btn btn-error"
                    onClick={() => remove(index)}
                  >
                    Remove
                  </button>
                </div>
                <TextInput
                  name={`intakes.${index}.name`}
                  type="text"
                  label="Intake Name*"
                  placeholder="Spring term"
                  disabled={disabled}
                />
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                  <TextInput
                    name={`intakes.${index}.startDate`}
                    type="date"
                    label="Start Date*"
                    disabled={disabled}
                  />
                  <TextInput
                    name={`intakes.${index}.endDate`}
                    type="date"
                    label="End Date*"
                    disabled={disabled}
                  />
                </div>
                <TextInput
                  name={`intakes.${index}.deadline`}
                  type="date"
                  label="Application Deadline*"
                  disabled={disabled}
                />
                {intake.cohortEligibility.length > 0 && (
                  <p className="text-md mb-12">Child Eligibility</p>
                )}
                {intake.cohortEligibility.map(
                  (eligibility, eligibilityIndex) => {
                    const isFromChildsBirthday =
                      intakes[index].cohortEligibility[eligibilityIndex]
                        .isFromChildsBirthday;
                    return (
                      <div
                        key={eligibility.cohortId}
                        className="dark-gray-container space-y-4"
                      >
                        <p className="text-md mb-6 font-medium">
                          {findCohortNameById(eligibility.cohortId)}
                        </p>
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                          <TextInput
                            name={`intakes.${index}.cohortEligibility.${eligibilityIndex}.startDate`}
                            type="date"
                            label="Date of Birth From*"
                            // disabled={disabled || isFromChildsBirthday}
                            disabled={disabled}
                          />
                          <TextInput
                            name={`intakes.${index}.cohortEligibility.${eligibilityIndex}.endDate`}
                            type="date"
                            label="Date of Birth To*"
                            // disabled={disabled || isFromChildsBirthday}
                            disabled={disabled}
                          />
                        </div>
                        <CheckboxItem
                          Content={() => (
                            <div>
                              <p className="mb-0.5 text-sm font-semibold">
                                From Child’s Birthday
                              </p>
                              <p className="text-sm">
                                A child becomes eligible from their birthday.
                              </p>
                            </div>
                          )}
                          checked={isFromChildsBirthday || false}
                          onChange={onChangeIsFromChildsBirthday(
                            `intakes.${index}.cohortEligibility.${eligibilityIndex}`,
                            isFromChildsBirthday
                          )}
                          disabled={disabled}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            );
          })}
          {formState.isSubmitted && intakes.length < 1 ? (
            <p className="mt-1.5 text-sm text-red-600">
              You need to add at least one intake!
            </p>
          ) : null}
          <button
            type="button"
            className="btn btn-secondary"
            onClick={addEmptyIntake}
          >
            Add Intake
          </button>
        </div>
      </div>
    </section>
  );
}

export { IntakesForm };
