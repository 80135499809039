import { reportKey } from '@/config/query-keys';
import { useReportingOutputControl } from '@/context/reporting-output-control.context';
import { reportService } from '@/services/report.service';
import { HttpError } from '@/types/error';
import { ToggledColumns } from '@/types/reporting-output-control';
import {
  BookingListing,
  CustomPaginateResult,
  ReportsBookingsDto,
} from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useBookingsReportResult(
  data: ReportsBookingsDto,
  queryOptions?: Partial<
    UseQueryOptions<CustomPaginateResult<BookingListing>, HttpError>
  >
) {
  return useQuery({
    queryKey: reportKey.getBookingsResults(data),
    queryFn: () => {
      return reportService.getBookingsReportResult({
        ...data,
        page: data.page && data.page !== 0 ? data.page + 1 : 1,
      });
    },
    ...queryOptions,
  });
}

function useExportBookingReportResultToCSV(
  data: ReportsBookingsDto,
  queryOptions?: Partial<UseQueryOptions<Blob, HttpError>>
) {
  const { toggledColumns } = useReportingOutputControl();

  const enabledColumns = Object.keys(toggledColumns).filter(
    key => toggledColumns[key as keyof ToggledColumns]
  );

  return useQuery({
    queryKey: reportKey.exportBookingsResult(data),
    queryFn: () => {
      return reportService.exportBookinsgReportResult({
        ...data,
        isCsvExport: true,
        toExport: enabledColumns as ReportsBookingsDto['toExport'],
        page: data.page && data.page !== 0 ? data.page + 1 : 1,
      });
    },
    ...queryOptions,
  });
}

export { useBookingsReportResult, useExportBookingReportResultToCSV };
