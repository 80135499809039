import * as yup from 'yup';

const childDetailsFormSchema = yup.object({
  firstName: yup.string().required().label('First Name'),
  lastName: yup.string().required().label('Last Name'),
  knownAsFirstName: yup.string().nullable().label('Known As First Name'),
  knownAsLastName: yup.string().nullable().label('Known As Last Name'),
  sex: yup.string().required().label('Sex'),
  dateOfBirth: yup.string().required().label('Date of Birth'),
  identificationType: yup
    .string()
    .oneOf(['passport', 'birth_certificate'])
    .required()
    .label('Identification Type'),
  identificationNumber: yup.string().required().label('Identification Number'),
  identificationCountry: yup
    .string()
    .when('identificationType', ([identificationType], schema) => {
      if (identificationType === 'passport') {
        return yup.string().required().label('Issuing Country');
      }
      return schema.nullable();
    }),
  attachments: yup
    .array(
      yup.object({
        filename: yup.string().required(),
        reference: yup.string().required(),
        key: yup.string().required(),
      })
    )
    .min(1, 'Add your scan of your document'),
});

export { childDetailsFormSchema };
