import { FallbackComponent } from '@/components/fallback-component';
import { RegisterDto, RegisterForm } from '@/components/form/register.form';
import { LoadingScreen } from '@/components/loading-screen';
import { LoginTitle } from '@/components/login-title';
import { parentRouterPath } from '@/config/route-paths.config';
import { useInvitation } from '@/hooks/query-hooks/use-invitation';
import { usePublicOrganisation } from '@/hooks/query-hooks/use-public-organisation';
import { useRegisterParentMutation } from '@/hooks/use-register-parent-mutation';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';

function ParentCreateAccount() {
  const { mutateAsync } = useRegisterParentMutation();
  const {
    data: organisation,
    isLoading: isOrganisationLoading,
    isError: isOrganisationError,
    isSuccess,
  } = usePublicOrganisation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get('code');
  const {
    isLoading: isInvitationLoading,
    data: invitation,
    isError: isInvitationError,
    isSuccess: isInvitationSuccess,
  } = useInvitation({
    enabled: !!invitationCode,
  });

  if (isOrganisationError || !isSuccess) {
    return <FallbackComponent />;
  }

  if (isInvitationError) {
    return (
      <>
        <Helmet>
          <title>Create account</title>
        </Helmet>

        <div>
          <LoginTitle
            title="Invalid invitation code"
            description="You invitation is expired or not valid"
          />
        </div>
      </>
    );
  }

  if (isOrganisationLoading || isInvitationLoading) {
    return <LoadingScreen />;
  }

  const handleRegister = async (dto: RegisterDto) => {
    await mutateAsync({
      firstName: dto.firstName,
      lastName: dto.lastName,
      email: dto.email.trim(),
      password: dto.password,
      organisationId: organisation?.id,
      code: invitationCode || undefined,
    });
    navigate(parentRouterPath.VERIFY_EMAIL, { state: { email: dto.email } });
  };

  return (
    <>
      <Helmet>
        <title>Create your account</title>
      </Helmet>

      <div>
        <LoginTitle
          title={
            isInvitationSuccess ? 'Set a New Password' : 'Create Your Account'
          }
        />
        <RegisterForm onRegister={handleRegister} initialData={invitation} />
      </div>
    </>
  );
}

export { ParentCreateAccount };
