import { Alert } from '@/components/alert';
import { MultiSelect } from '@/components/form/common/multi-select';
import { Select } from '@/components/form/common/select';
import { TextInput } from '@/components/form/common/text-input';
import { Textarea } from '@/components/form/common/textarea';
import { SessionDetails } from '@/components/session-details';
import { staffRouterPath } from '@/config/route-paths.config';
import { useSchoolYear } from '@/context/school-year.context';
import { hasLocationExtraHourResourceForSession } from '@/utils/product-utils';
import {
  ExtraHourResource,
  Product,
  ProductListing,
  Session,
} from '@admissions-support/types';
import { format } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import { Link, generatePath, useParams } from 'react-router-dom';
import { ManageProductFormData } from '../../manage-product.type';

type ProductDetailsElementProps = {
  sessions: Session[];
  sessionTypesValues: { label: string; value: string }[];
  extraHoursResource: ExtraHourResource[];
  initialData?: ProductListing | Product;
};

function ProductDetailsElement(props: ProductDetailsElementProps) {
  const { sessions, sessionTypesValues, initialData, extraHoursResource } =
    props;

  const { schoolYear } = useSchoolYear();
  const { locationId = '' } = useParams<string>();

  const { watch } = useFormContext<ManageProductFormData>();

  const sessionId = watch('sessionId');
  const hasResourceAllocation = watch('hasResourceAllocation');

  const formattedSessions = sessions.map(session => ({
    key: session.id,
    value: session.name ? session.name : session.id,
  }));

  const selectedSession = sessions.find(session => session.id === sessionId);

  const hasNoResourceForSession = !hasLocationExtraHourResourceForSession({
    session: selectedSession,
    extraHoursResource,
  });

  return (
    <div className="space-y-5">
      <Select
        name="sessionId"
        options={[{ key: '', value: 'Select...' }, ...formattedSessions]}
        label="Select Session"
        helperText={
          hasResourceAllocation
            ? 'What resource will this product use?'
            : undefined
        }
      />
      {/**
       * Display error message only if the product has resource allocation. We dont
       * need to define capacity for top-up products
       */}
      {hasResourceAllocation && selectedSession && hasNoResourceForSession && (
        <Alert
          type="error"
          text={
            <>
              <p className="font-semibold">Missing Resource!</p>
              <p>
                You forgot to define Extra Hour Resource for{' '}
                <span className="italic">{selectedSession.type.name}</span>{' '}
                session type.{' '}
                <Link
                  className="text-red-700 underline"
                  to={generatePath(
                    staffRouterPath.LOCATION_PURCHASE_EXTRA_HOURS_AVAILABILITY,
                    { locationId }
                  )}
                >
                  Click here
                </Link>{' '}
                to define the capacity.
              </p>
            </>
          }
        />
      )}
      {!hasNoResourceForSession && (
        <SessionDetails sessionId={sessionId} schoolYearId={schoolYear.id} />
      )}
      <div className="flex gap-6">
        <TextInput
          type="text"
          label="Product Name"
          name="name"
          placeholder="eg AM to Full Day"
          className="flex-1"
        />
        {!initialData ? (
          <TextInput
            prefix="£"
            type="number"
            label="Product Price"
            name="price"
            placeholder="00"
            min={0}
            className="flex-1"
          />
        ) : null}
      </div>
      <div className="flex gap-6">
        <TextInput
          name="availableFrom"
          type="date"
          label="Available From"
          className="flex-1"
          min={format(new Date(schoolYear.startDate), 'yyyy-MM-dd')}
          max={format(new Date(schoolYear.endDate), 'yyyy-MM-dd')}
        />
        <TextInput
          name="availableTo"
          type="date"
          label="Available To"
          className="flex-1"
          min={format(new Date(schoolYear.startDate), 'yyyy-MM-dd')}
          max={format(new Date(schoolYear.endDate), 'yyyy-MM-dd')}
        />
      </div>
      <MultiSelect
        name="linkedSessionTypes"
        options={sessionTypesValues}
        label="Only available to users of Session Type(s)"
        placeholder="Select Session..."
        helperText="Select session types that will use these products."
      />
      <Textarea
        name="description"
        label="Description"
        placeholder="Enter a description..."
        rows={5}
      />
    </div>
  );
}

export { ProductDetailsElement };
