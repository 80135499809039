import {
  defaultApplicationControl,
  useApplicationControl,
} from '@/context/application-control.context';
import { useSchoolYear } from '@/context/school-year.context';
import { useLocations } from '@/hooks/query-hooks/use-locations';
import { ChoiceFilter } from '@admissions-support/types';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDown, XClose } from '@untitled-ui/icons-react';
import { Fragment, ReactNode, useEffect, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { Tag } from '../tag';
import { ApplicationStatusTag } from './application-status-tag';
import { ChoiceStatusTag } from './choice-status-tag';
import { ChoiceStatusesFilter, choiceStatuses } from './choice-statuses-filter';
import { ChoicesFilter, choices } from './choices-filter';
import { IntakeFilter } from './intake-filter';
import { LocationFilter } from './location-filter';
import { StatusFilter, statuses } from './status-filter';

function ApplicationsFilterRow() {
  const { filter, setFilter, type } = useApplicationControl();
  const { data: locationsData, isLoading } = useLocations();
  const { schoolYear } = useSchoolYear();

  const locations = useMemo(
    () =>
      locationsData?.map(location => ({
        label: location.name,
        value: location.id,
      })) || [],
    [locationsData]
  );

  const intakes = schoolYear.intakes.map(intake => ({
    label: intake.name,
    value: intake.id,
  }));

  intakes.push({
    label: 'School Year',
    value: 'school-year',
  });

  const handleResetStatus = () => {
    setFilter(prevFilter => ({
      ...prevFilter,
      statuses: defaultApplicationControl.filter.statuses,
    }));
  };

  const handleResetChoices = () => {
    setFilter(prevFilter => ({
      ...prevFilter,
      choices: defaultApplicationControl.filter.choices,
    }));
  };

  const handleResetLocation = () => {
    setFilter(prevFilter => ({
      ...prevFilter,
      locations: defaultApplicationControl.filter.locations,
    }));
  };

  const handleResetChoiceStatuses = () => {
    setFilter(prevFilter => ({
      ...prevFilter,
      choiceStatuses: defaultApplicationControl.filter.choiceStatuses,
    }));
  };

  const handleResetIntake = () => {
    setFilter(prevFilter => ({
      ...prevFilter,
      intakes: defaultApplicationControl.filter.intakes,
    }));
  };

  function SelectedStatuses() {
    const selectedStatuses = statuses
      .filter(status => filter.statuses.includes(status.value))
      .sort(
        (a, b) =>
          filter.statuses.indexOf(a.value) - filter.statuses.indexOf(b.value)
      );

    return (
      <div className="space-x-1">
        <ApplicationStatusTag status={selectedStatuses[0].value}>
          {selectedStatuses[0].label}
        </ApplicationStatusTag>
      </div>
    );
  }

  function SelectedChoices() {
    const selectedChoices = choices
      .filter(choice =>
        filter.choices.includes(parseInt(choice.value) as ChoiceFilter)
      )
      .sort(
        (a, b) =>
          filter.choices.indexOf(parseInt(a.value) as ChoiceFilter) -
          filter.choices.indexOf(parseInt(b.value) as ChoiceFilter)
      );
    const getChoice = (nbChoice: number | null) => {
      if (nbChoice === 1) {
        return (
          <Tag type="success" className="px-1 py-0 text-xs">
            1st choice
          </Tag>
        );
      }

      if (nbChoice === 2) {
        return (
          <Tag type="warning" className="px-1 py-0 text-xs">
            2nd choice
          </Tag>
        );
      }

      if (nbChoice === 3) {
        return (
          <Tag type="error" className="px-1 py-0 text-xs">
            3rd choice
          </Tag>
        );
      }

      return (
        <Tag type="default" className="px-1 py-0 text-xs">
          None
        </Tag>
      );
    };

    return (
      <div className="space-x-1">
        {getChoice(parseInt(selectedChoices[0].value))}
      </div>
    );
  }

  function SelectedChoiceStatuses() {
    const selectedStatuses = choiceStatuses
      .filter(status => filter.choiceStatuses.includes(status.value))
      .sort(
        (a, b) =>
          filter.choiceStatuses.indexOf(a.value) -
          filter.choiceStatuses.indexOf(b.value)
      );

    return (
      <div className="space-x-1">
        <ChoiceStatusTag status={selectedStatuses[0].value}>
          {selectedStatuses[0].label}
        </ChoiceStatusTag>
      </div>
    );
  }

  function SelectedLocations() {
    const selectedLocations = locations
      .filter(location => filter.locations.includes(location.value))
      .sort(
        (a, b) =>
          filter.locations.indexOf(a.value) - filter.locations.indexOf(b.value)
      );

    return (
      <Tag
        key={selectedLocations[0].value}
        type="outline"
        className="px-1 py-0 text-xs"
      >
        {selectedLocations[0].label}
      </Tag>
    );
  }

  function SelectedIntakes() {
    const selectedIntakes = intakes
      .filter(intake => filter.intakes.includes(intake.value))
      .sort(
        (a, b) =>
          filter.intakes.indexOf(a.value) - filter.intakes.indexOf(b.value)
      );

    return (
      <Tag
        key={selectedIntakes[0].value}
        type="outline"
        className="px-1 py-0 text-xs"
      >
        {selectedIntakes[0].label}
      </Tag>
    );
  }

  return (
    <div className="mt-3 border-t border-gray-100 py-3">
      <Popover.Group className="hidden sm:flex sm:flex-wrap sm:items-baseline sm:gap-2">
        <FilterItem
          label="Status"
          counter={filter.statuses.length}
          reset={handleResetStatus}
          renderSelectedItems={<SelectedStatuses />}
        >
          <StatusFilter />
        </FilterItem>
        <FilterItem
          label="Choice"
          counter={filter.choices.length}
          reset={handleResetChoices}
          renderSelectedItems={<SelectedChoices />}
        >
          <ChoicesFilter />
        </FilterItem>
        {!isLoading && type === 'main' && (
          <FilterItem
            label="Location"
            counter={filter.locations.length}
            reset={handleResetLocation}
            renderSelectedItems={<SelectedLocations />}
          >
            <LocationFilter />
          </FilterItem>
        )}
        <FilterItem
          label="Choice Status"
          counter={filter.choiceStatuses.length}
          reset={handleResetChoiceStatuses}
          renderSelectedItems={<SelectedChoiceStatuses />}
        >
          <ChoiceStatusesFilter />
        </FilterItem>

        <FilterItem
          label="Intakes"
          counter={filter.intakes.length}
          reset={handleResetIntake}
          renderSelectedItems={<SelectedIntakes />}
        >
          <IntakeFilter />
        </FilterItem>
      </Popover.Group>
    </div>
  );
}

type HandleCloseProps = {
  onClose?: () => void;
  isOpen: boolean;
};

// This component is used to create an onClose event for the popover, because it doesn't provide one
// The reason we have this extracted is because the ESLint rules (react-hooks/rules-of-hooks specifically)
// don't allow us to place the useEffect hook inside the wrapper function of the Popover component ({({ open }) => ()
function HandleClose({ onClose, isOpen }: HandleCloseProps) {
  useEffect(() => {
    if (!isOpen && onClose) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // We return null because we don't want to render anything
  return null;
}

type FilterItemProps = {
  counter: number;
  reset: () => void;
  label: string;
  children: ReactNode;
  renderSelectedItems?: ReactNode;
  onClose?: () => void;
};

function FilterItem(props: FilterItemProps) {
  const { counter, reset, label, children, renderSelectedItems, onClose } =
    props;

  return (
    <Popover as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <HandleClose isOpen={open} onClose={onClose} />
          <div className="relative flex rounded-md bg-white text-gray-700 shadow-sm ring-1 ring-gray-300">
            <Popover.Button
              className={twMerge(
                'btn btn-secondary btn-empty btn-icon py-2 ring-0',
                counter !== 0 ? 'br-0 rounded-br-none rounded-tr-none' : ''
              )}
            >
              <span className="mr-2 py-0.5 font-semibold">{label}</span>
              {counter > 0 && renderSelectedItems}
              {counter > 1 && (
                <span className="ml-1 mt-0.5 text-xs text-gray-500">
                  +{counter - 1}
                </span>
              )}
              <ChevronDown
                viewBox="0 0 24 24"
                className="trailing-icon ui-open:rotate-180 ui-open:transform"
                aria-hidden="true"
              />
            </Popover.Button>
            {counter !== 0 ? (
              <button
                className="btn btn-secondary btn-empty bl-0 rounded-bl-none rounded-tl-none bg-transparent pl-0 ring-0"
                onClick={reset}
              >
                <XClose
                  viewBox="0 0 24 24"
                  className="trailing-icon m-0"
                  aria-hidden="true"
                />
              </button>
            ) : null}
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="absolute left-0 z-50 mt-2 min-w-[200px] rounded-md bg-white px-4 py-3 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
              {children}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export { ApplicationsFilterRow, FilterItem };
