import { get } from 'lodash-es';
import { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  name: string;
  label?: string;
  options: { key: string; value: string }[];
  helperText?: string;
}

interface SelectBaseProps extends SelectProps {
  error?: string;
}

function SelectBase({
  label,
  name,
  options,
  helperText,
  className,
  error,
  ...inputProps
}: SelectBaseProps) {
  const hasError = Boolean(error);

  return (
    <div className={className}>
      {label ? (
        <label htmlFor={name} className="label mb-2">
          {label}
        </label>
      ) : null}
      <div className="relative">
        <select
          id={name}
          className={twMerge(
            'input',
            hasError ? 'ring-red-500 focus:ring-red-300' : null,
            inputProps.value === '' ? 'text-gray-400' : 'text-gray-900'
          )}
          aria-invalid={hasError ? 'true' : 'false'}
          {...inputProps}
        >
          {options.map(option => (
            <option key={option.key} value={option.key}>
              {option.value}
            </option>
          ))}
        </select>
      </div>
      {helperText ? (
        <p className="mt-1.5 text-sm text-gray-600">{helperText}</p>
      ) : null}
      {hasError ? <p className="mt-1.5 text-sm text-red-600">{error}</p> : null}
    </div>
  );
}

function Select({
  label,
  name,
  options,
  helperText,
  className,
  ...inputProps
}: SelectProps) {
  const {
    register,
    formState: { errors, isSubmitting },
    watch,
  } = useFormContext();
  const error = get(errors, name);
  const hasError = Boolean(error);

  const textColor = watch(name) === '' ? 'text-gray-400' : 'text-gray-900';

  return (
    <div className={className}>
      {label ? (
        <label htmlFor={name} className="label mb-2">
          {label}
        </label>
      ) : null}
      <div className="relative">
        <select
          id={name}
          className={twMerge(
            'input',
            hasError ? 'ring-red-500 focus:ring-red-300' : null,
            textColor
          )}
          aria-invalid={hasError ? 'true' : 'false'}
          {...inputProps}
          disabled={isSubmitting || inputProps.disabled}
          {...register(name)}
        >
          {options.map(option => (
            <option key={`${name}-${option.key}`} value={option.key}>
              {option.value}
            </option>
          ))}
        </select>
      </div>
      {helperText ? (
        <p className="mt-1.5 text-sm text-gray-600">{helperText}</p>
      ) : null}
      {hasError ? (
        <p className="mt-1.5 text-sm text-red-600">
          {error?.message?.toString()}
        </p>
      ) : null}
    </div>
  );
}

export { Select, SelectBase };
