import { Balance } from '@/components/extra-hours-bookings/balance';
import { BookingAvailaility } from '@/components/extra-hours-bookings/booking-availability';
import { Payments } from '@/components/extra-hours-bookings/payments';
import { FallbackComponent } from '@/components/fallback-component';
import { LoadingScreen } from '@/components/loading-screen';
import { PageTitle } from '@/components/page-title';
import { ChildExtraHoursTable } from '@/components/parent/purchase-extra-hours/child-extra-hours-table';
import { staffRouterPath } from '@/config/route-paths.config';
import { useChild } from '@/hooks/query-hooks/use-child';
import { Link, useParams } from 'react-router-dom';

function ExtraHoursBookingsChildDetails() {
  const params = useParams<{ id: string }>();
  const childId = params.id || '';

  const {
    data: child,
    isError: isChildError,
    isSuccess: isChildSuccess,
    isLoading: isChildLoading,
  } = useChild(childId);

  const isLoading = isChildLoading;

  const isError = isChildError;

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError || !isChildSuccess) {
    return <FallbackComponent />;
  }

  return (
    <div>
      <PageTitle
        title={`Extra Hours Bookings for ${child.firstName} ${child.lastName}`}
        variant="gray"
        className="mb-6"
      >
        <Link
          to={staffRouterPath.EXTRA_HOURS_BOOKINGS}
          className="btn btn-secondary"
        >
          Return to All Bookings
        </Link>
      </PageTitle>
      <div className="space-y-6">
        <div className="grid gap-6 sm:grid-cols-3">
          <Balance childId={childId} />
          <BookingAvailaility childId={childId} />
        </div>
        <Payments child={child} />
        <ChildExtraHoursTable />
      </div>
    </div>
  );
}

export { ExtraHoursBookingsChildDetails };
