import { invitationKeys } from '@/config/query-keys';
import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import { CreateInvitationDto, Invitation } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCreateInvitationMutation(
  mutationOptions?: UseMutationOptions<
    Invitation,
    HttpError,
    CreateInvitationDto
  >
) {
  return useMutation({
    mutationKey: invitationKeys.create,
    mutationFn: organisationService.createInvitation,
    ...mutationOptions,
  });
}

export { useCreateInvitationMutation };
