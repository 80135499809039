import type { Application } from '@admissions-support/types';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { LocationChoice } from '../application/location-choice';
import { useOrganisationData } from '@/context/organisation-control.context';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  application: Application;
};

function PlacementDetailsModal(props: Props) {
  const { isOpen, onClose, application } = props;

  const { data: organisation } = useOrganisationData();

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Placement Details
                </Dialog.Title>
                <div className="mt-2">
                  <p className="pb-6 text-sm text-gray-600">
                    Please find your child’s placement details below.
                  </p>

                  <div className="flex flex-col gap-6">
                    {application.choices
                      .filter(choice => choice.status === 'PLACED')
                      .map(choice => {
                        return (
                          <LocationChoice
                            schoolYearId={
                              application.schoolYear.id.toString() || ''
                            }
                            choice={choice}
                            key={choice.id}
                          />
                        );
                      })}
                  </div>

                  <p className="text-md py-6 text-gray-600">
                    You will receive written confirmation of this placement and
                    further information in the post.
                  </p>
                  <p className="text-sm text-gray-600">
                    Should this placement not be suitable, please{' '}
                    <a
                      href={`mailto:${organisation.contactEmail}`}
                      className="font-semibold text-gray-600"
                    >
                      Contact Us
                    </a>{' '}
                    as soon as possible.{' '}
                  </p>
                </div>

                <div className="mt-7">
                  <button
                    type="button"
                    className="btn btn-primary w-full"
                    onClick={onClose}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export { PlacementDetailsModal };
