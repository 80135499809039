type DetailProps = {
  label: string;
  content: React.ReactNode;
};

function Detail(props: DetailProps) {
  const { label, content } = props;

  return (
    <div className="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="text-md font-medium leading-6 text-gray-700">{label}</dt>
      <dd className="text-md mt-1 leading-6 text-gray-600 sm:col-span-2 sm:mt-0">
        {content}
      </dd>
    </div>
  );
}

export { Detail };
