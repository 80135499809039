import { useReportingOutputControl } from '@/context/reporting-output-control.context';
import { exportableKeysLocations } from '@/utils/query-builder-utils';
import { Location } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { TextCell } from '../cells/text-cell';
import { camelCase, clone, compact } from 'lodash-es';
import { formatLocationAddress, getTagType } from '@/utils/location-utils';
import { Copy01 } from '@untitled-ui/icons-react';
import toast from 'react-hot-toast';
import { Tag } from '@/components/tag';
import { ProviderTypeBadge } from '@/components/application/provider-type-badge';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { format } from 'date-fns';
import { ToggledColumns } from '@/types/reporting-output-control';

type UseOutputLocationsTableColumnsProps = {
  dataSource: string;
};

const isLocationsToggledColumns = (data: ToggledColumns) => {
  return (
    data['areas.name'] !== undefined && data['sessions.type.name'] !== undefined
  );
};

function useOutputLocationsTableColumns(
  props: UseOutputLocationsTableColumnsProps
) {
  const { dataSource } = props;
  const columnHelper = createColumnHelper<Location>();

  const { toggledColumns, openModal, setSideDrawerContent } =
    useReportingOutputControl();

  const getToggledColumnsValuesThatStartWith = (string: string) => {
    /**
     * If dataSource is not LOCATIONS, we need to create an array with the same length
     * in order to avoid inconsistencies with useMemo dependency arrays.
     * Also need to check if toggledColumns is changed since there is a rerender
     * where dataSource has already changed but toggledColumn hasn't.
     */
    if (
      dataSource !== 'LOCATIONS' ||
      !isLocationsToggledColumns(toggledColumns)
    ) {
      return exportableKeysLocations
        .filter(key => key.startsWith(string))
        .map(() => false);
    }

    const filteredToggledColumns = Object.values(
      Object.entries(toggledColumns)
        .filter(([key]) => {
          return key.startsWith(string);
        })
        .reduce((acc, curr) => {
          const [key, value] = curr;
          return { ...acc, [key]: value };
        }, {})
    );

    return filteredToggledColumns;
  };

  const handleCopyValue = async (locationId: string) => {
    try {
      if (!locationId) {
        return;
      }

      await navigator.clipboard.writeText(locationId);

      toast.success('ID copied to clipboard!');
    } catch (error) {
      toast.error('Error copying to clipboard!');
    }
  };

  const nameColumnDependencies = [
    toggledColumns['name'],
    ...getToggledColumnsValuesThatStartWith('address'),
  ];

  const nameColumn = useMemo(
    () => [
      toggledColumns.name
        ? columnHelper.accessor('name', {
            id: 'name',
            cell: info => {
              const address = clone(info.row.original.address);

              if (!toggledColumns['address.streetAddress']) {
                address.streetAddress = '';
              }
              if (!toggledColumns['address.streetAddressTwo']) {
                address.streetAddressTwo = '';
              }
              if (!toggledColumns['address.city']) {
                address.city = '';
              }
              if (!toggledColumns['address.postcode']) {
                address.postcode = '';
              }

              const addressString = formatLocationAddress(address);

              const locationId = info.row.original.id;

              const name = info.getValue();

              return (
                <span className="block text-sm">
                  <div className="flex items-center font-medium text-gray-900">
                    {name}
                    <button
                      type="button"
                      className="copy-location-id-tooltip ml-2"
                      onClick={() => handleCopyValue(locationId)}
                    >
                      <Copy01 className="h-4 w-4" />
                    </button>
                  </div>
                  {addressString}
                </span>
              );
            },
            header: () => <span>Name</span>,
            enableSorting: false,
            // size: 183,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...nameColumnDependencies]
  );

  const typeColumnDeps = [toggledColumns.type];

  const typeColumn = useMemo(
    () => [
      toggledColumns['type']
        ? columnHelper.accessor('type', {
            id: 'type',
            cell: info => {
              const type = info.getValue();

              return <TextCell cellValue={type} />;
            },
            header: () => <span>Location Type</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...typeColumnDeps]
  );

  const statusColumnDeps = [toggledColumns.status];

  const statusColumn = useMemo(
    () => [
      toggledColumns['status']
        ? columnHelper.accessor('status', {
            id: 'status',
            cell: info => (
              <Tag type={getTagType(info.getValue())} className="capitalize">
                {camelCase(info.getValue())}
              </Tag>
            ),
            header: () => <span>Status</span>,
            enableSorting: false,
            size: 120,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...statusColumnDeps]
  );

  const providerTypeColumnDeps = [toggledColumns.providerType];

  const providerTypeColumn = useMemo(
    () => [
      toggledColumns['providerType']
        ? columnHelper.accessor('providerType', {
            id: 'providerType',
            cell: info => <ProviderTypeBadge providerType={info.getValue()} />,
            header: () => <span>Provider Type</span>,
            enableSorting: false,
            size: 120,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...providerTypeColumnDeps]
  );

  const serviceProviderNumberColumnDeps = [
    toggledColumns.serviceProviderNumber,
  ];

  const serviceProviderNumberColumn = useMemo(
    () => [
      toggledColumns['serviceProviderNumber']
        ? columnHelper.accessor('serviceProviderNumber', {
            id: 'serviceProviderNumber',
            cell: info => {
              const serviceProviderNumber = info.getValue();
              return <TextCell cellValue={serviceProviderNumber} />;
            },
            header: () => <span>Service Provider Number</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...serviceProviderNumberColumnDeps]
  );

  const careInspectorNumberColumnDeps = [toggledColumns.careInspectorNumber];

  const careInspectorNumberColumn = useMemo(
    () => [
      toggledColumns['careInspectorNumber']
        ? columnHelper.accessor('careInspectorNumber', {
            id: 'careInspectorNumber',
            cell: info => {
              const careInspectorNumber = info.getValue();
              return <TextCell cellValue={careInspectorNumber} />;
            },
            header: () => <span>Care Inspector Number</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...careInspectorNumberColumnDeps]
  );

  const contactNumberColumnDeps = [toggledColumns.contactNumber];

  const contactNumberColumn = useMemo(
    () => [
      toggledColumns['contactNumber']
        ? columnHelper.accessor('contactNumber', {
            id: 'contactNumber',
            cell: info => {
              const contactNumber = info.getValue();
              return <TextCell cellValue={contactNumber} />;
            },
            header: () => <span>Contact Number</span>,
            enableSorting: true,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...contactNumberColumnDeps]
  );

  const websiteColumnDeps = [toggledColumns.website];

  const websiteColumn = useMemo(
    () => [
      toggledColumns['website']
        ? columnHelper.accessor('website', {
            id: 'website',
            cell: info => {
              const website = info.getValue();
              if (!website) {
                return '--';
              }

              try {
                const parsedUrl = new URL(website);

                return <Link to={website}>{parsedUrl.hostname}</Link>;
              } catch {
                return <TextCell cellValue={website} />;
              }
            },
            header: () => <span>Website</span>,
            enableSorting: false,
            size: 100,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...websiteColumnDeps]
  );

  const notesColumnDeps = [toggledColumns.notes];

  const notesColumn = useMemo(
    () => [
      toggledColumns['notes']
        ? columnHelper.accessor('notes', {
            id: 'notes',
            cell: info => {
              const notes = info.getValue();
              return <TextCell cellValue={notes} />;
            },
            header: () => <span>Notes</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...notesColumnDeps]
  );

  const areasColumnDeps = [toggledColumns['areas.name']];

  const areasColumn = useMemo(
    () => [
      toggledColumns['areas.name']
        ? columnHelper.accessor('areas', {
            id: 'areas',
            cell: info => {
              const areas = info.getValue()?.map(area => area.name);

              return (
                <TextCell cellValue={areas.join(', ')} tooltipId="areas" />
              );
            },
            header: () => <span>Areas</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...areasColumnDeps]
  );

  const isKeyContactColumnEnabled =
    toggledColumns['keyContact.firstName'] ||
    toggledColumns['keyContact.lastName'] ||
    toggledColumns['keyContact.email'];

  const keyContactColumnDeps = [
    toggledColumns['keyContact.firstName'],
    toggledColumns['keyContact.lastName'],
    toggledColumns['keyContact.email'],
  ];

  const keyContactColumn = useMemo(
    () => [
      isKeyContactColumnEnabled
        ? columnHelper.accessor('keyContact', {
            id: 'keyContact',
            cell: info => {
              const keyContact = info.getValue();
              const keyContactFirstName = toggledColumns['keyContact.firstName']
                ? keyContact.firstName
                : '';
              const keyContactLastName = toggledColumns['keyContact.lastName']
                ? keyContact.lastName
                : '';
              const keyContactEmail = toggledColumns['keyContact.email']
                ? keyContact.email
                : '';

              const isOverflow = keyContactEmail && 25 < keyContactEmail.length;

              return (
                <div className="flex flex-col">
                  <span>{`${keyContactFirstName} ${keyContactLastName}`}</span>
                  <span
                    className={twMerge(
                      'block max-w-52 overflow-hidden truncate',
                      isOverflow ? 'hover:cursor-pointer' : ''
                    )}
                    data-tooltip-id={isOverflow ? 'key-contact-email' : ''}
                    data-tooltip-content={keyContactEmail}
                  >{`${keyContactEmail}`}</span>
                </div>
              );
            },
            header: () => <span>Key Contact</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...keyContactColumnDeps]
  );

  const sessionsColumnDeps = getToggledColumnsValuesThatStartWith('session');

  const isSessionsColumnEnabled = sessionsColumnDeps.some(val => val);

  const sessionsColumn = useMemo(
    () => [
      isSessionsColumnEnabled
        ? columnHelper.accessor('sessions', {
            id: 'sessions',
            cell: info => {
              const sessions = info.getValue();

              return (
                <button
                  type="button"
                  className="rounded-md p-2 font-semibold text-primary-700 hover:bg-gray-50"
                  onClick={() => {
                    openModal();
                    setSideDrawerContent(sessions);
                  }}
                >
                  View Sessions
                </button>
              );
            },
            header: () => <span>Sessions</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...sessionsColumnDeps]
  );

  const createdAtColumnDeps = [toggledColumns['createdAt']];

  const createdAtColumn = useMemo(
    () => [
      toggledColumns['createdAt']
        ? columnHelper.accessor('createdAt', {
            id: 'created_at',
            cell: info => {
              const createdAt = info.getValue()
                ? format(new Date(info.getValue() || ''), 'dd/MM/yyyy')
                : '--';

              return <TextCell cellValue={createdAt} />;
            },
            header: () => <span>Created At</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...createdAtColumnDeps]
  );

  const updatedAtColumnDeps = [toggledColumns['updatedAt']];

  const updatedAtColumn = useMemo(
    () => [
      toggledColumns['updatedAt']
        ? columnHelper.accessor('updatedAt', {
            id: 'updated_at',
            cell: info => {
              const updatedAt = info.getValue()
                ? format(new Date(info.getValue() || ''), 'P')
                : '--';

              return <TextCell cellValue={updatedAt} />;
            },
            header: () => <span>Updated At</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...updatedAtColumnDeps]
  );

  return {
    columns: compact([
      ...nameColumn,
      ...typeColumn,
      ...statusColumn,
      ...providerTypeColumn,
      ...serviceProviderNumberColumn,
      ...careInspectorNumberColumn,
      ...contactNumberColumn,
      ...websiteColumn,
      ...notesColumn,
      ...areasColumn,
      ...keyContactColumn,
      ...sessionsColumn,
      ...createdAtColumn,
      ...updatedAtColumn,
    ]),
  };
}

export { useOutputLocationsTableColumns };
