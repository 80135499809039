import { useCohorts } from '@/hooks/query-hooks/use-cohorts';
import { difference } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { MultiSelect } from '../common/multi-select';
import { TextInput } from '../common/text-input';
import { SchoolYearFormData } from './school-year.type';

type CohortsFormProps = {
  disabled?: boolean;
};

function CohortsForm(props: CohortsFormProps) {
  const { disabled } = props;
  const {
    isLoading: isCohortsLoading,
    isSuccess: isCohortsSuccess,
    data: cohortsData,
  } = useCohorts();
  const { watch, setValue } = useFormContext<SchoolYearFormData>();
  const { append: addNewCohort, remove: deleteCohort } =
    useFieldArray<SchoolYearFormData>({
      name: 'cohorts',
    });

  const selectedCohortIds = watch('selectedCohortIds');
  const cohorts = watch('cohorts');
  const intakes = watch('intakes');

  const formattedCohorts = useMemo(
    () =>
      isCohortsSuccess
        ? cohortsData.map(cohort => ({
            label: cohort.name,
            value: cohort.id,
            isUsedForIntake: cohort.isUsedForIntake,
          }))
        : [],
    [isCohortsSuccess, cohortsData]
  );

  const handleSelectCohortChange = useCallback(() => {
    const oldCohortIds = cohorts.map(cohort => cohort.cohortId);
    const addedCohortIds = difference(selectedCohortIds, oldCohortIds);
    const deletedCohortIds = difference(oldCohortIds, selectedCohortIds);

    addedCohortIds.forEach(selectedCohortId => {
      const selectedCohort = formattedCohorts.find(
        cohort => cohort.value === selectedCohortId
      );

      if (!selectedCohort) {
        return;
      }

      /**
       * if user adds a new cohort we also need to add this to the intake cohort eligibility array,
       * but only if the cohort set to used for intake
       */
      if (selectedCohort.isUsedForIntake) {
        intakes.map((intake, index) => {
          const newEligibility = [
            ...intake.cohortEligibility,
            {
              cohortId: selectedCohortId || '', // it cannot be null because of the earlier check
              startDate: '',
              endDate: '',
              isFromChildsBirthday: false,
            },
          ];

          setValue(`intakes.${index}`, {
            ...intake,
            cohortEligibility: newEligibility,
          });
        });
      }

      addNewCohort({
        cohortId: selectedCohort.value,
        cohortName: selectedCohort.label,
        startDate: '',
        endDate: '',
        isFromChildsBirthday: false,
      });
    });

    deletedCohortIds.forEach(deletedCohortId => {
      const selectedCohortIndex = cohorts.findIndex(
        cohort => cohort.cohortId === deletedCohortId
      );

      if (selectedCohortIndex < 0) {
        return;
      }

      deleteCohort(selectedCohortIndex);

      /**
       * if user adds a new cohort we also need to add this to the intake cohort eligibility array,
       * but only if the cohort set to used for intake
       */
      const selectedCohort = formattedCohorts.find(
        cohort => cohort.value === deletedCohortId
      );

      if (!selectedCohort) {
        return;
      }

      if (selectedCohort.isUsedForIntake) {
        intakes.map((intake, index) => {
          const newEligibility = intake.cohortEligibility.filter(
            eligibilityItem => eligibilityItem.cohortId !== selectedCohort.value
          );

          setValue(`intakes.${index}`, {
            ...intake,
            cohortEligibility: newEligibility,
          });
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCohortIds, formattedCohorts]);

  useEffect(() => {
    if (!isCohortsSuccess) {
      return;
    }

    handleSelectCohortChange();
  }, [selectedCohortIds, handleSelectCohortChange, isCohortsSuccess]);

  return (
    <section>
      <div className="two-col-form pt-0">
        <div>
          <h2 className="text-md font-medium leading-7 text-gray-900">
            Cohorts
          </h2>
        </div>
        <div className="col-span-2 space-y-8">
          <div className="sm:col-span-4">
            <MultiSelect
              name="selectedCohortIds"
              label="Cohorts to Include"
              helperText="Select which cohorts this school year will use. This will also affect intakes."
              options={formattedCohorts}
              isDisabled={disabled || isCohortsLoading}
            />

            <div className="mt-6 space-y-6">
              {cohorts.map((cohort, index) => (
                <div className="light-gray-container" key={cohort.cohortId}>
                  <p className="text-md mb-6 font-medium">
                    {cohort.cohortName}
                  </p>
                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                    <TextInput
                      name={`cohorts.${index}.startDate`}
                      type="date"
                      label="Date of Birth From*"
                      disabled={disabled}
                    />

                    <TextInput
                      name={`cohorts.${index}.endDate`}
                      type="date"
                      label="Date of Birth To*"
                      disabled={disabled}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { CohortsForm };
