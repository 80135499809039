import { SessionType } from '@admissions-support/types';
import { Fragment, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Select } from './form/common/select';
import { TextInput } from './form/common/text-input';

type SessionTypeItemProps = {
  index: number;
  onRemove: () => void;
  sessionTypes: SessionType[];
  disabled?: boolean;
};

function SessionTypeItem(props: SessionTypeItemProps) {
  const { index, onRemove, sessionTypes, disabled } = props;
  const name = `additionalSessionTypes.${index}`;
  const { fields } = useFieldArray({
    name,
  });
  const { watch } = useFormContext();

  const rules = watch(name);

  useEffect(() => {
    if (rules.length < 1) {
      onRemove();
    }
  }, [rules, onRemove]);

  const sessionTypeSelectValue = [
    { key: '', value: 'Select Session Type...' },
    ...sessionTypes.map(sessionType => ({
      key: sessionType.id,
      value: sessionType.name,
    })),
  ];

  return (
    <div className="light-gray-container">
      <div className="mb-6 flex items-center justify-between">
        <p className="text-md font-medium">Session Type</p>
        <button
          type="button"
          className="btn btn-error py-2"
          onClick={onRemove}
          disabled={disabled}
        >
          Remove
        </button>
      </div>

      <div className="space-y-6">
        {fields.map((field, index) => (
          <Fragment key={field.id}>
            {index > 0 ? (
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-b border-gray-200"></div>
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-gray-50 px-4 text-sm text-gray-600">
                    Or
                  </span>
                </div>
              </div>
            ) : null}
            <div className="group relative rounded-lg border border-gray-200 bg-gray-100 p-2">
              {/* <button
                onClick={() => remove(index)}
                disabled={disabled}
                className="absolute -top-2.5 right-3 hidden h-6 w-6 items-center justify-center rounded-full border-2 border-primary-700 bg-primary-600 hover:bg-primary-700 group-hover:flex"
              >
                <Trash01
                  aria-hidden="true"
                  className="h-3 w-3 text-white transition-all"
                  viewBox="0 0 24 24"
                />
                <span className="sr-only">Delete Session Type</span>
              </button> */}
              <div className="space-y-6">
                <Select
                  label="Session Type"
                  disabled={disabled}
                  name={`${name}.${index}.id`}
                  options={sessionTypeSelectValue.filter(sessionTypeRef => {
                    // if only one rule is in the OR statement, user can select any sessionType
                    if (rules.length === 1) {
                      return true;
                    }

                    // this is the first item on the OR,
                    // let select only sessionTypes which isnt selected in the second item
                    if (index === 0) {
                      return sessionTypeRef.key !== rules[1].id;
                    }

                    // this is the second item on the OR,
                    // let select only sessionTypes which isnt selected in the first item
                    if (index === 1) {
                      return sessionTypeRef.key !== rules[0].id;
                    }
                  })}
                />
                <TextInput
                  name={`${name}.${index}.allowance`}
                  type="number"
                  min={1}
                  label="Allowance"
                  disabled={disabled}
                  helperText="Number of sessions per week of this session type"
                />
              </div>
            </div>
          </Fragment>
        ))}
      </div>
      {/* We only allow maximum 2 additionalSession OR group */}
      {/* {fields.length === 2 ? null : (
        <button
          type="button"
          className="btn btn-secondary mt-4"
          disabled={disabled}
          onClick={() =>
            append({
              id: '',
              name: '',
              allowance: null,
            })
          }
        >
          Add Rule
        </button>
      )} */}
    </div>
  );
}

export { SessionTypeItem };
