import { MfaSetupRouterState } from '@/types/auth';
import { UpdateNon2FAAttributesDto } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Phone01 } from '@untitled-ui/icons-react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { TextInput } from './common/text-input';

interface SmsMfaFormProps {
  onSavePhoneNumber: (data: UpdateNon2FAAttributesDto) => Promise<void>;
}

const schema = yup
  .object({
    mobileNumber: yup
      .string()
      .matches(
        /^\+[0-9]+$/,
        'Phone number must start with a "+" sign and only contain digits'
      )
      .required('Phone number is required'),
  })
  .required();

function SmsMfaForm(props: SmsMfaFormProps) {
  const { onSavePhoneNumber } = props;
  const location = useLocation();
  const routeState = location.state as MfaSetupRouterState;
  const methods = useForm<UpdateNon2FAAttributesDto>({
    resolver: yupResolver(schema),
    defaultValues: {
      mobileNumber: routeState?.phoneNumber ? routeState.phoneNumber : '+44',
    },
    mode: 'onBlur',
  });

  const handleSavePhoneNumber = async (data: UpdateNon2FAAttributesDto) => {
    try {
      await onSavePhoneNumber(data);
    } catch {
      methods.setError('mobileNumber', {
        message: 'Something went wront. Try again later',
      });
    }
  };

  return (
    <div className="mt-8">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSavePhoneNumber)}>
          <TextInput
            name="mobileNumber"
            label="Mobile Number*"
            type="text"
            placeholder="+44 20 1234 5678"
            LeadingIcon={
              <Phone01 className="h-5 w-5 text-gray-500" viewBox="0 0 24 24" />
            }
            disabled={methods.formState.isSubmitting}
          />
          <button
            className="btn btn-primary btn-full mt-6"
            disabled={
              !methods.formState.isValid || methods.formState.isSubmitting
            }
          >
            Send Code
          </button>
        </form>
      </FormProvider>
    </div>
  );
}

export { SmsMfaForm };
