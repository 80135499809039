import {
  ForgottenPasswordForm,
  ForgottenPasswordFormDto,
} from '@/components/form/forgotten-password.form';
import { parentRouterPath, staffRouterPath } from '@/config/route-paths.config';
import { useAuth } from '@/context/auth.context';
import { isProd } from '@/utils/env-utils';
import { useOrganisationData } from '@/context/organisation-control.context';
import { isAdminDashboard } from '@/utils/is-dashboard';
import { ArrowNarrowLeft } from '@untitled-ui/icons-react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';

let toastId: string;

function ForgottenPassword() {
  const navigate = useNavigate();
  const { sendPasswordReset } = useAuth();
  const { data: organisation } = useOrganisationData();

  const handlePasswordResetRequest = async (data: ForgottenPasswordFormDto) => {
    toastId = toast.loading('Loading..');

    try {
      await sendPasswordReset({ ...data, organisationId: organisation.id });
    } catch {
      //silent error, dont expose wrong email
    }

    toast.success('Email has been sent successfully!', {
      id: toastId,
    });
    navigate(parentRouterPath.SIGN_IN);
  };

  return (
    <div className="mt-8 flex flex-col gap-y-7">
      <Link
        className="flex items-center gap-1"
        to={
          isAdminDashboard()
            ? staffRouterPath.SIGN_IN
            : parentRouterPath.SIGN_IN
        }
      >
        <ArrowNarrowLeft className="h-4 w-5 text-primary-700" />
        <p className="font-semibold text-gray-600">Back to Sign In</p>
      </Link>
      {!isAdminDashboard() && !isProd() ? (
        <div className="rounded-md border border-gray-200 bg-gray-25 px-6 py-3">
          <p className="pb-2 font-semibold text-gray-700">mygov.scot Users</p>
          <p className="text-gray-600">
            If you have forgotten your <strong>mygov.scot</strong> password you
            will need to reset using their service.
          </p>
        </div>
      ) : null}
      <div>
        <p className="mb-3 font-fmly text-4xl text-gray-900">Reset Password</p>
        <p className="text-gray-600">
          To reset your Admit password, please enter your account email below.
          If you have an account we will send you an email with reset
          instructions.
        </p>
      </div>
      <ForgottenPasswordForm
        onRequestPasswordReset={handlePasswordResetRequest}
      />
    </div>
  );
}

export { ForgottenPassword };
