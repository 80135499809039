import { reportService } from '@/services/report.service';
import { HttpError } from '@/types/error';
import {
  CreateReportTemplateDto,
  ReportTemplate,
} from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCreateReportTemplate(
  mutationOptions?: UseMutationOptions<
    ReportTemplate,
    HttpError,
    CreateReportTemplateDto
  >
) {
  return useMutation({
    mutationFn: reportService.createReportTemplate,
    ...mutationOptions,
  });
}

export { useCreateReportTemplate };
