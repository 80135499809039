import { FallbackComponent } from '@/components/fallback-component';
import { LoadingScreen } from '@/components/loading-screen';
import { parentRouterPath } from '@/config/route-paths.config';
import { useTerms } from '@/hooks/query-hooks/use-terms';
import { ArrowRight, FaceId, Home02 } from '@untitled-ui/icons-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Welcome() {
  const [schoolYearId, setSchoolYearId] = useState<string>('');
  const {
    data: schoolYears,
    isLoading: isTermsLoading,
    isError,
    isSuccess,
  } = useTerms();
  const navigate = useNavigate();

  if (isTermsLoading) {
    return <LoadingScreen />;
  }

  if (!isSuccess || isError) {
    return <FallbackComponent />;
  }

  const handleContinue = () => {
    navigate(
      `${parentRouterPath.APPLICATION_CREATE_CHILD}?schoolYear=${schoolYearId}`
    );
  };

  return (
    <>
      <h1 className="mb-8 mt-6 text-xl font-semibold text-gray-900">
        Early Learning & Childcare
      </h1>
      <p className="text-md mb-2 mt-6 font-semibold text-gray-900">
        Select Starting Date
      </p>
      <p>
        Determine the first date the applicant can begin their placement, and
        select the starting intake that suits.
      </p>

      <fieldset className="mt-6">
        <label className="label" htmlFor="school-year">
          School Year
        </label>
        <select
          id="school-year"
          className="input shadow-none"
          onChange={e => setSchoolYearId(e.target.value)}
          value={schoolYearId}
        >
          <option>Choose School Year...</option>{' '}
          {schoolYears.map(term => (
            <option key={term.id} value={term.id}>
              {term.name}
            </option>
          ))}
        </select>
      </fieldset>

      <p className="text-md mb-2 mt-6 font-semibold text-gray-900">
        Provide Child Details
      </p>
      <p>
        Add a child to your account. These details are used to uniquely identify
        your child and their applications.
      </p>
      <p className="text-md mb-2 mt-6 font-semibold text-gray-900">
        Complete Application Form
      </p>
      <p>
        You will be asked to provide details about your child’s household,
        siblings, parents/carers and your placement preferences.
      </p>
      <p className="text-md mb-2 mt-6 font-semibold text-gray-900">
        Updates and Outcomes
      </p>
      <p>
        You will be able to make changes to your application up until the
        application deadline. Once this passes, any changes will need to be
        approved.
      </p>
      <p className="mt-6">
        You will be notified of any decisions related to your application via
        email, and this dashboard.
      </p>

      <div className="light-gray-container mt-8">
        <p className="text-md mb-2 font-semibold text-gray-900">
          During the application process, the following information will be
          required.
        </p>

        <div className="mt-6 flex gap-x-4">
          <div className="self-start rounded-lg border border-gray-200 p-2">
            <FaceId />
          </div>
          <div>
            <p className="text-md mb-2 font-semibold text-gray-900">
              Child’s Passport or Birth Certificate
            </p>

            <p>
              You will need to upload a copy of either document and provide the
              identification number relevant to that document.
            </p>
          </div>
        </div>

        <div className="mt-6 flex gap-x-4">
          <div className="self-start rounded-lg border border-gray-200 p-2">
            <Home02 />
          </div>
          <div>
            <p className="text-md mb-2 font-semibold text-gray-900">
              Proof of Address
            </p>
            <p>
              You will need to provide evidence that you live that the address
              provided. If you are moving to a new address, you will need to
              provide an accepted mortgage offer or concluded missives.
            </p>
          </div>
        </div>
      </div>

      <button
        disabled={!schoolYearId}
        className="btn btn-primary ml-auto mt-8 flex items-center"
        onClick={handleContinue}
      >
        Continue
        <ArrowRight viewBox="0 0 24 24" className="ml-2 h-5 w-5 text-white" />
      </button>
    </>
  );
}

export { Welcome };
