import { productKey } from '@/config/query-keys';
import { productService } from '@/services/product.service';
import { HttpError } from '@/types/error';
import {
  UpdateProductDto,
  UpdateProductResponseType,
} from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateProductMutation(
  mutationOptions?: UseMutationOptions<
    UpdateProductResponseType,
    HttpError,
    { productId: string; data: UpdateProductDto }
  >
) {
  return useMutation({
    mutationKey: productKey.update,
    mutationFn: productService.updateProduct,
    ...mutationOptions,
  });
}

export { useUpdateProductMutation };
