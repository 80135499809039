import { staffRouterPath } from '@/config/route-paths.config';
import { NavLink, generatePath, useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

function PurchaseExtraHoursNavigation() {
  const { locationId = '' } = useParams<string>();
  return (
    <div>
      <NavLink
        to={generatePath(
          staffRouterPath.LOCATION_PURCHASE_EXTRA_HOURS_CALENDAR,
          { locationId }
        )}
        className={({ isActive }) =>
          twMerge(
            'btn rounded-md px-3 py-2 shadow-none',
            isActive ? 'bg-primary-50 text-primary-700' : 'text-gray-500'
          )
        }
      >
        Calendar
      </NavLink>
      <NavLink
        to={generatePath(
          staffRouterPath.LOCATION_PURCHASE_EXTRA_HOURS_PRODUCTS,
          { locationId }
        )}
        className={({ isActive }) =>
          twMerge(
            'btn rounded-md px-3 py-2 shadow-none',
            isActive ? 'bg-primary-50 text-primary-700' : 'text-gray-500'
          )
        }
      >
        Products
      </NavLink>
      <NavLink
        to={generatePath(
          staffRouterPath.LOCATION_PURCHASE_EXTRA_HOURS_AVAILABILITY,
          { locationId }
        )}
        className={({ isActive }) =>
          twMerge(
            'btn rounded-md px-3 py-2 shadow-none',
            isActive ? 'bg-primary-50 text-primary-700' : 'text-gray-500'
          )
        }
      >
        Availability
      </NavLink>
    </div>
  );
}

export { PurchaseExtraHoursNavigation };
