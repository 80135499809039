import { Textarea } from '@/components/form/common/textarea';
import { activityLogKey } from '@/config/query-keys';
import { useCreateActivityLogMutation } from '@/hooks/create-hooks/use-create-activity-log-mutation';
import { queryClient } from '@/libs/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

const schema = yup.object({
  notes: yup.string().required().label('Notes'),
});

type ActivityLogNoteFormProps = {
  childId: string;
};

function ActivityLogNoteForm(props: ActivityLogNoteFormProps) {
  const { childId } = props;

  const { mutateAsync: createActivityLog } = useCreateActivityLogMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: activityLogKey.byChildId(childId),
      });
    },
  });

  const form = useForm({
    resolver: yupResolver(schema),
  });

  const submitHandler = async (data: { notes: string }) => {
    await createActivityLog({ childId, note: data.notes });

    form.reset();
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(submitHandler)}
        className="border-y py-6"
      >
        <p className="pb-6 text-lg text-gray-900">Add note</p>
        <Textarea
          name="notes"
          label="Notes"
          placeholder="Enter a note..."
          helperText="Add any notes relating to this child."
          rows={5}
        />
        <button className="btn btn-secondary mt-6">Save Note</button>
      </form>
    </FormProvider>
  );
}

export { ActivityLogNoteForm };
