import { axiosClient } from '@/libs/axios';
import { HttpError } from '@/types/error';
import {
  CreateProductDto,
  CustomPaginateResult,
  FetchAllProductsByLocationAndSchoolYearDto,
  Product,
  ProductListing,
  UpdateProductDto,
  UpdateProductResponseType,
} from '@admissions-support/types';
import { AxiosResponse } from 'axios';
import { compact } from 'lodash-es';

const getProductsByLocationId = async (
  locationId: string,
  query: FetchAllProductsByLocationAndSchoolYearDto
): Promise<CustomPaginateResult<ProductListing>> => {
  const queryParams = compact([
    `schoolYearId=${query.schoolYearId}`,
    query.page ? `page=${query.page}` : null,
    query.limit ? `limit=${query.limit}` : null,
  ]);

  const response = await axiosClient.get<
    HttpError,
    AxiosResponse<CustomPaginateResult<ProductListing>>
  >(`/products/locations/${locationId}?${queryParams.join('&')}`);

  return response.data;
};

const createProduct = async (
  data: CreateProductDto
): Promise<{ created: number }> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<{ created: number }>,
    CreateProductDto
  >('/products', data);

  return response.data;
};

const updateProduct = async ({
  productId,
  data,
}: {
  productId: string;
  data: UpdateProductDto;
}): Promise<UpdateProductResponseType> => {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<UpdateProductResponseType>,
    UpdateProductDto
  >(`/products/${productId}`, data);

  return response.data;
};

const getProductById = async (productId: string): Promise<Product> => {
  const response = await axiosClient.get<HttpError, AxiosResponse<Product>>(
    `/products/${productId}`
  );

  return response.data;
};

const deleteProduct = async (id: string) => {
  await axiosClient.delete<HttpError>(`/products/${id}`);
};

export const productService = Object.freeze({
  getProductsByLocationId,
  createProduct,
  updateProduct,
  getProductById,
  deleteProduct,
});
