import { LoginDto, LoginForm } from '@/components/form/login.form';
import { LoadingScreen } from '@/components/loading-screen';
import { LoginTitle } from '@/components/login-title';
import { useAuth } from '@/context/auth.context';
import { usePublicOrganisation } from '@/hooks/query-hooks/use-public-organisation';
import { AuthError } from 'aws-amplify/auth';
import { Helmet } from 'react-helmet-async';
import { Navigate, useNavigate } from 'react-router-dom';

function SignIn() {
  const navigate = useNavigate();
  const { isLoading, data: organisation, isError } = usePublicOrganisation();
  const { login } = useAuth();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!organisation || isError) {
    return <Navigate to="/404" />;
  }

  const handleLogin = async (data: LoginDto) => {
    const result = await login({
      organisationId: organisation.id,
      password: data.password,
      email: data.email.trim(),
      rememberDevice: data.rememberDevice,
    });

    switch (result.nextStep.signInStep) {
      /*
       * User has 2FA setup, so needs to complete SMS or TOTP challenge
       */
      case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
        navigate('/auth/verify', {
          state: {
            type: 'TOTP',
          },
        });
        break;

      case 'CONFIRM_SIGN_UP':
        throw new AuthError({
          message:
            'You need to confirm your email address before you can sign in.',
          name: 'CONFIRM_SIGN_UP',
        });
      case 'CONFIRM_SIGN_IN_WITH_SMS_CODE':
        navigate('/auth/verify', {
          state: {
            type: 'SMS',
            codeDeliveryDetails: result.nextStep.codeDeliveryDetails,
          },
        });
        break;
      /*
       * User needs to set-up 2FA
       */
      case 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION':
        navigate('/auth/mfa-setup');
        break;
    }
  };

  return (
    <>
      <Helmet>
        <title>Sign in</title>
      </Helmet>

      <div>
        <LoginTitle
          title="Login"
          description="Welcome back! Please enter your details."
        />
        <LoginForm onLogin={handleLogin} />
      </div>
    </>
  );
}

export { SignIn };
