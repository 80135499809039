import { OverviewForm } from '@/components/settings/application-templates/elements/overview-form';
import { useApplicationTemplateOverview } from '@/components/settings/application-templates/use-application-template-overview.form';
import { applicationTemplateKey } from '@/config/query-keys';
import { useUpdateApplicationTemplateMutation } from '@/hooks/update-hooks/use-update-application-template';
import { queryClient } from '@/libs/react-query';
import { ApplicationTemplate, LocationType } from '@admissions-support/types';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ApplicationOverviewFormData } from './application-template-overview.type';
import { CriteriaForm } from './elements/criteria-form';

type UpdateOverviewFormProps = {
  initialData: ApplicationTemplate;
};

async function invalidateApplicationTemplatesQuery() {
  await queryClient.invalidateQueries({
    queryKey: applicationTemplateKey.list,
    refetchType: 'active',
  });
}

function UpdateOverviewForm({ initialData }: UpdateOverviewFormProps) {
  const { form, reset } = useApplicationTemplateOverview({ initialData });
  const params = useParams<{ id?: string }>();

  const {
    mutateAsync: update,
    isSuccess: isUpdated,
    data: updatedTemplate,
  } = useUpdateApplicationTemplateMutation();

  useEffect(() => {
    if (!isUpdated) {
      return;
    }

    reset(updatedTemplate);
    invalidateApplicationTemplatesQuery();
  }, [isUpdated, updatedTemplate, reset]);

  const handleSubmit = async (data: ApplicationOverviewFormData) => {
    const formattedCohorts = Array.isArray(data.cohorts)
      ? data.cohorts.map(co => ({ id: co }))
      : [];

    await update({
      id: params.id || '',
      data: {
        name: data.name,
        description: data.description || undefined,
        cohorts: formattedCohorts,
        applicationType: data.applicationType as LocationType,
        criteria: data.enableAdditionalCriteria ? data.criteria : [],
        criteriaRequirement: data.enableAdditionalCriteria
          ? data.criteriaRequirement
          : null,
      },
    });

    await invalidateApplicationTemplatesQuery();
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        id="update-overview-form"
      >
        <OverviewForm />
        <CriteriaForm />
      </form>
    </FormProvider>
  );
}

export { invalidateApplicationTemplatesQuery, UpdateOverviewForm };
