import { useOrganisationData } from '@/context/organisation-control.context';
import * as React from 'react';
import { Outlet } from 'react-router-dom';

export type RouteWrapperProps = {
  guard?: React.FC | null;
  layout?: React.FC | null;
};

function RouteWrapper({ guard, layout }: RouteWrapperProps) {
  const Guard = guard ?? React.Fragment;
  const Layout = layout ?? React.Fragment;

  const { data: organisation } = useOrganisationData();

  if (organisation.icon) {
    const icons: NodeListOf<HTMLLinkElement> = document.querySelectorAll(
      'link[id^="org-icon"]'
    );
    const meta = document.getElementById('org-icon270') as HTMLMetaElement;

    const iconLink = `https://${import.meta.env.VITE_BUCKET}.s3.${
      import.meta.env.VITE_AWS_REGION
    }.amazonaws.com/${organisation.icon.key}`;

    icons.forEach(icon => {
      icon.href = iconLink;
    });
    if (meta) {
      meta.content = iconLink;
    }
  }

  return (
    <Guard>
      <Layout>
        <Outlet />
      </Layout>
    </Guard>
  );
}

export { RouteWrapper };
