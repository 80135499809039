import { Disclosure as HeadlessUIDisclosure } from '@headlessui/react';
import { MinusCircle, PlusCircle } from '@untitled-ui/icons-react';
import { uniqueId } from 'lodash-es';
import { get, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { RadioBoxBase, RadioBoxOption } from '../../form/common/radio-box';
import { dataSources } from '../reporting.const';
import { QueryBuilderFormData } from '../reporting.type';
import { DataSourceOption } from './data-source-option';
import { useCanUserEditTemplate } from './use-can-user-edit-template';

const dataSourceOptions: RadioBoxOption[] = dataSources.map(item => ({
  value: item.name,
  Content: props => (
    <DataSourceOption
      {...props}
      name={item.name}
      description={item.description}
      subtitle={item.subtitle}
    />
  ),
}));

function DataSource() {
  const { setValue, watch, formState } = useFormContext<QueryBuilderFormData>();

  const dataSource = watch('dataSource');

  const error = get(formState.errors, 'dataSource');
  const hasError = !!error;

  const handleOnChange = (newValue: string) => {
    setValue('dataSource', newValue, { shouldValidate: true });
    setValue('conditionGroups', [
      {
        id: uniqueId('condition-group-'),
        variant: 'and',
        data: [{ id: uniqueId('condition-'), type: '', value: '' }],
      },
    ]);
  };

  const canUserEditTemplate = useCanUserEditTemplate();

  return (
    <HeadlessUIDisclosure
      as="div"
      className={twMerge(
        'rounded-lg border p-4',
        hasError ? 'border-red-500' : 'border-gray-200'
      )}
    >
      {({ open }) => (
        <>
          <HeadlessUIDisclosure.Button className="flex w-full justify-between py-1 text-lg font-medium text-gray-900">
            Data Source
            {!open && (
              <PlusCircle aria-hidden="true" className="block text-gray-400" />
            )}
            {open && (
              <MinusCircle aria-hidden="true" className="block text-gray-400" />
            )}
          </HeadlessUIDisclosure.Button>
          {!open && dataSource && (
            <div className="pt-3">
              <RadioBoxBase
                disabled={formState.isSubmitting || !canUserEditTemplate}
                value={dataSource}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onChange={() => {}}
                containerClassName="space-y-2"
                optionContainerClassName="!cursor-default"
                options={dataSourceOptions.filter(
                  item => item.value === dataSource
                )}
              />
            </div>
          )}

          <HeadlessUIDisclosure.Panel className="pt-3">
            <RadioBoxBase
              value={dataSource}
              disabled={formState.isSubmitting || !canUserEditTemplate}
              onChange={handleOnChange}
              containerClassName="space-y-2"
              options={dataSourceOptions}
            />
          </HeadlessUIDisclosure.Panel>
          {error && (
            <p className="mt-1.5 text-sm text-red-600">
              {error?.message?.toString()}
            </p>
          )}
        </>
      )}
    </HeadlessUIDisclosure>
  );
}

export { DataSource };
