import { Attachment01 } from '@untitled-ui/icons-react';
import { Tag } from '../tag';
import { Attachment } from '@admissions-support/types';

type AttachmentComponentProps = Attachment & {
  fileKey: string;
  handleDownload: (key: string) => void;
};

function MessageAttachment({
  filename,
  fileKey,
  handleDownload,
}: AttachmentComponentProps) {
  return (
    <button
      onClick={() => handleDownload(fileKey)}
      className="flex cursor-pointer items-center gap-2 bg-white"
    >
      <Tag type="default" className="flex items-center gap-1 bg-white">
        <Attachment01 height={12} width={12} />
        {filename}
      </Tag>
    </button>
  );
}

export { MessageAttachment };
