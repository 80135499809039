import chroma from 'chroma-js';
import * as yup from 'yup';

const adminContactSchema = yup.object({
  firstName: yup.string().required().min(2).label('First Name'),
  lastName: yup.string().required().min(2).label('Last Name'),
  position: yup.string().required().min(2).label('Position'),
  email: yup.string().required().email().label('Email'),
  phone: yup.string().nullable().label('Phone Number'),
});

const upsertOrganisationDetailsFormSchema = yup.object({
  name: yup.string().required().min(1).label('Organisation Name'),
  localAuthority: yup.string().label('Local Authority'),
  contactEmail: yup.string().email().required().label('Contact Email'),
  website: yup.string().url().label('Website'),
  brandColour: yup
    .string()
    .test('is-hex', 'Brand Colour must be a valid colour code', value => {
      return chroma.valid(value);
    })
    .label('Brand Colour'),
  isCouncilSplitPermitted: yup.boolean().required(),
  logo: yup
    .array(
      yup.object({
        filename: yup.string().required(),
        reference: yup.string().required(),
        key: yup.string().required(),
      })
    )
    .required()
    .max(1, 'Logo can only have 1 file'),
  icon: yup
    .array(
      yup.object({
        filename: yup.string().required(),
        reference: yup.string().required(),
        key: yup.string().required(),
      })
    )
    .required()
    .max(1, 'Icon can only have 1 file'),
  adminContacts: yup.array(adminContactSchema),
  additionalContacts: yup.array(adminContactSchema),
});

export { upsertOrganisationDetailsFormSchema };
