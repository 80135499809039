import {
  SearchParentDto,
  SearchParentResponse,
} from '@admissions-support/types';
import { axiosClient } from '@/libs/axios';
import { HttpError } from '@/types/error';
import { AxiosResponse } from 'axios';

const searchByNameAndEmail = async (
  query: SearchParentDto
): Promise<SearchParentResponse> => {
  const response = await axiosClient.get<
    HttpError,
    AxiosResponse<SearchParentResponse>
  >(`/parents/search?search=${query.search}`);

  return response.data;
};

export const parentService = Object.freeze({
  searchByNameAndEmail,
});
