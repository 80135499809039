import { messagingKey } from '@/config/query-keys';
import { messagingService } from '@/services/messaging.service';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type UseMarkMessageSeenProps = {
  id: string;
  isParent?: boolean;
};

function useMarkMessageSeen({ id, isParent = true }: UseMarkMessageSeenProps) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: messagingService.markMessageSeen,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: messagingKey.single(id) });
      queryClient.invalidateQueries({
        queryKey: messagingKey.list({}, isParent),
      });
      queryClient.invalidateQueries({
        queryKey: messagingKey.hasUnreadMessages,
      });
    },
  });
}

export { useMarkMessageSeen };
