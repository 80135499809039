import { Tag } from '@/components/tag';
import { staffRouterPath } from '@/config/route-paths.config';
import { useCapacitySimulation } from '@/context/capacity-simulation.context';
import {
  CapacityTableActionsCellProps,
  CapacityTableLocationCellProps,
  HeaderCellProps,
} from '@/types/capacity-simulation';
import { getCapacityOverride } from '@/utils/capacity-simulation-utils';
import { generateClassByCapacity } from '@/utils/location-utils';
import { FlatResource, FlatResourceCapacity } from '@admissions-support/types';
import { ChevronDown, ChevronRight } from '@untitled-ui/icons-react';
import { useEffect, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { UnpublishedChangeTag } from './unpublished-change-tag';

function CapacityTableLocationCell({
  locationId,
  locationName,
  areaNames,
}: CapacityTableLocationCellProps) {
  const url = generatePath(staffRouterPath.OVERVIEW_LOCATION, {
    locationId,
  });

  return (
    <div className="flex flex-col gap-1">
      <Link
        to={url}
        className="font-medium text-gray-900 hover:text-primary-600"
      >
        <p className="mb-0.5 cursor-pointer text-sm">{locationName}</p>
      </Link>
      <div className="flex gap-1">
        {areaNames.map(areaName => (
          <Tag key={areaName} type="default">
            {areaName}
          </Tag>
        ))}
        <UnpublishedChangeTag locationId={locationId} />
      </div>
    </div>
  );
}

function CapacityTableHeaderCell({
  text,
  isGroupHeader = false,
}: HeaderCellProps) {
  return (
    <span
      className={twMerge(
        'text-xs text-gray-600',
        isGroupHeader && 'text-gray-500'
      )}
    >
      {text}
    </span>
  );
}

function CapacityTableValueCell({ text }: { text: string }) {
  return <span className="font-mono text-sm text-gray-600">{text || '-'}</span>;
}

function CapacityTableActionsCell({
  isExpanded,
  expandRow,
}: CapacityTableActionsCellProps) {
  const height = 20;
  const width = 20;

  const handleExpand = () => {
    expandRow();
  };

  return (
    <button
      className="btn btn-sm flex items-center gap-2 shadow-none"
      onClick={handleExpand}
    >
      {isExpanded ? (
        <ChevronDown height={height} width={width} />
      ) : (
        <ChevronRight height={height} width={width} />
      )}
    </button>
  );
}

function CapacityResourcingTableCell({
  resource,
  day,
  locationId,
}: {
  resource: FlatResource;
  day: keyof FlatResourceCapacity;
  locationId: string;
}) {
  const { setSimulatedCapacity, simulatedCapacity } = useCapacitySimulation();
  const initialCapacity = resource.capacity[day]?.capacity;
  const overrideCapacity = getCapacityOverride({
    locationId,
    day,
    sessionTypeId: resource.sessionTypeIds[0].toString(),
    simulatedCapacity,
  });

  const [capacity, setCapacity] = useState<number | undefined>(
    overrideCapacity !== undefined ? overrideCapacity : initialCapacity
  );
  // These are most likely be numbers, but we want to display "-" if they are not numbers
  const allocated = resource.capacity[day]?.allocated;
  const hasDataForDay =
    allocated !== undefined && initialCapacity !== undefined;
  const allocatedValue = Number.isNaN(allocated) ? '-' : allocated;
  const capacityValue = Number.isNaN(capacity) ? '-' : capacity;

  // If we reset the changes, we want to reset the capacity to the initial capacity
  useEffect(() => {
    if (overrideCapacity === undefined) {
      setCapacity(initialCapacity);
    }
  }, [overrideCapacity, initialCapacity]);

  const handleSubmitCapacity = () => {
    if (capacity === initialCapacity) {
      return;
    }

    setSimulatedCapacity({
      locationId,
      day,
      // It's enought to pass only the first session type id,
      // because the backend will fill up all the other session types in the same group
      sessionTypeId: resource.sessionTypeIds[0].toString(),
      // This is not going to be 0, because if capacity is 0 we dont show the input
      capacity: capacity || 0,
    });
  };

  const tooltipValue = !hasDataForDay
    ? ''
    : `${allocatedValue} Allocated of ${capacityValue}`;

  if (!hasDataForDay) {
    return <>-</>;
  }

  return (
    <div>
      <div
        className="inline-flex items-center gap-2"
        data-tooltip-content={tooltipValue}
        data-tooltip-id="capacity-resourcing-tooltip"
      >
        <Tag
          type="default"
          className={twMerge(
            generateClassByCapacity(capacity || 0, allocated || 0),
            'h-[24px] min-w-10 p-0 text-center font-mono text-sm'
          )}
        >
          {allocatedValue}
        </Tag>
        <span className="text-sm font-medium text-gray-900">/</span>
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
          onBlur={handleSubmitCapacity}
        >
          <input
            name="capacity"
            type="number"
            min={0}
            value={capacity}
            onChange={e => setCapacity(Number(e.target.value))}
            className="inline-flex w-10 cursor-pointer items-center justify-center whitespace-nowrap rounded-md border border-gray-200 bg-gray-50 px-2 py-1 text-center text-xs font-medium text-gray-700"
          />
        </form>
      </div>
    </div>
  );
}

type CapacityTableRangeCellProps = {
  min: number;
  max: number;
};

function AvailabilityRangeCell({ min, max }: CapacityTableRangeCellProps) {
  const colorClasses = twMerge(
    min === 0 && 'border-green-200 bg-green-50 text-green-700',
    min < 0 && 'border-red-200 bg-red-50 text-red-700',
    min >= 0 && 'border-yellow-200 bg-yellow-50 text-yellow-700'
  );

  if (!min && !max) {
    // Need to return it as a fragment, because just a  - wouldn't be a valid JSX element
    return <>-</>;
  }

  if (min === max) {
    return (
      <Tag
        type="default"
        className={twMerge('h-[24px] min-w-10 font-mono text-sm', colorClasses)}
      >
        {min}
      </Tag>
    );
  }

  return (
    <Tag
      type="default"
      className={twMerge('h-[24px] min-w-10 font-mono text-sm', colorClasses)}
    >
      {min}-{max}
    </Tag>
  );
}

function CapacityRangeCell({ min, max }: CapacityTableRangeCellProps) {
  let text = '-';
  const isMinMaxDefined = !isNaN(min) && !isNaN(max);

  if (isMinMaxDefined && max - min === 0) {
    text = max.toString();
  } else if (isMinMaxDefined) {
    text = `${min}-${max}`;
  }

  return <span className="font-mono text-sm text-gray-600">{text}</span>;
}

export {
  AvailabilityRangeCell,
  CapacityRangeCell,
  CapacityResourcingTableCell,
  CapacityTableActionsCell,
  CapacityTableHeaderCell,
  CapacityTableLocationCell,
  CapacityTableValueCell,
};
