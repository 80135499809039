import { HttpError, isBadRequest } from '@/types/error';
import { getNestedKeys } from '@/utils/get-nested-keys';
import { ReportTemplate } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { uniqueId } from 'lodash-es';
import { useForm } from 'react-hook-form';
import { queryBuilderSchema } from './reporting.schema';
import {
  ConditionGroup,
  ConditionType,
  QueryBuilderFormData,
} from './reporting.type';

type UseQueryBuilderForm = {
  onSubmit: (data: QueryBuilderFormData) => Promise<ReportTemplate | void>;
  initialData?: ReportTemplate;
};

function transformQueryBuilderApiDataToFormData(initialData?: ReportTemplate): {
  conditionGroups: ConditionGroup[];
  dataSource: string;
  name: string;
  visibility: boolean;
} {
  if (!initialData) {
    return {
      dataSource: '',
      name: '',
      visibility: false,
      conditionGroups: [
        {
          id: uniqueId('condition-group-'),
          variant: 'and',
          data: [{ id: uniqueId('condition-'), type: '', value: '' }],
        },
      ],
    };
  }

  return {
    dataSource: initialData.dataSource,
    name: initialData.name,
    visibility: initialData.visibility === 'ORGANISATION',
    conditionGroups: initialData.conditions.map(group => ({
      id: uniqueId('condition-group-'),
      variant: group.variant,
      data: group.data.map(condition => ({
        id: uniqueId('condition-'),
        type: condition.type as ConditionType,
        value: condition.value,
      })),
    })),
  };
}

function useQueryBuilderForm(props: UseQueryBuilderForm) {
  const { onSubmit, initialData } = props;
  const form = useForm({
    resolver: yupResolver(queryBuilderSchema),
    defaultValues: transformQueryBuilderApiDataToFormData(initialData),
  });

  const handleSubmit = async (data: QueryBuilderFormData) => {
    try {
      const response = await onSubmit(data);

      return response;
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        const availableFields = getNestedKeys(form.getValues());

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }

      form.setError('root.server', {
        message: Array.isArray(httpError.message)
          ? httpError.message[0]
          : httpError.message,
      });
    }
  };

  return { form, handleSubmit };
}

export { transformQueryBuilderApiDataToFormData, useQueryBuilderForm };
