import { Alert } from '@/components/alert';
import { Box } from '@/components/box';
import { FallbackComponent } from '@/components/fallback-component';
import { SelectBase } from '@/components/form/common/select';
import { LoadingScreen } from '@/components/loading-screen';
import { NewBookingForm } from '@/components/parent/purchase-extra-hours/new-booking.form';
import { parentRouterPath } from '@/config/route-paths.config';
import { useChildBookingsInfo } from '@/hooks/query-hooks/use-child-bookings-info';
import { useTerms } from '@/hooks/query-hooks/use-terms';
import { useChildBookingMeta } from '@/hooks/use-child-booking-meta';
import { isFuture } from 'date-fns';
import {
  Link,
  Navigate,
  generatePath,
  useParams,
  useSearchParams,
} from 'react-router-dom';

function NewBooking() {
  const params = useParams<{ id: string }>();
  const childId = params.id || '';

  const [searchParams, setSearchParams] = useSearchParams();

  const currentSchoolYearId = searchParams.get('termId');

  const {
    data: schoolYears,
    isSuccess: isSchoolYearsSuccess,
    isLoading: isSchoolYearsLoading,
    isError: isSchoolYearsError,
  } = useTerms();

  const {
    data: bookingMeta,
    isSuccess: isBookingMetaSuccess,
    isLoading: isBookingMetaLoading,
    isError: isBookingMetaError,
  } = useChildBookingsInfo(childId);

  const {
    data: childMeta,
    isSuccess: isChildMetaSuccess,
    isLoading: isChildMetaLoading,
    isError: isChildMetaError,
  } = useChildBookingMeta(childId, currentSchoolYearId || '', {
    enabled: Boolean(schoolYears),
  });

  const isBookingDisabled =
    isBookingMetaSuccess && !bookingMeta.isBookingEnabled;

  if (isBookingMetaLoading || isSchoolYearsLoading || isChildMetaLoading) {
    return (
      <Box>
        <div className="flex items-center justify-between">
          <p className="mb-6 flex items-center text-lg font-semibold">
            Lookup Availability
          </p>
          <Link
            to={generatePath(parentRouterPath.CHILD_PURCHASE_EXTRA_HOURS, {
              id: childId,
            })}
          >
            Back to Purchase Extra Hours
          </Link>
        </div>

        <LoadingScreen />
      </Box>
    );
  }

  if (
    isBookingMetaError ||
    !isBookingMetaSuccess ||
    isSchoolYearsError ||
    !isSchoolYearsSuccess
  ) {
    return (
      <Box>
        <FallbackComponent />
      </Box>
    );
  }

  if (isBookingDisabled) {
    return (
      <Navigate
        to={generatePath(parentRouterPath.CHILD_OVERVIEW, { id: childId })}
      />
    );
  }

  const schoolYearsSelectValues = schoolYears
    .filter(schoolYear => isFuture(new Date(schoolYear.endDate)))
    .map(schoolYear => ({
      value: schoolYear.name,
      key: schoolYear.id,
    }));

  return (
    <Box>
      <div className="flex items-center justify-between">
        <p className="mb-6 flex items-center text-lg font-semibold">
          Lookup Availability
        </p>
        <Link
          to={generatePath(parentRouterPath.CHILD_PURCHASE_EXTRA_HOURS, {
            id: childId,
          })}
        >
          Back to Purchase Extra Hours
        </Link>
      </div>
      <Alert
        type="warning"
        title="Availability"
        text="Please be aware that the availability of extra hours displayed during the booking process is subject to change as other parents/carers make bookings. If you find that your selected sessions are no longer available, we recommend refining your search to check the most up-to-date availability."
      />
      <SelectBase
        name="schoolYear"
        options={schoolYearsSelectValues}
        value={currentSchoolYearId || schoolYears[0].id}
        label="School Year"
        onChange={e => {
          setSearchParams(prevSearchParams => {
            prevSearchParams.set('termId', e.target.value);
            return prevSearchParams;
          });
        }}
        className="mb-5 mt-6"
        disabled={schoolYearsSelectValues.length < 2}
      />
      {isChildMetaError || !isChildMetaSuccess ? (
        <Alert
          type="warning"
          text="This child has no application submitted in this school year or the
          application has not processed yet!"
        />
      ) : (
        <NewBookingForm
          childId={childId}
          attendedLocations={childMeta.attendedLocations}
          attendedDays={childMeta.attendedDays}
          startDate={childMeta.eligibilityStartDate}
          endDate={childMeta.currentSchoolYear.endDate}
        />
      )}
    </Box>
  );
}

export { NewBooking };
