import { Box } from '@/components/box';
import { staffRouterPath } from '@/config/route-paths.config';
import { useLeaveModal } from '@/hooks/use-leave-modal';
import { Application, UpdateApplicationDto } from '@admissions-support/types';
import { ArrowLeft, Send01 } from '@untitled-ui/icons-react';
import { FormProvider } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { AdditionalInfoElement } from './additional-info.element';
import { useAdditionalInfoForm } from './use-additional-info.form';

type AdditionalInfoFormProps = {
  onSubmit: (data: UpdateApplicationDto) => any;
  initialData: Application;
  isMutating?: boolean;
};

function ParentAdditionalInfoForm(props: AdditionalInfoFormProps) {
  const { initialData, onSubmit } = props;
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const { form, handleSubmit, saveAsDraft } = useAdditionalInfoForm({
    initialData,
    onSubmit,
  });

  useLeaveModal({
    show: form.formState.isDirty && !!initialData,
  });

  const formDisabled = form.formState.isSubmitting;

  return (
    <FormProvider {...form}>
      <form onSubmit={saveAsDraft} id="upsert-application-form">
        <Box className="space-y-4">
          <AdditionalInfoElement disabled={formDisabled} />
        </Box>
      </form>

      <div className="mt-6 flex flex-col space-y-4 sm:flex-row sm:justify-between sm:space-y-0">
        <button
          className="btn btn-secondary flex items-center"
          type="button"
          onClick={() =>
            navigate(
              generatePath(staffRouterPath.APPLICATION_PLACEMENT_PREFERENCES, {
                id: params.id || '',
              })
            )
          }
          disabled={formDisabled}
        >
          <ArrowLeft
            viewBox="0 0 24 24"
            className="mr-2 h-5 w-5 text-gray-500"
          />
          Previous
        </button>
        {initialData.status === 'DRAFT' && (
          <button
            className="btn btn-primary flex items-center"
            type="button"
            onClick={handleSubmit}
            disabled={formDisabled}
          >
            Submit Form <Send01 viewBox="0 0 24 24" className="ml-2 h-5 w-5" />
          </button>
        )}
      </div>
    </FormProvider>
  );
}

export { ParentAdditionalInfoForm };
