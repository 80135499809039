import {
  SessionDayDetails,
  SessionTiming,
  SessionType,
  SessionTypeExtendedReference,
  OperatingDaySessionDto,
} from '@admissions-support/types';
import { format, isValid, parseISO } from 'date-fns';

const days: (keyof OperatingDaySessionDto)[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

function getOperatingDaysOfSessionType(
  sessionType?: SessionType | SessionTypeExtendedReference
): (keyof OperatingDaySessionDto)[] {
  if (!sessionType) {
    return [];
  }

  return (
    Object.keys(sessionType.operatingDays) as (keyof OperatingDaySessionDto)[]
  ).filter(day => sessionType.operatingDays[day]);
}

function areAllDaysAreEqual(sessionTiming: SessionTiming) {
  let firstNonNullDay: SessionDayDetails | null = null;

  let areSame = true;
  days.forEach(dayName => {
    const day = sessionTiming[dayName as keyof SessionTiming];
    if (day === null) {
      return;
    }

    if (day === undefined) {
      return;
    }

    if (firstNonNullDay === null) {
      firstNonNullDay = day;
      return;
    }

    if (
      day.start !== firstNonNullDay.start ||
      day.end !== firstNonNullDay.end
    ) {
      areSame = false;
    }
  });

  const notNullArray = days.filter(
    dayName => sessionTiming[dayName as keyof SessionTiming]
  );

  // if only one day is selected lets display it as a single day not generalTime
  if (notNullArray.length === 1) {
    return false;
  }

  return areSame;
}
function getStartEndForDay(day?: SessionDayDetails | null) {
  if (!day) {
    return {
      start: '',
      end: '',
    };
  }

  const formatTime = (dateString?: string | null) => {
    if (!dateString) {
      return '';
    }
    const date = parseISO(dateString);
    return isValid(date) ? format(date, 'HH:mm') : '';
  };

  return {
    start: formatTime(day.start?.toString()),
    end: formatTime(day.end?.toString()),
  };
}

function findFirstNonNullDay(sessionTiming?: SessionTiming): {
  start: string;
  end: string;
} {
  let firstNonNullDay: SessionDayDetails | null = null;

  if (!sessionTiming) {
    return {
      start: '',
      end: '',
    };
  }

  const availableDays = Object.keys(sessionTiming);

  availableDays.forEach(dayName => {
    const day = sessionTiming[dayName as keyof SessionTiming];
    if (day !== null && firstNonNullDay === null) {
      firstNonNullDay = day;
    }
  });

  return getStartEndForDay(firstNonNullDay);
}

// Time is stored as date object, so lets create a random date. we will use only the time part
function generateDateFromTime(time: string): Date | null {
  if (time === '') {
    return null;
  }

  const [hours, minutes] = time.split(':');

  return new Date(1995, 11, 20, Number(hours), Number(minutes));
}

export {
  areAllDaysAreEqual,
  days,
  findFirstNonNullDay,
  generateDateFromTime,
  getOperatingDaysOfSessionType,
  getStartEndForDay,
};
