import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Alert } from '../alert';
import { TextInput } from './common/text-input';

const schema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

interface ForgottenPasswordFormDto {
  email: string;
}

interface ForgottenPasswordFormProps {
  onRequestPasswordReset: (data: ForgottenPasswordFormDto) => Promise<void>;
}

function ForgottenPasswordForm(
  ForgottenPasswordFormProps: ForgottenPasswordFormProps
) {
  const { onRequestPasswordReset } = ForgottenPasswordFormProps;
  const [hasError, setHasError] = useState(false);
  const methods = useForm<ForgottenPasswordFormDto>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: ForgottenPasswordFormDto) => {
    setHasError(false);

    try {
      await onRequestPasswordReset(data);
    } catch (e) {
      setHasError(true);
    }
  };

  return (
    <div>
      {hasError ? (
        <Alert
          type="error"
          text="There was a problem with your password reset request."
          className="mb-4"
        />
      ) : null}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-6">
          <TextInput
            name="email"
            label="Email*"
            type="email"
            placeholder="Enter your email"
          />
          <button
            className="btn btn-primary btn-full"
            disabled={
              methods.formState.isSubmitting || !methods.formState.isValid
            }
          >
            Request Password Reset
          </button>
        </form>
      </FormProvider>
    </div>
  );
}

export { ForgottenPasswordForm };
export type { ForgottenPasswordFormDto };
