import { QuestionCategory, QuestionTab } from '@/types/application-template';
import { twMerge } from 'tailwind-merge';

const tabs: QuestionTab[] = [
  {
    title: 'Child details',
    category: QuestionCategory.ChildDetails,
  },
  {
    title: 'Parent/Carer Details',
    category: QuestionCategory.ParentCarerDetails,
  },
  { title: 'Houshold Details', category: QuestionCategory.HouseholdDetails },
  {
    title: 'Placement Preferences',
    category: QuestionCategory.PlacementPreferences,
  },
  {
    title: 'Additional Details',
    category: QuestionCategory.AdditionalDetails,
  },
];

type QuestionNavigationProps = {
  active: QuestionCategory;
  onNavigationChange: (category: QuestionCategory) => void;
};

function QuestionNavigation({
  active,
  onNavigationChange,
}: QuestionNavigationProps) {
  return (
    <div className="mb-4 md:mb-0 md:inline-flex md:flex-col">
      {tabs.map(tab => (
        <button
          type="button"
          key={tab.title}
          className={twMerge(
            'btn rounded-md px-3 py-2 text-left text-gray-500 shadow-none',
            tab.category === active
              ? 'bg-primary-50 text-primary-700'
              : 'text-gray-500'
          )}
          onClick={() => onNavigationChange(tab.category)}
        >
          {tab.title}
        </button>
      ))}
    </div>
  );
}

export { QuestionNavigation };
