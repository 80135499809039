import { applicationKey } from '@/config/query-keys';
import { applicationService } from '@/services/application.service';
import { HttpError } from '@/types/error';
import { Application } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useApplicationsByChildId(
  childId?: string,
  queryOptions?: Partial<UseQueryOptions<Application[], HttpError>>
) {
  return useQuery({
    queryKey: applicationKey.byChildId(childId || ''),
    queryFn: () => applicationService.getApplicationsByChildId(childId || ''),
    ...queryOptions,
    enabled: Boolean(childId),
  });
}

export { useApplicationsByChildId };
