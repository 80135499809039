import { ParentCarerForm } from '@/components/application/carer/parent-carer.form';
import { Spinner } from '@/components/spinner';
import { staffRouterPath } from '@/config/route-paths.config';
import { useApplication } from '@/hooks/query-hooks/use-application';
import { useApplicationTemplate } from '@/hooks/query-hooks/use-application-template';
import { useUpdateApplicationMutation } from '@/hooks/update-hooks/use-update-application-mutation';
import { Navigate, useParams } from 'react-router-dom';

function ParentCarer() {
  const params = useParams<{ id: string }>();

  const {
    data: application,
    error: applicationError,
    isLoading: isApplicationLoading,
    isSuccess: isApplicationSuccess,
  } = useApplication(params.id || '', {
    enabled: Boolean(params.id),
  });

  const {
    data: applicationTemplate,
    isSuccess: isApplicationTemplateSuccess,
    isLoading: isApplicationTemplateLoading,
  } = useApplicationTemplate(
    isApplicationSuccess ? application.template.id.toString() : '',
    {
      enabled: isApplicationSuccess,
    }
  );

  const isFormLoading = isApplicationLoading || isApplicationTemplateLoading;

  const { mutateAsync: updateApplication, isPending: isApplicationUpdating } =
    useUpdateApplicationMutation(application?.id || '');

  const isFormDisabled = isApplicationUpdating;

  if (applicationError) {
    return <Navigate to={staffRouterPath.APPLICATIONS} />;
  }

  return (
    <div>
      {!isFormLoading && application && isApplicationTemplateSuccess ? (
        <ParentCarerForm
          initialData={application}
          onSubmit={updateApplication}
          isMutating={isFormDisabled}
          template={applicationTemplate}
        />
      ) : (
        <div className="flex items-center justify-center px-4 py-20">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export { ParentCarer };
