import { userService } from '@/services/user.service';
import { AmplifyError } from '@/types/error';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { PasswordRules } from '../password-rules';
import { TextInput } from './common/text-input';

const schema = yup.object({
  currentPassword: yup.string().required().label('Current Password'),
  password: yup
    .string()
    .notOneOf(
      [yup.ref('currentPassword'), ''],
      'You cannot use your old password!'
    )
    .min(12, 'Password must be at least 12 characters long')
    .matches(/^(?=.*[0-9])/, 'Password must contain at least 1 number')
    // https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
    .matches(
      /^(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])/,
      'Password must contain at least 1 special character'
    )
    .matches(
      /^(?=.*[A-Z])/,
      'Password must contain at least 1 uppercase letter'
    )
    .matches(
      /^(?=.*[a-z])/,
      'Password must contain at least 1 lowercase letter'
    )
    .required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), ''], 'Passwords must match')
    .required('Confirm Password is required'),
});

let toastId: string;

function ChangePasswordForm() {
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
  });

  const password = form.watch('password');

  const submitHandler = async (data: {
    currentPassword: string;
    password: string;
  }) => {
    try {
      toastId = toast.loading('Loading..');
      setIsLoading(true);
      await userService.changeUserPassword(data);
      form.reset();
      toast.success('Your password has been updated successfully!', {
        id: toastId,
      });
    } catch (e) {
      const error = e as AmplifyError;

      switch (error.code) {
        case 'LimitExceededException':
          toast.error(error.message, {
            id: toastId,
          });
          break;
        case 'NotAuthorizedException':
          form.setError('currentPassword', { message: error.message });
          toast.remove(toastId);
          break;
        default:
          toast.error(error.message, {
            id: toastId,
          });
      }
    }
    setIsLoading(false);
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(submitHandler)}
        className="flex h-full flex-1 flex-col"
      >
        <div className="grid max-w-4xl grid-cols-1 gap-x-6 gap-y-8 lg:col-span-2 lg:grid-cols-6">
          <div className="sm:col-span-4">
            <div className="space-y-6">
              <TextInput
                name="currentPassword"
                type="password"
                label="Current Password"
                disabled={isLoading}
              />
              <TextInput
                name="password"
                type="password"
                label="New Password"
                disabled={isLoading}
              />
              <TextInput
                name="confirmPassword"
                type="password"
                label="Confirm Password"
                disabled={isLoading}
              />
              <PasswordRules password={password} schema={schema} />

              <button
                className="btn btn-primary"
                disabled={isLoading || !form.formState.isDirty}
              >
                Update Password
              </button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export { ChangePasswordForm };
