import { locationKey } from '@/config/query-keys';
import { locationService } from '@/services/location.service';
import { HttpError } from '@/types/error';
import { Session } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useSessions(
  locationId: string,
  schoolYearId: string,
  queryOptions?: Partial<UseQueryOptions<Session[], HttpError>>
) {
  return useQuery({
    queryKey: locationKey.listSessions(locationId, schoolYearId),
    queryFn: () =>
      locationService.getSessions({
        locationId: locationId,
        termId: schoolYearId,
      }),
    ...queryOptions,
    enabled: !!locationId && !!schoolYearId,
  });
}

export { useSessions };
