import { Table } from '@/components/table';
import { staffRouterPath } from '@/config/route-paths.config';
import { useExtraHoursBookingsControl } from '@/context/extra-hours-bookings-control.context';
import { useSchoolYear } from '@/context/school-year.context';
import { useBookingsByChildren } from '@/hooks/query-hooks/use-bookings-by-children';
import { formatCurrency } from '@/utils/format-currency';
import { getErrorMessage } from '@/utils/get-error-message';
import { ChildWithBookingInfo } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import { Eye } from '@untitled-ui/icons-react';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { BillingStatusTag } from '../billing-status-tag';
import { Tag } from '../tag';

function ExtraHoursBookingsTable() {
  const columnHelper = createColumnHelper<ChildWithBookingInfo>();

  const { filter, setPagination, pagination, sorting, setSorting } =
    useExtraHoursBookingsControl();

  const { schoolYear } = useSchoolYear();

  const {
    data: childrenWithBookings,
    isFetching,
    isLoading,
    error,
  } = useBookingsByChildren(
    schoolYear.id,
    {
      pagination,
      filter,
      sorting,
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('childName', {
        id: 'childName',
        cell: info => {
          const childName = info.getValue();
          return <span className="text-sm text-gray-600">{childName}</span>;
        },
        header: () => <span className="text-xs text-gray-600">Child</span>,
        enableSorting: true,
      }),
      columnHelper.accessor('locations', {
        id: 'locations',
        cell: info => {
          const location = info.getValue();
          return <span className="text-sm text-gray-600">{location}</span>;
        },
        header: () => <span className="text-xs text-gray-600">Location</span>,
        enableSorting: false,
        size: 240,
      }),
      columnHelper.accessor('dateOfBirth', {
        id: 'dateOfBirth',
        cell: info => {
          const dob = info.getValue();
          return (
            <span className="font-mono text-sm text-gray-600">
              {format(new Date(dob), 'P')}
            </span>
          );
        },
        header: () => <span className="text-xs text-gray-600">DOB</span>,
        enableSorting: false,
        size: 133,
      }),
      columnHelper.accessor('numberOfBookings', {
        id: 'numberOfBookings',
        cell: info => {
          const numberOfBookings = info.getValue();
          return (
            <span className="font-mono text-sm text-gray-600">
              {numberOfBookings}
            </span>
          );
        },
        header: () => <span className="text-xs text-gray-600">Bookings</span>,
        enableSorting: true,
        size: 133,
      }),
      columnHelper.accessor('balance', {
        id: 'balance',
        cell: info => {
          const balance = info.getValue();
          return (
            <>
              <span className="font-mono text-sm text-gray-600">
                {balance < 0
                  ? formatCurrency(balance * -1)
                  : formatCurrency(balance)}
              </span>
              {balance < 0 && (
                <Tag
                  type="outline"
                  className="ml-1.5 inline-flex w-auto flex-row items-center px-1.5 py-0.5 text-xs font-normal capitalize shadow-xs"
                >
                  Credit
                </Tag>
              )}
            </>
          );
        },
        header: () => <span className="text-xs text-gray-600">Balance</span>,
        enableSorting: true,
        size: 160,
      }),
      columnHelper.accessor('billingStatus', {
        id: 'billingStatus',
        cell: info => {
          const billingStatus = info.getValue();
          return <BillingStatusTag status={billingStatus} />;
        },
        header: () => <span className="text-xs text-gray-600">Status</span>,
        enableSorting: false,
        size: 121,
      }),
      columnHelper.accessor('id', {
        id: 'actions',
        cell: info => {
          const childId = info.getValue();
          return (
            <Link
              className="icon-link block"
              to={generatePath(
                staffRouterPath.EXTRA_HOURS_BOOKINGS_CHILD_DETAILS,
                { id: childId }
              )}
            >
              <Eye className="h-5 w-5" viewBox="0 0 24 24" aria-hidden="true" />
            </Link>
          );
        },
        header: () => (
          <span className="text-xs text-gray-600">View Bookings</span>
        ),
        enableSorting: false,
        size: 133,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Table
      columns={columns}
      data={childrenWithBookings?.docs}
      isLoading={isLoading}
      isFetching={isFetching}
      error={getErrorMessage(error)}
      paginationType="manual"
      onPaginationChange={setPagination}
      pagination={pagination}
      pageCount={childrenWithBookings?.totalPages}
      EmptyState={
        <div className="py-12 text-center">
          <h3 className="mt-2 text-sm font-semibold text-gray-900">
            No Bookings found for this school year
          </h3>
        </div>
      }
      sortingType="server"
      sorting={sorting}
      onSortingChange={setSorting}
    />
  );
}

export { ExtraHoursBookingsTable };
