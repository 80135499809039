import { Checkbox } from '@/components/form/common/checkbox';

type SplitPlacementSectionProps = {
  isDisabled: boolean;
};

function SplitPlacementSection(props: SplitPlacementSectionProps) {
  const { isDisabled } = props;

  return (
    <div className="two-col-form gap-6 py-6">
      <div>
        <p className="font-semibold text-gray-700">Split Placements</p>{' '}
        <p className="text-gray-600">
          Determine where blended placements can take place
        </p>
      </div>
      <Checkbox
        disabled={isDisabled}
        className="col-span-2"
        label="Council Locations"
        helperText="Applications can split placements at council locations"
        name="isCouncilSplitPermitted"
      />
    </div>
  );
}

export { SplitPlacementSection };
