import { locationKey } from '@/config/query-keys';
import { useSchoolYear } from '@/context/school-year.context';
import { locationService } from '@/services/location.service';
import { HttpError } from '@/types/error';
import { Operation, UpdateOperationDto } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateOperationMutation(
  { operationId }: { operationId: string },
  mutationOptions?: UseMutationOptions<
    Operation,
    HttpError,
    { data: UpdateOperationDto }
  >
) {
  const { schoolYear } = useSchoolYear();

  return useMutation({
    mutationKey: locationKey.updateOperation(operationId || '', schoolYear.id),
    mutationFn: ({ data }) =>
      locationService.updateOperation({
        operationId,
        data,
      }),
    ...mutationOptions,
  });
}

export { useUpdateOperationMutation };
