import { organisationKey } from '@/config/query-keys';
import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import { UserGroup } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useUserGroups(
  queryOptions?: Partial<UseQueryOptions<UserGroup[], HttpError>>
) {
  return useQuery({
    queryKey: organisationKey.userGroupsList,
    queryFn: organisationService.listUserGroups,
    ...queryOptions,
  });
}

export { useUserGroups };
