import MygovscotLogo from '@/assets/mygovscot.svg?react';
import { parentRouterPath } from '@/config/route-paths.config';
import { isProd } from '@/utils/env-utils';
import { isAdminDashboard } from '@/utils/is-dashboard';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthError, signInWithRedirect } from 'aws-amplify/auth';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import * as yup from 'yup';
import { Alert } from '../alert';
import { Spinner } from '../spinner';
import { Checkbox } from './common/checkbox';
import { TextInput } from './common/text-input';

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
    rememberDevice: yup.boolean().required(),
  })
  .required();

interface LoginDto {
  email: string;
  password: string;
  rememberDevice: boolean;
}

interface LoginFormProps {
  onLogin: (data: LoginDto) => Promise<void>;
}

function LoginForm(loginFormProps: LoginFormProps) {
  const { onLogin } = loginFormProps;
  const [error, setError] = useState<string | null>(null);
  const methods = useForm<LoginDto>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
      rememberDevice: false,
    },
  });
  const [searchParams] = useSearchParams();
  //if we have this code defined in the search params, user was redirected from mygovscot to admit
  const mygovscotRedirectCode = searchParams.get('code');
  const isUserRedirected = !!mygovscotRedirectCode;

  const onSubmit = async (data: LoginDto) => {
    setError(null);

    try {
      await onLogin(data);
    } catch (e) {
      if (e instanceof AuthError) {
        setError(e.message);
        return;
      }

      setError(
        'We encountered an issue with your login attempt. Please refresh the page and try again.'
      );
    }
  };

  const handleMyGovSignIn = async () => {
    await signInWithRedirect({
      provider: {
        custom: 'mygovscot',
      },
    });
  };

  return (
    <div className="mt-8">
      {error ? (
        <Alert
          type="error"
          title="There was a problem with your login"
          text={error}
          className="mb-4"
        />
      ) : null}
      {!isAdminDashboard() && !isProd() && (
        <button
          onClick={handleMyGovSignIn}
          className="btn btn-secondary text-md relative mb-6 flex w-full flex-col items-center justify-center font-semibold sm:flex-row"
          disabled={isUserRedirected}
        >
          {isUserRedirected && (
            <div className="absolute inset-0 flex items-center justify-center">
              <Spinner className="m-0 h-5" />
            </div>
          )}
          <MygovscotLogo
            className={twMerge(
              'mt-1 h-5 sm:mr-3',
              isUserRedirected && 'opacity-25'
            )}
          />
          Sign in with mygov.scot
        </button>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-5">
          <TextInput
            name="email"
            label="Email"
            type="email"
            placeholder="Enter your email"
          />
          <TextInput
            name="password"
            label="Password"
            type="password"
            placeholder="Enter your password"
            autoComplete="current-password"
          />
          <div className="flex justify-between">
            <Checkbox name="rememberDevice" label="Trust this device?" />
            <Link
              to={parentRouterPath.FORGOTTEN_PASSWORD}
              className="text-sm font-semibold"
            >
              Forgotten password
            </Link>
          </div>
          <button
            className="btn btn-primary btn-full"
            disabled={
              methods.formState.isSubmitting || !methods.formState.isValid
            }
          >
            Sign In
          </button>
          {isAdminDashboard() ? (
            <p className="text-center text-sm">
              Don&apos;t have an account? Contact your team administrator.
            </p>
          ) : (
            <p className="text-center text-sm">
              Don&apos;t have an account?{' '}
              <Link to={parentRouterPath.CREATE_ACCOUNT}>Sign Up</Link>
            </p>
          )}
        </form>
      </FormProvider>
    </div>
  );
}

export { LoginForm };
export type { LoginDto };
