import { applicationKey } from '@/config/query-keys';
import { useSchoolYear } from '@/context/school-year.context';
import { applicationService } from '@/services/application.service';
import { HttpError } from '@/types/error';
import { MatchingSummary } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useRunMatching(
  mutationOptions?: UseMutationOptions<
    MatchingSummary,
    HttpError,
    { choice: number }
  >
) {
  const { schoolYear } = useSchoolYear();

  return useMutation({
    mutationKey: applicationKey.runMatching,
    mutationFn: ({ choice }: { choice: number }) =>
      applicationService.runMatching({
        schoolYearId: schoolYear.id,
        choiceIndex: choice,
      }),
    ...mutationOptions,
  });
}

export { useRunMatching };
