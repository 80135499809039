import { get } from 'lodash-es';
import { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

interface DateInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  type: 'date' | 'time';
}

function DateInput({ label, name, ...inputProps }: DateInputProps) {
  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext();

  const error = get(errors, name);
  const hasError = Boolean(error);

  return (
    <div>
      {label ? (
        <label htmlFor={name} className="label">
          {label}
        </label>
      ) : null}
      <div className="relative mt-2">
        <input
          id={name}
          className={twMerge(
            'input flex',
            hasError ? 'ring-red-500 focus:ring-red-300' : null
          )}
          aria-invalid={hasError ? 'true' : 'false'}
          disabled={isSubmitting}
          {...inputProps}
          {...register(name)}
        />
      </div>

      {hasError ? (
        <p className="mt-1.5 text-sm text-red-600">
          {error?.message?.toString()}
        </p>
      ) : null}
    </div>
  );
}

export { DateInput };
