import { reportKey } from '@/config/query-keys';
import { reportService } from '@/services/report.service';
import { HttpError } from '@/types/error';
import { ReportTemplate } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useReportTemplate(
  id: string,
  queryOptions?: Partial<UseQueryOptions<ReportTemplate, HttpError>>
) {
  return useQuery({
    queryKey: reportKey.singleTemplate(id),
    queryFn: () => reportService.getReportTemplate(id),
    ...queryOptions,
  });
}

export { useReportTemplate };
