import { PermissionGuard } from '@/components/auth/permission-guard';
import { messageCategories } from '@/components/messaging/message-categories';
import { MessageStatusBadge } from '@/components/messaging/message-status-badge';
import { MessageTitleCell } from '@/components/messaging/message-title-cell';
import { Table } from '@/components/table';
import { Tag } from '@/components/tag';
import { staffRouterPath } from '@/config/route-paths.config';
import { useOrganisationData } from '@/context/organisation-control.context';
import { Permission } from '@/types/auth';
import { ListTicketsResponseItem } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import { Eye, Plus, SearchRefraction } from '@untitled-ui/icons-react';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { generatePath, Link, useNavigate } from 'react-router-dom';

type MessagingTableProps = {
  messages: ListTicketsResponseItem[];
  isLoading: boolean;
  setSelectedCategories?: (
    categories: ListTicketsResponseItem['topic'][]
  ) => void;
  setShowClosedTasks: (showClosedTasks: boolean) => void;
  setSearch?: (search: string) => void;
  isParent?: boolean;
};

function MessagingTable({
  messages,
  isLoading,
  setSelectedCategories,
  setShowClosedTasks,
  setSearch,
  isParent = false,
}: MessagingTableProps) {
  const navigate = useNavigate();
  const messagingColumnHelper = createColumnHelper<ListTicketsResponseItem>();
  const { data: organisationData } = useOrganisationData();

  const messagingColumns = useMemo(
    () => [
      messagingColumnHelper.accessor('title', {
        id: 'title',
        header: () => 'Title',
        cell: info => <MessageTitleCell ticket={info.row.original} />,
      }),
      messagingColumnHelper.accessor('status', {
        id: 'status',
        header: () => 'Status',
        cell: info => <MessageStatusBadge status={info.row.original.status} />,
      }),
      messagingColumnHelper.accessor('topic', {
        id: 'topic',
        header: () => 'Category',
        cell: info => (
          <Tag type="outline">
            {messageCategories[info?.row?.original?.topic]}
          </Tag>
        ),
      }),
      messagingColumnHelper.accessor('lastUpdateAt', {
        id: 'lastUpdateAt',
        header: () => 'Last Update',
        cell: info => {
          const lastUpdateBy =
            info.row.original.lastUpdateBy === 'staff'
              ? organisationData?.name
              : isParent
              ? 'You'
              : 'Parent / Carer';
          return (
            <div className="flex flex-col">
              <span className="text-sm text-primary-900">{lastUpdateBy}</span>
              <span className="text-sm text-gray-500">
                {format(info.row.original.lastUpdateAt, 'dd/MM/yyyy')}
              </span>
            </div>
          );
        },
      }),
      messagingColumnHelper.display({
        id: 'actions',
        header: () => 'Actions',
        cell: info => (
          <Link
            to={generatePath(staffRouterPath.MESSAGING_CONVERSATION, {
              id: info.row.original.id,
            })}
            className="edit-location-tooltip icon-link block"
          >
            <Eye className="h-5 w-5" viewBox="0 0 24 24" aria-hidden="true" />
            <span className="sr-only">View Message</span>
          </Link>
        ),
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const clearFiltersClose = () => {
    setShowClosedTasks && setShowClosedTasks(false);
    setSelectedCategories && setSelectedCategories([]);
    setSearch && setSearch('');
  };

  const handleRowClick = (rowIndex: number) => {
    navigate(
      generatePath(staffRouterPath.MESSAGING_CONVERSATION, {
        id: messages[rowIndex].id,
      })
    );
  };

  return (
    <Table
      columns={messagingColumns}
      data={messages || []}
      isLoading={isLoading}
      onRowClick={handleRowClick}
      isFetching={isLoading}
      EmptyState={
        <div className="py-12 text-center">
          <SearchRefraction
            className="mx-auto h-8 w-8 text-gray-400"
            viewBox="0 0 24 24"
            aria-hidden="true"
          />
          <h3 className="mt-2 text-sm font-semibold text-gray-900">
            No Tickets Found
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Either you have no tickets or no tickets match your applied search
          </p>
          <div className="mt-4 flex w-full justify-center gap-4">
            <button className="btn btn-secondary" onClick={clearFiltersClose}>
              Clear Filters
            </button>

            <PermissionGuard
              requiredPermissions={[Permission['messaging:create']]}
            >
              <Link
                to={staffRouterPath.MESSAGING_NEW_CONVERSATION}
                className="btn btn-primary flex items-center gap-2"
              >
                <Plus
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                />
                New Ticket
              </Link>
            </PermissionGuard>
          </div>
        </div>
      }
    />
  );
}

export { MessagingTable };
