import { DeleteZone } from '@/components/delete-zone';
import { FallbackComponent } from '@/components/fallback-component';
import { PageTitle } from '@/components/page-title';
import { UpsertLocationGroupingForm } from '@/components/settings/location-grouping/upsert-location-grouping/upsert-location-grouping.form';
import { Spinner } from '@/components/spinner';
import { areaKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useCreateAreaMutation } from '@/hooks/create-hooks/use-create-area';
import { useDeleteAreaMutation } from '@/hooks/delete-hooks/use-delete-area-mutation';
import { useArea } from '@/hooks/query-hooks/use-area';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useUpdateAreaMutation } from '@/hooks/update-hooks/use-update-area-mutation';
import { queryClient } from '@/libs/react-query';
import { Permission } from '@/types/auth';
import { getErrorMessage } from '@/utils/get-error-message';
import { CreateAreaDto, UpdateAreaDto } from '@admissions-support/types';
import { Link, useNavigate, useParams } from 'react-router-dom';

async function invalidateAreasQuery() {
  await queryClient.invalidateQueries({
    queryKey: areaKey.list,
    refetchType: 'active',
  });
}

function UpsertLocationGrouping() {
  const params = useParams<{ id: string }>();

  const navigate = useNavigate();

  const isEditLocationGrouping = Boolean(params.id);

  const { hasPermission } = usePermissions();

  const { mutateAsync: update, isPending: isUpdating } = useUpdateAreaMutation({
    onSuccess: invalidateAreasQuery,
  });

  const {
    mutateAsync: deleteArea,
    isPending: isDeleting,
    error: deleteError,
  } = useDeleteAreaMutation({
    onSuccess: async () => {
      queryClient.removeQueries({
        queryKey: areaKey.single(params.id || ''),
      });
      await invalidateAreasQuery();
      navigate(staffRouterPath.LOCATION_GROUPING);
    },
    retry: 0,
  });

  const { mutateAsync: create, isPending: isCreating } = useCreateAreaMutation({
    onSuccess: invalidateAreasQuery,
  });

  const {
    data: area,
    isLoading: isAreaLoading,
    isSuccess: isAreaSuccess,
  } = useArea(params.id || '', { enabled: isEditLocationGrouping });

  const updateLocationGrouping = async (data: UpdateAreaDto) => {
    await update({ id: params.id || '', data });
  };

  const createLocationGrouping = async (data: CreateAreaDto) => {
    await create(data);
    navigate(staffRouterPath.LOCATION_GROUPING);
  };

  const deleteLocationGrouping = async () => {
    try {
      await deleteArea(params.id || '');
    } catch {
      // error handled in the modal
    }
  };

  const isMutationLoading = isUpdating || isDeleting || isCreating;

  const hasUpsertPermission =
    hasPermission([Permission['location:create']]) ||
    hasPermission([Permission['location:update']]);

  const pageTitle = (
    <PageTitle title="Edit Group" variant="gray">
      {hasUpsertPermission && (
        <div className="space-x-3">
          <Link
            to={staffRouterPath.LOCATION_GROUPING}
            className="btn btn-secondary"
          >
            Cancel
          </Link>

          <button
            type="submit"
            form="upsert-location-grouping-form"
            className="btn btn-primary"
            disabled={
              isMutationLoading || (isAreaLoading && isEditLocationGrouping)
            }
          >
            {isEditLocationGrouping ? 'Update' : 'Create'}
          </button>
        </div>
      )}
    </PageTitle>
  );

  if (isEditLocationGrouping && isAreaLoading) {
    return (
      <div>
        {pageTitle}
        <Spinner className="m-auto mt-12" />
      </div>
    );
  }

  if (isEditLocationGrouping && !isAreaLoading && !isAreaSuccess) {
    return <FallbackComponent />;
  }

  return (
    <div>
      {pageTitle}
      <UpsertLocationGroupingForm
        initialData={area}
        isLoading={isMutationLoading}
        onSubmit={
          isEditLocationGrouping
            ? updateLocationGrouping
            : createLocationGrouping
        }
      />
      {isEditLocationGrouping &&
      hasPermission([Permission['location:delete']]) ? (
        <div className="two-col-form">
          <div>
            <p className="text-md font-medium leading-7 text-gray-900">
              Destructive Actions
            </p>
            <p className="text-md leading-6 text-gray-600">
              Take care with these actions as they are destructive and may not
              be reversable.
            </p>
          </div>
          <div className="col-span-2 space-y-6">
            <DeleteZone
              title="Delete Group"
              actionButtonText="Delete Group"
              modalDescription="In order to delete this Location Group, please type DELETE in the input below and confirm deletion."
              onDelete={deleteLocationGrouping}
              isLoading={isDeleting}
              error={getErrorMessage(deleteError)}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export { UpsertLocationGrouping };
