import { TextInput, TextInputBase } from '@/components/form/common/text-input';
import { ProductListing } from '@admissions-support/types';

type UpdateProductPriceFormProps = {
  initialProduct?: ProductListing;
};

function UpdateProductPriceForm(props: UpdateProductPriceFormProps) {
  const { initialProduct } = props;

  return (
    <div className="space-y-4">
      <div className="flex gap-6">
        <TextInputBase
          name="productName"
          disabled
          value={initialProduct?.name}
          label="Product"
          className="flex-1"
        />

        <TextInput
          prefix="£"
          type="number"
          label="Product Price"
          name="price"
          placeholder="00"
          min={0}
          className="flex-1"
        />
      </div>
      <div className="flex gap-6">
        <TextInput
          name="availableFrom"
          type="date"
          label="From"
          className="flex-1"
        />
        <TextInput
          name="availableTo"
          type="date"
          label="To"
          className="flex-1"
        />
      </div>
    </div>
  );
}

export { UpdateProductPriceForm };
