import { useReportingOutputControl } from '@/context/reporting-output-control.context';
import { ChildSiblings } from '@admissions-support/types';

type SiblingsCellProps = {
  siblings?: ChildSiblings[];
};

function SiblingsCell(props: SiblingsCellProps) {
  const { siblings } = props;

  const { setSideDrawerContent, openModal } = useReportingOutputControl();

  if (!siblings || siblings?.length <= 0) {
    return <span>--</span>;
  }

  return (
    <div className="flex flex-col flex-wrap gap-1">
      {siblings.map((sibling, key) => (
        <button
          aria-label={`Open details for ${sibling.firstName} ${sibling.lastName}`}
          onClick={() => {
            setSideDrawerContent(sibling);
            openModal();
          }}
          type="button"
          className="w-fit rounded-md p-1 text-left text-sm font-semibold text-primary-700 hover:bg-gray-50"
          key={key}
        >{`${sibling.firstName} ${sibling.lastName}`}</button>
      ))}
    </div>
  );
}

export { SiblingsCell };
