import { invitationKeys } from '@/config/query-keys';
import { trusteeService } from '@/services/trustee.service';
import { HttpError } from '@/types/error';
import {
  CreateTrusteeInvitationDto,
  Invitation,
} from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCreateTrusteeMutation(
  mutationOptions?: UseMutationOptions<
    Invitation,
    HttpError,
    CreateTrusteeInvitationDto
  >
) {
  return useMutation({
    mutationKey: invitationKeys.create,
    mutationFn: trusteeService.createTrustee,
    ...mutationOptions,
  });
}

export { useCreateTrusteeMutation };
