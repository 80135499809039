import { axiosClient } from '@/libs/axios';
import { HttpError } from '@/types/error';
import { ChildBookingFilter } from '@/types/extra-hours-bookings-control';
import {
  ExtraHoursAvailableToBook,
  UpdateExtraHoursDto,
} from '@admissions-support/types';
import { AxiosResponse } from 'axios';
import { endOfDay, startOfDay } from 'date-fns';

const fetchExtraHoursByChildId = async (
  childId: string,
  query: ChildBookingFilter
): Promise<ExtraHoursAvailableToBook[]> => {
  const startDate = startOfDay(new Date(query.from));
  const queryParams = [
    `from=${startDate.toISOString()}`,
    `locationId=${query.location}`,
    `schoolYearId=${query.schoolYear}`,
  ];

  if (query.to) {
    const endDate = endOfDay(new Date(query.to));
    queryParams.push(`to=${endDate.toISOString()}`);
  }

  if (query.days.length > 0) {
    query.days.forEach(day => {
      queryParams.push(`days[]=${day}`);
    });
  }

  const response = await axiosClient.get<
    HttpError,
    AxiosResponse<ExtraHoursAvailableToBook[]>
  >(`/extra-hours/children/${childId}?${queryParams.join('&')}`);

  return response.data;
};

const updateExtraHoursPrice = async ({
  data,
}: {
  data: UpdateExtraHoursDto;
}): Promise<{ updated: number }> => {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<{ updated: number }>,
    UpdateExtraHoursDto
  >(`/extra-hours/`, data);

  return response.data;
};

export const extraHourService = Object.freeze({
  fetchExtraHoursByChildId,
  updateExtraHoursPrice,
});
