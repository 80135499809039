import { ParentChildDetailsForm } from '@/components/application/child-details/parent-child-details.form';
import { Box } from '@/components/box';
import { Spinner } from '@/components/spinner';
import { parentRouterPath } from '@/config/route-paths.config';
import { useChild } from '@/hooks/query-hooks/use-child';
import { useUpdateChildMutation } from '@/hooks/update-hooks/use-update-child-mutation';
import { generatePath, Link, Navigate, useParams } from 'react-router-dom';
import { Breadcrumbs } from '@/components/breadcrumbs';
import { BreadcrumbNode } from '@/types/breadcrumb';
import { ArrowLeft } from '@untitled-ui/icons-react';

function ChildUpdate() {
  const { id = '' } = useParams();

  const {
    data: child,
    error: childError,
    isLoading: isChildLoading,
  } = useChild(id, {
    enabled: Boolean(id),
  });

  const { mutateAsync: updateChild, isPending: isChildUpdating } =
    useUpdateChildMutation(id);

  if (childError) {
    return <Navigate to={parentRouterPath.ROOT} />;
  }

  const breadcrumbPaths: BreadcrumbNode[] = [
    {
      content: 'Children',
      link: parentRouterPath.ROOT,
    },
    {
      content: child ? `${child.firstName} ${child.lastName}` : '',
      link: parentRouterPath.CHILD_OVERVIEW.replace(':id', id),
    },
    {
      content: 'Update details',
    },
  ];

  const backButtonText =
    child?.firstName && child?.lastName
      ? `Back to ${child?.firstName} ${child?.lastName}`
      : 'Back to child';

  return (
    <>
      <div className="flex flex-col items-start justify-between gap-6 sm:flex-row sm:items-center">
        <Breadcrumbs path={breadcrumbPaths} />
        <Link
          to={generatePath(parentRouterPath.CHILD_OVERVIEW, { id })}
          className="btn btn-secondary flex w-fit items-center"
        >
          <ArrowLeft
            viewBox="0 0 24 24"
            className="mr-2 h-5 w-5 text-gray-500"
          />
          {backButtonText}
        </Link>
      </div>
      <Box className="mt-8">
        <p className="my-1.5 text-lg font-bold text-gray-900">Child Details</p>
        {isChildLoading ? (
          <div className="flex items-center justify-center rounded-lg px-4 py-20">
            <Spinner />
          </div>
        ) : (
          <ParentChildDetailsForm
            onSubmit={updateChild}
            isMutating={isChildUpdating}
            initialData={child}
          />
        )}
      </Box>
    </>
  );
}

export { ChildUpdate };
