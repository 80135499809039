import { Checkbox } from '@/components/form/common/checkbox';
import { TextInput } from '@/components/form/common/text-input';
import { Disclosure as HeadlessUIDisclosure } from '@headlessui/react';
import { MinusCircle, PlusCircle } from '@untitled-ui/icons-react';
import { get, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { QueryBuilderFormData } from '../reporting.type';
import { useCanUserEditTemplate } from './use-can-user-edit-template';

function ReportDetails() {
  const { formState } = useFormContext<QueryBuilderFormData>();

  const error = get(formState.errors, 'name');
  const hasError = !!error;

  const canUserEditTemplate = useCanUserEditTemplate();

  return (
    <HeadlessUIDisclosure
      as="div"
      className={twMerge(
        'rounded-lg border p-4',
        hasError ? 'border-red-500' : 'border-gray-200'
      )}
    >
      {({ open }) => (
        <>
          <HeadlessUIDisclosure.Button className="flex w-full justify-between py-1 text-lg font-medium text-gray-900">
            Template Details
            {!open && (
              <PlusCircle aria-hidden="true" className="block text-gray-400" />
            )}
            {open && (
              <MinusCircle aria-hidden="true" className="block text-gray-400" />
            )}
          </HeadlessUIDisclosure.Button>

          <HeadlessUIDisclosure.Panel className="space-y-4 pt-3">
            <TextInput
              name="name"
              label="Template Name"
              placeholder="All first choices"
              disabled={!canUserEditTemplate}
            />

            {canUserEditTemplate && (
              <Checkbox
                name="visibility"
                label="Organisation Wide"
                helperText="Make this template visible to other users within the organisation. Only the template creator will be able to update or edit this template."
              />
            )}
          </HeadlessUIDisclosure.Panel>
        </>
      )}
    </HeadlessUIDisclosure>
  );
}

export { ReportDetails };
