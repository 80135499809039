import { staffRouterPath } from '@/config/route-paths.config';
import { useApplication } from '@/hooks/query-hooks/use-application';
import { useChangelog } from '@/hooks/query-hooks/use-changelog';
import { useChild } from '@/hooks/query-hooks/use-child';
import { useUpdateApplicationMutation } from '@/hooks/update-hooks/use-update-application-mutation';
import { queryClient } from '@/libs/react-query';
import { PaginateResult } from '@/types/pagination';
import { ApplicationListing, Child, Choice } from '@admissions-support/types';
import { Edit05, Flag01 } from '@untitled-ui/icons-react';
import { startCase } from 'lodash-es';
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { ApplicationStatusTag } from './application/application-status-tag';
import { DrawerContainer } from './application/drawer/drawer-container';
import { DrawerContent } from './application/drawer/drawer-content';
import { LoadingScreen } from './loading-screen';
import { Tag } from './tag';

function getChoiceName(choice: Choice): string {
  if (choice.nbPreference === 1) {
    return '1st';
  }

  if (choice.nbPreference === 2) {
    return '2nd';
  }

  if (choice.nbPreference === -1) {
    return 'Prescribed Placement';
  }

  return '3rd';
}

type ApplicationQuickViewDrawerProps = {
  applicationId?: string;
  open: boolean;
  onClose: () => void;
  disableOnCloseNavigation?: boolean;
};

function ApplicationQuickViewDrawer(props: ApplicationQuickViewDrawerProps) {
  const {
    open,
    onClose,
    applicationId: applId,
    disableOnCloseNavigation,
  } = props;
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const applicationId = applId || params.id;

  const {
    isLoading: isApplicationLoading,
    data: application,
    isSuccess: isApplicationSuccess,
  } = useApplication(applicationId, {
    enabled: Boolean(applicationId),
  });

  const {
    isLoading: isChildLoading,
    data: child,
    isSuccess: isChildSuccess,
  } = useChild(application?.child?.id.toString() || '', {
    enabled: Boolean(application?.child?.id),
    placeholderData: () => {
      const listingQuery = queryClient.getQueriesData<
        PaginateResult<ApplicationListing>
      >({ queryKey: ['application-list'] })[0];

      if (!listingQuery?.[0]) {
        return undefined;
      }

      const listingData = listingQuery[1];

      if (listingData) {
        const actualApplication = listingData.docs.find(
          application => application.id === applicationId
        );

        const childPreview: Child = {
          id: application?.child?.id.toString() || '',
          firstName: actualApplication?.childName || '',
          lastName: '',
          knownAsFirstName: '',
          knownAsLastName: '',
          attachments: [],
          //@ts-ignore for preview empty string is enough
          sex: '',
          //@ts-ignore its a string anyway just backend does not have the correct typing exported
          dateOfBirth: application?.dateOfBirth,
        };

        return childPreview;
      }
    },
  });

  const {
    isLoading: isChangelogLoading,
    data: changelog,
    isSuccess: isChangelogSuccess,
  } = useChangelog(applicationId || '');

  const { mutateAsync: updateApplication } = useUpdateApplicationMutation(
    applicationId || ''
  );

  const isLoading =
    isApplicationLoading || isChildLoading || isChangelogLoading;

  const handleClose = () => {
    if (disableOnCloseNavigation) {
      return;
    }

    navigate(-1);
  };

  const handleToggleFlag = (isFlagged: boolean) => async () => {
    await updateApplication({ isFlagged });
  };

  return (
    <DrawerContainer
      open={open}
      onClose={onClose}
      isLoading={isApplicationLoading}
      onDrawerClosed={handleClose}
      isChoiceUpdateForm
    >
      <Tooltip
        anchorSelect=".flag-application"
        content="Flag Application"
        className="tooltip"
      />
      <Tooltip
        anchorSelect=".unflag-application"
        content="Unflag Application"
        className="tooltip"
      />
      <div className="mb-6 flex items-center">
        <p className="mr-4 flex items-center space-x-4 font-fmly text-3xl text-gray-700">
          <span>
            {child?.firstName} {child?.lastName}
          </span>
          {application?.flagsAndOverrides?.isFlagged ? (
            <button onClick={handleToggleFlag(false)} tabIndex={-1}>
              <Flag01
                viewBox="0 0 24 24"
                className="unflag-application h-5 w-5 fill-[#D92D20] text-[#D92D20]"
              />
            </button>
          ) : (
            <button onClick={handleToggleFlag(true)} tabIndex={-1}>
              <Flag01
                viewBox="0 0 24 24"
                className="flag-application h-5 w-5 fill-none text-gray-500"
              />
            </button>
          )}
        </p>
        <ApplicationStatusTag status={application?.status}>
          {startCase(application?.status?.toLowerCase())}
        </ApplicationStatusTag>
        <Link
          to={generatePath(staffRouterPath.APPLICATION_CHILD_DETAILS, {
            id: params.id || '',
          })}
          className="btn btn-secondary ml-auto flex items-center px-3 py-2"
        >
          <Edit05 className="mr-2 h-5 w-5" viewBox="0 0 24 24" />
          View full Application
        </Link>
      </div>
      {(application?.lookedAfter.isSubjectToCourtDecision ||
        application?.homeStatus === 'MOVING') && (
        <div className="mb-6 flex items-center">
          <div className="space-x-4">
            {application?.homeStatus === 'MOVING' && (
              <Tag type="warning">Moving to Area</Tag>
            )}
            {application.lookedAfter.isSubjectToCourtDecision && (
              <Tag type="warning">LAC</Tag>
            )}
          </div>
        </div>
      )}
      {!isLoading &&
        isApplicationSuccess &&
        isChildSuccess &&
        isChangelogSuccess && (
          <DrawerContent
            application={application}
            changelog={changelog}
            child={child}
          />
        )}
      {isLoading && !isApplicationLoading && <LoadingScreen />}
    </DrawerContainer>
  );
}

export { ApplicationQuickViewDrawer, getChoiceName };
