import { Box } from '@/components/box';
import { parentRouterPath } from '@/config/route-paths.config';
import { Application, UpdateApplicationDto } from '@admissions-support/types';
import { ArrowLeft, ArrowRight } from '@untitled-ui/icons-react';
import { FormProvider } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { HomeLanguage, Siblings } from './houshold.element';
import { useHouseholdForm } from './use-household.form';

type ParentHouseholdFormProps = {
  onSubmit: (data: UpdateApplicationDto) => any;
  initialData: Application;
};

function ParentHouseholdForm(props: ParentHouseholdFormProps) {
  const { initialData, onSubmit } = props;
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const { form, handleSubmit, saveAsDraft } = useHouseholdForm({
    initialData,
    onSubmit,
  });

  const formDisabled = form.formState.isSubmitting;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={saveAsDraft}
        className="space-y-4"
        id="upsert-application-form"
      >
        <Box className="space-y-4">
          <Siblings disabled={formDisabled} />
        </Box>
        <Box className="space-y-4">
          <HomeLanguage disabled={formDisabled} />
        </Box>
      </form>

      <div className="mt-6 flex flex-col space-y-4 sm:flex-row sm:justify-between sm:space-y-0">
        <button
          className="btn btn-secondary flex items-center"
          type="button"
          onClick={() =>
            navigate(
              generatePath(parentRouterPath.APPLICATION_CARER, {
                id: params.id || '',
              })
            )
          }
        >
          <ArrowLeft
            viewBox="0 0 24 24"
            className="mr-2 h-5 w-5 text-gray-500"
          />
          Previous
        </button>
        <button
          className="btn btn-primary flex items-center"
          type="button"
          onClick={handleSubmit}
        >
          Continue
          <ArrowRight viewBox="0 0 24 24" className="ml-2 h-5 w-5 text-white" />
        </button>
      </div>
    </FormProvider>
  );
}

export { ParentHouseholdForm };
