import { staffRouterPath } from '@/config/route-paths.config';
import { Navigate, generatePath, useParams } from 'react-router-dom';

function EditApplication() {
  const params = useParams<{ id: string }>();

  return (
    <Navigate
      to={generatePath(staffRouterPath.APPLICATION_CHILD_DETAILS, {
        id: params.id || '',
      })}
    />
  );
}

export { EditApplication };
