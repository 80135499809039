import { PermissionGuard } from '@/components/auth/permission-guard';
import { PageTitle } from '@/components/page-title';
import { Table } from '@/components/table';
import { Tag } from '@/components/tag';
import { staffRouterPath } from '@/config/route-paths.config';
import { useCohorts } from '@/hooks/query-hooks/use-cohorts';
import { Permission } from '@/types/auth';
import { getErrorMessage } from '@/utils/get-error-message';
import { Cohort } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import { Edit01, SearchRefraction } from '@untitled-ui/icons-react';
import { useMemo } from 'react';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

function Cohorts() {
  const navigate = useNavigate();
  const { isLoading, data: cohorts, error } = useCohorts();

  const columnHelper = createColumnHelper<Cohort>();

  const handleEdit = (id: string) => () => {
    const redirectUrl = generatePath(staffRouterPath.EDIT_COHORT, {
      id,
    });
    navigate(redirectUrl);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        cell: info => <span className="font-medium">{info.getValue()}</span>,
        header: () => <span>Name</span>,
        enableSorting: true,
        sortingFn: 'text',
        size: 400,
      }),
      columnHelper.accessor('isUsedForIntake', {
        cell: info => (
          <Tag type="outline">
            {info.row.original.isUsedForIntake ? 'Yes' : 'No'}
          </Tag>
        ),
        header: () => <span>Uses Intakes</span>,
      }),
      columnHelper.display({
        id: 'id',
        cell: info => (
          <button
            type="button"
            className="edit-ratio-tooltip rounded p-2"
            onClick={handleEdit(info.row.original.id)}
          >
            <Edit01
              className="h-5 w-5"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
            <span className="sr-only">Edit Cohort</span>
          </button>
        ),
        size: 72,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <PageTitle
        title="Cohorts"
        description="Specify groups of children"
        variant="white"
      >
        <PermissionGuard
          requiredPermissions={[Permission['organisation:create']]}
        >
          <Link
            type="button"
            className="btn btn-primary"
            to={staffRouterPath.NEW_COHORT}
          >
            Add Cohort
          </Link>
        </PermissionGuard>
      </PageTitle>

      <Tooltip
        anchorSelect=".edit-cohort-tooltip"
        content="Edit Cohort"
        place="top"
        className="tooltip"
      />
      <Table
        columns={columns}
        data={cohorts}
        paginationType="auto"
        isLoading={isLoading}
        error={getErrorMessage(error)}
        EmptyState={
          <div className="py-12 text-center">
            <SearchRefraction
              className="mx-auto h-8 w-8 text-gray-400"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              No Cohort Found
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              You do not currently have any cohorts added. Please add your first
              cohort.
            </p>
          </div>
        }
      />
    </>
  );
}

export { Cohorts };
