import { useExtraHoursBookingsControl } from '@/context/extra-hours-bookings-control.context';
import { useSchoolYear } from '@/context/school-year.context';
import { useExportBookingsByChildrenToCSV } from '@/hooks/query-hooks/use-bookings-by-children';
import { FileDownload03, Loading01 } from '@untitled-ui/icons-react';
import { twMerge } from 'tailwind-merge';

function ExtraHoursExportButton() {
  const { filter, sorting, pagination } = useExtraHoursBookingsControl();
  const { schoolYear } = useSchoolYear();

  const { refetch: exportResultToCSV, isFetching: isExporting } =
    useExportBookingsByChildrenToCSV(
      schoolYear.id,
      { pagination, filter, sorting },
      { enabled: false }
    );

  const handleExportToCSV = async () => {
    const result = await exportResultToCSV();

    if (!result.isSuccess) {
      return;
    }

    const {
      URL: { createObjectURL, revokeObjectURL },
      setTimeout,
    } = window;

    const blob = new Blob([result.data], { type: 'text/csv' });
    const url = createObjectURL(blob);

    const anchor = document.createElement('a');
    anchor.setAttribute('href', url);
    anchor.setAttribute('download', `extra-hours-booking-${Date.now()}`);
    anchor.click();

    setTimeout(() => {
      revokeObjectURL(url);
    }, 100);
  };

  return (
    <button
      className="btn btn-secondary btn-icon flex items-center gap-1"
      disabled={isExporting}
      type="button"
      onClick={handleExportToCSV}
    >
      {isExporting ? (
        <Loading01
          className="leading-icon h-5 w-5 animate-spin"
          aria-hidden="true"
        />
      ) : (
        <FileDownload03
          aria-hidden="true"
          className={twMerge(
            'leading-icon h-5 w-5',
            isExporting ? 'text-gray-400' : 'text-gray-700'
          )}
        />
      )}
      Export to CSV
    </button>
  );
}

export { ExtraHoursExportButton };
