import { deleteCondition } from '@/utils/reporting-utils';
import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Condition } from './condition';
import { ConditionTag } from './condition-tag';
import { QueryBuilderFormData } from './reporting.type';

type ConditionGroupProps = {
  index: number;
  id: string;
  variant: 'and' | 'or';
};

function ConditionGroup(props: ConditionGroupProps) {
  const { index, variant, id } = props;
  const { control, getValues, setValue } =
    useFormContext<QueryBuilderFormData>();

  const { fields: conditions } = useFieldArray({
    name: `conditionGroups.${index}.data`,
    control,
    keyName: 'uid',
  });

  const { setNodeRef } = useDroppable({
    id: id,
  });

  const handleDeleteAndCondition =
    ({ conditionIndex }: { conditionIndex: number }) =>
    () => {
      const formState = getValues();
      const newConditionGroups = deleteCondition({
        conditionGroups: formState.conditionGroups,
        groupIndex: index,
        conditionIndex,
      });
      setValue('conditionGroups', newConditionGroups);
    };

  return (
    <SortableContext
      items={conditions}
      strategy={verticalListSortingStrategy}
      id={id}
    >
      <div className="space-y-4" ref={setNodeRef}>
        {conditions.map((condition, conditionIndex) => (
          <div key={condition.uid}>
            <Condition
              id={condition.id}
              groupIndex={index}
              conditionIndex={conditionIndex}
              onRemove={handleDeleteAndCondition({ conditionIndex })}
            />
            {conditionIndex !== conditions.length - 1 && (
              <ConditionTag>{variant === 'and' ? 'And' : 'Or'}</ConditionTag>
            )}
          </div>
        ))}
      </div>
    </SortableContext>
  );
}

export { ConditionGroup };
