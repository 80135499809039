import { ChildLookedAfter } from '@admissions-support/types';
import { format } from 'date-fns';
import { startCase } from 'lodash-es';
import { Detail } from './detail';

type ApplicantDetailsProps = {
  dateOfBirth: string | Date;
  sex: string;
  lookedAfterStatus: ChildLookedAfter;
};

function ApplicantDetails(props: ApplicantDetailsProps) {
  const { dateOfBirth, sex, lookedAfterStatus } = props;
  const relationship = startCase(lookedAfterStatus.relationship?.toLowerCase());
  const careLocation = startCase(lookedAfterStatus.careLocation?.toLowerCase());

  return (
    <div className="space-y-4">
      <Detail
        label="Date of birth"
        content={format(dateOfBirth, 'dd/MM/yyyy')}
      />
      <Detail label="Sex" content={sex} />
      {(relationship || careLocation) && (
        <Detail
          label="Looked After Status"
          content={
            <>
              <p>{relationship}</p>
              <p>{careLocation}</p>
            </>
          }
        />
      )}
    </div>
  );
}

export { ApplicantDetails };
