import { messagingKey } from '@/config/query-keys';
import { queryClient } from '@/libs/react-query';
import { messagingService } from '@/services/messaging.service';
import { HttpError } from '@/types/error';
import { Ticket, UpdateTicketStatus } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateTicketStatus(
  id: string,
  mutationOptions?: Partial<
    UseMutationOptions<
      Ticket,
      HttpError,
      { status: UpdateTicketStatus['status'] }
    >
  >
) {
  return useMutation({
    mutationFn: ({ status }) => messagingService.updateTicketStatus(id, status),
    mutationKey: messagingKey.updateTicketStatus,
    ...mutationOptions,
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes(messagingKey.all);
        },
      });
    },
  });
}

export { useUpdateTicketStatus };
