import { staffRouterPath } from '@/config/route-paths.config';
import { Application, UpdateApplicationDto } from '@admissions-support/types';
import { ArrowLeft, ArrowRight } from '@untitled-ui/icons-react';
import { FormProvider } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { isAdditionalDetailsDisabled } from '../application-navigation';
import { Choices } from '../application/choices';
import { PreSchool } from '../application/placement-preferences/placement-preferences.element';
import { usePlacementPreferencesForm } from '../application/placement-preferences/use-placement-preferences.form';

type PlacementPreferencesFormProps = {
  onSubmit: (data: UpdateApplicationDto) => any;
  initialData: Application;
  locations: { key: string; value: string }[];
};

function PlacementPreferencesForm(props: PlacementPreferencesFormProps) {
  const { initialData, onSubmit, locations } = props;
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const { form, handleSubmit, saveAsDraft } = usePlacementPreferencesForm({
    initialData,
    onSubmit,
  });
  const isFormDisabled = form.formState.isSubmitting;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={saveAsDraft}
        className="mt-6"
        id="upsert-application-form"
      >
        <div className="two-col-form">
          <PreSchool disabled={isFormDisabled} locations={locations} />
        </div>
      </form>

      <div className="two-col-form mt-6">
        <Choices disabled={isFormDisabled} />
      </div>
      <div className="mt-6 flex flex-col space-y-4 sm:flex-row sm:justify-between sm:space-y-0">
        <button
          className="btn btn-secondary flex items-center"
          type="button"
          onClick={() =>
            navigate(
              generatePath(staffRouterPath.APPLICATION_HOUSEHOLD_DETAILS, {
                id: params.id || '',
              })
            )
          }
        >
          <ArrowLeft
            viewBox="0 0 24 24"
            className="mr-2 h-5 w-5 text-gray-500"
          />
          Return to Previous Section
        </button>
        <button
          className="btn btn-primary flex items-center"
          type="button"
          onClick={handleSubmit}
          disabled={isFormDisabled || isAdditionalDetailsDisabled(initialData)}
        >
          Continue to Next Session
          <ArrowRight viewBox="0 0 24 24" className="ml-2 h-5 w-5 text-white" />
        </button>
      </div>
    </FormProvider>
  );
}

export { PlacementPreferencesForm };
