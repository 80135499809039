import { extraHourKey } from '@/config/query-keys';
import { locationService } from '@/services/location.service';
import { HttpError } from '@/types/error';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useDeleteExtraHourResourceMutation(
  mutationOptions?: UseMutationOptions<
    void,
    HttpError,
    { operationId: string; resourceId: string }
  >
) {
  return useMutation({
    mutationKey: extraHourKey.deleteExtraHour,
    mutationFn: ({ operationId, resourceId }) =>
      locationService.deleteExtraHourResource({ operationId, resourceId }),
    ...mutationOptions,
  });
}

export { useDeleteExtraHourResourceMutation };
