import { cohortKey } from '@/config/query-keys';
import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import { Cohort } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useCohort(
  id?: string,
  queryOptions?: UseQueryOptions<Cohort, HttpError>
) {
  return useQuery({
    queryKey: cohortKey.single(id || ''),
    queryFn: () => organisationService.getCohort(id || ''),
    ...queryOptions,
    enabled: !!id,
  });
}

export { useCohort };
