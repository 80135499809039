import { userKeys } from '@/config/query-keys';
import { userService } from '@/services/user.service';
import { HttpError } from '@/types/error';
import { UpdateUserDto, User } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateUserMutation(
  mutationOptions?: UseMutationOptions<
    User,
    HttpError,
    { id: string; data: UpdateUserDto }
  >
) {
  return useMutation({
    mutationKey: userKeys.suspend,
    mutationFn: userService.updateUser,
    ...mutationOptions,
  });
}

export { useUpdateUserMutation };
