import { PageTitle } from '@/components/page-title';
import { AdditionalQuestionList } from '@/components/settings/application-templates/additional-question-list';
import { ApplicationTemplateNavigation } from './application-template-navigation';

function AdditionalQuestionsApplicationTemplate() {
  return (
    <div>
      <PageTitle
        title="Application Template Additional Questions"
        variant="gray"
      />
      <ApplicationTemplateNavigation />

      <div className="flex h-full flex-1 flex-col">
        <div className="mt-6 bg-white pt-6">
          <div className="two-col-form pt-0">
            <div>
              <h2 className="text-md font-medium leading-7 text-gray-900">
                Additional Questions
              </h2>
              <p className="text-md leading-6 text-gray-600">
                You can add additional questions to the end of the application
                form.
              </p>
            </div>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-12">
                <div className="space-y-6">
                  <AdditionalQuestionList />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { AdditionalQuestionsApplicationTemplate };
