import { Box } from '@/components/box';
import { StaffCreateMessageForm } from '@/components/form/staff-create-message.form';
import { PageTitle } from '@/components/page-title';
import { staffRouterPath } from '@/config/route-paths.config';
import { Link } from 'react-router-dom';

function CreateMessage() {
  return (
    <div>
      <PageTitle title="New Ticket" variant="gray">
        <Link to={staffRouterPath.MESSAGING} className="btn btn-secondary">
          Return to Messaging
        </Link>
      </PageTitle>
      <div className="mt-6 flex w-full min-w-full justify-between gap-8 py-6">
        <div className="flex w-full justify-center gap-4">
          <Box className="w-full max-w-[800px]">
            <StaffCreateMessageForm />
          </Box>
        </div>
      </div>
    </div>
  );
}

export default CreateMessage;
