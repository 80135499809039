import {
  CapacitySimulation,
  CapacitySimulationContextState,
  SetSimulatedCapacityProps,
} from '@/types/capacity-simulation';
import { ReportingOutputControl } from '@/types/reporting-output-control';
import { DeepPartial } from '@/types/util';
import { DtoSimulateMatchingLocation } from '@admissions-support/types';
import { createContext, useContext, useMemo, useState } from 'react';

const defaultCapacitySimulation: CapacitySimulation = {
  visibleReport: 'capacity',
  simulatedCapacity: [],
  dataUpdatedAt: 0,
};

const CapacitySimulationContext = createContext<
  CapacitySimulationContextState | undefined
>(undefined);

function CapacitySimulationProvider({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
  defaultControl?: DeepPartial<ReportingOutputControl>;
}) {
  const [simulatedCapacity, setSimulatedCapacity] = useState<
    DtoSimulateMatchingLocation[]
  >([]);

  const [visibleReport, setVisibleReport] = useState<'capacity' | 'demand'>(
    'capacity'
  );

  const [dataUpdatedAt, setDataUpdatedAt] = useState<number>(0);

  const handleSimulatedCapacityUpdate = ({
    locationId,
    capacity,
    sessionTypeId,
    day,
  }: SetSimulatedCapacityProps) => {
    setSimulatedCapacity(prevCapacity => {
      const locationIndex = prevCapacity.findIndex(
        item => item.locationId === locationId
      );

      if (locationIndex !== -1) {
        // If locationId exists, update the existing entry
        const updatedCapacity: DtoSimulateMatchingLocation[] = [
          ...prevCapacity,
        ];
        const location: DtoSimulateMatchingLocation = {
          ...updatedCapacity[locationIndex],
        };

        const capacityForSessionTypeIndex = location.capacity.findIndex(
          capacity => capacity.sessionTypeId === sessionTypeId
        );

        if (capacityForSessionTypeIndex !== -1) {
          location.capacity[capacityForSessionTypeIndex] = {
            ...location.capacity[capacityForSessionTypeIndex],
            [day]: capacity,
          };
        } else {
          location.capacity.push({
            sessionTypeId,
            [day]: capacity,
          });
        }

        return updatedCapacity;
      }
      // If locationId doesn't exist, add a new entry
      return [
        ...prevCapacity,
        {
          locationId,
          capacity: [
            {
              sessionTypeId,
              [day]: capacity,
            },
          ],
        },
      ];
    });
  };

  const resetSimulatedCapacityForLocation = (locationId: string) => {
    setSimulatedCapacity(prevCapacity =>
      prevCapacity.filter(resource => resource.locationId !== locationId)
    );
  };

  const updatedCapacitySimulation: CapacitySimulation = useMemo(
    () => ({
      simulatedCapacity,
      visibleReport,
      dataUpdatedAt,
    }),
    [simulatedCapacity, visibleReport, dataUpdatedAt]
  );

  return (
    <CapacitySimulationContext.Provider
      value={{
        ...updatedCapacitySimulation,
        setSimulatedCapacity: handleSimulatedCapacityUpdate,
        resetSimulatedCapacityForLocation,
        setVisibleReport,
        setDataUpdatedAt,
        hasSimulatedCapacity: simulatedCapacity.length > 0,
      }}
    >
      {children}
    </CapacitySimulationContext.Provider>
  );
}

const useCapacitySimulation = () => {
  const context = useContext(CapacitySimulationContext);

  if (!context) {
    throw new Error(
      'useCapacitySimulation must be used within a CapacitySimulationProvider'
    );
  }
  return context;
};

export {
  CapacitySimulationProvider,
  defaultCapacitySimulation,
  useCapacitySimulation,
};
