import { RatioForm } from '@/components/form/ratio.form';
import { PageTitle } from '@/components/page-title';
import { Spinner } from '@/components/spinner';
import { organisationKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useCreateRatioMutation } from '@/hooks/create-hooks/use-create-ratio-mutation';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useRatio } from '@/hooks/query-hooks/use-ratio';
import { useUpdateRatioMutation } from '@/hooks/update-hooks/use-update-ratio-mutation';
import { queryClient } from '@/libs/react-query';
import { Permission } from '@/types/auth';
import { CreateRatioDto, UpdateRatioDto } from '@admissions-support/types';
import { useEffect } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

async function invalidateRatiosQuery() {
  await queryClient.invalidateQueries({
    queryKey: organisationKey.ratios,
    refetchType: 'active',
  });
}

function UpsertRatio() {
  const navigate = useNavigate();
  const { mutateAsync: create, isPending: isCreating } = useCreateRatioMutation(
    {
      onSuccess: invalidateRatiosQuery,
    }
  );
  const { mutateAsync: update, isPending: isUpdating } = useUpdateRatioMutation(
    {
      onSuccess: invalidateRatiosQuery,
    }
  );
  const params = useParams<{ id?: string }>();
  const isCreateRatioView = useMatch(staffRouterPath.NEW_RATIO);

  const { isLoading: isRatioLoading, data: ratio, error } = useRatio(params.id);

  useEffect(() => {
    if (error) {
      navigate(staffRouterPath.RATIOS);
    }
  }, [error, navigate]);

  const { hasPermission } = usePermissions();

  const isMutating =
    isCreating ||
    isUpdating ||
    !hasPermission([Permission['organisation:update']]);

  const handleCreateRatio = async (data: CreateRatioDto) => {
    await create(data);
    navigate(staffRouterPath.RATIOS);
  };

  const handleUpdateRatio = async (data: UpdateRatioDto) => {
    await update({
      id: params.id || '',
      data,
    });
  };

  const handleCancel = () => {
    navigate(staffRouterPath.RATIOS);
  };

  return (
    <>
      <PageTitle
        title={isCreateRatioView ? 'New Ratio' : 'Edit Ratio'}
        variant="gray"
      >
        <div className="flex gap-3">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCancel}
            disabled={isMutating}
          >
            Cancel
          </button>

          <button
            className="btn btn-primary"
            disabled={isMutating}
            form="upsert-ratio-form"
          >
            {isCreateRatioView ? 'Create' : 'Update'}
          </button>
        </div>
      </PageTitle>

      {isRatioLoading && !isCreateRatioView ? (
        <div className="flex justify-center py-12">
          <Spinner className="h-12 w-12" />
        </div>
      ) : (
        <RatioForm
          onSubmit={isCreateRatioView ? handleCreateRatio : handleUpdateRatio}
          initialData={ratio}
          isLoading={isMutating}
        />
      )}
    </>
  );
}

export { UpsertRatio };
