import { staffRouterPath } from '@/config/route-paths.config';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { Permission } from '@/types/auth';
import { NavigationItem } from '@/types/navigation';
import {
  Building07,
  CalendarCheck02,
  CalendarPlus01,
  DotsGrid,
  FileCheck02,
  LayoutAlt03,
  MarkerPin04,
  Percent02,
} from '@untitled-ui/icons-react';

function useSettingsSubnavigation(): NavigationItem[] {
  const { hasPermission } = usePermissions();

  const navigation: NavigationItem[] = [];

  if (hasPermission([Permission['managers']])) {
    navigation.push(
      ...[
        // { name: 'Organisation', href: '#', icon: Building07 },
        // { name: 'Licence', href: '#', icon: Users01 },
        // { name: 'Catchment Areas', href: '#', icon: Map02 },
        {
          name: 'Details',
          href: staffRouterPath.ORGANISATION_DETAILS,
          icon: Building07,
        },
        {
          name: 'Week Models',
          href: staffRouterPath.WEEK_MODELS,
          icon: CalendarCheck02,
        },
        {
          name: 'Session Types',
          href: staffRouterPath.SESSION_TYPES,
          icon: LayoutAlt03,
        },
        {
          name: 'School Years',
          href: staffRouterPath.TERMS,
          icon: CalendarPlus01,
        },
        {
          name: 'Application Forms',
          href: staffRouterPath.APPLICATION_TEMPLATES,
          icon: FileCheck02,
        },
        { name: 'Cohorts', href: staffRouterPath.COHORTS, icon: DotsGrid },
      ]
    );
  }

  if (
    hasPermission([Permission['location:fetch']]) ||
    hasPermission([Permission['reporting']])
  ) {
    navigation.push({
      name: 'Location Grouping',
      href: staffRouterPath.LOCATION_GROUPING,
      icon: MarkerPin04,
    });
  }

  if (hasPermission([Permission['managers']])) {
    navigation.push({
      name: 'Ratios',
      href: staffRouterPath.RATIOS,
      icon: Percent02,
    });
  }

  return navigation;
}

export { useSettingsSubnavigation };
