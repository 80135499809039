import { axiosClient } from '@/libs/axios';
import { HttpError } from '@/types/error';
import {
  ChildPermission,
  CreateTrusteeInvitationDto,
  Invitation,
  TrusteeWithInvitation,
  UpdateTrusteeInvitationDto,
} from '@admissions-support/types';

const getAllByChildId = async (
  childId: string
): Promise<TrusteeWithInvitation[]> => {
  const response = await axiosClient.get<TrusteeWithInvitation[]>(
    `/trustee/${childId}`
  );
  return response.data;
};

const createTrustee = async (
  data: CreateTrusteeInvitationDto
): Promise<Invitation> => {
  const response = await axiosClient.post<
    HttpError,
    Invitation,
    CreateTrusteeInvitationDto
  >(`/invitations/trustee`, data);

  return response;
};

const deleteTrustee = async ({
  childId,
  trusteeId,
}: {
  trusteeId: string;
  childId: string;
}): Promise<void> => {
  await axiosClient.delete(`/trustee/${childId}/${trusteeId}`);
};

const updateTrustee = async ({
  trusteeId,
  childId,
  data,
}: {
  trusteeId: string;
  childId: string;
  data: UpdateTrusteeInvitationDto;
}): Promise<TrusteeWithInvitation> => {
  const response = await axiosClient.patch<TrusteeWithInvitation>(
    `/trustee/${childId}/${trusteeId}`,
    data
  );
  return response.data;
};

const getParentPermission = async (
  childId: string
): Promise<ChildPermission> => {
  const response = await axiosClient.get<ChildPermission>(
    `/parents/permissions/${childId}`
  );
  return response.data;
};

export const trusteeService = Object.freeze({
  getAllByChildId,
  createTrustee,
  deleteTrustee,
  updateTrustee,
  getParentPermission,
});
