import { organisationKey } from '@/config/query-keys';
import { organisationService } from '@/services/organisation.service';
import { HttpError } from '@/types/error';
import { UserGroup } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useUserGroup(
  id?: string,
  queryOptions?: UseQueryOptions<UserGroup, HttpError>
) {
  return useQuery({
    queryKey: organisationKey.userGroup(id || ''),
    queryFn: () => organisationService.getUserGroup(id || ''),
    ...queryOptions,
    enabled: !!id,
  });
}

export { useUserGroup };
