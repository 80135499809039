import * as yup from 'yup';

const makePaymentSchema = yup.object({
  paymentOption: yup.string().label('Payment Option').required(),
  childCareReference: yup
    .string()
    .label('Tax Free Childcare Reference')
    .nullable()
    .when('paymentOption', {
      is: 'TAX_FREE_CHILD_CARE',
      then: schema => schema.required(),
      otherwise: schema => schema,
    }),
  voucherProvider: yup
    .string()
    .label('Voucher Provider')
    .nullable()
    .when('paymentOption', {
      is: 'CHILDCARE_VOUCHERS',
      then: schema => schema.required(),
      otherwise: schema => schema,
    }),
});

export { makePaymentSchema };
