import { trusteeService } from '@/services/trustee.service';
import { HttpError } from '@/types/error';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useDeleteTrusteeMutation(
  mutationOptions?: UseMutationOptions<
    void,
    HttpError,
    { trusteeId: string; childId: string }
  >
) {
  return useMutation({
    mutationFn: trusteeService.deleteTrustee,
    ...mutationOptions,
  });
}

export { useDeleteTrusteeMutation };
