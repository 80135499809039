import { useOrganisationData } from '@/context/organisation-control.context';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

type LogoProps = {
  className?: string;
  isUseIcon?: boolean;
};

function Logo(props: LogoProps) {
  const { className, isUseIcon } = props;

  const { data: organisation } = useOrganisationData();

  if (!organisation?.icon?.key || !organisation?.logo?.key) {
    return <p>{organisation.name}</p>;
  }

  return (
    <Link to="/">
      <img
        className={twMerge('h-12', className)}
        src={`https://${import.meta.env.VITE_BUCKET}.s3.${
          import.meta.env.VITE_AWS_REGION
        }.amazonaws.com/${
          isUseIcon ? organisation.icon.key : organisation.logo.key
        }`}
        alt={organisation.name}
      />
    </Link>
  );
}

export { Logo };
