import { ParentAdditionalChildDetailsForm } from '@/components/application/additional-child-details/parent-additional-child-details.form';
import { useApplication } from '@/hooks/query-hooks/use-application';
import { useApplicationTemplate } from '@/hooks/query-hooks/use-application-template';
import { useUpdateApplicationMutation } from '@/hooks/update-hooks/use-update-application-mutation';
import { useParams } from 'react-router-dom';

function AdditionalChildDetails() {
  const params = useParams<{ id: string }>();

  const { data: application, isSuccess } = useApplication(params.id || '', {
    enabled: Boolean(params.id),
  });

  const { data: applicationTemplate, isSuccess: isApplicationTemplateSuccess } =
    useApplicationTemplate(
      isSuccess ? application.template.id.toString() : '',
      {
        enabled: isSuccess,
      }
    );

  const { mutateAsync: updateApplication } = useUpdateApplicationMutation(
    application?.id || ''
  );

  if (!isSuccess || !isApplicationTemplateSuccess) {
    return null;
  }

  return (
    <div>
      <ParentAdditionalChildDetailsForm
        initialData={application}
        onSubmit={updateApplication}
        template={applicationTemplate}
      />
    </div>
  );
}

export { AdditionalChildDetails };
