import { Child, TransactionType } from '@admissions-support/types';
import { compact, isEmpty, map, startCase, toLower } from 'lodash-es';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormTabButtons, Tab } from '../form-tab-buttons';
import { Select } from '../form/common/select';
import { TextInput } from '../form/common/text-input';
import { Textarea } from '../form/common/textarea';
import { RecordTransactionFormData } from './record-transaction.type';

type RecordTransactionFormProps = {
  child: Child;
};

const transactionNotes: Record<TransactionType, string> = {
  PAYMENT:
    'Payment is used to log transactions from the selected source of funding. This action serves as a log, no funds are physically transferred.',
  REFUND:
    'Refund will record the transfer of funds back to the original payer. This action serves as a log, no funds are physically transferred.',
  CREDIT:
    'Credit will apply a credit to an account, without the transfer of any funds. This action serves as a log, no funds are physically transferred.',
};

function RecordTransactionForm(props: RecordTransactionFormProps) {
  const { child } = props;

  const tabs: Tab[] = [
    { title: 'Payment', fieldValue: 'PAYMENT' },
    { title: 'Refund', fieldValue: 'REFUND' },
    { title: 'Credit', fieldValue: 'CREDIT' },
  ];

  const form = useFormContext<RecordTransactionFormData>();

  const type = form.watch('type');
  const source = form.watch('source');

  // reset form if type changes
  useEffect(() => {
    form.reset({ ...form.formState.defaultValues, type });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  // reset form if type changes
  useEffect(() => {
    form.setValue('sourceDescription', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  const enabledMethods: {
    key: string;
    value: string;
  }[] = compact(
    map(child.extraHours.paymentMethods, (value, key) => {
      return value ? { key, value: startCase(toLower(key)) } : undefined;
    })
  );

  if (!enabledMethods.some(method => method.key === 'OTHER' && method.value)) {
    enabledMethods.push({ key: 'OTHER', value: 'Other' });
  }

  const sourceValues = compact([
    { key: '', value: 'Select...' },
    ...(isEmpty(enabledMethods) ? [] : enabledMethods),
  ]);

  return (
    <div>
      <FormTabButtons fieldName="type" tabs={tabs} />
      <p className="mb-5 mt-2 text-sm text-gray-600">
        {transactionNotes[type]}
      </p>
      <div className="space-y-6 pb-8">
        {type !== 'CREDIT' ? (
          <div className="flex gap-6">
            <TextInput
              name="reference"
              label="Payment Reference*"
              placeholder="ID"
              type="text"
              className="flex-1"
            />
            <TextInput
              prefix="£"
              type="number"
              step=".01"
              label={
                type === 'PAYMENT' ? 'Amount Received*' : 'Amount Refunded*'
              }
              name="amount"
              placeholder="00.00"
              className="flex-1"
            />
          </div>
        ) : null}
        <div className="flex gap-6">
          {type === 'CREDIT' ? (
            <TextInput
              prefix="£"
              type="number"
              step=".01"
              label="Amount Credited*"
              name="amount"
              placeholder="00.00"
              className="flex-1"
            />
          ) : (
            <TextInput
              name="date"
              type="date"
              label="Transaction Date*"
              className="flex-1"
            />
          )}
          {type === 'PAYMENT' ? (
            <Select
              name="source"
              options={sourceValues}
              label="Source of Funding*"
              className="flex-1"
            />
          ) : null}
        </div>
        {type === 'PAYMENT' && source === 'OTHER' ? (
          <TextInput
            name="sourceDescription"
            type="text"
            placeholder="Describe your source of funding.."
            className="flex-1"
            label="Source Description*"
          />
        ) : null}
        <Textarea
          name="notes"
          label={
            type === 'PAYMENT'
              ? 'Notes'
              : type === 'CREDIT'
              ? 'Reason for Credit'
              : 'Reason for Refund'
          }
          placeholder="Enter a description..."
          rows={5}
        />
      </div>
    </div>
  );
}

export { RecordTransactionForm };
