import { useAuth } from '@/context/auth.context';
import { useModal } from '@/hooks/use-modal';
import { Modal } from '../modal';

function LogOut() {
  const { user, isLoading, logOut } = useAuth();
  const { isOpen, openModal, closeModal } = useModal();

  const handleLogout = async () => {
    await logOut();
  };

  if (isLoading || !user) {
    return null;
  }

  return (
    <div className="text-center text-sm text-gray-500">
      <Modal
        open={isOpen}
        onClose={closeModal}
        title="Confirm Log Out"
        description="Are you sure you want to log out? Logging out will end your current session and you will need to sign in again to access your account."
        primaryBtnText="Log Out"
        primaryAction={handleLogout}
        secondaryBtnText="Cancel"
        secondaryAction={closeModal}
        type="warning"
      />
      <p>
        You are logged in as {user.givenName} {user.familyName}.{' '}
        <button
          type="button"
          onClick={openModal}
          className="italic hover:underline"
        >
          Log out?
        </button>
      </p>
    </div>
  );
}

export { LogOut };
