type DeepKeys<T> = T extends (infer U)[]
  ? T extends object
    ? number | `${number}.${DeepKeys<U>}`
    : never
  : T extends object
  ? {
      [K in keyof T]-?: K extends string | number
        ? `${K}` | `${K}.${DeepKeys<T[K]>}`
        : never;
    }[keyof T]
  : never;

/**
 * Retrieves all nested keys of an object.
 *
 * @template T - The type of the object.
 * @param {T} obj - The object to retrieve the nested keys from.
 * @param {string} [prefix=''] - The prefix to use for nested keys.
 * @returns {DeepKeys<T>[]} - An array of nested keys.
 *
 * @example
 * // Example usage
 * const exampleObj = {
 *   name: 'John',
 *   age: 30,
 *   address: {
 *     street: '123 Main St',
 *     city: 'New York',
 *     postalCode: '10001',
 *   },
 *   hobbies: ['reading', 'playing guitar'],
 * };
 *
 * const keys = getNestedKeys(exampleObj);
 * console.log(keys);
 * // Output: ['name', 'age', 'address', 'address.street', 'address.city', 'address.postalCode', 'hobbies']
 */
export function getNestedKeys<T extends object>(
  obj: T,
  prefix = ''
): DeepKeys<T>[] {
  return Object.entries(obj).reduce((result: string[], [key, value]) => {
    const newPrefix = prefix ? `${prefix}.${key}` : key;

    return result.concat([
      String(newPrefix),
      ...(value && typeof value === 'object'
        ? getNestedKeys(value as any, String(newPrefix)).map(String)
        : []),
    ]);
  }, []) as DeepKeys<T>[];
}
