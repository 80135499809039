import { TextInput } from '@/components/form/common/text-input';
import { PermissionGroupItem } from '@/components/permission-group-item';
import { permissionGroups } from '@/config/permission-groups';
import { Permission } from '@/types/auth';
import { HttpError } from '@/types/error';
import { ICreateUserGroupDto } from '@/types/user-group';
import { UserGroup } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { uniq } from 'lodash-es';
import { ChangeEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

const schema = yup.object({
  name: yup.string().required().label('Name'),
  description: yup.string().nullable().label('Description'),
  //TODO: once the tpyes package is fixed, update to use oneOf(Object.values(OrganisationPermission))
  permissions: yup.array().of(yup.string().required()).required(),
});

type UserGroupFormProps = {
  onSubmit: (data: ICreateUserGroupDto) => Promise<void>;
  initialData?: UserGroup;
  isLoading?: boolean;
};

function UserGroupForm(props: UserGroupFormProps) {
  const { initialData, isLoading, onSubmit } = props;

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: initialData?.name || '',
      description: initialData?.description || '',
      permissions: initialData?.permissions || [],
    },
  });

  const permissions = form.watch('permissions');

  const submitHandler = async (data: ICreateUserGroupDto) => {
    try {
      await onSubmit(data);
    } catch (error) {
      const httpError = error as HttpError;

      if (httpError.statusCode === 409) {
        form.setError('name', { message: httpError.message });
      }
    }
  };

  const shouldOrganisationListBeSelected = (permission: string) => {
    return (
      permission === Permission['application:fetch'] ||
      permission === Permission['location:fetch']
    );
  };

  const handleSelectAll = (selectAll: boolean, value: string[]) => () => {
    if (selectAll) {
      form.setValue('permissions', uniq([...permissions, ...value]));
    } else {
      const filteredPermissions = permissions.filter(
        permission => !value.includes(permission)
      );

      // dont let unselect if dependents are selected
      if (permissions.some(shouldOrganisationListBeSelected)) {
        filteredPermissions.push(Permission['managers']);
      }
      form.setValue('permissions', filteredPermissions);
    }
  };

  const handlePermissionSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const permissionValue = event.target.name;

    if (event.target.checked) {
      // if application:fetch or location:fetch are selected, select setting list as well,
      // since we need that setting to create a location view/application view
      if (shouldOrganisationListBeSelected(permissionValue)) {
        form.setValue('permissions', [
          ...permissions,
          permissionValue,
          Permission['managers'],
        ]);
      } else {
        form.setValue('permissions', [...permissions, permissionValue]);
      }
    } else {
      const filteredPermissions = permissions.filter(
        permission => permission !== permissionValue
      );

      form.setValue('permissions', filteredPermissions);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(submitHandler)}
        className="flex h-full flex-1 flex-col"
        id="upsert-user-group-form"
      >
        <div className="mt-6 bg-white pt-6">
          <div className="two-col-form pt-0">
            <div>
              <h2 className="text-md font-medium leading-7 text-gray-900">
                Details
              </h2>
              <p className="text-md leading-6 text-gray-600">
                Specify permission group details
              </p>
            </div>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="space-y-6 sm:col-span-4">
                <TextInput
                  name="name"
                  type="text"
                  label="Group Name"
                  disabled={isLoading}
                />
                <TextInput
                  name="description"
                  type="text"
                  label="Description"
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>

          {permissionGroups.map(permissionGroup => (
            <PermissionGroupItem
              key={permissionGroup.name}
              data={permissionGroup}
              selectedPermissions={permissions}
              onSelectAll={handleSelectAll}
              onPermissionSelect={handlePermissionSelect}
              disabled={isLoading}
            />
          ))}
        </div>
      </form>
    </FormProvider>
  );
}

export { UserGroupForm };
