import { InputHTMLAttributes } from 'react';
import { get, useFormContext } from 'react-hook-form';

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string | React.ReactNode;
  options: { value: string; label: string }[];
}

function Radio(props: RadioProps) {
  const { label, name, options, className, ...rest } = props;
  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext();

  const error = get(errors, name);
  const hasError = Boolean(error);

  return (
    <div className={className}>
      <label className="label">{label}</label>
      <fieldset className="mt-4">
        <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
          {options.map(option => (
            <div key={option.value} className="flex items-center">
              <input
                id={option.value}
                type="radio"
                className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600"
                value={option.value}
                disabled={isSubmitting}
                {...register(name)}
                {...rest}
              />
              <label
                htmlFor={option.value}
                className="ml-3 block cursor-pointer text-sm font-medium leading-6 text-gray-700"
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
      {hasError ? (
        <p className="mt-1.5 text-sm text-red-600">
          {error?.message?.toString()}
        </p>
      ) : null}
    </div>
  );
}

export { Radio };
