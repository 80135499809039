import { DeleteZone } from '@/components/delete-zone';
import { LoadingScreen } from '@/components/loading-screen';
import { PageTitle } from '@/components/page-title';
import {
  invalidateApplicationTemplatesQuery,
  UpdateOverviewForm,
} from '@/components/settings/application-templates/update-overview-form';
import { staffRouterPath } from '@/config/route-paths.config';
import { useDeleteApplicationTemplateMutation } from '@/hooks/delete-hooks/use-delete-application-template';
import { useApplicationTemplate } from '@/hooks/query-hooks/use-application-template';
import { getErrorMessage } from '@/utils/get-error-message';
import { useNavigate, useParams } from 'react-router-dom';
import { ApplicationTemplateNavigation } from './application-template-navigation';

function UpdateApplicationTemplate() {
  const navigate = useNavigate();
  const params = useParams<{ id?: string }>();

  const {
    data: applicationTemplate,
    isLoading: isApplicationTemplateLoading,
    isSuccess: isApplicationTemplateLoaded,
  } = useApplicationTemplate(params.id || '');

  const {
    mutateAsync: deleteApplicationTemplate,
    isPending: isDeleting,
    error: deleteError,
  } = useDeleteApplicationTemplateMutation({
    retry: 0,
  });

  const handleCancel = () => {
    navigate(staffRouterPath.APPLICATION_TEMPLATES);
  };

  const handleDelete = async () => {
    await deleteApplicationTemplate(params.id || '');
    await invalidateApplicationTemplatesQuery();
    handleCancel();
  };

  return (
    <div>
      <PageTitle title="Edit Form" variant="gray">
        <div className="flex gap-3">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCancel}
          >
            Cancel
          </button>

          <button className="btn btn-primary" form="update-overview-form">
            Update
          </button>
        </div>
      </PageTitle>
      <ApplicationTemplateNavigation />
      {isApplicationTemplateLoading && <LoadingScreen className="mt-12" />}
      {isApplicationTemplateLoaded && (
        <>
          <UpdateOverviewForm initialData={applicationTemplate} />
          <div className="two-col-form">
            <div>
              <p className="text-md font-medium leading-7 text-gray-900">
                Destructive Actions
              </p>
              <p className="text-md leading-6 text-gray-600">
                Take care with these actions as they are destructive and may not
                be reversable.
              </p>
            </div>
            <div className="col-span-2 max-w-2xl space-y-6">
              <DeleteZone
                title="Delete Application Form"
                actionButtonText="Delete Application Form"
                onDelete={handleDelete}
                isLoading={isDeleting}
                error={getErrorMessage(deleteError)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export { UpdateApplicationTemplate };
