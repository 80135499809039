import {
  RadioBoxOption,
  RadioBoxOptionProps,
} from '@/components/form/common/radio-box';
import { ExtraHours } from '@admissions-support/types';
import { twMerge } from 'tailwind-merge';
import { PaymentOptions } from './payment-options.enum';

function PaymentOption(
  props: RadioBoxOptionProps & {
    name: string;
    description: string;
    muted?: string;
  }
) {
  const { checked, name, description, muted } = props;

  return (
    <div>
      <span
        className={twMerge(
          checked ? 'text-primary-800' : 'text-gray-700',
          'block text-sm font-medium'
        )}
      >
        {name} {muted && <span className="font-normal">{muted}</span>}
      </span>
      <span className="block text-sm text-gray-600">{description}</span>
    </div>
  );
}

function getAvailablePaymentOptions(
  paymentMethods: ExtraHours['paymentMethods']
): RadioBoxOption[] {
  const availablePaymentOptions: RadioBoxOption[] = [];
  if (paymentMethods.EPAY_CAPITA) {
    availablePaymentOptions.push({
      value: 'EPAY_CAPITA',
      Content: props => (
        <PaymentOption
          {...props}
          name={PaymentOptions['EPAY_CAPITA'].name}
          muted={PaymentOptions['EPAY_CAPITA'].muted}
          description={PaymentOptions['EPAY_CAPITA'].description}
        />
      ),
    });
  }

  if (paymentMethods.TAX_FREE_CHILD_CARE) {
    availablePaymentOptions.push({
      value: 'TAX_FREE_CHILD_CARE',
      Content: props => (
        <PaymentOption
          {...props}
          name={PaymentOptions['TAX_FREE_CHILD_CARE'].name}
          muted={PaymentOptions['TAX_FREE_CHILD_CARE'].muted}
          description={PaymentOptions['TAX_FREE_CHILD_CARE'].description}
        />
      ),
    });
  }

  if (paymentMethods.CHILDCARE_VOUCHERS) {
    availablePaymentOptions.push({
      value: 'CHILDCARE_VOUCHERS',
      Content: props => (
        <PaymentOption
          {...props}
          name={PaymentOptions['CHILDCARE_VOUCHERS'].name}
          muted={PaymentOptions['CHILDCARE_VOUCHERS'].muted}
          description={PaymentOptions['CHILDCARE_VOUCHERS'].description}
        />
      ),
    });
  }

  if (paymentMethods.WEST_LOTHIAN_COLLEGE) {
    availablePaymentOptions.push({
      value: 'WEST_LOTHIAN_COLLEGE',
      Content: props => (
        <PaymentOption
          {...props}
          name={PaymentOptions['WEST_LOTHIAN_COLLEGE'].name}
          muted={PaymentOptions['WEST_LOTHIAN_COLLEGE'].muted}
          description={PaymentOptions['WEST_LOTHIAN_COLLEGE'].description}
        />
      ),
    });
  }
  return availablePaymentOptions;
}

export { getAvailablePaymentOptions };
