import { locationKey } from '@/config/query-keys';
import { useSchoolYear } from '@/context/school-year.context';
import { useDeleteSessionMutation } from '@/hooks/delete-hooks/use-delete-session-mutation';
import { useModal } from '@/hooks/use-modal';
import { queryClient } from '@/libs/react-query';
import { getErrorMessage } from '@/utils/get-error-message';
import { Trash01 } from '@untitled-ui/icons-react';
import { useParams } from 'react-router-dom';
import { Modal } from './modal';

type DeleteSessionProps = {
  sessionId: string;
};

function DeleteSession(props: DeleteSessionProps) {
  const { sessionId } = props;
  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();
  const params = useParams<{ locationId: string }>();
  const { schoolYear } = useSchoolYear();
  const {
    mutateAsync: deleteSession,
    isPending,
    error,
  } = useDeleteSessionMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: locationKey.listSessions(
          params.locationId || '',
          schoolYear.id
        ),
        refetchType: 'active',
      });
    },
  });

  return (
    <>
      <button
        className="btn btn-error ml-auto flex items-center"
        onClick={openDeleteModal}
        type="button"
      >
        <Trash01
          className="mr-2 h-5 w-5"
          viewBox="0 0 24 24"
          aria-hidden="true"
        />
        Delete Session
      </button>
      <Modal
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        title="Confirm delete Session"
        primaryBtnText="Confirm"
        primaryAction={() => deleteSession({ sessionId })}
        secondaryBtnText="Cancel"
        secondaryAction={closeDeleteModal}
        type="error"
        error={getErrorMessage(error)}
        isLoading={isPending}
      />
    </>
  );
}

export { DeleteSession };
