import { CreatableSelect } from '@/components/form/common/creatable-select';
import { Spinner } from '@/components/spinner';
import { childKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useChildAssignationMeta } from '@/hooks/query-hooks/use-child-assignation-meta';
import { useTrustees } from '@/hooks/query-hooks/use-trustee';
import { useAssignChildMutation } from '@/hooks/update-hooks/use-assign-child-mutation';
import { useBulkEmailApplicantsMutation } from '@/hooks/use-bulk-message-applications-mutation';
import { queryClient } from '@/libs/react-query';
import {
  AssignationMeta,
  BulkEmailApplicantsDto,
  ChildCarer,
  EmailTemplateEnum,
} from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { generatePath, Link } from 'react-router-dom';
import * as yup from 'yup';
import { ApplicationStatusTag } from '../application-status-tag';
import { Detail } from './detail';

type Option = { label: string; value: string };

const assignationSchema = yup.object({
  assignedUser: yup
    .object({
      value: yup.string().email().label('Email').required(),
      label: yup.string().required(),
    })
    .nullable(),
});

type AssignationProps = {
  childId: string;
  carers: ChildCarer[];
  applicationId: string;
};

type AssignationFormData = yup.InferType<typeof assignationSchema>;

function transformApiDataToFormData({
  assignationMeta,
  carers,
}: {
  assignationMeta?: AssignationMeta;
  carers: ChildCarer[];
}): AssignationFormData {
  if (!assignationMeta) {
    return {
      assignedUser: null,
    };
  }

  const selectedCarer = carers.find(
    carer => carer.email === assignationMeta.email
  );

  // we defined a random email without an actual name
  if (!selectedCarer) {
    return {
      assignedUser: {
        label: assignationMeta.name,
        value: assignationMeta.email,
      },
    };
  }

  return {
    assignedUser: {
      label: `${selectedCarer.firstName} ${selectedCarer.lastName} (${selectedCarer.email})`,
      value: assignationMeta.email,
    },
  };
}

function Assignation({
  childId,
  applicationId,
  carers = [],
}: AssignationProps) {
  const {
    data: assignationMeta,
    isLoading: isAssignationMetaLoading,
    isSuccess: isAssignationMetaSuccess,
  } = useChildAssignationMeta(childId);

  const { data: trustees, isSuccess: isTrusteesSuccess } = useTrustees(childId);

  const form = useForm({
    defaultValues: transformApiDataToFormData({ assignationMeta, carers }),
    resolver: yupResolver(assignationSchema),
  });

  useEffect(() => {
    if (isAssignationMetaSuccess) {
      form.reset(transformApiDataToFormData({ assignationMeta, carers }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAssignationMetaSuccess, assignationMeta]);

  const { mutateAsync: assignChild, isPending: isAssignChildLoading } =
    useAssignChildMutation();

  const { mutateAsync: sendBulkEmail, isPending: isSendBulkEmail } =
    useBulkEmailApplicantsMutation();

  const saveEmail = async () => {
    const formState = form.getValues();
    const savingPromise = assignChild({
      childId: childId,
      email: formState.assignedUser ? formState.assignedUser.value : null,
    });

    await toast.promise(savingPromise, {
      loading: 'Saving...',
      success: 'Saved!',
      error: 'Something went wrong!',
    });
    await queryClient.invalidateQueries({
      queryKey: childKey.assignationMeta(childId),
    });
  };

  const sendInvitation = async () => {
    const payload: BulkEmailApplicantsDto = {
      applicationIds: [applicationId],
      emailTemplateId: 'AskParentToTakeOverApplication' as EmailTemplateEnum,
    };

    const sendEmailPromise = sendBulkEmail(payload);
    await toast.promise(sendEmailPromise, {
      loading: 'Sending emails...',
      success: 'Emails sent successfully',
      error: 'Failed to send emails',
    });
    await queryClient.invalidateQueries({
      queryKey: childKey.assignationMeta(childId),
    });
  };

  const carerEmailOptions: Option[] = carers
    .filter(carer => !!carer.email)
    .map(carer => ({
      label: `${carer.firstName} ${carer.lastName} (${carer.email})`,
      // Safe to assume email is present, because of filter above
      value: carer.email as string,
    }));

  if (isAssignationMetaLoading) {
    return (
      <div className="border-secondary flex justify-center gap-6 rounded-lg border border-gray-200 p-6">
        <Spinner />
      </div>
    );
  }

  const isAssignedUserRegistered =
    isAssignationMetaSuccess && assignationMeta
      ? assignationMeta.email === assignationMeta.name
        ? false
        : true
      : false;

  const showSendInvitation = !isAssignedUserRegistered && assignationMeta;

  return (
    <div className="border-secondary flex flex-col gap-6 rounded-lg border border-gray-200 p-6">
      <div className="space-x-3">
        <ApplicationStatusTag
          status={assignationMeta ? 'PLACED' : 'NOT_PLACED'}
        >
          {assignationMeta ? 'Assigned' : 'Unassigned'}
        </ApplicationStatusTag>

        {!isAssignedUserRegistered && assignationMeta && (
          <ApplicationStatusTag
            status={assignationMeta?.isEmailInvited ? 'PLACED' : 'NOT_PLACED'}
          >
            {assignationMeta?.isEmailInvited ? 'Email Sent' : 'Email Not Sent'}
          </ApplicationStatusTag>
        )}
      </div>
      <FormProvider {...form}>
        <form
          id="parent-assignation-email-form"
          className="flex flex-col gap-3"
          onSubmit={form.handleSubmit(saveEmail)}
        >
          <CreatableSelect
            name="assignedUser"
            label="Invite via Email"
            placeholder="Enter email address"
            helperText="Enter email address to assign application. If account exists with email, application will be assigned directly."
            options={carerEmailOptions}
            createOptionPosition="first"
          />

          <div className="flex gap-5">
            <button
              className="btn btn-primary flex items-center"
              disabled={!form.formState.isValid || isAssignChildLoading}
            >
              Save Email
            </button>
            {showSendInvitation && (
              <button
                className="btn btn-secondary"
                onClick={sendInvitation}
                disabled={
                  !form.formState.isValid ||
                  isSendBulkEmail ||
                  form.formState.isDirty
                }
                type="button"
              >
                {assignationMeta.isEmailInvited
                  ? 'Resend Invitation'
                  : 'Send Invitation'}
              </button>
            )}
          </div>
        </form>
      </FormProvider>
      <div className="flex items-center justify-between">
        <Detail
          label="Trustees"
          content={isTrusteesSuccess ? trustees?.length : 0}
        />

        <Link
          to={generatePath(staffRouterPath.TRUSTEES, { childId })}
          className="btn btn-secondary"
        >
          Manage Trustees
        </Link>
      </div>
    </div>
  );
}

export { Assignation };
