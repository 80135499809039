import { useLeaveModal } from '@/hooks/use-leave-modal';
import { Child, CreateChildDto, Sex } from '@admissions-support/types';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { ChildDetailsElement } from '../application/child-details/child-details.element';
import { ChildDetailsFormData } from '../application/child-details/child-details.type';
import { useChildDetailsForm } from '../application/child-details/use-child-details.form';

type ChildDetailsFormProps = {
  onSubmit: (data: CreateChildDto) => any;
  initialData?: Child;
  isLoading?: boolean;
  isMutating?: boolean;
};

function ChildDetailsForm(props: ChildDetailsFormProps) {
  const { initialData, onSubmit, isMutating } = props;
  const isCreateNewChildComponent = initialData === undefined;
  const [isUserEditing, setIsUserEditing] = useState(isCreateNewChildComponent);

  const { form, handleSubmit, defaultValues } = useChildDetailsForm({
    initialData,
    onSubmit,
  });

  useLeaveModal({
    show: form.formState.isDirty && !!initialData && isUserEditing,
  });

  const idType = form.watch('identificationType');

  const formDisabled = !isUserEditing || form.formState.isSubmitting;

  const transformFormSubmit = async (data: ChildDetailsFormData) => {
    const response = await handleSubmit({
      ...data,
      dateOfBirth: new Date(data.dateOfBirth),
      sex: data.sex as Sex,
      attachments: data.attachments || [],
    });

    if (!response) {
      return;
    }

    setIsUserEditing(false);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(transformFormSubmit)}>
        <div className="mb-6 flex items-center justify-between">
          <p className="text-md my-1.5 font-medium">Child&apos;s details</p>
          {isCreateNewChildComponent && (
            <div className="space-x-3">
              <button
                type="button"
                className="btn btn-secondary py-2"
                onClick={() => setIsUserEditing(false)}
                disabled={isMutating || isCreateNewChildComponent}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary py-2"
                disabled={!form.formState.isDirty || isMutating}
              >
                {isCreateNewChildComponent ? 'Create' : 'Save'}
              </button>
            </div>
          )}

          {/* Show it if you hover over an existing child */}
          {!isCreateNewChildComponent && !isUserEditing && (
            <button
              type="button"
              className="btn btn-secondary hidden py-2 group-hover:block"
              onClick={() => {
                setIsUserEditing(true);
              }}
            >
              Edit
            </button>
          )}

          {/* Show it if you clicked on Edit button on an existing child */}
          {!isCreateNewChildComponent && isUserEditing && (
            <div className="space-x-3">
              <button
                type="button"
                className="btn btn-secondary py-2"
                onClick={() => {
                  setIsUserEditing(false);
                  form.reset(defaultValues);
                }}
                disabled={isMutating}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary py-2"
                disabled={!form.formState.isDirty || isMutating}
              >
                Save
              </button>
            </div>
          )}
        </div>

        <ChildDetailsElement disabled={formDisabled} idType={idType} />
      </form>
    </FormProvider>
  );
}

export { ChildDetailsForm };
