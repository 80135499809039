import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { BulkEmailApplicantsDto } from '@admissions-support/types';
import { HttpError } from '@/types/error';
import { applicationKey } from '@/config/query-keys';
import { applicationService } from '@/services/application.service';

function useBulkEmailApplicantsMutation(
  mutationOptions?: UseMutationOptions<void, HttpError, BulkEmailApplicantsDto>
) {
  return useMutation({
    mutationKey: applicationKey.bulkEmailApplicants,
    mutationFn: applicationService.bulkEmailApplicants,
    ...mutationOptions,
  });
}

export { useBulkEmailApplicantsMutation };
