import { HttpError, isBadRequest } from '@/types/error';
import { transformFormDataToDto } from '@/utils/application-utils';
import { formatDateToYYYYMMDD } from '@/utils/format-date';
import { getNestedKeys } from '@/utils/get-nested-keys';
import { Child, CreateChildDto } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { childDetailsFormSchema } from './child-details.schema';
import { ChildDetailsFormData } from './child-details.type';

type UseChildDetailsFormProps = {
  initialData?: Child;
  onSubmit: (data: CreateChildDto) => Promise<Child>;
};

function transformApiDataToFormData(initialData?: Child): ChildDetailsFormData {
  if (!initialData) {
    return {
      firstName: '',
      lastName: '',
      knownAsFirstName: '',
      knownAsLastName: '',
      sex: '',
      dateOfBirth: '',
      identificationNumber: '',
      identificationCountry: '',
      identificationType: 'birth_certificate',
      attachments: [],
    };
  }

  return {
    firstName: initialData.firstName,
    lastName: initialData.lastName,
    knownAsFirstName: initialData.knownAsFirstName || '',
    knownAsLastName: initialData.knownAsLastName || '',
    sex: initialData.sex,
    dateOfBirth: formatDateToYYYYMMDD(initialData.dateOfBirth),
    identificationNumber: initialData.identificationNumber,
    identificationCountry: initialData.identificationCountry,
    identificationType: initialData.identificationType,
    attachments: initialData?.attachments,
  };
}

function useChildDetailsForm(props: UseChildDetailsFormProps) {
  const { initialData, onSubmit } = props;

  const defaultValues: ChildDetailsFormData =
    transformApiDataToFormData(initialData);

  const form = useForm({
    resolver: yupResolver(childDetailsFormSchema),
    defaultValues,
  });

  const handleSubmit = async (data: CreateChildDto) => {
    try {
      const transformedApplication = transformFormDataToDto(data, [
        'firstName',
        'lastName',
        'knownAsFirstName',
        'knownAsLastName',
        'sex',
        'dateOfBirth',
        'identificationType',
        'identificationNumber',
        'identificationCountry',
        'attachments',
      ]);

      const child = await onSubmit(transformedApplication);

      form.reset(transformApiDataToFormData(child));

      return 'Success';
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        const availableFields = getNestedKeys(form.getValues());

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      } else {
        form.setError('root.server', {
          message: Array.isArray(httpError.message)
            ? httpError.message[0]
            : httpError.message,
        });
      }
    }
  };

  return {
    form,
    handleSubmit,
    defaultValues,
  };
}

export { useChildDetailsForm };
