import { locationKey } from '@/config/query-keys';
import { locationService } from '@/services/location.service';
import { HttpError } from '@/types/error';
import { CreateLocationDto, Location } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCreateLocationMutation(
  mutationOptions?: UseMutationOptions<Location, HttpError, CreateLocationDto>
) {
  return useMutation({
    mutationKey: locationKey.create,
    mutationFn: locationService.createLocation,
    ...mutationOptions,
  });
}

export { useCreateLocationMutation };
