import { TextInput } from '@/components/form/common/text-input';

type OrganisationDetailsSectionProps = {
  isDisabled: boolean;
};

function OrganisationDetailsSection(props: OrganisationDetailsSectionProps) {
  const { isDisabled } = props;
  return (
    <div className="two-col-form gap-6 py-6">
      <div>
        <p className="font-semibold text-gray-700">Organisation</p>{' '}
        <p className="text-gray-600">
          Specify organisation name and local authority
        </p>
      </div>
      <div className="col-span-2 space-y-6">
        <TextInput
          name="name"
          label="Organisation Name"
          placeholder="Org name"
          disabled={isDisabled}
        />
        <TextInput
          name="localAuthority"
          label="Local Authority"
          helperText="This can be the same as organisation name if appropriate"
          placeholder="local authority"
        />
        <TextInput label="Website" name="website" placeholder="https://" />
      </div>
    </div>
  );
}

export { OrganisationDetailsSection };
