import { childKey } from '@/config/query-keys';
import { childService } from '@/services/child.service';
import { HttpError } from '@/types/error';
import { ListChild } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

/**
 *
 * Fetches all Children for a parent
 * User must be a parent
 */
function useChildren(queryOptions?: UseQueryOptions<ListChild[], HttpError>) {
  return useQuery({
    queryKey: childKey.list,
    queryFn: childService.getChildren,
    ...queryOptions,
  });
}

export { useChildren };
