import { transactionKey } from '@/config/query-keys';
import { transactionService } from '@/services/transaction.service';
import { HttpError } from '@/types/error';
import { CreateTransactionDto, Transaction } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCreateTransactionMutation(
  mutationOptions?: UseMutationOptions<
    Transaction,
    HttpError,
    CreateTransactionDto
  >
) {
  return useMutation({
    mutationKey: transactionKey.create,
    mutationFn: transactionService.createTransaction,
    ...mutationOptions,
  });
}

export { useCreateTransactionMutation };
