import { Box } from '@/components/box';
import { PageTitle } from '@/components/page-title';
import { AddTrusteeModal } from '@/components/parent/add-trustee-modal';
import { TrusteeListTable } from '@/components/parent/trustee-list-table';
import { useChild } from '@/hooks/query-hooks/use-child';
import { useTrustees } from '@/hooks/query-hooks/use-trustee';
import { useModal } from '@/hooks/use-modal';
import { useNavigate, useParams } from 'react-router-dom';

function Trustees() {
  const params = useParams<{ childId: string }>();
  const { data: child, isSuccess: isChildSuccess } = useChild(
    params.childId || ''
  );
  const { data, isLoading } = useTrustees(params.childId || '');
  const { openModal, isOpen, closeModal } = useModal();
  const navigate = useNavigate();
  return (
    <>
      <PageTitle title="Manage Trustees" variant="gray">
        <button className="btn btn-secondary" onClick={() => navigate(-1)}>
          Return to Applications
        </button>
      </PageTitle>

      <Box className="mt-12">
        <div className="flex items-center justify-between">
          <h4 className="text-lg font-semibold">
            {isChildSuccess && child
              ? `${child?.firstName} ${child?.lastName} Trustees`
              : 'Trustees'}
          </h4>
          <button className="btn btn-secondary btn-sm" onClick={openModal}>
            Add Trustee
          </button>
        </div>
        <AddTrusteeModal
          isOpen={isOpen}
          onClose={closeModal}
          childId={params.childId || ''}
        />
        <TrusteeListTable
          data={data}
          isLoading={isLoading}
          childId={params.childId || ''}
        />
      </Box>
    </>
  );
}

export { Trustees };
