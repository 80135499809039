import { ButtonGroup } from '@/components/form/common/button-group';
import { Select } from '@/components/form/common/select';
import { TextInput } from '@/components/form/common/text-input';
import { PageTitle } from '@/components/page-title';
import { SchoolYearFilter } from '@/components/school-year-filter';
import { organisationKey, reportKey } from '@/config/query-keys';
import { useCapacitySimulation } from '@/context/capacity-simulation.context';
import { useSchoolYear } from '@/context/school-year.context';
import {
  useCapacityReport,
  useCapacityReportCsv,
} from '@/hooks/query-hooks/use-capacity-report';
import { useRatios } from '@/hooks/query-hooks/use-ratios';
import { useLeaveModal } from '@/hooks/use-leave-modal';
import { useRunMatching } from '@/hooks/use-run-matching';
import { useSimulateScenarioMutation } from '@/hooks/use-simulate-scenario';
import { queryClient } from '@/libs/react-query';
import { CapacityTable } from '@/pages/reporting/capacity-planning-demand/capacity-table';
import {
  filterBySelectedLocations,
  orderByLocationName,
} from '@/utils/capacity-simulation-utils';
import { formatDateToYYYYMMDD } from '@/utils/format-date';
import {
  CapacityReportQueryParamsDto,
  CapacityReports,
  SchoolYear,
} from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileDownload03, Loading01, Play } from '@untitled-ui/icons-react';
import { format, isWithinInterval } from 'date-fns';
import { isEmpty } from 'lodash-es';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';
import * as yup from 'yup';
import { CapacityDemandLocationFilter } from './locations-filter';

const customSelectOptions: { key: string; value: string }[] = [
  {
    key: 'current',
    value: 'Current',
  },
  {
    key: 'future',
    value: 'Future',
  },
];

const choices: { key: string; value: string }[] = [
  { key: '0', value: '1st Choice' },
  { key: '1', value: '2nd Choice' },
  { key: '2', value: '3rd Choice' },
];

const schema = yup.object({
  tense: yup.string().required(),
  ratioId: yup.string().required().label('Ratio'),
  choiceIndex: yup.string().required(),
  matchingDate: yup
    .string()
    .test(
      'notInSchoolYear',
      'Date must be within the selected school year',
      function test(value) {
        const { schoolYear } = this.options.context as {
          schoolYear?: SchoolYear;
        };
        if (!schoolYear || !value) {
          return true;
        }

        const isSelectedDateIsValid = isWithinInterval(new Date(value), {
          start: new Date(schoolYear.startDate),
          end: new Date(schoolYear.endDate),
        });

        return isSelectedDateIsValid
          ? true
          : this.createError({
              message: `Date must be within the selected school year: ${format(
                new Date(schoolYear.startDate),
                'dd/MM/yyyy'
              )} - ${format(new Date(schoolYear.endDate), 'dd/MM/yyyy')}`,
              path: 'matchingDate',
            });
      }
    ),
});

export type CapacityFormData = yup.InferType<typeof schema>;

function CapacityReport() {
  const {
    data: ratios,
    isSuccess: isRatioSuccess,
    isLoading: isRatiosLoading,
  } = useRatios();
  const ratiosForSelect = isRatioSuccess
    ? ratios.map(ratio => ({
        key: ratio.id,
        value: ratio.name,
      }))
    : [];
  const { schoolYear } = useSchoolYear();
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [reportFilter, setReportFilter] =
    useState<CapacityReportQueryParamsDto>({} as CapacityReportQueryParamsDto);
  const { hasSimulatedCapacity, simulatedCapacity, setDataUpdatedAt } =
    useCapacitySimulation();

  useLeaveModal({ show: hasSimulatedCapacity });

  const form = useForm({
    context: {
      schoolYear,
    },
    resolver: yupResolver(schema),
    defaultValues: {
      tense: 'current',
      ratioId: '',
      choiceIndex: 'all',
      matchingDate: formatDateToYYYYMMDD(new Date()),
    },
  });

  const tense = form.watch('tense');

  useEffect(() => {
    if (tense === 'future') {
      return;
    }

    form.setValue('choiceIndex', 'all');
    form.setValue('matchingDate', undefined);
  }, [tense, form]);

  const {
    data: capacityReport,
    isFetching: isCapacityReportLoading,
    dataUpdatedAt: capacityReportUpdatedAt,
    refetch: refetchCapacityReport,
    isSuccess: isCapacityReportSuccess,
  } = useCapacityReport(reportFilter, {
    enabled: !isEmpty(reportFilter),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 0,
  });

  const { refetch: fetchCapacityCsv } = useCapacityReportCsv(reportFilter, {
    enabled: false,
  });

  const {
    mutateAsync: simulateMatching,
    isPending: isSimulateMatchingLoading,
  } = useSimulateScenarioMutation();

  const {
    isPending: isMatching,
    mutateAsync,
    data,
    reset,
  } = useRunMatching({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['application'],
      });
      queryClient.invalidateQueries({
        queryKey: ['application-list'],
      });
      queryClient.invalidateQueries({
        queryKey: organisationKey.terms,
      });
    },
  });

  useEffect(() => {
    if (isCapacityReportSuccess) {
      setDataUpdatedAt(capacityReportUpdatedAt);
    }
  }, [isCapacityReportSuccess, capacityReportUpdatedAt, setDataUpdatedAt]);

  useEffect(() => {
    if (isEmpty(reportFilter)) {
      return;
    }

    async function init() {
      await refetchCapacityReport();
    }

    init();
  }, [refetchCapacityReport, reportFilter]);

  const submitHandler = async (data: CapacityFormData, event: any) => {
    const capacityReportFilter: CapacityReportQueryParamsDto = {
      ratioId: data.ratioId,
      schoolYearId: schoolYear.id,
      date: data.matchingDate,
      //choiceindex cannot be undefined if its used for demand report
      choiceIndex:
        data.choiceIndex !== 'all' ? parseInt(data.choiceIndex) : undefined,
    };

    const capacityReportQueryKey = reportKey.capacity(capacityReportFilter);

    const submitEvent = event.nativeEvent as SubmitEvent;
    const clickedButton = submitEvent.submitter as HTMLButtonElement;
    const actionValue = clickedButton.value;

    if (actionValue === 'with-matching') {
      if (data.choiceIndex === 'all') {
        await mutateAsync({ choice: 0 });
        await mutateAsync({ choice: 1 });
        await mutateAsync({ choice: 2 });
      } else {
        await mutateAsync({ choice: parseInt(data.choiceIndex) });
      }
    }

    if (hasSimulatedCapacity) {
      // if its simulated query, we call the simulated-report endpoint

      const simulationQuery = await simulateMatching({
        schoolYearId: schoolYear.id,
        //@ts-ignore
        date: data.matchingDate || formatDateToYYYYMMDD(new Date()),
        ratioId: data.ratioId,
        choiceIndexes: [
          data.choiceIndex === 'all' ? 0 : parseInt(data.choiceIndex),
        ],
        isPublishingChanges: false,
        locationData: simulatedCapacity,
      });

      if (simulationQuery) {
        // we dont introduce a new state, instead, we override the report query data
        queryClient.setQueryData<CapacityReports>(
          capacityReportQueryKey,
          oldCapacities => {
            if (!oldCapacities) {
              return [];
            }

            return simulationQuery.updatedResources;
          }
        );

        return;
      }
    }
    setReportFilter(capacityReportFilter);
  };

  const exportCSV = async () => {
    // Request CSV
    const csvBlob = await toast.promise(fetchCapacityCsv(), {
      loading: 'Exporting CSV...',
      success: 'CSV exported successfully',
      error: 'Error exporting CSV',
    });

    if (!csvBlob.isSuccess) {
      return;
    }

    const blob = csvBlob.data;

    // Download the file
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'capacity-report.csv';

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const capacityReportWithSelectedLocations = orderByLocationName(
    filterBySelectedLocations(capacityReport || [], selectedLocations)
  );

  return (
    <div>
      <PageTitle
        title="Capacity Planning"
        description="Custom Report"
        variant="gray"
      />
      <div className="two-col-form mt-6 gap-6 py-6">
        <div className="flex flex-col">
          <h2 className="font-semibold text-gray-700">Capacity</h2>
          <span className="text-gray-600">
            View and manage the available capacity.
          </span>
        </div>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(submitHandler)}
            className="col-span-2"
          >
            <div className="grid gap-6 rounded-lg border border-solid border-gray-200 px-4 py-6">
              <ButtonGroup
                name="tense"
                label=""
                options={customSelectOptions}
              />
              <div
                className={twMerge(
                  'grid gap-6',
                  tense === 'future' ? 'sm:grid-cols-2' : ''
                )}
              >
                <div className="space-y-6">
                  <SchoolYearFilter
                    label="Select School Year"
                    className="shadow-sm"
                    disabled={form.formState.isSubmitting}
                  />
                  <Select
                    label="Ratio"
                    options={[
                      { key: '', value: 'Select a Ratio...' },
                      ...ratiosForSelect,
                    ]}
                    name="ratioId"
                    disabled={isRatiosLoading}
                  />
                </div>
                {tense === 'future' && (
                  <div className="space-y-6">
                    <TextInput
                      name="matchingDate"
                      type="date"
                      label="Select Matching Date"
                    />
                    <Select
                      label="Choice"
                      options={[{ key: 'all', value: 'All' }, ...choices]}
                      name="choiceIndex"
                    />
                  </div>
                )}
              </div>
              <div className="flex justify-end">
                {tense === 'future' ? (
                  <div className="space-x-2">
                    <button className="btn btn-secondary" disabled={isMatching}>
                      <div className="flex items-center gap-2">
                        {isCapacityReportLoading ||
                        isSimulateMatchingLoading ? (
                          <Loading01
                            className="leading-icon animate-spin"
                            aria-hidden="true"
                          />
                        ) : (
                          <Play className="leading-icon" aria-hidden="true" />
                        )}
                        {isCapacityReportLoading || isSimulateMatchingLoading
                          ? 'Query Running'
                          : 'Run Without Matching'}
                      </div>
                    </button>
                    <button className="btn btn-primary" value="with-matching">
                      <div className="flex items-center gap-2">
                        {isCapacityReportLoading ||
                        isSimulateMatchingLoading ||
                        isMatching ? (
                          <Loading01
                            className="leading-icon animate-spin"
                            aria-hidden="true"
                          />
                        ) : (
                          <Play className="leading-icon" aria-hidden="true" />
                        )}
                        {isCapacityReportLoading ||
                        isSimulateMatchingLoading ||
                        isMatching
                          ? 'Running...'
                          : 'Run Matching'}
                      </div>
                    </button>
                  </div>
                ) : (
                  <button className="btn btn-primary">
                    <div className="flex items-center gap-2">
                      {isCapacityReportLoading || isSimulateMatchingLoading ? (
                        <Loading01
                          className="leading-icon animate-spin"
                          aria-hidden="true"
                        />
                      ) : (
                        <Play className="leading-icon" aria-hidden="true" />
                      )}
                      {isCapacityReportLoading || isSimulateMatchingLoading
                        ? 'Query Running'
                        : 'Run Query'}
                    </div>
                  </button>
                )}
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
      {capacityReport && (
        <div className="flex flex-col gap-6 bg-white pb-6">
          <div className="flex items-center justify-between pt-6">
            <div className="flex items-center gap-4">
              <h4 className="text-lg font-semibold text-gray-900">Output</h4>
              <CapacityDemandLocationFilter
                onSelectedLocationsChange={setSelectedLocations}
                selectedLocations={selectedLocations}
              />
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={exportCSV}
              >
                <div className="flex items-center gap-2">
                  <FileDownload03 className="h-5 w-5" />
                  Export to CSV
                </div>
              </button>
            </div>
          </div>
          <FormProvider {...form}>
            <CapacityTable
              report={capacityReportWithSelectedLocations}
              isLoading={isSimulateMatchingLoading}
            />
          </FormProvider>
        </div>
      )}
    </div>
  );
}

export { CapacityReport };
