import { applicationService } from '@/services/application.service';
import { HttpError } from '@/types/error';
import {
  Application,
  CreateEmptyApplicationDto,
} from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useCreateEmptyApplication(
  mutationOptions?: UseMutationOptions<
    Application,
    HttpError,
    CreateEmptyApplicationDto
  >
) {
  return useMutation({
    mutationFn: applicationService.createApplicationWithChildIdentification,
    ...mutationOptions,
  });
}

export { useCreateEmptyApplication };
