import {
  defaultApplicationControl,
  useApplicationControl,
} from '@/context/application-control.context';
import { useSchoolYear } from '@/context/school-year.context';
import { ChangeEvent } from 'react';
import { CheckboxBase } from '../form/common/checkbox';

function IntakeFilter() {
  const { filter, setFilter } = useApplicationControl();
  const { schoolYear } = useSchoolYear();

  const intakes = schoolYear.intakes.map(intake => ({
    label: intake.name,
    value: intake.id,
  }));

  intakes.push({
    label: 'School Year',
    value: 'school-year',
  });

  const handleIntakeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedLocation = event.target.name;

    const index = filter.intakes.indexOf(selectedLocation);
    if (index === -1) {
      setFilter(prevFilter => ({
        ...prevFilter,
        intakes: [...prevFilter.intakes, selectedLocation],
      }));
    } else {
      setFilter(prevFilter => ({
        ...prevFilter,
        intakes: prevFilter.intakes.filter(
          location => location !== selectedLocation
        ),
        //set filter false if intakes gonna be empty
        isSiblingAtLocation:
          prevFilter.intakes.length === 1
            ? false
            : prevFilter.isSiblingAtLocation,
      }));
    }
  };

  const handleSelectAllIntakes = () => {
    if (filter.intakes.length === intakes.length) {
      setFilter(prevFilter => ({
        ...prevFilter,
        intakes: defaultApplicationControl.filter.intakes,
        isSiblingAtLocation: false,
      }));
    } else {
      setFilter(prevFilter => ({
        ...prevFilter,
        intakes: intakes.map(s => s.value),
      }));
    }
  };

  const isSomeSelected = intakes.some(location =>
    filter.intakes.includes(location.value)
  );

  return (
    <>
      <div className="max-h-96 space-y-2 overflow-y-auto">
        {intakes.map(intake => {
          return (
            <CheckboxBase
              key={intake.value}
              name={intake.value}
              label={intake.label}
              onChange={handleIntakeChange}
              checked={filter.intakes.findIndex(c => c === intake.value) >= 0}
            />
          );
        })}
      </div>
      <hr className="!my-4" />
      <CheckboxBase
        name="selectAllIntakes"
        label="Select All"
        onChange={handleSelectAllIntakes}
        checked={filter.intakes.length === intakes.length}
        indeterminate={isSomeSelected}
      />
    </>
  );
}

export { IntakeFilter };
