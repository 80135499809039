import { Ticket } from '@admissions-support/types';
import { startCase } from 'lodash-es';
import { twMerge } from 'tailwind-merge';

type MessageStatusBadge = {
  status: Ticket['status'];
};

const dotColorClasses: Record<Ticket['status'], string> = {
  OPEN: 'bg-green-500',
  CLOSED: 'bg-gray-500',
  IN_PROGRESS: 'bg-blue-500',
  PENDING: 'bg-yellow-500',
};

function MessageStatusBadge(props: MessageStatusBadge) {
  const { status } = props;

  return (
    <span
      className={twMerge(
        'flex w-fit items-center gap-2 rounded-md border bg-white px-[6px] py-[2px] text-xs text-gray-700 shadow-xs'
      )}
    >
      <div
        className={twMerge(
          'h-[6px] w-[6px] rounded-full',
          dotColorClasses[status]
        )}
      />
      {startCase(status.toLowerCase())}
    </span>
  );
}

export { MessageStatusBadge };
