import { sessionTypeKey } from '@/config/query-keys';
import { sessionTypeService } from '@/services/session-type.service';
import { HttpError } from '@/types/error';
import { SessionType } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useSessionTypes(
  queryOptions?: Partial<UseQueryOptions<SessionType[], HttpError>>
) {
  return useQuery({
    queryKey: sessionTypeKey.list,
    queryFn: sessionTypeService.listSessionType,
    ...queryOptions,
  });
}

export { useSessionTypes };
