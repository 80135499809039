import { staffRouterPath } from '@/config/route-paths.config';
import { useChildByPassport } from '@/hooks/query-hooks/use-child-by-passport';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { EligibilityForm } from '../application/eligibility/eligibility-form';
import { TextInput } from './common/text-input';

const schema = yup
  .object({
    identificationNumber: yup
      .string()
      .required()
      .label('Identification number'),
  })
  .required();

function SearchExistingChildForm() {
  const [identificationNumber, setIdentificationNumber] = useState('');

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      identificationNumber: '',
    },
  });

  const {
    data: child,
    isSuccess: isChildSuccess,
    isLoading: isChildFetching,
    isError,
    error,
  } = useChildByPassport(identificationNumber, {
    enabled: !!identificationNumber,
  });

  useEffect(() => {
    if (!isError) {
      return;
    }
    const formattedMessage = Array.isArray(error.message)
      ? error.message[0]
      : error.message;

    form.setError('identificationNumber', { message: formattedMessage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isError]);

  const handleChildSearch = async (data: { identificationNumber: string }) => {
    setIdentificationNumber(data.identificationNumber);
  };

  return (
    <div className="mt-8">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleChildSearch)}>
          <TextInput
            name="identificationNumber"
            label="Birth Certificate or Passport number"
            type="text"
            disabled={isChildFetching}
          />
          {!isChildSuccess && (
            <>
              <button
                className="btn btn-primary btn-full mt-6"
                disabled={!form.formState.isValid || isChildFetching}
              >
                Continue
              </button>
              <p className="py-4 text-center text-gray-400">or</p>
              <Link
                type="button"
                className="btn btn-secondary btn-full"
                to={staffRouterPath.NEW_APPLICATION}
              >
                New Child
              </Link>
            </>
          )}
        </form>
      </FormProvider>
      {isChildSuccess && <EligibilityForm childId={child.id} />}
    </div>
  );
}

export { SearchExistingChildForm };
