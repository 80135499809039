import { reportKey } from '@/config/query-keys';
import { reportService } from '@/services/report.service';
import { HttpError } from '@/types/error';
import {
  ReportTemplate,
  UpdateReportTemplateDto,
} from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateReportTemplate(
  mutationOptions?: UseMutationOptions<
    ReportTemplate,
    HttpError,
    { data: UpdateReportTemplateDto; id: string }
  >
) {
  return useMutation({
    mutationKey: reportKey.updateTemplate,
    mutationFn: reportService.updateReportTemplate,
    ...mutationOptions,
  });
}

export { useUpdateReportTemplate };
