import { FallbackComponent } from '@/components/fallback-component';
import { LoadingScreen } from '@/components/loading-screen';
import { usePublicOrganisation } from '@/hooks/query-hooks/use-public-organisation';
import { OrganisationPublic } from '@admissions-support/types';
import { createContext, useContext } from 'react';

type OrganisationDataContextState = {
  data: OrganisationPublic;
};

const OrganisationDataContext = createContext<OrganisationDataContextState>({
  data: undefined,
} as unknown as OrganisationDataContextState);

function OrganisationDataProvider({ children }: { children: React.ReactNode }) {
  const { data: organisation, isLoading, isSuccess } = usePublicOrganisation();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isLoading && !isSuccess) {
    return <FallbackComponent />;
  }

  return (
    <OrganisationDataContext.Provider
      // This won't be rendered if organisation is undefined so casting is okay
      value={{ data: organisation as OrganisationPublic }}
    >
      {children}
    </OrganisationDataContext.Provider>
  );
}

const useOrganisationData = () => {
  const context = useContext(OrganisationDataContext);
  if (!context) {
    throw new Error(
      'useOrganisationData must be used within an OrganisationDataProvider'
    );
  }
  return context;
};

export { OrganisationDataProvider, useOrganisationData };
