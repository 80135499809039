import { reportKey } from '@/config/query-keys';
import { reportService } from '@/services/report.service';
import { HttpError } from '@/types/error';
import {
  DemandReportQueryParamsDto,
  DemandReports,
} from '@admissions-support/types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

function useDemandReport(
  data: DemandReportQueryParamsDto,
  queryOptions?: Partial<UseQueryOptions<DemandReports, HttpError>>
) {
  return useQuery<DemandReports, HttpError>({
    queryKey: reportKey.capacity(data),
    queryFn: () => reportService.getDemandReport(data),
    ...queryOptions,
  });
}

function useDemandReportCsv(
  data: DemandReportQueryParamsDto,
  queryOptions?: Partial<UseQueryOptions<Blob, HttpError>>
) {
  return useQuery<Blob, HttpError>({
    queryKey: reportKey.demandCsv(data),
    queryFn: () =>
      reportService.getDemandReportCsv({ ...data, isCsvExport: true }),
    ...queryOptions,
  });
}

export { useDemandReport, useDemandReportCsv };
