import { useReportingOutputControl } from '@/context/reporting-output-control.context';
import { ChildCarer } from '@admissions-support/types';

type CarersCellProps = {
  carers?: ChildCarer[];
};

function CarersCell(props: CarersCellProps) {
  const { carers } = props;

  const { setSideDrawerContent, openModal } = useReportingOutputControl();

  if (!carers || carers?.length <= 0) {
    return <span>--</span>;
  }

  return (
    <div className="flex flex-col flex-wrap gap-1">
      {carers.map((carer, key) => (
        <button
          aria-label={`Open details for ${carer.firstName} ${carer.lastName}`}
          onClick={() => {
            setSideDrawerContent(carer);
            openModal();
          }}
          type="button"
          className="w-fit rounded-md p-1 text-left text-sm font-semibold text-primary-700 hover:bg-gray-50"
          key={key}
        >{`${carer.firstName} ${carer.lastName}`}</button>
      ))}
    </div>
  );
}

export { CarersCell };
