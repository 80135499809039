import { axiosClient } from '@/libs/axios';
import { HttpError } from '@/types/error';
import { ActivityLog, CreateActivityLogDto } from '@admissions-support/types';
import { AxiosResponse } from 'axios';

const createActivityLog = async (
  data: CreateActivityLogDto
): Promise<ActivityLog> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<ActivityLog>,
    CreateActivityLogDto
  >('/activity-log', data);

  return response.data;
};

const getActivityLogByChildId = async (
  childId: string
): Promise<ActivityLog[]> => {
  const response = await axiosClient.get<ActivityLog[]>(
    `/activity-log/children/${childId}`
  );

  return response.data;
};

export const activityLogService = Object.freeze({
  createActivityLog,
  getActivityLogByChildId,
});
