import { DeleteZone } from '@/components/delete-zone';
import { UserGroupForm } from '@/components/form/user-group.form';
import { PageTitle } from '@/components/page-title';
import { Spinner } from '@/components/spinner';
import { organisationKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useAuth } from '@/context/auth.context';
import { useCreateUserGroupMutation } from '@/hooks/create-hooks/use-create-user-group-mutation';
import { useDeleteUserGroupMutation } from '@/hooks/delete-hooks/use-delete-user-group-mutation';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useUserGroup } from '@/hooks/query-hooks/use-user-group';
import { useUpdateUserGroupMutation } from '@/hooks/update-hooks/use-update-user-group-mutation';
import { queryClient } from '@/libs/react-query';
import { Permission } from '@/types/auth';
import { ICreateUserGroupDto } from '@/types/user-group';
import { getErrorMessage } from '@/utils/get-error-message';
import { CreateUserGroupDto } from '@admissions-support/types';
import { useEffect } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

function UpsertUserGroup() {
  const navigate = useNavigate();
  const { handleSession } = useAuth();
  const { mutateAsync: create, isPending: isCreating } =
    useCreateUserGroupMutation({
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: organisationKey.userGroupsList,
          refetchType: 'active',
        });
      },
    });

  const { mutateAsync: update, isPending: isUpdating } =
    useUpdateUserGroupMutation({
      onSuccess: async () => {
        //refreshes current user permissions as well
        await handleSession();
        await queryClient.invalidateQueries({
          queryKey: organisationKey.userGroupsList,
          refetchType: 'active',
        });
      },
    });

  const {
    mutateAsync: deleteUserGroup,
    isPending: isDeleting,
    error: deleteError,
  } = useDeleteUserGroupMutation({
    onSuccess: async () => {
      //refreshes current user permissions as well
      await handleSession();

      navigate(staffRouterPath.USER_GROUPS);
    },
  });

  const params = useParams<{ id?: string }>();
  const isCreateUserGroupView = useMatch(staffRouterPath.NEW_USER_GROUP);
  const { hasPermission } = usePermissions();

  const isLoading = isCreating || isUpdating;

  const {
    isLoading: isUserGroupLoading,
    data: userGroup,
    error,
  } = useUserGroup(params.id);

  useEffect(() => {
    if (error) {
      navigate(staffRouterPath.USER_GROUPS);
    }
  }, [error, navigate]);

  const handleCreateUserGroup = async (data: ICreateUserGroupDto) => {
    //TODO: once the tpyes package is fixed, remove this
    const convertedData = data as CreateUserGroupDto;

    if (!convertedData.description) {
      convertedData.description = null;
    }

    await create(convertedData);
    navigate(staffRouterPath.USER_GROUPS);
  };

  const handleUpdateUserGroup = async (data: ICreateUserGroupDto) => {
    //TODO: once the tpyes package is fixed, remove this
    const convertedData = data as CreateUserGroupDto;

    if (!convertedData.description) {
      convertedData.description = null;
    }

    await update({
      id: params.id || '',
      data: convertedData,
    });
  };

  const handleCancel = () => {
    navigate(staffRouterPath.USER_GROUPS);
  };

  const handleDeleteUserGroup = async () => {
    if (!params.id) {
      return;
    }

    await deleteUserGroup(params.id);
  };

  return (
    <div>
      <PageTitle
        title={isCreateUserGroupView ? 'New User Group' : 'Edit User Group'}
        description="Permission Group"
        variant="gray"
      >
        <div className="flex gap-3">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCancel}
            disabled={isLoading}
          >
            Cancel
          </button>

          <button
            className="btn btn-primary"
            disabled={
              isLoading ||
              !hasPermission([Permission['organisation:user-groups:update']])
            }
            form="upsert-user-group-form"
          >
            {isCreateUserGroupView ? 'Create' : 'Update'}
          </button>
        </div>
      </PageTitle>

      {isUserGroupLoading && !isCreateUserGroupView ? (
        <div className="flex justify-center py-12">
          <Spinner className="h-12 w-12" />
        </div>
      ) : (
        <>
          <UserGroupForm
            onSubmit={
              isCreateUserGroupView
                ? handleCreateUserGroup
                : handleUpdateUserGroup
            }
            initialData={userGroup}
            isLoading={isLoading}
          />
          {hasPermission([Permission['organisation:user-groups:delete']]) &&
          !isCreateUserGroupView ? (
            <div className="two-col-form">
              <div>
                <p className="text-md font-medium leading-7 text-gray-900">
                  Destructive Actions
                </p>
                <p className="text-md leading-6 text-gray-600">
                  Take care with these actions as they are destructive and may
                  not be reversable.
                </p>
              </div>
              <div className="col-span-2 space-y-6">
                <DeleteZone
                  title="Delete User Group"
                  actionButtonText="Delete User Group"
                  description="This action cannot be completed if there are users associated with this user group."
                  modalDescription="In order to delete this User Group, please type DELETE in the input below and confirm deletion."
                  onDelete={handleDeleteUserGroup}
                  isLoading={isDeleting}
                  error={getErrorMessage(deleteError)}
                />
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}

export { UpsertUserGroup };
