import { CarerForm } from '@/components/form/carer.form';
import { PageTitle } from '@/components/page-title';
import { Spinner } from '@/components/spinner';
import { applicationKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useApplication } from '@/hooks/query-hooks/use-application';
import { useApplicationTemplate } from '@/hooks/query-hooks/use-application-template';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useUpdateApplicationMutation } from '@/hooks/update-hooks/use-update-application-mutation';
import { Permission } from '@/types/auth';
import { Application } from '@admissions-support/types';
import { useIsMutating } from '@tanstack/react-query';
import { Navigate, matchPath, useNavigate, useParams } from 'react-router-dom';

const transformDefaultApplicationData = (data: Application): Application => {
  return {
    ...data,
    carers: data.carers.map(carer => ({
      ...carer,
      address: {
        lineOne: carer?.address?.lineOne || '',
        lineTwo: carer?.address?.lineTwo || null,
        lineThree: carer?.address?.lineThree || null,
        lineFour: carer?.address?.lineFour || null,
        postcode: carer?.address?.postcode || '',
        uprn: carer?.address?.uprn || null,
        eastings: carer?.address?.eastings || null,
        northings: carer?.address?.northings || null,
      },
    })),
  };
};

function Carer() {
  const navigate = useNavigate();
  const isNewApplication = Boolean(
    matchPath(staffRouterPath.NEW_APPLICATION, location.pathname)
  );
  const params = useParams<{ id: string }>();
  const nbOfMutation = useIsMutating({
    mutationKey: applicationKey.updateApplication,
  });
  const { hasPermission } = usePermissions();

  const {
    data: application,
    error: applicationError,
    isLoading: isApplicationLoading,
    isSuccess: isApplicationLoaded,
  } = useApplication(params.id || '', {
    enabled: Boolean(params.id),
    select: transformDefaultApplicationData,
  });

  const {
    data: applicationTemplate,
    isSuccess: isApplicationTemplateSuccess,
    isLoading: isApplicationTemplateLoading,
  } = useApplicationTemplate(
    isApplicationLoaded ? application.template.id.toString() : '',
    {
      enabled: isApplicationLoaded,
    }
  );

  const { mutateAsync: updateApplication, isPending: isApplicationUpdating } =
    useUpdateApplicationMutation(application?.id || '');

  const isFormDisabled =
    isApplicationUpdating || !hasPermission([Permission['application:update']]);

  if (applicationError) {
    return <Navigate to={staffRouterPath.APPLICATIONS} />;
  }

  return (
    <div>
      <PageTitle
        title={isNewApplication ? 'New Application' : 'Edit Application'}
        variant="gray"
        className="top-0 z-10 mt-[52px] py-3 lg:sticky lg:mt-0 lg:py-5"
      >
        <div className="flex gap-3">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate(staffRouterPath.APPLICATIONS)}
          >
            Cancel
          </button>

          <button
            className="btn btn-primary"
            form="upsert-application-form"
            disabled={Boolean(nbOfMutation) || isFormDisabled}
          >
            Save Changes
          </button>
        </div>
      </PageTitle>

      {isApplicationLoaded && isApplicationTemplateSuccess && (
        <CarerForm
          initialData={application}
          onSubmit={updateApplication}
          isMutating={isFormDisabled}
          template={applicationTemplate}
        />
      )}

      {isApplicationLoading && (
        <div className="flex items-center justify-center  px-4 py-20">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export { Carer };
