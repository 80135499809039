import { ApplicationStatusTag } from '@/components/application/application-status-tag';
import { Modal } from '@/components/modal';
import { Table } from '@/components/table';
import { staffRouterPath } from '@/config/route-paths.config';
import { useCancelBooking } from '@/hooks/use-cancel-booking';
import { queryClient } from '@/libs/react-query';
import { formatCurrency } from '@/utils/format-currency';
import { getErrorMessage } from '@/utils/get-error-message';
import { BookingSummary } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import { capitalize } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { PaymentStatusTag } from '../../payment-status-tag';

type BookingsTableProps = {
  bookings?: BookingSummary[];
};

function BookingsTable(props: BookingsTableProps) {
  const { bookings } = props;

  const columnHelper = createColumnHelper<BookingSummary>();
  const [cancelModal, setCancelModal] = useState<string | null>(null);

  const {
    mutateAsync,
    isPending: isBookingCancelling,
    error: cancellingError,
  } = useCancelBooking();

  const handleCancelBooking = async () => {
    if (!cancelModal) {
      return;
    }

    await mutateAsync({ bookingId: cancelModal });
    setCancelModal(null);
    await queryClient.invalidateQueries({
      queryKey: ['booking'],
    });
    await queryClient.invalidateQueries({
      queryKey: ['booking', 'child', 'meta'],
    });
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('child.name', {
        id: 'childName',
        cell: info => {
          const childName = info.getValue();
          return <span className="text-sm text-gray-600">{childName}</span>;
        },
        header: () => <span className="text-xs text-gray-600">Child</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('productName', {
        id: 'productName',
        cell: info => {
          const productName = info.getValue();
          return <span className="text-sm text-gray-600">{productName}</span>;
        },
        header: () => <span className="text-xs text-gray-600">Product</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('price', {
        id: 'price',
        cell: info => {
          return (
            <span className="font-mono text-sm text-gray-600">
              {formatCurrency(Number(info.getValue()))}
            </span>
          );
        },
        header: () => <span className="text-xs text-gray-600">Cost</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('paymentStatus', {
        id: 'paymentStatus',
        cell: info => {
          if (info.row.original.status === 'CANCELLED') {
            return (
              <ApplicationStatusTag status="PLACEMENT_REJECTED">
                Cancelled
              </ApplicationStatusTag>
            );
          }

          const paymentStatus = info.getValue();
          return (
            <PaymentStatusTag status={paymentStatus}>
              {capitalize(paymentStatus)}
            </PaymentStatusTag>
          );
        },
        header: () => <span className="text-xs text-gray-600">Status</span>,
        enableSorting: false,
      }),
      columnHelper.display({
        id: 'actions',
        cell: info => {
          const childId = info.row.original.child.id;
          return (
            <Link
              className="link"
              to={generatePath(
                staffRouterPath.EXTRA_HOURS_BOOKINGS_CHILD_DETAILS,
                { id: childId }
              )}
            >
              Manage Booking
            </Link>
          );
        },
        header: () => <span className="text-xs text-gray-600">Actions</span>,
        enableSorting: false,
        size: 160,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!bookings) {
    return null;
  }

  if (bookings.length < 1) {
    return <p className="mt-3">There aren&apos;t any bookings for this day</p>;
  }

  return (
    <>
      <Table columns={columns} data={bookings} />
      <Modal
        open={Boolean(cancelModal)}
        onClose={() => setCancelModal(null)}
        title="Cancel Booking"
        description="Are you sure you want to cancel this booking? This action cannot be undone."
        primaryBtnText="Cancel Booking"
        primaryAction={handleCancelBooking}
        secondaryBtnText="Keep Booking"
        secondaryAction={() => setCancelModal(null)}
        type="error"
        error={getErrorMessage(cancellingError)}
        isLoading={isBookingCancelling}
      />
    </>
  );
}

export { BookingsTable };
