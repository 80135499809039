import { ChevronDown, Menu02, XClose } from '@untitled-ui/icons-react';
import { Fragment, useState } from 'react';

import { NavigationItem } from '@/types/navigation';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { Logo } from './logo';
import { LogoutNavigationItem } from './logout-navigation-item';
import { renderItem } from './sidebar';

interface MobileNavigationProps {
  navigation: NavigationItem[];
  bottomNavigation: NavigationItem[];
}

function Dropdown({ item }: { item: NavigationItem }) {
  return (
    <Disclosure>
      <Disclosure.Button className="navigation-item group w-full">
        <item.icon
          className="navigation-icon group-hover:text-primary-dark-500"
          aria-hidden="true"
        />
        <span>{item.name}</span>
        <ChevronDown
          className="ml-auto h-5 w-5 shrink-0 text-gray-400"
          aria-hidden="true"
        />
      </Disclosure.Button>
      <Disclosure.Panel as="ul" className="mt-1 px-2">
        {item.children &&
          item.children.map(subItem => (
            <li key={subItem.name}>
              {/* 44px */}
              <NavLink
                to={subItem.href}
                className={({ isActive }) =>
                  twMerge(
                    'navigation-item py-2 pl-9 pr-2 text-sm',
                    isActive ? 'bg-secondary-light-200' : ''
                  )
                }
              >
                {subItem.name}
              </NavLink>
            </li>
          ))}
      </Disclosure.Panel>
    </Disclosure>
  );
}

function MobileNavigation(props: MobileNavigationProps) {
  const { navigation, bottomNavigation } = props;
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      {/* Mobile navigation top*/}
      <div className="fixed left-0 right-0 top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-1.5 shadow-sm sm:px-6 lg:hidden">
        <div className="flex h-10 shrink-0 items-center">
          <Logo className="h-8" />
        </div>

        <button
          type="button"
          className="-m-2.5 ml-auto p-2.5 text-gray-500 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Menu02 className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      {/* Mobile navigation sidebar*/}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XClose
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>

                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-50 px-6 pb-2">
                  <div className="flex h-16 shrink-0 items-center">
                    <Logo className="h-10" />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <ul className="-mx-2 space-y-1">
                        {navigation.map(item => (
                          <li key={item.name}>
                            {item.children && item.children.length > 0 ? (
                              <Dropdown item={item} />
                            ) : (
                              renderItem(item)
                            )}
                          </li>
                        ))}
                      </ul>
                      <li className="mt-auto">
                        <ul className="-mx-2 space-y-1">
                          {bottomNavigation.map(item => (
                            <li key={item.name}>
                              {item.children && item.children.length > 0 ? (
                                <Dropdown item={item} />
                              ) : (
                                renderItem(item)
                              )}
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                  <LogoutNavigationItem />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export { Dropdown, MobileNavigation };
