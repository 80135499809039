const formatDateToShortDate = (date?: Date | string | null) => {
  if (!date) {
    return null;
  }

  const convertedDate = new Date(date);
  if (isNaN(convertedDate.getTime())) {
    return null;
  }

  const year = convertedDate.getUTCFullYear();
  const month = convertedDate.getUTCMonth() + 1; // Date provides month index; not month number
  const day = convertedDate.getUTCDate();

  return `${String(day).padStart(2, '0')}/${String(month).padStart(
    2,
    '0'
  )}/${year}`;
};

function formatDateToYYYYMMDD(date: Date | string) {
  const convertedDate = new Date(date);

  return convertedDate.toLocaleDateString('en-CA');
}

export { formatDateToShortDate, formatDateToYYYYMMDD };
