import { childKey } from '@/config/query-keys';
import { queryClient } from '@/libs/react-query';
import { childService } from '@/services/child.service';
import { HttpError } from '@/types/error';
import { Child, UpdateChildDto } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateChildMutation(
  id: string,
  mutationOptions?: UseMutationOptions<Child, HttpError, UpdateChildDto>
) {
  return useMutation({
    mutationKey: childKey.updateChild,
    mutationFn: data => childService.updateChild(id, data),
    onSuccess: updatedChild => {
      queryClient.setQueryData(childKey.single(id), updatedChild);
      queryClient.invalidateQueries({
        queryKey: childKey.list,
      });
    },
    ...mutationOptions,
  });
}

export { useUpdateChildMutation };
