import { twMerge } from 'tailwind-merge';
import { Tag } from './tag';
import { BillingStatus } from '@admissions-support/types';

type PaymentTagProps = {
  status: BillingStatus;
};

enum BillingStatusText {
  HEALTHY = 'Healthy',
  DUE_PAYMENT = 'Overdue',
}

function BillingStatusTag(props: PaymentTagProps) {
  const { status } = props;

  const statusText = BillingStatusText[status];

  return (
    <Tag
      type="outline"
      className="inline-flex w-auto flex-row items-center px-1.5 py-0.5 text-xs font-normal capitalize shadow-xs"
    >
      <span
        className={twMerge(
          'mr-1 inline-block h-1.5 w-1.5 rounded-full',
          status === 'HEALTHY' ? 'bg-green-500' : null,
          status === 'DUE_PAYMENT' ? 'bg-red-500' : null
        )}
      ></span>
      {statusText}
    </Tag>
  );
}

export { BillingStatusTag };
