import { formatCurrency } from '@/utils/format-currency';
import {
  ActivityLog,
  GenericResourceReference,
} from '@admissions-support/types';
import { Disclosure as HeadlessUIDisclosure } from '@headlessui/react';
import { ChevronDown } from '@untitled-ui/icons-react';
import { format, formatDistance } from 'date-fns';
import { map, startCase } from 'lodash-es';
import { twMerge } from 'tailwind-merge';

type ActivitylogItemProps = {
  data: ActivityLog;
  isLast?: boolean;
};

function ActivitylogItem(props: ActivitylogItemProps) {
  const { data, isLast } = props;
  const isNote = data.type === 'NOTE' && data.note;
  const isPayment = data.type === 'PAYMENT' && Boolean(data.paymentDetails);
  const isBookingPaid =
    data.type === 'BOOKING_PAID' && Boolean(data.bookingDetails);
  const isRefund = data.type === 'REFUND';
  const isCredit = data.type === 'CREDIT';
  const isBookingCancel = data.type === 'BOOKING_CANCEL';
  const isPaymentDue = data.type === 'PAYMENT_DUE';

  if (
    isNote ||
    isPayment ||
    isBookingPaid ||
    isRefund ||
    isCredit ||
    isBookingCancel ||
    isPaymentDue
  ) {
    let title = '';
    if (isNote) {
      title = 'Added a note';
    }
    if (isPayment) {
      title = 'Recorded a payment';
    }
    if (isBookingPaid) {
      title = 'Paid for a product';
    }
    if (isRefund) {
      title = 'Issued a refund';
    }
    if (isCredit) {
      title = 'Added a credit';
    }
    if (isBookingCancel) {
      title = 'Cancelled a booking';
    }
    if (isPaymentDue) {
      title = 'Payment is due for a booking';
    }
    // because its a lot of content, we want to display it as Disclosure
    return (
      <HeadlessUIDisclosure as="div" className="relative flex gap-x-4">
        {({ open }) => (
          <>
            <div
              className={twMerge(
                'absolute left-0 top-0 flex w-6 justify-center',
                isLast ? 'h-6' : '-bottom-6'
              )}
            >
              <div className="w-0.5 bg-gray-500" />
            </div>

            <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
              <div className="h-2 w-2 rounded-full bg-gray-500" />
            </div>

            <div className="flex-auto">
              <HeadlessUIDisclosure.Button
                className="flex-auto cursor-pointer"
                as="div"
              >
                <div className="flex justify-between">
                  <p className="py-0.5 text-sm font-bold leading-5 text-gray-700">
                    <span className="font-normal text-gray-900">
                      {data.user?.name}
                    </span>{' '}
                    {title}
                  </p>

                  <div className="flex items-center">
                    <time
                      dateTime={format(
                        new Date(data.createdAt),
                        'dd/MM/yyyy HH:mm'
                      )}
                      className="flex-none py-0.5 text-sm leading-5 text-gray-500 hover:cursor-pointer"
                      data-tooltip-id="change-log-tooltip"
                      data-tooltip-content={format(
                        new Date(data.createdAt),
                        'dd/MM/yyyy HH:mm'
                      )}
                    >
                      {formatDistance(new Date(data.createdAt), new Date(), {
                        addSuffix: true,
                      })}
                    </time>
                    <ChevronDown
                      className={twMerge(
                        'ml-4 h-5 w-5 shrink-0 text-gray-400',
                        open && 'rotate-180'
                      )}
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </HeadlessUIDisclosure.Button>
              <HeadlessUIDisclosure.Panel className="mt-4 flex-auto ">
                {/** Note */}
                {isNote && (
                  <div className="rounded-md border border-gray-100 bg-gray-25 p-4 text-sm text-gray-700">
                    {data.note}
                  </div>
                )}

                {/** Record Payment */}
                {isPayment && (
                  <div className="space-y-3 rounded-md border border-gray-100 bg-gray-25 p-4">
                    {map(data.paymentDetails, (value, key) => {
                      if (!value) {
                        return;
                      }

                      if (key === 'notes' || key === 'id') {
                        return;
                      }

                      let [title, val] = [key, value];

                      switch (key) {
                        case 'amount':
                          val = formatCurrency(Number(value));
                          title = 'Amount';
                          break;
                        case 'date':
                          val = format(new Date(value.toString()), 'dd/MM/yy');
                          title = 'Transaction Date';
                          break;
                        case 'source':
                          val = startCase(value.toString().toLowerCase());
                          title = 'Funding Source';
                          break;
                        case 'sourceDescription':
                          val = startCase(value.toString().toLowerCase());
                          title = 'Source Description';
                          break;
                        case 'reference':
                          val = value.toString();
                          title = 'Transaction ID';
                          break;
                        default:
                          break;
                      }
                      return (
                        <div className="flex">
                          <p className="w-2/5 text-gray-700">{title}</p>
                          <p className="w-3/5 font-mono text-gray-600">
                            {val.toString()}
                          </p>
                        </div>
                      );
                    })}
                    <div className="flex">
                      <p className="w-2/5 text-gray-700">Child Name</p>
                      <p className="w-3/5 font-mono text-gray-600">
                        {data.child.name}
                      </p>
                    </div>
                    {data.paymentDetails?.notes ? (
                      <div className="flex">
                        <p className="w-2/5 text-gray-700">Notes</p>
                        <p className="w-3/5 font-mono text-gray-600">
                          {data.paymentDetails.notes}
                        </p>
                      </div>
                    ) : null}
                  </div>
                )}

                {/** Booking Paid */}
                {isBookingPaid && (
                  <div className="space-y-3 rounded-md border border-gray-100 bg-gray-25 p-4">
                    {map(data.bookingDetails, (value, key) => {
                      if (!value) {
                        return;
                      }

                      let val = '';
                      let title = '';
                      const formattedValue = value as GenericResourceReference;

                      switch (key) {
                        case 'date':
                          val = format(new Date(value.toString()), 'dd/MM/yy');
                          title = 'Booking Date';
                          break;
                        case 'location':
                          val = startCase(
                            formattedValue.name!.toString().toLowerCase()
                          );
                          title = 'Nursery Location';
                          break;
                        case 'product':
                          val = formattedValue.name.toString();
                          title = 'Product name';
                          break;
                        default:
                          break;
                      }
                      return (
                        <div className="flex">
                          <p className="w-2/5 text-gray-700">{title}</p>
                          <p className="w-3/5 font-mono text-gray-600">{val}</p>
                        </div>
                      );
                    })}
                    <div className="flex">
                      <p className="w-2/5 text-gray-700">Child Name</p>
                      <p className="w-3/5 font-mono text-gray-600">
                        {data.child.name}
                      </p>
                    </div>
                  </div>
                )}

                {/** Refund */}
                {isRefund && (
                  <div className="space-y-3 rounded-md border border-gray-100 bg-gray-25 p-4">
                    {map(data.paymentDetails, (value, key) => {
                      if (!value) {
                        return;
                      }

                      if (key === 'notes' || key === 'id') {
                        return;
                      }

                      let [title, val] = [key, value];

                      switch (key) {
                        case 'amount':
                          val = formatCurrency(Number(value));
                          title = 'Amount';
                          break;
                        case 'date':
                          val = format(new Date(value.toString()), 'dd/MM/yy');
                          title = 'Transaction Date';
                          break;
                        case 'reference':
                          val = value.toString();
                          title = 'Transaction ID';
                          break;
                        default:
                          break;
                      }
                      return (
                        <div className="flex">
                          <p className="w-2/5 text-gray-700">{title}</p>
                          <p className="w-3/5 font-mono text-gray-600">
                            {val.toString()}
                          </p>
                        </div>
                      );
                    })}
                    <div className="flex">
                      <p className="w-2/5 text-gray-700">Child Name</p>
                      <p className="w-3/5 font-mono text-gray-600">
                        {data.child.name}
                      </p>
                    </div>
                    {data.paymentDetails?.notes ? (
                      <div className="flex">
                        <p className="w-2/5 text-gray-700">Notes</p>
                        <p className="w-3/5 font-mono text-gray-600">
                          {data.paymentDetails.notes}
                        </p>
                      </div>
                    ) : null}
                  </div>
                )}

                {/** Credit */}
                {isCredit && (
                  <div className="space-y-3 rounded-md border border-gray-100 bg-gray-25 p-4">
                    {map(data.paymentDetails, (value, key) => {
                      if (!value) {
                        return;
                      }

                      if (key === 'notes' || key === 'id') {
                        return;
                      }

                      let [title, val] = [key, value];

                      switch (key) {
                        case 'amount':
                          val = formatCurrency(Number(value));
                          title = 'Amount';
                          break;
                        case 'date':
                          val = format(new Date(value.toString()), 'dd/MM/yy');
                          title = 'Credit Date';
                          break;
                        default:
                          break;
                      }
                      return (
                        <div className="flex">
                          <p className="w-2/5 text-gray-700">{title}</p>
                          <p className="w-3/5 font-mono text-gray-600">
                            {val.toString()}
                          </p>
                        </div>
                      );
                    })}
                    <div className="flex">
                      <p className="w-2/5 text-gray-700">Child Name</p>
                      <p className="w-3/5 font-mono text-gray-600">
                        {data.child.name}
                      </p>
                    </div>
                    {data.paymentDetails?.notes ? (
                      <div className="flex">
                        <p className="w-2/5 text-gray-700">Notes</p>
                        <p className="w-3/5 font-mono text-gray-600">
                          {data.paymentDetails.notes}
                        </p>
                      </div>
                    ) : null}
                  </div>
                )}

                {/** Booking Cancelled */}
                {isBookingCancel && (
                  <div className="space-y-3 rounded-md border border-gray-100 bg-gray-25 p-4">
                    <div className="flex">
                      <p className="w-2/5 text-gray-700">Child Name</p>
                      <p className="w-3/5 font-mono text-gray-600">
                        {data.child.name}
                      </p>
                    </div>
                    <div className="flex">
                      <p className="w-2/5 text-gray-700">Cancellation Date</p>
                      <p className="w-3/5 font-mono text-gray-600">
                        {format(new Date(data.createdAt), 'dd/MM/yy')}
                      </p>
                    </div>
                    {data.bookingDetails?.date ? (
                      <div className="flex">
                        <p className="w-2/5 text-gray-700">Booking Date</p>
                        <p className="w-3/5 font-mono text-gray-600">
                          {format(
                            new Date(data.bookingDetails.date),
                            'dd/MM/yy'
                          )}
                        </p>
                      </div>
                    ) : null}
                    {data.bookingDetails?.product.name ? (
                      <div className="flex">
                        <p className="w-2/5 text-gray-700">Product Name</p>
                        <p className="w-3/5 font-mono text-gray-600">
                          {data.bookingDetails?.product.name}
                        </p>
                      </div>
                    ) : null}
                    {data.bookingDetails?.location.name}
                    <div className="flex">
                      <p className="w-2/5 text-gray-700">Location</p>
                      <p className="w-3/5 font-mono text-gray-600">
                        {data.bookingDetails?.location.name}
                      </p>
                    </div>
                  </div>
                )}

                {isPaymentDue && (
                  <div className="space-y-3 rounded-md border border-gray-100 bg-gray-25 p-4">
                    <div className="flex">
                      <p className="w-2/5 text-gray-700">Child Name</p>
                      <p className="w-3/5 font-mono text-gray-600">
                        {data.child.name}
                      </p>
                    </div>
                    {data.bookingDetails?.date ? (
                      <div className="flex">
                        <p className="w-2/5 text-gray-700">Booking Date</p>
                        <p className="w-3/5 font-mono text-gray-600">
                          {format(
                            new Date(data.bookingDetails.date),
                            'dd/MM/yy'
                          )}
                        </p>
                      </div>
                    ) : null}
                    {data.bookingDetails?.product.name ? (
                      <div className="flex">
                        <p className="w-2/5 text-gray-700">Product Name</p>
                        <p className="w-3/5 font-mono text-gray-600">
                          {data.bookingDetails.product.name}
                        </p>
                      </div>
                    ) : null}
                    {data.bookingDetails?.location.name ? (
                      <div className="flex">
                        <p className="w-2/5 text-gray-700">Location</p>
                        <p className="w-3/5 font-mono text-gray-600">
                          {data.bookingDetails?.location.name}
                        </p>
                      </div>
                    ) : null}
                  </div>
                )}
              </HeadlessUIDisclosure.Panel>
            </div>
          </>
        )}
      </HeadlessUIDisclosure>
    );
  }

  return (
    <div className="relative flex gap-x-4">
      <div
        className={twMerge(
          'absolute left-0 top-0 flex w-6 justify-center',
          isLast ? 'h-6' : '-bottom-6'
        )}
      >
        <div className="w-0.5 bg-gray-500" />
      </div>

      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
        <div className="h-2 w-2 rounded-full bg-gray-500" />
      </div>
      <div className="flex-auto">
        <div className="mb-4 flex justify-between">
          <p className="py-0.5 text-sm font-bold leading-5 text-gray-700">
            <span className="font-normal text-gray-900">{data.user?.name}</span>{' '}
            {startCase(data.type.toLowerCase())}
          </p>

          <time
            dateTime={format(new Date(data.createdAt), 'dd/MM/yyyy HH:mm')}
            className="flex-none py-0.5 text-sm leading-5 text-gray-500 hover:cursor-pointer"
            data-tooltip-id="change-log-tooltip"
            data-tooltip-content={format(
              new Date(data.createdAt),
              'dd/MM/yyyy HH:mm'
            )}
          >
            {formatDistance(new Date(data.createdAt), new Date(), {
              addSuffix: true,
            })}
          </time>
        </div>
      </div>
    </div>
  );
}

export { ActivitylogItem };
