import { useExtraHoursBookingsControl } from '@/context/extra-hours-bookings-control.context';
import { Popover } from '@headlessui/react';
import { ApplicationStatusTag } from '../application/application-status-tag';
import { FilterItem } from '../application/applications-filter-row';
import { PaymentStatusFilter, paymentStatuses } from './status-filter';

function ExtraHoursFilterRow() {
  const { filter, setFilter } = useExtraHoursBookingsControl();

  const handleResetStatus = () => {
    setFilter(prevFilter => ({
      ...prevFilter,
      status: '',
    }));
  };

  function SelectedStatuses() {
    const selectedStatuses = paymentStatuses.filter(
      status => status.value === filter.status
    );

    return (
      <div className="space-x-1">
        <ApplicationStatusTag
          status={
            selectedStatuses[0].value === 'DUE_PAYMENT'
              ? 'PLACEMENT_REJECTED'
              : 'PLACED'
          }
        >
          {selectedStatuses[0].label}
        </ApplicationStatusTag>
      </div>
    );
  }

  return (
    <Popover.Group className="hidden sm:flex sm:items-baseline sm:space-x-2">
      <FilterItem
        counter={filter.status === '' ? 0 : 1}
        label="Status"
        reset={handleResetStatus}
        renderSelectedItems={<SelectedStatuses />}
      >
        <PaymentStatusFilter />
      </FilterItem>
    </Popover.Group>
  );
}

export { ExtraHoursFilterRow };
