import { Ticket } from '@admissions-support/types';

const messageCategories: Record<Ticket['topic'], string> = {
  applications: 'Applications',
  billingAndPayments: 'Billing & Payments',
  buyExtraNurseryHours: 'Buy Extra Nursery Hours',
  changeOfDetails: 'Change of Details',
  generalEnquiry: 'General Enquiry',
  placementDecision: 'Placement Decision',
  reportAnIssue: 'Report an Issue',
  documentationRequest: 'Documentation Request',
};

export { messageCategories };
