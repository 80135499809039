import {
  RatioCapacity,
  Resource,
  SessionType,
} from '@admissions-support/types';
import { ResourceGroupWrapper } from '../resource-group-wrapper';
import {
  getIsActive,
  transformResourceDayCapacityToArray,
} from '@/utils/location-resource-utils';
import { sortBy } from 'lodash-es';
import { CapacityGroup } from '../capacity-group';
import { format } from 'date-fns';
import { ApplicationStatusTag } from '@/components/application/application-status-tag';

type LocationResourceItemProps = {
  sessionTypes: SessionType[];
  ratioCapacities: RatioCapacity[];
  resource: Resource;
  onClick: () => void;
};

function LocationResourceItem(props: LocationResourceItemProps) {
  const { sessionTypes, resource, ratioCapacities, onClick } = props;

  const sortedResourceCapacities = sortBy(
    resource.capacities,
    item => new Date(item.from)
  );

  return (
    <ResourceGroupWrapper onClick={onClick} sessionTypes={sessionTypes}>
      {sortedResourceCapacities.map((resourceCapacity, index) => {
        const transformedResourceCapacity =
          transformResourceDayCapacityToArray(resourceCapacity);

        const isActive = getIsActive(
          sortedResourceCapacities,
          index,
          resourceCapacity
        );

        return (
          <div
            key={`${resource.id}-${resourceCapacity.from.toString()}`}
            className="light-gray-container"
          >
            <div className="mb-2 flex items-center gap-2">
              <p className="font-semibold text-gray-700">
                {index !== 0
                  ? `From ${format(
                      new Date(resourceCapacity.from),
                      'dd/MM/yyyy'
                    )}`
                  : 'Initial'}
              </p>
              {isActive && (
                <ApplicationStatusTag status="GRANTED">
                  Active
                </ApplicationStatusTag>
              )}
            </div>
            <div className="space-y-2">
              {ratioCapacities.map(ratio => {
                return (
                  <CapacityGroup
                    key={`${
                      ratio.ratio.id
                    }-${resourceCapacity.from.toString()}`}
                    resourceDays={transformedResourceCapacity}
                    ratio={ratio.ratio}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </ResourceGroupWrapper>
  );
}

export { LocationResourceItem };
