import { ButtonGroup } from '@/components/form/common/button-group';
import { Dropzone } from '@/components/form/common/dropzone';
import { Select } from '@/components/form/common/select';
import { TextInput } from '@/components/form/common/text-input';
import { paths } from '@/config/upload-paths';

type ChildDetailsElementProps = {
  idType: string;
  disabled?: boolean;
};

function ChildDetailsElement(props: ChildDetailsElementProps) {
  const { disabled, idType } = props;

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
        <TextInput
          type="text"
          name="firstName"
          label="First Name*"
          placeholder="Jane"
          disabled={disabled}
        />

        <TextInput
          type="text"
          name="lastName"
          label="Last Name*"
          placeholder="Doe"
          disabled={disabled}
        />
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <TextInput
          type="text"
          name="knownAsFirstName"
          label="Known as First Name"
          placeholder="Jane"
          disabled={disabled}
        />

        <TextInput
          type="text"
          name="knownAsLastName"
          label="Known as Last Name"
          placeholder="Doe"
          disabled={disabled}
        />
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
        <TextInput
          name="dateOfBirth"
          type="date"
          label="Date of Birth*"
          disabled={disabled}
        />
        <Select
          label="Sex*"
          options={[
            { key: '', value: 'Select..' },
            { key: 'male', value: 'Male' },
            { key: 'female', value: 'Female' },
          ]}
          name="sex"
          disabled={disabled}
        />
      </div>
      <p className="text-md text-gray-600">
        Please add <b>either</b> Birth Certificate or Passport number.
      </p>
      <ButtonGroup
        name="identificationType"
        label=""
        options={[
          {
            key: 'birth_certificate',
            value: 'Birth Certificate',
          },
          {
            key: 'passport',
            value: 'Passport',
          },
        ]}
        disabled={disabled}
      />
      {idType === 'passport' && (
        <TextInput
          type="text"
          name="identificationCountry"
          label="Issuing Country"
          placeholder="United Kingdom"
          disabled={disabled}
        />
      )}
      <TextInput
        type="text"
        name="identificationNumber"
        label={
          idType === 'passport'
            ? 'Passport Number'
            : 'Birth Certificate Reference Number'
        }
        placeholder="#123456789"
        disabled={disabled}
      />
      <Dropzone
        name="attachments"
        path={paths.applications}
        options={{
          disabled: disabled,
          accept: {
            'image/jpeg': [],
            'image/png': [],
            'application/pdf': [],
          },
        }}
        fileRestrictionLabel="Only jpg, pdf files are accepted."
        helperText="Please upload a scanned copy of your ID document."
      />
    </div>
  );
}

export { ChildDetailsElement };
