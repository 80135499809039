import { invitationKeys } from '@/config/query-keys';
import { useAuth } from '@/context/auth.context';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

function useInvitation(queryOptions?: { enabled: boolean }) {
  const { getInvitationByCode } = useAuth();
  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get('code') || '';

  return useQuery({
    queryKey: invitationKeys.single(invitationCode),
    queryFn: () => getInvitationByCode(invitationCode),
    retry: false,
    ...queryOptions,
  });
}

export { useInvitation };
