import {
  ExtraHourResource,
  ExtraHoursAvailableToBook,
  Session,
} from '@admissions-support/types';
import { groupBy, keys, pickBy } from 'lodash-es';

function hasLocationExtraHourResourceForSession({
  session,
  extraHoursResource,
}: {
  session: Session | undefined;
  extraHoursResource: ExtraHourResource[];
}): boolean {
  if (!session) {
    return true;
  }

  return extraHoursResource.some(extraHourResource =>
    extraHourResource.sessionTypes.some(
      sessionType => sessionType.id.toString() === session.type.id.toString()
    )
  );
}

function hasLocationExtraHourResourceForSessionType({
  sessionTypeId,
  extraHoursResource,
}: {
  sessionTypeId: string;
  extraHoursResource: ExtraHourResource[];
}): boolean {
  return extraHoursResource.some(extraHourResource =>
    extraHourResource.sessionTypes.some(
      sessionType => sessionType.id.toString() === sessionTypeId
    )
  );
}

function hasInvalidSelectedSessionType({
  sessionTypeIds,
  extraHoursResource,
}: {
  sessionTypeIds: string[];
  extraHoursResource: ExtraHourResource[];
}) {
  return sessionTypeIds.some(
    sessionTypeId =>
      !hasLocationExtraHourResourceForSessionType({
        sessionTypeId,
        extraHoursResource,
      })
  );
}
/**
 *
 * Identifies dates with multiple bookings
 * Returns: Array of dates with duplicates
 */
function getDuplicatedSelectedExtraHours({
  extraHours,
}: {
  extraHours: ExtraHoursAvailableToBook[];
}) {
  const grouped = groupBy(extraHours, 'date');

  const duplicates = keys(pickBy(grouped, group => group.length > 1));

  return duplicates;
}

function getExtraHoursByIds({
  extraHourIds,
  extraHours,
}: {
  extraHourIds: string[];
  extraHours: ExtraHoursAvailableToBook[];
}) {
  return extraHours.filter(eh => extraHourIds.includes(eh.id));
}

export {
  getDuplicatedSelectedExtraHours,
  getExtraHoursByIds,
  hasInvalidSelectedSessionType,
  hasLocationExtraHourResourceForSessionType,
  hasLocationExtraHourResourceForSession,
};
