import { usePhoneNumberMutation } from '@/hooks/use-phone-number-mutation';
import { MfaSetupRouterState } from '@/types/auth';
import { UpdateNon2FAAttributesDto } from '@admissions-support/types';
import { useNavigate } from 'react-router-dom';
import { SmsMfaForm } from './form/sms-mfa.form';

function SmsMfaSetup() {
  const { mutateAsync } = usePhoneNumberMutation();
  const navigate = useNavigate();

  const handleSavePhoneNumber = async (data: UpdateNon2FAAttributesDto) => {
    await mutateAsync(data);

    const routerState: MfaSetupRouterState = {
      phoneNumber: data.mobileNumber,
    };

    navigate('/auth/mfa-setup/sms-verification', { state: routerState });
  };

  return (
    <div>
      <p>Enter your phone number and we&apos;ll send you a code to verify.</p>

      <SmsMfaForm onSavePhoneNumber={handleSavePhoneNumber} />
    </div>
  );
}

export { SmsMfaSetup };
